import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CohortService } from '../../services/cohort/cohort.service';
import { TemplateService } from '../../services/template/template.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss'],
})
export class MeetingsComponent implements OnInit {
  displayedColumns: string[] = ['checkbox', 'srno', 'student_name', 'email'];
  dataSource: MatTableDataSource<Topiclist>;
  displayedColumnsMeeting: string[] = [
    'srno',
    'cohort_name',
    'meeting_name',
    'createdBy',
    'display_date',
    'time_zone',
    'actions',
  ];
  dataSourceMeeting: MatTableDataSource<MeetingList>;

  selection = new SelectionModel<Topiclist>(true, []);
  Search: any;
  Cohorts = [];
  SelectedCohort: any;
  SelectCohort = [];
  AdminAttendeeId: any;
  AdminMeetingId: any;
  MeetingName: any;
  addMeeting: boolean = false;
  SearchMeeting: any;
  MeetingStartDate: any;
  id: any;
  IsUpdate: boolean = false;
  time_zone: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public templateService: TemplateService,
    public cohortService: CohortService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  async ngOnInit() {
    await this.spinner.show();
    await this.getAllMeetings();
    await this.getAllCohort();
    await this.spinner.hide();
  }

  closeModal() {
    this.addMeeting = false;
    this.SelectedCohort = '';
    this.SearchMeeting = '';
    this.Search = '';
    this.SelectCohort = [];

    const users: Topiclist[] = [];
    this.dataSource = new MatTableDataSource(users);
    this.MeetingName = '';
    this.MeetingStartDate = '';
    this.time_zone = '';
  }
  openModal() {
    this.addMeeting = true;
  }
  async filterMeeting() {
    this.dataSourceMeeting.filter = this.SearchMeeting;
  }
  async getAllMeetings() {
    this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: MeetingList[] = [];
        await this.service.getAllMeetings(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            let srno = 0;
            await list.data[0].forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                meeting_name: element.meeting_name,
                start_date: this.auth
                  .ConvertFormatDbDateTime(
                    element.start_date,
                    element.time_zone == null || element.time_zone == ''
                      ? ''
                      : JSON.parse(element.time_zone).nameValue,
                  )
                  .toString(),
                display_date: this.auth
                  .ConvertDateDDFormat(
                    element.start_date,
                    element.time_zone == null || element.time_zone == ''
                      ? ''
                      : JSON.parse(element.time_zone).nameValue,
                    'DD-MM-YYYY hh:mm A',
                  )
                  .toString(),
                cohort_id: element.cohort_id,
                cohort_name: element.cohort_name,
                createdBy: element.createdBy,
                admin_attendee_id: element.admin_attendee_id,
                attendees: element.attendees,
                end_date: element.end_date,
                is_active: element.is_active,
                meeting_id: element.meeting_id,
                time_zone:
                  element.time_zone == null || element.time_zone == ''
                    ? ''
                    : JSON.parse(element.time_zone).nameValue,
              });
            });
            this.dataSourceMeeting = new MatTableDataSource(users);
            this.dataSourceMeeting.sort = this.sort;
            this.dataSourceMeeting.paginator = this.paginator;
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        });
      }
    });
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      try {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();

                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                this.service.token = token;
                await this.service
                  .DeleteMeeting(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      let noti = {
                        cohort_id: item.cohort_id,
                        noti_type: 'inform_meeting',
                        test_type: 'meeting',
                        test_id: item.id,
                      };
                      await this.service.deleteNotification(noti, token);
                      var list = JSON.parse(<string>value[0].json);
                      await this.getAllMeetings();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Meeting has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        }
      } catch (e) {
        await this.spinner.hide();
      }
    });
  }
  async edit(item) {
    this.SelectedCohort = item.cohort_id;
    await this.changeCohort();
    this.id = item.id;
    this.MeetingName = item.meeting_name;
    this.MeetingStartDate = this.auth.ConvertDateDDFormat(
      item.start_date,
      this.auth.formatZone(item.time_zone).nameValue,
      'yyyy-MM-DTHH:mm',
    );
    let att = item.attendees ? JSON.parse(item.attendees) : [];
    this.dataSource.data.forEach((row) => {
      att.forEach((element) => {
        if (element.user_id == row.id) {
          this.selection.select(row);
        }
      });
    });
    this.IsUpdate = true;
    this.addMeeting = true;
    this.time_zone = this.auth.formatZone(item.time_zone);
  }
  updateMeeting() {
    let att = [];
    let noti = [];
    let adminId = window.sessionStorage.getItem('b6h3a5d9r6e3s5h');

    att.push({
      user_id: adminId,
      AttendeeId: '',
    });
    for (let i = 0; i < this.selection.selected.length; i++) {
      att.push({
        user_id: this.selection.selected[i].id,
        AttendeeId: '',
      });
    }
    let meeting_request = {
      id: this.id,
      cohort_id: this.SelectedCohort,
      meeting_name: this.MeetingName,
      created_by: window.sessionStorage.getItem('b6h3a5d9r6e3s5h'),
      attendees: JSON.stringify(att),
      is_active: 1,
      start_date: this.auth.convertStringUtcTimeZone(
        this.auth.ConvertFormatDbDateTime(
          this.MeetingStartDate,
          this.time_zone.nameValue,
        ),
        this.time_zone.nameValue,
      ),
      time_zone: JSON.stringify(this.time_zone),
    };
    this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .updateMeeting(meeting_request, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json).data;
              for (let i = 0; i < this.selection.selected.length; i++) {
                noti.push({
                  user_id: this.selection.selected[i].id,
                  cohort_id: this.SelectedCohort,
                  message: this.MeetingName + ' meeting will start from ',
                  noti_type: 'inform_meeting',
                  test_type: 'meeting',
                  test_id: list.id,
                  is_read: 0,
                  fire_time: this.auth.convertStringUtcTimeZone(
                    this.auth.ConvertFormatDbDateTime(
                      this.getFireTime(),
                      this.time_zone.nameValue,
                    ),
                    this.time_zone.nameValue,
                  ),
                  meeting_date: this.auth.convertStringUtcTimeZone(
                    this.auth.ConvertFormatDbDateTime(
                      this.MeetingStartDate,
                      this.time_zone.nameValue,
                    ),
                    this.time_zone.nameValue,
                  ),
                });
              }
              await this.service.addNotification(noti, token);
              this.closeModal();
              await this.getAllMeetings();
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      }
    });
  }
  async getAllCohort() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.cohortService.ListCohort(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Cohorts = list.data;
            this.SelectedCohort = '';
          }
        });
      }
    });
  }
  getFireTime() {
    let curr_date = this.auth
      .ConvertAnyTimeZoneToCurrentZone(new Date(), this.time_zone.nameValue)
      .getTime();
    let start_date = this.auth
      .ConvertAnyTimeZoneToCurrentZone(
        this.MeetingStartDate,
        this.time_zone.nameValue,
      )
      .getTime();
    var diff = (curr_date - start_date) / 1000;
    var minute = (diff /= 60);
    let fire_date = this.auth.ConvertFormatDbDateTime(
      new Date(),
      this.time_zone.nameValue,
    );
    if (Math.round(Math.abs(minute)) <= 60) {
      fire_date = fire_date;
    } else {
      fire_date = this.auth
        .AddDateHourFormat(
          this.MeetingStartDate,
          this.time_zone.nameValue,
          -1,
          'h',
        )
        .toString();
    }
    return fire_date;
  }
  async changeCohort() {
    if (this.SelectedCohort) {
      this.spinner.show();
      this.SelectCohort = [];
      this.Cohorts.forEach((element) => {
        if (element.id == this.SelectedCohort) {
          this.SelectCohort = element;
        }
      });
      await this.usersList(this.SelectedCohort);
      this.spinner.hide();
    }
  }
  async usersList(id) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Topiclist[] = [];
        this.spinner.show();
        await this.templateService
          .getUserFromCohort(id, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                await users.push({
                  srno: srno,
                  id: element.id,
                  student_name: element.name,
                  email: element.email,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
            }
          });
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
  copyToClipboard(link) {
    navigator.clipboard.writeText(link);
    this.service.ShowSuccess('Link copied to clipboard.');
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data?.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  async createMeeting() {
    let adminId = window.sessionStorage.getItem('b6h3a5d9r6e3s5h');
    let att = [];
    let noti = [];
    att.push({
      user_id: adminId,
      AttendeeId: '',
    });

    for (let i = 0; i < this.selection.selected.length; i++) {
      att.push({
        user_id: this.selection.selected[i].id,
        AttendeeId: '',
      });
    }
    let meeting_request = {
      cohort_id: this.SelectedCohort,
      meeting_name: this.MeetingName,
      meeting_id: null,
      created_by: window.sessionStorage.getItem('b6h3a5d9r6e3s5h'),
      admin_attendee_id: null,
      attendees: JSON.stringify(att),
      is_active: 1,
      start_date: this.auth.convertStringUtcTimeZone(
        this.auth.ConvertFormatDbDateTime(
          this.MeetingStartDate,
          this.time_zone.nameValue,
        ),
        this.time_zone.nameValue,
      ),
      time_zone: JSON.stringify(this.time_zone),
    };
    this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .addMeeting(meeting_request, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json).data;
              for (let i = 0; i < this.selection.selected.length; i++) {
                noti.push({
                  user_id: this.selection.selected[i].id,
                  cohort_id: this.SelectedCohort,
                  message: this.MeetingName + ' meeting will start from ',
                  noti_type: 'inform_meeting',
                  test_type: 'meeting',
                  test_id: list.id,
                  is_read: 0,
                  fire_time: this.auth.convertStringUtcTimeZone(
                    this.auth.ConvertFormatDbDateTime(
                      this.getFireTime(),
                      this.time_zone.nameValue,
                    ),
                    this.time_zone.nameValue,
                  ),
                  meeting_date: this.auth.convertStringUtcTimeZone(
                    this.auth.ConvertFormatDbDateTime(
                      this.MeetingStartDate,
                      this.time_zone.nameValue,
                    ),
                    this.time_zone.nameValue,
                  ),
                });
              }
              await this.service.addNotification(noti, token);
              this.closeModal();
              await this.getAllMeetings();
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      }
    });
  }
  async startMeeting(row) {
    this.spinner.show();
    let userIds = '';
    let attend = row.attendees ? JSON.parse(row.attendees) : [];
    for (let i = 0; i < attend.length; i++) {
      userIds += attend[i].user_id + ',';
    }
    if (userIds.endsWith(',') == true) {
      userIds = userIds.substring(0, userIds.length - 1);
    }

    await this.service.createMeeting(userIds).then(async (data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        let Attendees = list.data.Attendees;
        let Meeting = list.data.Meeting;
        let noti = [];
        let att = [];
        Attendees.forEach((element) => {
          let u_id = element.ExternalUserId.split('#')[1];
          let AttendeeId = element.AttendeeId;
          att.push({
            user_id: u_id,
            AttendeeId: AttendeeId,
          });
          if (u_id == window.sessionStorage.getItem('b6h3a5d9r6e3s5h')) {
            this.AdminAttendeeId = AttendeeId;
          }
        });

        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();

            Attendees.forEach((element) => {
              let u_id = element.ExternalUserId.split('#')[1];
              let AttendeeId = element.AttendeeId;
              let MeetingId = Meeting.MeetingId;
              let data = {
                AttendeeId: AttendeeId,
                meeting_id: row.id,
              };
              let user_id = btoa(JSON.stringify(data));
              let link = 'https://www.my.destcert.com/conference/' + user_id;
              let msg =
                row.meeting_name +
                ' has started please join us by clicking button <a href="' +
                link +
                '"><span>Join</span></a>';

              noti.push({
                user_id: u_id,
                cohort_id: row.cohort_id,
                message: msg,
                noti_type: 'start_meeting',
                test_type: 'meeting',
                test_id: row.id,
                is_read: 0,
                fire_time: this.auth.convertStringUtcTimeZone(
                  this.auth.ConvertFormatDbDateTime(new Date(), row.time_zone),
                  row.time_zone,
                ),
                meeting_date: this.auth.convertStringUtcTimeZone(
                  this.auth.ConvertFormatDbDateTime(
                    this.MeetingStartDate,
                    row.time_zone,
                  ),
                  row.time_zone,
                ),
              });
              if (u_id == window.sessionStorage.getItem('b6h3a5d9r6e3s5h')) {
                this.AdminMeetingId = MeetingId;
                this.AdminAttendeeId = AttendeeId;
              }
            });
            await this.service.addNotification(noti, token);
            let update_meeting = {
              id: row.id,
              attendees: JSON.stringify(att),
              admin_attendee_id: this.AdminAttendeeId,
              meeting_id: this.AdminMeetingId,
            };
            await this.service.updateMeeting(update_meeting, token);
            Swal.fire({
              title: 'Meeting has been created',
              text: 'You want to join as Admin?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Join',
            }).then(async (result) => {
              if (result.value) {
                let data = {
                  meeting_id: row.id,
                };
                let user_id = btoa(JSON.stringify(data));
                this.router.navigate([`/joinmeeting/${user_id}`]);
              }
            });
            this.spinner.hide();
          }
        });
        this.spinner.hide();
      } else {
        var list = JSON.parse(<string>data[0].json);

        this.spinner.hide();
        this.service.ShowError(list.message);
      }
    });
  }
  cancelMail() {
    this.SelectedCohort = '';
    this.selection.clear();
  }
  getOrdinal(n) {
    let ord = [, 'st', 'nd', 'rd'];
    let exceptions = [11, 12, 13];
    let nth =
      ord[n % 100] == undefined || exceptions.includes(n % 100)
        ? 'th'
        : ord[n % 100];
    return n + nth;
  }
}

export interface Topiclist {
  srno: number;
  id: number;
  student_name: string;
  email: string;
}

export interface MeetingList {
  srno: number;
  id: number;
  meeting_name: string;
  start_date: string;
  display_date: string;
  cohort_id: string;
  cohort_name: string;
  createdBy: string;
  admin_attendee_id: string;
  attendees: string;
  end_date: string;
  is_active: string;
  meeting_id: string;
  time_zone: string;
}
