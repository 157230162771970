import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';

@Component({
  selector: 'app-teacherscreen',
  templateUrl: './teacherscreen.component.html',
  styleUrls: ['./teacherscreen.component.scss'],
})
export class TeacherscreenComponent implements OnInit {
  SelectedTest: any;
  TestName: any;
  Questions = [];
  SingleQue = [];
  QuestionKey = 0;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.SelectedTest = JSON.parse(atob(params['id']));
        this.TestName = this.SelectedTest.test_name;
        this.Questions = this.SelectedTest.questions;
        let No = 1;
        this.Questions.forEach((element) => {
          element['QuestionNo'] = No++;
        });
        this.SingleQue.push(this.Questions[this.QuestionKey]);
      } catch (error) {
        this.router.navigate(['/main/livequestion/']);
      }
    });
  }
  nextQuestion() {
    this.SingleQue = [];
    this.QuestionKey++;
    if (this.QuestionKey < this.Questions.length) {
      this.SingleQue.push(this.Questions[this.QuestionKey]);
    }
  }
  previousQuestion() {
    this.SingleQue = [];
    this.QuestionKey--;
    if (this.QuestionKey < this.Questions.length) {
      this.SingleQue.push(this.Questions[this.QuestionKey]);
    }
  }
  ngOnInit(): void {}
}
