import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { AcronymsService } from '../../services/acronyms/acronyms.service';
import { CatexamService } from '../../services/catexam/catexam.service';
import { CourseService } from '../../services/course/course.service';
@Component({
  selector: 'app-cateexamtest',
  templateUrl: './cateexamtest.component.html',
  styleUrls: ['./cateexamtest.component.scss'],
})
export class CateexamtestComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'ketexam_name',
    'questionlenth',
    'time',
    'actions',
  ];
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @Input('matpagination') matpagination: boolean;
  @ViewChildren(MatPaginator) set matquePaginator(
    quespaginator: QueryList<MatPaginator>,
  ) {
    if (this.matpagination) {
      this.dataSourcequestion.paginator = quespaginator.toArray()[1];
    }
  }
  dataSource: MatTableDataSource<cateexamlist>;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  displayedColumnsquestion: string[] = [
    'srno',
    'question',
    'AnswerA',
    'AnswerB',
    'AnswerC',
    'AnswerD',
    'actions',
  ];
  dataSourcequestion: MatTableDataSource<questionlist>;
  @ViewChildren(MatSort) sortquestion = new QueryList<MatSort>();
  Search: any;
  addCateExam: boolean = false;
  id: any;
  EditHideen = true;
  PracticeTestname: any;
  public PracticeTestForm: FormGroup;
  Certificate: any;
  Certificatelist = [];
  acrolist = [];
  acroselecteds = [];
  hours: any;
  Minutes: any;
  IsUpdate: boolean = false;
  loglist = [];
  SearchQuestion: any;
  editCatQuestion: boolean = false;
  oldAuditValueArray = {
    Certificate: '',
    'Exam Name': '',
    Acronyms: '',
    Hours: '',
    Minutes: '',
  };
  newAuditValueArray = {
    Certificate: '',
    'Exam Name': '',
    Acronyms: '',
    Hours: '',
    Minutes: '',
  };
  auditHistory = '';
  @ViewChildren(MatPaginator) quespaginator = new QueryList<MatPaginator>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  constructor(
    public courseService: CourseService,
    public catTestService: CatexamService,
    public AcronymsService: AcronymsService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    this.dataSourcequestion = new MatTableDataSource();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  closeModal() {
    this.addCateExam = false;
    this.IsUpdate = false;
    this.hours = '';
    this.Minutes = '';
    this.acroselecteds = [];
    this.Certificate = '';
    this.PracticeTestname = '';
    this.oldAuditValueArray = {
      Certificate: '',
      'Exam Name': '',
      Acronyms: '',
      Hours: '',
      Minutes: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      'Exam Name': '',
      Acronyms: '',
      Hours: '',
      Minutes: '',
    };
    this.auditHistory = '';
  }
  addNewCatExam() {
    this.addCateExam = true;
    this.IsUpdate = false;
  }
  async ngOnInit() {
    this.PracticeTestForm = this.fb.group({
      PracticeTestname: ['', Validators.required],
      Certificate: ['', Validators.required],
      Acronymsname: ['', Validators.required],
      hours: ['', Validators.required],
      Minutes: ['', Validators.required],
    });
    await this.spinner.show();
    await this.ListCateexam();
    await this.certificatelist();
    await this.Acroymslist();
    await this.spinner.hide();
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.catTestService
                .DeleteCateExamTest(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();
                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            '<span> ' +
                            item.ketexam_name +
                            '</span> Cat Exam is deleted by <span> ' +
                            e +
                            '</span>';
                          await this.service.addAdminLogs(
                            desc,
                            'cateexam',
                            item.id,
                            token,
                          );
                        }
                      });
                    await this.ListCateexam();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Cate Exam test has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async ListCateexam() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: cateexamlist[] = [];
        await this.catTestService.ListCateExamTest(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                certificate_name: element.certificate.certificate_name,
                ketexam_name: element.ketexam_name,
                ques_count: element.ques_count,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                cid: element.certificate.id,
                time: element.time,
                acronum_ids: element.acronums,
                course_id: element.course_id,
                questionlenth: element.questions.length
                  ? element.questions.length
                  : 0,
                question: element.questions,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort.toArray()[0];
            this.dataSource.paginator = this.paginator.toArray()[0];
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  async edit(item) {
    this.dataSourcequestion = new MatTableDataSource(item.question);
    setTimeout(() => {
      this.dataSourcequestion.paginator = this.quespaginator.toArray()[1];
      this.dataSourcequestion.sort = this.sortquestion.toArray()[1];
      this.matpagination = true;
    }, 1000);
    this.id = item.id;
    this.addCateExam = true;
    this.IsUpdate = true;
    this.PracticeTestname = item.ketexam_name;
    this.Certificate = item.cid;
    if (item.acronum_ids) {
      this.acroselecteds = item.acronum_ids.map((x) => x.id);
    }
    let time = item.time;
    if (time != null && time != '') {
      this.hours = time.split(':')[0];
      this.Minutes = time.split(':')[1];
    } else {
      this.hours = '';
      this.Minutes = '';
    }
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find((x) => x.id == this.Certificate)
        ? this.Certificatelist.find((x) => x.id == this.Certificate)
            .certificate_name
        : '',
      'Exam Name': this.PracticeTestname,
      Acronyms: this.acrolist
        .filter((item) => {
          return this.acroselecteds.includes(item.id);
        })
        .map(function (elem) {
          return elem.short_name;
        })
        .join(','),
      Hours: this.hours,
      Minutes: this.Minutes,
    };
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('cateexam', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async filterQuestion() {
    this.dataSourcequestion.filter = this.SearchQuestion;
    if (this.matpagination) {
      this.dataSourcequestion.paginator._changePageSize(
        this.dataSourcequestion.paginator.pageSize,
      );
    }
  }
  async certificatelist() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          this.Certificatelist = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data;
            this.Certificate = '';
          }
        });
      }
    });
  }
  async Acroymslist() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.AcronymsService.Listacronyms(token).then(async (data) => {
          this.acrolist = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.acrolist = list.data;
          }
        });
      }
    });
  }
  async onSubmit() {
    this.markFormTouched(this.PracticeTestForm);
    if (this.PracticeTestForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var time = this.hours + ':' + this.Minutes + ':00';
          await this.catTestService
            .AddCateExamTest(
              this.PracticeTestname,
              '1',
              this.Certificate,
              this.acroselecteds.toString(),
              time,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.PracticeTestname +
                        '</span> Cat Exam is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'cateexam',
                        list.data.id,
                        token,
                      );
                    }
                  });
                await this.ListCateexam();
                this.closeModal();
                await this.spinner.hide();
                this.service.ShowSuccess('Exam Added Successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async Update() {
    this.markFormTouched(this.PracticeTestForm);
    if (this.PracticeTestForm.valid) {
      if (parseInt(this.hours) > 0 || parseInt(this.Minutes) > 0) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              var time = this.hours + ':' + this.Minutes + ':00';
              await this.catTestService
                .UpdateCateExamTest(
                  this.PracticeTestname,
                  this.id,
                  '1',
                  this.Certificate,
                  this.acroselecteds.toString(),
                  time,
                  token,
                )
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();
                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          this.newAuditValueArray = {
                            Certificate: this.Certificatelist.find(
                              (x) => x.id == this.Certificate,
                            )
                              ? this.Certificatelist.find(
                                  (x) => x.id == this.Certificate,
                                ).certificate_name
                              : '',
                            'Exam Name': this.PracticeTestname,
                            Acronyms: this.acrolist
                              .filter((item) => {
                                return this.acroselecteds.includes(item.id);
                              })
                              .map(function (elem) {
                                return elem.short_name;
                              })
                              .join(','),
                            Hours: this.hours,
                            Minutes: this.Minutes,
                          };
                          var audit = this.auth.compareAuditHist(
                            this.oldAuditValueArray,
                            this.newAuditValueArray,
                          );
                          var desc = audit;
                          await this.service.addAdminLogs(
                            desc,
                            'cateexam',
                            this.id,
                            token,
                          );
                        }
                      });
                    await this.ListCateexam();
                    this.closeModal();
                    await this.spinner.hide();
                    this.service.ShowSuccess('Exam Updated Successfully');
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      } else {
        this.service.ShowError('Please Valid time format.');
      }
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  questionedit(item) {
    let data = btoa(JSON.stringify(item.id));
    this.router.navigate([`main/CateExamQuestion/${data}`]);
  }
  async deleteque(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.catTestService
                  .DeletecateexamQuestion(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        item.title +
                        '</span> Cat Question is deleted by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'cateexamquestion',
                        item.id,
                        token,
                      );
                      this.closeModal();
                      this.ListCateexam();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'CAT Question has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
}
export interface cateexamlist {
  srno: number;
  id: string;
  ketexam_name: string;
  certificate_name: string;
  ques_count: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  cid: number;
  time: string;
  acronum_ids: string;
  course_id: string;
  questionlenth: string;
  question: [];
}
export interface questionlist {
  srno: number;
  id: string;
  answer: string;
  blog_url: string;
  bookpage_no: number;
  createdAt: string;
  d_id: string;
  description: string;
  first_choice: number;
  fourth_choice: string;
  point: string;
  ref_link: string;
  sd_id: string;
  second_choice: string;
  status: string;
  subtopic_id: string;
  test_id: string;
  third_choice: string;
  title: string;
  topic_id: string;
  type: string;
  updatedAt: string;
  youtube_link: string;
}
