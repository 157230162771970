import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { ClientService } from '../../services/client/client.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { CompanyService } from '../../services/company/company.service';

@Component({
  selector: 'app-clientcohort',
  templateUrl: './clientcohort.component.html',
  styleUrls: ['./clientcohort.component.scss'],
})
export class ClientcohortComponent implements OnInit {
  SortingData = {
    active: '',
    direction: '',
  };
  addClient: boolean = false;
  PhoneFocus: boolean = false;
  EmailFocus: boolean = false;
  ClientNameFocus: boolean = false;
  public LoginForm: FormGroup;
  listCohortData = [];
  bkplistCohortData = [];
  Companies = [];
  displayedColumns: string[] = [
    'SrNo',
    'chortname',
    'name',
    'email',
    'mobile',
    'company_name',
    'add_update_cohort',
    'actions',
  ];
  dataSource: MatTableDataSource<Userlist>;
  Search: any;
  PopUpshow = false;
  SrNo: number = 0;
  totalUsers: number;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public companyService: CompanyService,
    public cohortService: CohortService,
    public clientService: ClientService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  email: any;
  ClientName: any;
  ContactNumber: any;
  selectCohortData: any;
  Address: any;
  Notes: any;
  SelectedCompany: any;
  IsUpdate: boolean = false;
  Id: any;
  loglist = [];
  oldAuditValueArray = {
    'Company Name': '',
    'Client Name': '',
    Email: '',
    'Phone Number': '',
    Address: '',
    Notes: '',
  };
  newAuditValueArray = {
    'Company Name': '',
    'Client Name': '',
    Email: '',
    'Phone Number': '',
    Address: '',
    Notes: '',
  };
  auditHistory = '';
  async ngOnInit() {
    this.LoginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
          ),
        ]),
      ],
      ClientName: ['', Validators.required],
      ContactNumber: new FormControl(),
      Address: new FormControl(),
      Notes: new FormControl(),
      SelectedCompany: ['', Validators.required],
    });
    await this.spinner.show();
    await this.getAllClients(
      1,
      this.paginator ? this.paginator.pageSize : 10,
      '',
    );
    await this.getAllCompanies();
    await this.listCohort();

    await this.spinner.hide();
  }
  async getAllCompanies() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.companyService.GetAllCompany(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Companies = list.data;
            this.SelectedCompany = '';
          }
        });
      }
    });
  }
  closeModal() {
    this.addClient = false;
    this.IsUpdate = false;
    this.SelectedCompany = '';
    this.ClientName = '';
    this.email = '';
    this.ContactNumber = '';
    this.Address = '';
    this.Notes = '';
    this.oldAuditValueArray = {
      'Company Name': '',
      'Client Name': '',
      Email: '',
      'Phone Number': '',
      Address: '',
      Notes: '',
    };
    this.newAuditValueArray = {
      'Company Name': '',
      'Client Name': '',
      Email: '',
      'Phone Number': '',
      Address: '',
      Notes: '',
    };
    this.auditHistory = '';
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  addNewClient() {
    this.addClient = true;
  }
  get f() {
    return this.LoginForm.controls;
  }
  delete(element) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this client?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();

              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.auth.deleteuser(element.email).then(async (data) => {
                if (data[0].StatusCode == 1) {
                  await this.clientService
                    .deleteuser(element.id, token)
                    .then(async (userdata) => {
                      if (userdata[0].status == 200) {
                        this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              await this.spinner.hide();

                              this.router.navigate(['/login']);
                            } else {
                              var token = session.getIdToken().getJwtToken();
                              var e = window.localStorage.getItem('adminUser');
                              if (e == null || e == undefined || e == '') {
                                e = 'admin';
                              }
                              var desc =
                                '<span> ' +
                                element.email +
                                '</span> is deleted as client by <span> ' +
                                e +
                                '</span>';

                              await this.service.addAdminLogs(
                                desc,
                                'client',
                                element.id,
                                token,
                              );

                              await this.clientService.deleteClientCohort(
                                element.clientcohortId,
                                token,
                              );
                            }
                          });
                        await this.getAllClients(
                          1,
                          this.paginator ? this.paginator.pageSize : 10,
                          this.Search,
                        );
                        await this.spinner.hide();
                        Swal.fire(
                          'Deleted!',
                          'Client delete successfully',
                          'success',
                        );
                      } else {
                        await this.spinner.hide();
                        await this.service.ShowError(
                          'Delete client unsuccessfully',
                        );
                      }
                    });
                } else {
                  if (data[0].message == 'User does not exist.') {
                    await this.clientService
                      .deleteuser(element.id, token)
                      .then(async (userdata) => {
                        if (userdata[0].status == 200) {
                          await this.getAllClients(
                            1,
                            this.paginator ? this.paginator.pageSize : 10,
                            this.Search,
                          );
                          await this.spinner.hide();
                          Swal.fire(
                            'Deleted!',
                            'Client delete successfully',
                            'success',
                          );
                        } else {
                          await this.spinner.hide();
                          await this.service.ShowError(
                            'Delete client unsuccessfully',
                          );
                        }
                      });
                  } else {
                    await this.spinner.hide();
                    await this.service.ShowError(data[0].message);
                  }
                }
              });
            }
          });
      }
    });
  }

  async pageEvents(event: any) {}
  async getAllClients(pageNo, limit, search, active = '', direction = '') {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: Userlist[] = [];
            if (!this.Search) {
              this.Search = '';
            }
            await this.clientService
              .getLimitClient(pageNo, limit, search, active, direction, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.SrNo = (pageNo - 1) * limit;
                  await list.data.rows.forEach(async (element) => {
                    var chortname = '';
                    if (element.cohorts.length > 0) {
                      for (let i = 0; i < element.cohorts.length; i++) {
                        chortname =
                          chortname + element.cohorts[i].cohort_name + ',';
                      }
                      if (chortname.endsWith(',') == true) {
                        chortname = chortname.substring(
                          0,
                          chortname.length - 1,
                        );
                      }
                    }
                    var company_name = '';
                    if (element.company.length > 0) {
                      for (let i = 0; i < element.company.length; i++) {
                        company_name =
                          company_name + element.company[i].name + ',';
                      }
                      if (company_name.endsWith(',') == true) {
                        company_name = company_name.substring(
                          0,
                          company_name.length - 1,
                        );
                      }
                    }

                    this.SrNo = this.SrNo + 1;
                    await users.push({
                      SrNo: this.SrNo,
                      id: element.id,
                      name: element.name,
                      email: element.email,
                      roll_id: element.roll_id,
                      email_verified_at: element.email_verified_at,
                      email_verified: element.email_verified,
                      mobile: element.mobile ? element.mobile : '',
                      address: element.address,
                      notes: element.notes,
                      profile_img: element.profile_img,
                      permissions: element.permissions,
                      password: element.password,
                      status: element.status,
                      createdAt: element.createdAt,
                      updatedAt: element.updatedAt,
                      free_flashcard: element.free_flashcard,
                      free_flashccsp: element.free_flashccsp,
                      chortname: chortname,
                      cohorts: element.cohorts,
                      clientcohortId: element.clientcohortId,
                      company_name: company_name,
                      company_id: element.company_id ? element.company_id : '',
                    });
                  });
                  this.dataSource = new MatTableDataSource(users);
                  this.dataSource.paginator = this.paginator;
                  this.pagination = true;
                  this.totalUsers = list.data.count;
                  this.dataSource.sort = this.sort;
                  this.dataSource.sortingDataAccessor = (
                    data,
                    sortHeaderId,
                  ) => {
                    switch (sortHeaderId) {
                      case 'SrNo':
                        return data[sortHeaderId];
                      default:
                        return data[sortHeaderId].toLocaleLowerCase();
                    }
                  };
                } else {
                  this.spinner.hide();
                }
              });
          }
        });
    } catch (e) {
      this.spinner.hide();
    }
  }
  async sortData(event) {}
  ShowPop(element) {
    this.listCohortData = this.bkplistCohortData.filter(
      (x) => x.company_id == element.company_id,
    );
    this.listCohortData.forEach((list) => {
      list['checked'] = false;
      element.cohorts.forEach((elements) => {
        if (list.id == elements.id) {
          list['checked'] = true;
        }
      });
    });
    this.PopUpshow = true;
    this.selectCohortData = element;
  }
  async listCohort() {
    this.listCohortData = [];
    this.bkplistCohortData = [];
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.cohortService.ListCohort(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.listCohortData = list.data;
            this.bkplistCohortData = list.data;
          }
        });
      }
    });
  }

  async submit() {
    var data = [];
    data = this.listCohortData.filter((x) => x.checked == true);
    if (data.length > 0) {
      var CohortId = '';
      var c_name = '';
      await data.forEach(async (element) => {
        CohortId = CohortId + element.id + ',';
        c_name = c_name + element.cohort_name + ', ';
      });
      if (c_name.endsWith(',') == true) {
        c_name = c_name.substring(0, c_name.length - 2);
      }
      if (CohortId.endsWith(',') == true) {
        CohortId = CohortId.substring(0, CohortId.length - 1);
      }

      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.clientService
              .client_addclientcohort(this.selectCohortData.id, CohortId, token)
              .then(
                async (data) => {
                  if (data[0].status == 200) {
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();

                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            '<span> ' +
                            c_name +
                            '</span> Cohorts is assign to client <span>' +
                            this.selectCohortData.email +
                            '</span> by <span> ' +
                            e +
                            '</span>';
                          await this.service.addAdminLogs(
                            desc,
                            'assigncohort',
                            this.selectCohortData.id,
                            token,
                          );
                        }
                      });
                    await this.getAllClients(
                      1,
                      this.paginator ? this.paginator.pageSize : 10,
                      '',
                    );
                    this.PopUpshow = false;
                    await this.spinner.hide();
                  } else {
                    this.PopUpshow = false;
                    await this.spinner.hide();
                  }
                },
                (err) => {
                  this.spinner.hide();
                },
              );
          }
        });
    } else {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.clientService
              .client_emptyclientcohort(this.selectCohortData.id, token)
              .then(
                async (data) => {
                  if (data[0].status == 200) {
                    await this.getAllClients(
                      1,
                      this.paginator ? this.paginator.pageSize : 10,
                      '',
                    );
                    this.PopUpshow = false;
                    await this.spinner.hide();
                  } else {
                    this.PopUpshow = false;
                    await this.spinner.hide();
                  }
                },
                (err) => {
                  this.spinner.hide();
                },
              );
          }
        });
    }
  }
  checkall(value) {
    this.listCohortData.forEach((data) => {
      data.checked = value;
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async edit(item) {
    this.loglist = [];

    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('client', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.addClient = true;
    this.IsUpdate = true;
    this.Id = item.id;
    this.ClientName = item.name;
    this.SelectedCompany = item.company_id;
    this.ContactNumber = item.mobile;
    this.Address = item.address;
    this.Notes = item.notes;
    this.email = item.email;
    this.oldAuditValueArray = {
      'Company Name': this.Companies.find((x) => x.id == this.SelectedCompany)
        ? this.Companies.find((x) => x.id == this.SelectedCompany).name
        : '',
      'Client Name': this.ClientName,
      Email: this.email,
      'Phone Number': this.ContactNumber,
      Address: this.Address,
      Notes: this.Notes,
    };
  }
  checkchange(event, item) {
    item.checked = event.target.checked;
  }
  async saveClient() {
    this.markFormTouched(this.LoginForm);
    if (this.LoginForm.valid) {
      this.ClientName = this.ClientName.trim();
      if (
        this.ClientName == '' ||
        this.ClientName == undefined ||
        this.ClientName == null
      ) {
        this.service.ShowError('Client Name is required');
        return;
      }
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.email)) {
        this.service.ShowError('Please enter a valid email!');
        await this.spinner.hide();
        return;
      } else {
        await this.spinner.show();
        await this.auth.CreateUser(this.email).then(async (data) => {
          if (data[0].StatusCode == 1) {
            await this.clientService
              .addClienttemp(
                this.ClientName,
                this.email,
                4,
                'REWerew889##',
                0,
                '0',
                this.Address,
                this.Notes,
                this.ContactNumber,
                this.SelectedCompany,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.service.ShowSuccess('Client add successfully');
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.email +
                          '</span> is added as client by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'client',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  this.addClient = false;
                  this.pagination = false;
                  this.closeModal();
                  await this.getAllClients(
                    1,
                    this.paginator ? this.paginator.pageSize : 10,
                    '',
                  );
                  await this.spinner.hide();
                } else {
                  await this.spinner.hide();
                }
              });
          } else {
            await this.spinner.hide();
            this.service.ShowError(data[0].message);
          }
        });
      }
    }
  }
  async updateClient() {
    this.markFormTouched(this.LoginForm);
    if (this.LoginForm.valid) {
      this.ClientName = this.ClientName.trim();
      if (
        this.ClientName == '' ||
        this.ClientName == undefined ||
        this.ClientName == null
      ) {
        this.service.ShowError('Client Name is required');
        return;
      }
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.email)) {
        this.service.ShowError('Please enter a valid email!');
        await this.spinner.hide();
        return;
      } else {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.clientService
                .UpdateClient(
                  this.Id,
                  this.ClientName,
                  this.SelectedCompany,
                  this.ContactNumber,
                  this.Address,
                  this.Notes,
                  token,
                )
                .then(async (data) => {
                  if (data[0].status == 200) {
                    var list = JSON.parse(<string>data[0].json);
                    this.service.ShowSuccess('Client update successfully');
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();
                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          this.newAuditValueArray = {
                            'Company Name': this.Companies.find(
                              (x) => x.id == this.SelectedCompany,
                            )
                              ? this.Companies.find(
                                  (x) => x.id == this.SelectedCompany,
                                ).name
                              : '',
                            'Client Name': this.ClientName,
                            Email: this.email,
                            'Phone Number': this.ContactNumber,
                            Address: this.Address,
                            Notes: this.Notes,
                          };
                          var audit = this.auth.compareAuditHist(
                            this.oldAuditValueArray,
                            this.newAuditValueArray,
                          );
                          var desc = audit;
                          if (desc != '')
                            await this.service.addAdminLogs(
                              desc,
                              'client',
                              this.Id,
                              token,
                            );
                        }
                      });
                    this.pagination = false;
                    this.closeModal();
                    await this.getAllClients(
                      1,
                      this.paginator ? this.paginator.pageSize : 10,
                      this.Search,
                    );
                    await this.spinner.hide();
                  } else {
                    await this.spinner.hide();
                  }
                });
            }
          });
      }
    }
  }
}
export interface Userlist {
  SrNo: number;
  id: string;
  name: string;
  email: string;
  roll_id: string;
  email_verified_at: string;
  email_verified: string;
  mobile: string;
  address: string;
  notes: string;
  profile_img: string;
  permissions: string;
  password: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  free_flashcard: string;
  free_flashccsp: string;
  chortname: string;
  cohorts: string;
  clientcohortId: string;
  company_name: string;
  company_id: string;
}
