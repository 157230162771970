<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Mind Maps</h3>
      <img
        src="../../../assets/images/mindmaps-icon.png"
        alt=""
        (click)="openRearrangePopup()"
      />
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Domain/Sub Domain..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="openTranscriptPopup()">Add MindMap</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domin_name }}">{{ row.domin_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdomain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain_name }}">{{
              row.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.name }}">{{ row.name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="maps">
          <th mat-header-cell *matHeaderCellDef>View Maps</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon (click)="ViewMapPopup(row.description)"
              >visibility</mat-icon
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="editReference(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="deleteReference(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<div class="cutome-modal" *ngIf="TranscriptPopup">
  <div class="cutome-modal-content">
    <div class="cutome-container">
      <div class="modal-header">
        <p *ngIf="!IsUpdate">Add Mind Maps</p>
        <p *ngIf="IsUpdate">Edit Mind Maps</p>
        <span class="material-icons" (click)="closeTranscriptPopup()"
          >close</span
        >
      </div>
      <div class="modal-body">
        <div>
          <form class="example-form" [formGroup]="VideoLessonForm">
            <div class="formwrapper">
              <div>
                <!-- <label>Certification</label> -->
                <div class="select-box">
                  <select
                    [(ngModel)]="SelectedCertificate"
                    formControlName="SelectedCertificate"
                    (change)="onCertificateChange($event)"
                    required
                    [ngClass]="{
                      err:
                        VideoLessonForm.controls[
                          'SelectedCertificate'
                        ].hasError('required') &&
                        (VideoLessonForm.controls['SelectedCertificate']
                          .dirty ||
                          VideoLessonForm.controls['SelectedCertificate']
                            .touched)
                    }"
                  >
                    <option value="">Select Certification</option>
                    <option
                      *ngFor="let item of Certificatelist"
                      value="{{ item.id }}"
                    >
                      {{ item.certificate_name }}
                    </option>
                  </select>
                  <span></span>
                </div>
              </div>

              <div>
                <!-- <label>Domain</label> -->
                <div class="select-box">
                  <select
                    (change)="domainchange($event)"
                    [(ngModel)]="SelectedDomain"
                    formControlName="SelectedDomain"
                    required
                    [ngClass]="{
                      err:
                        VideoLessonForm.controls['SelectedDomain'].hasError(
                          'required'
                        ) &&
                        (VideoLessonForm.controls['SelectedDomain'].dirty ||
                          VideoLessonForm.controls['SelectedDomain'].touched)
                    }"
                  >
                    <option value="">Select Domain</option>
                    <option *ngFor="let item of Domain" value="{{ item.id }}">
                      {{ item.domin_name }}
                    </option>
                  </select>
                  <span></span>
                </div>
              </div>

              <div class="w-100">
                <ng-select
                  [items]="this.SelectedDomain != '' ? SubDomain : []"
                  formControlName="SelectedSubDomain"
                  (change)="changeSubDomain($event)"
                  [disabled]="true"
                  [(ngModel)]="SelectedSubDomain"
                  bindLabel="subdomain_name"
                  bindValue="id"
                  placeholder="SubDomains"
                  appendTo="body"
                  multiple="true"
                  [attr.disabled]="this.SelectedDomain == '' ? true : null"
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['SelectedSubDomain'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['SelectedSubDomain'].dirty ||
                        VideoLessonForm.controls['SelectedSubDomain'].touched)
                  }"
                >
                </ng-select>
              </div>
              <div class="w-100">
                <ng-select
                  [items]="SelectedSubDomain != '' ? Topic : []"
                  formControlName="SelectedTopics"
                  bindLabel="topic_name"
                  bindValue="id"
                  placeholder="Topics"
                  appendTo="body"
                  multiple="true"
                  [(ngModel)]="SelectedTopics"
                  [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['SelectedTopics'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['SelectedTopics'].dirty ||
                        VideoLessonForm.controls['SelectedTopics'].touched)
                  }"
                >
                </ng-select>
              </div>

              <div>
                <!-- <label>MindMap Title</label> -->
                <input
                  [(ngModel)]="name"
                  formControlName="name"
                  placeholder="MindMap Title"
                  type="text"
                  required
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['name'].hasError('required') &&
                      (VideoLessonForm.controls['name'].dirty ||
                        VideoLessonForm.controls['name'].touched)
                  }"
                />
              </div>

              <div class="video-thum input-linkimg">
                <!-- <label>Thumbnail Link</label> -->
                <input
                  [(ngModel)]="Thumbnail"
                  formControlName="Thumbnail"
                  placeholder="MindMap Thumbnail CDN link"
                  type="text"
                  required
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['Thumbnail'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['Thumbnail'].dirty ||
                        VideoLessonForm.controls['Thumbnail'].touched)
                  }"
                />
                <div class="link-img">
                  <mat-icon class="link">link</mat-icon>
                  <img
                    src="../../../assets/images/No-image Icon.jpg"
                    alt="No-image"
                  />
                </div>
              </div>

              <div class="video-thum input-video-linkimg">
                <!-- <label>Video Link</label> -->
                <input
                  [(ngModel)]="VideoLink"
                  (keyup)="linkChange()"
                  formControlName="VideoLink"
                  placeholder="Video CDN link 1080p HD"
                  type="text"
                  required
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['VideoLink'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['VideoLink'].dirty ||
                        VideoLessonForm.controls['VideoLink'].touched)
                  }"
                />
                <mat-icon class="link-cdn">link</mat-icon>
              </div>
              <div>
                <input
                  [(ngModel)]="Duration"
                  formControlName="Duration"
                  placeholder="Minutes:Seconds"
                  type="text"
                  required
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['Duration'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['Duration'].dirty ||
                        VideoLessonForm.controls['Duration'].touched)
                  }"
                />
              </div>
              <!-- transcode start -->
              <div class="w-100 video-thum input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_720p"
                  formControlName="VideoLink_720p"
                  placeholder="Video CDN link 720p"
                  type="text"
                />
                <mat-icon class="link-cdn">link</mat-icon>
              </div>
              <div class="w-100 video-thum input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_480p"
                  formControlName="VideoLink_480p"
                  placeholder="Video CDN link 480p"
                  type="text"
                />
                <mat-icon class="link-cdn">link</mat-icon>
              </div>
              <div class="w-100 video-thum input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_360p"
                  formControlName="VideoLink_360p"
                  placeholder="Video CDN link 360p"
                  type="text"
                />
                <mat-icon class="link-cdn">link</mat-icon>
              </div>
              <div class="w-100 video-thum input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_240p"
                  formControlName="VideoLink_240p"
                  placeholder="Video CDN link 240p"
                  type="text"
                />
                <mat-icon class="link-cdn">link</mat-icon>
              </div>
              <div class="w-100 video-thum input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_144p"
                  formControlName="VideoLink_144p"
                  placeholder="Video CDN link 144p"
                  type="text"
                />
                <mat-icon class="link-cdn">link</mat-icon>
              </div>
              <!-- transcode end -->
              <div class="w-qrurl">
                <input
                  [(ngModel)]="mindmap_link"
                  formControlName="mindmap_link"
                  placeholder="MindMap Sort Link"
                  type="text"
                  name="mindmap_link"
                />
              </div>
              <div class="w-qr">
                <ngx-kjua
                  [text]="Qr_Url"
                  [renderAsync]="false"
                  [render]="'svg'"
                  [crisp]="true"
                  [minVersion]="1"
                  [ecLevel]="'H'"
                  [size]="70"
                  [ratio]="undefined"
                  [fill]="'#333'"
                  [mSize]="40"
                  [back]="'#fff'"
                  [rounded]="100"
                  [quiet]="0"
                  [mode]="'labelimage'"
                  [image]="base64"
                  [cssClass]="'coolQRCode'"
                  style="width: 70px; height: 70px"
                  #qrEle
                  id="container"
                >
                </ngx-kjua>
                <p (click)="downloadQrCode()" class="qr-download">Download</p>
              </div>
            </div>

            <div class="formwrapper">
              <div class="w-100">
                <label>MindMap HTML Code</label>
                <textarea
                  class="my-text"
                  [(ngModel)]="description"
                  formControlName="description"
                  placeholder="Type here..."
                  rows="10"
                ></textarea>
              </div>
            </div>
          </form>
          <div class="formwrapper">
            <div class="w-100">
              <label>Chapters</label>
              <div
                class="transcript-div"
                *ngFor="let item of ChaptersArray; let i = index"
              >
                <div class="main-transcript">
                  <div class="transcript-textaria">
                    <textarea
                      [(ngModel)]="item.Text"
                      placeholder="Type here..."
                    ></textarea>
                  </div>
                  <div class="transcript-input">
                    <input
                      [(ngModel)]="item.FromTime"
                      type="text"
                      placeholder="00:00"
                      (keyup)="validateFromTime(item, $event)"
                    />
                  </div>
                  <div
                    *ngIf="i > 0"
                    class="transcript-delete"
                    (click)="DeleteChapters(item)"
                  >
                    <span class="material-icons"> delete </span>
                  </div>
                </div>
                <div class="add-new" (click)="addAnotherChapters()">
                  <span class="material-icons"> add </span>
                </div>
              </div>
            </div>
          </div>
          <div class="formwrapper">
            <div class="w-100">
              <label>Transcripts</label>
              <div
                class="transcript-div w-100"
                *ngFor="let item of TranscriptArray; let i = index"
              >
                <div class="main-transcript">
                  <div class="transcript-textaria">
                    <textarea
                      [(ngModel)]="item.Text"
                      placeholder="Type here..."
                    ></textarea>
                  </div>
                  <div class="transcript-input">
                    <input
                      [(ngModel)]="item.FromTime"
                      type="text"
                      placeholder="00:00"
                      (keyup)="validateFromTime(item, $event)"
                    />
                    <input
                      [(ngModel)]="item.ToTime"
                      type="text"
                      placeholder="00:00"
                      (keyup)="validateToTime(item, $event)"
                    />
                  </div>
                  <div
                    *ngIf="i > 0"
                    class="transcript-delete"
                    (click)="DeleteTranscript(item)"
                  >
                    <span class="material-icons"> delete </span>
                  </div>
                </div>
                <div class="add-new" (click)="addAnotherTranscript()">
                  <span class="material-icons"> add </span>
                </div>
              </div>
            </div>
          </div>
          <div class="history-div" *ngIf="IsUpdate">
            <h3><span class="material-icons"> history </span>Audit history</h3>
            <div class="audit-table">
              <div class="table-header">
                <div class="date-title"><span>Date & Time</span></div>
                <div class="action-title"><span>Action</span></div>
              </div>
              <div class="main-body-audit">
                <div class="table-body" *ngFor="let log of loglist">
                  <div class="date-title">
                    <span>{{ log.createdAt | date: 'medium' }}</span>
                  </div>
                  <div class="action-title">
                    <span [innerHTML]="log.description"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel-btn" (click)="closeTranscriptPopup()">
          Cancel
        </button>
        <button class="submit-btn" *ngIf="!IsUpdate" (click)="saveMindMaps()">
          Save
        </button>
        <button class="submit-btn" *ngIf="IsUpdate" (click)="updateMindMaps()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div class="cutome-modal" *ngIf="ViewMap">
  <div class="cutome-modal-content mind">
    <div class="cutome-container">
      <div class="modal-header">
        <p>View Mind Maps</p>
        <span class="material-icons" (click)="ViewMap = false">close</span>
      </div>
      <div class="modal-body">
        <div [innerHTML]="DescriptionView"></div>
      </div>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="RearrangePopup">
  <div class="cutome-modal-content" style="width: 75%">
    <div class="modal-header">
      <p>Rearrange Mindmaps</p>
      <span (click)="closeRearrangeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <div class="formwrapper">
          <div class="w33">
            <div class="select-box">
              <select
                [(ngModel)]="RearrangeCertificate"
                (change)="onRearrangeCertificateChange()"
              >
                <option value="">Select Certification</option>
                <option
                  *ngFor="let item of Certificatelist"
                  value="{{ item.id }}"
                >
                  {{ item.certificate_name }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
          <div class="w33">
            <div class="select-box">
              <select
                [attr.disabled]="this.RearrangeCertificate == '' ? true : null"
                (change)="RearrangeDomainChange()"
                [(ngModel)]="RearrangeSelectedDomain"
              >
                <option value="">Select Domain</option>
                <option *ngFor="let item of Domain" value="{{ item.id }}">
                  {{ item.domin_name }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
        </div>
        <div *ngIf="RearrangeArray.length <= 0" class="no-data">
          <p>There is no mindmaps availabe for selected filter</p>
        </div>
        <div class="mat-elevation-z8" *ngIf="RearrangeArray.length > 0">
          <div class="heading">
            <span>Title</span>
          </div>
          <div cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="body" *ngFor="let item of RearrangeArray" cdkDrag>
              <div
                class="items"
                style="
                  cursor: move;
                  display: flex;
                  border: solid 1px #2e3361;
                  border-radius: 5px;
                  margin: 10px 0px;
                  align-items: center;
                  background: #1b1f48;
                "
              >
                <div style="padding: 0px 10px">
                  <span
                    ><img
                      src="../../../assets/images/sidebar.JPG"
                      alt=""
                      style="width: 13px; line-height: 0"
                  /></span>
                </div>
                <div style="padding: 4px 10px">
                  <span
                    ><img
                      src="{{ item.thumbnail_link }}"
                      alt="videos"
                      style="width: 65px; height: 42px; border-radius: 10px"
                  /></span>
                </div>
                <div
                  style="padding: 0px 10px; color: #8580bd; font-weight: 600"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeRearrangeModal()">Cancel</button>
      <div>
        <button
          type="button"
          (click)="saveRearrange()"
          class="submit-btn"
          [disabled]="RearrangeArray.length <= 0"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
