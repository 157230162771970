import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { EmailService } from '../../services/email/email.service';
import { VariableService } from '../../services/variable/variable.service';
import * as ClassicEditor from '@blowstack/ckeditor5-full-free-build';

@Component({
  selector: 'app-sendmail',
  templateUrl: './sendmail.component.html',
  styleUrls: ['./sendmail.component.scss'],
})
export class SendmailComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'name',
    'subject',
    'actions',
  ];
  dataSource: MatTableDataSource<Topiclist>;
  Search: any;
  certi_id: any;
  name: any;
  subject: any;
  message = '';
  UpdateId: any;
  addsubTopic: boolean = false;
  IsUpdate: boolean = false;
  ListCerficateData = [];
  Variables = [];
  variable: any;
  public VariableForm: FormGroup;
  loglist = [];
  public Editor = ClassicEditor;
  showFullScreen: boolean = false;
  html_source: any;
  oldAuditValueArray = {
    Certificate: '',
    'Template Name': '',
    'Email Subject': '',
    'Html Code': '',
    'Email Message': '',
  };
  newAuditValueArray = {
    Certificate: '',
    'Template Name': '',
    'Email Subject': '',
    'Html Code': '',
    'Email Message': '',
  };
  auditHistory = '';

  ckeConfig = {
    allowedContent: true,
    height: '100vh',
    toolbar: [
      {
        name: 'paragraph',
        items: [
          'Source',
          'Undo',
          'Redo',
          'RemoveFormat',
          'heading',
          'Format',
          '|',
          'FontSize',
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Font',
          'TextColor',
          'BGColor',
          'Link',
          'CreateDiv',
          'Blockquote',
          'Subscript',
          'Superscript',
          'SpecialChar',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          'Outdent',
          'Indent',
          'PageBreak',
          'NumberedList',
          'BulletedList',
          'Image',
          'Table',
          'HorizontalRule',
          'Maximize',
        ],
      },
    ],
  };
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public variableService: VariableService,
    public emailService: EmailService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
    this.VariableForm = this.fb.group({
      certi_id: ['', Validators.required],
      name: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      variable: new FormControl(),
      html_source: new FormControl(),
    });
  }
  addNewsubTopic() {
    this.addsubTopic = true;
    this.IsUpdate = false;
    this.UpdateId = '';
  }
  closeModal() {
    this.addsubTopic = false;
    this.IsUpdate = false;
    this.certi_id = '';
    this.name = '';
    this.subject = '';
    this.UpdateId = '';
    this.message = '';
    this.html_source = '';
    this.oldAuditValueArray = {
      Certificate: '',
      'Template Name': '',
      'Email Subject': '',
      'Html Code': '',
      'Email Message': '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      'Template Name': '',
      'Email Subject': '',
      'Html Code': '',
      'Email Message': '',
    };
    this.auditHistory = '';
  }
  async ngOnInit() {
    await this.spinner.show();
    await this.listTemplate();
    await this.ListCertificate();
    await this.getAllVariables();
    await this.spinner.hide();
  }
  async editEmailTemplate(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('sendmail', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.certi_id = item.certi_id;
    this.name = item.name;
    this.subject = item.subject;
    this.message = item.message;
    this.UpdateId = item.id;
    this.html_source = item.html_source;
    this.addsubTopic = true;
    this.IsUpdate = true;
    this.oldAuditValueArray = {
      Certificate: this.ListCerficateData.find((x) => x.id == this.certi_id)
        ? this.ListCerficateData.find((x) => x.id == this.certi_id)
            .certificate_name
        : '',
      'Template Name': this.name,
      'Email Subject': this.subject,
      'Html Code': this.html_source,
      'Email Message': this.message,
    };
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCerficateData = list.data;
            this.certi_id = '';
          }
        });
      }
    });
  }
  async getAllVariables() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        this.spinner.show();
        var token = session.getIdToken().getJwtToken();
        await this.variableService.GetAllVariables(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Variables = list.data;
            this.variable = '';
          }
        });
        await this.spinner.hide();
      }
    });
  }
  async saveEmailTemplate() {
    this.markFormTouched(this.VariableForm);
    if (this.VariableForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var data = '';
          data = this.message;
          var head = `<h3 class="ck-heading_heading3">&nbsp;</h3>`;
          var ptag = `<p>&nbsp;</p>`;
          data = data.toString().replace(new RegExp(head, 'g'), '');
          data = data.toString().replace(new RegExp(ptag, 'g'), '');
          this.message = data;
          await this.emailService
            .AddEmailTemplate(
              this.certi_id,
              this.name,
              this.subject,
              this.message,
              this.html_source,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.name +
                        ' </span> email template is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'sendmail',
                        list.data.id,
                        token,
                      );
                    }
                  });
                this.closeModal();
                await this.listTemplate();
                await this.spinner.hide();
                this.service.ShowSuccess('Email template added successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
              }
            });
        }
      });
    }
  }
  async updateEmailTemplate() {
    this.markFormTouched(this.VariableForm);
    if (this.VariableForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var data = '';
          data = this.message;
          var head = `<h3 class="ck-heading_heading3">&nbsp;</h3>`;
          var ptag = `<p>&nbsp;</p>`;
          data = data.toString().replace(new RegExp(head, 'g'), '');
          data = data.toString().replace(new RegExp(ptag, 'g'), '');
          this.message = data;
          await this.emailService
            .UpdateEmailTemplate(
              this.UpdateId,
              this.certi_id,
              this.name,
              this.subject,
              this.message,
              this.html_source,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      this.newAuditValueArray = {
                        Certificate: this.ListCerficateData.find(
                          (x) => x.id == this.certi_id,
                        )
                          ? this.ListCerficateData.find(
                              (x) => x.id == this.certi_id,
                            ).certificate_name
                          : '',
                        'Template Name': this.name,
                        'Email Subject': this.subject,
                        'Html Code': this.html_source,
                        'Email Message': this.message,
                      };
                      var audit = this.auth.compareAuditHist(
                        this.oldAuditValueArray,
                        this.newAuditValueArray,
                      );
                      var desc = audit;
                      await this.service.addAdminLogs(
                        desc,
                        'sendmail',
                        this.UpdateId,
                        token,
                      );
                    }
                  });
                this.closeModal();
                await this.listTemplate();
                await this.spinner.hide();
                this.service.ShowSuccess('Email template update successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
              }
            });
        }
      });
    }
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete ' + item.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.emailService
                .DeleteEmailTemplate(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();
                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            '<span> ' +
                            item.subtopic_name +
                            '</span> Email template is deleted by <span> ' +
                            e +
                            '</span>';
                          await this.service.addAdminLogs(
                            desc,
                            'sendmail',
                            item.id,
                            token,
                          );
                        }
                      });

                    await this.listTemplate();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Email template has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async listTemplate() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Topiclist[] = [];
        await this.emailService
          .GetAllEmailTemplate(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                await users.push({
                  srno: srno,
                  id: element.id,
                  certificate_name: element.certificate.certificate_name,
                  certi_id: element.certi_id,
                  name: element.name,
                  message: element.message,
                  subject: element.subject,
                  html_source: element.html_source,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
              this.pagination = true;
            }
          });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}

export interface Topiclist {
  srno: number;
  id: number;
  certificate_name: string;
  certi_id: number;
  name: string;
  message: string;
  subject: string;
  html_source: string;
}

export class UploadAdapter {
  private loader;
  constructor(loader: any) {
    this.loader = loader;
  }
  public upload(): Promise<any> {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = (e) => {
            resolve({ default: myReader.result });
          };
          myReader.readAsDataURL(file);
        }),
    );
  }
}
