<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3 (click)="show()">Teachers</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Name, Email, Certification"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewClient()">Add Teacher</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="SrNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.SrNo }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>
        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
          <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification Access
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.certificate_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(element)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(element)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      (page)="pageEvents($event)"
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addClient">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Teacher</p>
      <p *ngIf="IsUpdate">Edit Teacher</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="LoginForm">
          <div class="formwrapper">
            <div>
              <!-- <label>Teacher Name</label> -->
              <div class="input-fill">
                <input
                  placeholder="Teacher Name"
                  required
                  formControlName="ClientName"
                  [(ngModel)]="ClientName"
                  [ngClass]="{
                    err:
                      LoginForm.controls['ClientName'].hasError('required') &&
                      (LoginForm.controls['ClientName'].dirty ||
                        LoginForm.controls['ClientName'].touched)
                  }"
                />
                <span class="material-icons">account_circle</span>
              </div>
            </div>
            <div *ngIf="!IsUpdate">
              <!-- <label>Teacher Email</label> -->
              <div class="input-fill">
                <input
                  placeholder="Teacher Email"
                  required
                  formControlName="email"
                  [(ngModel)]="email"
                  [ngClass]="{
                    err:
                      LoginForm.controls['email'].hasError('required') &&
                      (LoginForm.controls['email'].dirty ||
                        LoginForm.controls['email'].touched)
                  }"
                />
                <span class="material-icons">email</span>
              </div>
            </div>

            <div>
              <!-- <label>Teacher Phone Number</label> -->
              <div class="input-fill">
                <input
                  type="number"
                  placeholder="Teacher Phone Number"
                  [(ngModel)]="ContactNumber"
                  formControlName="ContactNumber"
                  [ngClass]="{
                    err:
                      LoginForm.controls['ContactNumber'].hasError(
                        'required'
                      ) &&
                      (LoginForm.controls['ContactNumber'].dirty ||
                        LoginForm.controls['ContactNumber'].touched)
                  }"
                />
                <span class="material-icons">call</span>
              </div>
            </div>
            <div *ngIf="!IsUpdate && IsUpdate">
              <div class="input-fill">
                <input
                  type="password"
                  placeholder="Teacher Password"
                  name="Address"
                  [(ngModel)]="Password"
                  formControlName="Password"
                  [ngClass]="{
                    err:
                      LoginForm.controls['Password'].hasError('required') &&
                      (LoginForm.controls['Password'].dirty ||
                        LoginForm.controls['Password'].touched)
                  }"
                />
                <span class="material-icons"> more_horiz </span>
              </div>
            </div>
          </div>
          <div class="formwrapper student-checkbox">
            <div *ngIf="IsUpdate">
              <label class="container"
                >Force password change at next login
                <input
                  type="checkbox"
                  [(ngModel)]="ForceChange"
                  formControlName="ForceChange1"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div>
            <label class="mb-5">Certification Access</label>
            <ng-select
              [items]="Certificates"
              formControlName="Certificate"
              bindLabel="certificate_name"
              bindValue="id"
              placeholder="Select"
              appendTo="body"
              multiple="true"
              [(ngModel)]="selecteds"
            >
            </ng-select>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <button *ngIf="!IsUpdate" class="submit-btn" (click)="saveTeacher()">
        Save
      </button>
      <button *ngIf="IsUpdate" class="submit-btn" (click)="updateTeacher()">
        Save
      </button>
    </div>
  </div>
</div>
