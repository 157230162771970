<div class="Profile-Setting">
  <div class="survey-password">
    <span class="main-title">Change Password</span>
    <div class="form" [formGroup]="LoginForm">
      <div class="input-form mb-20">
        <input
          formControlName="email"
          type="email"
          disabled
          [(ngModel)]="email"
          class="input"
          placeholder="Email"
          required
        />
        <span class="material-icons"> lock </span>
      </div>
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Email is required</div>
        <div *ngIf="f.email.errors.email">
          Email must be a valid email address
        </div>
      </div>
      <div
        *ngIf="
          f.email.errors && !submitted && (f.email.dirty || f.email.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="f.email.errors.required">Email is required</div>
        <div *ngIf="f.email.errors.email">
          Email must be a valid email address
        </div>
      </div>

      <div class="input-form mb-20">
        <input
          formControlName="oldpassword"
          type="password"
          [(ngModel)]="oldpassword"
          class="input"
          placeholder="Old Password"
          required
        />
        <span class="material-icons"> lock </span>
      </div>
      <div *ngIf="submitted && f.oldpassword.errors" class="invalid-feedback">
        <div *ngIf="f.oldpassword.errors.required">
          Old Password is required
        </div>
      </div>
      <div
        *ngIf="
          f.oldpassword.invalid &&
          !submitted &&
          (f.oldpassword.dirty || f.oldpassword.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="f.oldpassword.errors.required">
          Old Password is required
        </div>
      </div>
      <div class="input-form mb-20">
        <input
          formControlName="password"
          type="password"
          [(ngModel)]="password"
          (keyup)="PasswordCheck()"
          class="input"
          placeholder="New Password"
          required
        />
        <span class="material-icons"> lock </span>
      </div>
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">Password is required</div>
        <div *ngIf="f.password.errors.pattern">Pattern not match</div>
      </div>
      <div
        *ngIf="
          f.password.invalid &&
          !submitted &&
          (f.password.dirty || f.password.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="f.password.errors.required">Password is required</div>
        <div *ngIf="f.password.errors.pattern">Pattern not match</div>
      </div>
      <div class="input-form mb-10">
        <input
          name="Confirmpass"
          type="password"
          formControlName="Confirmpass"
          class="input"
          placeholder="Confirm New Password"
          required
        />
        <span class="material-icons"> visibility </span>
      </div>
      <div *ngIf="submitted && f.Confirmpass.errors" class="invalid-feedback">
        <div *ngIf="f.Confirmpass.errors.required">
          Confirm Password is required
        </div>
        <div *ngIf="f.Confirmpass.errors.confirmedValidator">
          Passwords must match
        </div>
      </div>
      <div
        *ngIf="
          f.Confirmpass.invalid &&
          !submitted &&
          (f.Confirmpass.dirty || f.Confirmpass.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="f.Confirmpass.errors.required">
          Confirm Password is required
        </div>
        <div *ngIf="f.Confirmpass.errors.confirmedValidator">
          Passwords must match
        </div>
      </div>
      <div class="strength">
        <div class="strengthpro">
          <p>
            Strength: <span>{{ PassMsg }}</span>
          </p>
          <div class="progress">
            <div class="filler" [ngStyle]="{ 'width.%': PassWidth }"></div>
          </div>
          <div class="stronginfo">
            <p>Please use at least:</p>
            <ul>
              <li>
                <span
                  class="material-icons"
                  [ngClass]="{ Passright: PassLength }"
                >
                  check_circle_outline </span
                >8 Characters
              </li>

              <li>
                <span class="material-icons" [ngClass]="{ Passright: Number }">
                  check_circle_outline
                </span>
                1 Number
              </li>
              <li>
                <span
                  class="material-icons"
                  [ngClass]="{ Passright: IsSpecial }"
                >
                  check_circle_outline
                </span>
                1 Special character
              </li>
              <li>
                <span class="material-icons" [ngClass]="{ Passright: IsUpper }">
                  check_circle_outline
                </span>
                1 Uppercase letter
              </li>
              <li>
                <span class="material-icons" [ngClass]="{ Passright: IsSmall }">
                  check_circle_outline
                </span>
                1 lowercase letter
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button class="sub-btn" (click)="submit()">Submit</button>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
