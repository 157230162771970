import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import * as XLSX from 'xlsx';
import { PracticeService } from '../../services/practice/practice.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ExcelService } from 'src/app/services/excelservices/excel.service';

@Component({
  selector: 'app-practiceappque',
  templateUrl: './practiceappque.component.html',
  styleUrls: ['./practiceappque.component.scss'],
})
export class PracticeappqueComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'free_paid',
    'domin_name',
    'subdomain_name',
    'topic_name',
    'title',
    'first_choice',
    'second_choice',
    'third_choice',
    'fourth_choice',
    'type',
    'actions',
  ];
  dataSource: MatTableDataSource<courselist>;
  Search: any;
  addPracticeQua: boolean = false;
  id: any;
  EditHideen = true;
  public PraacticeQueForm: FormGroup;
  Free_paid: any;
  Domainname: any;
  subdomainname: any;
  Topicname: any;
  title: any;
  option1: any;
  option2: any;
  option3: any;
  option4: any;
  CorrectAnswer: any;
  Type: any;
  Explanation: any;
  ListCerficateData = [];
  Domain = [];
  SubDomain = [];
  Topic = [];
  Certificate: any;
  filtersubDomain = [];
  filterTpoic = [];
  filterDomain = [];
  editdata: any;

  loglist = [];
  isBulkUpload: boolean = false;
  files: File[] = [];
  myEvent: any;
  progress: any = 0;
  selectBulkupload: boolean = false;
  selectprogress: boolean = false;
  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Question: '',
    'Answer A': '',
    'Answer B': '',
    'Answer C': '',
    'Answer D': '',
    Answer: '',
    Explanation: '',
    'Free/Paid': '',
    Difficulty: '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Question: '',
    'Answer A': '',
    'Answer B': '',
    'Answer C': '',
    'Answer D': '',
    Answer: '',
    Explanation: '',
    'Free/Paid': '',
    Difficulty: '',
  };
  auditHistory = '';
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public practiceService: PracticeService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    private excelService: ExcelService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  addNewPracticeQua() {
    this.addPracticeQua = true;
    this.editdata = '';
    this.EditHideen = false;
    this.Free_paid = '';
    this.Domainname = '';
    this.subdomainname = '';
    this.Topicname = '';
    this.title = '';
    this.option1 = '';
    this.option2 = '';
    this.option3 = '';
    this.option4 = '';
    this.CorrectAnswer = '';
    this.Type = '';
    this.Explanation = '';
    this.Certificate = '';
    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      'Answer A': '',
      'Answer B': '',
      'Answer C': '',
      'Answer D': '',
      Answer: '',
      Explanation: '',
      'Free/Paid': '',
      Difficulty: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      'Answer A': '',
      'Answer B': '',
      'Answer C': '',
      'Answer D': '',
      Answer: '',
      Explanation: '',
      'Free/Paid': '',
      Difficulty: '',
    };
    this.auditHistory = '';
  }
  closeModal() {
    this.addPracticeQua = false;
    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      'Answer A': '',
      'Answer B': '',
      'Answer C': '',
      'Answer D': '',
      Answer: '',
      Explanation: '',
      'Free/Paid': '',
      Difficulty: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      'Answer A': '',
      'Answer B': '',
      'Answer C': '',
      'Answer D': '',
      Answer: '',
      Explanation: '',
      'Free/Paid': '',
      Difficulty: '',
    };
    this.auditHistory = '';
  }

  async ngOnInit() {
    this.PraacticeQueForm = this.fb.group({
      Free_paid: ['', Validators.required],
      Domainname: ['', Validators.required],
      subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      title: ['', Validators.required],
      option1: ['', Validators.required],
      option2: ['', Validators.required],
      option3: ['', Validators.required],
      option4: ['', Validators.required],
      CorrectAnswer: ['', Validators.required],
      Type: ['', Validators.required],
      Explanation: ['', Validators.required],
      Certificate: ['', Validators.required],
    });
    await this.spinner.show();
    await this.listPracticalQues();
    await this.GetAllDropdown();

    await this.spinner.hide();
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();

              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.practiceService
                .DeletePracticeAppQuestion(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    await this.listPracticalQues();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Question has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    var desc =
                      '<span> ' +
                      item.title +
                      '</span> question is added by <span> ' +
                      e +
                      '</span>';
                    await this.service.addAdminLogs(
                      desc,
                      'practiceappque',
                      item.id,
                      token,
                    );
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async listPracticalQues() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: courselist[] = [];
        await this.practiceService
          .ListPracticeAppQuestion(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                await users.push({
                  srno: srno,
                  id: element.id,
                  test_id: element.test_id,
                  title: element.title,
                  first_choice: element.first_choice,
                  second_choice: element.second_choice,
                  third_choice: element.third_choice,
                  fourth_choice: element.fourth_choice,
                  answer: element.answer,
                  type: element.type,
                  d_id: element.d_id,
                  topic_id: element.topic_id,
                  ref_link: element.ref_link,
                  youtube_link: element.youtube_link,
                  bookpage_no: element.bookpage_no,
                  blog_url: element.blog_url,
                  createdAt: element.createdAt,
                  updatedAt: element.updatedAt,
                  domin_name: element.domain.domin_name,
                  subdomain_name: element.subdomain.subdomain_name,
                  topic_name: element.topic.topic_name,
                  free_paid: element.free_paid,
                  certi_id: element.certi_id,
                  sd_id: element.sd_id,
                  explanation: element.explanation,
                  certificate_name: element.certificate.certificate_name,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
              this.pagination = true;
            }
          });
      }
    });
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('practiceappque', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.addPracticeQua = true;
    this.editdata = item;
    this.EditHideen = true;
    this.Free_paid = item.free_paid;
    this.Domainname = item.d_id;
    this.subdomainname = item.sd_id;
    this.Topicname = item.topic_id;
    this.title = item.title;
    this.option1 = item.first_choice;
    this.option2 = item.second_choice;
    this.option3 = item.third_choice;
    this.option4 = item.fourth_choice;
    this.CorrectAnswer = item.answer;
    this.Type = item.type;
    this.Explanation = item.explanation;
    this.Certificate = item.certi_id;

    this.Domain = this.filterDomain.filter(
      (x) => x.certi_id == this.Certificate,
    );
    this.SubDomain = this.filtersubDomain.filter(
      (x) => x.d_id == this.Domainname,
    );
    this.Topic = this.filterTpoic.filter((x) => x.sd_id == this.subdomainname);
    this.oldAuditValueArray = {
      Certificate: this.ListCerficateData.find((x) => x.id == this.Certificate)
        ? this.ListCerficateData.find((x) => x.id == this.Certificate)
            .certificate_name
        : '',
      Domain: this.filterDomain.find((x) => x.id == this.Domainname)
        ? this.filterDomain.find((x) => x.id == this.Domainname).domin_name
        : '',
      Subdomain: this.filtersubDomain.find((x) => x.id == this.subdomainname)
        ? this.filtersubDomain.find((x) => x.id == this.subdomainname)
            .subdomain_name
        : '',
      Topic: this.filterTpoic.find((x) => x.id == this.Topicname)
        ? this.filterTpoic.find((x) => x.id == this.Topicname).topic_name
        : '',
      Question: this.title,
      'Answer A': this.option1,
      'Answer B': this.option2,
      'Answer C': this.option3,
      'Answer D': this.option4,
      Answer:
        this.CorrectAnswer == 1
          ? 'Answer A'
          : this.CorrectAnswer == 2
          ? 'Answer B'
          : this.CorrectAnswer == 3
          ? 'Answer C'
          : this.CorrectAnswer == 4
          ? 'Answer D'
          : '',
      Explanation: this.Explanation,
      'Free/Paid': this.Free_paid,
      Difficulty: this.Type,
    };
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  upload(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = jsonData;
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          this.service
            .bulkuploded(dataString.Sheet1, 'PracticeAppque', token)
            .then((set) => {});
        }
      });
    };
    reader.readAsBinaryString(file);
  }
  async GetAllDropdown() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCerficateData = list.data.Certificate;
            this.Topic = list.data.Topic;
            this.SubDomain = list.data.SubDomain;
            this.Domain = list.data.Domain;
            this.filtersubDomain = list.data.SubDomain;
            this.filterTpoic = list.data.Topic;
            this.filterDomain = list.data.Domain;
            this.Domainname = '';
            this.subdomainname = '';
            this.Topicname = '';
            this.title = '';
            this.option1 = '';
            this.option2 = '';
            this.option3 = '';
            this.option4 = '';
            this.CorrectAnswer = '';
            this.Type = '';
          }
        });
      }
    });
  }
  async certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
      this.Domain = this.filterDomain;
    }
  }

  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
      this.SubDomain = this.filtersubDomain;
    }
    this.subdomainname = '';
    this.Topicname = '';
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return (
          itemset.sd_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.Topicname = '';
    } else {
      this.Topicname = '';
      this.Topic = this.filterTpoic;
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async onSubmit() {
    this.markFormTouched(this.PraacticeQueForm);
    if (this.PraacticeQueForm.valid) {
      await this.spinner.show();
      return this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.practiceService
              .AddPracticeAppQuestion(
                this.Free_paid,
                this.Topicname,
                this.title,
                this.option1,
                this.option2,
                this.option3,
                this.option4,
                this.CorrectAnswer,
                this.Type,
                this.Domainname,
                this.subdomainname,
                this.Topicname,
                this.Certificate,
                1,
                this.Explanation,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  await this.listPracticalQues();
                  var e = window.localStorage.getItem('adminUser');
                  if (e == null || e == undefined || e == '') {
                    e = 'admin';
                  }
                  var desc =
                    '<span> ' +
                    this.title +
                    '</span> question is added by <span> ' +
                    e +
                    '</span>';
                  await this.service.addAdminLogs(
                    desc,
                    'practiceappque',
                    list.data.id,
                    token,
                  );
                  this.addPracticeQua = false;
                  await this.spinner.hide();
                  this.service.ShowSuccess(list.message);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }

  async Update() {
    this.markFormTouched(this.PraacticeQueForm);
    if (this.PraacticeQueForm.valid) {
      await this.spinner.show();
      return this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.practiceService
              .UpdatePracticeAppQuestion(
                this.Free_paid,
                this.Topicname,
                this.title,
                this.option1,
                this.option2,
                this.option3,
                this.option4,
                this.CorrectAnswer,
                this.Type,
                this.Domainname,
                this.subdomainname,
                this.Topicname,
                this.editdata.id,
                this.Certificate,
                1,
                this.Explanation,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  await this.listPracticalQues();
                  var token = session.getIdToken().getJwtToken();
                  var e = window.localStorage.getItem('adminUser');
                  if (e == null || e == undefined || e == '') {
                    e = 'admin';
                  }
                  this.newAuditValueArray = {
                    Certificate: this.ListCerficateData.find(
                      (x) => x.id == this.Certificate,
                    )
                      ? this.ListCerficateData.find(
                          (x) => x.id == this.Certificate,
                        ).certificate_name
                      : '',
                    Domain: this.filterDomain.find(
                      (x) => x.id == this.Domainname,
                    )
                      ? this.filterDomain.find((x) => x.id == this.Domainname)
                          .domin_name
                      : '',
                    Subdomain: this.filtersubDomain.find(
                      (x) => x.id == this.subdomainname,
                    )
                      ? this.filtersubDomain.find(
                          (x) => x.id == this.subdomainname,
                        ).subdomain_name
                      : '',
                    Topic: this.filterTpoic.find((x) => x.id == this.Topicname)
                      ? this.filterTpoic.find((x) => x.id == this.Topicname)
                          .topic_name
                      : '',
                    Question: this.title,
                    'Answer A': this.option1,
                    'Answer B': this.option2,
                    'Answer C': this.option3,
                    'Answer D': this.option4,
                    Answer:
                      this.CorrectAnswer == 1
                        ? 'Answer A'
                        : this.CorrectAnswer == 2
                        ? 'Answer B'
                        : this.CorrectAnswer == 3
                        ? 'Answer C'
                        : this.CorrectAnswer == 4
                        ? 'Answer D'
                        : '',
                    Explanation: this.Explanation,
                    'Free/Paid': this.Free_paid,
                    Difficulty: this.Type,
                  };
                  var audit = this.auth.compareAuditHist(
                    this.oldAuditValueArray,
                    this.newAuditValueArray,
                  );
                  var desc = audit;
                  await this.service.addAdminLogs(
                    desc,
                    'practiceappque',
                    this.editdata.id,
                    token,
                  );
                  this.addPracticeQua = false;
                  await this.spinner.hide();
                  this.service.ShowSuccess('Question update successfully');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async bulkCheckDKIM(data) {
    let response = 0;
    data.forEach(async (element) => {
      let domain = element.email.split('@')[1];
      await this.auth.verifyDKIM(domain).then(async (result) => {
        if (result[0].StatusCode == 1) {
          response = 1;
        }
      });
    });
    return response;
  }
  async openBulkUploadModal() {
    this.isBulkUpload = true;

    return console.log({
      certData: this.ListCerficateData,
      domain: this.Domain,
      subdomain: this.SubDomain,
      topic: this.Topic,
    });
  }
  async closeBulkUploadModal() {
    this.isBulkUpload = false;
    this.progress = 0;
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.files = [];
    this.myEvent = '';
  }
  async bulkUpload() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.myEvent.addedFiles[0];
    console.log({ file });
    if (file) {
      reader.onload = async (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        console.log(jsonData);
        if (jsonData.hasOwnProperty('Sheet1')) {
          await this.addBulkQuestions(jsonData.Sheet1);
        } else {
          console.error(`There's an issue with the CSV File. Sheet1 expected.`);
        }
      };

      reader.readAsBinaryString(file);
    } else {
      console.error('No valid file selected.');
    }
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.selectBulkupload = true;
    this.selectprogress = false;
  }
  onSelect(event) {
    let me = this;
    var timeleft = 10;
    me.progress = 0;
    me.selectprogress = true;
    me.selectBulkupload = true;
    this.files.push(event.addedFiles);
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        me.progress = 10 - timeleft;
        me.myEvent = event;
        me.selectBulkupload = false;
        me.selectprogress = false;
        clearInterval(downloadTimer);
      }
      me.progress = 10 - timeleft;
      timeleft -= 1;
    }, 200);
  }

  ExportExcel() {
    var certification = Object.assign(
      this.ListCerficateData.map((k) => ({ name: k.certificate_name })),
    );
    var domain = Object.assign(
      this.Domain.map((k) => ({ name: k.domin_name })),
    );
    var subdomain = Object.assign(
      this.SubDomain.map((k) => ({ name: k.subdomain_name })),
    );
    var topic = Object.assign(this.Topic.map((k) => ({ name: k.topic_name })));
    var Free = [
      {
        workSheet: 'Free',
        rows: [{ name: 'free' }, { name: 'paid' }],
        properties: { state: 'hidden' },
      },
    ];
    var Correct = [
      {
        workSheet: 'Correct',
        rows: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }],
        properties: { state: 'hidden' },
      },
    ];
    var Type = [
      {
        workSheet: 'Type',
        rows: [
          { name: 'VERY SIMPLE' },
          { name: 'SIMPLE' },
          { name: 'AVERAGE' },
          { name: 'ABOVE AVERAGE' },
          { name: 'HARD' },
          { name: 'DIFFICULT' },
          ,
          { name: 'TRICKY' },
        ],
        properties: { state: 'hidden' },
      },
    ];
    var certificationData = [
      {
        workSheet: 'certification',
        rows: certification,
        properties: { state: 'hidden' },
      },
    ];
    var domainData = [
      {
        workSheet: 'domain',
        rows: domain,
        properties: { state: 'hidden' },
      },
    ];
    var subdomainData = [
      {
        workSheet: 'subdomain',
        rows: subdomain,
        properties: { state: 'hidden' },
      },
    ];
    var topicData = [
      {
        workSheet: 'topic',
        rows: topic,
        properties: { state: 'hidden' },
      },
    ];
    var Practice_App_Question_sample = [
      {
        name: 'Practice_App_Question_sample',
        values: [
          {
            header: 'certification',
            value: [
              { name: 'certification!$A$2:$A$' + (certification.length + 1) },
            ],
          },
          {
            header: 'domain',
            value: [{ name: 'domain!$A$2:$A$' + (domain.length + 1) }],
          },
          {
            header: 'subdomain',
            value: [{ name: 'subdomain!$A$2:$A$' + (subdomain.length + 1) }],
          },
          {
            header: 'topic',
            value: [{ name: 'topic!$A$2:$A$' + (topic.length + 1) }],
          },
          { header: 'question', value: '' },
          { header: 'answer_a', value: '' },
          { header: 'answer_b', value: '' },
          { header: 'answer_c', value: '' },
          { header: 'answer_d', value: '' },
          { header: 'correct_answer', value: [{ name: 'Correct!$A$2:$A$5' }] },
          { header: 'difficult_type', value: [{ name: 'Type!$A$2:$A$8' }] },
          { header: 'Free/Paid', value: [{ name: 'Free!$A$2:$A$3' }] },
          { header: 'explanation', value: '' },
        ],
        properties: { state: 'visible' },
      },
    ];
    var workbookData = [
      this.transform(Practice_App_Question_sample)[0],
      certificationData[0],
      domainData[0],
      subdomainData[0],
      topicData[0],
      Correct[0],
      Type[0],
      Free[0],
    ];
    this.excelService.exportAsExcelFile(
      workbookData,
      Practice_App_Question_sample[0].name,
    );
  }
  transform(data) {
    const noOfRowaToGenerate = 5;
    return data.map(({ name, values, properties }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value)
            ? next.value.map(({ name }) => name)
            : next.value,
        }),
        {},
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers),
        properties: properties,
      };
    });
  }

  async addBulkQuestions(questionList: any[]) {
    await this.spinner.show();
    return this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        const token = session.getIdToken().getJwtToken();

        const finalQuestionList = [];
        try {
          questionList.forEach((questionItem) => {
            const certificateData = this.ListCerficateData.filter(
              (item) => item.certificate_name === questionItem.certification,
            );

            if (certificateData.length === 0) {
              throw new Error(
                `Error! Certification title (${questionItem.certification}) not found. Aborting import.`,
              );
            }

            const domain = this.Domain.filter(
              (item) =>
                item.domin_name === questionItem.domain &&
                item.certi_id === certificateData[0].id,
            );

            if (domain.length === 0) {
              throw new Error(
                `Error! Domain title (${questionItem.domain}) not found. Aborting import.`,
              );
            }

            const subdomain = this.SubDomain.filter(
              (item) =>
                item.subdomain_name === questionItem.subdomain &&
                item.d_id === domain[0].id &&
                item.certi_id === certificateData[0].id,
            );

            if (subdomain.length === 0) {
              throw new Error(
                `Error! Subdomain title (${questionItem.subdomain}) not found. Aborting import.`,
              );
            }
            const topic = this.Topic.filter(
              (item) =>
                item.topic_name === questionItem.topic &&
                item.sd_id === subdomain[0].id &&
                item.d_id === domain[0].id &&
                item.certi_id === certificateData[0].id,
            );

            if (topic.length === 0) {
              throw new Error(`Error! Topic title (${questionItem.topic}) not found. Aborting import.`);
            }

            console.log({
              certificateData,
              domain,
              subdomain,
              topic,
            });

            const finalData = {
              ...questionItem,
              certificateId: certificateData[0].id,
              domainId: domain[0].id,
              subdomainId: subdomain[0].id,
              topicId: topic[0].id,
            };

            console.log({ finalData });

            finalQuestionList.push(finalData);
          });

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < finalQuestionList.length; i++) {
            const questionItem = finalQuestionList[i];

            console.log({ questionItem });

            await this.practiceService
              .AddPracticeAppQuestion(
                'free',
                '',
                questionItem.question,
                questionItem.answer_a,
                questionItem.answer_b,
                questionItem.answer_c,
                questionItem.answer_d,
                questionItem.correct_answer,
                'AVERAGE',
                questionItem.domainId,
                questionItem.subdomainId,
                questionItem.topicId,
                questionItem.certificateId,
                1,
                questionItem.explanation,
                token,
              )
              .then(async (value) => {
                if (value[0].status !== 200) {
                  const list = JSON.parse(<string>value[0].json);
                  throw new Error(list.message);
                }
              })
              .catch((error) => {
                throw error;
              });
          }
          await this.spinner.hide();
          this.service.ShowSuccess('Bulk import succeeded!');
          this.router.navigate(['main/practiceappques']);
        } catch (error) {
          await this.spinner.hide();
          console.error(error);
          this.service.ShowError(error.message);
        }
      }
    });
  }
}

export interface courselist {
  srno: number;
  id: string;
  test_id: string;
  title: string;
  first_choice: string;
  second_choice: string;
  third_choice: string;
  fourth_choice: string;
  answer: string;
  type: string;
  d_id: string;
  topic_id: string;
  ref_link: string;
  youtube_link: string;
  bookpage_no: string;
  blog_url: string;
  createdAt: string;
  updatedAt: string;
  domin_name: string;
  subdomain_name: string;
  topic_name: string;
  free_paid: string;
  certi_id: string;
  sd_id: string;
  explanation: string;
  certificate_name: string;
}
