<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>App Versions</h3>
    </div>
  </div>

  <div class="pagesection">
    <div class="versions">
      <div class="apps">
        <div class="flashcard">
          <div class="text">
            <p>Flashcard CISSP</p>
          </div>
          <div class="controls">
            <p>Android</p>
            <input
              type="text"
              placeholder="Enter app version"
              [(ngModel)]="version_android"
            />
            <button (click)="updateAllAppVersion()">Save</button>
          </div>
        </div>
      </div>
      <div class="apps">
        <div class="flashcard">
          <div class="text">
            <p>Flashcard CISSP</p>
          </div>
          <div class="controls">
            <p>iOS</p>
            <input
              type="text"
              placeholder="Enter app version"
              [(ngModel)]="version_ios"
            />
            <button (click)="updateAllAppVersion()">Save</button>
          </div>
        </div>
      </div>
      <div class="apps">
        <div class="flashcard">
          <div class="text">
            <p>Flashcard CCSP</p>
          </div>
          <div class="controls">
            <p>Android</p>
            <input
              type="text"
              placeholder="Enter app version"
              [(ngModel)]="version_android_ccsp"
            />
            <button (click)="updateAllAppVersion()">Save</button>
          </div>
        </div>
      </div>
      <div class="apps">
        <div class="flashcard">
          <div class="text">
            <p>Flashcard CCSP</p>
          </div>
          <div class="controls">
            <p>iOS</p>
            <input
              type="text"
              placeholder="Enter app version"
              [(ngModel)]="version_ios_ccsp"
            />
            <button (click)="updateAllAppVersion()">Save</button>
          </div>
        </div>
      </div>
      <div class="apps">
        <div class="flashcard">
          <div class="text">
            <p>CISSP Questions</p>
          </div>
          <div class="controls">
            <p>Android</p>
            <input
              type="text"
              placeholder="Enter app version"
              [(ngModel)]="version_android_practice"
            />
            <button (click)="updateAllAppVersion()">Save</button>
          </div>
        </div>
      </div>
      <div class="apps">
        <div class="flashcard">
          <div class="text">
            <p>CISSP Questions</p>
          </div>
          <div class="controls">
            <p>iOS</p>
            <input
              type="text"
              placeholder="Enter app version"
              [(ngModel)]="version_ios_practice"
            />
            <button (click)="updateAllAppVersion()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
