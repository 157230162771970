<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Recordings</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Event Name, Directory Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <span>{{ i + 1 }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Event Title</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.title }}">{{ row.title }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.createdAt | date: 'medium' }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              alt="Edit"
              (click)="edit(row)"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="showModal">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="IsUpdate">Edit Recording URL</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="RecordingForm">
          <div>
            <input
              [ngClass]="{
                err:
                  RecordingForm.controls['recording_url'].hasError(
                    'required'
                  ) &&
                  (RecordingForm.controls['recording_url'].dirty ||
                    RecordingForm.controls['recording_url'].touched)
              }"
              placeholder="Recording URL 1080p"
              formControlName="recording_url"
              [(ngModel)]="recording_url"
              required
            />
            <!-- transcoder url -->
            <input
              placeholder="Recording URL 720p"
              formControlName="recording_url_720p"
              [(ngModel)]="recording_url_720p"
            />
            <input
              placeholder="Recording URL 480p"
              formControlName="recording_url_480p"
              [(ngModel)]="recording_url_480p"
            />
            <input
              placeholder="Recording URL 360p"
              formControlName="recording_url_360p"
              [(ngModel)]="recording_url_360p"
            />
            <input
              placeholder="Recording URL 240p"
              formControlName="recording_url_240p"
              [(ngModel)]="recording_url_240p"
            />
            <input
              placeholder="Recording URL 144p"
              formControlName="recording_url_144p"
              [(ngModel)]="recording_url_144p"
            />
          </div>
        </form>
        <div>
          <span class="transcripts">Questions Asked</span>
          <div
            class="transcript-div"
            *ngFor="let item of Questions; let i = index"
          >
            <div class="main-transcript">
              <div class="transcript-textaria">
                <textarea
                  [(ngModel)]="item.Text"
                  placeholder="Type here..."
                ></textarea>
              </div>
              <div class="transcript-input">
                <input
                  [(ngModel)]="item.FromTime"
                  type="text"
                  placeholder="00:00"
                  (keyup)="validateFromTime(item, $event)"
                />
              </div>
              <div
                *ngIf="i > 0"
                class="transcript-delete"
                (click)="deleteQuestion(item)"
              >
                <img
                  src="../../../assets/images/remove.png"
                  alt="Delete"
                  class="action-icon"
                />
              </div>
            </div>
            <div class="add-new" (click)="addQuestion()">
              <span class="material-icons"> add </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <button
        [disabled]="!RecordingForm.valid"
        (click)="updateRecordingURL()"
        class="submit-btn"
        *ngIf="IsUpdate"
      >
        Save
      </button>
    </div>
  </div>
</div>
