import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { CourseService } from '../../services/course/course.service';
import { DomainService } from '../../services/domain/domain.service';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss'],
})
export class DomainsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'domain_number',
    'domin_name',
    'certificate_name',
    'actions',
  ];
  dataSource: MatTableDataSource<Domainlist>;
  Search: any;
  addDomain: boolean = false;
  IsUpdate: boolean = false;
  ListCerficateData = [];
  CertificateName: any;
  DomainName: any;
  DomainNumber: any;
  id: any;
  public DomainForm: FormGroup;
  oldAuditValueArray = {
    'Domain Name': '',
    'Domain Number': '',
    'Certificate Name': '',
  };
  newAuditValueArray = {
    'Domain Name': '',
    'Domain Number': '',
    'Certificate Name': '',
  };
  auditHistory = '';
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  loglist = [];

  constructor(
    public domainService: DomainService,
    public courseService: CourseService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewDomain() {
    this.addDomain = true;
    this.IsUpdate = false;
    this.DomainName = '';
    this.DomainNumber = '';
    this.CertificateName = '';
  }
  closeModal() {
    this.addDomain = false;
    this.IsUpdate = false;
    this.DomainName = '';
    this.DomainNumber = '';
    this.CertificateName = '';
    this.oldAuditValueArray = {
      'Domain Name': '',
      'Domain Number': '',
      'Certificate Name': '',
    };
    this.newAuditValueArray = {
      'Domain Name': '',
      'Domain Number': '',
      'Certificate Name': '',
    };
    this.auditHistory = '';
  }
  async ngOnInit() {
    this.DomainForm = this.fb.group({
      DomainName: ['', Validators.required],
      DomainNumber: ['', Validators.required],
      CertificateName: ['', Validators.required],
    });
    await this.spinner.show();
    try {
      await this.listDomain();
      await this.ListCertificate();
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }

  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.domainService
                  .DeleteDomain(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();

                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              'Domain is deleted by <span> ' + e + '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'domains',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.listDomain();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Domain has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
  async listDomain() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Domainlist[] = [];
        await this.domainService.ListDomain(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                domin_name: element.domin_name,
                domain_number: element.domain_number,
                certi_id: element.certi_id,
                status: element.status,
                createdAt: element.createdAt,
                certificate_name: element.certificate.certificate_name,
                updatedAt: element.updatedAt,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  async SaveCohort() {
    this.markFormTouched(this.DomainForm);
    if (this.DomainForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.domainService
            .AddDomain(
              this.DomainNumber,
              this.DomainName,
              this.CertificateName,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc = 'Domain is added by <span> ' + e + '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'domains',
                        list.data.id,
                        token,
                      );
                    }
                  });
                await this.listDomain();
                this.IsUpdate = false;
                this.addDomain = false;
                await this.spinner.hide();
                this.service.ShowSuccess('Domain add successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async UpdateCohort() {
    this.markFormTouched(this.DomainForm);
    if (this.DomainForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.domainService
            .UpdateDomain(
              this.DomainName,
              this.DomainNumber,
              this.CertificateName,
              this.id,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();

                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      this.newAuditValueArray = {
                        'Domain Name': this.DomainName,
                        'Domain Number': this.DomainNumber,
                        'Certificate Name': this.ListCerficateData.find(
                          (x) => x.id === this.CertificateName,
                        )
                          ? this.ListCerficateData.find(
                              (x) => x.id === this.CertificateName,
                            ).certificate_name
                          : '',
                      };
                      var audit = this.auth.compareAuditHist(
                        this.oldAuditValueArray,
                        this.newAuditValueArray,
                      );
                      var desc = audit;
                      if (desc != '')
                        await this.service.addAdminLogs(
                          desc,
                          'domains',
                          this.id,
                          token,
                        );
                    }
                  });
                await this.listDomain();
                this.IsUpdate = false;
                this.addDomain = false;
                await this.spinner.hide();
                this.service.ShowSuccess('Domain update successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCerficateData = list.data;
            this.CertificateName = '';
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('domains', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.IsUpdate = true;
    this.addDomain = true;
    this.CertificateName = item.certi_id;
    this.DomainNumber = item.domain_number;
    this.DomainName = item.domin_name;
    this.id = item.id;
    this.oldAuditValueArray = {
      'Domain Name': this.DomainName,
      'Domain Number': this.DomainNumber,
      'Certificate Name': this.ListCerficateData.find(
        (x) => x.id === this.CertificateName,
      )
        ? this.ListCerficateData.find((x) => x.id === this.CertificateName)
            .certificate_name
        : '',
    };
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface Domainlist {
  srno: number;
  id: number;
  domin_name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  certificate_name: string;
  domain_number: string;
  certi_id: string;
}
