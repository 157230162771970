<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Variables</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Template/Subject Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewsubTopic()">Add Variables</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.srno }}">{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Variables Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.name }}">{{ row.name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="query_text">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Query</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.query_text }}">{{ row.query_text }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="editVariable(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addsubTopic">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Variables</p>
      <p *ngIf="IsUpdate">Edit Variables</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="VariableForm">
          <div>
            <input
              [ngClass]="{
                err:
                  VariableForm.controls['name'].hasError('required') &&
                  (VariableForm.controls['name'].dirty ||
                    VariableForm.controls['name'].touched)
              }"
              placeholder="Variables Name"
              formControlName="name"
              [(ngModel)]="name"
              required
            />
          </div>
          <div>
            <textarea
              placeholder="Query"
              formControlName="query_text"
              [(ngModel)]="query_text"
              required
              [ngClass]="{
                err:
                  VariableForm.controls['query_text'].hasError('required') &&
                  (VariableForm.controls['query_text'].dirty ||
                    VariableForm.controls['query_text'].touched)
              }"
            ></textarea>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div>
        <button
          [disabled]="!VariableForm.valid"
          class="submit-btn"
          (click)="saveVariable()"
          *ngIf="!IsUpdate"
        >
          Save
        </button>
        <button
          [disabled]="!VariableForm.valid"
          class="submit-btn"
          (click)="updateVariable()"
          *ngIf="IsUpdate"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
