import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../service/authorization.service';
import { AnalysesService } from '../services/analyses/analyses.service';
@Component({
  selector: 'app-user-analysis',
  templateUrl: './user-analysis.component.html',
  styleUrls: ['./user-analysis.component.scss'],
})
export class UserAnalysisComponent implements OnInit {
  id: any;
  practicetestlist = [];
  Newpracticelist = [];
  totalcard = 0;
  totalleft = 0;
  totalright = 0;
  mark = 0;
  knowass = [];
  LoginHistory = [];
  ExamHistoryData = [];
  constructor(
    public analysesService: AnalysesService,
    public ActiveRoute: ActivatedRoute,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    } else {
      this.ActiveRoute.params.subscribe(async (params) => {
        try {
          this.id = params['id'];
        } catch (error) {
          this.router.navigate(['/main/liveclass']);
        }
      });
    }
  }
  async ngOnInit() {
    await this.spinner.show();
    await this.listcateexam();
    await this.listPracticetest();
    await this.getAggregatedta();
    await this.knowassreport();
    await this.getUserLoginHistory();
    await this.ExamHistory();
    await this.spinner.hide();
  }
  async listcateexam() {
    this.practicetestlist = [];
    try {
      var authenticatedUser = this.auth.getAuthenticatedUser();
      if (authenticatedUser == null) {
        this.router.navigate(['/login']);
      } else {
        await authenticatedUser.getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          }
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.analysesService
                  .cateexamreportpracticetestAns(this.id, token)
                  .then(async (data) => {
                    if (data[0].status == 200) {
                      var list = JSON.parse(<string>data[0].json);
                      this.practicetestlist = list.data;
                      if (this.practicetestlist.length > 0) {
                        await this.practicetestlist.forEach((element) => {
                          element['Perntage'] =
                            ((element.currect + element.wrong) * 100) /
                              element.total >
                            0
                              ? ((element.currect + element.wrong) * 100) /
                                element.total
                              : '0';
                          element['circlecount'] =
                            ((element.currect + element.wrong) * 100) /
                              element.total >
                            0
                              ? (((element.currect + element.wrong) * 100) /
                                  element.total) *
                                  2 +
                                ', 200'
                              : '0,200';
                          element['CorrectPer'] =
                            (element.currect * 100) / element.total > 0
                              ? (
                                  (element.currect * 100) /
                                  element.total
                                ).toFixed(2)
                              : 0;
                          element['InCorrectPer'] =
                            (element.wrong * 100) / element.total > 0
                              ? ((element.wrong * 100) / element.total).toFixed(
                                  2,
                                )
                              : 0;
                          element['PandingPer'] =
                            (element.panding * 100) / element.total > 0
                              ? (
                                  (element.panding * 100) /
                                  element.total
                                ).toFixed(2)
                              : 0;
                        });
                      }
                    }
                  });
              }
            });
        });
      }
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async listPracticetest() {
    this.Newpracticelist = [];
    try {
      var authenticatedUser = this.auth.getAuthenticatedUser();
      if (authenticatedUser == null) {
        this.router.navigate(['/login']);
      } else {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.analysesService
                .getuserreportpracticetestAns(this.id, token)
                .then(async (data) => {
                  if (data[0].status == 200) {
                    var list = JSON.parse(<string>data[0].json);
                    this.Newpracticelist = list.data;
                    if (this.Newpracticelist.length > 0) {
                      this.Newpracticelist.forEach((element) => {
                        element['Perntage'] =
                          ((element.currect + element.wrong) * 100) /
                            element.total >
                          0
                            ? Math.round(
                                ((element.currect + element.wrong) * 100) /
                                  element.total,
                              )
                            : '0';
                        element['circlecount'] =
                          ((element.currect + element.wrong) * 100) /
                            element.total >
                          0
                            ? (((element.currect + element.wrong) * 100) /
                                element.total) *
                                2 +
                              ', 200'
                            : '0,200';
                        element['CorrectPer'] =
                          (element.currect * 100) / element.total > 0
                            ? ((element.currect * 100) / element.total).toFixed(
                                2,
                              )
                            : 0;
                        element['InCorrectPer'] =
                          (element.wrong * 100) / element.total > 0
                            ? ((element.wrong * 100) / element.total).toFixed(2)
                            : 0;
                        element['PandingPer'] =
                          (element.panding * 100) / element.total > 0
                            ? ((element.panding * 100) / element.total).toFixed(
                                2,
                              )
                            : 0;
                      });
                    }
                  }
                });
            }
          });
      }
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async getAggregatedta() {
    await this.analysesService.getAggregate(this.id).then((data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        this.totalcard = list[0].totalcards;
        this.totalleft = list[0].totallearnmore;
        this.totalright = list[0].totolright;
        this.mark = list[0].totalreviews;
      }
    });
  }
  async knowassreport() {
    this.knowass = [];
    try {
      var authenticatedUser = this.auth.getAuthenticatedUser();
      if (authenticatedUser == null) {
        this.router.navigate(['/login']);
      } else {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.analysesService
                .knowassreport(this.id, token)
                .then(async (data) => {
                  if (data[0].status == 200) {
                    var list = JSON.parse(<string>data[0].json);
                    this.knowass = list.data;
                    if (this.knowass.length > 0) {
                      await this.knowass.forEach((element) => {
                        element['Perntage'] =
                          ((element.currect + element.wrong) * 100) /
                            element.total >
                          0
                            ? ((element.currect + element.wrong) * 100) /
                              element.total
                            : '0';
                        element['circlecount'] =
                          ((element.currect + element.wrong) * 100) /
                            element.total >
                          0
                            ? (((element.currect + element.wrong) * 100) /
                                element.total) *
                                2 +
                              ', 200'
                            : '0,200';
                        element['CorrectPer'] =
                          (element.currect * 100) / element.total > 0
                            ? ((element.currect * 100) / element.total).toFixed(
                                2,
                              )
                            : 0;
                        element['InCorrectPer'] =
                          (element.wrong * 100) / element.total > 0
                            ? ((element.wrong * 100) / element.total).toFixed(2)
                            : 0;
                        element['PandingPer'] =
                          (element.panding * 100) / element.total > 0
                            ? ((element.panding * 100) / element.total).toFixed(
                                2,
                              )
                            : 0;
                      });
                    }
                  }
                });
            }
          });
      }
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async getUserLoginHistory() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.analysesService
          .GetUserLoginHistory(this.id, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.LoginHistory = list.data;
              if (this.LoginHistory.length > 0) {
                this.LoginHistory.forEach((element) => {
                  let dt = new Date(element.createdAt).toLocaleString('en-US', {
                    timeZone: 'America/New_York',
                  });
                  element.createdAt = dt;
                });
              }
            }
          });
      }
    });
  }
  async ExamHistory() {
    this.ExamHistoryData = [];
    try {
      var authenticatedUser = this.auth.getAuthenticatedUser();
      if (authenticatedUser == null) {
        this.router.navigate(['/login']);
      } else {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.analysesService
                .testlogs_history(this.id, token)
                .then(async (data) => {
                  if (data[0].status == 200) {
                    var list = JSON.parse(<string>data[0].json);
                    this.ExamHistoryData = list.data;
                    this.ExamHistoryData.forEach((element) => {
                      let dt = new Date(element.createdAt).toLocaleString(
                        'en-US',
                        { timeZone: 'America/New_York' },
                      );
                      element.createdAt = dt;
                      if (element.test_type == 'CAT') {
                        element['name'] = 'CAT Exam';
                      }
                      if (element.test_type == 'PRACTICE') {
                        element['name'] = 'Practice Test';
                      }
                      if (element.test_type == 'KA') {
                        element['name'] = 'Knowledge Assessment';
                      }
                    });
                  }
                });
            }
          });
      }
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
