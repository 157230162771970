<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Hybrid Templates</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Course Template Name, Certification..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewTemplate()">Add Hybrid Template</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="Srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.Srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="templateName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.templateName }}">{{
              element.templateName
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addTemplate">
  <div class="cutome-modal-content course-template">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Hybrid Template</p>
      <p *ngIf="IsUpdate">Edit hybrid Template</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div class="body-div">
        <div class="course-name">
          <div class="course-input">
            <input
              type="text"
              [(ngModel)]="template_name"
              placeholder="Hybrid Template Name"
            />
          </div>

          <div class="course-input">
            <select
              [(ngModel)]="SelectedCertificate"
              (change)="CertificateChange($event)"
            >
              <option value="">Select Certification</option>
              <option *ngFor="let item of Certificates" value="{{ item.id }}">
                {{ item.certificate_name }}
              </option>
            </select>
          </div>
          <div class="chkboxs">
            <div class="check-app">
              <label class="container">
                Videos
                <input
                  type="checkbox"
                  [(ngModel)]="VideoAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Mindmaps
                <input
                  type="checkbox"
                  [(ngModel)]="MindmapAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Summaries
                <input type="checkbox" [(ngModel)]="SummaryAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                References
                <input type="checkbox" [(ngModel)]="ReferenceAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Knowledge Assessments
                <input
                  type="checkbox"
                  [(ngModel)]="KAAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Tests
                <input
                  type="checkbox"
                  [(ngModel)]="PTAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-app">
              <label class="container">
                CAT Exams (free per attempt)
                <input
                  type="checkbox"
                  [(ngModel)]="CATAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Chat
                <input type="checkbox" [(ngModel)]="LiveChatAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Questions
                <input type="checkbox" [(ngModel)]="LiveQuestionAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Events
                <input
                  type="checkbox"
                  [(ngModel)]="LiveEventAccess"
                  (change)="ChangeSubscriptionLiveEvent()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Flashcard App (full access)
                <input type="checkbox" [(ngModel)]="FashFullAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Question App (full access)
                <input type="checkbox" [(ngModel)]="PraAppFullAccess" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="live-events">
          <div class="course-input" *ngIf="LiveEventAccess">
            <label for="Name">Live Events</label>
            <ng-select
              [items]="LiveEvents"
              bindLabel="event_name"
              bindValue="id"
              placeholder="Select live events"
              appendTo="body"
              multiple="true"
              [(ngModel)]="SelectedEvents"
            >
            </ng-select>
          </div>
          <div class="pricing">
            <h2 class="pricing-title">Add Schedule items</h2>
            <div class="select-pricing">
              <select [(ngModel)]="ScheduleItem">
                <option value="Section">Section</option>
                <option value="Flashcard App">Flashcard App</option>
                <option value="Practice Question App">
                  Practice Question App
                </option>
                <option value="Download">Download</option>
                <option value="video">Video</option>
              </select>
              <button (click)="addScheduleItems()">Add</button>
            </div>
          </div>
          <div class="course-input">
            <!-- <span class="domain-title">Study Material</span> -->
            <div cdkDropList (cdkDropListDropped)="drop($event)">
              <div
                cdkDrag
                *ngFor="let item of StudyMaterialsSub; let i = index"
              >
                <div
                  id="select"
                  class="all-domain"
                  style="
                    border: 1px solid #303563;
                    padding: 5px 10px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    cursor: move;
                    background: #1b1f48;
                  "
                  *ngIf="item.type == 'title' || item.visible_material"
                >
                  <div
                    *ngIf="item.type == 'title'"
                    style="display: flex; width: 100%"
                  >
                    <div style="width: 90%; display: flex; align-items: center">
                      <div style="line-height: 0">
                        <img
                          src="../../../assets/images/sidebar.JPG"
                          alt=""
                          style="width: 13px; line-height: 0"
                        />
                      </div>

                      <div
                        class="domain-name"
                        style="display: flex; align-items: center; width: 100%"
                      >
                        <div
                          class="select-box"
                          style="display: flex; align-items: center; width: 27%"
                        >
                          <img
                            src="../../../assets/images/{{ item.icon }}"
                            alt=""
                            style="margin: 0 10px 0 10px"
                          />
                          <p
                            style="color: #9891d1; margin: 0; margin-right: 5px"
                          >
                            {{ item.title_type }}
                          </p>
                        </div>
                        <span
                          style="color: #9891d1; width: 73%"
                          *ngIf="
                            item.title_type != 'Download' &&
                            item.title_type != 'video'
                          "
                        >
                          <input
                            type="text"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              width: calc(100% - 20px);
                              background: none;
                              color: #8683b2;
                              outline: none;
                              border-radius: 8px;
                              padding: 10px;
                              font-size: 15px;
                            "
                          />
                        </span>
                        <span
                          style="color: #9891d1; width: 73%"
                          *ngIf="item.title_type == 'video'"
                        >
                          <input
                            type="text"
                            placeholder="Title"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="
                              margin-bottom: 5px;
                              border: solid 1px #4a4a80;
                              width: calc(100% - 20px);
                              background: none;
                              color: #8683b2;
                              outline: none;
                              border-radius: 8px;
                              padding: 10px;
                              font-size: 15px;
                            "
                          />
                          <input
                            type="text"
                            placeholder="Description"
                            [(ngModel)]="item.download_description"
                            class="textbox"
                            style="
                              margin-bottom: 5px;
                              border: solid 1px #4a4a80;
                              width: calc(100% - 20px);
                              background: none;
                              color: #8683b2;
                              outline: none;
                              border-radius: 8px;
                              padding: 10px;
                              font-size: 15px;
                            "
                          />
                          <div
                            style="
                              position: relative;
                              align-items: center;
                              justify-content: space-between;
                              display: flex;
                              border-radius: 8px;
                              font-size: 15px;
                              outline: none;
                              border: 1px solid #4a4a80;
                              background: none;
                              width: calc(100% - 20px);
                              box-sizing: border-box;
                              color: #8d87c5;
                            "
                          >
                            <span style="margin-left: 10px">{{
                              item.download_url.substring(
                                54,
                                item.download_url.length
                              )
                            }}</span>
                            <button
                              style="
                                white-space: nowrap;
                                background: #45287e;
                                border: none;
                                outline: none;
                                color: #a799e2;
                                padding: 7px 15px;
                                margin: 5px;
                                border-radius: 5px;
                              "
                            >
                              Choose File
                            </button>
                            <input
                              style="position: absolute; opacity: 0"
                              type="file"
                              #myInputFile
                              (click)="myInputFile.value = null"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                            />
                          </div>
                        </span>
                        <span
                          style="color: #9891d1; width: 73%"
                          *ngIf="item.title_type == 'Download'"
                        >
                          <input
                            type="text"
                            placeholder="Title"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="
                              margin-bottom: 5px;
                              border: solid 1px #4a4a80;
                              width: calc(100% - 20px);
                              background: none;
                              color: #8683b2;
                              outline: none;
                              border-radius: 8px;
                              padding: 10px;
                              font-size: 15px;
                            "
                          />
                          <input
                            type="text"
                            placeholder="Description"
                            [(ngModel)]="item.download_description"
                            class="textbox"
                            style="
                              margin-bottom: 5px;
                              border: solid 1px #4a4a80;
                              width: calc(100% - 20px);
                              background: none;
                              color: #8683b2;
                              outline: none;
                              border-radius: 8px;
                              padding: 10px;
                              font-size: 15px;
                            "
                          />
                          <div
                            style="
                              position: relative;
                              align-items: center;
                              justify-content: space-between;
                              display: flex;
                              border-radius: 8px;
                              font-size: 15px;
                              outline: none;
                              border: 1px solid #4a4a80;
                              background: none;
                              width: calc(100% - 20px);
                              box-sizing: border-box;
                              color: #8d87c5;
                            "
                          >
                            <span style="margin-left: 10px">{{
                              item.download_url.substring(
                                54,
                                item.download_url.length
                              )
                            }}</span>
                            <button
                              style="
                                white-space: nowrap;
                                background: #45287e;
                                border: none;
                                outline: none;
                                color: #a799e2;
                                padding: 7px 15px;
                                margin: 5px;
                                border-radius: 5px;
                              "
                            >
                              Choose File
                            </button>
                            <input
                              style="position: absolute; opacity: 0"
                              type="file"
                              #myInputFile
                              (click)="myInputFile.value = null"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        width: 17%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                    >
                      <span
                        (click)="moveup(i)"
                        style="
                          background: #222650;
                          width: 23px;
                          height: 23px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 17px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_upward</mat-icon
                        >
                      </span>
                      <span
                        (click)="movedown(i)"
                        style="
                          background: #222650;
                          width: 23px;
                          height: 23px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 17px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_downward</mat-icon
                        >
                      </span>
                      <span (click)="deleteScheduleItems(item)">
                        <mat-icon
                          style="
                            border: solid 2px #434473;
                            border-radius: 50%;
                            font-size: 16px;
                            color: #434473;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: 600;
                            margin-left: 6px;
                            height: 20px;
                            width: 20px;
                          "
                        >
                          clear</mat-icon
                        >
                      </span>
                    </div>
                  </div>

                  <div
                    *ngIf="item.type != 'title' && item.visible_material"
                    style="display: flex; width: 100%"
                  >
                    <div
                      style="
                        width: 40%;
                        display: flex;
                        align-items: flex-start;
                        border-right: 1px solid #303563;
                        padding-top: 10px;
                      "
                    >
                      <div style="margin-right: 20px; line-height: 0">
                        <img
                          src="../../../assets/images/sidebar.JPG"
                          alt=""
                          style="width: 13px; line-height: 0"
                        />
                      </div>
                      <div
                        class="domain-name"
                        style="display: flex; align-items: flex-start"
                      >
                        <img
                          src="../../../assets/images/{{ item.icon }}"
                          alt=""
                          style="margin: 5px 10px 0 10px"
                        />
                        <span style="color: #9891d1; padding-right: 10px">
                          {{ item.name }}</span
                        >
                      </div>
                    </div>
                    <div
                      style="
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        margin-left: 15px;
                      "
                    >
                      <div class="over">
                        <div class="check-app">
                          <label
                            class="container"
                            style="
                              margin: 10px 0;
                              color: #9891d1;
                              position: relative;
                              display: flex;
                              align-items: center;
                            "
                          >
                            <input
                              type="checkbox"
                              [(ngModel)]="item.Assigned"
                            />
                            <span
                              class="checkmark"
                              style="
                                position: absolute;
                                top: 2px;
                                left: 0;
                                height: 15px;
                                width: 15px;
                                border: 1px solid #4e4c7f;
                                border-radius: 5px;
                              "
                            ></span>
                            Assigned
                          </label>
                        </div>
                        <div class="check-app check-app2">
                          <label
                            class="container"
                            style="
                              color: #9891d1;
                              position: relative;
                              display: flex;
                              align-items: center;
                            "
                          >
                            <input
                              type="checkbox"
                              [(ngModel)]="item.Required"
                            />
                            <span
                              class="checkmark"
                              style="
                                position: absolute;
                                top: 2px;
                                left: 0;
                                height: 15px;
                                width: 15px;
                                border: 1px solid #4e4c7f;
                                border-radius: 5px;
                              "
                            ></span>
                            Required
                          </label>
                        </div>
                      </div>
                      <div class="over" style="margin: 0px 26px">
                        <div
                          class="due"
                          [ngClass]="{ opacity: item.Assigned }"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin: 7px 0;
                          "
                        >
                          <label
                            for="Due"
                            style="
                              margin-bottom: 0px;
                              width: 55px;
                              text-align: right;
                              color: #9891d1;
                            "
                            >Due:</label
                          >
                          <input
                            type="number"
                            (keyup)="checkIsDueNumber(item)"
                            [disabled]="!item.Assigned"
                            [(ngModel)]="item.DueNumber"
                            style="
                              width: calc(70% - 20px);
                              background: none;
                              color: #8683b2;
                              outline: none;
                              border-radius: 4px;
                              padding: 4px;
                              font-size: 14px;
                              border: 1px solid #2f2f5e;
                            "
                          />
                        </div>
                        <div
                          class="due due2"
                          [ngClass]="{ opacity: item.Required }"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin: 7px 0;
                          "
                        >
                          <label
                            for="Due"
                            style="margin-bottom: 0px; color: #9891d1"
                            >Overdue:</label
                          >
                          <input
                            type="number"
                            (keyup)="checkIsOverDueNumber(item)"
                            [disabled]="!item.Required"
                            [(ngModel)]="item.OverDueNumber"
                            style="
                              width: calc(70% - 20px);
                              background: none;
                              color: #8683b2;
                              outline: none;
                              border-radius: 4px;
                              padding: 4px;
                              font-size: 14px;
                              border: 1px solid #2f2f5e;
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 10%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                    >
                      <span
                        (click)="moveup(i)"
                        style="
                          background: #222650;
                          width: 23px;
                          height: 23px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_upward</mat-icon
                        >
                      </span>
                      <span
                        (click)="movedown(i)"
                        style="
                          background: #222650;
                          width: 23px;
                          height: 23px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_downward</mat-icon
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button type="button" class="submit-btn" (click)="saveTemplates()">
          Save
        </button>
      </div>
      <div *ngIf="IsUpdate">
        <button type="button" class="submit-btn" (click)="updateTemplates()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
