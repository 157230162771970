import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';
@Injectable({
  providedIn: 'root',
})
export class CatexamService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  async AddCateExamTest(
    ketexamtest,
    course_id,
    certi_id,
    acronums,
    time,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ketexam_name: ketexamtest,
          status: '0',
          course_id: course_id,
          certi_id: certi_id,
          acronums: acronums,
          time: time,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CAT_TEST_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateCateExamTest(
    ketexam_name,
    id,
    course_id,
    certi_id,
    acronums,
    time,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ketexam_name: ketexam_name,
          status: '0',
          course_id: course_id,
          certi_id: certi_id,
          acronums: acronums,
          time: time,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.CAT_TEST + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteCateExamTest(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.CAT_TEST + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListCateExamTest(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CAT_TEST_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }

  //-------start cate exam question----------//
  async AddcateexamQuestion(
    certi_id,
    test_id,
    exam_id,
    title,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
    type,
    d_id,
    sd_id,
    topic_id,
    token,
    point,
    explanation,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          test_id: test_id,
          exam_id: exam_id,
          title: title,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
          type: type,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          ref_link: '',
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: '0',
          point: parseInt(point),
          description: explanation,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CAT_QUES_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdatecateexamQuestion(
    certi_id,
    test_id,
    exam_id,
    title,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
    type,
    d_id,
    sd_id,
    topic_id,
    id,
    token,
    point,
    explanation,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          test_id: test_id,
          exam_id: exam_id,
          title: title,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
          type: type,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          ref_link: '',
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: '0',
          point: parseInt(point),
          description: explanation,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.CAT_QUES + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeletecateexamQuestion(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.CAT_QUES + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListcateexamQuestion(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CAT_QUES_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }
  async IdcateexamQuestion(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.CAT_QUES + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //---------End cate exam question---------//
}
