import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { FormBuilder } from '@angular/forms';
import { PracticeService } from '../../services/practice/practice.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { CatexamService } from '../../services/catexam/catexam.service';
import { KnowledgeService } from '../../services/knowledge/knowledge.service';
import { CourseService } from '../../services/course/course.service';
import { LiveeventsService } from '../../services/liveevents/liveevents.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SelfpacedService } from 'src/app/services/selfpaced/selfpaced.service';
import { HybridtemplateService } from 'src/app/services/hybridtemplate/hybridtemplate.service';

@Component({
  selector: 'app-hybridtemplates',
  templateUrl: './hybridtemplates.component.html',
  styleUrls: ['./hybridtemplates.component.scss'],
})
export class HybridtemplatesComponent implements OnInit {
  displayedColumns: string[] = [
    'Srno',
    'templateName',
    'certificate_name',
    'actions',
  ];
  dataSource: MatTableDataSource<cohortlist>;
  Search: any;
  PopUpshow: boolean = false;
  addTemplate: boolean = false;
  ClientCohort = [];
  IsUpdate: boolean = false;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  Certificates = [];
  SelectedCertificate: any = '';
  template_name: any;
  Id: any;
  KnowledgeAssessments = [];
  PracticeExams = [];
  CatExams = [];
  FilterKnowledgeAssessments = [];
  FilterPracticeExams = [];
  FilterCatExams = [];
  EmailTemplates = [];
  FilterEmailTemplates = [];
  Events = [];
  StartDate = new Date();

  VideoAccess: boolean = false;
  MindmapAccess: boolean = false;
  SummaryAccess: boolean = false;
  ReferenceAccess: boolean = false;
  KAAccess: boolean = false;
  PTAccess: boolean = false;
  CATAccess: boolean = false;
  LiveChatAccess: boolean = false;
  LiveQuestionAccess: boolean = false;
  LiveEventAccess: boolean = false;
  FashFullAccess: boolean = false;
  PraAppFullAccess: boolean = false;
  QuestionAccess: boolean = false;
  FlashcardAccess: boolean = false;
  SelectedEvents = [];
  LiveEvents = [];
  FilterLiveEvents = [];
  Videos = [];
  FilterVideos = [];
  Mindmap = [];
  FilterMindmap = [];
  baselineka = [];
  Filterbaselineka = [];
  SelectedBaselineKA: any;
  SelectedBaselineKAdueDate: any;
  SelectedBaselineKAdueTime: any;
  SelectedBaselineKAOverdueDate: any;
  SelectedBaselineKAOverdueTime: any;
  time_zone = this.auth.currentTimeZone();
  Hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
  StudyMaterialsSub = [];
  bkpStudyMaterialsSub = [];
  SavedStudyMaterialsSub = [];
  ScheduleItem: any;
  constructor(
    public templateService: HybridtemplateService,
    public practiceService: PracticeService,
    public knowledgeService: KnowledgeService,
    public courseService: CourseService,
    private cohortService: CohortService,
    private catTestService: CatexamService,
    public auth: AuthorizationService,
    public router: Router,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public liveservice: LiveeventsService,
    public selfService: SelfpacedService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewTemplate() {
    this.addTemplate = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addTemplate = false;
    this.IsUpdate = false;
    this.template_name = '';
    this.SelectedCertificate = '';
    this.FashFullAccess = false;
    this.PraAppFullAccess = false;
    this.Events = [];
    this.VideoAccess = false;
    this.MindmapAccess = false;
    this.SummaryAccess = false;
    this.ReferenceAccess = false;
    this.KAAccess = false;
    this.PTAccess = false;
    this.CATAccess = false;
    this.LiveChatAccess = false;
    this.LiveQuestionAccess = false;
    this.LiveEventAccess = false;
    this.QuestionAccess = false;
    this.FlashcardAccess = false;
    this.StudyMaterialsSub = [];
    this.SavedStudyMaterialsSub = [];
    this.ChangeSubscriptionMaterial();
    this.ChangeSubscriptionLiveEvent();
    this.Events = [];
    this.SelectedEvents = [];
  }

  async ngOnInit() {
    this.spinner.show();
    await this.getAllCourseTemplate();
    await this.getAllCertificate();
    await this.getAllLiveEvents();
    await this.getAllHybridMaterials();
    await this.spinner.hide();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.StudyMaterialsSub,
      event.previousIndex,
      event.currentIndex,
    );
  }
  private swap(array: any[], x: any, y: any) {
    var b = array[x];
    array[x] = array[y];
    array[y] = b;
  }
  moveup(index: any) {
    if (index >= 1) {
      this.swap(this.StudyMaterialsSub, index, index - 1);
    }
  }
  movedown(index: any) {
    if (index < this.StudyMaterialsSub.length - 1) {
      this.swap(this.StudyMaterialsSub, index, index + 1);
    }
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.templateService
              .DeleteTemplate(item.id, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          item.name +
                          '</span> Hybrid template is deleted by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'templates',
                          item.id,
                          token,
                        );
                      }
                    });
                  await this.getAllCourseTemplate();
                  await this.spinner.hide();
                  Swal.fire(
                    list.message,
                    'Template has been deleted.',
                    'success',
                  );
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      }
    });
  }
  async edit(item) {
    this.addTemplate = true;
    this.IsUpdate = true;
    this.Id = item.id;
    this.template_name = item.templateName;
    this.SelectedCertificate = item.certi_id;
    this.VideoAccess = item.video_access;
    this.MindmapAccess = item.mindmap_access;
    this.SummaryAccess = item.summary_access;
    this.ReferenceAccess = item.reference_access;
    this.KAAccess = item.KA_access;
    this.PTAccess = item.PT_access;
    this.CATAccess = item.CAT_access;
    this.LiveChatAccess = item.livechat_access;
    this.LiveQuestionAccess = item.liveq_access;
    this.LiveEventAccess = item.liveevent_access;
    this.PraAppFullAccess = item.question_access;
    this.FashFullAccess = item.flashcard_app;
    this.StartDate = item.start_date;
    this.SelectedBaselineKA = item.baseline_ka == null ? '' : item.baseline_ka;

    if (item.live_events != null && item.live_events != '') {
      this.SelectedEvents = item.live_events.split(',').map(Number);
    } else {
      this.SelectedEvents = [];
    }
    if (item.events != null) {
      try {
        this.Events = JSON.parse(item.events);
      } catch (error) {
        this.Events = [];
      }
    } else this.Events = [];
    await this.getSavedHybridSchedules(item.id);
    await this.ChangeSubscriptionMaterial();
    await this.editHybridMaterial();
  }
  async getAllCourseTemplate() {
    const users: cohortlist[] = [];
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.templateService.ListTemplates(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                Srno: srno,
                id: element.id,
                templateName: element.name,
                certi_id: element.certi_id,
                cource_id: element.cource_id,
                createdAt: element.createdAt,
                live_events: element.live_events,
                videos: element.videos,
                mindmaps: element.mindmaps,
                knowassessments: element.knowledge_asses,
                practicetests: element.practicetests,
                ketexamtests: element.ketexamtests,
                updatedAt: element.updatedAt,
                certificate_name: element.certificate.certificate_name,
                practice_que_app: element.practice_que_app,
                flashcard_app: element.flashcard_app,
                events: element.events,
                email_templates: element.email_templates,
                video_access: element.video_access,
                mindmap_access: element.mindmap_access,
                summary_access: element.summary_access,
                reference_access: element.reference_access,
                KA_access: element.KA_access,
                PT_access: element.PT_access,
                CAT_access: element.CAT_access,
                livechat_access: element.livechat_access,
                liveq_access: element.liveq_access,
                liveevent_access: element.liveevent_access,
                start_date: element.start_date,
                question_access: element.question_access,
                flashcard_access: element.flashcard_access,
                baseline_ka: element.baseline_ka,
                baseline_ka_schedule: element.baseline_ka_schedule,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  showClientPopup(element) {
    this.PopUpshow = true;
    this.ClientCohort = element.practice_time[0];
  }

  async addEvents() {
    let isAdd = true;
    this.Events.forEach((element) => {
      if (element.event_name == '') {
        isAdd = false;
      }
    });
    if (isAdd) {
      this.Events.push({
        event_name: '',
        event_date: '',
        event_time: '',
        minutes: '',
        url: '',
      });
    }
  }
  async deleteEvents(item) {
    const index = this.Events.indexOf(item);
    if (index > -1) {
      this.Events.splice(index, 1);
    }
  }
  async getAllCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificates = list.data;
            this.SelectedCertificate = '';
          }
        });
      }
    });
  }
  async CertificateChange(ev) {
    this.spinner.show();
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.StudyMaterialsSub = this.bkpStudyMaterialsSub.filter(
        (x) => x.certi_id == val,
      );
      this.StudyMaterialsSub.forEach((element) => {
        this.SavedStudyMaterialsSub.forEach((saved) => {
          if (element.type_id == saved.type_id && saved.type == element.type) {
            element.is_checked = true;
            element.Assigned = true;
            element.Required = saved.required;
            element.DueNumber = saved.due_number;
            element.OverDueNumber = saved.overdue_number;
            element['seq_number'] = saved.seq_number;
          }
        });
      });
      let titles = this.SavedStudyMaterialsSub.filter((x) => x.type == 'title');

      titles.forEach((element) => {
        element.title_type == 'Section'
          ? (element['icon'] = 'section.png')
          : element.title_type == 'Flashcard App'
            ? (element['icon'] = 'flashcardsection.png')
            : element.title_type == 'Practice Question App'
              ? (element['icon'] = 'pqasection.png')
              : element.title_type == 'Download'
                ? (element['icon'] = 'downloadsection.png')
                : element.title_type == 'video'
                  ? (element['icon'] = 'video.svg')
                  : '';
        this.StudyMaterialsSub.push(element);
      });

      this.StudyMaterialsSub.sort(function (a, b) {
        if (a.Assigned) return parseInt(a.seq_number) - parseInt(b.seq_number);
      });
    }

    this.ChangeSubscriptionMaterial();
    this.spinner.hide();
  }
  async saveTemplates() {
    if (
      this.template_name == '' ||
      this.template_name == null ||
      this.template_name == undefined
    ) {
      this.service.ShowError('Enter template name');
    } else if (
      this.SelectedCertificate == '' ||
      this.SelectedCertificate == null ||
      this.SelectedCertificate == undefined
    ) {
      this.service.ShowError('Select certificate');
    } else {
      let videotemp = '';
      let mindmaptemp = '';
      let knowassessments = '';
      let practicetests = '';
      let email_templates = '';
      let ketexamtests = '';
      let schedule = [];

      this.StudyMaterialsSub.forEach((element, i) => {
        element['DueTime'] = '00:00';
        element['OverDueTime'] = '00:00';
        if (element.type == 'video' && element.Assigned) {
          videotemp += element.type_id + ',';
          element.Due = this.dueDateCalculate(
            element.DueTime,
            element.DueNumber,
          );
          element.OverDue = this.dueDateCalculate(
            element.OverDueTime,
            element.OverDueNumber,
          );
          schedule.push({
            due_number: element.DueNumber,
            overdue_number: element.OverDueNumber,
            due_date: element.Due,
            overdue_date: element.OverDue,
            required: element.Required,
            homework: element.Homework,
            type: 'video',
            type_id: element.type_id,
            template_id: '',
            is_checked: element.Assigned,
            seq_number: i,
            title_name: element.type == 'title' ? element.title_name : null,
            title_type: element.title_type,
            download_description: element.download_description,
            download_url: element.download_url,
          });
        }

        if (element.type == 'mindmap' && element.Assigned) {
          mindmaptemp += element.type_id + ',';
          element.Due = this.dueDateCalculate(
            element.DueTime,
            element.DueNumber,
          );
          element.OverDue = this.dueDateCalculate(
            element.OverDueTime,
            element.OverDueNumber,
          );
          schedule.push({
            due_number: element.DueNumber,
            overdue_number: element.OverDueNumber,
            due_date: element.Due,
            overdue_date: element.OverDue,
            required: element.Required,
            homework: element.Homework,
            type: 'mindmap',
            type_id: element.type_id,
            template_id: '',
            is_checked: element.Assigned,
            seq_number: i,
            title_name: element.type == 'title' ? element.title_name : null,
            title_type: element.title_type,
            download_description: element.download_description,
            download_url: element.download_url,
          });
        }
        if (element.type == 'KA' && element.Assigned) {
          knowassessments += element.type_id + ',';
          element.Due = this.dueDateCalculate(
            element.DueTime,
            element.DueNumber,
          );
          element.OverDue = this.dueDateCalculate(
            element.OverDueTime,
            element.OverDueNumber,
          );
          schedule.push({
            due_number: element.DueNumber,
            overdue_number: element.OverDueNumber,
            due_date: element.Due,
            overdue_date: element.OverDue,
            required: element.Required,
            homework: element.Homework,
            type: 'KA',
            type_id: element.type_id,
            template_id: '',
            is_checked: element.Assigned,
            seq_number: i,
            title_name: element.type == 'title' ? element.title_name : null,
            title_type: element.title_type,
            download_description: element.download_description,
            download_url: element.download_url,
          });
        }
        if (element.type == 'PRACTICE' && element.Assigned) {
          practicetests += element.type_id + ',';
          element.Due = this.dueDateCalculate(
            element.DueTime,
            element.DueNumber,
          );
          element.OverDue = this.dueDateCalculate(
            element.OverDueTime,
            element.OverDueNumber,
          );
          schedule.push({
            due_number: element.DueNumber,
            overdue_number: element.OverDueNumber,
            due_date: element.Due,
            overdue_date: element.OverDue,
            required: element.Required,
            homework: element.Homework,
            type: 'PRACTICE',
            type_id: element.type_id,
            template_id: '',
            is_checked: element.Assigned,
            seq_number: i,
            title_name: element.type == 'title' ? element.title_name : null,
            title_type: element.title_type,
            download_description: element.download_description,
            download_url: element.download_url,
          });
        }
        if (element.type == 'CAT' && element.Assigned) {
          ketexamtests += element.type_id + ',';
          element.Due = this.dueDateCalculate(
            element.DueTime,
            element.DueNumber,
          );
          element.OverDue = this.dueDateCalculate(
            element.OverDueTime,
            element.OverDueNumber,
          );
          schedule.push({
            due_number: element.DueNumber,
            overdue_number: element.OverDueNumber,
            due_date: element.Due,
            overdue_date: element.OverDue,
            required: element.Required,
            homework: element.Homework,
            type: 'CAT',
            type_id: element.type_id,
            template_id: '',
            is_checked: element.Assigned,
            seq_number: i,
            title_name: element.type == 'title' ? element.title_name : null,
            title_type: element.title_type,
            download_description: element.download_description,
            download_url: element.download_url,
          });
        }
        if (element.type == 'title' && element.Assigned) {
          schedule.push({
            due_number: element.DueNumber,
            overdue_number: element.OverDueNumber,
            due_date: element.Due,
            overdue_date: element.OverDue,
            required: element.Required,
            homework: element.Homework,
            type: 'title',
            type_id: element.type_id,
            template_id: '',
            is_checked: element.Assigned,
            seq_number: i,
            title_name: element.type == 'title' ? element.title_name : null,
            title_type: element.title_type,
            download_description: element.download_description,
            download_url: element.download_url,
          });
        }
      });

      if (videotemp.endsWith(',') == true) {
        videotemp = videotemp.substring(0, videotemp.length - 1);
      }
      if (mindmaptemp.endsWith(',') == true) {
        mindmaptemp = mindmaptemp.substring(0, mindmaptemp.length - 1);
      }
      if (knowassessments.endsWith(',') == true) {
        knowassessments = knowassessments.substring(
          0,
          knowassessments.length - 1,
        );
      }

      if (practicetests.endsWith(',') == true) {
        practicetests = practicetests.substring(0, practicetests.length - 1);
      }
      if (ketexamtests.endsWith(',') == true) {
        ketexamtests = ketexamtests.substring(0, ketexamtests.length - 1);
      }
      let events = '';
      if (this.Events.length > 0) {
        events = JSON.stringify(this.Events);
      } else {
        events = '';
      }

      var baseline_ka_schedule = {
        baselineka: this.SelectedBaselineKA,
        DueDate: this.SelectedBaselineKAdueDate,
        DueTime: this.SelectedBaselineKAdueTime,
        OverDueDate: this.SelectedBaselineKAOverdueDate,
        OverDueTime: this.SelectedBaselineKAOverdueTime,
      };

      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var access = {
            VideoAccess: this.VideoAccess,
            MindmapAccess: this.MindmapAccess,
            SummaryAccess: this.SummaryAccess,
            ReferenceAccess: this.ReferenceAccess,
            KAAccess: this.KAAccess,
            PTAccess: this.PTAccess,
            CATAccess: this.CATAccess,
            LiveChatAccess: this.LiveChatAccess,
            LiveQuestionAccess: this.LiveQuestionAccess,
            LiveEventAccess: this.LiveEventAccess,
            FlashcardAccess: this.FlashcardAccess,
            QuestionAccess: this.QuestionAccess,
          };
          await this.templateService
            .AddTemplate(
              this.template_name,
              this.SelectedCertificate,
              this.SelectedEvents.toString(),
              videotemp,
              mindmaptemp,
              knowassessments,
              practicetests,
              ketexamtests,
              this.FashFullAccess,
              this.PraAppFullAccess,
              schedule,
              events,
              email_templates,
              access,
              this.StartDate,
              this.SelectedBaselineKA,
              JSON.stringify(baseline_ka_schedule),
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.template_name +
                        '</span> Hybrid template is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'templates',
                        list.data.id,
                        token,
                      );
                    }
                  });
                await this.getAllCourseTemplate();
                this.closeModal();
                await this.spinner.hide();
                this.service.ShowSuccess('Template add successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async updateTemplates() {
    let videotemp = '';
    let mindmaptemp = '';
    let knowassessments = '';
    let practicetests = '';
    let email_templates = '';
    let ketexamtests = '';
    let schedule = [];
    let cohort_schedule = [];
    this.StudyMaterialsSub.forEach((element, i) => {
      element['DueTime'] = '00:00';
      element['OverDueTime'] = '00:00';
      if (element.type == 'video' && element.Assigned) {
        videotemp += element.type_id + ',';
        element.Due = this.dueDateCalculate(element.DueTime, element.DueNumber);
        element.OverDue = this.dueDateCalculate(
          element.OverDueTime,
          element.OverDueNumber,
        );
        schedule.push({
          due_number: element.DueNumber,
          overdue_number: element.OverDueNumber,
          due_date: element.Due,
          overdue_date: element.OverDue,
          required: element.Required,
          homework: element.Homework,
          type: 'video',
          type_id: element.type_id,
          template_id: '',
          is_checked: element.Assigned,
          seq_number: i,
          title_name: element.type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
        });
      }

      if (element.type == 'mindmap' && element.Assigned) {
        mindmaptemp += element.type_id + ',';
        element.Due = this.dueDateCalculate(element.DueTime, element.DueNumber);
        element.OverDue = this.dueDateCalculate(
          element.OverDueTime,
          element.OverDueNumber,
        );
        schedule.push({
          due_number: element.DueNumber,
          overdue_number: element.OverDueNumber,
          due_date: element.Due,
          overdue_date: element.OverDue,
          required: element.Required,
          homework: element.Homework,
          type: 'mindmap',
          type_id: element.type_id,
          template_id: '',
          is_checked: element.Assigned,
          seq_number: i,
          title_name: element.type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
        });
      }
      if (element.type == 'KA' && element.Assigned) {
        knowassessments += element.type_id + ',';
        element.Due = this.dueDateCalculate(element.DueTime, element.DueNumber);
        element.OverDue = this.dueDateCalculate(
          element.OverDueTime,
          element.OverDueNumber,
        );
        schedule.push({
          due_number: element.DueNumber,
          overdue_number: element.OverDueNumber,
          due_date: element.Due,
          overdue_date: element.OverDue,
          required: element.Required,
          homework: element.Homework,
          type: 'KA',
          type_id: element.type_id,
          template_id: '',
          is_checked: element.Assigned,
          seq_number: i,
          title_name: element.type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
        });
      }
      if (element.type == 'PRACTICE' && element.Assigned) {
        practicetests += element.type_id + ',';
        element.Due = this.dueDateCalculate(element.DueTime, element.DueNumber);
        element.OverDue = this.dueDateCalculate(
          element.OverDueTime,
          element.OverDueNumber,
        );
        schedule.push({
          due_number: element.DueNumber,
          overdue_number: element.OverDueNumber,
          due_date: element.Due,
          overdue_date: element.OverDue,
          required: element.Required,
          homework: element.Homework,
          type: 'PRACTICE',
          type_id: element.type_id,
          template_id: '',
          is_checked: element.Assigned,
          seq_number: i,
          title_name: element.type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
        });
      }
      if (element.type == 'CAT' && element.Assigned) {
        ketexamtests += element.type_id + ',';
        element.Due = this.dueDateCalculate(element.DueTime, element.DueNumber);
        element.OverDue = this.dueDateCalculate(
          element.OverDueTime,
          element.OverDueNumber,
        );
        schedule.push({
          due_number: element.DueNumber,
          overdue_number: element.OverDueNumber,
          due_date: element.Due,
          overdue_date: element.OverDue,
          required: element.Required,
          homework: element.Homework,
          type: 'CAT',
          type_id: element.type_id,
          template_id: '',
          is_checked: element.Assigned,
          seq_number: i,
          title_name: element.type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
        });
      }
      if (element.type == 'title' && element.Assigned) {
        schedule.push({
          due_number: element.DueNumber,
          overdue_number: element.OverDueNumber,
          due_date: element.Due,
          overdue_date: element.OverDue,
          required: element.Required,
          homework: element.Homework,
          type: 'title',
          type_id: element.type_id,
          template_id: '',
          is_checked: element.Assigned,
          seq_number: i,
          title_name: element.type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
        });
      }
    });
    if (videotemp.endsWith(',') == true) {
      videotemp = videotemp.substring(0, videotemp.length - 1);
    }
    if (mindmaptemp.endsWith(',') == true) {
      mindmaptemp = mindmaptemp.substring(0, mindmaptemp.length - 1);
    }
    if (knowassessments.endsWith(',') == true) {
      knowassessments = knowassessments.substring(
        0,
        knowassessments.length - 1,
      );
    }
    if (practicetests.endsWith(',') == true) {
      practicetests = practicetests.substring(0, practicetests.length - 1);
    }
    if (ketexamtests.endsWith(',') == true) {
      ketexamtests = ketexamtests.substring(0, ketexamtests.length - 1);
    }

    let events = '';
    if (this.Events.length > 0) {
      events = JSON.stringify(this.Events);
    } else {
      events = '';
    }
    var baseline_ka_schedule = {
      baselineka: this.SelectedBaselineKA,
      DueDate: this.SelectedBaselineKAdueDate,
      DueTime: this.SelectedBaselineKAdueTime,
      OverDueDate: this.SelectedBaselineKAOverdueDate,
      OverDueTime: this.SelectedBaselineKAOverdueTime,
    };
    await this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        var access = {
          VideoAccess: this.VideoAccess,
          MindmapAccess: this.MindmapAccess,
          SummaryAccess: this.SummaryAccess,
          ReferenceAccess: this.ReferenceAccess,
          KAAccess: this.KAAccess,
          PTAccess: this.PTAccess,
          CATAccess: this.CATAccess,
          LiveChatAccess: this.LiveChatAccess,
          LiveQuestionAccess: this.LiveQuestionAccess,
          LiveEventAccess: this.LiveEventAccess,
          FlashcardAccess: this.FlashcardAccess,
          QuestionAccess: this.QuestionAccess,
        };
        await this.templateService
          .UpdateTemplate(
            this.Id,
            this.template_name,
            this.SelectedCertificate,
            this.SelectedEvents.toString(),
            videotemp,
            mindmaptemp,
            knowassessments,
            practicetests,
            ketexamtests,
            this.FashFullAccess,
            this.PraAppFullAccess,
            schedule,
            events,
            email_templates,
            access,
            this.StartDate,
            this.SelectedBaselineKA,
            JSON.stringify(baseline_ka_schedule),
            token,
          )
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.auth
                .getAuthenticatedUser()
                .getSession(async (err, session) => {
                  if (err) {
                    await this.spinner.hide();
                    this.router.navigate(['/login']);
                  } else {
                    var token = session.getIdToken().getJwtToken();
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    var desc =
                      '<span> ' +
                      this.template_name +
                      '</span> template is update by <span> ' +
                      e +
                      '</span>';
                    await this.service.addAdminLogs(
                      desc,
                      'Addtemplates',
                      this.Id,
                      token,
                    );
                  }
                });
              await this.getAllCourseTemplate();
              this.closeModal();
              await this.spinner.hide();
              this.service.ShowSuccess('Template update successfully');
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          });
      }
    });
  }
  checkIsDueNumber(item) {
    var reg = /^\d+$/;
    if (!reg.test(item.DueNumber)) {
      item.DueNumber = '';
    }
  }
  checkIsOverDueNumber(item) {
    var reg = /^\d+$/;
    if (!reg.test(item.OverDueNumber)) {
      item.OverDueNumber = '';
    }
  }
  dueDateCalculate(DueTime, DueNumber) {
    if (DueNumber !== '' && DueNumber != null && DueNumber != undefined) {
      let date = new Date();
      let hr = DueTime;
      let min = DueTime;
      hr = parseInt(hr.substr(0, 2));
      min = parseInt(min.substr(3, 4));
      if (!hr && hr != 0) {
        hr = 0;
      }
      if (!min && min != 0) {
        min = 0;
      }
      if (DueNumber == '') DueNumber = '0';
      var dt = new Date(date.setDate(date.getDate() + parseInt(DueNumber)));
      let d_date = dt.getDate();
      let d_year = dt.getFullYear();
      let d_month = dt.getMonth();
      var final_date = new Date(d_year, d_month, d_date, hr, min);
      return final_date.toString();
    } else {
      return '';
    }
  }

  async getAllLiveEvents() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveservice.getAllLiveEvents(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.LiveEvents = list.data;
            this.FilterLiveEvents = list.data;
            await this.spinner.hide();
          }
        });
      }
    });
  }

  async getAllVideoLesson() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getAllVideoLesson(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Videos = list.data.Videos;
            this.FilterVideos = list.data.Videos;
            this.Mindmap = list.data.MindMaps;
            this.FilterMindmap = list.data.MindMaps;
            this.Videos.forEach((vi) => {
              vi.Assigned = false;
              vi.Required = false;
              vi.Homework = false;
              vi.Due = '';
              vi.OverDue = '';
              vi.DueNumber = '';
              vi.OverDueNumber = '';
              vi.DueTime = '';
              vi.OverDueTime = '';
            });
            this.Mindmap.forEach((mi) => {
              mi.Assigned = false;
              mi.Required = false;
              mi.Homework = false;
              mi.Due = '';
              mi.OverDue = '';
              mi.DueNumber = '';
              mi.OverDueNumber = '';
              mi.DueTime = '';
              mi.OverDueTime = '';
            });
            await this.spinner.hide();
          }
        });
      }
    });
  }
  videoChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.Videos.sort((a, b) => (a.video_title > b.video_title ? 1 : -1));
    else if (val == 'date')
      this.Videos.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  mindmapChange(event) {
    const val = event.target.value;
    if (val == 'name') this.Mindmap.sort((a, b) => (a.name > b.name ? 1 : -1));
    else if (val == 'date')
      this.Mindmap.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  knowledgeChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.KnowledgeAssessments.sort((a, b) =>
        a.knowass_name > b.knowass_name ? 1 : -1,
      );
    else if (val == 'date')
      this.KnowledgeAssessments.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  catChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.CatExams.sort((a, b) => (a.ketexam_name > b.ketexam_name ? 1 : -1));
    else if (val == 'date')
      this.CatExams.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  practiceChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.PracticeExams.sort((a, b) =>
        a.practice_name > b.practice_name ? 1 : -1,
      );
    else if (val == 'date')
      this.PracticeExams.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  emailChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.EmailTemplates.sort((a, b) => (a.name > b.name ? 1 : -1));
    else if (val == 'date')
      this.EmailTemplates.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  checkvaluedDue(item) {
    var reg = /^-?\d+$/;
    if (!reg.test(item.DueNumber)) {
      item.DueNumber = '';
    }
  }
  checkvalueOverDue(item) {
    var reg = /^-?\d+$/;
    if (!reg.test(item.OverDueNumber)) {
      item.OverDueNumber = '';
    }
  }
  handleFileSelect(evt, item) {
    let me = this;
    let file = evt.target.files[0];
    item['download_filename'] = file.name;
    let mime = 'data:' + file.type + ';base64,';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.uploadFile(reader.result, item, mime);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async uploadFile(image, item, mime) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.selfService
          .uploadFile(image, mime, token)
          .then(async (imgresponse) => {
            if (imgresponse[0].status == 200) {
              var imglist = JSON.parse(<string>imgresponse[0].json);
              if (item.title_type == 'video') {
                item.download_url = imglist.data;
              } else {
                item.download_url = imglist.data;
              }
              this.service.ShowSuccess('File uploaded successful');
              this.spinner.hide();
            } else {
              this.service.ShowError('Endpoint request timeout');
              this.spinner.hide();
            }
          });
      }
    });
  }

  async addScheduleItems() {
    if (this.ScheduleItem == 'Section') {
      this.StudyMaterialsSub.unshift({
        type_id: 0,
        title_name: '',
        type: 'title',
        is_checked: true,
        Assigned: true,
        icon: 'section.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
      });
    } else if (this.ScheduleItem == 'Flashcard App') {
      this.StudyMaterialsSub.unshift({
        type_id: 0,
        title_name: '',
        type: 'title',
        is_checked: true,
        Assigned: true,
        icon: 'flashcardsection.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
      });
    } else if (this.ScheduleItem == 'Practice Question App') {
      this.StudyMaterialsSub.unshift({
        type_id: 0,
        title_name: '',
        type: 'title',
        is_checked: true,
        Assigned: true,
        icon: 'pqasection.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
      });
    } else if (this.ScheduleItem == 'Download') {
      this.StudyMaterialsSub.unshift({
        type_id: 0,
        title_name: '',
        type: 'title',
        is_checked: true,
        Assigned: true,
        icon: 'downloadsection.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
        download_url: '',
        download_description: '',
      });
    } else {
      this.StudyMaterialsSub.unshift({
        type_id: 0,
        title_name: '',
        type: 'title',
        is_checked: true,
        Assigned: true,
        icon: 'video.svg',
        seq_number: 1,
        title_type: this.ScheduleItem,
        download_url: '',
        download_description: '',
      });
    }
  }
  async deleteScheduleItems(item) {
    const index = this.StudyMaterialsSub.indexOf(item);
    if (index > -1) {
      this.StudyMaterialsSub.splice(index, 1);
    }
  }

  async getAllHybridMaterials() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.selfService
          .getAllHybridMaterials(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.bkpStudyMaterialsSub = [];
              if (list.data.KnowledgeAssessments.length > 0) {
                list.data.KnowledgeAssessments.forEach((element) => {
                  this.bkpStudyMaterialsSub.push({
                    visible_material: this.KAAccess,
                    type_id: element.id,
                    name: element.knowass_name,
                    type: 'KA',
                    is_checked: false,
                    icon: 'ka.svg',
                    seq_number: 5000,
                    Assigned: false,
                    Required: false,
                    DueNumber: '',
                    OverDueNumber: '',
                    certi_id: element.certi_id,
                  });
                });
              }
              if (list.data.MindMaps.length > 0) {
                list.data.MindMaps.forEach((element) => {
                  this.bkpStudyMaterialsSub.push({
                    visible_material: this.MindmapAccess,
                    type_id: element.id,
                    name: element.name,
                    type: 'mindmap',
                    is_checked: false,
                    icon: 'mindmap.svg',
                    seq_number: 5000,
                    Assigned: false,
                    Required: false,
                    DueNumber: '',
                    OverDueNumber: '',
                    certi_id: element.certi_id,
                  });
                });
              }
              if (list.data.PracticeExams.length > 0) {
                list.data.PracticeExams.forEach((element) => {
                  this.bkpStudyMaterialsSub.push({
                    visible_material: this.PTAccess,
                    type_id: element.id,
                    name: element.practice_name,
                    type: 'PRACTICE',
                    is_checked: false,
                    icon: 'test.svg',
                    seq_number: 5000,
                    Assigned: false,
                    Required: false,
                    DueNumber: '',
                    OverDueNumber: '',
                    certi_id: element.certi_id,
                  });
                });
              }
              if (list.data.Videos.length > 0) {
                list.data.Videos.forEach((element) => {
                  this.bkpStudyMaterialsSub.push({
                    visible_material: this.VideoAccess,
                    type_id: element.id,
                    name: element.video_title,
                    type: 'video',
                    is_checked: false,
                    icon: 'video.svg',
                    seq_number: 5000,
                    Assigned: false,
                    Required: false,
                    DueNumber: '',
                    OverDueNumber: '',
                    certi_id: element.certi_id,
                  });
                });
              }
              if (list.data.CatExams.length > 0) {
                list.data.CatExams.forEach((element) => {
                  this.bkpStudyMaterialsSub.push({
                    visible_material: this.CATAccess,
                    type_id: element.id,
                    name: element.ketexam_name,
                    type: 'CAT',
                    is_checked: false,
                    icon: 'mindmap.svg',
                    seq_number: 5000,
                    Assigned: false,
                    Required: false,
                    DueNumber: '',
                    OverDueNumber: '',
                    certi_id: element.certi_id,
                  });
                });
              }
              this.editHybridMaterial();
            }
            await this.spinner.hide();
          });
      }
    });
  }
  editHybridMaterial() {
    this.StudyMaterialsSub = this.bkpStudyMaterialsSub.filter(
      (x) => x.certi_id == this.SelectedCertificate,
    );
    this.StudyMaterialsSub.forEach((element) => {
      this.SavedStudyMaterialsSub.forEach((saved) => {
        if (element.type_id == saved.type_id && saved.type == element.type) {
          element.is_checked = saved.is_checked;
          element.Assigned = true;
          element.Required = saved.required;
          element.DueNumber = saved.due_number;
          element.OverDueNumber = saved.overdue_number;
          element['seq_number'] = saved.seq_number;
        }
      });
    });
    let titles = this.SavedStudyMaterialsSub.filter((x) => x.type == 'title');

    titles.forEach((element) => {
      element.title_type == 'Section'
        ? (element['icon'] = 'section.png')
        : element.title_type == 'Flashcard App'
          ? (element['icon'] = 'flashcardsection.png')
          : element.title_type == 'Practice Question App'
            ? (element['icon'] = 'pqasection.png')
            : element.title_type == 'Download'
              ? (element['icon'] = 'downloadsection.png')
              : element.title_type == 'video'
                ? (element['icon'] = 'video.svg')
                : '';
      this.StudyMaterialsSub.push(element);
    });

    this.ChangeSubscriptionMaterial();
    this.StudyMaterialsSub.sort(function (a, b) {
      return parseInt(a.seq_number) - parseInt(b.seq_number);
    });
  }

  ChangeSubscriptionMaterial() {
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'KA',
    ).forEach((someobject) => (someobject.visible_material = this.KAAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'mindmap',
    ).forEach(
      (someobject) => (someobject.visible_material = this.MindmapAccess),
    );
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'PRACTICE',
    ).forEach((someobject) => (someobject.visible_material = this.PTAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'video',
    ).forEach((someobject) => (someobject.visible_material = this.VideoAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'CAT',
    ).forEach((someobject) => (someobject.visible_material = this.CATAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'KA' && !this.KAAccess,
    ).forEach((someobject) => (someobject.is_checked = this.KAAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'mindmap' && !this.MindmapAccess,
    ).forEach((someobject) => (someobject.is_checked = this.MindmapAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'PRACTICE' && !this.PTAccess,
    ).forEach((someobject) => (someobject.is_checked = this.PTAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'video' && !this.VideoAccess,
    ).forEach((someobject) => (someobject.is_checked = this.VideoAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.type == 'CAT' && !this.CatExams,
    ).forEach((someobject) => (someobject.is_checked = this.CATAccess));
  }
  ChangeSubscriptionLiveEvent() {
    this.SelectedEvents = [];
  }

  async getSavedHybridSchedules(template_id) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.SavedStudyMaterialsSub = [];
        await this.templateService
          .getSavedHybridSchedules(template_id, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              if (list.data.length > 0) {
                this.SavedStudyMaterialsSub = list.data;
              } else {
                this.SavedStudyMaterialsSub = [];
              }
            }
            await this.spinner.hide();
          });
      }
    });
  }
}

export interface cohortlist {
  Srno: number;
  id: number;
  templateName: string;
  certi_id: number;
  cource_id: string;
  createdAt: string;
  live_events: string;
  videos: string;
  mindmaps: string;
  knowassessments: string;
  practicetests: string;
  ketexamtests: string;
  updatedAt: string;
  certificate_name: string;
  flashcard_app: string;
  practice_que_app: string;
  events: string;
  email_templates: string;
  video_access: string;
  mindmap_access: string;
  summary_access: string;
  reference_access: string;
  KA_access: string;
  PT_access: string;
  CAT_access: string;
  livechat_access: string;
  liveq_access: string;
  liveevent_access: string;
  flashcard_access: string;
  question_access: string;
  start_date: string;
  baseline_ka: string;
  baseline_ka_schedule: string;
}
