import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from 'src/app/service/authorization.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from 'src/app/services.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { LiveeventsService } from 'src/app/services/liveevents/liveevents.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { v4 as uuidv4 } from 'uuid';

export interface timeZone {
  abbr: string;
  group: string;
  name: string;
  nameValue: string;
  timeValue: string;
}
@Component({
  selector: 'app-liveevent',
  templateUrl: './liveevent.component.html',
  styleUrls: ['./liveevent.component.scss'],
})
export class LiveeventComponent implements OnInit {
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = [
    'certificate_name',
    'event_name',
    'event_type',
    'next_event',
    'actions',
  ];
  dataSource: MatTableDataSource<eventList>;
  matcher = new MyErrorStateMatcher();
  NotAddpopup: boolean = false;
  EditEventpopup: boolean = false;
  SetEvent: any;
  Search: any;
  RecordEvent: any;
  AutomaticallyRecode: any;
  Certificatelist = [];
  SelectedCertificate: any;
  AlterNativeSoftware: any;
  time_zone: any;
  AdminAttendeeId: any;
  AdminMeetingId: any;
  Event_Name: any;
  Event_Link: any;
  Start_Date: any;
  Start_Time: any;
  Temp_Start_Time = 'Start Time';
  End_Time: any;
  Temp_End_Time = 'End Time';
  Is_Repeat: number = 2;
  Repeat_Count: number = 0;
  Repeat_Type: string = 'Day';
  Repeat_On: any;
  Ends_Type: string = 'Never';
  editdata: any;
  ends_on: any;
  total_occurances: any;
  IsUpdate: boolean = false;
  linkupdate: boolean = false;
  sun: number = 0;
  mon: number = 0;
  tues: number = 0;
  wed: number = 0;
  thurs: number = 0;
  fri: number = 0;
  sat: number = 0;
  SelectDay = [];
  array_start_date = [];
  todayDate: Date = new Date();
  bkpSelectDay = [
    {
      name: 'sun',
      value: 0,
      day: 0,
    },
    {
      name: 'mon',
      value: 0,
      day: 1,
    },
    {
      name: 'tues',
      value: 0,
      day: 2,
    },
    {
      name: 'wed',
      value: 0,
      day: 3,
    },
    {
      name: 'thurs',
      value: 0,
      day: 4,
    },
    {
      name: 'fri',
      value: 0,
      day: 5,
    },
    {
      name: 'sat',
      value: 0,
      day: 6,
    },
  ];
  public addeventform: FormGroup;
  constructor(
    public formbuilder: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public liveservice: LiveeventsService,
    public socket: WebsocketService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.addeventform = this.formbuilder.group({
      CertificateName: ['', Validators.required],
      EventName: ['', Validators.required],
      Start_Date: ['', Validators.required],
      StartTime: ['', Validators.required],
      TempStartTime: new FormControl(),
      EndTime: ['', Validators.required],
      TempEndTime: new FormControl(),
      time_zone: new FormControl(),
      EventLink: new FormControl(),
      AutomaticallyRecode: new FormControl(),
      RecordEvent: new FormControl(),
      AlterNative: new FormControl(),
      IsRepeat: new FormControl(),
      RepeatCount: new FormControl(),
      RepeatType: new FormControl(),
      Ends_Type: new FormControl(),
      ends_on: new FormControl(),
    });

    this.spinner.show();

    this.ListCertificate();
    this.getAllLiveEvents();
    this.time_zone = this.auth.currentTimeZone();
    this.SelectedCertificate = '';
    this.AlterNativeSoftware = '1';
    this.SelectDay = this.bkpSelectDay;
  }
  editEvent(row) {
    this.IsUpdate = true;
    this.SetEvent = 'Edit';
    this.EditEventpopup = true;
    this.editdata = row;
    this.SelectedCertificate = row.certi_id;
    this.Event_Name = row.event_name;
    this.Event_Link = row.event_link;
    this.time_zone =
      row.time_zone == null || row.time_zone == ''
        ? this.auth.currentTimeZone()
        : JSON.parse(row.time_zone);
    this.Start_Date = this.auth.ConvertAnyTimeZoneToCurrentZone(
      this.auth.ConvertFormatDbDateTime(
        row.start_date,
        this.time_zone.nameValue,
      ),
      this.time_zone.nameValue,
    );
    this.Start_Time = row.start_time;
    this.StartTimeChange();
    this.End_Time = row.end_time;
    this.EndTimeChange();
    this.ends_on = row.ends_on
      ? this.auth.ConvertAnyTimeZoneToCurrentZone(
          this.auth.ConvertFormatDbDateTime(
            row.ends_on,
            this.time_zone.nameValue,
          ),
          this.time_zone.nameValue,
        )
      : null;
    if (row.is_repeat == 1) {
      this.NotAddpopup = true;
    } else {
      this.NotAddpopup = false;
    }

    this.Is_Repeat = row.is_repeat;
    this.total_occurances = row.total_occurances;
    this.AlterNativeSoftware = row.meeting_playform;
    if (row.meeting_playform == '1') {
      this.linkupdate = true;
    } else {
      this.linkupdate = false;
    }
    this.RecordEvent = row.is_record;
    this.AutomaticallyRecode = row.is_auto_record;
    if (row.repeat_count) {
      this.Repeat_Count = row.repeat_count;
    } else {
      this.Repeat_Count = 0;
    }
    if (row.repeat_on) {
      this.Repeat_On = row.repeat_on;
    } else {
      this.Repeat_On = null;
    }
    this.SelectDay =
      row.repeat_on != null ? JSON.parse(row.repeat_on) : this.bkpSelectDay;

    this.SelectDay.forEach((element) => {
      if (element.value == 1) {
        this.setRepeatOn(element.name);
      }
    });

    if (row.repeat_type) {
      this.Repeat_Type = row.repeat_type;
    } else {
      this.Repeat_Type = 'Day';
    }
    if (row.ends_type) {
      this.Ends_Type = row.ends_type;
    } else {
      this.Ends_Type = 'Never';
    }
  }

  AddEvent() {
    this.SetEvent = 'Add';
    this.EditEventpopup = true;
    this.IsUpdate = false;
    this.linkupdate = true;
    this.ends_on = null;
    this.sun = 0;
    this.mon = 0;
    this.tues = 0;
    this.wed = 0;
    this.thurs = 0;
    this.fri = 0;
    this.sat = 0;
    this.array_start_date = [];
  }
  async getAllLiveEvents() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveservice.getAllLiveEvents(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var data1: eventList[] = [];

            list.data.forEach((element) => {
              data1.push({
                certificate_name: element.certificate
                  ? element.certificate.certificate_name
                  : '',
                event_name: element.event_name,
                event_type:
                  element.is_repeat == 1 ? 'Recurring' : 'Does not repeat',
                next_event: element.next_event,
                id: element.id,
                certi_id: element.certi_id,
                event_link: element.event_link,
                start_date: element.start_date,
                start_time: element.start_time,
                end_time: element.end_time,
                is_repeat: element.is_repeat,
                repeat_count: element.repeat_count,
                repeat_type: element.repeat_type,
                repeat_on: element.repeat_on,
                ends_type: element.ends_type,
                ends_on: element.ends_on,
                total_occurances: element.total_occurances,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                is_record: element.is_record,
                is_auto_record: element.is_auto_record,
                time_zone: element.time_zone,
                meeting_playform: element.meeting_playform,
              });
            });
            this.dataSource = new MatTableDataSource(data1);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
            await this.spinner.hide();
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data;
            this.SelectedCertificate = '';
          }
        });
      }
    });
  }
  setRepeatOn(day) {
    day == 'sun'
      ? (this.sun = this.sun == 1 ? 0 : 1)
      : day == 'mon'
        ? (this.mon = this.mon == 1 ? 0 : 1)
        : day == 'tues'
          ? (this.tues = this.tues == 1 ? 0 : 1)
          : day == 'wed'
            ? (this.wed = this.wed == 1 ? 0 : 1)
            : day == 'thurs'
              ? (this.thurs = this.thurs == 1 ? 0 : 1)
              : day == 'fri'
                ? (this.fri = this.fri == 1 ? 0 : 1)
                : day == 'sat'
                  ? (this.sat = this.sat == 1 ? 0 : 1)
                  : '';
  }
  async saveModal() {
    this.markFormTouched(this.addeventform);
    if (this.Is_Repeat == 1) {
      this.SelectDay = [
        { name: 'sun', value: this.sun, day: 0 },
        { name: 'mon', value: this.mon, day: 1 },
        { name: 'tues', value: this.tues, day: 2 },
        { name: 'wed', value: this.wed, day: 3 },
        { name: 'thurs', value: this.thurs, day: 4 },
        { name: 'fri', value: this.fri, day: 5 },
        { name: 'sat', value: this.sat, day: 6 },
      ];

      var start_date = new Date(this.Start_Date);
      var ends_on = new Date(this.ends_on);
      var diffTime = Math.abs(start_date.getTime() - ends_on.getTime());
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      let oc = 0;
      let date = [];
      for (var i = start_date; i <= ends_on; i.setDate(i.getDate() + 1)) {
        let dd = i;
        date.push({ date: dd.toDateString() });
      }
      if (this.Repeat_Type == 'Day') {
        for (let d = 0; d < date.length; d++) {
          let sd = new Date(date[d].date);
          let ed = new Date(
            new Date(date[d].date).setDate(
              new Date(date[d].date).getDate() + this.Repeat_Count,
            ),
          );
          let is_oc = '';
          for (let l = sd; l <= ed; l.setDate(l.getDate() + 1)) {
            var count = 0;
            this.SelectDay.forEach((element) => {
              if (
                l.getDay() == element.day &&
                element.value == 1 &&
                is_oc == ''
              ) {
                oc++;
                count = count + 1;
                if (count == 6) is_oc = 'found';
              }
            });
          }
          d = d + this.Repeat_Count;
        }
      } else if (this.Repeat_Type == 'Week') {
        for (let d = 0; d < date.length; d++) {
          let sd = new Date(date[d].date);
          let ed = new Date(
            new Date(date[d].date).setDate(
              new Date(date[d].date).getDate() + this.Repeat_Count * 7,
            ),
          );
          let is_oc = '';
          for (let l = sd; l <= ed; l.setDate(l.getDate() + 1)) {
            var count = 0;
            this.SelectDay.forEach((element) => {
              if (
                l.getDay() == element.day &&
                element.value == 1 &&
                is_oc == ''
              ) {
                oc++;
                count = count + 1;
                if (count == 6) is_oc = 'found';
              }
            });
            break;
          }
          d = d + 7 * this.Repeat_Count;
        }
      } else if (this.Repeat_Type == 'Month') {
        for (let d = 0; d < date.length; d++) {
          let sd = new Date(date[d].date);
          let ed = new Date(
            new Date(date[d].date).setDate(
              new Date(date[d].date).getDate() + this.Repeat_Count * 31,
            ),
          );
          let is_oc = '';
          for (let l = sd; l <= ed; l.setDate(l.getDate() + 1)) {
            var count = 0;
            this.SelectDay.forEach((element) => {
              if (
                l.getDay() == element.day &&
                element.value == 1 &&
                is_oc == ''
              ) {
                oc++;
                count = count + 1;
                if (count == 6) is_oc = 'found';
              }
            });
            break;
          }
          d = d + 31 * this.Repeat_Count;
        }
      } else if (this.Repeat_Type == 'Year') {
        for (let d = 0; d < date.length; d++) {
          let sd = new Date(date[d].date);
          let ed = new Date(
            new Date(date[d].date).setDate(
              new Date(date[d].date).getDate() + this.Repeat_Count * 365,
            ),
          );
          let is_oc = '';
          for (let l = sd; l <= ed; l.setDate(l.getDate() + 1)) {
            var count = 0;
            this.SelectDay.forEach((element) => {
              if (
                l.getDay() == element.day &&
                element.value == 1 &&
                is_oc == ''
              ) {
                oc++;
                count = count + 1;
                if (count == 6) is_oc = 'found';
              }
            });
            break;
          }
          d = d + 365 * this.Repeat_Count;
        }
      }
      if (this.Ends_Type == 'After') oc++;
      this.total_occurances = oc;
    } else {
      this.total_occurances = 1;
    }
    await this.recurringEvent();
    if (this.addeventform.valid && this.IsUpdate) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            let StrDate = new Date(this.Start_Date);
            if (
              new Date(this.Start_Date).toLocaleDateString() == 'Invalid Date'
            ) {
              StrDate = new Date(
                this.auth.convertUniDateFormat(this.Start_Date),
              );
            } else {
              StrDate = new Date(this.Start_Date);
            }
            let EventDate =
              StrDate.getFullYear() +
              '-' +
              (StrDate.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              StrDate.getDate().toString().padStart(2, '0') +
              'T' +
              this.Start_Time +
              ':00' +
              this.time_zone.timeValue;
            await this.liveservice
              .UpdateLiveEvents(
                this.SelectedCertificate,
                this.Event_Name,
                this.Event_Link,
                EventDate,
                this.auth.convertAnyTime24Hour(this.Start_Time),
                this.auth.convertAnyTime24Hour(this.End_Time),
                this.Is_Repeat,
                token,
                this.Repeat_Type,
                this.Repeat_Count,
                JSON.stringify(this.SelectDay),
                this.Ends_Type,
                this.ends_on
                  ? this.auth.convertAnyTimeZoneUtcTimeZone(
                      this.ends_on,
                      this.time_zone.nameValue,
                    )
                  : null,
                this.total_occurances,
                this.editdata.id,
                this.RecordEvent,
                this.AutomaticallyRecode,
                this.AlterNativeSoftware,
                JSON.stringify(this.time_zone),
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  await this.sendNotifications(this.editdata.id, EventDate);
                  await this.recurringLiveEvents();
                  this.service.ShowSuccess('Live event update successfully');
                  this.closeModal();
                  this.pagination = false;
                  this.EditEventpopup = false;
                  await this.getAllLiveEvents();
                  await this.spinner.hide();
                } else {
                  await this.spinner.hide();
                  this.service.ShowError('Live event not update successfully');
                }
              });
          }
          this.IsUpdate = true;
        });
    } else {
      if (this.addeventform.valid) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              this.time_zone =
                this.time_zone != undefined && this.time_zone != ''
                  ? this.time_zone
                  : '';

              let StrDate = new Date(this.Start_Date);
              if (
                new Date(this.Start_Date).toLocaleDateString() == 'Invalid Date'
              ) {
                StrDate = new Date(
                  this.auth.convertUniDateFormat(this.Start_Date),
                );
              } else {
                StrDate = new Date(this.Start_Date);
              }
              let EventDate =
                StrDate.getFullYear() +
                '-' +
                (StrDate.getMonth() + 1).toString().padStart(2, '0') +
                '-' +
                StrDate.getDate().toString().padStart(2, '0') +
                'T' +
                this.Start_Time +
                ':00' +
                this.time_zone.timeValue;

              let com = JSON.stringify({
                l: new Date(this.Start_Date).toLocaleDateString(),
                newdate: new Date(this.Start_Date),
                startDate: this.Start_Date,
                nameValue: this.time_zone.nameValue,
                timeValue: this.time_zone.timeValue,
                auth: this.auth.convertLocalTimeZoneUtcTimeZone(
                  new Date(this.Start_Date).toLocaleDateString(),
                  this.time_zone.nameValue,
                  this.time_zone.timeValue,
                ),
                Start_Date: this.Start_Date,
                StrDate: StrDate,
              });
              await this.liveservice
                .AddLiveEvents(
                  this.SelectedCertificate,
                  this.Event_Name,
                  this.Event_Link,
                  EventDate,
                  this.auth.convertAnyTime24Hour(this.Start_Time),
                  this.auth.convertAnyTime24Hour(this.End_Time),
                  this.Is_Repeat,
                  this.RecordEvent,
                  this.AutomaticallyRecode,
                  this.AlterNativeSoftware,
                  JSON.stringify(this.time_zone),
                  token,
                  this.Repeat_Type,
                  this.Repeat_Count,
                  JSON.stringify(this.SelectDay),
                  this.Ends_Type,
                  this.ends_on
                    ? this.auth.convertAnyTimeZoneUtcTimeZone(
                        this.ends_on,
                        this.time_zone.nameValue,
                      )
                    : null,
                  this.total_occurances,
                )
                .then(async (data) => {
                  if (data[0].status == 200) {
                    this.editdata = JSON.parse(<string>data[0].json).data;
                    await this.sendNotifications(this.editdata.id, EventDate);
                    await this.recurringLiveEvents();
                    this.service.ShowSuccess('Live event add successfully');
                    this.closeModal();
                    this.pagination = false;
                    this.EditEventpopup = false;
                    await this.getAllLiveEvents();
                    await this.spinner.hide();
                  } else {
                    await this.spinner.hide();
                    this.service.ShowError('Error in adding event');
                  }
                });
            }
          });
      }
    }
  }
  deleteEvent(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.liveservice
                  .DeleteLiveEvents(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.pagination = false;
                      await this.getAllLiveEvents();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Live Events has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
  closeModal() {
    this.SelectedCertificate = '';
    this.Event_Name = '';
    this.Event_Link = '';
    this.Start_Date = '';
    this.Start_Time = '';
    this.End_Time = '';
    this.Is_Repeat = 2;
    this.Repeat_Count = 0;
    this.Repeat_Type = '';
    this.Repeat_On = 0;
    this.Ends_Type = '';
    this.editdata = '';
    this.EditEventpopup = false;
    this.RecordEvent = '';
    this.AutomaticallyRecode = '';
    this.AlterNativeSoftware = '1';
    this.time_zone = this.auth.currentTimeZone();
    this.sun = 0;
    this.mon = 0;
    this.tues = 0;
    this.wed = 0;
    this.thurs = 0;
    this.fri = 0;
    this.sat = 0;
    this.Temp_Start_Time = 'Start Time';
    this.Temp_End_Time = 'End Time';
    this.IsUpdate = false;
    this.array_start_date = [];
  }
  async sendNotifications(id: number, start_date: string) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveservice
          .getUserwithcohorts(id, token)
          .then(async (rep) => {
            this.spinner.hide();
            if (rep[0].status == 200) {
              var listData = JSON.parse(<string>rep[0].json).data[0];
              if (listData.length > 0) {
                let date = new Date(
                  this.auth.convertAnyTimeZoneUtcTimeZone(
                    start_date,
                    this.time_zone.nameValue,
                  ),
                );
                let fire_time = this.auth.convertStringUtcTimeZone(
                  this.auth.ConvertFormatDbDateTime(
                    date.setMinutes(date.getMinutes() - 15),
                    this.time_zone.nameValue,
                  ),
                  this.time_zone.nameValue,
                );
                let noti = [];

                for (let i = 0; i < listData.length; i++) {
                  listData[i].userslist.split(',').forEach((element) => {
                    var eventStart = {
                      id: id,
                      title: this.Event_Name,
                      user_id: element,
                    };
                    let link =
                      'https://www.my.destcert.com/startconference/' +
                      btoa(JSON.stringify(eventStart));
                    let msg =
                      'event (' +
                      this.Event_Name +
                      ') will start from <a href="' +
                      link +
                      '"><span>Join</span></a>';

                    noti.push({
                      user_id: element,
                      cohort_id: listData[i].id,
                      message: msg,
                      noti_type: 'start_meeting',
                      test_type: 'meeting',
                      test_id: id,
                      is_read: 0,
                      fire_time: fire_time,
                      meeting_date: new Date(start_date).toISOString(),
                    });
                  });
                }
                if (noti.length > 0) {
                  await this.service.addNotification(noti, token);
                }
              }
            }
          });
      }
    });
  }

  async startMeetingSingleAttendee(row) {
    let Check_live_event = false;

    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveservice
          .all_live_event_Start_time_event(row.id, token)
          .then(async (prerep) => {
            if (prerep[0].status == 200) {
              var list = JSON.parse(<string>prerep[0].json).data;
              let allEvents = list;
              allEvents.forEach((element) => {
                let timeZone = element.start_date.substr(
                  element.start_date.length - 6,
                  6,
                );
                let due_date = new Date();
                let end_date = new Date();
                let currTimeZone = this.auth.currentTimeZone();
                let end_time = element.end_time.split(':');
                if (currTimeZone.timeValue != timeZone) {
                  due_date = new Date(element.start_date);
                  due_date.setMinutes(due_date.getMinutes() - 15);
                  end_date = new Date(element.start_date);
                  end_date = new Date(
                    end_date.setHours(
                      due_date.getHours() + parseInt(end_time[0]),
                      due_date.getMinutes() + parseInt(end_time[1]),
                    ),
                  );
                } else {
                  due_date = new Date(
                    new Date(element.start_date).toLocaleString('en-US', {
                      timeZone: currTimeZone.nameValue,
                    }),
                  );
                  due_date.setMinutes(due_date.getMinutes() - 15);
                  end_date = new Date(
                    new Date(element.start_date).toLocaleString('en-US', {
                      timeZone: currTimeZone.nameValue,
                    }),
                  );
                  end_date = new Date(
                    end_date.setHours(
                      due_date.getHours() + parseInt(end_time[0]),
                      due_date.getMinutes() + parseInt(end_time[1]),
                    ),
                  );
                }
                element['due_date'] = due_date;
                element['end_date'] = end_date;
                let curr_date = new Date(
                  new Date().toLocaleString('en-US', {
                    timeZone: currTimeZone.nameValue,
                  }),
                );
                if (due_date <= curr_date && end_date >= curr_date) {
                  element['eligible'] = 1;
                } else {
                  element['eligible'] = 0;
                }
              });
              let count = allEvents.filter((x) => x.eligible == 1);
              if (count.length <= 0) {
                this.service.ShowError(
                  "Meeting can't be start before Schedule",
                );
                Check_live_event = false;
              } else {
                Check_live_event = true;
              }
            }
          });
      }
    });
    await this.spinner.hide();
    if (Check_live_event) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.liveservice
              .getUserwithcohorts(row.id, token)
              .then(async (rep) => {
                if (rep[0].status == 200) {
                  var listdata = JSON.parse(<string>rep[0].json).data[0];
                  let timezone =
                    row.time_zone != '' && row.time_zone != null
                      ? JSON.parse(row.time_zone).nameValue
                      : '';
                  let MeetingStartDate = new Date();
                  if (listdata.length > 0) {
                    let adminUser =
                      window.sessionStorage.getItem('b6h3a5d9r6e3s5h');
                    let allUsers = '';
                    for (let i = 0; i < listdata.length; i++) {
                      allUsers += listdata[i].userslist + ',';
                    }

                    if (allUsers.endsWith(',') == true) {
                      allUsers = allUsers.substring(0, allUsers.length - 1);
                    }
                    let allUsersArray = allUsers.split(',');
                    let noti = [];
                    let att = [];
                    for (let i = 0; i < allUsersArray.length; i++) {
                      let data = {
                        id: row.id,
                        title: row.event_name,
                        user_id: allUsersArray[i],
                      };
                      att.push({
                        U: allUsersArray[i],
                        A: uuidv4() + '#' + allUsersArray[i],
                      });
                      let link =
                        'https://www.my.destcert.com/startconference/' +
                        btoa(JSON.stringify(data));
                      let msg =
                        row.event_name +
                        ' has started please join us by clicking button <a href="' +
                        link +
                        '"><span>Join</span></a>';
                      let filter_lst = listdata.filter((x) =>
                        x.userslist.includes(allUsersArray[i]),
                      );
                      let cohortid = '';
                      filter_lst.forEach((element) => {
                        cohortid = element.id;
                        noti.push({
                          user_id: allUsersArray[i],
                          cohort_id: cohortid,
                          message: msg,
                          noti_type: 'start_meeting',
                          test_type: 'meeting',
                          test_id: row.id,
                          is_read: 0,
                          fire_time: this.auth.convertStringUtcTimeZone(
                            this.auth.ConvertFormatDbDateTime(
                              new Date(),
                              timezone,
                            ),
                            timezone,
                          ),
                          meeting_date: this.auth.convertStringUtcTimeZone(
                            this.auth.ConvertFormatDbDateTime(
                              MeetingStartDate,
                              timezone,
                            ),
                            timezone,
                          ),
                        });
                      });
                    }

                    await this.service
                      .createMeeting(adminUser)
                      .then(async (data) => {
                        if (data[0].status == 200) {
                          var list = JSON.parse(<string>data[0].json);
                          let Attendees = list.data.attendee.Attendees;
                          let Meeting = list.data.meeting.Meeting;
                          let MeetingId = Meeting.MeetingId;
                          Attendees.forEach((element) => {
                            let u_id = element.ExternalUserId.split('#')[1];
                            let AttendeeId = element.AttendeeId;
                            att.unshift({
                              U: u_id,
                              A: AttendeeId,
                            });
                            if (
                              u_id ==
                              window.sessionStorage.getItem('b6h3a5d9r6e3s5h')
                            ) {
                              this.AdminMeetingId = MeetingId;
                              this.AdminAttendeeId = AttendeeId;
                            }
                          });
                          await this.service.addNotification(noti, token);
                          let update_meeting = {
                            id: row.id,
                            attendees: JSON.stringify(att),
                            admin_attendee_id: this.AdminAttendeeId,
                            meeting_id: this.AdminMeetingId,
                            is_live: 1,
                            is_completed: 0,
                          };
                          let tzone =
                            row.time_zone != '' && row.time_zone != null
                              ? JSON.parse(row.time_zone)
                              : '';
                          await this.liveservice.UpdatestartLiveEvents(
                            update_meeting,
                            token,
                          );
                          let all_update_meeting = {
                            live_event_id: row.id,
                            attendees: JSON.stringify(att),
                            admin_attendee_id: this.AdminAttendeeId,
                            meeting_id: this.AdminMeetingId,
                            is_live: 1,
                            is_completed: 0,
                            startdate: this.auth.ConvertDateDDFormat(
                              new Date(),
                              this.time_zone.nameValue,
                              'YYYY-MM-DD',
                            ),
                          };
                          await this.liveservice.UpdatestartAllLiveEvents(
                            all_update_meeting,
                            token,
                          );
                          this.SocketConnect(
                            window.sessionStorage.getItem('b6h3a5d9r6e3s5h'),
                            1,
                          );
                          this.spinner.hide();

                          await Swal.fire({
                            title: 'Meeting has been created',
                            text: 'You want to join as Admin?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Join',
                          }).then(async (result) => {
                            if (result.value) {
                              let data = {
                                meeting_id: row.id,
                              };
                              let user_id = btoa(JSON.stringify(data));
                              this.router.navigate([`/joinmeeting/${user_id}`]);
                            }
                          });
                        }
                      });
                  } else {
                    this.spinner.hide();
                    this.service.ShowError(
                      'There is no student assigned in this event',
                    );
                  }
                } else {
                  var listdata = JSON.parse(<string>rep[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(listdata.message);
                }
              });
          }
        });
      await this.spinner.hide();
    }
  }
  SocketConnect(user_id, test_id) {
    let MessageArr = {
      action: 'message',
      msg: {
        action: 'connected',
        user_id: user_id,
        test_id: test_id,
        roll_id: 1,
        q_id: 1,
        sr_no: 1,
      },
    };
    this.socket.Connect();
    this.socket
      .OnOpen()
      .then((result) => {
        this.socket
          .DoSend(MessageArr)
          .then((result) => {
            let msg = {
              action: 'message',
              msg: {
                action: 'nextq',
                user_id: user_id,
                test_id: 1,
                roll_id: 1,
                q_id: 1,
                sr_no: 1,
              },
            };
            this.socket.DoSend(msg);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  changerRepeatValue(event) {
    let value = event.target.value;
    if (value == '1') {
      this.NotAddpopup = true;
    } else {
      this.NotAddpopup = false;
    }

    this.Repeat_Count = 0;
    this.Repeat_On = 0;
    this.Repeat_Type = '';
    this.Ends_Type = '';
    this.ends_on = null;
    this.sun = 0;
    this.mon = 0;
    this.tues = 0;
    this.wed = 0;
    this.thurs = 0;
    this.fri = 0;
    this.sat = 0;
  }

  ChangeSoftWare(event) {
    let value = event.target.value;
    if (value == '1') {
      this.linkupdate = true;
    } else {
      this.linkupdate = false;
    }
  }

  changeEvent() {
    if (!this.RecordEvent) {
      this.AutomaticallyRecode = false;
    }
  }

  StartTimeChange() {
    this.Temp_Start_Time = 'Start Time (' + this.Start_Time + ')';
  }
  EndTimeChange() {
    this.Temp_End_Time = 'End Time (' + this.End_Time + ')';
  }
  generateLink() {
    navigator.clipboard.writeText(this.Event_Link);
    this.service.ShowSuccess('Link copied to clipboard.');
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  async recurringEvent() {
    if (this.Is_Repeat == 1) {
      this.SelectDay = [
        { name: 'sun', value: this.sun, day: 0 },
        { name: 'mon', value: this.mon, day: 1 },
        { name: 'tues', value: this.tues, day: 2 },
        { name: 'wed', value: this.wed, day: 3 },
        { name: 'thurs', value: this.thurs, day: 4 },
        { name: 'fri', value: this.fri, day: 5 },
        { name: 'sat', value: this.sat, day: 6 },
      ];
      let arrayDate = [];
      let start_date = this.auth.convertLocalTimeZoneUtcTimeZone(
        new Date(this.Start_Date).toDateString(),
        this.time_zone.nameValue,
        this.time_zone.timeValue,
      );
      arrayDate.push({ date: start_date, newDate: new Date(start_date) });
      let end_date = new Date(new Date(this.ends_on).toDateString());
      if (this.Ends_Type == 'Never') {
        end_date = new Date(new Date().setDate(new Date().getDate() + 365));
      } else if (this.Ends_Type == 'On') {
        end_date = new Date(this.ends_on);
      } else {
        end_date = new Date(
          new Date(this.ends_on).setDate(new Date(this.ends_on).getDate() + 1),
        );
      }

      let diffTime = Math.abs(
        new Date(start_date).getTime() - new Date(end_date).getTime(),
      );
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (this.Repeat_Type == 'Day') {
        let recountDate = new Date(
          new Date(start_date).setDate(
            new Date(start_date).getDate() + this.Repeat_Count,
          ),
        );
        for (var i = 0; i < diffDays; i++) {
          let SelectDayCount = this.SelectDay.filter(
            (x) => x.day == recountDate.getDay() && x.value == 1,
          );
          if (
            SelectDayCount.length > 0 &&
            new Date(recountDate).getTime() < new Date(end_date).getTime()
          ) {
            arrayDate.push({
              date: this.auth.convertLocalTimeZoneUtcTimeZone(
                new Date(recountDate).toDateString(),
                this.time_zone.nameValue,
                this.time_zone.timeValue,
              ),
              newDate: new Date(recountDate),
            });
            recountDate = new Date(
              new Date(recountDate).setDate(
                new Date(recountDate).getDate() + this.Repeat_Count,
              ),
            );
            i = i + this.Repeat_Count - 1;
          } else {
            recountDate = new Date(
              new Date(recountDate).setDate(
                new Date(recountDate).getDate() + 1,
              ),
            );
          }
        }
      } else if (this.Repeat_Type == 'Week') {
        let recountDate = new Date(
          new Date(start_date).setDate(
            new Date(start_date).getDate() + this.Repeat_Count * 7,
          ),
        );
        for (var i = 0; i < diffDays; i++) {
          let SelectDayCount = this.SelectDay.filter(
            (x) => x.day == recountDate.getDay() && x.value == 1,
          );
          if (
            SelectDayCount.length > 0 &&
            new Date(recountDate).getTime() < new Date(end_date).getTime()
          ) {
            arrayDate.push({
              date: this.auth.convertLocalTimeZoneUtcTimeZone(
                new Date(recountDate).toDateString(),
                this.time_zone.nameValue,
                this.time_zone.timeValue,
              ),
              newDate: new Date(recountDate),
            });
            recountDate = new Date(
              new Date(recountDate).setDate(
                new Date(recountDate).getDate() + this.Repeat_Count * 7,
              ),
            );
            i = i + this.Repeat_Count * 7 - 1;
          } else {
            recountDate = new Date(
              new Date(recountDate).setDate(
                new Date(recountDate).getDate() + 1,
              ),
            );
          }
        }
      } else if (this.Repeat_Type == 'Month') {
        let recountDate = new Date(
          new Date(start_date).setMonth(
            new Date(start_date).getMonth() + this.Repeat_Count,
          ),
        );
        for (var i = 0; i < diffDays; i++) {
          let SelectDayCount = this.SelectDay.filter(
            (x) => x.day == recountDate.getDay() && x.value == 1,
          );
          if (
            SelectDayCount.length > 0 &&
            new Date(recountDate).getTime() < new Date(end_date).getTime()
          ) {
            arrayDate.push({
              date: this.auth.convertLocalTimeZoneUtcTimeZone(
                new Date(recountDate).toDateString(),
                this.time_zone.nameValue,
                this.time_zone.timeValue,
              ),
              newDate: new Date(recountDate),
            });
            recountDate = new Date(
              new Date(recountDate).setMonth(
                new Date(recountDate).getMonth() + this.Repeat_Count,
              ),
            );
            i = i + this.Repeat_Count - 1;
          } else {
            recountDate = new Date(
              new Date(recountDate).setMonth(
                new Date(recountDate).getMonth() + 1,
              ),
            );
          }
        }
      } else if (this.Repeat_Type == 'Year') {
        let recountDate = new Date(
          new Date(start_date).setFullYear(
            new Date(start_date).getFullYear() + this.Repeat_Count,
          ),
        );
        for (var i = 0; i < diffDays; i++) {
          let SelectDayCount = this.SelectDay.filter(
            (x) => x.day == recountDate.getDay() && x.value == 1,
          );
          if (
            SelectDayCount.length > 0 &&
            new Date(recountDate).getTime() < new Date(end_date).getTime()
          ) {
            arrayDate.push({
              date: this.auth.convertLocalTimeZoneUtcTimeZone(
                new Date(recountDate).toDateString(),
                this.time_zone.nameValue,
                this.time_zone.timeValue,
              ),
              newDate: new Date(recountDate),
            });
            recountDate = new Date(
              new Date(recountDate).setFullYear(
                new Date(recountDate).getFullYear() + this.Repeat_Count,
              ),
            );
            i = i + this.Repeat_Count - 1;
          } else {
            recountDate = new Date(
              new Date(recountDate).setFullYear(
                new Date(recountDate).getFullYear() + 1,
              ),
            );
          }
        }
      }
      this.array_start_date = arrayDate;
    }
  }
  async recurringLiveEvents() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let StrDate = new Date(this.Start_Date);
        if (new Date(this.Start_Date).toLocaleDateString() == 'Invalid Date') {
          StrDate = new Date(this.auth.convertUniDateFormat(this.Start_Date));
        } else {
          StrDate = new Date(this.Start_Date);
        }
        let EventDate =
          StrDate.getFullYear() +
          '-' +
          (StrDate.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          StrDate.getDate().toString().padStart(2, '0') +
          'T' +
          this.Start_Time +
          ':00' +
          this.time_zone.timeValue;
        this.array_start_date.forEach((element) => {
          element.date = this.getDateTimeZoneWise(element.date);
        });
        await this.liveservice
          .recurringLiveEvents(
            this.SelectedCertificate,
            this.Event_Name,
            this.Event_Link,
            EventDate,
            this.auth.convertAnyTime24Hour(this.Start_Time),
            this.auth.convertAnyTime24Hour(this.End_Time),
            this.Is_Repeat,
            token,
            this.Repeat_Type,
            this.Repeat_Count,
            JSON.stringify(this.SelectDay),
            this.Ends_Type,
            this.ends_on
              ? this.auth.convertAnyTimeZoneUtcTimeZone(
                  this.ends_on,
                  this.time_zone.nameValue,
                )
              : null,
            this.total_occurances,
            this.editdata.id,
            this.RecordEvent,
            this.AutomaticallyRecode,
            this.AlterNativeSoftware,
            JSON.stringify(this.time_zone),
            JSON.stringify(this.array_start_date),
            this.auth.convertLocalTimeZoneUtcTimeZone(
              new Date(this.Start_Date).toDateString(),
              this.time_zone.nameValue,
              this.time_zone.timeValue,
            ),
          )
          .then(async (multipledata) => {
            if (multipledata[0].status == 200) {
            }
          });
      }
    });
  }
  getDateTimeZoneWise(eventDate) {
    let StrDate = new Date(eventDate);
    return (
      StrDate.getFullYear() +
      '-' +
      (StrDate.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      StrDate.getDate().toString().padStart(2, '0') +
      'T' +
      this.Start_Time +
      ':00' +
      this.time_zone.timeValue
    );
  }
  testing() {
    let date = new Date(this.Start_Date);

    let stTime = this.auth.convertAnyTime24Hour(this.Start_Time).split(':');
    date.setHours(parseInt(stTime[0]), parseInt(stTime[1]));
  }
}
export interface eventList {
  certificate_name: string;
  event_name: string;
  event_type: string;
  next_event: string;
  id: string;
  certi_id: string;
  event_link: string;
  start_date: string;
  start_time: string;
  end_time: string;
  is_repeat: string;
  repeat_count: string;
  repeat_type: string;
  repeat_on: string;
  ends_type: string;
  ends_on: string;
  total_occurances: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  is_record: string;
  is_auto_record: string;
  time_zone: string;
  meeting_playform: string;
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const invalidCtrl = control && control.invalid;
    const invalidParent = control && control.parent && control.parent.invalid;
    return (invalidCtrl || invalidParent) && (control.dirty || control.touched);
  }
}
