import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../service/authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  Isknowledge: boolean = false;
  Ispractice: boolean = false;
  Iscatexam: boolean = false;
  Isacronyms: boolean = false;
  Issendmail: boolean = false;
  StudyMaterial: boolean = false;
  Peoples: boolean = false;
  CertStructure: boolean = false;
  Course: boolean = false;
  IspracticeApp: boolean = false;
  liveQ: boolean = false;
  LiveEvent: boolean = false;

  constructor(
    public router: Router,
    public auth: AuthorizationService,
  ) {}

  ngOnInit(): void {}

  async Logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    await this.router.navigate(['/login']);
  }
  CertStructures() {
    this.closeAll();
    this.CertStructure = !this.CertStructure;
  }

  knowledge() {
    this.closeAll();
    this.Isknowledge = !this.Isknowledge;
  }
  practice() {
    this.closeAll();
    this.Ispractice = !this.Ispractice;
  }
  catexam() {
    this.closeAll();
    this.Iscatexam = !this.Iscatexam;
  }
  LiveQuestion() {
    this.closeAll();
    this.liveQ = !this.liveQ;
  }
  sendmail() {
    this.closeAll();
    this.Issendmail = !this.Issendmail;
  }
  studymaterial() {
    this.closeAll();
    this.StudyMaterial = !this.StudyMaterial;
  }
  People() {
    this.closeAll();
    this.Peoples = !this.Peoples;
  }
  Courses() {
    this.closeAll();
    this.Course = !this.Course;
  }
  LiveEvents() {
    this.closeAll();
    this.LiveEvent = !this.LiveEvent;
  }
  practiceApp() {
    this.closeAll();
    this.IspracticeApp = !this.IspracticeApp;
  }
  closeAll() {
    this.Isknowledge = false;
    this.Issendmail = false;
    this.Iscatexam = false;
    this.StudyMaterial = false;
    this.Peoples = false;
    this.CertStructure = false;
    this.Course = false;
    this.Ispractice = false;
    this.LiveEvent = false;
    this.liveQ = false;
    this.IspracticeApp = false;
  }
}
