<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Assessments</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Assessment Name..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewKnowledgeAss()">Add Assessment</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certi_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="knowass_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Assessment Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.knowass_name }}">{{ row.knowass_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="total_question">
          <th mat-header-cell *matHeaderCellDef mat-sort-header># Questions</th>
          <td mat-cell *matCellDef="let row">
            <span title="View Question">{{ row.total_question }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <!-- <mat-icon (click)="addNewKnowledgeAss()">edit</mat-icon> -->
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addKnowledgeass">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Assessment</p>
      <p *ngIf="IsUpdate">Edit Assessment</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form validate [formGroup]="KnowAssForm" class="example-form">
          <div class="formwrapper">
            <div>
              <!-- <label>Certificate</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="CertificateName"
                  formControlName="CertificateName"
                  required
                  [ngClass]="{
                    err:
                      KnowAssForm.controls['CertificateName'].hasError(
                        'required'
                      ) &&
                      (KnowAssForm.controls['CertificateName'].dirty ||
                        KnowAssForm.controls['CertificateName'].touched)
                  }"
                >
                  <option selected="true" value="">Select Certificate</option>
                  <option
                    *ngFor="let item of ListCerficateData"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <!-- <label>Name</label> -->
              <input
                [(ngModel)]="KnowAssname"
                formControlName="KnowAssname"
                placeholder="Name"
                name="name"
                required
                [ngClass]="{
                  err:
                    KnowAssForm.controls['KnowAssname'].hasError('required') &&
                    (KnowAssForm.controls['KnowAssname'].dirty ||
                      KnowAssForm.controls['KnowAssname'].touched)
                }"
              />
            </div>
          </div>
        </form>
        <div class="pagesection pagesection-inside" *ngIf="IsUpdate">
          <div class="search-wrap">
            <div class="search-icon">
              <input
                [(ngModel)]="SearchQuestion"
                (keyup)="filterQuestion()"
                placeholder="Search by Question"
                name="search"
              />
              <span class="material-icons">search</span>
            </div>
          </div>
          <div class="mat-elevation-z8">
            <table
              mat-table
              [dataSource]="dataSourcequestion"
              #quespaginator
              matSort
              style="border-spacing: 0 10px"
            >
              <ng-container matColumnDef="srno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Question
                </th>
                <td mat-cell *matCellDef="let row">{{ row.question }}</td>
              </ng-container>
              <ng-container matColumnDef="answer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Answer
                </th>
                <td mat-cell *matCellDef="let row">{{ row.answer }}</td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                  <img
                    src="../../../assets/images/edit-1.png"
                    (click)="questionedit(row)"
                    alt="Edit"
                    class="action-icon"
                  />
                  <img
                    src="../../../assets/images/remove.png"
                    alt="Delete"
                    class="action-icon"
                  />
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsquestion"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsquestion"
              ></tr>
            </table>
          </div>
        </div>
        <div class="page-footer" *ngIf="IsUpdate">
          <mat-paginator
            style-paginator
            #quespaginator
            [showTotalPages]="0"
            [length]="
              dataSourcequestion.data ? dataSourcequestion.data.length : 0
            "
            *ngIf="matpagination"
            [pageSizeOptions]="[10, 20, 50, 100, 500]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="UpdateKnowAss()">Save</button>
      </div>
    </div>
  </div>
</div>
