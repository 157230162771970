import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { TopicService } from '../../services/topic/topic.service';
import { ExcelService } from '../../services/excelservices/excel.service';
import * as XLSX from 'xlsx';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss'],
})
export class TopicsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'topic_name',
    'domain',
    'subdomain',
    'certificate_name',
    'actions',
  ];
  dataSource: MatTableDataSource<Topiclist>;
  topicsdataSource: Topiclist[] = [];
  Search: any;
  addTopic: boolean = false;
  Certificatelist = [];
  loglist = [];
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  IsUpdate: boolean = false;
  public TopicForm: FormGroup;
  domainname: any;
  subdomainname: any;
  Topicname: any;
  Explanation: any;
  id: any;
  EditHideen = true;
  Domainlist = [];
  subDomainlist = [];
  filterDomain = [];
  filtersubDomain = [];
  Certificate: any;
  SRNumber: any;
  isBulkUpload: boolean = false;
  files: File[] = [];
  myEvent: any;
  progress: any = 0;
  selectBulkupload: boolean = false;
  selectprogress: boolean = false;
  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    'Topic Number': '',
    'Topic Name': '',
    Keywords: '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    'Topic Number': '',
    'Topic Name': '',
    Keywords: '',
  };
  auditHistory = '';
  constructor(
    public fb: FormBuilder,
    public topicService: TopicService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    private excelService: ExcelService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewTopic() {
    this.domainname = '';
    this.subdomainname = '';
    this.Topicname = '';
    this.Certificate = '';
    this.Explanation = '';
    this.SRNumber = '';
    this.addTopic = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addTopic = false;
    this.IsUpdate = false;
    this.domainname = '';
    this.subdomainname = '';
    this.Topicname = '';
    this.SRNumber = '';
    this.Certificate = '';
    this.Explanation = '';
    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      'Topic Number': '',
      'Topic Name': '',
      Keywords: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      'Topic Number': '',
      'Topic Name': '',
      Keywords: '',
    };
    this.auditHistory = '';
  }
  async ngOnInit() {
    this.TopicForm = this.fb.group({
      Topicname: ['', Validators.required],
      Domainname: ['', Validators.required],
      subdomainname: ['', Validators.required],
      Certificate: ['', Validators.required],
      SRNumber: ['', Validators.required],
      Explanation: new FormControl(),
    });
    await this.spinner.show();
    await this.listTopic();
    await this.GetAllDropdown();
    await this.spinner.hide();
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data.Certificate;

            this.Domainlist = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.subDomainlist = list.data.SubDomain;
            this.filtersubDomain = list.data.SubDomain;

            this.Certificate = '';
            this.domainname = '';
            this.subdomainname = '';
            this.Topicname = '';
            this.SRNumber = '';
            this.Explanation = '';
          }
        });
      }
    });
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();

            await this.topicService
              .DeleteTopic(item.id, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          item.topic_name +
                          '</span> is deleted by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'topics',
                          item.id,
                          token,
                        );
                      }
                    });
                  await this.listTopic();
                  await this.spinner.hide();
                  Swal.fire(list.message, 'Topic has been deleted.', 'success');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      }
    });
  }
  async listTopic() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Topiclist[] = [];
        await this.topicService.ListTopic(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                topic_name: element.topic_name,
                d_id: element.d_id,
                sd_id: element.sd_id,
                status: element.status,
                domain: element.domain.domin_name,
                subdomain: element.subdomain.subdomain_name,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                certificate_name: element.certificate.certificate_name,
                certi_id: element.certi_id,
                sr_number: element.sr_number,
                description: element.description,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.topicsdataSource = users;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('topics', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.addTopic = true;
    this.IsUpdate = true;
    this.id = item.id;
    this.domainname = item.d_id;
    this.subdomainname = item.sd_id;
    this.Topicname = item.topic_name;
    this.SRNumber = item.sr_number;
    this.Certificate = item.certi_id;
    this.Explanation = item.description;
    this.Domainlist = this.filterDomain.filter(
      (x) => x.certi_id == this.Certificate,
    );
    this.subDomainlist = this.filtersubDomain.filter(
      (x) => x.d_id == this.domainname,
    );
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find((x) => x.id == this.Certificate)
        ? this.Certificatelist.find((x) => x.id == this.Certificate)
            .certificate_name
        : '',
      Domain: this.Domainlist.find((x) => x.id == this.domainname)
        ? this.Domainlist.find((x) => x.id == this.domainname).domainname
        : '',
      Subdomain: this.subDomainlist.find((x) => x.id == this.subdomainname)
        ? this.subDomainlist.find((x) => x.id == this.subdomainname)
            .subdomain_name
        : '',
      'Topic Number': this.SRNumber,
      'Topic Name': this.Topicname,
      Keywords: this.Explanation,
    };
  }
  certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domainlist = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });
    } else {
      this.Domainlist = this.filterDomain;
    }
    this.domainname = '';
    this.subdomainname = '';
  }
  domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.subDomainlist = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });
    } else {
      this.domainname = '';
      this.subDomainlist = this.subDomainlist;
    }
    this.subdomainname = '';
  }

  async Update() {
    this.markFormTouched(this.TopicForm);
    if (this.TopicForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.topicService
            .UpdateTopic(
              this.Certificate,
              this.Topicname,
              this.domainname,
              this.subdomainname,
              this.id,
              1,
              token,
              this.Explanation,
              this.SRNumber,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      this.newAuditValueArray = {
                        Certificate: this.Certificatelist.find(
                          (x) => x.id == this.Certificate,
                        )
                          ? this.Certificatelist.find(
                              (x) => x.id == this.Certificate,
                            ).certificate_name
                          : '',
                        Domain: this.Domainlist.find(
                          (x) => x.id == this.domainname,
                        )
                          ? this.Domainlist.find((x) => x.id == this.domainname)
                              .domainname
                          : '',
                        Subdomain: this.subDomainlist.find(
                          (x) => x.id == this.subdomainname,
                        )
                          ? this.subDomainlist.find(
                              (x) => x.id == this.subdomainname,
                            ).subdomain_name
                          : '',
                        'Topic Number': this.SRNumber,
                        'Topic Name': this.Topicname,
                        Keywords: this.Explanation,
                      };
                      var audit = this.auth.compareAuditHist(
                        this.oldAuditValueArray,
                        this.newAuditValueArray,
                      );
                      var desc = audit;
                      await this.service.addAdminLogs(
                        desc,
                        'topics',
                        this.id,
                        token,
                      );
                    }
                  });
                await this.listTopic();
                this.closeModal();
                await this.spinner.hide();
                this.service.ShowSuccess('Topic updated successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async onSubmit() {
    this.markFormTouched(this.TopicForm);
    if (this.TopicForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.topicService
            .AddTopic(
              this.Certificate,
              this.Topicname,
              this.domainname,
              this.subdomainname,
              1,
              token,
              this.Explanation,
              this.SRNumber,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      await this.listTopic();

                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc = ' Topic is added by <span> ' + e + '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'topics',
                        this.id,
                        token,
                      );
                      await this.closeModal();
                      await this.spinner.hide();
                      this.service.ShowSuccess(list.message);
                    }
                  });
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async openBulkUploadModal() {
    this.isBulkUpload = true;
  }
  async closeBulkUploadModal() {
    this.isBulkUpload = false;
    this.progress = 0;
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.files = [];
    this.myEvent = '';
  }
  async bulkUpload() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.myEvent.addedFiles[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = jsonData;
    };

    reader.readAsBinaryString(file);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.selectBulkupload = true;
    this.selectprogress = false;
  }
  onSelect(event) {
    let me = this;
    var timeleft = 10;
    me.progress = 0;
    me.selectprogress = true;
    me.selectBulkupload = true;
    this.files.push(event.addedFiles);
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        me.progress = 10 - timeleft;
        me.myEvent = event;
        me.selectBulkupload = false;
        me.selectprogress = false;
        clearInterval(downloadTimer);
      }
      me.progress = 10 - timeleft;
      timeleft -= 1;
    }, 200);
  }
  async bulkCheckDKIM(data) {
    let response = 0;
    data.forEach(async (element) => {
      let domain = element.email.split('@')[1];
      await this.auth.verifyDKIM(domain).then(async (result) => {
        if (result[0].StatusCode == 1) {
          response = 1;
        }
      });
    });
    return response;
  }

  ExportExcel() {
    var certification = Object.assign(
      this.Certificatelist.map((k) => ({ name: k.certificate_name })),
    );
    var domain = Object.assign(
      this.Domainlist.map((k) => ({ name: k.domin_name })),
    );
    var subdomain = Object.assign(
      this.subDomainlist.map((k) => ({ name: k.subdomain_name })),
    );
    var certificationData = [
      {
        workSheet: 'certification',
        rows: certification,
        properties: { state: 'hidden' },
      },
    ];
    var domainData = [
      {
        workSheet: 'domain',
        rows: domain,
        properties: { state: 'hidden' },
      },
    ];
    var subdomainData = [
      {
        workSheet: 'subdomain',
        rows: subdomain,
        properties: { state: 'hidden' },
      },
    ];
    var Topic = [
      {
        name: 'Topic',
        values: [
          {
            header: 'certification',
            value: [
              { name: 'certification!$A$2:$A$' + (certification.length + 1) },
            ],
          },
          {
            header: 'domain',
            value: [{ name: 'domain!$A$2:$A$' + (domain.length + 1) }],
          },
          {
            header: 'subdomain',
            value: [{ name: 'subdomain!$A$2:$A$' + (subdomain.length + 1) }],
          },
          { header: 'topic_no', value: '' },
          { header: 'topic_name', value: '' },
          { header: 'keywords', value: '' },
        ],
        properties: { state: 'visible' },
      },
    ];
    var workbookData = [
      this.transform(Topic)[0],
      certificationData[0],
      domainData[0],
      subdomainData[0],
    ];
    this.excelService.exportAsExcelFile(workbookData, Topic[0].name);
  }
  transform(data) {
    const noOfRowaToGenerate = 5;
    return data.map(({ name, values, properties }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value)
            ? next.value.map(({ name }) => name)
            : next.value,
        }),
        {},
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers),
        properties: properties,
      };
    });
  }

  sortData(event: MatSort) {}

  compareNumber(a: number, b: number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareString(a: string, b: string, isAsc: boolean) {
    return a.localeCompare(b, 'da-DK') * (isAsc ? 1 : -1);
  }
}

export interface Topiclist {
  srno: number;
  id: number;
  topic_name: string;
  d_id: number;
  sd_id: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  certificate_name: string;
  domain: string;
  subdomain: string;
  certi_id: string;
  description: string;
  sr_number: string;
}
