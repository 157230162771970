import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { CatexamService } from '../../services/catexam/catexam.service';
import * as ClassicEditor from '@blowstack/ckeditor5-full-free-build';
import * as XLSX from 'xlsx';
import { ExcelService } from 'src/app/services/excelservices/excel.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-cateexamtestquestion',
  templateUrl: './cateexamtestquestion.component.html',
  styleUrls: ['./cateexamtestquestion.component.scss'],
})
export class CateexamtestquestionComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'domin_name',
    'subdomain_name',
    'topic_name',
    'ketexam_name',
    'title',
    'type',
    'actions',
  ];
  dataSource: MatTableDataSource<courselist>;
  Search: any;
  addCateExamQua: boolean = false;
  Domain = [];
  SubDomain = [];
  Topics = [];
  filterTopics = [];
  Certificates = [];
  filterDomain = [];
  filtersubDomain = [];
  CatExams = [];
  public PraacticeQueForm: FormGroup;
  Domainname: any;
  subdomainname: any;
  Topicname: any;
  title: any;
  option1: any;
  option2: any;
  option3: any;
  option4: any;
  CorrectAnswer: any;
  Type: any;
  test_id: any;
  Explanation: any;
  Id: any;
  IsUpdate: boolean = false;
  CertificateName: any;
  isBulkUpload: boolean = false;
  files: File[] = [];
  myEvent: any;
  progress: any = 0;
  selectBulkupload: boolean = false;
  selectprogress: boolean = false;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  loglist = [];
  public Editor = ClassicEditor;
  editFromExam: boolean = false;
  selecteds = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  DifficultyScore: any;
  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Question: '',
    'Answer A': '',
    'Answer B': '',
    'Answer C': '',
    'Answer D': '',
    Answer: '',
    Explanation: '',
    'Exam Name': '',
    Difficulty: '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Question: '',
    'Answer A': '',
    'Answer B': '',
    'Answer C': '',
    'Answer D': '',
    Answer: '',
    Explanation: '',
    'Exam Name': '',
    Difficulty: '',
  };
  auditHistory = '';
  constructor(
    private location: Location,
    private catTestService: CatexamService,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    private excelService: ExcelService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewCatExamQua() {
    this.Id = '';
    this.Domainname = '';
    this.subdomainname = '';
    this.test_id = '';
    this.Topicname = '';
    this.title = '';
    this.option1 = '';
    this.option2 = '';
    this.option3 = '';
    this.option4 = '';
    this.Type = '';
    this.Explanation = '';
    this.CorrectAnswer = '';
    this.CertificateName = '';
    this.addCateExamQua = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addCateExamQua = false;
    this.IsUpdate = false;
    this.Id = '';
    this.Domainname = '';
    this.subdomainname = '';
    this.test_id = '';
    this.selecteds = [];
    this.Topicname = '';
    this.title = '';
    this.option1 = '';
    this.option2 = '';
    this.option3 = '';
    this.option4 = '';
    this.Type = '';
    this.Explanation = '';
    this.CorrectAnswer = '';
    this.DifficultyScore = '';
    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      'Answer A': '',
      'Answer B': '',
      'Answer C': '',
      'Answer D': '',
      Answer: '',
      Explanation: '',
      'Exam Name': '',
      Difficulty: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      'Answer A': '',
      'Answer B': '',
      'Answer C': '',
      'Answer D': '',
      Answer: '',
      Explanation: '',
      'Exam Name': '',
      Difficulty: '',
    };
    this.auditHistory = '';
  }
  async ngOnInit() {
    this.PraacticeQueForm = this.fb.group({
      CertificateName: ['', Validators.required],
      test_id: new FormControl(),
      catexamsid: ['', Validators.required],
      Domainname: ['', Validators.required],
      subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      title: ['', Validators.required],
      option1: ['', Validators.required],
      option2: ['', Validators.required],
      option3: ['', Validators.required],
      option4: ['', Validators.required],
      CorrectAnswer: ['', Validators.required],
      Type: ['', Validators.required],
      Explanation: ['', Validators.required],
    });
    await this.spinner.show();
    try {
      await this.GetAllDropdown();
      await this.getAllCatExam();
      await this.getAllCatQuestions();
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.catTestService
                  .DeletecateexamQuestion(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        item.title +
                        '</span> Cat Question is deleted by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'cateexamquestion',
                        this.Id,
                        token,
                      );
                      await this.getAllCatQuestions();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'CAT Question has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
  async getAllCatQuestions() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        const users: courselist[] = [];
        var token = session.getIdToken().getJwtToken();
        await this.catTestService
          .ListcateexamQuestion(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                await users.push({
                  srno: srno,
                  id: element.id,
                  test_id: element.test_id,
                  exam_id: element.exam_id ? element.exam_id : [],
                  title: element.title,
                  first_choice: element.first_choice,
                  second_choice: element.second_choice,
                  third_choice: element.third_choice,
                  fourth_choice: element.fourth_choice,
                  answer: element.answer,
                  type: element.type,
                  d_id: element.d_id,
                  sd_id: element.sd_id,
                  topic_id: element.topic_id,
                  ref_link: element.ref_link,
                  youtube_link: element.youtube_link,
                  bookpage_no: element.bookpage_no,
                  blog_url: element.blog_url,
                  createdAt: element.createdAt,
                  updatedAt: element.updatedAt,
                  domin_name: element.domain.domin_name,
                  subdomain_name: element.subdomain.subdomain_name,
                  topic_name: element.topic.topic_name,
                  ketexam_name: element.ketexamtest.ketexam_name,
                  description: element.description,
                  certi_id: element.certi_id,
                  certificate_name: element.certificate.certificate_name,
                });
              });
              this.ActiveRoute.params.subscribe(async (params) => {
                try {
                  var data = JSON.parse(atob(params['id']));
                  let edited = users.filter((x) => x.id == data);
                  this.editFromExam = true;
                  this.edit(edited[0]);
                } catch (error) {}
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
              this.pagination = true;
            }
          });
      }
    });
  }
  async edit(item) {
    this.CertificateName = item.certi_id;
    this.addCateExamQua = true;
    this.IsUpdate = true;
    this.Id = item.id;
    this.Domainname = item.d_id;
    this.subdomainname = item.sd_id;
    this.test_id = item.test_id;
    this.selecteds = item.exam_id.map(Number);
    this.Topicname = item.topic_id;
    this.title = item.title;
    this.option1 = item.first_choice;
    this.option2 = item.second_choice;
    this.option3 = item.third_choice;
    this.option4 = item.fourth_choice;
    this.Type = item.type;
    //DifficultyScore
    item.type == 'VERY SIMPLE'
      ? (this.DifficultyScore = '1')
      : item.type == 'SIMPLE'
        ? (this.DifficultyScore = '1.5')
        : item.type == 'AVERAGE'
          ? (this.DifficultyScore = '2')
          : item.type == 'ABOVE AVERAGE'
            ? (this.DifficultyScore = '2.5')
            : item.type == 'HARD'
              ? (this.DifficultyScore = '3')
              : item.type == 'DIFFICULT'
                ? (this.DifficultyScore = '3.5')
                : item.type == 'TRICKY'
                  ? (this.DifficultyScore = '4')
                  : '0';
    this.Explanation = item.description;
    this.CorrectAnswer = item.answer;
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('cateexamquestion', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.Domain = this.filterDomain.filter(
      (x) => x.certi_id == this.CertificateName,
    );
    this.SubDomain = this.filtersubDomain.filter(
      (x) => x.d_id == this.Domainname,
    );
    this.Topics = this.filterTopics.filter(
      (x) => x.sd_id == this.subdomainname,
    );
    this.oldAuditValueArray = {
      Certificate: this.Certificates.find((x) => x.id == this.CertificateName)
        ? this.Certificates.find((x) => x.id == this.CertificateName)
            .certificate_name
        : '',
      Domain: this.filterDomain.find((x) => x.id == this.Domainname)
        ? this.filterDomain.find((x) => x.id == this.Domainname).domin_name
        : '',
      Subdomain: this.filtersubDomain.find((x) => x.id == this.subdomainname)
        ? this.filtersubDomain.find((x) => x.id == this.subdomainname)
            .subdomain_name
        : '',
      Topic: this.Topics.find((x) => x.id == this.Topicname)
        ? this.Topics.find((x) => x.id == this.Topicname).topic_name
        : '',
      Question: this.title,
      'Answer A': this.option1,
      'Answer B': this.option2,
      'Answer C': this.option3,
      'Answer D': this.option4,
      Answer:
        this.CorrectAnswer == 1
          ? 'Answer A'
          : this.CorrectAnswer == 2
            ? 'Answer B'
            : this.CorrectAnswer == 3
              ? 'Answer C'
              : this.CorrectAnswer == 4
                ? 'Answer D'
                : '',
      Explanation: this.Explanation,
      'Exam Name': this.CatExams.filter((item) => {
        return this.selecteds.includes(item.id);
      })
        .map(function (elem) {
          return elem.ketexam_name;
        })
        .join(','),
      Difficulty: this.Type,
    };
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domain = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.SubDomain = list.data.SubDomain;
            this.Topics = list.data.Topic;
            this.filterTopics = list.data.Topic;
            this.filtersubDomain = list.data.SubDomain;
            this.Certificates = list.data.Certificate;
          }
        });
      }
    });
  }
  async certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });
      this.subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
      this.Domain = this.filterDomain;
    }
  }
  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });
      this.subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
      this.SubDomain = this.filtersubDomain;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topics = this.filterTopics.filter((itemset) => {
        return itemset.sd_id == val;
      });
      this.Topicname = '';
    } else {
      this.Topicname = '';
      this.Topics = this.filterTopics;
    }
  }
  async getAllCatExam() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.catTestService.ListCateExamTest(token).then(async (data) => {
          this.CatExams = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.CatExams = list.data;
            this.test_id = '';
            this.selecteds = [];
          }
        });
      }
    });
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async onSubmit() {
    this.markFormTouched(this.PraacticeQueForm);
    if (this.PraacticeQueForm.valid) {
      await this.spinner.show();
      try {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.catTestService
                .AddcateexamQuestion(
                  this.CertificateName,
                  this.selecteds.toString(),
                  this.selecteds.toString(),
                  this.title,
                  this.option1,
                  this.option2,
                  this.option3,
                  this.option4,
                  this.CorrectAnswer,
                  this.Type,
                  this.Domainname,
                  this.subdomainname,
                  this.Topicname,
                  token,
                  '0',
                  this.Explanation,
                )
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    await this.getAllCatQuestions();
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    var desc =
                      '<span> ' +
                      this.title +
                      '</span> Cat Question is added by <span> ' +
                      e +
                      '</span>';
                    await this.service.addAdminLogs(
                      desc,
                      'cateexamquestion',
                      list.data.id,
                      token,
                    );
                    this.closeModal();
                    await this.spinner.hide();
                    this.service.ShowSuccess(list.message);
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  async UpdateCatQue() {
    this.markFormTouched(this.PraacticeQueForm);
    if (this.PraacticeQueForm.valid) {
      await this.spinner.show();
      try {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.catTestService
                .UpdatecateexamQuestion(
                  this.CertificateName,
                  this.test_id,
                  this.selecteds.toString(),
                  this.title,
                  this.option1,
                  this.option2,
                  this.option3,
                  this.option4,
                  this.CorrectAnswer,
                  this.Type,
                  this.Domainname,
                  this.subdomainname,
                  this.Topicname,
                  this.Id,
                  token,
                  '0',
                  this.Explanation,
                )
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    this.newAuditValueArray = {
                      Certificate: this.Certificates.find(
                        (x) => x.id == this.CertificateName,
                      )
                        ? this.Certificates.find(
                            (x) => x.id == this.CertificateName,
                          ).certificate_name
                        : '',
                      Domain: this.filterDomain.find(
                        (x) => x.id == this.Domainname,
                      )
                        ? this.filterDomain.find((x) => x.id == this.Domainname)
                            .domin_name
                        : '',
                      Subdomain: this.filtersubDomain.find(
                        (x) => x.id == this.subdomainname,
                      )
                        ? this.filtersubDomain.find(
                            (x) => x.id == this.subdomainname,
                          ).subdomain_name
                        : '',
                      Topic: this.Topics.find((x) => x.id == this.Topicname)
                        ? this.Topics.find((x) => x.id == this.Topicname)
                            .topic_name
                        : '',
                      Question: this.title,
                      'Answer A': this.option1,
                      'Answer B': this.option2,
                      'Answer C': this.option3,
                      'Answer D': this.option4,
                      Answer:
                        this.CorrectAnswer == 1
                          ? 'Answer A'
                          : this.CorrectAnswer == 2
                            ? 'Answer B'
                            : this.CorrectAnswer == 3
                              ? 'Answer C'
                              : this.CorrectAnswer == 4
                                ? 'Answer D'
                                : '',
                      Explanation: this.Explanation,
                      'Exam Name': this.CatExams.filter((item) => {
                        return this.selecteds.includes(item.id);
                      })
                        .map(function (elem) {
                          return elem.ketexam_name;
                        })
                        .join(','),
                      Difficulty: this.Type,
                    };
                    var audit = this.auth.compareAuditHist(
                      this.oldAuditValueArray,
                      this.newAuditValueArray,
                    );
                    var desc = audit;
                    await this.service.addAdminLogs(
                      desc,
                      'cateexamquestion',
                      this.Id,
                      token,
                    );
                    await this.getAllCatQuestions();
                    this.closeModal();
                    await this.spinner.hide();
                    this.service.ShowSuccess('Question update successfully');
                    if (this.editFromExam == true) {
                      this.editFromExam = false;
                      await this.spinner.hide();
                      this.location.back();
                    } else {
                      await this.getAllCatQuestions();
                      await this.spinner.hide();
                    }
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  async bulkCheckDKIM(data) {
    let response = 0;
    data.forEach(async (element) => {
      let domain = element.email.split('@')[1];
      await this.auth.verifyDKIM(domain).then(async (result) => {
        if (result[0].StatusCode == 1) {
          response = 1;
        }
      });
    });
    return response;
  }
  async openBulkUploadModal() {
    this.isBulkUpload = true;
  }
  async closeBulkUploadModal() {
    this.isBulkUpload = false;
    this.progress = 0;
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.files = [];
    this.myEvent = '';
  }
  async bulkUpload() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.myEvent.addedFiles[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = jsonData;
      this.spinner.show();
      if ((await this.bulkCheckDKIM(dataString.Sheet1)) == 0) {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.service
                .bulkuploded(dataString.Sheet1, 'User', token)
                .then(
                  (data) => {
                    var list = JSON.parse(<string>data[0].json);
                    if (data[0].status == 200) {
                      this.service.ShowSuccess(
                        list.data.length + ' Students added successfully',
                      );
                      this.closeBulkUploadModal();
                      this.spinner.hide();
                    } else {
                      this.service.ShowError(list.message);
                      this.spinner.hide();
                    }
                  },
                  (error) => {
                    console.log(error);
                    this.spinner.hide();
                  },
                );
            }
          });
      } else {
        this.service.ShowError('Invalid domain found in email');
      }
    };
    reader.readAsBinaryString(file);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.selectBulkupload = true;
    this.selectprogress = false;
  }
  onSelect(event) {
    let me = this;
    var timeleft = 10;
    me.progress = 0;
    me.selectprogress = true;
    me.selectBulkupload = true;
    this.files.push(event.addedFiles);
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        me.progress = 10 - timeleft;
        me.myEvent = event;
        me.selectBulkupload = false;
        me.selectprogress = false;
        clearInterval(downloadTimer);
      }
      me.progress = 10 - timeleft;
      timeleft -= 1;
    }, 200);
  }
  ExportExcel() {
    var certification = Object.assign(
      this.Certificates.map((k) => ({ name: k.certificate_name })),
    );
    var domain = Object.assign(
      this.Domain.map((k) => ({ name: k.domin_name })),
    );
    var subdomain = Object.assign(
      this.SubDomain.map((k) => ({ name: k.subdomain_name })),
    );
    var topic = Object.assign(this.Topics.map((k) => ({ name: k.topic_name })));
    var Exam = Object.assign(
      this.CatExams.map((k) => ({ name: k.ketexam_name })),
    );
    var Correct = [
      {
        workSheet: 'Correct',
        rows: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }],
        properties: { state: 'hidden' },
      },
    ];
    var Type = [
      {
        workSheet: 'Type',
        rows: [
          { name: 'VERY SIMPLE' },
          { name: 'SIMPLE' },
          { name: 'AVERAGE' },
          { name: 'ABOVE AVERAGE' },
          { name: 'HARD' },
          { name: 'DIFFICULT' },
          ,
          { name: 'TRICKY' },
        ],
        properties: { state: 'hidden' },
      },
    ];
    var certificationData = [
      {
        workSheet: 'certification',
        rows: certification,
        properties: { state: 'hidden' },
      },
    ];
    var domainData = [
      {
        workSheet: 'domain',
        rows: domain,
        properties: { state: 'hidden' },
      },
    ];
    var subdomainData = [
      {
        workSheet: 'subdomain',
        rows: subdomain,
        properties: { state: 'hidden' },
      },
    ];
    var topicData = [
      {
        workSheet: 'topic',
        rows: topic,
        properties: { state: 'hidden' },
      },
    ];
    var ExamData = [
      {
        workSheet: 'Exam',
        rows: Exam,
        properties: { state: 'hidden' },
      },
    ];
    var Cat_sample = [
      {
        name: 'Cat_sample',
        values: [
          {
            header: 'certification',
            value: [
              { name: 'certification!$A$2:$A$' + (certification.length + 1) },
            ],
          },
          {
            header: 'domain',
            value: [{ name: 'domain!$A$2:$A$' + (domain.length + 1) }],
          },
          {
            header: 'subdomain',
            value: [{ name: 'subdomain!$A$2:$A$' + (subdomain.length + 1) }],
          },
          {
            header: 'topic',
            value: [{ name: 'topic!$A$2:$A$' + (topic.length + 1) }],
          },
          {
            header: 'Exam',
            value: [{ name: 'Exam!$A$2:$A$' + (Exam.length + 1) }],
          },
          { header: 'question', value: '' },
          { header: 'answer_a', value: '' },
          { header: 'answer_b', value: '' },
          { header: 'answer_c', value: '' },
          { header: 'answer_d', value: '' },
          { header: 'correct_answer', value: [{ name: 'Correct!$A$2:$A$5' }] },
          { header: 'difficult_type', value: [{ name: 'Type!$A$2:$A$8' }] },
          { header: 'explanation', value: '' },
        ],
        properties: { state: 'visible' },
      },
    ];
    var workbookData = [
      this.transform(Cat_sample)[0],
      certificationData[0],
      domainData[0],
      subdomainData[0],
      topicData[0],
      ExamData[0],
      Correct[0],
      Type[0],
    ];
    this.excelService.exportAsExcelFile(workbookData, Cat_sample[0].name);
  }
  transform(data) {
    const noOfRowaToGenerate = 5;
    return data.map(({ name, values, properties }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value)
            ? next.value.map(({ name }) => name)
            : next.value,
        }),
        {},
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers),
        properties: properties,
      };
    });
  }
}
export interface courselist {
  srno: number;
  id: string;
  test_id: string;
  exam_id: string;
  title: string;
  first_choice: string;
  second_choice: string;
  third_choice: string;
  fourth_choice: string;
  answer: string;
  type: string;
  d_id: string;
  sd_id: string;
  topic_id: string;
  ref_link: string;
  youtube_link: string;
  bookpage_no: string;
  blog_url: string;
  createdAt: string;
  updatedAt: string;
  domin_name: string;
  subdomain_name: string;
  topic_name: string;
  ketexam_name: string;
  description: string;
  certi_id: string;
  certificate_name: string;
}
