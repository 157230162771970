import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../service/authorization.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { Location } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-studentreport',
  templateUrl: './studentreport.component.html',
  styleUrls: ['./studentreport.component.scss'],
})
export class StudentreportComponent implements OnInit {
  StudentReport = [];
  FirstSignIn: any;
  LastActivity: any;
  ExamDate: any;
  SurComplete: any;
  Knowledge = [];
  Practice = [];
  Flashcards = { Know: 0, Review: 0, Mark: 0, Unseen: 0 };
  PracticeQ = {
    Right: 0,
    Wrong: 0,
    Marked: 0,
    Unseen: 0,
    Completed: '-',
    percScore: '0',
  };
  Activities = [];
  AverageScore = 0;
  AverageScorePt = 0;
  time_zone = this.auth.currentTimeZone();
  Cohorts = [];
  SelectedCohort: any;
  user_id: any;
  user_email: any;
  Name: any;
  Avatar: any;
  @ViewChild('printable') printable: ElementRef;
  constructor(
    private location: Location,
    public auth: AuthorizationService,
    public service: CohortService,
    public spinner: NgxSpinnerService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        let Details = JSON.parse(atob(params['id']));
        this.user_id = Details.user_id;
        let cohortIds = Details.cohortIds;
        this.user_email = Details.email;
        this.spinner.show();
        await this.getCohortByIds(cohortIds, this.user_id);
      } catch (error) {
        this.router.navigate(['main/students']);
      }
    });
  }

  ngOnInit(): void {}
  back() {
    this.location.back();
  }
  async getCohortByIds(cohortIds, user_id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.spinner.show();
        await this.service
          .getCohortByIds(cohortIds, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.Cohorts = list.data;
              this.SelectedCohort = this.Cohorts[0].id;
              await this.getStudentReport(user_id, this.SelectedCohort);
              await this.getActivityTracker(this.user_id, this.SelectedCohort);
            }
          });
        this.spinner.hide();
      }
    });
  }
  async changeCohort() {
    this.FirstSignIn = '';
    this.LastActivity = '';
    this.ExamDate = '';
    this.SurComplete = false;
    this.AverageScore = 0;
    this.AverageScorePt = 0;
    await this.getStudentReport(this.user_id, this.SelectedCohort);
    await this.getActivityTracker(this.user_id, this.SelectedCohort);
  }
  async getStudentReport(user_id, cohort_id) {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            this.spinner.show();
            await this.service
              .getStudentReport(user_id, cohort_id, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.StudentReport = list.data;

                  let Dt = '-';
                  this.Name = this.StudentReport['Name'];
                  this.Avatar = this.StudentReport['Avatar'];
                  let dtlast = '-';

                  if (this.StudentReport['login_logs'].length > 0) {
                    Dt = formatDate(
                      this.StudentReport['login_logs'][0].createdAt,
                      'yyyy-MM-dd, hh:mm',
                      'en_US',
                    );
                    let len = this.StudentReport['login_logs'].length - 1;
                    dtlast = formatDate(
                      this.StudentReport['login_logs'][len].createdAt,
                      'yyyy-MM-dd, hh:mm',
                      'en_US',
                    );
                  }
                  let exlast = '-';
                  if (
                    this.StudentReport['Examdate'] != '' &&
                    this.StudentReport['Examdate'] != null
                  ) {
                    exlast = formatDate(
                      this.StudentReport['Examdate'],
                      'yyyy-MM-dd, hh:mm',
                      'en_US',
                    );
                  }

                  this.FirstSignIn = Dt;

                  this.LastActivity = dtlast;
                  this.ExamDate = exlast;

                  this.SurComplete = this.StudentReport['SurComplete'];
                  this.Knowledge = this.StudentReport['Knowledge'];
                  this.Practice = this.StudentReport['PracticeTest'];

                  if (this.StudentReport['Flashcards'].length > 0) {
                    this.Flashcards.Know = this.StudentReport['Flashcards'][0]
                      .ttlright
                      ? parseInt(this.StudentReport['Flashcards'][0].ttlright)
                      : 0;
                    this.Flashcards.Mark = this.StudentReport['Flashcards'][0]
                      .ttlbookmark
                      ? parseInt(
                          this.StudentReport['Flashcards'][0].ttlbookmark,
                        )
                      : 0;
                    this.Flashcards.Review = this.StudentReport['Flashcards'][0]
                      .ttlreview
                      ? parseInt(this.StudentReport['Flashcards'][0].ttlreview)
                      : 0;

                    var un =
                      this.Flashcards.Know +
                      this.Flashcards.Mark +
                      this.Flashcards.Review;
                    this.Flashcards.Unseen =
                      parseInt(this.StudentReport['FlashTotal']) - un;
                  }
                  let avg = 0;
                  if (this.Knowledge.length > 0) {
                    this.Knowledge.forEach((element) => {
                      if (element.CompletedOn != '-') {
                        let formattedDt =
                          'Completed at ' +
                          formatDate(
                            element.CompletedOn,
                            'yyyy-MM-dd, hh:mm',
                            'en_US',
                          );
                        element.CompletedOn = formattedDt;
                      }
                      element['Percentage'] = Math.round(
                        (parseInt(element.ttlpointget) * 100) /
                          parseInt(element.ttlpoint),
                      );
                      avg +=
                        (parseInt(element.ttlpointget) * 100) /
                        parseInt(element.ttlpoint);
                    });
                    this.AverageScore = Math.round(avg / this.Knowledge.length);
                  }
                  let avgpt = 0;
                  if (this.Practice.length > 0) {
                    this.Practice.forEach((element) => {
                      if (element.CompletedOn != '-') {
                        let formattedDt =
                          'Completed at ' +
                          formatDate(
                            element.CompletedOn,
                            'yyyy-MM-dd, hh:mm',
                            'en_US',
                          );
                        element.CompletedOn = formattedDt;
                      }
                      element['Percentage'] = Math.round(
                        (parseInt(element.ttlpointget) * 100) /
                          parseInt(element.ttlpoint),
                      );
                      avgpt +=
                        (parseInt(element.ttlpointget) * 100) /
                        parseInt(element.ttlpoint);
                    });
                    this.AverageScorePt = Math.round(
                      avgpt / this.Practice.length,
                    );
                  }
                  var PQUnseen =
                    parseInt(this.StudentReport['PracticeQuestions'].marked) +
                    parseInt(this.StudentReport['PracticeQuestions'].right) +
                    parseInt(this.StudentReport['PracticeQuestions'].wrong);
                  this.PracticeQ.Marked = this.StudentReport[
                    'PracticeQuestions'
                  ].marked
                    ? this.StudentReport['PracticeQuestions'].marked
                    : 0;
                  this.PracticeQ.Right = this.StudentReport['PracticeQuestions']
                    .right
                    ? this.StudentReport['PracticeQuestions'].right
                    : 0;
                  this.PracticeQ.Unseen =
                    parseInt(
                      this.StudentReport['PracticeQuestions'].ttlprques,
                    ) - PQUnseen;
                  this.PracticeQ.Wrong =
                    this.StudentReport['PracticeQuestions'].wrong;
                  if (
                    parseInt(
                      this.StudentReport['PracticeQuestions'].ttlprques,
                    ) > 0
                  ) {
                    this.PracticeQ.percScore = (
                      (this.PracticeQ.Right * 100) /
                      parseInt(
                        this.StudentReport['PracticeQuestions'].ttlprques,
                      )
                    ).toFixed(2);
                  }
                  if (this.StudentReport['PracticeQuestions'].CompletedOn) {
                    let formattedDt =
                      'Completed at ' +
                      formatDate(
                        this.StudentReport['PracticeQuestions'][0].CompletedOn,
                        'yyyy-MM-dd, hh:mm',
                        'en_US',
                      );
                    this.PracticeQ.Completed = formattedDt;
                  }
                }
              });
            this.spinner.hide();
          }
        });
    } catch (error) {
      this.router.navigate(['/login']);
    }
  }
  async getActivityTracker(user_id, cohort_id) {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            this.spinner.show();
            await this.service
              .getActivityTracker(user_id, this.user_email, cohort_id, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.Activities = list.data;
                  this.Activities.forEach((element) => {
                    if (element.flashcard == 'flashcard') {
                      element.test_name =
                        'Flashcards: ' +
                        element.ttlright +
                        ' know, ' +
                        element.ttlreview +
                        ' review, ' +
                        element.ttlbookmark +
                        ' marked, ' +
                        element.ttlnotes +
                        ' notes';
                      let date = new Date(element.activity_date);
                      element.activity_date = this.auth.ConvertDateDDFormat(
                        new Date(element.activity_date),
                        this.time_zone.nameValue,
                        'yyyy-MM-DD',
                      ); //date.toISOString().split('T')[0];
                      element.activity_time = date
                        .toISOString()
                        .split('T')[1]
                        .substr(0, 5);
                      element['sort_date'] = element.activity_date;
                    } else if (element.other == 'other') {
                      element['sort_date'] = element.activity_date;
                      let date = new Date(element.activity_date);
                      element.activity_date = this.auth.ConvertDateDDFormat(
                        date,
                        this.time_zone.nameValue,
                        'yyyy-MM-DD',
                      );
                      element.activity_time = this.auth.ConvertDateDDFormat(
                        date,
                        this.time_zone.nameValue,
                        'HH:mm',
                      );
                    } else {
                      element.pers = 'Completed ' + element.percentage + '%';
                      if (element.percentage == 100) {
                        element.completed = true;
                      } else {
                        element.completed = false;
                      }
                      let date = new Date(element.activity_date);
                      element['sort_date'] = element.activity_date;
                      element.activity_date = this.auth.ConvertDateDDFormat(
                        date,
                        this.time_zone.nameValue,
                        'yyyy-MM-DD',
                      );
                      element.activity_time = this.auth.ConvertDateDDFormat(
                        date,
                        this.time_zone.nameValue,
                        'HH:mm',
                      );
                    }
                  });
                  if (this.StudentReport['onbording_date']) {
                    let date = new Date(this.StudentReport['onbording_date']);

                    let onFlow = {
                      completed: true,
                      activity_date: this.auth.ConvertDateDDFormat(
                        date,
                        this.time_zone.nameValue,
                        'yyyy-MM-DD',
                      ),
                      activity_time: this.auth.ConvertDateDDFormat(
                        date,
                        this.time_zone.nameValue,
                        'HH:mm',
                      ),
                      pers: 'Completed',
                      test_name: 'onboarding',
                      sort_date: this.StudentReport['onbording_date'],
                    };
                    this.Activities.push(onFlow);
                  }
                  if (this.StudentReport['login_logs'].length > 0) {
                    for (
                      let l = 0;
                      l < this.StudentReport['login_logs'].length;
                      l++
                    ) {
                      let date = new Date(
                        this.StudentReport['login_logs'][l].createdAt,
                      );
                      let logs = {
                        completed: true,
                        activity_date: this.auth.ConvertDateDDFormat(
                          date,
                          this.time_zone.nameValue,
                          'yyyy-MM-DD',
                        ),
                        activity_time: this.auth.ConvertDateDDFormat(
                          date,
                          this.time_zone.nameValue,
                          'HH:mm',
                        ),
                        pers: 'Last',
                        test_name: 'Login',
                        sort_date:
                          this.StudentReport['login_logs'][l].createdAt,
                      };
                      this.Activities.push(logs);
                    }
                  }
                  this.Activities.sort(function (a, b) {
                    return (
                      new Date(b.sort_date).getTime() -
                      new Date(a.sort_date).getTime()
                    );
                  });
                  this.Activities.forEach((element) => {
                    element.activity_date = this.auth.ConvertDateDDFormat(
                      element.sort_date,
                      this.time_zone.nameValue,
                      'yyyy-MM-DD',
                    );
                    element.activity_time = this.auth.ConvertDateDDFormat(
                      element.sort_date,
                      this.time_zone.nameValue,
                      'HH:mm',
                    );
                  });
                }
              });
            this.spinner.hide();
          }
        });
    } catch (error) {
      this.router.navigate(['/login']);
    }
  }
  printReport() {
    this.spinner.show();
    let studyprogress = document.getElementById('studyprogress');
    let activitytaracker = document.getElementById('activitytaracker');
    let printReport = document.getElementById('printReport');
    let close = document.getElementById('close');
    studyprogress.style.height = 'auto';
    activitytaracker.style.height = 'auto';
    printReport.style.opacity = '0';
    close.style.opacity = '0';
    let data = document.getElementById('printable');
    let w = document.getElementById('printable').offsetWidth;
    let h =
      document.getElementById('printable').offsetHeight + window.screen.height;
    html2canvas(data, { width: w, height: h, backgroundColor: '#060921' }).then(
      (canvas) => {
        let pdf = new jsPDF('p', 'mm', [w, h]);
        const contentDataURL = canvas.toDataURL('image/png', 1);
        const imgProps = pdf.getImageProperties(contentDataURL);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(contentDataURL, 'PNG', 0, 0, w, h);
        pdf.save(
          this.SelectedCohort +
            new Date().getDate() +
            new Date().getMonth() +
            new Date().getFullYear() +
            new Date().getHours() +
            new Date().getMinutes() +
            new Date().getSeconds() +
            new Date().getMilliseconds() +
            '.pdf',
        );

        studyprogress.style.removeProperty('height');
        activitytaracker.style.removeProperty('height');
        printReport.style.removeProperty('opacity');
        close.style.removeProperty('opacity');
        this.spinner.hide();
      },
    );
  }
}
