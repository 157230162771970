import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { formatDate } from '@angular/common';
import { AdminlogsService } from '../../services/adminlogs/adminlogs.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-adminlogs',
  templateUrl: './adminlogs.component.html',
  styleUrls: ['./adminlogs.component.scss'],
})
export class AdminlogsComponent implements OnInit {
  displayedColumns: string[] = ['SrNo', 'description', 'createdAt'];
  dataSource: MatTableDataSource<Userlist>;
  Search: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  constructor(
    public adminLogsService: AdminlogsService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var isLogin = this.auth.isLoggedIn();
    if (!isLogin) {
      this.router.navigate(['/login']);
    }
  }
  async ngOnInit() {
    await this.spinner.show();
    await this.getAllAdminLogs();
    await this.spinner.hide();
  }
  async getAllAdminLogs() {
    try {
      const users: Userlist[] = [];

      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.adminLogsService
              .getAllAdminLogs(token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  var srno = 0;
                  await list.data.forEach(async (element) => {
                    let dt = formatDate(
                      element.createdAt,
                      'yyyy-MM-dd, hh:mm',
                      'en_US',
                    );
                    srno = srno + 1;
                    await users.push({
                      SrNo: srno,
                      id: element.id,
                      description: element.description,
                      createdAt: dt,
                    });
                  });
                  this.dataSource = new MatTableDataSource(users);
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.matSort;
                  this.filter();
                } else {
                  this.spinner.hide();
                }
              });
          }
        });
    } catch (e) {
      this.spinner.hide();
    }
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
}
export interface Userlist {
  SrNo: number;
  id: string;
  description: string;
  createdAt: string;
}
