<div class="main-report-div" id="componentID" id="printable" #printable>
  <div class="title-div">
    <div class="back-btn">
      <button (click)="back()" id="close">
        <span class="material-icons">close</span>
      </button>
      <img [src]="Avatar" alt="" />
      <label>{{ Name }}</label>
    </div>
    <div class="print" id="printReport">
      <span (click)="printReport()"
        ><svg
          width="25"
          height="23"
          viewBox="0 0 25 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.1109 0.133293C15.9922 0.0471999 15.8485 0 15.7 0H14.7H5.7L5.60501 0.00639017C5.26334 0.0527418 5 0.345617 5 0.7V4.7L5.00639 4.79499C5.05274 5.13666 5.34562 5.4 5.7 5.4L5.79499 5.39361C6.13666 5.34726 6.4 5.05438 6.4 4.7V1.4H14V4.7L14.0064 4.79499C14.0527 5.13666 14.3456 5.4 14.7 5.4H18.7C18.7322 5.4 18.7639 5.39782 18.795 5.39361C19.1367 5.34725 19.4 5.05438 19.4 4.7V3.7L19.3913 3.58983C19.3682 3.445 19.3 3.31005 19.195 3.20503L16.195 0.205025L16.1109 0.133293ZM15.4 4V1.4H15.411L18 3.989V4H15.4ZM21.7 6C23.1301 6 24.3063 7.12159 24.3947 8.5297L24.4 8.7V14.7C24.4 16.1297 23.2788 17.3063 21.8703 17.3947L21.7 17.4H19.4V21.7C19.4 22.0866 19.0866 22.4 18.7 22.4H5.7C5.3134 22.4 5 22.0866 5 21.7V17.4H2.7C1.27058 17.4 0.0937427 16.2781 0.00533142 14.8703L0 14.7V8.7C0 7.27058 1.12186 6.09374 2.52974 6.00533L2.7 6H21.7ZM21.7 16H19.4V12.7C19.4 12.3134 19.0866 12 18.7 12H5.7C5.3134 12 5 12.3134 5 12.7V16H2.7L2.56784 15.9932C1.91557 15.9262 1.4 15.3688 1.4 14.7V8.7L1.40676 8.56784C1.47382 7.91557 2.03119 7.4 2.7 7.4H21.7L21.8323 7.40676C22.485 7.47377 23 8.03081 23 8.7V14.7L22.9932 14.8322C22.9263 15.4848 22.3694 16 21.7 16ZM6.4 21V16.7V13.4H18V16.7V21H6.4ZM5.7 10.2C5.7 11.028 5.0295 11.7 4.2 11.7C3.372 11.7 2.7 11.028 2.7 10.2C2.7 9.3705 3.372 8.7 4.2 8.7C5.0295 8.7 5.7 9.3705 5.7 10.2ZM15.795 16.3936C16.1367 16.3473 16.4 16.0544 16.4 15.7C16.4 15.3134 16.0866 15 15.7 15H8.7L8.60501 15.0064C8.26334 15.0527 8 15.3456 8 15.7C8 16.0866 8.3134 16.4 8.7 16.4H15.7L15.795 16.3936ZM16.4 18.7C16.4 18.3134 16.0866 18 15.7 18H8.7L8.60501 18.0064C8.26334 18.0527 8 18.3456 8 18.7C8 19.0866 8.3134 19.4 8.7 19.4H15.7L15.795 19.3936C16.1367 19.3473 16.4 19.0544 16.4 18.7Z"
            fill="#C2B8FF"
          />
        </svg>
      </span>
    </div>
    <div class="headerlogo">
      <label for="">Select Live Class</label>
      <select [(ngModel)]="SelectedCohort" (change)="changeCohort()">
        <option *ngFor="let item of Cohorts" value="{{ item.id }}">
          {{ item.cohort_name }}
        </option>
      </select>
    </div>
  </div>
  <div class="main-div">
    <div class="study-progress" id="studyprogress">
      <div class="div-head">
        <span class="bg-span"></span>
        <span>Study Progress</span>
      </div>
      <div class="div-body">
        <div class="main-details">
          <div class="assessments-title"></div>
          <div class="assessments-body">
            <div class="exam-detail">
              <div class="student-names">
                <span>First Sign-in</span>
              </div>
              <div class="student-names">
                <span class="rank-date">{{ FirstSignIn }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span>Last Activity</span>
              </div>
              <div class="student-names">
                <span class="rank-date">{{ LastActivity }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span>Exam date</span>
              </div>
              <div class="student-names">
                <span class="rank-date">{{ ExamDate }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span>Survey Completed</span>
              </div>
              <div class="student-names">
                <span class="ranks border-0">
                  <img
                    src="../../../assets/images/true.svg"
                    alt="true"
                    *ngIf="SurComplete != null"
                  />
                  <img
                    src="../../../assets/images/wrong.svg"
                    alt="true"
                    *ngIf="SurComplete == null"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="assessments">
          <div class="assessments-title">
            <span title="Knowledge Assessment" *ngIf="Knowledge.length <= 4"
              >KA</span
            >
            <span title="Knowledge Assessment" *ngIf="Knowledge.length > 4"
              >Knowledge Assessment</span
            >
          </div>
          <div class="assessments-body">
            <div class="exam-detail" *ngFor="let item of Knowledge">
              <div class="student-names">
                <span>{{ item.knowass_name }}</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ item.Percentage }}%</span>
                <span class="rank-date"> {{ item.CompletedOn }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span class="totle-score">Average Score</span>
              </div>
              <div class="student-names">
                <span class="ranks totle-score">{{ AverageScore }}%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="assessments">
          <div class="assessments-title">
            <span title="Practice Test" *ngIf="Practice.length <= 2">PT</span>
            <span title="Practice Test" *ngIf="Practice.length > 2"
              >Practice Test</span
            >
          </div>
          <div class="assessments-body">
            <div class="exam-detail" *ngFor="let item of Practice">
              <div class="student-names">
                <span>{{ item.practice_name }}</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ item.Percentage }}%</span>
                <span class="rank-date"> {{ item.CompletedOn }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span class="totle-score">Average Score</span>
              </div>
              <div class="student-names">
                <span class="ranks totle-score">{{ AverageScorePt }}%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flashcards">
          <div class="assessments-title">
            <span>Flashcards</span>
          </div>
          <div class="assessments-body">
            <div class="exam-detail">
              <div class="student-names">
                <span>Know</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ Flashcards.Know }}</span>
                <span class="rank-date"></span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span>Review</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ Flashcards.Review }}</span>
                <span class="rank-date"></span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span>Marked</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ Flashcards.Mark }}</span>
                <span class="rank-date"></span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span class="totle-score">Unseen</span>
              </div>
              <div class="student-names">
                <span class="ranks totle-score">{{ Flashcards.Unseen }}</span>
                <span class="rank-date"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="practice">
          <div class="assessments-title">
            <span>Practice Question</span>
          </div>
          <div class="assessments-body">
            <div class="exam-detail">
              <div class="student-names">
                <span>Right</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ PracticeQ.Right }}</span>
                <span class="rank-date">{{ PracticeQ.Completed }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span>Wrong</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ PracticeQ.Wrong }}</span>
                <span class="rank-date">{{ PracticeQ.Completed }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span>Marked</span>
              </div>
              <div class="student-names">
                <span class="ranks">{{ PracticeQ.Marked }}</span>
                <span class="rank-date">{{ PracticeQ.Completed }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span class="totle-score">Unseen</span>
              </div>
              <div class="student-names">
                <span class="ranks totle-score">{{ PracticeQ.Unseen }}</span>
                <span class="rank-date">{{ PracticeQ.Completed }}</span>
              </div>
            </div>
            <div class="exam-detail">
              <div class="student-names">
                <span class="totle-score">Practice Exam Score</span>
              </div>
              <div class="student-names">
                <span class="ranks totle-score"
                  >{{ PracticeQ.percScore }}%</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="activity-taracker" id="activitytaracker">
      <div class="div-head">
        <span>Activity Tracker</span>
      </div>
      <div class="task-div-body">
        <div class="main-div" *ngFor="let item of Activities; let i = index">
          <div class="date">
            <span class="done-task"
              ><img
                *ngIf="item.completed"
                src="../../../assets/images/true.svg"
                alt="True"
            /></span>
            <span>{{ item.activity_date }}</span>
            <span>{{ item.activity_time }}</span>
          </div>
          <div class="task-name">
            <p>
              {{ item.pers
              }}<span [ngClass]="{ 'task-color': item.completed }">
                {{ item.test_name }}
              </span>
            </p>
          </div>
        </div>
        <div *ngIf="Activities.length <= 0">
          <p style="text-align: center; margin-top: 30px; color: #c2b8ff">
            No Activities found
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
