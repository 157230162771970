<div class="receipt-wrapper">
  <div
    class="introduce-mail"
    style="
      font-family: arial, 'helvetica neue', helvetica, sans-serif;
      box-sizing: border-box;
      width: 740px;
      margin: 56px auto;
    "
  >
    <div
      class="introduce-mail__wrapper"
      style="
        font-family: arial, 'helvetica neue', helvetica, sans-serif;
        box-sizing: border-box;
        position: relative;
      "
    >
      <div
        class="introduce-mail__container"
        style="
          font-family: arial, 'helvetica neue', helvetica, sans-serif;
          box-sizing: border-box;
          height: fit-content;
          background-image: linear-gradient(#151458, #151458);
          margin: auto;
          border-radius: 8px;
          overflow: hidden;
          float: left;
          width: 100%;
        "
      >
        <div
          class="introduce-mail__hero"
          style="
            font-family: arial, 'helvetica neue', helvetica, sans-serif;
            box-sizing: border-box;
            display: block;
            height: 115px;
            justify-content: space-between;
            padding: 0 40px 8px 54px;
            background-image: url(https://welcomeemailtemplate.s3.us-east-2.amazonaws.com/hero-bg.png);
            background-size: cover;
            float: left;
            width: 100%;
          "
        >
          <div
            class="introduce-mail__hero__logo"
            style="
              font-family: arial, 'helvetica neue', helvetica, sans-serif;
              box-sizing: border-box;
              display: block;
              float: right;
              padding-top: 14px;
              width: 120px;
              height: 120px;
              background: white;
              border-radius: 50%;
              margin-top: 25px;
              text-align: center;
            "
          >
            <img
              src="https://welcomeemailtemplate.s3.us-east-2.amazonaws.com/logo.png"
              style="
                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                box-sizing: border-box;
                width: 100px;
                height: 50px;
              "
            />
            <div
              class="introduce-mail__hero__logo__txt"
              style="
                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                box-sizing: border-box;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 13px;
                color: #000;
              "
            >
              Destination
            </div>
            <div
              class="introduce-mail__hero__logo__subtxt"
              style="
                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                box-sizing: border-box;
                text-transform: uppercase;
                font-size: 9px;
                color: #000;
              "
            >
              Certification
            </div>
          </div>
        </div>
        <div
          class="introduce-mail__main"
          style="
            font-family: arial, 'helvetica neue', helvetica, sans-serif;
            box-sizing: border-box;
            display: block;
            height: calc(100% - 115px);
            padding: 36px 55px 18px 55px;
          "
        >
          <div
            class="introduce-mail__main__overview-section section-margin"
            style="
              font-family: arial, 'helvetica neue', helvetica, sans-serif;
              box-sizing: border-box;
              display: block;
              flex-direction: column;
              margin-bottom: 34px;
              margin: 130px 0 30px 0;
            "
          >
            <h2
              class="normal"
              style="
                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                box-sizing: border-box;
                font-size: 26px;
                line-height: 29px;
                font-weight: normal;
                font-style: normal;
                margin: 0;
                color: #ada1ff;
              "
            >
              Receipt from Destination Certification Inc.
            </h2>
            <p
              style="
                margin: 0px;
                color: #ffffffb5;
                font-size: 12px;
                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                margin-top: 2px;
              "
            >
              Receipt {{ ReceiptData ? ReceiptData.payment_receipt : '' }}
            </p>
            <table style="width: 100%; text-align: left; margin-top: 15px">
              <tr style="font-size: 11px; color: #ffffffb5">
                <th>AMOUNT PAID</th>
                <th>DATE PAID</th>
                <th>PAYMENT METHOD</th>
              </tr>
              <tr style="font-size: 15px; color: #fff; font-weight: 600">
                <td>{{ ReceiptData ? ReceiptData.amount : 0 }}</td>
                <td>{{ ReceiptData ? ReceiptData.createdAt : '' }}</td>
                <td>
                  {{ ReceiptData ? ReceiptData.card_type : '' }}-{{
                    ReceiptData ? ReceiptData.card_last4 : ''
                  }}
                </td>
              </tr>
            </table>
            <p
              style="
                font-size: 11px;
                color: #ffffffb5;
                margin: 15px 0px 0px 0px;
              "
            >
              PURCHASED
            </p>
            <p
              style="
                font-size: 15px;
                color: #fff;
                font-weight: 600;
                margin: 10px 0 0 0;
              "
            >
              {{ ReceiptData ? ReceiptData.plan_description : '' }}
            </p>
            <p
              style="
                font-size: 11px;
                color: #ffffffb5;
                margin: 15px 0px 0px 0px;
              "
            >
              ACCESS
            </p>
            <p
              style="
                font-size: 15px;
                color: #fff;
                font-weight: 600;
                margin: 10px 0 0 0;
              "
            >
              {{ ReceiptData ? ReceiptData.createdAt : '' }} To
              {{ ReceiptData ? ReceiptData.expiry_date : '' }}
            </p>
            <p
              style="
                font-size: 12px;
                color: #ac9ffc;
                font-weight: 600;
                margin: 10px 0 0 10px;
              "
            >
              *Your Memebership will auto-renew monthly starting
              {{ ReceiptData ? ReceiptData.next_date : '' }}
            </p>
            <p
              style="
                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                box-sizing: border-box;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.54;
                color: #ffffffb5;
                border-top: dashed 2px #ada1ff3b;
                margin-top: 30px;
                padding-top: 30px;
              "
            >
              Destination Certification Inc. Victoria, BC, CA
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
