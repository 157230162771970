import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from '../../service/authorization.service';
import { ServicesService } from '../../services.service';

@Component({
  selector: 'app-application-version',
  templateUrl: './application-version.component.html',
  styleUrls: ['./application-version.component.scss'],
})
export class ApplicationVersionComponent implements OnInit {
  version_android = '';
  version_ios = '';
  version_android_ccsp = '';
  version_ios_ccsp = '';
  version_android_practice = '';
  version_ios_practice = '';
  constructor(
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  async ngOnInit() {
    this.getAllAppVersions();
  }
  async getAllAppVersions() {
    try {
      this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.service.getAllAppVersions(token).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                if (list.data.length > 0) {
                  this.version_android = list.data[0].version_android;
                  this.version_ios = list.data[0].version_ios;
                  this.version_android_ccsp = list.data[0].version_android_ccsp;
                  this.version_ios_ccsp = list.data[0].version_ios_ccsp;
                  this.version_android_practice =
                    list.data[0].version_android_practice;
                  this.version_ios_practice = list.data[0].version_ios_practice;
                }
                await this.spinner.hide();
              }
            });
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
  async updateAllAppVersion() {
    try {
      this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            let params = {
              version_android: this.version_android,
              version_ios: this.version_ios,
              version_android_ccsp: this.version_android_ccsp,
              version_ios_ccsp: this.version_ios_ccsp,
              version_android_practice: this.version_android_practice,
              version_ios_practice: this.version_ios_practice,
            };
            await this.service
              .updateAllAppVersion(params, token)
              .then(async (data) => {
                await this.spinner.hide();
              });
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
}
