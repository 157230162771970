<div class="main-report-div" id="printable" #printable>
  <div class="title-div" id="titlediv">
    <div class="back-btn" id="backbtn">
      <button (click)="back()">
        <span class="material-icons">arrow_back</span> Back
      </button>
    </div>
    <div class="master-class">
      <h2 id="cohortreport">
        <span>{{ cohort_name }}</span> Report
      </h2>
      <span id="printicon" (click)="SavePDF()"
        ><svg
          width="25"
          height="23"
          viewBox="0 0 25 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.1109 0.133293C15.9922 0.0471999 15.8485 0 15.7 0H14.7H5.7L5.60501 0.00639017C5.26334 0.0527418 5 0.345617 5 0.7V4.7L5.00639 4.79499C5.05274 5.13666 5.34562 5.4 5.7 5.4L5.79499 5.39361C6.13666 5.34726 6.4 5.05438 6.4 4.7V1.4H14V4.7L14.0064 4.79499C14.0527 5.13666 14.3456 5.4 14.7 5.4H18.7C18.7322 5.4 18.7639 5.39782 18.795 5.39361C19.1367 5.34725 19.4 5.05438 19.4 4.7V3.7L19.3913 3.58983C19.3682 3.445 19.3 3.31005 19.195 3.20503L16.195 0.205025L16.1109 0.133293ZM15.4 4V1.4H15.411L18 3.989V4H15.4ZM21.7 6C23.1301 6 24.3063 7.12159 24.3947 8.5297L24.4 8.7V14.7C24.4 16.1297 23.2788 17.3063 21.8703 17.3947L21.7 17.4H19.4V21.7C19.4 22.0866 19.0866 22.4 18.7 22.4H5.7C5.3134 22.4 5 22.0866 5 21.7V17.4H2.7C1.27058 17.4 0.0937427 16.2781 0.00533142 14.8703L0 14.7V8.7C0 7.27058 1.12186 6.09374 2.52974 6.00533L2.7 6H21.7ZM21.7 16H19.4V12.7C19.4 12.3134 19.0866 12 18.7 12H5.7C5.3134 12 5 12.3134 5 12.7V16H2.7L2.56784 15.9932C1.91557 15.9262 1.4 15.3688 1.4 14.7V8.7L1.40676 8.56784C1.47382 7.91557 2.03119 7.4 2.7 7.4H21.7L21.8323 7.40676C22.485 7.47377 23 8.03081 23 8.7V14.7L22.9932 14.8322C22.9263 15.4848 22.3694 16 21.7 16ZM6.4 21V16.7V13.4H18V16.7V21H6.4ZM5.7 10.2C5.7 11.028 5.0295 11.7 4.2 11.7C3.372 11.7 2.7 11.028 2.7 10.2C2.7 9.3705 3.372 8.7 4.2 8.7C5.0295 8.7 5.7 9.3705 5.7 10.2ZM15.795 16.3936C16.1367 16.3473 16.4 16.0544 16.4 15.7C16.4 15.3134 16.0866 15 15.7 15H8.7L8.60501 15.0064C8.26334 15.0527 8 15.3456 8 15.7C8 16.0866 8.3134 16.4 8.7 16.4H15.7L15.795 16.3936ZM16.4 18.7C16.4 18.3134 16.0866 18 15.7 18H8.7L8.60501 18.0064C8.26334 18.0527 8 18.3456 8 18.7C8 19.0866 8.3134 19.4 8.7 19.4H15.7L15.795 19.3936C16.1367 19.3473 16.4 19.0544 16.4 18.7Z"
            fill="#C2B8FF"
          />
        </svg>
      </span>
    </div>
    <div class="headerlogo">
      <img src="../../../assets/images/logo.png" alt="CISSP" id="printlogo" />
    </div>
  </div>
  <div class="cohort-table">
    <div class="limiter">
      <div class="container-table100">
        <div class="wrap-table100">
          <div class="table100 ver1" id="dflex">
            <div class="table100-firstcol">
              <table border="1" id="left-table">
                <thead>
                  <tr>
                    <th class="bg-color"></th>
                  </tr>
                  <tr class="row100 head">
                    <th class="cell100 column1 averageScore" id="height129">
                      Student Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="row100 body studentName"
                    *ngFor="let item of Reports"
                  >
                    <td
                      class="cell100 column2 UserBackground"
                      title="{{ item.name }}"
                      style="white-space: nowrap"
                    >
                      <div class="user-name">
                        <img
                          src="{{ item.avatar_url }}"
                          style="
                            height: 38px;
                            width: 38px;
                            border-radius: 50%;
                            margin: 0 10px;
                          "
                          onError="this.src='../../assets/images/avatar.png'"
                          alt="user"
                        />
                        <p>
                          {{ item.name ? item.name : '-' }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="wrap-table100-nextcols js-pscroll">
              <div class="table100-nextcols">
                <table border="1" id="right-table">
                  <thead>
                    <tr>
                      <th
                        [attr.colspan]="KnowledgeAssessments.length + 1"
                        class="bg-color"
                      >
                        <span>Knowledge Assessments</span>
                      </th>
                      <th colspan="4" class="bg-color">
                        <span>Flashcard App</span>
                      </th>
                      <th colspan="4" class="bg-color">
                        <span>Practice Tests</span>
                      </th>
                      <th colspan="1" class="bg-color"></th>
                    </tr>
                    <tr class="row100 head" id="height110">
                      <th
                        class="cell100 column2"
                        *ngFor="let item of KnowledgeAssessments"
                      >
                        <div class="knowldgeAssement">
                          {{ item.knowass_name }}
                        </div>
                      </th>
                      <th class="cell100 column18 color">
                        <div class="knowldgeAssement averageScore">
                          Average Score
                        </div>
                      </th>
                      <th class="cell100 column19">
                        <div class="knowldgeAssement">Know</div>
                      </th>
                      <th class="cell100 column20">
                        <div class="knowldgeAssement">Review</div>
                      </th>
                      <th class="cell100 column21">
                        <div class="knowldgeAssement">Marked</div>
                      </th>
                      <th class="cell100 column22 color">
                        <div class="knowldgeAssement averageScore">Unseen</div>
                      </th>
                      <th class="cell100 column23">
                        <div class="knowldgeAssement">Right</div>
                      </th>
                      <th class="cell100 column24">
                        <div class="knowldgeAssement">Wrong</div>
                      </th>
                      <th class="cell100 column25">
                        <div class="knowldgeAssement">Marked</div>
                      </th>
                      <th class="cell100 column26 color">
                        <div class="knowldgeAssement averageScore">Unseen</div>
                      </th>
                      <th class="cell100 column27 color">
                        <div class="knowldgeAssement averageScore">
                          Practice Exam Score
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="row100 body height46"
                      *ngFor="let item of Results; let i = index"
                    >
                      <th
                        class="cell100 column2"
                        *ngFor="let mainKA of KnowledgeAssessments"
                      >
                        <span *ngFor="let ka of item.KnowAss">
                          <span
                            *ngIf="
                              mainKA.knowass_name == ka.knowass_name && !ka.type
                            "
                          >
                            {{ ka.percent }}%
                          </span>
                          <span
                            *ngIf="
                              mainKA.knowass_name == ka.knowass_name && ka.type
                            "
                          >
                            <svg
                              class="svgSize"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                                fill="url(#paint0_linear)"
                              />
                              <path
                                d="M5.59902 10.5144L10.4539 5.88573"
                                stroke="white"
                                stroke-width="1.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.4537 10.5144L5.59882 5.88573"
                                stroke="white"
                                stroke-width="1.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear"
                                  x1="16"
                                  y1="0"
                                  x2="0"
                                  y2="0"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#E44A44" />
                                  <stop offset="1" stop-color="#FF8D68" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </span>
                        </span>
                        <span *ngIf="item.KnowAss.length <= 0">
                          <svg
                            class="svgSize"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                              fill="url(#paint0_linear)"
                            />
                            <path
                              d="M5.59902 10.5144L10.4539 5.88573"
                              stroke="white"
                              stroke-width="1.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.4537 10.5144L5.59882 5.88573"
                              stroke="white"
                              stroke-width="1.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear"
                                x1="16"
                                y1="0"
                                x2="0"
                                y2="0"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#E44A44" />
                                <stop offset="1" stop-color="#FF8D68" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </span>
                      </th>
                      <th class="cell100 column18 color averageScore">
                        {{ item.AvgKnowPer }}%
                      </th>
                      <th class="cell100 column19">
                        {{ item.Flashcards.ttlright }}
                      </th>
                      <th class="cell100 column20">
                        {{ item.Flashcards.ttlreview }}
                      </th>
                      <th class="cell100 column21">
                        {{ item.Flashcards.ttlbookmark }}
                      </th>
                      <th class="cell100 column22 color averageScore">
                        {{ item.Flashcards.ttlunseen }}
                      </th>
                      <th class="cell100 column23">
                        {{ item.Practice.right }}
                      </th>
                      <th class="cell100 column24">
                        {{ item.Practice.wrong }}
                      </th>
                      <th class="cell100 column25">
                        {{ item.Practice.marked }}
                      </th>
                      <th class="cell100 column26 color averageScore">
                        {{ item.Practice.unseen }}
                      </th>
                      <th class="cell100 column27 color averageScore">
                        {{ item.Practice.percent }}%
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
