import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from '../services.service';
import { AuthorizationService } from '../service/authorization.service';
import { CryptoService } from '../services/crypto/crypto.service';
@Component({
  selector: 'app-verifyauthy',
  templateUrl: './verifyauthy.component.html',
  styleUrls: ['./verifyauthy.component.scss'],
})
export class VerifyauthyComponent implements OnInit {
  data: any;
  code: any;
  dataqr: any;
  AuthyShow = false;
  elementType: 'url';
  value: any = '';
  public LoginForm: FormGroup;
  newdata: any;
  devicecode: any;
  authyPasswordEnbled: boolean = false;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthorizationService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    private crypto: CryptoService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(this.crypto.dec(params['data']));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  async ngOnInit() {
    this.LoginForm = this.fb.group({
      code: ['', Validators.required],
    });
    this.getQRCode();
  }
  async getQRCode() {
    try {
      await this.spinner.show();
      var passwordset = this.data.password;
      if (passwordset && this.data.email) {
        await this.auth
          .Getqrcode(this.data.email, passwordset)
          .then((dataset) => {
            if (dataset && dataset[0].StatusCode == 1) {
              this.data.password = passwordset;
              this.value = dataset[0].code;
              this.devicecode = dataset[0].devicecode;
              this.newdata = dataset;
              this.spinner.hide();
            } else {
              if (
                dataset[0].message == 'callback.totpRequired is not a function'
              ) {
                this.authyPasswordEnbled = true;
                this.spinner.hide();
              } else {
                this.authyPasswordEnbled = true;
                this.spinner.hide();
              }
            }
          });
      } else {
        this.spinner.hide();
      }
    } catch {
      this.spinner.hide();
    }
  }
  async thankyou() {
    if (this.code) {
      if (this.code.trim() && this.code.length == 6) {
        this.spinner.show();
        await this.auth
          .verify(this.data.email, this.data.password, this.code, 0)
          .then(async (set) => {
            if (set[0].StatusCode == 1) {
              window.sessionStorage.setItem('logintrue', 'true');
              this.router.navigate(['/main/dashboard']);
              await this.spinner.hide();
            } else {
              await this.spinner.hide();
              this.service.ShowError(
                'You have entered Invalid code. Please try again with valid code.',
              );
            }
          });
        await this.spinner.hide();
      } else {
        await this.spinner.hide();
        this.service.ShowError(
          'Please enter the 6-digit one-time password that has been generated by your app',
        );
      }
    } else {
      this.service.ShowError(
        'Please enter the 6-digit one-time password that has been generated by your app ',
      );
    }
    await this.spinner.hide();
  }
  async GeneratedQR() {
    try {
      await this.spinner.show();
      var passwordset = this.data.password;
      if (passwordset && this.data.email) {
        await this.auth
          .ResetMFASetup(this.data.email, passwordset)
          .then(async (dataset) => {
            await this.getQRCode();
            this.authyPasswordEnbled = false;
            var userdata = {
              email: this.data.email,
              password: passwordset,
            };
            var datas = this.crypto.enc(JSON.stringify(userdata));
            this.router.navigate([`authypassword/${datas}`]);
          });
        this.spinner.hide();
      } else {
        this.router.navigate(['/login']);
        this.spinner.hide();
      }
    } catch {
      this.router.navigate(['/login']);
      this.spinner.hide();
    }
  }
  onOtpChange($event) {
    this.code = $event;
  }
}
