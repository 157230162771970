import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../../service/authorization.service';
import { CourseService } from '../../../services/course/course.service';
import { DomainService } from '../../../services/domain/domain.service';
import { PracticeService } from '../../../services/practice/practice.service';
import { TopicService } from '../../../services/topic/topic.service';

@Component({
  selector: 'app-createpracticeque',
  templateUrl: './createpracticeque.component.html',
  styleUrls: ['./createpracticeque.component.scss'],
})
export class CreatepracticequeComponent implements OnInit {
  id: any;
  EditHideen = true;
  public PraacticeQueForm: FormGroup;
  Free_paid: any;
  Domainname: any;
  subdomainname: any;
  Topicname: any;
  title: any;
  option1: any;
  option2: any;
  option3: any;
  option4: any;
  CorrectAnswer: any;
  Type: any;
  Explanation: any;
  ListCerficateData = [];
  Domain = [];
  SubDomain = [];
  Topic = [];
  CourseName: any;
  ListCoursedata = [];
  Certificate: any;
  filtersubDomain = [];
  filterTpoic = [];
  filterDomain = [];
  constructor(
    public topicService: TopicService,
    public practiceService: PracticeService,
    public domainService: DomainService,
    public courseService: CourseService,
    public auth: AuthorizationService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    } else {
      this.ActiveRoute.params.subscribe(async (params) => {
        try {
          this.id = JSON.parse(atob(params['id']));
          this.EditHideen = false;
        } catch (error) {
          this.router.navigate(['main/practiceappques/createpracticeappques']);
        }
      });
    }
  }

  async ngOnInit() {
    this.PraacticeQueForm = this.fb.group({
      Free_paid: ['', Validators.required],
      Domainname: ['', Validators.required],
      subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      title: ['', Validators.required],
      option1: ['', Validators.required],
      option2: ['', Validators.required],
      option3: ['', Validators.required],
      option4: ['', Validators.required],
      CorrectAnswer: ['', Validators.required],
      Type: ['', Validators.required],
      Explanation: ['', Validators.required],
      Certificate: ['', Validators.required],
      CourseName: ['', Validators.required],
    });
    await this.spinner.show();
    await this.ListCourse();
    await this.ListCertificate();
    await this.listDomain();
    await this.listSubDomain();
    await this.listTopic();
    await this.spinner.hide();

    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.id = JSON.parse(atob(params['id']));
        if (this.id != null && this.id != undefined && this.id != '') {
          await this.EditRecord();
        }
        await this.spinner.hide();
      } catch (error) {
        this.Free_paid = '';
        this.Domainname = '';
        this.subdomainname = '';
        this.Topicname = '';
        this.title = '';
        this.option1 = '';
        this.option2 = '';
        this.option3 = '';
        this.option4 = '';
        this.CorrectAnswer = '';
        this.Type = '';
        this.Explanation = '';
        this.CourseName = '';
        this.Certificate = '';
        await this.spinner.hide();
      }
    });
  }
  async onSubmit() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.practiceService
          .AddPracticeAppQuestion(
            this.Free_paid,
            this.Topicname,
            this.title,
            this.option1,
            this.option2,
            this.option3,
            this.option4,
            this.CorrectAnswer,
            this.Type,
            this.Domainname,
            this.subdomainname,
            this.Topicname,
            this.Certificate,
            this.CourseName,
            this.Explanation,
            token,
          )
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowSuccess(list.message);
              this.router.navigate(['main/practiceappques']);
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          });
      }
    });
  }
  async UpdatePracticeQue() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.practiceService
          .UpdatePracticeAppQuestion(
            this.Free_paid,
            this.Topicname,
            this.title,
            this.option1,
            this.option2,
            this.option3,
            this.option4,
            this.CorrectAnswer,
            this.Type,
            this.Domainname,
            this.subdomainname,
            this.Topicname,
            this.id,
            this.Certificate,
            this.CourseName,
            this.Explanation,
            token,
          )
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowSuccess(list.message);
              this.router.navigate(['main/practiceappques']);
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          });
      }
    });
  }

  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCerficateData = list.data;
          }
        });
      }
    });
  }

  async listDomain() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.domainService.ListDomain(token).then(async (data) => {
          this.Domain = [];
          this.filterDomain = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domain = list.data;
            this.filterDomain = list.data;
          }
        });
      }
    });
  }
  async listSubDomain() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.domainService.ListSubDomain(token).then(async (data) => {
          this.SubDomain = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.SubDomain = list.data;
            this.filtersubDomain = list.data;
          }
        });
      }
    });
  }
  async listTopic() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.topicService.ListTopic(token).then(async (data) => {
          this.Topic = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Topic = list.data;
            this.filterTpoic = list.data;
          }
        });
      }
    });
  }
  async EditRecord() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.practiceService
          .IdPracticeAppQuestion(this.id, token)
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.Free_paid = list.data.free_paid;
              this.Domainname = list.data.d_id;
              this.subdomainname = list.data.sd_id;
              this.Topicname = list.data.topic_id;
              this.title = list.data.title;
              this.option1 = list.data.first_choice;
              this.option2 = list.data.second_choice;
              this.option3 = list.data.third_choice;
              this.option4 = list.data.fourth_choice;
              this.CorrectAnswer = list.data.answer;
              this.Type = list.data.type;
              this.Explanation = 'fddf';
              this.Certificate = list.data.certi_id;
              this.CourseName = list.datacource_id;
            }
          });
      }
    });
  }
  async ListCourse() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.courseService.ListCourses(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCoursedata = list.data;
          }
        });
      }
    });
  }
  async Coursechange(ev) {
    this.ListCoursedata.forEach((element) => {
      if (element.id == ev.target.value) {
        this.Certificate = element.certi_id;
      }
    });
  }
  async certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return (
          itemset.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) >
          -1
        );
      });
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
      this.Domain = this.filterDomain;
    }
  }

  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return (
          itemset.d_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
      this.SubDomain = this.filtersubDomain;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return (
          itemset.sd_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.Topicname = '';
      this.Topic = this.filterTpoic;
    }
  }
}
