import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';
@Injectable({
  providedIn: 'root',
})
export class KnowledgeService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  //start KnowAss
  async AddKnowAss(certi_id, knowass_name, cource_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          knowass_name: knowass_name,
          cource_id: cource_id,
          status: '0',
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOW_ASSES_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateKnowAss(certi_id, knowass_name, id, cource_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          knowass_name: knowass_name,
          cource_id: cource_id,

          status: '0',
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.KNOW_ASSES + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteKnowAss(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.KNOW_ASSES + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListKnowAss(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOW_ASSES_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async IdKnowAss(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.KNOW_ASSES + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End KnowAss

  //start knowAssque
  async AddknowAssque(
    ass_id,
    question,
    d_id,
    sd_id,
    topic_id,
    answer,
    token,
    point,
    description,
    ref_link,
    certi_id,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: ass_id,
          question: question,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          answer: answer,
          ref_link: ref_link,
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: '0',
          point: parseInt(point),
          description: description,
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOW_ASSES_QUES_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateknowAssque(
    id,
    ass_id,
    question,
    d_id,
    sd_id,
    topic_id,
    answer,
    token,
    point,
    description,
    references,
    certi_id,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: ass_id,
          question: question,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          answer: answer,
          ref_link: references,
          youtube_link: '',
          bookpage_no: '',
          blog_url: '',
          status: 0,
          point: parseInt(point),
          description: description,
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL +
        environment.KNOW_ASSES_QUES +
        this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteknowAssque(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL +
        environment.KNOW_ASSES_QUES +
        this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListknowAssque(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOW_ASSES_QUES_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async IdknowAssque(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL +
        environment.KNOW_ASSES_QUES +
        this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getKnowAssQueById(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOW_ASSES_QUES_BY_ID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End knowAssque

  async KnowDomainReport(
    user_id,
    certi_id,
    ass_id,
    RowNumber,
    cohort_id,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cource_id: certi_id,
          ass_id: ass_id,
          RowNumber: RowNumber,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOWASSANS_GETDOMAIN_REPORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async ListKnowAssCerfication(user_id, certi_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cource_id: certi_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOWASSANS_ASS_REPORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async FilterKnowQueResultShow(
    ass_id: number,
    user_id: number,
    cohort_id,
    token,
    RowNumber,
  ) {
    let method_id = localStorage.getItem('method');
    let method_type = localStorage.getItem('course_type');
    let material_type = 'Video';

    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: ass_id,
          user_id: user_id,
          cohort_id: cohort_id,
          method_id: method_id,
          method_type: method_type,
          material_type: material_type,
          RowNumber: RowNumber,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.KNOWASSANS_FILTERS_QUEST;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err }]);
        },
      );
    });
  }
}
