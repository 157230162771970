import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  async getAllAdmin(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADMIN_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async addUser(name, email, roll_id, password, permission: number, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          email: email,
          roll_id: roll_id,
          password: password,
          permission: permission,
          email_verified_at: false,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async deleteuser(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async updateUser(id, name, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS + this.crypto.enc(id);
      var data = {
        data: this.crypto.encrypt(
          JSON.stringify({
            name: name,
          }),
        ),
      };
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async updateUserPage(
    id,
    name,
    allow_access,
    company_id,
    schedule_date,
    aliasname,
    time_zone,
    Is_Selpaced,
    mentor_id,
    token,
  ) {
    if (allow_access == true) {
      allow_access = 1;
    } else {
      allow_access = 'false';
    }
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS + this.crypto.enc(id);
      var data = {
        data: this.crypto.encrypt(
          JSON.stringify({
            name: name,
            allow_access: allow_access.toString(),
            company_id: company_id,
            mentor_id: mentor_id,
            schedule_date: schedule_date,
            aliasname: aliasname,
            user_time_zone: time_zone,
            is_selfpaced: Is_Selpaced,
          }),
        ),
      };
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async free_purchase(id, free_flashcard, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS + this.crypto.enc(id);
      var data = {
        data: this.crypto.encrypt(
          JSON.stringify({
            free_flashcard: free_flashcard,
          }),
        ),
      };
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async CCSPfree_purchase(id, free_flashccsp, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS + this.crypto.enc(id);
      var data = {
        data: this.crypto.encrypt(
          JSON.stringify({
            free_flashccsp: free_flashccsp,
          }),
        ),
      };
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }

  async countdash(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CLIENT_COUNT_DASH;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getSelfPacedClasses(certi_id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELFPACED_CLASSES;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }

  async addStudentToSelfCohort(cohort_id, user_id) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohortIds: cohort_id,
          user_id: user_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(
          environment.BASE_URL + environment.ADD_SELFCOHORT_STD,
          data,
          options,
        )
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async addUserAccessDuration(data) {
    data = {
      data: this.crypto.encrypt(JSON.stringify(data)),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(
          environment.BASE_URL + environment.SAVE_USER_ACCESS_DURATION,
          data,
          options,
        )
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async savePaymentTransaction(data) {
    data = {
      data: this.crypto.encrypt(JSON.stringify(data)),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(
          environment.BASE_URL + environment.PAYMENT_TRANSACTION,
          data,
          options,
        )
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async updatePaymentTransaction(data) {
    data = {
      data: this.crypto.encrypt(JSON.stringify(data)),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(environment.BASE_URL + environment.UPDATE_PAY_TRN, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async getEnrollmentDetails(user_id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ENROLL_DETAILS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async removeStudentFromEnroll(id, user_id, cohort_id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.REMOVE_ENROLL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async payTransactionById(id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.PAY_TRN_BYID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
}
