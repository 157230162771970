import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async DeleteCourses(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COURSE + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async AddCourses(
    course_name,
    certi_id,
    description,
    access,
    pass_guarantee,
    fee,
    days,
    short_name,
    token,
    image,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          course_name: course_name,
          certi_id: certi_id,
          description: description,
          access: access,
          pass_guarantee: pass_guarantee,
          fee: fee,
          days: days,
          status: '0',
          short_name: short_name,
          img_path: image,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COURSE_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          if (res.status === 200) {
            resolve([{ status: res.status, json: res['_body'] }]);
          }
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateCourses(
    course_name,
    certi_id,
    description,
    access,
    pass_guarantee,
    fee,
    id,
    days,
    short_name,
    token,
    image,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          course_name: course_name,
          certi_id: certi_id,
          description: description,
          access: access,
          pass_guarantee: pass_guarantee,
          fee: fee,
          status: '0',
          days: days,
          short_name: short_name,
          img_path: image,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COURSE + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async IdCourses(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COURSE + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async ListCourses(token) {
    return new Promise(async (resolve) => {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COURSE_ALL;
      await this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
