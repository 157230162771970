<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Courses</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search.."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewCourse()">Add Course</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="course_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certi_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Course Name</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.course_name }}">{{ row.course_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Certificate</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <!-- <mat-icon (click)="addNewCourse()">edit</mat-icon> -->
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addCourse">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Course</p>
      <p *ngIf="IsUpdate">Edit Course</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="CoureseForm">
          <div class="formwrapper">
            <div>
              <label>Course Name</label>
              <input
                [(ngModel)]="CourseName"
                formControlName="coursename"
                placeholder="Title"
                required
                [ngClass]="{
                  err:
                    CoureseForm.controls['coursename'].hasError('required') &&
                    (CoureseForm.controls['coursename'].dirty ||
                      CoureseForm.controls['coursename'].touched)
                }"
              />
            </div>
            <div>
              <label>Certification</label>
              <div class="select-box">
                <select
                  [(ngModel)]="CertificateName"
                  formControlName="CertificateName"
                  required
                  [ngClass]="{
                    err:
                      CoureseForm.controls['CertificateName'].hasError(
                        'required'
                      ) &&
                      (CoureseForm.controls['CertificateName'].dirty ||
                        CoureseForm.controls['CertificateName'].touched)
                  }"
                >
                  <option selected="true" value="">Select</option>
                  <option
                    *ngFor="let item of ListCerficateData"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Pass Guarantee</label>
              <div class="select-box">
                <select
                  [(ngModel)]="Guarantee"
                  formControlName="Guarantee"
                  required
                  #passGuarantee
                  [ngClass]="{
                    err:
                      CoureseForm.controls['Guarantee'].hasError('required') &&
                      (CoureseForm.controls['Guarantee'].dirty ||
                        CoureseForm.controls['Guarantee'].touched)
                  }"
                >
                  <option selected="true" value="">Select</option>
                  <option value="Free">Free</option>
                  <option value="Paid">Paid</option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Fees</label>
              <input
                [(ngModel)]="Fee"
                min="1"
                oninput="validity.valid||(value='');"
                formControlName="Fee"
                placeholder="Fees"
                type="number"
                required
                [ngClass]="{
                  err:
                    CoureseForm.controls['Fee'].hasError('required') &&
                    (CoureseForm.controls['Fee'].dirty ||
                      CoureseForm.controls['Fee'].touched)
                }"
              />
            </div>
            <div>
              <label>Days</label>
              <input
                [(ngModel)]="days"
                min="1"
                oninput="validity.valid||(value='');"
                formControlName="Days"
                placeholder="Days"
                type="number"
                required
                [ngClass]="{
                  err:
                    CoureseForm.controls['Days'].hasError('required') &&
                    (CoureseForm.controls['Days'].dirty ||
                      CoureseForm.controls['Days'].touched)
                }"
              />
            </div>
            <div>
              <label>Shortname</label>
              <input
                [(ngModel)]="CourseShort"
                formControlName="CourseShort"
                placeholder="Shortname"
                type="text"
                required
                [ngClass]="{
                  err:
                    CoureseForm.controls['CourseShort'].hasError('required') &&
                    (CoureseForm.controls['CourseShort'].dirty ||
                      CoureseForm.controls['CourseShort'].touched)
                }"
              />
            </div>
            <div>
              <label>Add File</label>
              <input
                type="file"
                type="file"
                id="Courseimg"
                formControlName="imageset"
                [(ngModel)]="imageset"
                name="myImage"
                accept="image/x-png,image/jpeg"
                (change)="previewFile($event)"
              />
            </div>
          </div>
          <div>
            <span class="Ck-Editor-span">Description</span>
            <!-- [editor]="Editor" -->
            <ckeditor
              formControlName="description"
              [(ngModel)]="description"
              id="description"
              name="description"
            >
            </ckeditor>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="sub-btn">
        <button class="cancel-btn" (click)="closeModal()">Cancel</button>
        <div *ngIf="!IsUpdate">
          <button type="button" class="submit-btn" (click)="onSubmit()">
            Save
          </button>
        </div>
        <div *ngIf="IsUpdate">
          <button type="button" class="submit-btn" (click)="UpdateCourse()">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
