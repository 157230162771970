import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  async addUsertemp(
    name,
    email,
    roll_id,
    password,
    permission: number,
    mobile,
    schedule_date,
    company_id,
    free_flashcard,
    free_practque,
    opt_in,
    allow_access,
    time_zone,
    Is_Selpaced,
    mentor_id,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          email: email,
          roll_id: roll_id,
          password: password,
          permission: permission,
          email_verified_at: false,
          is_temp: 1,
          mobile: mobile,
          schedule_date: schedule_date,
          company_id: company_id,
          free_flashcard: free_flashcard.toString(),
          free_practque: free_practque.toString(),
          opt_in: opt_in.toString(),
          allow_access: allow_access.toString(),
          user_time_zone: time_zone.toString(),
          is_selfpaced: Is_Selpaced,
          show_resources: 1,
          mentor_id: mentor_id,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }

  async addUserBulk(
    name,
    email,
    is_temp,
    roll_id,
    password,
    permission: number,
    company_id,
    free_flashcard,
    free_practque,
    opt_in,
    allow_access,
    Is_Selpaced,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          email: email,
          roll_id: roll_id,
          password: password,
          permission: permission,
          email_verified_at: false,
          is_temp: is_temp,
          company_id: company_id,
          free_flashcard: free_flashcard.toString(),
          free_practque: free_practque.toString(),
          opt_in: opt_in.toString(),
          allow_access: allow_access.toString(),
          is_selfpaced: Is_Selpaced,
          show_resources: 1,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async Listuser(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async deleteuser(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async deleteClientCohort(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CLIENT_COHORT_REMOVE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async Teacherlist(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CLIENT_ALL;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async client_addclientcohort(user_id, cohortIds, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohortIds: cohortIds.toString(),
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CLIENT_COHORT_ASSIGN;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async client_emptyclientcohort(user_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CLIENT_COHORT_EMPTY;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async addClienttemp(
    name,
    email,
    roll_id,
    password,
    permission: number,
    token,
    address,
    notes,
    mobile,
    company_id,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          email: email,
          roll_id: roll_id,
          password: password,
          permission: permission,
          email_verified_at: false,
          is_temp: 1,
          address: address,
          notes: notes,
          mobile: mobile,
          company_id: company_id,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async getLimitStudent(
    page,
    limit,
    search,
    active = '',
    direction = '',
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          page: page,
          limit: limit,
          search: search.toString(),
          active: active,
          direction: direction,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USER_LIMIT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getLimitClient(page, limit, search, active, direction, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          page: page,
          limit: limit,
          search: search.toString(),
          active: active,
          direction: direction,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CLIENT_LIMIT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getLimitTeachers(page, limit, search, active, direction, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          page: page,
          limit: limit,
          search: search.toString(),
          active: active,
          direction: direction,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEACHER_LIMIT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addTeacher(name, email, mobile, password, certi_Ids, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          email: email,
          roll_id: 5,
          password: password,
          permission: 0,
          email_verified_at: false,
          mobile: mobile,
          certi_Ids: certi_Ids,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEACHER_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async updateTeacher(id, name, mobile, certi_Ids, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          name: name,
          mobile: mobile,
          certi_Ids: certi_Ids,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEACHER_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }

  async UpdateClient(id, name, company_id, mobile, address, notes, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS + this.crypto.enc(id);
      var data = {
        data: this.crypto.encrypt(
          JSON.stringify({
            name: name,
            company_id: company_id,
            mobile: mobile,
            address: address,
            notes: notes,
          }),
        ),
      };
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async addAuthyUser(id, email, mobile) {
    var url = environment.BASE_URL + environment.AUTHY_REGISTER;
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          email: email,
          cellphone: mobile,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async resetProgress(user_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.RESET_PROGRESS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
}
