import { StudymaterialService } from './../../services/studymaterial/studymaterial.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as XLSX from 'xlsx';
import { ExcelService } from 'src/app/services/excelservices/excel.service';

@Component({
  selector: 'app-studymaterial',
  templateUrl: './studymaterial.component.html',
  styleUrls: ['./studymaterial.component.scss'],
})
export class StudymaterialComponent implements OnInit {
  public config: any = {
    simpleUpload: {
      uploadUrl: 'https://tech4biz.io/ckeditor/ckeditor.php?responseType=json',
    },
    removePlugins: ['Title'],
    heading: {
      options: [
        {
          model: 'heading1',
          view: { name: 'h1', classes: 'ck-heading_heading1' },
          title: 'Heading 1',
        },
        {
          model: 'heading2',
          view: { name: 'h2', classes: 'ck-heading_heading2' },
          title: 'Heading 2',
        },
        {
          model: 'heading3',
          view: { name: 'h3', classes: 'ck-heading_heading3' },
          title: 'Heading 3',
        },
      ],
    },
    toolbar: [
      'undo',
      'redo',
      'removeformat',
      'heading',
      '|',
      'fontsize',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'fontfamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'link',
      'highlight',
      'code',
      'codeBlock',
      'blockQuote',
      'subscript',
      'superscript',
      'specialCharacters',
      '|',
      'alignment',
      'indent',
      'outdent',
      'pagebreak',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'uploadImage',
      'insertTable',
      'tableProperties',
      'tableCellProperties',
      'horizontalLine',
    ],
    extraAllowedContent: true,
    fullPage: true,
  };
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'domin_name',
    'subdomain_name',
    'topic_name',
    'title',
    'actions',
  ];
  dataSource: MatTableDataSource<Studymateriallist>;
  Search: any;
  addStudyMaterial: boolean = false;

  Domain = [];
  SubDomain = [];
  Topic = [];
  id: any;
  IsUpdate = true;
  Domainname: any;
  Subdomainname: any;
  Topicname: any;
  Title: any;
  Content: any;
  Certificatelist = [];
  filterDomain = [];
  filtersubDomain = [];
  filterTpoic = [];
  public StudymaterialForm: FormGroup;
  Certificate: any;
  loglist = [];
  showFullScreen: boolean = false;
  isBulkUpload: boolean = false;
  files: File[] = [];
  myEvent: any;
  progress: any = 0;
  selectBulkupload: boolean = false;
  selectprogress: boolean = false;
  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Title: '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Title: '',
  };
  auditHistory = '';
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ckeConfig: any;
  mycontent: string;
  log: string = '';
  @ViewChild('myckeditor') ckeditor: any;

  constructor(
    private sanitizer: DomSanitizer,
    public studyMaterialService: StudymaterialService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    private excelService: ExcelService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewStudyMaterial() {
    this.addStudyMaterial = true;
    this.IsUpdate = false;
  }

  closeModal() {
    this.addStudyMaterial = false;
    this.IsUpdate = false;
    this.Certificate = '';
    this.Domainname = '';
    this.Subdomainname = '';
    this.Topicname = '';
    this.Title = '';
    this.Content = '';
    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Title: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Title: '',
    };
    this.auditHistory = '';
  }

  async ngOnInit() {
    this.StudymaterialForm = this.fb.group({
      Domainname: ['', Validators.required],
      Subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      Title: ['', Validators.required],
      Content: ['', Validators.required],
      Certificate: ['', Validators.required],
    });

    this.ckeConfig = {
      allowedContent: true,
      height: '100vh',
      toolbar: [
        {
          name: 'paragraph',
          items: [
            'Source',
            'Undo',
            'Redo',
            'RemoveFormat',
            'heading',
            'Format',
            '|',
            'FontSize',
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            'Font',
            'TextColor',
            'BGColor',
            'Link',
            'CreateDiv',
            'Blockquote',
            'Subscript',
            'Superscript',
            'SpecialChar',
            'JustifyLeft',
            'JustifyCenter',
            'JustifyRight',
            'JustifyBlock',
            'Outdent',
            'Indent',
            'PageBreak',
            'NumberedList',
            'BulletedList',
            'Image',
            'Table',
            'HorizontalRule',
            'Maximize',
          ],
        },
      ],
    };

    await this.spinner.show();
    await this.listStudyMaterial();
    await this.GetAllDropdown();
    await this.spinner.hide();
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domain = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.SubDomain = list.data.SubDomain;
            this.filtersubDomain = list.data.SubDomain;
            this.Certificatelist = list.data.Certificate;
            this.Topic = list.data.Topic;
            this.filterTpoic = list.data.Topic;

            this.Certificate = '';
            this.Domainname = '';
            this.Subdomainname = '';
            this.Topicname = '';
            this.Title = '';
            this.Content = '';
          }
        });
      }
    });
  }

  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();

              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.studyMaterialService
                .DeleteStudyMaterial(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    var desc =
                      '<span> ' +
                      item.title +
                      '</span> Written is deleted by <span> ' +
                      e +
                      '</span>';
                    await this.service.addAdminLogs(
                      desc,
                      'Written',
                      this.id,
                      token,
                    );
                    await this.listStudyMaterial();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Study material has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }

  async listStudyMaterial() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Studymateriallist[] = [];
        await this.studyMaterialService
          .ListStudyMaterial(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                await users.push({
                  srno: srno,
                  id: element.id,
                  d_id: element.d_id,
                  sd_id: element.sd_id,
                  topic_id: element.topic_id,
                  status: element.status,
                  createdAt: element.createdAt,
                  updatedAt: element.updatedAt,
                  domin_name: element.domain.domin_name,
                  subdomain_name: element.subdomain.subdomain_name,
                  topic_name: element.topic.topic_name,
                  title: element.title,
                  content: element.content,
                  certificate_name: element.certificate.certificate_name,
                  certi_id: element.certi_id,
                  cource_id: element.cource_id,
                  cource_name: element.cource.course_name,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
              this.pagination = true;
            }
          });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async edit(item) {
    this.IsUpdate = true;
    this.addStudyMaterial = true;
    this.Certificate = item.certi_id;
    this.Domainname = item.d_id;
    this.Subdomainname = item.sd_id;
    this.Topicname = item.topic_id;
    this.Title = item.title;
    this.Content = item.content;
    this.id = item.id;
    this.loglist = [];
    this.Domain = this.filterDomain.filter(
      (x) => x.certi_id == this.Certificate,
    );
    this.SubDomain = this.filtersubDomain.filter(
      (x) => x.d_id == this.Domainname,
    );
    this.Topic = this.filterTpoic.filter((x) => x.sd_id == this.Subdomainname);
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find((x) => x.id == this.Certificate)
        ? this.Certificatelist.find((x) => x.id == this.Certificate)
            .certificate_name
        : '',
      Domain: this.filterDomain.find((x) => x.id == this.Domainname)
        ? this.filterDomain.find((x) => x.id == this.Domainname).domin_name
        : '',
      Subdomain: this.filtersubDomain.find((x) => x.id == this.Subdomainname)
        ? this.filtersubDomain.find((x) => x.id == this.Subdomainname)
            .subdomain_name
        : '',
      Topic: this.filterTpoic.find((x) => x.id == this.Topicname)
        ? this.filterTpoic.find((x) => x.id == this.Topicname).topic_name
        : '',
      Title: this.Title,
    };
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('Written', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
  }
  async certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
      this.Domain = this.filterDomain;
    }
  }
  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });

      this.Subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
      this.SubDomain = this.filtersubDomain;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return itemset.sd_id == val;
      });
      this.Topicname = '';
    } else {
      this.Topicname = '';
      this.Topic = this.filterTpoic;
    }
  }
  async Submit() {
    this.markFormTouched(this.StudymaterialForm);
    if (this.StudymaterialForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var data = '';
          data = this.Content;
          var h1 = `<h1 class="ck-heading_heading1">&nbsp;</h1>`;
          var h2 = `<h2 class="ck-heading_heading2">&nbsp;</h2>`;
          var h3 = `<h3 class="ck-heading_heading3">&nbsp;</h3>`;
          var ptag = `<p>&nbsp;</p>`;
          var remove_n = `\n`;
          var remove_t = `\t`;
          data = data.toString().replace(new RegExp(h1, 'g'), '');
          data = data.toString().replace(new RegExp(h2, 'g'), '');
          data = data.toString().replace(new RegExp(h3, 'g'), '');
          data = data.toString().replace(new RegExp(ptag, 'g'), '');
          data = data.toString().replace(new RegExp(remove_n, 'g'), '');
          data = data.toString().replace(new RegExp(remove_t, 'g'), '');
          this.Content = data;

          await this.studyMaterialService
            .AddStudyMaterial(
              this.Certificate,
              this.Domainname,
              this.Subdomainname,
              this.Topicname,
              this.Title,
              this.Content,
              '1',
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);

                await this.listStudyMaterial();
                var e = window.localStorage.getItem('adminUser');
                if (e == null || e == undefined || e == '') {
                  e = 'admin';
                }
                var desc =
                  '<span> ' +
                  this.Title +
                  '</span> Written is added by <span> ' +
                  e +
                  '</span>';
                await this.service.addAdminLogs(
                  desc,
                  'Written',
                  list.data.id,
                  token,
                );
                this.addStudyMaterial = false;
                this.IsUpdate = false;
                await this.spinner.hide();
                this.service.ShowSuccess(list.message);
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async Update() {
    this.markFormTouched(this.StudymaterialForm);
    if (this.StudymaterialForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var data = '';
          data = this.Content;
          var h1 = `<h1 class="ck-heading_heading1">&nbsp;</h1>`;
          var h2 = `<h2 class="ck-heading_heading2">&nbsp;</h2>`;
          var h3 = `<h3 class="ck-heading_heading3">&nbsp;</h3>`;
          var ptag = `<p>&nbsp;</p>`;
          var remove_n = `\n`;
          var remove_t = `\t`;
          data = data.toString().replace(new RegExp(h1, 'g'), '');
          data = data.toString().replace(new RegExp(h2, 'g'), '');
          data = data.toString().replace(new RegExp(h3, 'g'), '');
          data = data.toString().replace(new RegExp(ptag, 'g'), '');
          data = data.toString().replace(new RegExp(remove_n, 'g'), '');
          data = data.toString().replace(new RegExp(remove_t, 'g'), '');
          this.Content = data;

          await this.studyMaterialService
            .UpdateStudyMaterial(
              this.Certificate,
              this.Domainname,
              this.Subdomainname,
              this.Topicname,
              this.Title,
              this.Content,
              this.id,
              '1',
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                var e = window.localStorage.getItem('adminUser');
                if (e == null || e == undefined || e == '') {
                  e = 'admin';
                }
                this.newAuditValueArray = {
                  Certificate: this.Certificatelist.find(
                    (x) => x.id == this.Certificate,
                  )
                    ? this.Certificatelist.find((x) => x.id == this.Certificate)
                        .certificate_name
                    : '',
                  Domain: this.filterDomain.find((x) => x.id == this.Domainname)
                    ? this.filterDomain.find((x) => x.id == this.Domainname)
                        .domin_name
                    : '',
                  Subdomain: this.filtersubDomain.find(
                    (x) => x.id == this.Subdomainname,
                  )
                    ? this.filtersubDomain.find(
                        (x) => x.id == this.Subdomainname,
                      ).subdomain_name
                    : '',
                  Topic: this.filterTpoic.find((x) => x.id == this.Topicname)
                    ? this.filterTpoic.find((x) => x.id == this.Topicname)
                        .topic_name
                    : '',
                  Title: this.Title,
                };
                var audit = this.auth.compareAuditHist(
                  this.oldAuditValueArray,
                  this.newAuditValueArray,
                );
                var desc = audit;
                await this.service.addAdminLogs(
                  desc,
                  'Written',
                  this.id,
                  token,
                );
                await this.listStudyMaterial();
                this.addStudyMaterial = false;
                this.IsUpdate = false;
                await this.spinner.hide();
                this.service.ShowSuccess('Study material update successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async bulkCheckDKIM(data) {
    let response = 0;
    data.forEach(async (element) => {
      let domain = element.email.split('@')[1];
      await this.auth.verifyDKIM(domain).then(async (result) => {
        if (result[0].StatusCode == 1) {
          response = 1;
        }
      });
    });
    return response;
  }
  async openBulkUploadModal() {
    this.isBulkUpload = true;
  }
  async closeBulkUploadModal() {
    this.isBulkUpload = false;
    this.progress = 0;
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.files = [];
    this.myEvent = '';
  }
  async bulkUpload() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.myEvent.addedFiles[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
    };

    reader.readAsBinaryString(file);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.selectBulkupload = true;
    this.selectprogress = false;
  }
  onSelect(event) {
    let me = this;
    var timeleft = 10;
    me.progress = 0;
    me.selectprogress = true;
    me.selectBulkupload = true;
    this.files.push(event.addedFiles);
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        me.progress = 10 - timeleft;
        me.myEvent = event;
        me.selectBulkupload = false;
        me.selectprogress = false;
        clearInterval(downloadTimer);
      }
      me.progress = 10 - timeleft;
      timeleft -= 1;
    }, 200);
  }
  ExportExcel() {
    var certification = Object.assign(
      this.Certificatelist.map((k) => ({ name: k.certificate_name })),
    );
    var domain = Object.assign(
      this.Domain.map((k) => ({ name: k.domin_name })),
    );
    var subdomain = Object.assign(
      this.SubDomain.map((k) => ({ name: k.subdomain_name })),
    );
    var topic = Object.assign(this.Topic.map((k) => ({ name: k.topic_name })));
    var certificationData = [
      {
        workSheet: 'certification',
        rows: certification,
        properties: { state: 'hidden' },
      },
    ];
    var domainData = [
      {
        workSheet: 'domain',
        rows: domain,
        properties: { state: 'hidden' },
      },
    ];
    var subdomainData = [
      {
        workSheet: 'subdomain',
        rows: subdomain,
        properties: { state: 'hidden' },
      },
    ];
    var topicData = [
      {
        workSheet: 'topic',
        rows: topic,
        properties: { state: 'hidden' },
      },
    ];
    var Written = [
      {
        name: 'Written',
        values: [
          {
            header: 'certification',
            value: [
              { name: 'certification!$A$2:$A$' + (certification.length + 1) },
            ],
          },
          {
            header: 'domain',
            value: [{ name: 'domain!$A$2:$A$' + (domain.length + 1) }],
          },
          {
            header: 'subdomain',
            value: [{ name: 'subdomain!$A$2:$A$' + (subdomain.length + 1) }],
          },
          {
            header: 'topic',
            value: [{ name: 'topic!$A$2:$A$' + (topic.length + 1) }],
          },
          { header: 'title', value: '' },
          { header: 'content', value: '' },
        ],
        properties: { state: 'visible' },
      },
    ];
    var workbookData = [
      this.transform(Written)[0],
      certificationData[0],
      domainData[0],
      subdomainData[0],
      topicData[0],
    ];
    this.excelService.exportAsExcelFile(workbookData, Written[0].name);
  }
  transform(data) {
    const noOfRowaToGenerate = 5;
    return data.map(({ name, values, properties }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value)
            ? next.value.map(({ name }) => name)
            : next.value,
        }),
        {},
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers),
        properties: properties,
      };
    });
  }
}
export interface Studymateriallist {
  srno: number;
  id: number;
  d_id: string;
  sd_id: string;
  topic_id: string;
  title: string;
  content: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  domin_name: string;
  subdomain_name: string;
  topic_name: string;
  certificate_name: string;
  certi_id: string;
  cource_id: string;
  cource_name: string;
}
export class UploadAdapter {
  private loader;
  constructor(
    loader: any,
    public studyMaterialService: StudymaterialService,
  ) {
    this.loader = loader;
  }
  public upload(): Promise<any> {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = (e) => {
            this.studyMaterialService
              .ckUpload(myReader.result)
              .then(async (data) => {});
          };
          myReader.readAsDataURL(file);
        }),
    );
  }
  onEditorChange(event) {
    console.log(event);
  }
}
