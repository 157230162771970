<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Live Class</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Live Class/Company/Course Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewCohort()">Add Live Class</button>
    </div>
    <div class="pagesection">
      <div class="mat-elevation-z8">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          style="border-spacing: 0 10px"
        >
          <ng-container matColumnDef="Srno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.Srno }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="cohort_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Live Class Name
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.cohort_name }}">{{
                element.cohort_name
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.company ? element.company : '-' }}">{{
                element.company ? element.company : '-'
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="template_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Course</th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.template_name }}">{{
                element.template_name
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="studentCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              # Students
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.studentCount }}">{{
                element.studentCount
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Start Date
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.start_date | date: 'short' }}">{{
                element.start_date | date: 'dd MMM yyyy'
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <img
                src="../../../assets/images/edit-1.png"
                (click)="edit(row)"
                alt="Edit"
                class="action-icon"
              />
              <img
                src="../../../assets/images/remove.png"
                (click)="delete(row)"
                alt="Delete"
                class="action-icon"
              />
              <img
                src="../../../assets/images/Report.png"
                (click)="gotoCohortReport(row)"
                alt="Report"
                class="action-icon"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      <mat-paginator
        style-paginator
        showFirstLastButtons
        [showTotalPages]="3"
        *ngIf="pagination"
        [length]="dataSource.data ? dataSource.data.length : 0"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50, 100, 500]"
      >
      </mat-paginator>
    </div>
  </div>
  <ngx-spinner
    bdColor="#00041aa6"
    size="default"
    color="##d7779b"
    template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
    [fullScreen]="true"
  >
  </ngx-spinner>
  <div class="cutome-modal" *ngIf="addCohort">
    <div class="cutome-modal-content">
      <div class="modal-header">
        <p *ngIf="!IsUpdate">Add Live Class</p>
        <p *ngIf="IsUpdate">Edit Live Class</p>
        <span (click)="closeModal()" class="material-icons">close</span>
      </div>
      <div class="modal-body">
        <div>
          <div class="formwrapper">
            <div class="select-box">
              <select [(ngModel)]="SelectedCompany">
                <option value="">Company</option>
                <option value="{{ item.id }}" *ngFor="let item of Companies">
                  {{ item.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="select-box">
              <select
                [(ngModel)]="SelectedTemplate"
                (change)="changeTemplate()"
              >
                <option value="">Live Course Template</option>
                <option value="{{ item.id }}" *ngFor="let item of Templates">
                  {{ item.templateName }}
                </option>
              </select>
              <span></span>
            </div>
          </div>

          <div class="formwrapper">
            <div class="cohort-name w-100">
              <input [(ngModel)]="CohortName" placeholder="Class Name" />
            </div>
          </div>
          <div class="formwrapper">
            <div class="cohort-name">
              <ng-moment-timezone-picker
                customPlaceholderText="Time Zone"
                [(ngModel)]="time_zone"
                clearOnBackspace="true"
              >
              </ng-moment-timezone-picker>
            </div>
            <div class="cohort-name">
              <input [(ngModel)]="Location" placeholder="Location" />
            </div>
          </div>
          <div class="formwrapper">
            <div class="date-input">
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [matDatepicker]="picker"
                  [disabled]="true"
                  placeholder="Class Start Date"
                  [(ngModel)]="StartDate"
                  (dateChange)="changeStartDate()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="false"></mat-datepicker>
              </mat-form-field>
              <span>
                <img
                  src="../../../assets/images/Calendar.svg"
                  alt="Calendar"
                  class="calendar-icon"
                />
              </span>
            </div>
          </div>
          <div class="course-name">
            <div class="course-input">
              <div class="check-app">
                <label class="container">
                  Videos
                  <input type="checkbox" [(ngModel)]="VideoAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Mindmaps
                  <input type="checkbox" [(ngModel)]="MindmapAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Summaries
                  <input type="checkbox" [(ngModel)]="SummaryAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  References
                  <input type="checkbox" [(ngModel)]="ReferenceAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Knowledge Assessments
                  <input type="checkbox" [(ngModel)]="KAAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Practice Tests
                  <input type="checkbox" [(ngModel)]="PTAccess" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="course-input">
              <div class="check-app">
                <label class="container">
                  CAT Exams (free per attempt)
                  <input type="checkbox" [(ngModel)]="CATAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Live Chat
                  <input type="checkbox" [(ngModel)]="LiveChatAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Live Questions
                  <input type="checkbox" [(ngModel)]="LiveQuestionAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Live Events
                  <input type="checkbox" [(ngModel)]="LiveEventAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Flashcard App (full access)
                  <input type="checkbox" [(ngModel)]="FashFullAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Practice Question App (full access)
                  <input type="checkbox" [(ngModel)]="PraAppFullAccess" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="add-new-event">
            <div class="events">
              <span>Live Events</span>
            </div>
            <ng-select
              [items]="LiveEvents"
              bindLabel="event_name"
              bindValue="id"
              placeholder="Select Live Events"
              appendTo="body"
              multiple="true"
              [(ngModel)]="SelectedEvents"
            >
            </ng-select>
          </div>

          <div class="add-new-event">
            <div class="events">
              <span>Class Times</span>
              <button (click)="addEvents()">Add Class Time</button>
            </div>
            <div class="transcript-div" *ngFor="let item of Events">
              <div class="main-transcript">
                <div class="all-event">
                  <div class="event-name">
                    <input
                      type="text"
                      class="inputText"
                      [(ngModel)]="item.event_name"
                    />
                  </div>
                  <div class="event-number">
                    <input
                      type="date"
                      class="inputText eventDate"
                      [(ngModel)]="item.event_date"
                    />
                  </div>
                  <div class="event-time">
                    <input
                      type="text"
                      class="inputText"
                      (keyup)="validateFromTime(item, $event)"
                      [(ngModel)]="item.event_time"
                      placeholder="00:00"
                    />
                  </div>
                  <div class="event-time min">
                    <input type="number" [(ngModel)]="item.minutes" />
                    <span>MIN</span>
                  </div>
                </div>
                <div class="all-event m-0">
                  <input type="text" placeholder="URL" [(ngModel)]="item.url" />
                </div>
                <div class="transcript-delete" (click)="deleteEvents(item)">
                  <span class="material-icons"> delete </span>
                </div>
              </div>
            </div>
          </div>
          <div class="knowledge-ass">
            <div class="title_filter showdata">
              <span class="main-title">Video</span>
              <div class="course-input">
                <select
                  name="filtervideo"
                  id="filtervideo"
                  (change)="videoChange($event)"
                >
                  <option value="name">Sort By</option>
                  <option value="name">Name</option>
                  <option value="date">Date & Time</option>
                </select>
              </div>
            </div>
            <div class="cissp-days showdata" *ngFor="let item of Videos">
              <div class="cissp-assigned">
                <span class="first-title">{{ item.video_title }}</span>
                <div>
                  <div class="check-app">
                    <label class="container">
                      Assigned
                      <input type="checkbox" [(ngModel)]="item.Assigned" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="check-app">
                    <label class="container">
                      Required
                      <input type="checkbox" [(ngModel)]="item.Required" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div
                    class="check-app check-app2"
                    [ngClass]="{ opacity: item.Assigned }"
                  >
                    <label class="container">
                      Homework
                      <input
                        type="checkbox"
                        [disabled]="!item.Assigned"
                        [(ngModel)]="item.Homework"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="overdue">
                <div class="over">
                  <div class="due" [ngClass]="{ opacity: item.Assigned }">
                    <label for="Due">Due:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Due"
                    />
                    <select
                      [(ngModel)]="item.DueTime"
                      [disabled]="!item.Assigned"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                  <div class="due" [ngClass]="{ opacity: item.Required }">
                    <label for="Due">Overdue:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Required"
                      [(ngModel)]="item.OverDue"
                      [min]="item.Due | date: 'yyyy-MM-dd'"
                    />
                    <select
                      [(ngModel)]="item.OverDueTime"
                      [disabled]="!item.Required"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="cissp-days showdata" *ngIf="Videos.length == 0">
              <div class="cissp-assigned">
                <span class="first-title">NONE</span>
              </div>
            </div>
            <div class="title_filter showdata">
              <span class="main-title">Mind Map</span>
              <div class="course-input">
                <select
                  name="filtermindmap"
                  id="filtermindmap"
                  (change)="mindmapChange($event)"
                >
                  <option value="name">Sort By</option>
                  <option value="name">Name</option>
                  <option value="date">Date & Time</option>
                </select>
              </div>
            </div>
            <div class="cissp-days showdata" *ngFor="let item of Mindmap">
              <div class="cissp-assigned">
                <span class="first-title">{{ item.name }}</span>
                <div>
                  <div class="check-app">
                    <label class="container">
                      Assigned
                      <input type="checkbox" [(ngModel)]="item.Assigned" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="check-app">
                    <label class="container">
                      Required
                      <input type="checkbox" [(ngModel)]="item.Required" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div
                    class="check-app check-app2"
                    [ngClass]="{ opacity: item.Assigned }"
                  >
                    <label class="container">
                      Homework
                      <input
                        type="checkbox"
                        [disabled]="!item.Assigned"
                        [(ngModel)]="item.Homework"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="overdue">
                <div class="over">
                  <div class="due" [ngClass]="{ opacity: item.Assigned }">
                    <label for="Due">Due:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Due"
                    />
                    <select
                      [(ngModel)]="item.DueTime"
                      [disabled]="!item.Assigned"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                  <div class="due" [ngClass]="{ opacity: item.Required }">
                    <label for="Due">Overdue:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Required"
                      [(ngModel)]="item.OverDue"
                      [min]="item.Due | date: 'yyyy-MM-dd'"
                    />
                    <select
                      [(ngModel)]="item.OverDueTime"
                      [disabled]="!item.Required"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="cissp-days showdata" *ngIf="Mindmap.length == 0">
              <div class="cissp-assigned">
                <span class="first-title">NONE</span>
              </div>
            </div>
            <div class="title_filter">
              <span class="main-title">Knowledge Assessments</span>
              <div class="course-input">
                <select
                  name="filterknowledge"
                  id="filterknowledge"
                  (change)="knowledgeChange($event)"
                >
                  <option value="name">Sort By</option>
                  <option value="name">Name</option>
                  <option value="date">Date & Time</option>
                </select>
              </div>
            </div>
            <!-- <span class="main-title">Knowledge Assessments</span> -->
            <div class="course-input">
              <span class="main-title">Baseline Knowledge Assessment</span>
              <select [(ngModel)]="SelectedBaselineKA">
                <option value="" style="display: none">
                  Select Baseline Knowledge Assessment
                </option>
                <option *ngFor="let item of baselineka" value="{{ item.id }}">
                  {{ item.knowass_name }}
                </option>
              </select>
            </div>
            <div class="course-input">
              <div class="baselinedue">
                <label for="Due">Due:</label>
                <input type="date" [(ngModel)]="SelectedBaselineKAdueDate" />
                <label for="Due">Time:</label>
                <select [(ngModel)]="SelectedBaselineKAdueTime">
                  <option
                    *ngFor="let itm of Hours; let i = index"
                    value="{{ itm }}:00:00"
                  >
                    {{ itm }}
                  </option>
                </select>
                <label for="Due" class="overdue-date-lable">Overdue:</label>
                <input
                  type="date"
                  [(ngModel)]="SelectedBaselineKAOverdueDate"
                />
                <label for="Due">Time:</label>
                <select [(ngModel)]="SelectedBaselineKAOverdueTime">
                  <option
                    *ngFor="let itm of Hours; let i = index"
                    value="{{ itm }}:00:00"
                  >
                    {{ itm }}
                  </option>
                </select>
                <label for="End" class="overdue-date-lable">End:</label>
                <input type="date" [(ngModel)]="SelectedBaselineKAEndDate" />
                <label for="End">Time:</label>
                <select [(ngModel)]="SelectedBaselineKAEndTime">
                  <option
                    *ngFor="let itm of Hours; let i = index"
                    value="{{ itm }}:00:00"
                  >
                    {{ itm }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <span class="main-title">Knowledge Assessments</span> -->
            <div class="cissp-days" *ngFor="let item of KnowledgeAssessments">
              <div class="cissp-assigned">
                <span class="first-title">{{ item.knowass_name }}</span>
                <div *ngIf="item.Count > 0">
                  <div class="check-app">
                    <label class="container">
                      Assigned
                      <input type="checkbox" [(ngModel)]="item.Assigned" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="check-app">
                    <label class="container">
                      Required
                      <input type="checkbox" [(ngModel)]="item.Required" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div
                    class="check-app check-app2"
                    [ngClass]="{ opacity: item.Assigned }"
                  >
                    <label class="container">
                      Homework
                      <input
                        type="checkbox"
                        [disabled]="!item.Assigned"
                        [(ngModel)]="item.Homework"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="overdue" *ngIf="item.Count > 0">
                <div class="over">
                  <div class="due" [ngClass]="{ opacity: item.Assigned }">
                    <label for="Due">Due:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Due"
                    />
                    <select
                      [(ngModel)]="item.DueTime"
                      [disabled]="!item.Assigned"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                  <div class="due" [ngClass]="{ opacity: item.Required }">
                    <label for="Due">Overdue:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Required"
                      [min]="item.Due | date: 'yyyy-MM-dd'"
                      [(ngModel)]="item.OverDue"
                    />
                    <select
                      [(ngModel)]="item.OverDueTime"
                      [disabled]="!item.Required"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="!item.Count" class="noque">
                <span>There is no questions in this test</span>
              </div>
            </div>
            <div class="cissp-days" *ngIf="KnowledgeAssessments.length == 0">
              <div class="cissp-assigned">
                <span class="first-title">NONE</span>
              </div>
            </div>

            <div class="title_filter">
              <span class="main-title">Practice Tests</span>
              <div class="course-input">
                <select
                  name="filterpractice"
                  id="filterpractice"
                  (change)="practiceChange($event)"
                >
                  <option value="name">Sort By</option>
                  <option value="name">Name</option>
                  <option value="date">Date & Time</option>
                </select>
              </div>
            </div>
            <div class="cissp-days" *ngFor="let item of PracticeExams">
              <div class="cissp-assigned">
                <span class="first-title">{{ item.practice_name }}</span>
                <div *ngIf="item.Count > 0">
                  <div class="check-app">
                    <label class="container">
                      Assigned
                      <input type="checkbox" [(ngModel)]="item.Assigned" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="check-app">
                    <label class="container">
                      Required
                      <input type="checkbox" [(ngModel)]="item.Required" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div
                    class="check-app check-app2"
                    [ngClass]="{ opacity: item.Assigned }"
                  >
                    <label class="container">
                      Homework
                      <input
                        type="checkbox"
                        [disabled]="!item.Assigned"
                        [(ngModel)]="item.Homework"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="overdue" *ngIf="item.Count > 0">
                <div class="over">
                  <div class="due" [ngClass]="{ opacity: item.Assigned }">
                    <label for="Due">Due:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Due"
                    />
                    <select
                      [(ngModel)]="item.DueTime"
                      [disabled]="!item.Assigned"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                  <div class="due" [ngClass]="{ opacity: item.Required }">
                    <label for="Due">Overdue:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Required"
                      [(ngModel)]="item.OverDue"
                      [min]="item.Due | date: 'yyyy-MM-dd'"
                    />
                    <select
                      [(ngModel)]="item.OverDueTime"
                      [disabled]="!item.Required"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="!item.Count" class="noque">
                <span>There is no questions in this test</span>
              </div>
            </div>
            <div class="cissp-days" *ngIf="PracticeExams.length == 0">
              <div class="cissp-assigned">
                <span class="first-title">NONE</span>
              </div>
            </div>

            <div class="title_filter">
              <span class="main-title">CAT Exam</span>
              <div class="course-input">
                <select
                  name="filtercat"
                  id="filtercat"
                  (change)="catChange($event)"
                >
                  <option value="name">Sort By</option>
                  <option value="name">Name</option>
                  <option value="date">Date & Time</option>
                </select>
              </div>
            </div>
            <div class="cissp-days" *ngFor="let item of CatExams">
              <div class="cissp-assigned">
                <span class="first-title">{{ item.ketexam_name }}</span>
                <div *ngIf="item.Count > 0">
                  <div class="check-app">
                    <label class="container">
                      Assigned
                      <input type="checkbox" [(ngModel)]="item.Assigned" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="check-app">
                    <label class="container">
                      Required
                      <input type="checkbox" [(ngModel)]="item.Required" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div
                    class="check-app check-app2"
                    [ngClass]="{ opacity: item.Assigned }"
                  >
                    <label class="container">
                      Homework
                      <input
                        type="checkbox"
                        [disabled]="!item.Assigned"
                        [(ngModel)]="item.Homework"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="overdue" *ngIf="item.Count > 0">
                <div class="over">
                  <div class="due" [ngClass]="{ opacity: item.Assigned }">
                    <label for="Due">Due:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Due"
                    />
                    <select
                      [(ngModel)]="item.DueTime"
                      [disabled]="!item.Assigned"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                  <div class="due" [ngClass]="{ opacity: item.Required }">
                    <label for="Due">Overdue:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Required"
                      [(ngModel)]="item.OverDue"
                      [min]="item.Due | date: 'yyyy-MM-dd'"
                    />
                    <select
                      [(ngModel)]="item.OverDueTime"
                      [disabled]="!item.Required"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="!item.Count" class="noque">
                <span>There is no questions in this test</span>
              </div>
            </div>
            <div class="cissp-days" *ngIf="CatExams.length == 0">
              <div class="cissp-assigned">
                <span class="first-title">NONE</span>
              </div>
            </div>
          </div>
          <div class="knowledge-ass">
            <div class="title_filter">
              <span class="main-title">Email Notifications</span>
              <div class="course-input">
                <select
                  name="filteremail"
                  id="filteremail"
                  (change)="emailChange($event)"
                >
                  <option value="name">Sort By</option>
                  <option value="name">Name</option>
                  <option value="date">Date & Time</option>
                </select>
              </div>
            </div>
            <!-- <span class="main-title">Email Notifications</span> -->

            <div class="cissp-days" *ngFor="let item of EmailTemplates">
              <div class="cissp-assigned">
                <span class="first-title">{{ item.name }}</span>
                <div>
                  <div class="check-app">
                    <label class="container">
                      Assigned
                      <input type="checkbox" [(ngModel)]="item.Assigned" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="check-app check-app2">
                    <label class="container">
                      Required
                      <input type="checkbox" [(ngModel)]="item.Required" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="overdue">
                <div class="over">
                  <div class="due" [ngClass]="{ opacity: item.Assigned }">
                    <label for="Due">Due:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Due"
                    />
                    <select
                      [(ngModel)]="item.DueTime"
                      [disabled]="!item.Assigned"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                  <div class="due" [ngClass]="{ opacity: item.Required }">
                    <label for="Due">Overdue:</label>
                    <input
                      type="date"
                      class="date-w"
                      [disabled]="!item.Required"
                      [(ngModel)]="item.OverDue"
                      [min]="item.Due | date: 'yyyy-MM-dd'"
                    />
                    <select
                      [(ngModel)]="item.OverDueTime"
                      [disabled]="!item.Required"
                    >
                      <option
                        *ngFor="let itm of Hours; let i = index"
                        value="{{ itm }}:00:00"
                      >
                        {{ itm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="cissp-days" *ngIf="EmailTemplates.length == 0">
              <div class="cissp-assigned">
                <span class="first-title">NONE</span>
              </div>
            </div>
          </div>
          <div class="users-table">
            <div class="main-title">
              <div class="student-filters">
                <span>Students</span>

                <div class="search-icon">
                  <select
                    [(ngModel)]="SearchSelectedCompany"
                    name="SearchEmail"
                    id="SearchEmail"
                    (change)="changeCompany($event)"
                  >
                    <option value="0">Filter by Company</option>
                    <option
                      value="{{ item.id }}"
                      *ngFor="let item of Companies"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <input
                    placeholder="Search by Student Name/Email"
                    [(ngModel)]="SearchStudent"
                    (keyup)="filterStudent()"
                    name="search"
                    autocomplete="off"
                  />
                  <span class="material-icons">search</span>
                </div>
              </div>
            </div>
            <div class="students-list">
              <table
                mat-table
                [dataSource]="dataSourceStudents"
                style="border-spacing: 0 10px"
              >
                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? masterToggle($event) : null"
                      [checked]="selection.hasValue() && isEntirePageSelected()"
                      [indeterminate]="
                        selection.hasValue() && !isEntirePageSelected()
                      "
                    ></mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="check-app">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="changeCheckbox(row, $event)"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row) || row.checked"
                      >
                      </mat-checkbox>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Student Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Student Email</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.email }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsStudents"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsStudents"
                ></tr>
              </table>
              <mat-paginator
                #studentpage
                (page)="pageEvents($event)"
                [length]="totalUsers"
                [pageSizeOptions]="[10, 20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
          <div class="history-div" *ngIf="IsUpdate">
            <h3><span class="material-icons"> history </span>Audit history</h3>
            <div class="audit-table">
              <div class="table-header">
                <div class="date-title"><span>Date & Time</span></div>
                <div class="action-title"><span>Action</span></div>
              </div>
              <div class="main-body-audit">
                <div class="table-body" *ngFor="let log of loglist">
                  <div class="date-title">
                    <span>{{ log.createdAt | date: 'medium' }}</span>
                  </div>
                  <div class="action-title">
                    <span [innerHTML]="log.description"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel-btn" (click)="closeModal()">Cancel</button>
        <div *ngIf="!IsUpdate">
          <button type="button" class="submit-btn" (click)="SaveCohort()">
            Save
          </button>
        </div>
        <div *ngIf="IsUpdate">
          <button type="button" class="submit-btn" (click)="UpdateCohort()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="cutome-modal" *ngIf="LiveEventPopup">
    <div class="cutome-modal-content" style="width: 45%">
      <div class="modal-header">
        <p>Live Events</p>
        <span class="material-icons" (click)="closeLiveEventPopup()"
          >close</span
        >
      </div>

      <div class="modal-body" style="min-height: 110px">
        <div>
          <div class="students-list">
            <div class="event-list" *ngIf="!NoLiveEventFound">
              <div class="rowevent header">
                <div class="value first">Event Name</div>
                <div class="value">Start Date</div>
                <div class="value">Start Time</div>
                <div class="value last">Action</div>
              </div>
              <div class="rowevent" *ngFor="let item of PopLiveEvents">
                <div class="value first">{{ item.event_name }}</div>
                <div class="value">
                  {{ item.start_date | date: 'dd MMM yyyy' }}
                </div>
                <div class="value">{{ item.start_time }}</div>
              </div>
            </div>
            <div class="no-course" *ngIf="NoLiveEventFound">
              <span
                ><svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Login-Flow"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Assets-New-Screens"
                      transform="translate(-104.000000, -237.000000)"
                      fill="#a39bde"
                    >
                      <g
                        id="icon-alert"
                        transform="translate(104.000000, 237.000000)"
                      >
                        <path
                          d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M6,1.1 C3.29380473,1.1 1.1,3.29380473 1.1,6 C1.1,8.70619527 3.29380473,10.9 6,10.9 C8.70619527,10.9 10.9,8.70619527 10.9,6 C10.9,3.29380473 8.70619527,1.1 6,1.1 Z M6,2.93081622 C6.27338095,2.93081622 6.50017619,3.13027336 6.54280143,3.39160336 L6.55,3.48081622 L6.55,5.66865795 C6.55,5.97241456 6.30375661,6.21865795 6,6.21865795 C5.72661905,6.21865795 5.49982381,6.01920081 5.45719857,5.75787081 L5.45,5.66865795 L5.45,3.48081622 C5.45,3.17705961 5.69624339,2.93081622 6,2.93081622 Z"
                          id="Combined-Shape"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M6,8.96240597 C6.4418278,8.96240597 6.8,8.60423377 6.8,8.16240597 C6.8,7.72057817 6.4418278,7.36240597 6,7.36240597 C5.5581722,7.36240597 5.2,7.72057817 5.2,8.16240597 C5.2,8.60423377 5.5581722,8.96240597 6,8.96240597 Z"
                          id="Oval"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <p>No live events are schedules for the selected class</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel-btn" (click)="closeLiveEventPopup()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
