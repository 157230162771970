<div class="pagemain">
  <div class="pagetitle">
    <h3>
      <span class="material-icons" title="Back" routerLink="/main/livequestion"
        >arrow_back_ios</span
      >
      Live Questions
    </h3>
  </div>
  <div class="test-name">
    {{ TestName }}
  </div>
  <div *ngFor="let item of SingleQue">
    <div class="pre-next">
      <div class="btn-wrap">
        <button
          mat-button
          color="primary"
          class="btn-previous"
          (click)="previousQuestion()"
          [disabled]="QuestionKey == 0"
        >
          Previous
        </button>
        <span>#{{ item.QuestionNo }}</span>
        <button
          mat-button
          color="primary"
          class="btn-previous"
          (click)="nextQuestion()"
          [disabled]="QuestionKey >= Questions.length - 1"
        >
          Next
        </button>
      </div>
      <div class="no-std">
        <span class="std">23</span><span> of 26 Student Respond</span>
      </div>
    </div>
    <div class="question">
      <div class="main-question">
        <p>{{ item.question }}</p>
      </div>
      <div class="option">
        <div class="first-opstion">
          <p>A. {{ item.first_choice }}</p>
          <div class="rank-div">
            <span>12</span>
            <span>52%</span>
          </div>
        </div>
        <div class="first-opstion">
          <p>B. {{ item.second_choice }}</p>
          <div class="rank-div">
            <span>4</span>
            <span>17%</span>
          </div>
        </div>
        <div class="first-opstion">
          <p>C. {{ item.third_choice }}</p>
          <div class="rank-div">
            <span>6</span>
            <span>26%</span>
          </div>
        </div>
        <div class="first-opstion">
          <p>D. {{ item.fourth_choice }}</p>
          <div class="rank-div">
            <span>1</span>
            <span>4%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
