import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../../service/authorization.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LiveService } from '../../../services/live/live.service';

@Component({
  selector: 'app-addlivequestion',
  templateUrl: './addlivequestion.component.html',
  styleUrls: ['./addlivequestion.component.scss'],
})
export class AddlivequestionComponent implements OnInit {
  public LiveQueTestForm: FormGroup;
  Search: any;
  SelectedTest: any;
  TestName: any;
  Question: any;
  OptionA: any;
  OptionB: any;
  OptionC: any;
  OptionD: any;
  CorrectAnswer: any;
  Description: any;
  EditedRecord: any;
  IsUpdate: boolean = false;
  displayedColumns: string[] = [
    'srno',
    'question',
    'first_choice',
    'second_choice',
    'third_choice',
    'fourth_choice',
    'answer',
    'description',
    'actions',
  ];
  dataSource: MatTableDataSource<questionlist>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    public liveService: LiveService,
    public auth: AuthorizationService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.SelectedTest = JSON.parse(atob(params['id']));
        this.TestName = this.SelectedTest.test_name;
        this.getAllQuestionTestWise();
      } catch (error) {
        this.router.navigate(['/main/livequestion/']);
      }
    });
  }

  ngOnInit() {
    this.LiveQueTestForm = this.fb.group({
      Question: ['', Validators.required],
      OptionA: ['', Validators.required],
      OptionB: ['', Validators.required],
      OptionC: ['', Validators.required],
      OptionD: ['', Validators.required],
      CorrectAnswer: ['', Validators.required],
      Description: ['', Validators.required],
    });
  }
  gotoTeacherScreen() {
    var data = btoa(JSON.stringify(this.SelectedTest));
    this.router.navigate([`main/livequestion/teacher/${data}`]);
  }
  async addLiveQuestion() {
    if (this.LiveQueTestForm.valid) {
      this.spinner.show();
      await this.liveService
        .AddLiveQuestion(
          this.SelectedTest.id,
          this.Question,
          this.OptionA,
          this.OptionB,
          this.OptionC,
          this.OptionD,
          this.CorrectAnswer,
          this.Description,
        )
        .then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.service.ShowSuccess('Question add successfully');
            this.getAllQuestionTestWise();
          }
        });
      this.spinner.hide();
    }
  }
  async getAllQuestionTestWise() {
    const users: questionlist[] = [];
    this.spinner.show();
    await this.liveService
      .LiveTestWiseQuestionList(this.SelectedTest.id)
      .then(async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          var srno = 0;
          await list.data.questions.forEach(async (element) => {
            await users.push({
              srno: ++srno,
              id: element.id,
              question: element.question,
              first_choice: element.first_choice,
              second_choice: element.second_choice,
              third_choice: element.third_choice,
              fourth_choice: element.fourth_choice,
              description: element.description,
              answer: element.answer,
            });
          });
          this.dataSource = new MatTableDataSource(users);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.filter();
        }
      });
    this.spinner.hide();
  }
  async editQuestion(item) {
    this.Question = item.question;
    this.OptionA = item.first_choice;
    this.OptionB = item.second_choice;
    this.OptionC = item.third_choice;
    this.OptionD = item.fourth_choice;
    this.CorrectAnswer = item.answer;
    this.EditedRecord = item;
    this.IsUpdate = true;
  }
  async cancelUpdate() {
    this.Question = '';
    this.OptionA = '';
    this.OptionB = '';
    this.OptionC = '';
    this.OptionD = '';
    this.CorrectAnswer = '';
    this.IsUpdate = false;
  }
  async updateLiveQuestion() {
    if (this.LiveQueTestForm.valid) {
      this.spinner.show();
      await this.liveService
        .UpdateLiveQuestion(
          this.EditedRecord.id,
          this.Question,
          this.OptionA,
          this.OptionB,
          this.OptionC,
          this.OptionD,
          this.CorrectAnswer,
        )
        .then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.service.ShowSuccess('Question update successfully');
            this.cancelUpdate();
            this.getAllQuestionTestWise();
          }
        });
      this.spinner.hide();
    }
  }
  async deleteQuestion(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        await this.liveService
          .DeleteLiveQuestion(item.id)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.service.ShowSuccess('Question deleted successfully');
              this.getAllQuestionTestWise();
            }
          });
        this.spinner.hide();
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
}
export interface questionlist {
  srno: number;
  id: string;
  question: string;
  first_choice: string;
  second_choice: string;
  third_choice: string;
  fourth_choice: string;
  answer: string;
  description: string;
}
