import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { MatSort } from '@angular/material/sort';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { CourseService } from '../../services/course/course.service';
import * as ClassicEditor from '@blowstack/ckeditor5-full-free-build';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  displayedColumns: string[] = [
    'course_name',
    'certi_id',
    'description',
    'actions',
  ];
  dataSource: MatTableDataSource<courselist>;
  Search: any;
  addCourse: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public CoureseForm: FormGroup;
  description: any;
  ListCerficateData = [];
  CertificateName: any;
  CourseName: any;
  Guarantee: any;
  Fee: any;
  id: any;
  days: any;
  CourseShort: any;
  imageset: any;
  imagebase64: any;
  IsUpdate: boolean = true;
  loglist = [];
  public Editor = ClassicEditor;
  constructor(
    public courseService: CourseService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {}
  async ngOnInit() {
    this.CoureseForm = this.fb.group({
      coursename: ['', Validators.required],
      CertificateName: ['', Validators.required],
      Guarantee: ['', Validators.required],
      Fee: ['', Validators.required],
      description: ['', Validators.required],
      Days: ['', Validators.required],
      CourseShort: ['', Validators.required],
      imageset: [''],
    });
    await this.spinner.show();
    await this.listCourses();
    await this.ListCertificate();

    await this.spinner.hide();
  }
  addNewCourse() {
    this.addCourse = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addCourse = false;
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.courseService
                .DeleteCourses(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();

                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            '<span> ' +
                            item.course_name +
                            '</span> course is deleted by <span> ' +
                            e +
                            '</span>';
                          await this.service.addAdminLogs(
                            desc,
                            'courses',
                            item.id,
                            token,
                          );
                        }
                      });
                    await this.listCourses();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Course has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async listCourses() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: courselist[] = [];
        await this.courseService.ListCourses(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                course_name: element.course_name,
                certi_id: element.certi_id,
                description: element.description,
                access: element.access,
                pass_guarantee: element.pass_guarantee,
                fee: element.fee,
                certificate_name: element.certificate.certificate_name,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                short_name: element.short_name,
                days: element.days,
                img_path: element.img_path,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
          }
        });
      }
    });
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCerficateData = list.data;
            this.CertificateName = '';
            this.Guarantee = '';
          }
        });
      }
    });
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('courses', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.addCourse = true;
    this.IsUpdate = true;
    this.id = item.id;
    this.CourseName = item.course_name;
    this.CertificateName = item.certi_id;
    this.Guarantee = item.pass_guarantee;
    this.Fee = item.fee;
    this.days = item.days;
    this.CourseShort = item.short_name;
    this.imagebase64 = item.img_path;
    this.description = item.description;
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
  previewFile(event) {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagebase64 = reader.result;
      };
    } catch (e) {
      this.imageset = '';
    }
  }

  async onSubmit() {
    this.markFormTouched(this.CoureseForm);
    if (this.CoureseForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.courseService
              .AddCourses(
                this.CourseName,
                this.CertificateName,
                this.description,
                '',
                this.Guarantee,
                this.Fee,
                this.days,
                this.CourseShort,
                token,
                this.imagebase64,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.CourseName +
                          '</span> course is added by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'courses',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  this.IsUpdate = false;
                  this.addCourse = false;
                  await this.spinner.hide();
                  this.service.ShowSuccess(list.message);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async UpdateCourse() {
    this.markFormTouched(this.CoureseForm);
    if (this.CoureseForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.courseService
              .UpdateCourses(
                this.CourseName,
                this.CertificateName,
                this.description,
                '',
                this.Guarantee,
                this.Fee,
                this.id,
                this.days,
                this.CourseShort,
                token,
                this.imagebase64,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.CourseName +
                          '</span> course is updated by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'courses',
                          this.id,
                          token,
                        );
                      }
                    });
                  this.IsUpdate = false;
                  this.addCourse = false;
                  await this.spinner.hide();
                  this.service.ShowSuccess(list.message);
                  this.router.navigate(['main/courses']);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface courselist {
  srno: number;
  id: string;
  course_name: string;
  certi_id: string;
  description: string;
  access: string;
  pass_guarantee: string;
  fee: string;
  status: string;
  certificate_name: string;
  createdAt: string;
  updatedAt: string;
  days: string;
  short_name: string;
  img_path: string;
}
