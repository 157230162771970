<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Books</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Assessment Name..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewaddAcronyms()">Add Book</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="book_title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Book Title</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.book_title }}">{{
              element.book_title
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addAcronyms">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Book</p>
      <p *ngIf="IsUpdate">Edit Book</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="CertificateForm">
          <div class="formwrapper">
            <div class="w-50">
              <div class="select-box">
                <select
                  [(ngModel)]="SelectedCertificate"
                  formControlName="SelectedCertificate"
                  required
                  [ngClass]="{
                    err:
                      CertificateForm.controls['SelectedCertificate'].hasError(
                        'required'
                      ) &&
                      (CertificateForm.controls['SelectedCertificate'].dirty ||
                        CertificateForm.controls['SelectedCertificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100">
              <textarea
                type="text"
                [(ngModel)]="book_title"
                formControlName="book_title"
                placeholder="Book Title"
                required
                [ngClass]="{
                  err:
                    CertificateForm.controls['book_title'].hasError(
                      'required'
                    ) &&
                    (CertificateForm.controls['book_title'].dirty ||
                      CertificateForm.controls['book_title'].touched)
                }"
              ></textarea>
            </div>
            <div class="w-100">
              <div class="d-flex2">
                <input
                  type="text"
                  [(ngModel)]="book_img"
                  formControlName="book_img"
                  placeholder="Book Image"
                />
                <span>
                  <label for="book_img" class="upload-label">
                    <mat-icon class="upload">upload</mat-icon>Select File
                  </label>
                  <input
                    type="file"
                    class="file"
                    id="book_img"
                    (change)="handleFileSelect($event)"
                  />
                  <mat-icon class="link">link</mat-icon>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <div class="search-wrap" style="justify-content: space-between">
            <h3>References</h3>
            <div class="search-icon">
              <input
                placeholder="Search by Reference Name"
                [(ngModel)]="SearchReference"
                (keyup)="filterReference()"
                name="search"
              />
              <span class="material-icons">search</span>
            </div>
          </div>
          <div class="pagesection">
            <div class="mat-elevation-z8">
              <table
                mat-table
                [dataSource]="dataSourceReference"
                matSort
                style="border-spacing: 0 10px"
              >
                <ng-container matColumnDef="srno">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.srno }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Reference Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span title="{{ element.title }}">{{ element.title }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="page_no">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Reference Page #
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span title="{{ element.page_no }}">{{
                      element.page_no
                    }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row">
                    <img
                      src="../../../assets/images/edit-1.png"
                      (click)="editReference(row)"
                      alt="Edit"
                      class="action-icon"
                    />
                    <img
                      src="../../../assets/images/remove.png"
                      (click)="deleteReference(row)"
                      alt="Delete"
                      class="action-icon"
                    />
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsReference"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsReference"
                ></tr>
              </table>

              <mat-paginator
                style-paginator
                #quespaginator
                [showTotalPages]="0"
                [length]="
                  dataSourceReference.data ? dataSourceReference.data.length : 0
                "
                [pageSize]="10"
                *ngIf="matpagination"
                [pageSizeOptions]="[10, 20, 50, 100, 500]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="UpdateCertificate()">Save</button>
      </div>
    </div>
  </div>
</div>
