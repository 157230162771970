<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Flashcards</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Assessment Name..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <div class="bulk-btn">
        <button (click)="addNewFlashcard()">Add Flashcard</button>
      </div>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="free_paid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Free/Paid</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.free_paid }}">{{ element.free_paid }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.domin_name }}">{{
              element.domin_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Domain</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.subdomain_name }}">{{
              element.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.topic_name }}">{{
              element.topic_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="term">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Term</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.term }}">{{ element.term }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="definition">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Definition</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.definition }}">{{
              element.definition
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addFlashcard">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Flashcard</p>
      <p *ngIf="IsUpdate">Edit Flashcard</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="FlashcardForm">
          <div class="formwrapper">
            <div class="w-50">
              <div class="select-box">
                <select
                  (change)="certificatechange($event)"
                  (keyup)="certificatechange($event)"
                  (keydown)="certificatechange($event)"
                  [(ngModel)]="Certificate"
                  formControlName="Certificate"
                  required
                  [ngClass]="{
                    err:
                      FlashcardForm.controls['Certificate'].hasError(
                        'required'
                      ) &&
                      (FlashcardForm.controls['Certificate'].dirty ||
                        FlashcardForm.controls['Certificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="w-100">
              <!-- <label>Domain</label> -->
              <div class="select-box">
                <select
                  (change)="domainchange($event)"
                  [attr.disabled]="this.Certificate == '' ? true : null"
                  [(ngModel)]="Domainname"
                  formControlName="Domainname"
                  required
                  [ngClass]="{
                    err:
                      FlashcardForm.controls['Domainname'].hasError(
                        'required'
                      ) &&
                      (FlashcardForm.controls['Domainname'].dirty ||
                        FlashcardForm.controls['Domainname'].touched)
                  }"
                >
                  <option value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="w-100">
              <!-- <label>Sub Domain</label> -->
              <div class="select-box">
                <select
                  (change)="SubDomainchange($event)"
                  [attr.disabled]="this.Domainname == '' ? true : null"
                  [(ngModel)]="Subdomainname"
                  formControlName="Subdomainname"
                  required
                  [ngClass]="{
                    err:
                      FlashcardForm.controls['Subdomainname'].hasError(
                        'required'
                      ) &&
                      (FlashcardForm.controls['Subdomainname'].dirty ||
                        FlashcardForm.controls['Subdomainname'].touched)
                  }"
                >
                  <option value="">Select Sub-domain</option>
                  <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="w-100">
              <!-- <label>Topic</label> -->
              <div class="select-box">
                <select
                  [attr.disabled]="this.Subdomainname == '' ? true : null"
                  [(ngModel)]="Topicname"
                  formControlName="Topicname"
                  required
                  [ngClass]="{
                    err:
                      FlashcardForm.controls['Topicname'].hasError(
                        'required'
                      ) &&
                      (FlashcardForm.controls['Topicname'].dirty ||
                        FlashcardForm.controls['Topicname'].touched)
                  }"
                >
                  <option value="">Select Topic</option>
                  <option *ngFor="let item of Topic" value="{{ item.id }}">
                    {{ item.topic_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="w-50">
              <!-- <label>Free/Paid</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="free_paid"
                  formControlName="free_paid"
                  required
                  [ngClass]="{
                    err:
                      FlashcardForm.controls['free_paid'].hasError(
                        'required'
                      ) &&
                      (FlashcardForm.controls['free_paid'].dirty ||
                        FlashcardForm.controls['free_paid'].touched)
                  }"
                >
                  <option value="">Select</option>
                  <option value="free">Free</option>
                  <option value="paid">Paid</option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="w-100">
              <!-- <label>Term</label> -->
              <input
                [(ngModel)]="Term"
                formControlName="Term"
                placeholder="Term"
                name="name"
                [ngClass]="{
                  err:
                    FlashcardForm.controls['Term'].hasError('required') &&
                    (FlashcardForm.controls['Term'].dirty ||
                      FlashcardForm.controls['Term'].touched)
                }"
              />
            </div>

            <div class="w-100">
              <!-- <label>Definition</label> -->
              <textarea
                [(ngModel)]="Definition"
                formControlName="Definition"
                placeholder="Definition"
                name="search"
                [ngClass]="{
                  err:
                    FlashcardForm.controls['Definition'].hasError('required') &&
                    (FlashcardForm.controls['Definition'].dirty ||
                      FlashcardForm.controls['Definition'].touched)
                }"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="SubmitFlashcard()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="UpdateFlashcard()">Update</button>
      </div>
    </div>
  </div>
</div>
