import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { AcronymsService } from '../../services/acronyms/acronyms.service';
import { ServicesService } from '../../services.service';
@Component({
  selector: 'app-acronyms',
  templateUrl: './acronyms.component.html',
  styleUrls: ['./acronyms.component.scss'],
})
export class AcronymsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'short_name',
    'full_name',
    'actions',
  ];
  dataSource: MatTableDataSource<certificatelist>;
  Search: any;
  addAcronyms: boolean = false;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Abbreviation: any;
  Fullname: any;
  SelectedCertificate: any;
  id: any;
  IsUpdate: boolean = false;
  public CertificateForm: FormGroup;
  Certificatelist = [];
  loglist = [];
  oldAuditValueArray = { Certificate: '', Abbreviation: '', Phrases: '' };
  newAuditValueArray = { Certificate: '', Abbreviation: '', Phrases: '' };
  auditHistory = '';
  constructor(
    public AcronymsService: AcronymsService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  closeModal() {
    this.addAcronyms = false;
    this.Abbreviation = '';
    this.SelectedCertificate = '';
    this.Fullname = '';
    this.oldAuditValueArray = {
      Certificate: '',
      Abbreviation: '',
      Phrases: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Abbreviation: '',
      Phrases: '',
    };
    this.auditHistory = '';
  }
  addNewaddAcronyms() {
    this.addAcronyms = true;
    this.IsUpdate = false;
  }
  async ngOnInit() {
    this.CertificateForm = this.fb.group({
      Abbreviation: ['', Validators.required],
      Fullname: ['', Validators.required],
      SelectedCertificate: ['', Validators.required],
    });
    await this.spinner.show();
    await this.listCertificatedata();
    await this.ListCertificate();
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      try {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.AcronymsService.Deleteacronyms(item.id, token).then(
                  async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();
                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.full_name +
                              '</span> Acronyms is deleted by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'acronyms',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.listCertificatedata();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Acronyms has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  },
                );
              }
            });
        }
      } catch (e) {
        await this.spinner.hide();
      }
    });
  }
  async listCertificatedata() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: certificatelist[] = [];
            await this.AcronymsService.Listacronyms(token).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  var srno = 0;
                  await list.data.forEach(async (element) => {
                    srno = srno + 1;
                    await users.push({
                      srno: srno,
                      id: element.id,
                      certificate_name: element.certificate.certificate_name,
                      certi_id: element.certi_id,
                      short_name: element.short_name,
                      full_name: element.full_name,
                      status: element.status,
                      createdAt: element.createdAt,
                      updatedAt: element.updatedAt,
                    });
                  });
                  this.dataSource = new MatTableDataSource(users);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.filter();
                  this.pagination = true;
                  await this.spinner.hide();
                }
              },
            );
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('acronyms', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.id = item.id;
    this.Fullname = item.full_name;
    this.Abbreviation = item.short_name;
    this.SelectedCertificate = item.certi_id;
    this.IsUpdate = true;
    this.addAcronyms = true;
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find(
        (x) => x.id == this.SelectedCertificate,
      )
        ? this.Certificatelist.find((x) => x.id == this.SelectedCertificate)
            .certificate_name
        : '',
      Abbreviation: this.Abbreviation,
      Phrases: this.Fullname,
    };
  }
  async onSubmit() {
    this.markFormTouched(this.CertificateForm);
    if (this.CertificateForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.AcronymsService.Addacronyms(
              this.Abbreviation,
              this.Fullname,
              this.SelectedCertificate,
              token,
            ).then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.Fullname +
                        '</span> Acronyms is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'acronyms',
                        list.data.id,
                        token,
                      );
                    }
                  });
                this.addAcronyms = false;
                this.closeModal();
                await this.listCertificatedata();
                await this.spinner.hide();
                this.service.ShowSuccess('Acronyms Added Successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
          }
        });
    }
  }
  async UpdateCertificate() {
    this.markFormTouched(this.CertificateForm);
    if (this.CertificateForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.AcronymsService.Updateacronyms(
              this.id,
              this.Abbreviation,
              this.Fullname,
              this.SelectedCertificate,
              token,
            ).then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      this.newAuditValueArray = {
                        Certificate: this.Certificatelist.find(
                          (x) => x.id == this.SelectedCertificate,
                        )
                          ? this.Certificatelist.find(
                              (x) => x.id == this.SelectedCertificate,
                            ).certificate_name
                          : '',
                        Abbreviation: this.Abbreviation,
                        Phrases: this.Fullname,
                      };
                      var audit = this.auth.compareAuditHist(
                        this.oldAuditValueArray,
                        this.newAuditValueArray,
                      );
                      var desc = audit;
                      await this.service.addAdminLogs(
                        desc,
                        'acronyms',
                        this.id,
                        token,
                      );
                    }
                  });
                this.addAcronyms = false;
                this.IsUpdate = false;
                this.closeModal();
                await this.listCertificatedata();
                await this.spinner.hide();
                this.service.ShowSuccess('Acronyms Updated Successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
          }
        });
    }
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.spinner.show();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data;
            this.SelectedCertificate = '';
          }
        });
        this.spinner.hide();
      }
    });
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface certificatelist {
  srno: number;
  id: string;
  certificate_name: string;
  certi_id: string;
  short_name: string;
  full_name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}
