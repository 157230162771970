import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class TopicService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  //start Topic
  async AddTopic(
    certi_id,
    topic_name,
    d_id,
    sd_id,
    cource_id,
    token,
    description,
    sr_number,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          topic_name: topic_name,
          d_id: d_id,
          sd_id: sd_id,
          certi_id: certi_id,
          status: '0',
          cource_id: parseInt(cource_id),
          description: description,
          sr_number: sr_number,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();

      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TOPIC_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateTopic(
    certi_id,
    topic_name,
    d_id,
    sd_id,
    id,
    cource_id,
    token,
    description,
    sr_number,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          topic_name: topic_name,
          d_id: d_id,
          sd_id: sd_id,
          certi_id: certi_id,
          status: '0',
          cource_id: parseInt(cource_id),
          description: description,
          sr_number: sr_number,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TOPIC + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteTopic(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TOPIC + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListTopic(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TOPIC_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async IdTopic(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TOPIC + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  //start sub topic
  async AddSubTopic(
    topic_id,
    subtopic_name,
    d_id,
    sd_id,
    cource_id,
    description,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          topic_id: topic_id,
          subtopic_name: subtopic_name,
          d_id: d_id,
          sd_id: sd_id,
          cource_id: cource_id,
          status: '0',
          description: description,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();

      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SUB_TOPIC_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async IdSubTopic(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: 1,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();

      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SUB_TOPIC_BY_ID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateSubTopic(
    id,
    topic_id,
    subtopic_name,
    d_id,
    sd_id,
    cource_id,
    description,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          topic_id: topic_id,
          subtopic_name: subtopic_name,
          d_id: d_id,
          sd_id: sd_id,
          cource_id: cource_id,
          status: '0',
          description: description,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SUB_TOPIC_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListSubTopic(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SUB_TOPIC_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
}
