<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Meetings</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Meeting Name..."
          [(ngModel)]="SearchMeeting"
          (keyup)="filterMeeting()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="openModal()">Add Meeting</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSourceMeeting"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">{{ row.srno }}</td>
        </ng-container>
        <ng-container matColumnDef="cohort_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Cohort/Live Class
          </th>
          <td mat-cell *matCellDef="let row">{{ row.cohort_name }}</td>
        </ng-container>
        <ng-container matColumnDef="meeting_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Meeting Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.meeting_name }}</td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
          <td mat-cell *matCellDef="let row">{{ row.createdBy }}</td>
        </ng-container>
        <ng-container matColumnDef="display_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let row">{{ row.display_date }}</td>
        </ng-container>
        <ng-container matColumnDef="time_zone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Time Zone</th>
          <td mat-cell *matCellDef="let row">{{ row.time_zone }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row" class="icons">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
            <button class="btn-start" (click)="startMeeting(row)">Start</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsMeeting"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMeeting"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      [pageSizeOptions]="[50, 100, 500]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
<div class="cutome-modal" *ngIf="addMeeting">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Meeting</p>
      <p *ngIf="IsUpdate">Edit Meeting</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <div class="formwrapper">
          <div class="w-100">
            <div class="select-box">
              <select
                required
                (change)="changeCohort()"
                [(ngModel)]="SelectedCohort"
                name="SelectedCohort"
              >
                <option value="">Select Live Class</option>
                <option *ngFor="let items of Cohorts" value="{{ items.id }}">
                  {{ items.cohort_name }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-50">
              <input
                type="text"
                [(ngModel)]="MeetingName"
                placeholder="Meeting Title"
              />
            </div>
            <div class="w-50">
              <input
                class="date-time"
                title="Meeting Start Date"
                type="datetime-local"
                [(ngModel)]="MeetingStartDate"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="w-50">
              <ng-moment-timezone-picker
                customPlaceholderText="Time Zone"
                [(ngModel)]="time_zone"
                clearOnBackspace="true"
              >
              </ng-moment-timezone-picker>
            </div>
          </div>
        </div>
        <div class="search-div">
          <div class="search-wrap">
            <div class="search-icon">
              <input
                placeholder="Search by Student Name/Email"
                [(ngModel)]="Search"
                autocomplete="off"
                (keyup)="filter()"
                name="Search"
              />
              <span class="material-icons">search</span>
            </div>
          </div>
        </div>
        <div class="pagesection">
          <div class="mat-elevation-z8">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              style="border-spacing: 0 10px"
            >
              <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="check-app">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="srno">
                <th mat-header-cell *matHeaderCellDef>#</th>
                <td mat-cell *matCellDef="let row">{{ row.srno }}</td>
              </ng-container>
              <ng-container matColumnDef="student_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Student Name
                </th>
                <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Email Address
                </th>
                <td mat-cell *matCellDef="let row">{{ row.email }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
        <div class="page-footer">
          <mat-paginator
            [pageSizeOptions]="[50, 100, 500]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div>
        <button
          class="submit-btn"
          (click)="createMeeting()"
          [disabled]="selection.selected.length <= 0 || !MeetingName"
          *ngIf="!IsUpdate"
        >
          Save
        </button>
        <button
          class="submit-btn"
          (click)="updateMeeting()"
          [disabled]="selection.selected.length <= 0 || !MeetingName"
          *ngIf="IsUpdate"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
