import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from '../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../service/authorization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../services/client/client.service';
import { CohortService } from '../services/cohort/cohort.service';
import { EmailService } from '../services/email/email.service';
@Component({
  selector: 'app-addstudentcohort',
  templateUrl: './addstudentcohort.component.html',
  styleUrls: ['./addstudentcohort.component.scss'],
})
export class AddstudentcohortComponent implements OnInit {
  id: any;
  Userlistdata = [];
  Search: any;
  idcohort = [];
  SearchFilter = [];
  selectallcheck = false;
  SearchUser: any;
  SelectedUsers = [];
  CohortName: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public emailService: EmailService,
    public cohortService: CohortService,
    public clientService: ClientService,
    public ActiveRoute: ActivatedRoute,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    } else {
      this.ActiveRoute.params.subscribe(async (params) => {
        try {
          this.id = params['id'];
        } catch (error) {
          this.router.navigate(['/main/liveclass']);
        }
      });
    }
  }
  async ngOnInit() {
    await this.spinner.show();
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.listuser();
            await this.cohortService
              .IdCohort(this.id, token)
              .then(async (value) => {
                var idcohort = JSON.parse(<string>value[0].json);
                this.CohortName = idcohort.data[0].cohort_name;
                this.idcohort = idcohort.data[0].userslist;
                if (this.Userlistdata.length > 0) {
                  await this.Userlistdata.forEach(async (element) => {
                    var count = 0;
                    if (this.idcohort.length > 0) {
                      await this.idcohort.forEach((elementuser) => {
                        if (elementuser == element.id) {
                          count = 1;
                        }
                      });
                      if (count == 1) {
                        element['check'] = true;
                      } else {
                        element['check'] = false;
                      }
                    }
                  });
                  this.SearchFilter = [];
                  this.SearchFilter = this.Userlistdata;
                  this.SelectedUsers = this.Userlistdata.filter(function (e) {
                    return e.check == true;
                  });
                  await this.spinner.hide();
                } else {
                  await this.spinner.hide();
                }
              });
          }
        });
    } catch (e) {
      await this.spinner.hide();
    }
  }
  async listuser() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.clientService.Listuser(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Userlistdata = list.data;
          }
        });
      }
    });
  }
  async selectall(event) {
    if (event.target.checked) {
      this.Userlistdata.forEach((element) => {
        element['check'] = true;
      });
    } else {
      this.Userlistdata.forEach((element) => {
        element['check'] = false;
      });
    }
    this.SelectedUsers = this.Userlistdata.filter(function (e) {
      return e.check == true;
    });
  }
  async UserSelect(event, data) {
    if (event.target.checked) {
      data.check = true;
    } else {
      data.check = false;
    }
    var count = 0;
    for (let i = 0; i < this.Userlistdata.length; i++) {
      if (this.Userlistdata[i].check == false) {
        count = 1;
        break;
      }
    }
    if (count == 1) {
      this.selectallcheck = false;
    } else {
      this.selectallcheck = true;
    }
    this.SelectedUsers = this.Userlistdata.filter(function (e) {
      return e.check == true;
    });
  }
  search() {
    if (this.SearchUser && this.SearchUser.trim() != '') {
      this.Userlistdata = this.SearchFilter.filter((item) => {
        if (item.email != '' && item.email != undefined && item.email != null) {
          return (
            item.email.toLowerCase().indexOf(this.SearchUser.toLowerCase()) >
              -1 ||
            item.name.toLowerCase().indexOf(this.SearchUser.toLowerCase()) > -1
          );
        }
      });
    } else {
      this.Userlistdata = this.SearchFilter;
    }
  }
  async submit() {
    try {
      this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var UserpushId = '';
          var Emails = '';
          await this.SearchFilter.forEach((element) => {
            if (element.check == true) {
              UserpushId = UserpushId + element.id + ',';
              Emails = Emails + element.email + ', ';
            }
          });
          if (UserpushId.endsWith(',') == true) {
            UserpushId = UserpushId.substring(0, UserpushId.length - 1);
          }
          if (Emails.endsWith(',') == true) {
            Emails = Emails.substring(0, Emails.length - 2);
          }
          await this.cohortService
            .EditCohortuser(UserpushId, this.id, token)
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        'Following users <span>' +
                        Emails +
                        '</span> is updated in ' +
                        this.CohortName +
                        '  by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'addstudentcohort',
                        this.id,
                        token,
                      );
                    }
                  });
                await this.spinner.hide();
                this.service.ShowSuccess(list.message);
                this.router.navigate(['main/liveclass']);
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    } catch (e) {
      await this.spinner.hide();
    }
  }
  async AddStudent() {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.SearchUser)) {
      this.service.ShowError('Please enter a valid email!');
      return;
    } else {
      await this.spinner.show();
      await this.auth.CreateUser(this.SearchUser).then(async (data) => {
        if (data[0].StatusCode == 1) {
        } else {
          await this.spinner.hide();
          this.service.ShowError(data[0].message);
        }
      });
    }
  }
  getOrdinal(n) {
    let ord = [, 'st', 'nd', 'rd'];
    let exceptions = [11, 12, 13];
    let nth =
      ord[n % 100] == undefined || exceptions.includes(n % 100)
        ? 'th'
        : ord[n % 100];
    return n + nth;
  }
}
