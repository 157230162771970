<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Manually Send</h3>
    </div>
  </div>
  <div class="select-company">
    <div class="select-box">
      <select
        required
        (change)="changeCompany()"
        [(ngModel)]="SelectedCompany"
        name="SelectedCompany"
      >
        <option value="" [disabled]="true">Select Company</option>
        <option *ngFor="let items of Companies" value="{{ items.id }}">
          {{ items.name }}
        </option>
      </select>
      <span></span>
    </div>
    <div class="select-box">
      <select
        required
        (change)="changeCohort()"
        [(ngModel)]="SelectedCohort"
        name="SelectedCohort"
        [attr.disabled]="this.SelectedCompany == '' ? true : null"
      >
        <option value="" [disabled]="true">Select Live Class</option>
        <option *ngFor="let items of Cohorts" value="{{ items.id }}">
          {{ items.cohort_name }}
        </option>
      </select>
      <span></span>
    </div>
  </div>
  <div class="search-div">
    <span>Select Students</span>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Student Name/Email"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="Search"
        />
        <span class="material-icons">search</span>
      </div>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="check-app">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">{{ row.srno }}</td>
        </ng-container>
        <ng-container matColumnDef="student_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Student Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Student Email Address
          </th>
          <td mat-cell *matCellDef="let row">{{ row.email }}</td>
        </ng-container>
        <ng-container matColumnDef="user_state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User State</th>
          <td mat-cell *matCellDef="let row">
            {{ row.user_state }}
          </td>
        </ng-container>
        <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Welcome Link
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon
              (click)="copyToClipboard(row.link)"
              title="Copy link"
              class="copyico"
              >content_copy
            </mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
  <div class="email-temp">
    <div class="select-box select-box2">
      <select
        required
        [(ngModel)]="SelectedTemplate"
        (change)="changeTemplate()"
        name="SelectedTemplate"
      >
        <option value="">Email Template</option>
        <option *ngFor="let items of Templates" value="{{ items.id }}">
          {{ items.name }}
        </option>
      </select>
      <span></span>
    </div>
    <div>
      <input placeholder="Subject" [(ngModel)]="Subject" name="Subject" />
    </div>
    <div>
      <input placeholder="CC" [(ngModel)]="CC" name="CC" />
    </div>
  </div>
  <div class="select-company select-company2">
    <label>Email Message</label>
    <div class="select-box">
      <select
        required
        [(ngModel)]="SelectedVariable"
        name="SelectedVariable"
        (change)="changeVariable()"
      >
        <option value="">Insert Variable</option>
        <option *ngFor="let items of Variables" value="{{ items.name }}">
          {{ items.name }}
        </option>
      </select>
      <span></span>
    </div>
  </div>
  <div class="ckdiv">
    <span [innerHTML]="Message" class="web-templete"></span>
  </div>
</div>
<div class="footer-div">
  <button (click)="cancelMail()">Cancel</button>
  <button (click)="sendMail()" [disabled]="selection.selected.length <= 0">
    Send
  </button>
</div>
<div class="ckdivnew" *ngIf="showFullScreen">
  <span class="material-icons ckfull" (click)="showFullScreen = !showFullScreen"
    >fullscreen_exit</span
  >
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
