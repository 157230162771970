import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  //---------------start Schedule time-------//
  async AddScheduleTime(
    template_id,
    test_id,
    start_time,
    end_time,
    type,
    examname,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          template_id: template_id,
          test_id: test_id,
          start_time: start_time,
          end_time: end_time,
          type: type,
          examname: examname,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SCHEDULE_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetAllScheduleTime(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SCHEDULE_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }
  async DeleteScheduleTime(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SCHEDULE_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateScheduleTime(
    id,
    template_id,
    test_id,
    start_time,
    end_time,
    type,
    examname,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          template_id: template_id,
          test_id: test_id,
          start_time: start_time,
          end_time: end_time,
          type: type,
          examname: examname,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SCHEDULE_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //---------------end Schedule time-------//
}
