import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { DomainService } from '../../services/domain/domain.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';

@Component({
  selector: 'app-subdomains',
  templateUrl: './subdomains.component.html',
  styleUrls: ['./subdomains.component.scss'],
})
export class SubdomainsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'domain_number',
    'subdomain_name',
    'd_id',
    'certificate_name',
    'actions',
  ];
  dataSource: MatTableDataSource<SubDomainlist>;
  Search: any;
  addSubDomain: boolean = false;
  IsUpdate: boolean = false;

  id: any;
  EditHideen = true;
  SubDomain: any;
  Domainname: any;
  Domain = [];
  Certificatelist = [];
  Certificate: any;
  filterDomain = [];
  DomainNumber: any;
  public SubDomainForm: FormGroup;
  loglist = [];
  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    'Subdomain number': '',
    Subdomain: '',
    'No Material Assign Note': '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    'Subdomain number': '',
    Subdomain: '',
    'No Material Assign Note': '',
  };
  auditHistory = '';
  no_assign_note: any;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public fb: FormBuilder,
    public domainService: DomainService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewSubDomain() {
    this.addSubDomain = true;
    this.IsUpdate = false;
    this.Domainname = '';
    this.DomainNumber = '';
    this.Certificate = '';
    this.SubDomain = '';
    this.no_assign_note = '';
  }
  closeModal() {
    this.addSubDomain = false;
    this.IsUpdate = false;
    this.Domainname = '';
    this.DomainNumber = '';
    this.Certificate = '';
    this.SubDomain = '';
    this.no_assign_note = '';
    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      'Subdomain number': '',
      Subdomain: '',
      'No Material Assign Note': '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      'Subdomain number': '',
      Subdomain: '',
      'No Material Assign Note': '',
    };
    this.auditHistory = '';
  }
  async ngOnInit() {
    this.SubDomainForm = this.fb.group({
      SubDomainName: ['', Validators.required],
      DomainName: ['', Validators.required],
      DomainNumber: ['', Validators.required],
      Certificate: ['', Validators.required],
      no_assign_note: new FormControl(),
    });
    await this.spinner.show();
    await this.listSubDomain();
    await this.GetAllDropdown();
    await this.spinner.hide();
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domain = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.Certificatelist = list.data.Certificate;

            this.Domainname = '';
            this.DomainNumber = '';
            this.Certificate = '';
            this.SubDomain = '';
            this.no_assign_note = '';
          }
        });
      }
    });
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();

              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.domainService
                .DeleteSubDomain(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();

                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            'Sub-domain is updated by <span> ' + e + '</span>';

                          await this.service.addAdminLogs(
                            desc,
                            'subdomain',
                            item.id,
                            token,
                          );
                        }
                      });
                    await this.listSubDomain();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Sub domain has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async listSubDomain() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: SubDomainlist[] = [];
        await this.domainService.ListSubDomain(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                subdomain_name: element.subdomain_name,
                domain_name: element.domain.domin_name,
                d_id: element.domain.id,
                certificate_name: element.certificate.certificate_name,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                certi_id: element.certi_id,
                domain_number: element.domain_number,
                no_assign_note: element.no_assign_note
                  ? element.no_assign_note
                  : '',
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  onBookChange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });
    } else {
      this.Domain = this.filterDomain;
    }
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('subdomain', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.IsUpdate = true;
    this.addSubDomain = true;
    this.id = item.id;
    this.Domainname = item.d_id;
    this.SubDomain = item.subdomain_name;
    this.DomainNumber = item.domain_number;
    this.Certificate = item.certi_id;
    this.no_assign_note = item.no_assign_note;
    this.Domain = this.filterDomain.filter((x) => x.certi_id == item.certi_id);
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find((x) => x.id == this.Certificate)
        ? this.Certificatelist.find((x) => x.id == this.Certificate)
            .certificate_name
        : '',
      Domain: this.filterDomain.find((x) => x.id)
        ? this.filterDomain.find((x) => x.id).domin_name
        : '',
      'Subdomain number': this.DomainNumber,
      Subdomain: this.SubDomain,
      'No Material Assign Note': this.no_assign_note,
    };
  }
  async onSubmit() {
    this.markFormTouched(this.SubDomainForm);
    if (this.SubDomainForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.domainService
              .AddSubDomain(
                this.DomainNumber,
                this.SubDomain,
                this.Domainname,
                this.Certificate,
                1,
                this.no_assign_note,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          'Sub-domain is added by <span> ' + e + '</span>';

                        await this.service.addAdminLogs(
                          desc,
                          'subdomain',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  await this.closeModal();
                  await this.listSubDomain();
                  await this.spinner.hide();
                  this.service.ShowSuccess(list.message);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async Update() {
    this.markFormTouched(this.SubDomainForm);
    if (this.SubDomainForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.domainService
              .UpdateSubDomain(
                this.DomainNumber,
                this.Certificate,
                this.SubDomain,
                this.Domainname,
                this.id,
                1,
                this.no_assign_note,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        this.addSubDomain = false;
                        this.newAuditValueArray = {
                          Certificate: this.Certificatelist.find(
                            (x) => x.id == this.Certificate,
                          )
                            ? this.Certificatelist.find(
                                (x) => x.id == this.Certificate,
                              ).certificate_name
                            : '',
                          Domain: this.filterDomain.find((x) => x.id)
                            ? this.filterDomain.find((x) => x.id).domin_name
                            : '',
                          'Subdomain number': this.DomainNumber,
                          Subdomain: this.SubDomain,
                          'No Material Assign Note': this.no_assign_note,
                        };
                        var audit = this.auth.compareAuditHist(
                          this.oldAuditValueArray,
                          this.newAuditValueArray,
                        );
                        var desc = audit;
                        await this.service.addAdminLogs(
                          desc,
                          'subdomain',
                          this.id,
                          token,
                        );

                        await this.listSubDomain();
                      }
                    });
                  await this.spinner.hide();
                  this.service.ShowSuccess('subdomain update successfully');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
}
export interface SubDomainlist {
  srno: number;
  id: number;
  subdomain_name: string;
  d_id: string;
  domain_name: string;
  status: string;
  certificate_name: string;
  certi_id: string;
  createdAt: string;
  updatedAt: string;
  domain_number: string;
  no_assign_note: string;
}
