import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { ExcelService } from 'src/app/services/excelservices/excel.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { KnowledgeService } from '../../services/knowledge/knowledge.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-knowlegeassques',
  templateUrl: './knowlegeassques.component.html',
  styleUrls: ['./knowlegeassques.component.scss'],
})
export class KnowlegeassquesComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'ass_id',
    'question',
    'domin_name',
    'subdomain_name',
    'topic_name',
    'answer',
    'actions',
  ];
  dataSource: MatTableDataSource<courselist>;
  Search: any;
  addKnowledgeassqua: boolean = false;
  id: any;
  IsUpdate = true;
  items = [];
  Filteritems = [];
  Assessment = [];
  Domain = [];
  SubDomain = [];
  Topic = [];
  selecteds = [];
  filterDomain = [];
  filtersubDomain = [];
  filterTpoic = [];
  Domainname: any;
  KnowAssname: any;
  CertificateName: any;
  Questions: any;
  subdomainname: any;
  Topicname: any;
  Explanation: any;
  Points: any;
  UpdateID: any;
  References: any;
  EditPopup = false;
  Certificates = [];
  public knowQueForm: FormGroup;
  showFullScreen: boolean = false;
  answer: any;
  loglist = [];
  isBulkUpload: boolean = false;
  files: File[] = [];
  myEvent: any;
  progress: any = 0;
  selectBulkupload: boolean = false;
  selectprogress: boolean = false;
  ckeConfig = {
    allowedContent: true,
    height: '100vh',
    toolbar: [
      {
        name: 'paragraph',
        items: [
          'Source',
          'Undo',
          'Redo',
          'RemoveFormat',
          'heading',
          'Format',
          '|',
          'FontSize',
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Font',
          'TextColor',
          'BGColor',
          'Link',
          'CreateDiv',
          'Blockquote',
          'Subscript',
          'Superscript',
          'SpecialChar',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          'Outdent',
          'Indent',
          'PageBreak',
          'NumberedList',
          'BulletedList',
          'Image',
          'Table',
          'HorizontalRule',
          'Maximize',
        ],
      },
    ],
  };

  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Question: '',
    Answer: '',
    Explanation: '',
    'Exam Name': '',
    Point: '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Question: '',
    Answer: '',
    Explanation: '',
    'Exam Name': '',
    Point: '',
  };
  auditHistory = '';
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public knowledgeService: KnowledgeService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    private excelService: ExcelService,
    public ActiveRoute: ActivatedRoute,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  addNewKnowledgeassqua() {
    this.knowQueForm.clearValidators();
    this.Domainname = '';
    this.UpdateID = '';
    this.selecteds = [];
    this.Questions = '';
    this.subdomainname = '';
    this.Topicname = '';
    this.Points = '';
    this.Explanation = '';
    this.Assessment = [];
    this.knowQueForm.get('Explanation').clearValidators();
    this.addKnowledgeassqua = true;
    this.EditPopup = false;
  }

  closeModal() {
    this.EditPopup = false;
    this.addKnowledgeassqua = false;
    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      Answer: '',
      Explanation: '',
      'Exam Name': '',
      Point: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Question: '',
      Answer: '',
      Explanation: '',
      'Exam Name': '',
      Point: '',
    };
    this.auditHistory = '';
  }

  async ngOnInit() {
    this.knowQueForm = this.fb.group({
      CertificateName: ['', Validators.required],
      Assessment: ['', Validators.required],
      Questions: ['', Validators.required],
      Domainname: ['', Validators.required],
      subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      answer: ['', Validators.required],
      Points: ['', Validators.required],
      Explanation: ['', Validators.required],
    });
    await this.spinner.show();
    try {
      await this.listPracticalQues();
      await this.listKnowAss();
      await this.GetAllDropdown();
      await this.ActiveRoute.params.subscribe(async (params) => {
        try {
          var id = JSON.parse(atob(params['id']));
          if (
            this.dataSource &&
            this.dataSource.data &&
            this.dataSource.data.length > 0
          ) {
            await this.dataSource.data.forEach(async (element) => {
              if (element.id == id) {
                await this.edit(element);
              }
            });
          }
        } catch (error) {
          this.router.navigate(['main/knowlegeassques']);
        }
      });
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  async listKnowAss() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.knowledgeService.ListKnowAss(token).then(async (data) => {
          this.items = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.items = list.data;
            this.Filteritems = list.data;
          }
        });
      }
    });
  }
  async edit(item) {
    this.CertificateName = item.certi_id;
    this.Domainname = item.d_id;
    this.UpdateID = item.id;
    this.selecteds = item.ass_id.map(Number);
    this.Questions = item.question;
    this.subdomainname = item.sd_id;
    this.Topicname = item.topic_id;
    this.answer = item.answer;
    this.Points = item.point;
    this.Explanation = item.description;
    this.addKnowledgeassqua = true;
    this.EditPopup = true;
    this.loglist = [];
    this.Domain = this.filterDomain.filter(
      (x) => x.certi_id == this.CertificateName,
    );
    this.SubDomain = this.filtersubDomain.filter(
      (x) => x.d_id == this.Domainname,
    );
    this.Topic = this.filterTpoic.filter((x) => x.sd_id == this.subdomainname);
    this.oldAuditValueArray = {
      Certificate: this.Certificates.find((x) => x.id == this.CertificateName)
        ? this.Certificates.find((x) => x.id == this.CertificateName)
            .certificate_name
        : '',
      Domain: this.filterDomain.find((x) => x.id == this.Domainname)
        ? this.filterDomain.find((x) => x.id == this.Domainname).domin_name
        : '',
      Subdomain: this.filtersubDomain.find((x) => x.id == this.subdomainname)
        ? this.filtersubDomain.find((x) => x.id == this.subdomainname)
            .subdomain_name
        : '',
      Topic: this.filterTpoic.find((x) => x.id == this.Topicname)
        ? this.filterTpoic.find((x) => x.id == this.Topicname).topic_name
        : '',
      Question: this.Questions,
      Answer: this.answer,
      Explanation: this.Explanation,
      'Exam Name': this.Filteritems.filter((item) => {
        return this.selecteds.includes(item.id);
      })
        .map(function (elem) {
          return elem.ketexam_name;
        })
        .join(','),
      Point: this.Points,
    };

    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('knowassquestion', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
  async listPracticalQues() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: courselist[] = [];
        await this.knowledgeService.ListknowAssque(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              var knowAssname = '';
              if (
                element.knowledge_asses &&
                element.knowledge_asses.length > 0
              ) {
                for (let i = 0; i < element.knowledge_asses.length; i++) {
                  knowAssname =
                    knowAssname + element.knowledge_asses[i].knowass_name + ',';
                }
                if (knowAssname.endsWith(',') == true) {
                  knowAssname = knowAssname.substring(
                    0,
                    knowAssname.length - 1,
                  );
                }
              }
              await users.push({
                srno: srno,
                id: element.id,
                aas_name: knowAssname,
                ass_id: element.ass_id,
                question: element.question,
                d_id: element.d_id,
                sd_id: element.sd_id,
                topic_id: element.topic_id,
                answer: element.answer,
                ref_link: element.ref_link,
                youtube_link: element.youtube_link,
                bookpage_no: element.bookpage_no,
                blog_url: element.blog_url,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                domin_name: element.domain.domin_name,
                subdomain_name: element.subdomain.subdomain_name,
                topic_name: element.topic.topic_name,
                point: element.point,
                description: element.description,
                certi_id: element.certi_id,
                certificate_name: element.certificate
                  ? element.certificate.certificate_name
                  : '',
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.pagination = true;
            this.filter();
          }
        });
      }
    });
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.knowledgeService
              .DeleteknowAssque(item.id, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  await this.listPracticalQues();
                  var e = window.localStorage.getItem('adminUser');
                  if (e == null || e == undefined || e == '') {
                    e = 'admin';
                  }
                  var desc =
                    '<span> ' +
                    item.question +
                    '</span> Knowledge Assessment Question is deleted by <span> ' +
                    e +
                    '</span>';
                  await this.service.addAdminLogs(
                    desc,
                    'knowassquestion',
                    item.id,
                    token,
                  );
                  await this.listPracticalQues();
                  await this.spinner.hide();
                  Swal.fire(
                    list.message,
                    'Question has been deleted.',
                    'success',
                  );
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      }
    });
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domain = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.SubDomain = list.data.SubDomain;
            this.filtersubDomain = list.data.SubDomain;
            this.Topic = list.data.Topic;
            this.filterTpoic = list.data.Topic;
            this.Certificates = list.data.Certificate;

            this.Domainname = '';
            this.KnowAssname = '';
            this.CertificateName = '';
            this.Questions = '';
            this.subdomainname = '';
            this.Topicname = '';
            this.answer = '';
            this.Explanation = '';
            this.CertificateName = '';
          }
        });
      }
    });
  }
  async certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });
      this.items = this.Filteritems.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.Domainname = '';
      this.subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.Domain = this.filterDomain;
    }
  }
  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });
      this.subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.subdomainname = '';
      this.SubDomain = this.filtersubDomain;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return itemset.sd_id == val;
      });
      this.Topicname = '';
    } else {
      this.Topicname = '';
      this.Topic = this.filterTpoic;
    }
  }
  async save() {
    this.markFormTouched(this.knowQueForm);
    if (this.knowQueForm.valid) {
      try {
        await this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            var data = '';
            data = this.Explanation;
            var h1 = `<h1 class="ck-heading_heading1">&nbsp;</h1>`;
            var h2 = `<h2 class="ck-heading_heading2">&nbsp;</h2>`;
            var h3 = `<h3 class="ck-heading_heading3">&nbsp;</h3>`;
            var ptag = `<p>&nbsp;</p>`;
            data = data.toString().replace(new RegExp(h1, 'g'), '');
            data = data.toString().replace(new RegExp(h2, 'g'), '');
            data = data.toString().replace(new RegExp(h3, 'g'), '');
            data = data.toString().replace(new RegExp(ptag, 'g'), '');
            this.Explanation = data;
            await this.knowledgeService
              .AddknowAssque(
                this.selecteds.toString(),
                this.Questions,
                this.Domainname,
                this.subdomainname,
                this.Topicname,
                this.answer,
                token,
                this.Points,
                this.Explanation,
                this.References,
                this.CertificateName,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  this.addKnowledgeassqua = false;
                  var list = JSON.parse(<string>value[0].json);
                  var e = window.localStorage.getItem('adminUser');
                  if (e == null || e == undefined || e == '') {
                    e = 'admin';
                  }
                  var desc =
                    '<span> ' +
                    this.Questions +
                    '</span> Knowledge Assessment Question is added by <span> ' +
                    e +
                    '</span>';
                  await this.service.addAdminLogs(
                    desc,
                    'knowassquestion',
                    list.data.id,
                    token,
                  );
                  await this.listPracticalQues();
                  await this.spinner.hide();
                  this.service.ShowSuccess(list.message);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  async Update() {
    this.markFormTouched(this.knowQueForm);
    if (this.knowQueForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var data = '';
          data = this.Explanation;
          var h1 = `<h1 class="ck-heading_heading1">&nbsp;</h1>`;
          var h2 = `<h2 class="ck-heading_heading2">&nbsp;</h2>`;
          var h3 = `<h3 class="ck-heading_heading3">&nbsp;</h3>`;
          var ptag = `<p>&nbsp;</p>`;
          data = data.toString().replace(new RegExp(h1, 'g'), '');
          data = data.toString().replace(new RegExp(h2, 'g'), '');
          data = data.toString().replace(new RegExp(h3, 'g'), '');
          data = data.toString().replace(new RegExp(ptag, 'g'), '');
          this.Explanation = data;
          await this.knowledgeService
            .UpdateknowAssque(
              this.UpdateID,
              this.selecteds.toString(),
              this.Questions,
              this.Domainname,
              this.subdomainname,
              this.Topicname,
              this.answer,
              token,
              this.Points,
              this.Explanation,
              this.References,
              this.CertificateName,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);

                this.addKnowledgeassqua = false;
                this.EditPopup = false;
                var e = window.localStorage.getItem('adminUser');
                if (e == null || e == undefined || e == '') {
                  e = 'admin';
                }
                this.newAuditValueArray = {
                  Certificate: this.Certificates.find(
                    (x) => x.id == this.CertificateName,
                  )
                    ? this.Certificates.find(
                        (x) => x.id == this.CertificateName,
                      ).certificate_name
                    : '',
                  Domain: this.filterDomain.find((x) => x.id == this.Domainname)
                    ? this.filterDomain.find((x) => x.id == this.Domainname)
                        .domin_name
                    : '',
                  Subdomain: this.filtersubDomain.find(
                    (x) => x.id == this.subdomainname,
                  )
                    ? this.filtersubDomain.find(
                        (x) => x.id == this.subdomainname,
                      ).subdomain_name
                    : '',
                  Topic: this.filterTpoic.find((x) => x.id == this.Topicname)
                    ? this.filterTpoic.find((x) => x.id == this.Topicname)
                        .topic_name
                    : '',
                  Question: this.Questions,
                  Answer: this.answer,
                  Explanation: this.Explanation,
                  'Exam Name': this.Filteritems.filter((item) => {
                    return this.selecteds.includes(item.id);
                  })
                    .map(function (elem) {
                      return elem.ketexam_name;
                    })
                    .join(','),
                  Point: this.Points,
                };
                var audit = this.auth.compareAuditHist(
                  this.oldAuditValueArray,
                  this.newAuditValueArray,
                );
                var desc = audit;
                await this.service.addAdminLogs(
                  desc,
                  'knowassquestion',
                  this.UpdateID,
                  token,
                );
                await this.listPracticalQues();

                await this.spinner.hide();
                this.service.ShowSuccess('Question update successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async bulkCheckDKIM(data) {
    let response = 0;
    data.forEach(async (element) => {
      let domain = element.email.split('@')[1];
      await this.auth.verifyDKIM(domain).then(async (result) => {
        if (result[0].StatusCode == 1) {
          response = 1;
        }
      });
    });
    return response;
  }
  async openBulkUploadModal() {
    this.isBulkUpload = true;
  }
  async closeBulkUploadModal() {
    this.isBulkUpload = false;
    this.progress = 0;
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.files = [];
    this.myEvent = '';
  }
  async bulkUpload() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.myEvent.addedFiles[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
    };

    reader.readAsBinaryString(file);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.selectBulkupload = true;
    this.selectprogress = false;
  }
  onSelect(event) {
    let me = this;
    var timeleft = 10;
    me.progress = 0;
    me.selectprogress = true;
    me.selectBulkupload = true;
    this.files.push(event.addedFiles);
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        me.progress = 10 - timeleft;
        me.myEvent = event;
        me.selectBulkupload = false;
        me.selectprogress = false;
        clearInterval(downloadTimer);
      }
      me.progress = 10 - timeleft;
      timeleft -= 1;
    }, 200);
  }
  ExportExcel() {
    var certification = Object.assign(
      this.Certificates.map((k) => ({ name: k.certificate_name })),
    );
    var domain = Object.assign(
      this.Domain.map((k) => ({ name: k.domin_name })),
    );
    var subdomain = Object.assign(
      this.SubDomain.map((k) => ({ name: k.subdomain_name })),
    );
    var topic = Object.assign(this.Topic.map((k) => ({ name: k.topic_name })));
    var Exam = Object.assign(
      this.Filteritems.map((k) => ({ name: k.knowass_name })),
    );
    var Correct = [
      {
        workSheet: 'Correct',
        rows: [{ name: 'true' }, { name: 'false' }],
        properties: { state: 'hidden' },
      },
    ];
    var certificationData = [
      {
        workSheet: 'certification',
        rows: certification,
        properties: { state: 'hidden' },
      },
    ];
    var domainData = [
      {
        workSheet: 'domain',
        rows: domain,
        properties: { state: 'hidden' },
      },
    ];
    var subdomainData = [
      {
        workSheet: 'subdomain',
        rows: subdomain,
        properties: { state: 'hidden' },
      },
    ];
    var topicData = [
      {
        workSheet: 'topic',
        rows: topic,
        properties: { state: 'hidden' },
      },
    ];
    var ExamData = [
      {
        workSheet: 'Exam',
        rows: Exam,
        properties: { state: 'hidden' },
      },
    ];
    var KA_sample = [
      {
        name: 'KA_sample',
        values: [
          {
            header: 'certification',
            value: [
              { name: 'certification!$A$2:$A$' + (certification.length + 1) },
            ],
          },
          {
            header: 'domain',
            value: [{ name: 'domain!$A$2:$A$' + (domain.length + 1) }],
          },
          {
            header: 'subdomain',
            value: [{ name: 'subdomain!$A$2:$A$' + (subdomain.length + 1) }],
          },
          {
            header: 'topic',
            value: [{ name: 'topic!$A$2:$A$' + (topic.length + 1) }],
          },
          {
            header: 'Exam',
            value: [{ name: 'Exam!$A$2:$A$' + (Exam.length + 1) }],
          },
          { header: 'question', value: '' },
          { header: 'correct_answer', value: [{ name: 'Correct!$A$2:$A$5' }] },
          { header: 'point', value: '' },
          { header: 'explanation', value: '' },
        ],
        properties: { state: 'visible' },
      },
    ];
    var workbookData = [
      this.transform(KA_sample)[0],
      certificationData[0],
      domainData[0],
      subdomainData[0],
      topicData[0],
      ExamData[0],
      Correct[0],
    ];
    this.excelService.exportAsExcelFile(workbookData, KA_sample[0].name);
  }
  transform(data) {
    const noOfRowaToGenerate = 5;
    return data.map(({ name, values, properties }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value)
            ? next.value.map(({ name }) => name)
            : next.value,
        }),
        {},
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers),
        properties: properties,
      };
    });
  }
}
export interface courselist {
  srno: number;
  id: string;
  ass_id: string;
  question: string;
  d_id: string;
  sd_id: string;
  topic_id: string;
  answer: string;
  ref_link: string;
  youtube_link: string;
  bookpage_no: string;
  blog_url: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  domin_name: string;
  subdomain_name: string;
  topic_name: string;
  aas_name: string;
  point: string;
  description: string;
  certi_id: string;
  certificate_name: string;
}
export class UploadAdapter {
  private loader;
  constructor(loader: any) {
    this.loader = loader;
  }
  public upload(): Promise<any> {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = (e) => {
            resolve({ default: myReader.result });
          };
          myReader.readAsDataURL(file);
        }),
    );
  }
}
