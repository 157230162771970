import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from 'src/app/services.service';
import {
  ConsoleLogger,
  DefaultAudioVideoController,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultReconnectController,
  DefaultWebSocketAdapter,
  FullJitterBackoff,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import * as Chime from 'aws-sdk/clients/chime';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthorizationService } from 'src/app/service/authorization.service';
import { CohortService } from 'src/app/services/cohort/cohort.service';
import { isArray } from 'util';
import { Location } from '@angular/common';
import { LiveeventsService } from 'src/app/services/liveevents/liveevents.service';
@Component({
  selector: 'app-joinmeeting',
  templateUrl: './joinmeeting.component.html',
  styleUrls: ['./joinmeeting.component.scss'],
})
export class JoinmeetingComponent implements OnInit {
  MeetingResponse = {
    meeting: {
      Meeting: {
        MeetingId: 'd3fb6e1e-2876-4bcc-aa14-860d57620706',
        ExternalMeetingId: '33f3f459-6840-41e5-83ac-d8c2302ff61f',
        MediaPlacement: {
          AudioHostUrl:
            '4e6deb0b8b66f4a6b7ea8d291eca1d09.k.m1.ue1.app.chime.aws:3478',
          AudioFallbackUrl:
            'wss://haxrp.m1.ue1.app.chime.aws:443/calls/d3fb6e1e-2876-4bcc-aa14-860d57620706',
          ScreenDataUrl:
            'wss://bitpw.m1.ue1.app.chime.aws:443/v2/screen/d3fb6e1e-2876-4bcc-aa14-860d57620706',
          ScreenSharingUrl:
            'wss://bitpw.m1.ue1.app.chime.aws:443/v2/screen/d3fb6e1e-2876-4bcc-aa14-860d57620706',
          ScreenViewingUrl:
            'wss://bitpw.m1.ue1.app.chime.aws:443/ws/connect?passcode=null&viewer_uuid=null&X-BitHub-Call-Id=d3fb6e1e-2876-4bcc-aa14-860d57620706',
          SignalingUrl:
            'wss://signal.m1.ue1.app.chime.aws/control/d3fb6e1e-2876-4bcc-aa14-860d57620706',
          TurnControlUrl: 'https://ccp.cp.ue1.app.chime.aws/v2/turn_sessions',
        },
        MediaRegion: 'us-east-1',
      },
    },
    attendee: {
      Attendee: {
        ExternalUserId: '319c9264#2017',
        AttendeeId: '7f129f67-8a72-c9ad-fa73-68dcef4b3499',
        JoinToken:
          'N2YxMjlmNjctOGE3Mi1jOWFkLWZhNzMtNjhkY2VmNGIzNDk5OmY3OGMwNDE3LTY2NmYtNGQ5NS1hY2U4LTIwNWE0Y2YyM2NlZQ',
      },
    },
  };
  MediaCapturePipeline = {
    MediaCapturePipeline: {
      MediaPipelineId: 'c62ad79d-e58b-4139-9ffc-a538ac79aec0',
      SourceType: 'ChimeSdkMeeting',
      SourceArn:
        'arn:aws:chime::462849534434:meeting:7872af28-a0fe-4b0a-b7dc-72e6d9ec0706',
      Status: 'Initializing',
      SinkType: 'S3Bucket',
      SinkArn:
        'arn:aws:s3:::chatmultimediadata/captures/7872af28-a0fe-4b0a-b7dc-72e6d9ec0706',
      CreatedTimestamp: '2021-10-19T08:55:40.981Z',
      UpdatedTimestamp: '2021-10-19T08:55:41.064Z',
      ChimeSdkMeetingConfiguration: {
        ArtifactsConfiguration: {
          Audio: {
            MuxType: 'AudioWithActiveSpeakerVideo',
          },
          Video: {
            State: 'Disabled',
            MuxType: null,
          },
          Content: {
            State: 'Enabled',
            MuxType: 'ContentOnly',
          },
        },
      },
    },
  };
  meetingSession: DefaultMeetingSession;
  IsMuted: boolean = false;
  IsStartVideo: boolean = true;
  IsFullScreen: boolean = false;
  ShowAttendees: boolean = true;
  ShowChat: boolean = false;
  IsScreenShare: boolean = false;
  totalSeconds = 0;
  QuestionsAnsweredArray = [];
  QuestionsAnswered: boolean = false;
  saveButtonText = 'Save';
  question = '';
  cohort_id: any;
  db_meeting_id: any;
  TempMessages = [];
  Messages = [];
  message = '';
  toggled: boolean = false;
  chime = new Chime({ region: 'us-east-1' });
  Attendees = [];
  AttendeesCount = 0;
  MeetingName: any;
  AdminUserId: any;
  IsRecord: boolean = false;
  IsAutoRecord: boolean = false;
  certi_id: any;
  event_id: any;
  title: any;
  toggledView: boolean = false;
  AdminAttendeeId: any;
  AdminTileId: any;
  live_event: any;
  time_zone: any;
  selectedAudio = '';
  selectedVideo = '';
  chooseDevicePopup: boolean = false;
  audioDevices = [];
  videoDevices = [];
  constructor(
    private location: Location,
    public cohortService: CohortService,
    public auth: AuthorizationService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public liveservice: LiveeventsService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        var data = JSON.parse(atob(params['id']));
        await this.getMeetingById(data.meeting_id);
      } catch (error) {}
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.toggledView = true;
    this.spinner.hide();
    setInterval(() => {
      this.totalSeconds++;
    }, 1000);
  }
  async back() {
    this.endMeeting();
  }
  saveQuestionsAnswered() {
    this.saveButtonText = 'Saving...';
    this.QuestionsAnsweredArray.push({
      Text: this.question,
      FromTime: this.convertSecondsToTime(this.totalSeconds),
    });
    sessionStorage.setItem(
      'QuestionsAnsweredArray',
      JSON.stringify(this.QuestionsAnsweredArray),
    );
    setTimeout(() => {
      this.saveButtonText = 'Save';
      this.question = '';
    }, 2000);
  }
  async getMeetingById(id) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getMeetingById(id, token).then(async (result) => {
          var list = JSON.parse(<string>result[0].json);
          this.live_event = list.data;
          this.time_zone =
            this.live_event.time_zone == null || this.live_event.time_zone == ''
              ? this.auth.currentTimeZone()
              : JSON.parse(this.live_event.time_zone);
          if (result[0].status == 200) {
            if (list.data.is_record == 1) {
              this.IsRecord = true;
            }
            if (list.data.is_auto_record == 1) {
              this.IsAutoRecord = true;
            }
            this.certi_id = list.data.certi_id;
            this.event_id = list.data.id;
            this.MeetingName = list.data.event_name;
            this.AdminUserId = list.data.created_by;
            let att = JSON.parse(list.data.attendees);
            let attendees = '';
            att.forEach((element) => {
              attendees = attendees + element.U + ',';
            });
            if (attendees.endsWith(',') == true) {
              attendees = attendees.substring(0, attendees.length - 1);
            }
            this.AdminAttendeeId = list.data.admin_attendee_id;
            await this.getMeeting(
              list.data.admin_attendee_id,
              list.data.meeting_id,
            );
            this.cohort_id = list.data.cohort_id;
            this.db_meeting_id = list.data.id;
            await this.getAttendeeDetails(attendees, att);
            await this.getMessage(list.data.id, 0);
          }
          this.spinner.hide();
        });
      }
    });
  }
  async getMeeting(AttendeeId, MeetingId) {
    await this.service
      .getMeeting(AttendeeId, MeetingId)
      .then(async (result) => {
        var list = JSON.parse(<string>result[0].json);
        if (result[0].status == 200) {
          this.MeetingResponse = list.data;
          await this.start();
          this.service.ShowSuccess('Meeting has been join successfully');
        } else {
          this.service.ShowError('Meeting has been ended');
        }
      });
  }
  async getAttendeeDetails(attendees, att) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .getAllChannelUsers(attendees, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              let adminId = window.sessionStorage.getItem('b6h3a5d9r6e3s5h');
              var list = JSON.parse(<string>data[0].json);
              this.Attendees = list.data;
              this.Attendees.forEach((element) => {
                element['mute'] = false;
                element['is_play'] = true;
                element['activeUser'] = false;
                let atts = att.filter((x) => x.U == element.id);
                element['AttendeeId'] = atts[0].A;
                if (element.id == adminId) {
                  element['presenter'] = true;
                  element['activeUser'] = true;
                } else {
                  element['presenter'] = false;
                }
              });
              this.AttendeesCount = this.Attendees.filter(
                (x) => x.activeUser,
              ).length;
            }
          });
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
  async getMessage(meeting_id, limitRecord = 0) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .getMessage(meeting_id, limitRecord, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              list.data = list.data.reverse();
              this.TempMessages.push(list.data);
              this.ChattingDetails();
            }
          });
        this.spinner.hide();
      }
    });
  }
  public ChattingDetails() {
    let msg = [];
    for (const key in this.TempMessages) {
      this.TempMessages[key].forEach((element) => {
        msg.push(element);
      });
    }
    this.Messages = msg;
    let adminId = window.sessionStorage.getItem('b6h3a5d9r6e3s5h');
    this.Messages.forEach((el) => {
      if (el.user.id == adminId) {
        el['color'] = '#f050ac';
      } else {
        el['color'] = '#8069c1';
      }
      el['mydate'] = el.createdAt.split('T')[0];
      if (el.emoji_count == null || el.emoji_count == undefined) {
        el.emoji_count = [];
      } else {
        if (!isArray(el.emoji_count)) {
          el.emoji_count = JSON.parse(el.emoji_count);
        }
      }
    });
    let grouping = [];
    let grouped = this.groupByDate(this.Messages, 'mydate');
    for (const key in grouped) {
      grouped[key].forEach((element) => {
        grouping.push(element);
      });
    }
    this.Messages = grouping;
  }
  groupByDate(array, key) {
    return array.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  generateRandom() {
    var chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let ids = '';
    for (let id = 0; id < 16; id++) {
      ids += chars[Math.floor(Math.random() * chars.length)];
    }
    return ids;
  }
  async sendMessage() {
    if (this.message && this.message.trim()) {
      this.meetingSession.audioVideo.realtimeSendDataMessage(
        this.MeetingResponse.meeting.Meeting.MeetingId,
        this.message,
      );
      let ref_number = this.generateRandom();
      let user_id = sessionStorage.getItem('b6h3a5d9r6e3s5h');
      let email = localStorage.getItem('adminUser');
      let msg = {
        attendees_log: '',
        aws_meeting_id:
          this.Messages.length > 0 ? this.Messages[0].aws_meeting_id : '',
        cohort_id: null,
        color: '#f050ac',
        createdAt: new Date().toISOString(),
        emoji_count: [],
        id:
          this.Messages.length > 0
            ? this.Messages[this.Messages.length - 1].id + 1
            : 1,
        meeting_id: this.Messages.length > 0 ? this.Messages[0].meeting_id : '',
        message: this.message,
        message_type: 'text',
        mydate:
          new Date().getFullYear() +
          '-' +
          (new Date().getMonth() + 1) +
          '-' +
          new Date().getDate(),
        status: 0,
        updatedAt: new Date().toISOString(),
        user: {
          aliasname: null,
          avatar_url: null,
          email: email,
          id: user_id,
          name: email.split('@')[0],
        },
        user_id: user_id,
        ref_number: ref_number,
      };
      var x = [];
      x.push(msg);
      let already = this.Messages.filter((x) => x.id == msg.id);
      if (already.length <= 0) {
        this.TempMessages.push(x);
        this.ChattingDetails();
      }
      x = [];
      let mmssgg = this.message;
      this.message = '';
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.service
              .saveMessage(
                this.cohort_id,
                user_id,
                this.db_meeting_id,
                this.MeetingResponse.meeting.Meeting.MeetingId,
                'text',
                '',
                mmssgg,
                ref_number,
                token,
              )
              .then(async (data) => {});
            this.spinner.hide();
          }
        });
    } else {
    }
  }
  onPickedEmoji(event) {
    this.message += event.char;
  }
  async getSingleMessage(db_meeting_id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .getSingleMessage(db_meeting_id, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              list.data = list.data.reverse();
              for (let i = 0; i < list.data.length; i++) {
                let already = this.Messages.filter(
                  (x) => x.ref_number == list.data[i].ref_number,
                );
                if (already.length <= 0) {
                  let attendees_log = list.data[i].attendees_log
                    ? JSON.parse(list.data[i].attendees_log)
                    : [];
                  if (attendees_log.length > 0) {
                    let state = attendees_log[0].status;
                    let attid = attendees_log[0].AttendeeId;
                    if (state == 'Left') {
                      await this.meetingSession.audioVideo.removeVideoTilesByAttendeeId(
                        attid,
                      );
                      this.Attendees.forEach((element) => {
                        if (element.AttendeeId == attid) {
                          element.activeUser = false;
                        }
                      });

                      this.AttendeesCount = this.Attendees.filter(
                        (x) => x.activeUser,
                      ).length;
                    } else if (state == 'Play') {
                      this.Attendees.forEach((element) => {
                        if (element.AttendeeId == attid) {
                          element['is_play'] = true;
                          let video = document.getElementById(
                            'video-' + element.tile_id,
                          ) as HTMLVideoElement;
                          video.style.opacity = '1';
                          video.play();
                        }
                      });
                    } else if (state == 'Pause') {
                      this.Attendees.forEach((element) => {
                        if (element.AttendeeId == attid) {
                          element['is_play'] = false;
                          setTimeout(() => {
                            let video = document.getElementById(
                              'video-' + element.tile_id,
                            ) as HTMLVideoElement;
                            video.style.opacity = '0.3';
                            video.pause();
                          }, 500);
                        }
                      });
                    } else if (state == 'QuestionsAnswered') {
                    }
                  }
                  var x = [];
                  x.push(list.data[i]);
                  this.TempMessages.push(x);
                  x = [];
                  this.ChattingDetails();
                }
              }
            }
          });
        this.spinner.hide();
      }
    });
  }

  async start() {
    try {
      const logger = new ConsoleLogger('SDK', LogLevel.OFF);
      const configuration = new MeetingSessionConfiguration(
        this.MeetingResponse.meeting.Meeting,
        this.MeetingResponse.attendee.Attendee,
      );
      const deviceController = new DefaultDeviceController(logger);
      let audioVideoController = new DefaultAudioVideoController(
        configuration,
        logger,
        new DefaultWebSocketAdapter(logger),
        deviceController,
        new DefaultReconnectController(
          configuration.reconnectTimeoutMs,
          new FullJitterBackoff(
            configuration.reconnectFixedWaitMs,
            configuration.reconnectShortBackOffMs,
            configuration.reconnectLongBackOffMs,
          ),
        ),
      );
      deviceController.bindToAudioVideoController(audioVideoController);
      this.meetingSession = new DefaultMeetingSession(
        configuration,
        logger,
        deviceController,
      );
      this.changeAudioVideoSource();

      let me = this;
      const observer = {
        videoTileDidUpdate: (tileState) => {
          let attId = tileState.boundExternalUserId;
          me.Attendees.forEach((element) => {
            if (element.AttendeeId == attId) {
              element.activeUser = true;
            }
          });
          if (!tileState.boundAttendeeId) {
            return;
          }
          me.updateTiles(me.meetingSession);
        },
      };
      this.meetingSession.audioVideo.addObserver(observer);
      const audioOutputElement = document.getElementById(
        'meeting-audio',
      ) as HTMLAudioElement;
      this.meetingSession.audioVideo.bindAudioElement(audioOutputElement);
      this.meetingSession.audioVideo.start();
      this.meetingSession.audioVideo.startLocalVideoTile();
      if (this.IsAutoRecord) {
        this.startRecording();
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: 'Device Error!',
        text: err.message + ' Please reload the page or attach camera properly',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  }
  getScore() {}
  async openDevicePopup() {
    this.chooseDevicePopup = true;
  }
  async closeDevicePopup() {
    this.chooseDevicePopup = false;
    if (this.videoDevices.length > 0) {
      await this.meetingSession.audioVideo.chooseVideoInputDevice(
        this.videoDevices[0].deviceId,
      );
    }
    if (this.videoDevices.length > 0) {
      await this.meetingSession.audioVideo.chooseAudioInputDevice(
        this.audioDevices[0].deviceId,
      );
    }
    this.meetingSession.audioVideo.stopLocalVideoTile();
    this.meetingSession.audioVideo.startLocalVideoTile();
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(
      this.MeetingResponse.meeting.Meeting.MeetingId,
      (data) => {
        this.getSingleMessage(this.db_meeting_id);
      },
    );
  }
  async chooseDeviceClick() {
    this.chooseDevicePopup = false;

    if (this.selectedVideo) {
      await this.meetingSession.audioVideo.chooseVideoInputDevice(
        this.selectedVideo,
      );
    }
    if (this.selectedAudio) {
      await this.meetingSession.audioVideo.chooseAudioInputDevice(
        this.selectedAudio,
      );
    }
    this.meetingSession.audioVideo.stopLocalVideoTile();
    this.meetingSession.audioVideo.startLocalVideoTile();
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(
      this.MeetingResponse.meeting.Meeting.MeetingId,
      (data) => {
        this.getSingleMessage(this.db_meeting_id);
      },
    );
  }
  async changeAudioVideoSource() {
    var audioInputs = [];
    audioInputs = await this.meetingSession.audioVideo.listAudioInputDevices();
    this.audioDevices = [];
    if (audioInputs.length > 0) {
      for (const input of audioInputs) {
        this.audioDevices.push({
          deviceId: input.deviceId,
          label: input.label,
        });
      }
    }
    var videoInputs =
      await this.meetingSession.audioVideo.listVideoInputDevices();
    this.videoDevices = [];
    for (const input of videoInputs) {
      this.videoDevices.push({
        deviceId: input.deviceId,
        label: input.label,
      });
    }
    this.openDevicePopup();
  }

  async startScreenShare() {
    if (this.IsScreenShare) {
      this.IsScreenShare = false;
      this.meetingSession.audioVideo.stopContentShare();
      this.meetingSession.audioVideo.startLocalVideoTile();
    } else {
      this.IsScreenShare = true;
      await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
      this.meetingSession.audioVideo.stopLocalVideoTile();
    }
  }

  updateTiles(meetingSession) {
    const observer = {
      videoTileDidUpdate: (tileState) => {
        let attId = tileState.boundExternalUserId;
        let tileId = tileState.tileId;

        let videoElement = document.getElementById(
          'video-' + tileId,
        ) as HTMLVideoElement;
        if (tileState.videoPaused || !tileState.active) {
          if (videoElement) {
            // Remove the video element from the DOM
            let nameElement = document.getElementById('main-div-' + tileId);
            nameElement.remove();
            videoElement.remove();
          }
        } else {
          if (!videoElement) {
            // Create a new video element and bind the tile to it
            videoElement = document.createElement('video');
            videoElement.setAttribute('controls', 'true');
            videoElement.id = 'video-' + tileId;
            videoElement.style.width = '300px';
            videoElement.style.height = '150px';
            videoElement.style.border = 'solid 1px #8069c178';
            videoElement.style.borderRadius = '10px';
            videoElement.style.margin = '10px';
            document.getElementById('video-list').append(videoElement);
            meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
          } else {
            // Bind the tile to the existing video element
            meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
          }
        }
        this.Attendees.forEach((element) => {
          if (element.AttendeeId == attId) {
            element.activeUser = tileState.active;
          }
        });
        this.AttendeesCount = this.Attendees.filter((x) => x.activeUser).length;
      },
      videoTileWasRemoved: (tileId) => {
        let videoElement = document.getElementById(
          'video-' + tileId,
        ) as HTMLVideoElement;
        let nameElement = document.getElementById('main-div-' + tileId);
        if (videoElement) {
          // Remove the video element from the DOM
          videoElement.remove();
          nameElement.remove();
        }
      },
    };
    meetingSession.audioVideo.addObserver(observer);

    const tiles = meetingSession.audioVideo.getAllVideoTiles();
    tiles.forEach((tile) => {
      let tileId = tile.id();

      let videoElement = document.getElementById(
        'video-' + tileId,
      ) as HTMLVideoElement;
      if (!videoElement) {
        let state = tile.state();
        let attId = state.boundExternalUserId;
        if (state.boundAttendeeId == this.AdminAttendeeId) {
          this.AdminTileId = tileId;
        }
        videoElement = document.createElement('video');
        videoElement.setAttribute('controls', 'true');
        videoElement.id = 'video-' + tileId;

        videoElement.style.width = '300px';
        videoElement.style.height = '150px';
        videoElement.style.border = 'solid 1px #8069c178';
        videoElement.style.borderRadius = '10px';
        videoElement.style.margin = '10px';
        let div = document.getElementById('main-div-' + tileId);
        if (!div) {
          div = document.createElement('div');
          div.id = 'main-div-' + tileId;
          div.style.display = 'inline-block';
          div.style.position = 'relative';
        }

        div.appendChild(videoElement);
        document.getElementById('video-list').append(div);
        meetingSession.audioVideo.bindVideoElement(tileId, videoElement);

        this.Attendees.forEach((element) => {
          if (element.AttendeeId == attId) {
            element['tile_id'] = tileId;
            element.activeUser = true;
            let nameElement = document.getElementById('name-span' + tileId);
            if (!nameElement) {
              nameElement = document.createElement('span');
              nameElement.style.position = 'absolute';
              nameElement.id = 'name-span-' + tileId;
              nameElement.className =
                'name-span name-span-' +
                element.AttendeeId.toString().split('#')[0];
              nameElement.style.top = '15px';
              nameElement.style.left = '26px';
              nameElement.style.color = '#9645c4';
              nameElement.style.fontWeight = 'bold';
              nameElement.style.fontSize = '17px';
              nameElement.style.background = '#0000009c';
              nameElement.style.padding = '3px 10px';
              nameElement.style.borderRadius = '5px';
              nameElement.innerText = element.aliasname
                ? element.aliasname
                : element.name;
              div.appendChild(nameElement);
            }
          }
        });

        this.AttendeesCount = this.Attendees.filter((x) => x.activeUser).length;
      } else {
        // Bind the tile to the existing video element
        meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
      }
    });
  }

  muteAudio() {
    if (this.IsMuted) {
      this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      this.meetingSession.audioVideo.realtimeMuteLocalAudio();
    }
    this.IsMuted = !this.IsMuted;
  }
  startStopVideo() {
    if (this.IsStartVideo) {
      this.meetingSession.audioVideo.stopLocalVideoTile();
      this.IsStartVideo = false;
    } else {
      try {
        this.meetingSession.audioVideo.startLocalVideoTile();
        this.IsStartVideo = true;
      } catch (error) {
        console.log(error);
      }
    }
  }
  endMeeting() {
    Swal.fire({
      title: 'Leave Meeting?',
      text: 'Do you want to end the meeting session?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.value) {
        this.meetingSession.audioVideo.realtimeSendDataMessage(
          this.MeetingResponse.meeting.Meeting.MeetingId,
          'Ended',
        );
        this.spinner.show();
        let message = 'Meeting has been ended';
        let attendeeLog = [
          {
            user_id: 0,
            AttendeeId: '',
            status: 'End',
          },
        ];
        let ref_number = this.generateRandom();
        let user_id = sessionStorage.getItem('b6h3a5d9r6e3s5h');
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.deleteMeeting();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.service
                .saveMessage(
                  this.cohort_id,
                  user_id,
                  this.db_meeting_id,
                  this.MeetingResponse.meeting.Meeting.MeetingId,
                  'log',
                  JSON.stringify(attendeeLog),
                  message,
                  ref_number,
                  token,
                )
                .then(async (data) => {
                  if (data[0].status == 200) {
                    await this.service
                      .getBucketData(
                        this.MeetingResponse.meeting.Meeting.MeetingId,
                      )
                      .then(async (data) => {
                        if (data[0].status == 200) {
                          var buckertRes = JSON.parse(<string>data[0].json);
                          var play_list = buckertRes.data.Contents;
                          if (play_list && play_list.length > 0) {
                            const src = play_list.map((item) => {
                              const filename = item.Key.split('/').pop();
                              return { src: filename };
                            });
                            let ques = sessionStorage.getItem(
                              'QuestionsAnsweredArray',
                            );
                            if (ques && ques != null) {
                              this.QuestionsAnsweredArray = JSON.parse(ques);
                            } else {
                              this.QuestionsAnsweredArray = [];
                            }
                            var updateRecode = {
                              meeting_id:
                                this.MeetingResponse.meeting.Meeting.MeetingId,
                              play_list: JSON.stringify(src),
                              duration: play_list.length * 5,
                              certi_id: this.certi_id,
                              event_id: this.event_id,
                              title: this.MeetingName,
                              questions:
                                this.QuestionsAnsweredArray.length > 0
                                  ? JSON.stringify(this.QuestionsAnsweredArray)
                                  : null,
                            };
                            await this.liveservice
                              .add_recording(updateRecode, token)
                              .then(async (data) => {
                                if (data[0].status == 200) {
                                  this.UpdatestartAllLiveEvents(token);
                                } else {
                                  var list = JSON.parse(<string>data[0].json);
                                  this.service.ShowError(list.message);
                                  this.deleteMeeting();
                                }
                              });
                          } else {
                            this.UpdatestartAllLiveEvents(token);
                          }
                          this.spinner.hide();
                        } else {
                          var list = JSON.parse(<string>data[0].json);
                          this.spinner.hide();
                          this.service.ShowError(list.message);
                          this.deleteMeeting();
                        }
                      });
                  }
                });
            }
          });
      }
    });
  }

  async UpdatestartAllLiveEvents(token) {
    let all_update_meeting = {
      live_event_id: this.live_event.id,
      attendees: '',
      admin_attendee_id: '',
      meeting_id: '',
      is_live: 0,
      is_completed: 1,
      complete_date: new Date().toISOString(),
      startdate: this.auth.convertLocalTimeZoneUtcTimeZone(
        new Date().toDateString(),
        this.time_zone.nameValue,
        this.time_zone.timeValue,
      ),
    };
    await this.liveservice.UpdatestartAllLiveEvents(all_update_meeting, token);
    this.deleteMeeting();
  }
  async muteAttendy(item, isMute) {
    this.Attendees.forEach((element) => {
      if (element.id == item.id) {
        element.mute = isMute == 'mute' ? true : false;
      }
    });
    let user_id = window.sessionStorage.getItem('b6h3a5d9r6e3s5h');
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let name = item.aliasname
          ? item.aliasname
          : item.name
            ? item.name
            : item.email;
        let state = 'Mute';
        if (isMute == 'unmute') {
          state = 'Unmute';
        }
        let message = 'Presenter has been ' + state + ' ' + name;
        let attendeeLog = [
          {
            user_id: item.id,
            AttendeeId: item.AttendeeId,
            status: state,
          },
        ];
        let ref_number = this.generateRandom();
        this.meetingSession.audioVideo.realtimeSendDataMessage(
          this.MeetingResponse.meeting.Meeting.MeetingId,
          'Mute',
        );
        await this.service
          .saveMessage(
            this.cohort_id,
            user_id,
            this.db_meeting_id,
            this.MeetingResponse.meeting.Meeting.MeetingId,
            'log',
            JSON.stringify(attendeeLog),
            message,
            ref_number,
            token,
          )
          .then(async (data) => {
            if (data[0].status == 200) {
              this.message = '';
            }
          });
        this.spinner.hide();
      }
    });
  }
  convertSecondsToTime(seconds) {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
    var timeString =
      minutes.toString().padStart(2, '0') +
      ':' +
      remainingSeconds.toString().padStart(2, '0');
    return timeString;
  }
  async startRecording() {
    let data = {
      MeetingId: this.MeetingResponse.meeting.Meeting.MeetingId,
    };
    this.IsAutoRecord = true;
    this.spinner.show();
    await this.service.startRecording(data).then(async (data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        this.MediaCapturePipeline = list.data;
        setInterval(() => {
          this.totalSeconds++;
        }, 1000);
        this.service.ShowSuccess('Recording has been started');
      }
      this.spinner.hide();
    });
  }

  async stopRecording() {
    let data = {
      MediaPipelineId:
        this.MediaCapturePipeline.MediaCapturePipeline.MediaPipelineId,
    };
    this.IsAutoRecord = false;
    this.spinner.show();
    await this.service.stopRecording(data).then(async (data) => {
      if (data[0].status == 200) {
        this.service.ShowSuccess('Recording has been stopped');
      }
      this.spinner.hide();
    });
  }

  async deleteMeeting() {
    setTimeout(() => {
      this.spinner.show();
    }, 100);
    this.meetingSession.audioVideo.stop();
    this.meetingSession.audioVideo.chooseVideoInputDevice(null);
    this.meetingSession.audioVideo.stopLocalVideoTile();
    let user_id = sessionStorage.getItem('b6h3a5d9r6e3s5h');
    let message = 'Meeting has been ended';

    let attendeeLog = [
      {
        user_id: 0,
        AttendeeId: '',
        status: 'End',
      },
    ];
    this.meetingSession.audioVideo.realtimeSendDataMessage(
      this.MeetingResponse.meeting.Meeting.MeetingId,
      'End',
    );
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let ref_number = this.generateRandom();
        await this.service
          .saveMessage(
            this.cohort_id,
            user_id,
            this.db_meeting_id,
            this.MeetingResponse.meeting.Meeting.MeetingId,
            'log',
            JSON.stringify(attendeeLog),
            message,
            ref_number,
            token,
          )
          .then(async (data) => {
            if (data[0].status == 200) {
              this.message = '';
              await this.service
                .deleteChimeMeeting(
                  this.MeetingResponse.meeting.Meeting.MeetingId,
                )
                .then(async (data) => {
                  if (data[0].status == 200) {
                    setTimeout(async () => {
                      await this.service
                        .deleteMeetingChats(
                          this.db_meeting_id,
                          this.MeetingName,
                          token,
                        )
                        .then((data) => {
                          this.spinner.hide();
                          window.location.href = '/main/liveevent';
                        });
                    }, 3000);
                  } else {
                    var list = JSON.parse(<string>data[0].json);
                    this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
}
