import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { AdminService } from '../../services/admin/admin.service';
import { TemplateService } from '../../services/template/template.service';
import { CompanyService } from '../../services/company/company.service';
import { ClientService } from '../../services/client/client.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CourseService } from '../../services/course/course.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as AWS from 'aws-sdk/global';
import * as Chime from 'aws-sdk/clients/chime';
import * as momentZone from 'moment-timezone';
import {
  ConsoleLogger,
  DefaultMessagingSession,
  LogLevel,
  MessagingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import { LiveeventsService } from 'src/app/services/liveevents/liveevents.service';
@Component({
  selector: 'app-cohorts',
  templateUrl: './cohorts.component.html',
  styleUrls: ['./cohorts.component.scss'],
})
export class CohortsComponent implements OnInit {
  LiveEventPopup: boolean = false;
  NoLiveEventFound: boolean = false;
  PopLiveEvents = [];
  AdminAttendeeId: any;
  AdminMeetingId: any;
  displayedColumns: string[] = [
    'Srno',
    'cohort_name',
    'company',
    'template_name',
    'studentCount',
    'createdAt',
    'actions',
  ];
  displayedColumnsStudents: string[] = ['checkbox', 'name', 'email'];
  dataSource: MatTableDataSource<cohortlist>;
  dataSourceStudents: MatTableDataSource<StudentLists>;
  selection = new SelectionModel<StudentLists>(true, []);
  Search: any;
  addCohort: boolean = false;
  IsUpdate: boolean = false;
  Location: any;
  CohortName: any;
  Id: any;
  loglist = [];
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) studentpage: MatPaginator;
  Templates = [];
  Companies = [];
  Events = [];
  Domain = [];
  filterDomain = [];
  changeDomain = [];
  Certificates = [];
  SelectedCertificateName: any;
  StudyMaterialsSub = [];
  SelectedCompany: any;
  SelectedTemplate: any;
  KnowledgeAssessments = [];
  PracticeExams = [];
  CatExams = [];
  FilterKnowledgeAssessments = [];
  FilterPracticeExams = [];
  FilterCatExams = [];
  EmailTemplates = [];
  FilterEmailTemplates = [];
  totalUsers: number = 0;
  SearchStudent: any;
  Users: StudentLists[] = [];
  EditUsers: StudentLists[] = [];
  SelectedCertificate: any;
  StartDate: any;
  time_zone: any;
  VideoAccess: boolean = false;
  MindmapAccess: boolean = false;
  SummaryAccess: boolean = false;
  ReferenceAccess: boolean = false;
  KAAccess: boolean = false;
  PTAccess: boolean = false;
  CATAccess: boolean = false;
  LiveChatAccess: boolean = false;
  LiveQuestionAccess: boolean = false;
  LiveEventAccess: boolean = false;
  FashFullAccess: boolean = false;
  PraAppFullAccess: boolean = false;
  QuestionAccess: boolean = false;
  FlashcardAccess: boolean = false;
  SelectedEvents = [];
  LiveEvents = [];
  FilterLiveEvents = [];
  Videos = [];
  FilterVideos = [];
  Mindmap = [];
  FilterMindmap = [];
  baselineka = [];
  Filterbaselineka = [];
  SelectedBaselineKA: any;
  SelectedBaselineKAdueDate: any;
  SelectedBaselineKAdueTime: any;
  SelectedBaselineKAOverdueDate: any;
  SelectedBaselineKAOverdueTime: any;
  SelectedBaselineKAEndDate: any;
  SelectedBaselineKAEndTime: any;
  SelectedBaselineKAdueNumber: any;
  SelectedBaselineKAOverdueNumber: any;
  SelectedBaselineKAEndNumber: any;
  chime = new Chime({ region: 'us-east-1' });
  Hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
  SearchSelectedCompany = 0;
  oldAuditValueArray = {
    'Company name': '',
    Template: '',
    'Cohort Name': '',
    'Time Zone': '',
    Location: '',
    'Start Date': '',
    'Live Event': '',
    'Video Access': [{ type: 'checkbox', value: false }],
    'Mindmaps Access': [{ type: 'checkbox', value: false }],
    'Summary Access': [{ type: 'checkbox', value: false }],
    'Reference Access': [{ type: 'checkbox', value: false }],
    'KA Access': [{ type: 'checkbox', value: false }],
    'Practice Test Access': [{ type: 'checkbox', value: false }],
    'CAT Access': [{ type: 'checkbox', value: false }],
    'LiveChat Access': [{ type: 'checkbox', value: false }],
    'LiveQuestion Access': [{ type: 'checkbox', value: false }],
    'LiveEvent Access': [{ type: 'checkbox', value: false }],
    'FashcardFull Access': [{ type: 'checkbox', value: false }],
    'Practice question app full access': [{ type: 'checkbox', value: false }],
  };
  newAuditValueArray = {
    'Company name': '',
    Template: '',
    'Cohort Name': '',
    'Time Zone': '',
    Location: '',
    'Start Date': '',
    'Live Event': '',
    'Video Access': [{ type: 'checkbox', value: false }],
    'Mindmaps Access': [{ type: 'checkbox', value: false }],
    'Summary Access': [{ type: 'checkbox', value: false }],
    'Reference Access': [{ type: 'checkbox', value: false }],
    'KA Access': [{ type: 'checkbox', value: false }],
    'Practice Test Access': [{ type: 'checkbox', value: false }],
    'CAT Access': [{ type: 'checkbox', value: false }],
    'LiveChat Access': [{ type: 'checkbox', value: false }],
    'LiveQuestion Access': [{ type: 'checkbox', value: false }],
    'LiveEvent Access': [{ type: 'checkbox', value: false }],
    'FashcardFull Access': [{ type: 'checkbox', value: false }],
    'Practice question app full access': [{ type: 'checkbox', value: false }],
  };
  auditHistory = '';
  constructor(
    public adminService: AdminService,
    public cohortService: CohortService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public templateService: TemplateService,
    public companyService: CompanyService,
    public clientService: ClientService,
    public courseService: CourseService,
    public liveservice: LiveeventsService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewCohort() {
    this.addCohort = true;
  }
  closeModal() {
    this.SelectedCompany = '';
    this.addCohort = false;
    this.IsUpdate = false;
    this.CohortName = '';
    this.Location = '';
    this.SelectedCertificate = '';
    this.SelectedTemplate = '';
    this.FashFullAccess = false;
    this.PraAppFullAccess = false;
    this.Events = [];
    this.VideoAccess = false;
    this.MindmapAccess = false;
    this.SummaryAccess = false;
    this.ReferenceAccess = false;
    this.KAAccess = false;
    this.PTAccess = false;
    this.CATAccess = false;
    this.LiveChatAccess = false;
    this.LiveQuestionAccess = false;
    this.LiveEventAccess = false;
    this.QuestionAccess = false;
    this.FlashcardAccess = false;
    this.SelectedEvents = [];
    this.EditUsers = [];
    this.selection = new SelectionModel<StudentLists>(true, []);
    this.time_zone = '';
    this.StartDate = new Date().toDateString();
    this.LiveEvents = [];
    this.Videos.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.Mindmap.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.KnowledgeAssessments.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.PracticeExams.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.CatExams.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.SelectedBaselineKA = '';
    this.SelectedBaselineKAdueDate = '';
    this.SelectedBaselineKAdueTime = '';
    this.SelectedBaselineKAOverdueDate = '';
    this.SelectedBaselineKAOverdueTime = '';
    this.SelectedBaselineKAEndDate = '';
    this.SelectedBaselineKAEndTime = '';
    this.SelectedBaselineKAOverdueNumber = '';
    this.SelectedBaselineKAdueNumber = '';
    this.SelectedBaselineKAEndNumber = '';
    this.oldAuditValueArray = {
      'Company name': '',
      Template: '',
      'Cohort Name': '',
      'Time Zone': '',
      Location: '',
      'Start Date': '',
      'Live Event': '',
      'Video Access': [{ type: 'checkbox', value: false }],
      'Mindmaps Access': [{ type: 'checkbox', value: false }],
      'Summary Access': [{ type: 'checkbox', value: false }],
      'Reference Access': [{ type: 'checkbox', value: false }],
      'KA Access': [{ type: 'checkbox', value: false }],
      'Practice Test Access': [{ type: 'checkbox', value: false }],
      'CAT Access': [{ type: 'checkbox', value: false }],
      'LiveChat Access': [{ type: 'checkbox', value: false }],
      'LiveQuestion Access': [{ type: 'checkbox', value: false }],
      'LiveEvent Access': [{ type: 'checkbox', value: false }],
      'FashcardFull Access': [{ type: 'checkbox', value: false }],
      'Practice question app full access': [{ type: 'checkbox', value: false }],
    };
    this.newAuditValueArray = {
      'Company name': '',
      Template: '',
      'Cohort Name': '',
      'Time Zone': '',
      Location: '',
      'Start Date': '',
      'Live Event': '',
      'Video Access': [{ type: 'checkbox', value: false }],
      'Mindmaps Access': [{ type: 'checkbox', value: false }],
      'Summary Access': [{ type: 'checkbox', value: false }],
      'Reference Access': [{ type: 'checkbox', value: false }],
      'KA Access': [{ type: 'checkbox', value: false }],
      'Practice Test Access': [{ type: 'checkbox', value: false }],
      'CAT Access': [{ type: 'checkbox', value: false }],
      'LiveChat Access': [{ type: 'checkbox', value: false }],
      'LiveQuestion Access': [{ type: 'checkbox', value: false }],
      'LiveEvent Access': [{ type: 'checkbox', value: false }],
      'FashcardFull Access': [{ type: 'checkbox', value: false }],
      'Practice question app full access': [{ type: 'checkbox', value: false }],
    };
    this.auditHistory = '';
    this.pagination = false;
  }
  closeLiveEventPopup() {
    this.LiveEventPopup = false;
    this.NoLiveEventFound = false;
  }
  async openLiveEventPopup(item) {
    this.LiveEventPopup = true;
    if (item.live_events[0] > 0) {
      let ids = item.live_events.toString();
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.cohortService
            .liveEventByIds(ids, token)
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.PopLiveEvents = list.data[0];
                this.spinner.hide();
              }
            });
          this.spinner.hide();
        }
      });
    } else {
      this.NoLiveEventFound = true;
    }
  }
  gotoCohortReport(row) {
    if (row.studentCount <= 0) {
      Swal.fire('', 'No report available');
    } else {
      let data = {
        cohort_id: row.id,
        cohort_name: row.cohort_name,
      };
      let post = btoa(JSON.stringify(data));
      this.router.navigate([`cohortreports/${post}`]);
    }
  }
  async ngOnInit() {
    await this.spinner.show();
    await this.listCohort();
    this.time_zone = this.auth.formatZone(momentZone.tz.guess(true));
    await this.getAllCourseTemplate();
    await this.getAllLiveEvents();
    await this.getAllVideoLesson();
    await this.GetAllCompany();
    await this.getAllExams();
    await this.getAllUsers(
      1,
      this.studentpage ? this.studentpage.pageSize : 10,
      '',
    );
    try {
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          this.service.token = token;
          await this.GetAllDropdown(token);
          await this.spinner.hide();
        }
      });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
    await this.spinner.hide();
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.cohortService
              .DeleteCohort(item.id, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          item.cohort_name +
                          '</span> Live class is deleted by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'cohorts',
                          item.id,
                          token,
                        );
                      }
                    });
                  await this.listCohort();
                  await this.spinner.hide();
                  Swal.fire(
                    list.message,
                    'Live class has been deleted.',
                    'success',
                  );
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      }
    });
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('cohorts', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.IsUpdate = true;
    this.addCohort = true;
    this.Location = item.location;
    this.CohortName = item.cohort_name;
    this.SelectedTemplate = item.template_id;
    this.SelectedCertificate = item.certi_id;
    this.time_zone = item.time_zone ? JSON.parse(item.time_zone).nameValue : '';
    this.VideoAccess = item.video_access;
    this.MindmapAccess = item.mindmap_access;
    this.SummaryAccess = item.summary_access;
    this.ReferenceAccess = item.reference_access;
    this.KAAccess = item.KA_access;
    this.PTAccess = item.PT_access;
    this.CATAccess = item.CAT_access;
    this.LiveChatAccess = item.livechat_access;
    this.LiveQuestionAccess = item.liveq_access;
    this.LiveEventAccess = item.liveevent_access;
    this.PraAppFullAccess = item.question_access;
    this.FashFullAccess = item.flashcard_access;
    this.StartDate = new Date(item.start_date);
    this.SelectedBaselineKA = item.baseline_ka == null ? '' : item.baseline_ka;
    if (item.baseline_ka_schedule) {
      this.SelectedBaselineKAdueDate =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).DueDate
          : '';
      this.SelectedBaselineKAdueTime =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).DueTime
          : '';
      this.SelectedBaselineKAOverdueDate =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).OverDueDate
          : '';
      this.SelectedBaselineKAOverdueTime =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).OverDueTime
          : '';
      this.SelectedBaselineKAOverdueNumber =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).OverDueNumber
          : '';
      this.SelectedBaselineKAdueTime =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).DueTime
          : '';
      this.SelectedBaselineKAEndDate =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).EndDate
          : '';
      this.SelectedBaselineKAEndTime =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).EndTime
          : '';
      this.SelectedBaselineKAEndNumber =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).EndNumber
          : '';
      this.SelectedBaselineKAdueNumber =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).DueNumber
          : '';
    }
    if (item.company_id) {
      this.SelectedCompany = item.company_id;
    } else {
      this.SelectedCompany = '';
    }
    this.Id = item.id;
    if (
      item.live_events != null &&
      item.live_events != '' &&
      item.live_events != '0'
    ) {
      this.SelectedEvents = item.live_events.map(Number);
    }
    if (item.events != null) {
      try {
        this.Events = JSON.parse(item.events);
        this.Events.forEach((element) => {
          element.event_date = this.auth
            .ConvertFormatDbDateTime(element.event_date, this.time_zone)
            .split('T')[0];
        });
      } catch (error) {
        this.Events = [];
      }
    } else this.Events = [];
    this.LiveEvents = this.FilterLiveEvents.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.Videos = this.FilterVideos.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.Mindmap = this.FilterMindmap.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.KnowledgeAssessments = this.FilterKnowledgeAssessments.filter(
      (data) => {
        return (
          data.certi_id
            .toString()
            .toLowerCase()
            .indexOf(item.certi_id.toString().toLowerCase()) > -1
        );
      },
    );
    this.CatExams = this.FilterCatExams.filter((data) => {
      return (
        data.certi_id
          .toString()
          .toLowerCase()
          .indexOf(item.certi_id.toString().toLowerCase()) > -1
      );
    });
    this.PracticeExams = this.FilterPracticeExams.filter((data) => {
      return (
        data.certi_id
          .toString()
          .toLowerCase()
          .indexOf(item.certi_id.toString().toLowerCase()) > -1
      );
    });
    this.EmailTemplates = this.FilterEmailTemplates.filter((data) => {
      return (
        data.certi_id
          .toString()
          .toLowerCase()
          .indexOf(item.certi_id.toString().toLowerCase()) > -1
      );
    });
    this.baselineka = this.Filterbaselineka.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.Videos.forEach((ka) => {
      item.videos.forEach((element) => {
        if (ka.id == element.id && element.cohort_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.Mindmap.forEach((ka) => {
      item.mindmaps.forEach((element) => {
        if (ka.id == element.id && element.cohort_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.KnowledgeAssessments.forEach((ka) => {
      item.knowassessments.forEach((element) => {
        if (ka.id == element.id && element.cohort_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.PracticeExams.forEach((ka) => {
      item.practicetests.forEach((element) => {
        if (ka.id == element.id && element.cohort_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.CatExams.forEach((ka) => {
      item.ketexamtests.forEach((element) => {
        if (ka.id == element.id && element.cohort_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.EmailTemplates.forEach((ka) => {
      item.email_templates.forEach((element) => {
        if (ka.id == element.id && element.cohort_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.EditUsers = [];
    this.EditUsers = item.user;
    await item.user.forEach((element) => {
      this.Users.forEach((main) => {
        if (element.id == main.id) {
          main.checked = true;
        }
      });
    });
    this.selection = new SelectionModel<StudentLists>(true, []);
    item.user.forEach((element) => {
      this.selection.select(element);
    });
    this.oldAuditValueArray = {
      'Company name': this.Companies.find((x) => x.id == this.SelectedCompany)
        ? this.Companies.find((x) => x.id == this.SelectedCompany).name
        : '',
      Template: this.Templates.find((x) => x.id == this.SelectedTemplate)
        ? this.Templates.find((x) => x.id == this.SelectedTemplate).templateName
        : '',
      'Cohort Name': this.CohortName,
      'Time Zone': this.time_zone,
      Location: this.Location,
      'Start Date': new Date(this.StartDate).toDateString(),
      'Live Event': this.FilterLiveEvents.filter((item) => {
        return this.SelectedEvents.includes(item.id);
      })
        .map(function (elem) {
          return elem.event_name;
        })
        .join(','),
      'Video Access': [{ type: 'checkbox', value: this.VideoAccess }],
      'Mindmaps Access': [{ type: 'checkbox', value: this.MindmapAccess }],
      'Summary Access': [{ type: 'checkbox', value: this.SummaryAccess }],
      'Reference Access': [{ type: 'checkbox', value: this.ReferenceAccess }],
      'KA Access': [{ type: 'checkbox', value: this.KAAccess }],
      'Practice Test Access': [{ type: 'checkbox', value: this.PTAccess }],
      'CAT Access': [{ type: 'checkbox', value: this.CATAccess }],
      'LiveChat Access': [{ type: 'checkbox', value: this.LiveChatAccess }],
      'LiveQuestion Access': [
        { type: 'checkbox', value: this.LiveQuestionAccess },
      ],
      'LiveEvent Access': [{ type: 'checkbox', value: this.LiveEventAccess }],
      'FashcardFull Access': [{ type: 'checkbox', value: this.FashFullAccess }],
      'Practice question app full access': [
        { type: 'checkbox', value: this.PraAppFullAccess },
      ],
    };
  }
  async listCohort() {
    const users: cohortlist[] = [];
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.cohortService.ListCohort(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              if (element.course_type == null || element.course_type == '') {
                srno = srno + 1;
                this.time_zone =
                  element.time_zone != ''
                    ? JSON.parse(element.time_zone).nameValue
                    : this.auth.formatZone(momentZone.tz.guess(true)).nameValue;
                if (element.videos.length > 0) {
                  for (let i = 0; i < element.videos.length; i++) {
                    if (element.video_schedule.length > 0) {
                      element.videos[i].Assigned = true;
                      element.videos[i].cohort_id = element.id;
                      element.video_schedule.forEach((schedule) => {
                        if (
                          schedule.type_id == element.videos[i].id &&
                          schedule.cohort_id == element.id
                        ) {
                          element.videos[i].Due = schedule.due_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.due_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.videos[i].OverDue = schedule.overdue_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.overdue_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.videos[i].EndDue = schedule.end_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.end_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.videos[i].DueTime = schedule.due_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.due_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.videos[i].OverDueTime = schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.videos[i].EndTime = schedule.end_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.end_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.videos[i].Required = schedule.required;
                          element.videos[i].Homework = schedule.homework;
                          element.videos[i].DueNumber = schedule.due_number;
                          element.videos[i].OverDueNumber =
                            schedule.overdue_number;
                          element.videos[i].EndNumber = schedule.end_number;
                        }
                      });
                    }
                  }
                }
                if (element.mindmaps.length > 0) {
                  for (let i = 0; i < element.mindmaps.length; i++) {
                    if (element.mindmap_schedule.length > 0) {
                      element.mindmaps[i].Assigned = true;
                      element.mindmaps[i].cohort_id = element.id;
                      element.mindmap_schedule.forEach((schedule) => {
                        if (
                          schedule.type_id == element.mindmaps[i].id &&
                          schedule.cohort_id == element.id
                        ) {
                          element.mindmaps[i].Due = schedule.due_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.due_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.mindmaps[i].OverDue = schedule.overdue_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.overdue_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.mindmaps[i].EndDue = schedule.end_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.end_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.mindmaps[i].DueTime = schedule.due_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.due_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.mindmaps[i].OverDueTime =
                            schedule.overdue_date
                              ? this.auth.ConvertDateDDFormat(
                                  schedule.overdue_date,
                                  this.time_zone,
                                  'HH:mm:ss',
                                )
                              : '';
                          element.mindmaps[i].EndTime = schedule.end_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.end_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.mindmaps[i].Required = schedule.required;
                          element.mindmaps[i].Homework = schedule.homework;
                          element.mindmaps[i].DueNumber = schedule.due_number;
                          element.mindmaps[i].OverDueNumber =
                            schedule.overdue_number;
                          element.mindmaps[i].EndNumber = schedule.end_number;
                        }
                      });
                    }
                  }
                }
                if (element.knowledge_asses.length > 0) {
                  for (let i = 0; i < element.knowledge_asses.length; i++) {
                    if (element.knowledge_schedule.length > 0) {
                      element.knowledge_asses[i].Assigned = true;
                      element.knowledge_asses[i].cohort_id = element.id;
                      element.knowledge_schedule.forEach((schedule) => {
                        if (
                          schedule.type_id == element.knowledge_asses[i].id &&
                          schedule.cohort_id == element.id
                        ) {
                          element.knowledge_asses[i].Due = schedule.due_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.due_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.knowledge_asses[i].OverDue =
                            schedule.overdue_date
                              ? this.auth
                                  .ConvertFormatDbDateTime(
                                    schedule.overdue_date,
                                    this.time_zone,
                                  )
                                  .split('T')[0]
                              : '';
                          element.knowledge_asses[i].EndDue = schedule.end_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.end_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.knowledge_asses[i].DueTime = schedule.due_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.due_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.knowledge_asses[i].OverDueTime =
                            schedule.overdue_date
                              ? this.auth.ConvertDateDDFormat(
                                  schedule.overdue_date,
                                  this.time_zone,
                                  'HH:mm:ss',
                                )
                              : '';
                          element.knowledge_asses[i].EndTime = schedule.end_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.end_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.knowledge_asses[i].Required =
                            schedule.required;
                          element.knowledge_asses[i].Homework =
                            schedule.homework;
                          element.knowledge_asses[i].DueNumber =
                            schedule.due_number;
                          element.knowledge_asses[i].OverDueNumber =
                            schedule.overdue_number;
                          element.knowledge_asses[i].EndNumber =
                            schedule.end_number;
                        }
                      });
                    }
                  }
                }
                if (element.practicetests.length > 0) {
                  for (let i = 0; i < element.practicetests.length; i++) {
                    if (element.practice_schedule.length > 0) {
                      element.practicetests[i].Assigned = true;
                      element.practicetests[i].cohort_id = element.id;
                      element.practice_schedule.forEach((schedule) => {
                        if (
                          schedule.type_id == element.practicetests[i].id &&
                          schedule.cohort_id == element.id
                        ) {
                          element.practicetests[i].Required = schedule.required;
                          element.practicetests[i].Homework = schedule.homework;
                          element.practicetests[i].Due = schedule.due_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.due_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.practicetests[i].OverDue =
                            schedule.overdue_date
                              ? this.auth
                                  .ConvertFormatDbDateTime(
                                    schedule.overdue_date,
                                    this.time_zone,
                                  )
                                  .split('T')[0]
                              : '';
                          element.practicetests[i].EndDue = schedule.end_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.end_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.practicetests[i].DueTime = schedule.due_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.due_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.practicetests[i].OverDueTime =
                            schedule.overdue_date
                              ? this.auth.ConvertDateDDFormat(
                                  schedule.overdue_date,
                                  this.time_zone,
                                  'HH:mm:ss',
                                )
                              : '';
                          element.practicetests[i].EndTime = schedule.end_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.end_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.practicetests[i].DueNumber =
                            schedule.due_number;
                          element.practicetests[i].OverDueNumber =
                            schedule.overdue_number;
                          element.practicetests[i].EndNumber =
                            schedule.end_number;
                        }
                      });
                    }
                  }
                }
                if (element.ketexamtests.length > 0) {
                  for (let i = 0; i < element.ketexamtests.length; i++) {
                    if (element.cat_schedule.length > 0) {
                      element.ketexamtests[i].Assigned = true;
                      element.ketexamtests[i].cohort_id = element.id;
                      element.cat_schedule.forEach((schedule) => {
                        if (
                          schedule.type_id == element.ketexamtests[i].id &&
                          schedule.cohort_id == element.id
                        ) {
                          element.ketexamtests[i].Required = schedule.required;
                          element.ketexamtests[i].Homework = schedule.homework;
                          element.ketexamtests[i].Due = schedule.due_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.due_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.ketexamtests[i].OverDue =
                            schedule.overdue_date
                              ? this.auth
                                  .ConvertFormatDbDateTime(
                                    schedule.overdue_date,
                                    this.time_zone,
                                  )
                                  .split('T')[0]
                              : '';
                          element.ketexamtests[i].EndDue = schedule.end_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.end_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.ketexamtests[i].DueTime = schedule.due_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.due_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.ketexamtests[i].OverDueTime =
                            schedule.overdue_date
                              ? this.auth.ConvertDateDDFormat(
                                  schedule.overdue_date,
                                  this.time_zone,
                                  'HH:mm:ss',
                                )
                              : '';
                          element.ketexamtests[i].EndTime = schedule.end_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.end_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.ketexamtests[i].DueNumber =
                            schedule.due_number;
                          element.ketexamtests[i].OverDueNumber =
                            schedule.overdue_number;
                          element.ketexamtests[i].EndNumber =
                            schedule.end_number;
                        }
                      });
                    }
                  }
                }
                if (element.email_templates.length > 0) {
                  for (let i = 0; i < element.email_templates.length; i++) {
                    if (element.email_schedule.length > 0) {
                      element.email_templates[i].Assigned = true;
                      element.email_templates[i].cohort_id = element.id;
                      element.email_schedule.forEach((schedule) => {
                        if (
                          schedule.type_id == element.email_templates[i].id &&
                          schedule.cohort_id == element.id
                        ) {
                          element.email_templates[i].Required =
                            schedule.required;
                          element.email_templates[i].Due = schedule.due_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.due_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.email_templates[i].OverDue =
                            schedule.overdue_date
                              ? this.auth
                                  .ConvertFormatDbDateTime(
                                    schedule.overdue_date,
                                    this.time_zone,
                                  )
                                  .split('T')[0]
                              : '';
                          element.email_templates[i].EndDue = schedule.end_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.end_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                          element.email_templates[i].DueTime = schedule.due_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.due_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.email_templates[i].OverDueTime =
                            schedule.overdue_date
                              ? this.auth.ConvertDateDDFormat(
                                  schedule.overdue_date,
                                  this.time_zone,
                                  'HH:mm:ss',
                                )
                              : '';
                          element.email_templates[i].EndTime = schedule.end_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.end_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                          element.email_templates[i].DueNumber =
                            schedule.due_number;
                          element.email_templates[i].OverDueNumber =
                            schedule.overdue_number;
                          element.email_templates[i].EndNumber =
                            schedule.end_number;
                        }
                      });
                    }
                  }
                }
                let company_name = '-';
                if (element.company.length > 0) {
                  company_name = element.company[0].name;
                }
                await users.push({
                  Srno: srno,
                  id: element.id,
                  certi_id: element.certi_id,
                  cohort_name: element.cohort_name,
                  cource_id: element.cource_id,
                  createdAt: element.createdAt,
                  live_events: element.live_events,
                  videos: element.videos,
                  mindmaps: element.mindmaps,
                  knowassessments: element.knowledge_asses,
                  practicetests: element.practicetests,
                  ketexamtests: element.ketexamtests,
                  updatedAt: element.updatedAt,
                  practice_name:
                    element.practicetests.length > 0
                      ? element.practicetests[0].practice_name
                      : '',
                  user: element.user,
                  location: element.location,
                  IsLoading: false,
                  IsLoad: false,
                  studentCount: element.user.length,
                  company: company_name,
                  company_id: element.company_id,
                  template_id: element.template_id,
                  template_name:
                    element.templates.length > 0
                      ? element.templates[0].name
                      : '',
                  events: element.events,
                  email_templates: element.email_templates,
                  time_zone: element.time_zone,
                  video_access: element.video_access,
                  mindmap_access: element.mindmap_access,
                  summary_access: element.summary_access,
                  reference_access: element.reference_access,
                  KA_access: element.KA_access,
                  PT_access: element.PT_access,
                  CAT_access: element.CAT_access,
                  livechat_access: element.livechat_access,
                  liveq_access: element.liveq_access,
                  liveevent_access: element.liveevent_access,
                  start_date: this.auth.convertStringUtcTimeZone(
                    element.start_date,
                    this.time_zone,
                  ),
                  question_access: element.question_access,
                  flashcard_access: element.flashcard_access,
                  baseline_ka: element.baseline_ka,
                  baseline_ka_schedule: element.baseline_ka_schedule,
                });
              }
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
            this.time_zone = '';
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async filterStudent() {
    await this.getAllUsers(
      1,
      this.studentpage ? this.studentpage.pageSize : 10,
      this.SearchStudent,
    );
    this.dataSourceStudents.filter = this.SearchStudent;
  }
  changeCheckbox(row, event: MatCheckboxChange) {
    setTimeout(() => {
      if (event.checked == false) {
        this.selection = new SelectionModel<StudentLists>(true, []);
        var index = -2;
        this.EditUsers.forEach((element) => {
          if (element.id == row.id) {
            index = this.EditUsers.indexOf(element);
          } else {
            this.selection.select(element);
          }
        });
        this.EditUsers.splice(index, 1);
      }
    }, 500);
  }
  async SaveCohort() {
    if (
      this.SelectedCompany == '' ||
      this.SelectedCompany == null ||
      this.SelectedCompany == undefined
    ) {
      this.service.ShowError('Select company');
    } else if (
      this.SelectedTemplate == '' ||
      this.SelectedTemplate == null ||
      this.SelectedTemplate == undefined
    ) {
      this.service.ShowError('Select course template');
    } else if (
      this.CohortName == '' ||
      this.CohortName == null ||
      this.CohortName == undefined
    ) {
      this.service.ShowError('Enter Live class name');
    } else {
      let videotemp = '';
      let mindmaptemp = '';
      let email_templates = '';
      let knowassessments = '';
      let practicetests = '';
      let ketexamtests = '';
      let cohort_name = this.CohortName;
      let certi_id = this.SelectedCertificate;
      let company_id = this.SelectedCompany;
      let userslist = '';
      let schedule = [];
      let time_zone = JSON.stringify(this.time_zone);
      for (let i = 0; i < this.selection.selected.length; i++) {
        userslist += this.selection.selected[i].id + ',';
      }
      if (userslist.endsWith(',') == true) {
        userslist = userslist.substring(0, userslist.length - 1);
      }
      this.Videos.forEach((ka) => {
        if (ka.Assigned) {
          videotemp += ka.id + ',';
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'video',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.video_title,
          });
        }
      });
      if (videotemp.endsWith(',') == true) {
        videotemp = videotemp.substring(0, videotemp.length - 1);
      }
      this.Mindmap.forEach((ka) => {
        if (ka.Assigned) {
          mindmaptemp += ka.id + ',';
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'mindmap',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.name,
          });
        }
      });
      if (mindmaptemp.endsWith(',') == true) {
        mindmaptemp = mindmaptemp.substring(0, mindmaptemp.length - 1);
      }
      //Knowledge Assessments----------------------------------
      this.KnowledgeAssessments.forEach((ka) => {
        if (ka.Assigned) {
          knowassessments += ka.id + ',';
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'KA',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.knowass_name,
          });
        }
      });
      if (knowassessments.endsWith(',') == true) {
        knowassessments = knowassessments.substring(
          0,
          knowassessments.length - 1,
        );
      }
      //Practice Exams----------------------------------
      this.PracticeExams.forEach((ka) => {
        if (ka.Assigned) {
          practicetests += ka.id + ',';
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'PRACTICE',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.practice_name,
          });
        }
      });
      if (practicetests.endsWith(',') == true) {
        practicetests = practicetests.substring(0, practicetests.length - 1);
      }
      //CAT Exams----------------------------------
      this.CatExams.forEach((ka) => {
        if (ka.Assigned) {
          ketexamtests += ka.id + ',';
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'CAT',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.ketexam_name,
          });
        }
      });
      if (ketexamtests.endsWith(',') == true) {
        ketexamtests = ketexamtests.substring(0, ketexamtests.length - 1);
      }
      //Email Templates----------------------------------
      this.EmailTemplates.forEach((ka) => {
        if (ka.Assigned) {
          email_templates += ka.id + ',';
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            type: 'EMAIL',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
          });
        }
      });
      if (email_templates.endsWith(',') == true) {
        email_templates = email_templates.substring(
          0,
          email_templates.length - 1,
        );
      }
      let flashcard_app = this.FashFullAccess;
      let practice_que_app = this.PraAppFullAccess;
      var access = {
        VideoAccess: this.VideoAccess,
        MindmapAccess: this.MindmapAccess,
        SummaryAccess: this.SummaryAccess,
        ReferenceAccess: this.ReferenceAccess,
        KAAccess: this.KAAccess,
        PTAccess: this.PTAccess,
        CATAccess: this.CATAccess,
        LiveChatAccess: this.LiveChatAccess,
        LiveQuestionAccess: this.LiveQuestionAccess,
        LiveEventAccess: this.LiveEventAccess,
        FlashcardAccess: this.FashFullAccess,
        QuestionAccess: this.PraAppFullAccess,
      };
      let events = '';
      if (this.Events.length > 0) {
        this.Events.forEach((element) => {
          element.event_date = this.auth.convertLocalTimeZoneUtcTimeZone(
            element.event_date,
            this.time_zone.nameValue,
            this.time_zone.timeValue,
          );
        });
        events = JSON.stringify(this.Events);
      }
      var baseline_ka_schedule = {
        baselineka: this.SelectedBaselineKA,
        DueDate: this.SelectedBaselineKAdueDate,
        DueTime: this.SelectedBaselineKAdueTime,
        OverDueDate: this.SelectedBaselineKAOverdueDate,
        OverDueTime: this.SelectedBaselineKAOverdueTime,
        EndDate: this.SelectedBaselineKAEndDate,
        EndTime: this.SelectedBaselineKAEndTime,
        DueNumber: this.SelectedBaselineKAdueNumber,
        OverDueNumber: this.SelectedBaselineKAOverdueNumber,
        EndNumber: this.SelectedBaselineKAEndNumber,
      };
      if (
        this.SelectedBaselineKA != '' &&
        this.SelectedBaselineKAdueDate != '' &&
        this.SelectedBaselineKAdueTime != '' &&
        this.SelectedBaselineKAOverdueDate != '' &&
        this.SelectedBaselineKAOverdueTime != ''
      ) {
        schedule.push({
          due_date: this.newDueDateCalculate(
            this.SelectedBaselineKAdueTime,
            this.SelectedBaselineKAdueDate,
          ),
          overdue_date: this.newDueDateCalculate(
            this.SelectedBaselineKAOverdueTime,
            this.SelectedBaselineKAOverdueDate,
          ),
          end_date: this.newDueDateCalculate(
            this.SelectedBaselineKAEndTime,
            this.SelectedBaselineKAEndDate,
          ),
          required: false,
          homework: false,
          type: 'KA',
          type_id: this.SelectedBaselineKA,
          cohort_id: '',
          due_number: this.SelectedBaselineKAdueNumber,
          overdue_number: this.SelectedBaselineKAOverdueNumber,
          end_number: this.SelectedBaselineKAEndNumber,
          name:
            this.Filterbaselineka.filter((x) => x.id == this.SelectedBaselineKA)
              .length > 0
              ? this.Filterbaselineka.filter(
                  (x) => x.id == this.SelectedBaselineKA,
                )[0].knowass_name
              : '',
        });
      }
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.cohortService
            .AddCohort(
              cohort_name,
              certi_id,
              1,
              knowassessments,
              practicetests,
              ketexamtests,
              flashcard_app,
              practice_que_app,
              events,
              userslist,
              this.Location,
              company_id,
              schedule,
              email_templates,
              time_zone,
              this.SelectedTemplate,
              access,
              this.auth.convertStringUtcTimeZone(
                this.StartDate,
                this.time_zone.nameValue,
              ),
              0,
              0,
              '',
              token,
              this.SelectedEvents.toString(),
              videotemp,
              mindmaptemp,
              this.SelectedBaselineKA,
              JSON.stringify(baseline_ka_schedule),
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.CohortName +
                        '</span> Live class is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'cohorts',
                        list.data.id,
                        token,
                      );
                      if (
                        userslist != null &&
                        userslist != undefined &&
                        userslist != ''
                      ) {
                        await this.addNotification(schedule, list.data.id);
                      }
                      if (this.SelectedEvents.length > 0) {
                        if (
                          userslist != null &&
                          userslist != undefined &&
                          userslist != ''
                        )
                          await this.addNotificationLiveEvent(
                            userslist,
                            list.data.id,
                          );
                      }
                    }
                  });
                this.addCohort = false;
                this.IsUpdate = false;
                this.closeModal();
                await this.listCohort();
                await this.getAllUsers(
                  1,
                  this.studentpage ? this.studentpage.pageSize : 10,
                  '',
                );
                await this.spinner.hide();
                this.service.ShowSuccess('Live class add Successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async addNotification(schedule, cohort_id) {
    let noti = [];
    for (let s = 0; s < this.selection.selected.length; s++) {
      for (let n = 0; n < schedule.length; n++) {
        if (schedule[n].type != 'EMAIL') {
          let exm = '';
          if (schedule[n].type == 'KA') {
            exm = 'Knowledge Assessment';
          } else if (schedule[n].type == 'PRACTICE') {
            exm = 'Practice Test';
          } else if (schedule[n].type == 'CAT') {
            exm = 'CAT Exam';
          } else if (schedule[n].type == 'video') {
            exm = 'Video';
          } else if (schedule[n].type == 'mindmap') {
            exm = 'Mind Map';
          }
          let msg = schedule[n].name + ' ' + exm + ' has been started from ';
          let date = new Date(
            this.auth.convertAnyTimeZoneUtcTimeZone(
              schedule[n].due_date,
              this.time_zone.nameValue,
            ),
          );
          let fire_time = this.auth.convertStringUtcTimeZone(
            this.auth.ConvertFormatDbDateTime(
              date.setHours(date.getHours() - 24),
              this.time_zone.nameValue,
            ),
            this.time_zone.nameValue,
          );
          noti.push({
            user_id: this.selection.selected[s].id,
            cohort_id: cohort_id,
            message: msg,
            noti_type: 'start_exam',
            test_type: schedule[n].type,
            test_id: schedule[n].type_id,
            is_read: 0,
            fire_time: fire_time,
            meeting_date: this.auth.convertStringUtcTimeZone(
              schedule[n].due_date,
              this.time_zone.nameValue,
            ),
          });
          if (schedule[n].overdue_date) {
            let date = new Date(
              this.auth.convertAnyTimeZoneUtcTimeZone(
                schedule[n].overdue_date,
                this.time_zone.nameValue,
              ),
            );
            let fire_time = this.auth.convertStringUtcTimeZone(
              this.auth.ConvertFormatDbDateTime(
                date.setHours(date.getHours() - 24),
                this.time_zone.nameValue,
              ),
              this.time_zone.nameValue,
            );
            msg = schedule[n].name + ' ' + exm + ' has been overdue on ';
            noti.push({
              user_id: this.selection.selected[s].id,
              cohort_id: cohort_id,
              message: msg,
              noti_type: 'overdue',
              test_type: schedule[n].type,
              test_id: schedule[n].type_id,
              is_read: 0,
              fire_time: fire_time,
              meeting_date: this.auth.convertStringUtcTimeZone(
                schedule[n].overdue_date,
                this.time_zone.nameValue,
              ),
            });
          }
        }
      }
    }
    if (noti.length > 0) {
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.service.addNotification(noti, token);
        }
      });
    }
  }
  async sendChannelMessage() {
    const logger = new ConsoleLogger('SDK', LogLevel.OFF);
    let AppInstanceUserArn =
      'arn:aws:chime:us-east-1:462849534434:app-instance/f2c11c62-6879-4d3a-92bc-08cc3f3ded63/user/f2c11c62-6879-4d3a-92bc-08cc3f3ded63';
    const endpoint = 'node001.ue1.ws-messaging.chime.aws';
    const sessionId = '1234657980';
    const configuration = new MessagingSessionConfiguration(
      AppInstanceUserArn,
      sessionId,
      endpoint,
      this.chime,
      AWS,
    );
    const messagingSession = new DefaultMessagingSession(configuration, logger);
    const observer = {
      messagingSessionDidStart: () => {},
      messagingSessionDidStartConnecting: (reconnecting) => {
        if (reconnecting) {
          // console.log('Start reconnecting');
        } else {
          // console.log('Start connecting');
        }
      },
      messagingSessionDidStop: (event) => {
        // console.log(`Closed: ${event.code} ${event.reason}`);
      },
      messagingSessionDidReceiveMessage: (message) => {},
    };
    messagingSession.addObserver(observer);
    messagingSession.start();
  }
  getOrdinal(n) {
    let ord = [, 'st', 'nd', 'rd'];
    let exceptions = [11, 12, 13];
    let nth =
      ord[n % 100] == undefined || exceptions.includes(n % 100)
        ? 'th'
        : ord[n % 100];
    return n + nth;
  }
  async UpdateCohort() {
    if (
      this.SelectedCompany == '' ||
      this.SelectedCompany == null ||
      this.SelectedCompany == undefined
    ) {
      this.service.ShowError('Select company');
    } else if (
      this.SelectedTemplate == '' ||
      this.SelectedTemplate == null ||
      this.SelectedTemplate == undefined
    ) {
      this.service.ShowError('Select course template');
    } else if (
      this.CohortName == '' ||
      this.CohortName == null ||
      this.CohortName == undefined
    ) {
      this.service.ShowError('Enter Live class name');
    } else {
      let videotemp = '';
      let mindmaptemp = '';
      let email_templates = '';
      let knowassessments = '';
      let practicetests = '';
      let ketexamtests = '';
      let cohort_name = this.CohortName;
      let certi_id = this.SelectedCertificate;
      let company_id = this.SelectedCompany;
      let time_zone = JSON.stringify(this.time_zone);
      let userslist = '';
      let schedule = [];
      for (let i = 0; i < this.selection.selected.length; i++) {
        userslist += this.selection.selected[i].id + ',';
      }
      if (userslist.endsWith(',') == true) {
        userslist = userslist.substring(0, userslist.length - 1);
      }
      this.Videos.forEach((ka) => {
        if (ka.Assigned) {
          videotemp += ka.id + ',';
          var save_due = ka.Due;
          var save_Overdue = ka.OverDue;
          var save_Enddue = ka.EndDue;
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'video',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.video_title,
          });
          ka.Due = save_due;
          ka.OverDue = save_Overdue;
          ka.EndDue = save_Enddue;
        }
      });
      if (videotemp.endsWith(',') == true) {
        videotemp = videotemp.substring(0, videotemp.length - 1);
      }
      this.Mindmap.forEach((ka) => {
        if (ka.Assigned) {
          mindmaptemp += ka.id + ',';
          var save_due = ka.Due;
          var save_Overdue = ka.OverDue;
          var save_Enddue = ka.EndDue;
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'mindmap',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.name,
          });
          ka.Due = save_due;
          ka.OverDue = save_Overdue;
          ka.EndDue = save_Enddue;
        }
      });
      if (mindmaptemp.endsWith(',') == true) {
        mindmaptemp = mindmaptemp.substring(0, mindmaptemp.length - 1);
      }
      //Knowledge Assessments----------------------------------
      this.KnowledgeAssessments.forEach((ka) => {
        if (ka.Assigned) {
          knowassessments += ka.id + ',';
          var save_due = ka.Due;
          var save_Overdue = ka.OverDue;
          var save_Enddue = ka.EndDue;
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'KA',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.knowass_name,
          });
          ka.Due = save_due;
          ka.OverDue = save_Overdue;
          ka.EndDue = save_Enddue;
        }
      });
      if (knowassessments.endsWith(',') == true) {
        knowassessments = knowassessments.substring(
          0,
          knowassessments.length - 1,
        );
      }
      //Practice Exams----------------------------------
      this.PracticeExams.forEach((ka) => {
        if (ka.Assigned) {
          practicetests += ka.id + ',';
          var save_due = ka.Due;
          var save_Overdue = ka.OverDue;
          var save_Enddue = ka.EndDue;
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'PRACTICE',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.practice_name,
          });
          ka.Due = save_due;
          ka.OverDue = save_Overdue;
          ka.EndDue = save_Enddue;
        }
      });
      if (practicetests.endsWith(',') == true) {
        practicetests = practicetests.substring(0, practicetests.length - 1);
      }
      //CAT Exams----------------------------------
      this.CatExams.forEach((ka) => {
        if (ka.Assigned) {
          ketexamtests += ka.id + ',';
          var save_due = ka.Due;
          var save_Overdue = ka.OverDue;
          var save_Enddue = ka.EndDue;
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'CAT',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            name: ka.ketexam_name,
          });
          ka.Due = save_due;
          ka.OverDue = save_Overdue;
          ka.EndDue = save_Enddue;
        }
      });
      if (ketexamtests.endsWith(',') == true) {
        ketexamtests = ketexamtests.substring(0, ketexamtests.length - 1);
      }
      //Email Templates----------------------------------
      this.EmailTemplates.forEach((ka) => {
        if (ka.Assigned) {
          email_templates += ka.id + ',';
          var save_due = ka.Due;
          var save_Overdue = ka.OverDue;
          var save_Enddue = ka.EndDue;
          ka.Due = this.newDueDateCalculate(ka.DueTime, ka.Due);
          ka.OverDue = this.newDueDateCalculate(ka.OverDueTime, ka.OverDue);
          ka.EndDue = this.newDueDateCalculate(ka.EndTime, ka.EndDue);
          schedule.push({
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            type: 'EMAIL',
            type_id: ka.id,
            cohort_id: '',
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
          });
          ka.Due = save_due;
          ka.OverDue = save_Overdue;
          ka.EndDue = save_Enddue;
        }
      });
      if (email_templates.endsWith(',') == true) {
        email_templates = email_templates.substring(
          0,
          email_templates.length - 1,
        );
      }
      let flashcard_app = this.FashFullAccess;
      let practice_que_app = this.PraAppFullAccess;
      let events = '';
      if (this.Events.length > 0) {
        this.Events.forEach((element) => {
          element.event_date = this.auth.convertLocalTimeZoneUtcTimeZone(
            element.event_date,
            this.time_zone.nameValue,
            this.time_zone.timeValue,
          );
        });
        events = JSON.stringify(this.Events);
      }
      var access = {
        VideoAccess: this.VideoAccess,
        MindmapAccess: this.MindmapAccess,
        SummaryAccess: this.SummaryAccess,
        ReferenceAccess: this.ReferenceAccess,
        KAAccess: this.KAAccess,
        PTAccess: this.PTAccess,
        CATAccess: this.CATAccess,
        LiveChatAccess: this.LiveChatAccess,
        LiveQuestionAccess: this.LiveQuestionAccess,
        LiveEventAccess: this.LiveEventAccess,
        FlashcardAccess: this.FashFullAccess,
        QuestionAccess: this.PraAppFullAccess,
      };
      var baseline_ka_schedule = {
        baselineka: this.SelectedBaselineKA,
        DueDate: this.SelectedBaselineKAdueDate,
        DueTime: this.SelectedBaselineKAdueTime,
        OverDueDate: this.SelectedBaselineKAOverdueDate,
        OverDueTime: this.SelectedBaselineKAOverdueTime,
        EndDate: this.SelectedBaselineKAEndDate,
        EndTime: this.SelectedBaselineKAEndTime,
        DueNumber: this.SelectedBaselineKAdueNumber,
        OverDueNumber: this.SelectedBaselineKAOverdueNumber,
        EndNumber: this.SelectedBaselineKAEndNumber,
      };
      if (
        this.SelectedBaselineKA != '' &&
        this.SelectedBaselineKAdueDate != '' &&
        this.SelectedBaselineKAdueTime != '' &&
        this.SelectedBaselineKAOverdueDate != '' &&
        this.SelectedBaselineKAOverdueTime != ''
      ) {
        schedule.push({
          due_date: this.newDueDateCalculate(
            this.SelectedBaselineKAdueTime,
            this.SelectedBaselineKAdueDate,
          ),
          overdue_date: this.newDueDateCalculate(
            this.SelectedBaselineKAOverdueTime,
            this.SelectedBaselineKAOverdueDate,
          ),
          end_date: this.newDueDateCalculate(
            this.SelectedBaselineKAEndTime,
            this.SelectedBaselineKAEndDate,
          ),
          required: false,
          homework: false,
          type: 'KA',
          type_id: this.SelectedBaselineKA,
          cohort_id: '',
          due_number: this.SelectedBaselineKAdueNumber,
          overdue_number: this.SelectedBaselineKAOverdueNumber,
          name:
            this.Filterbaselineka.filter((x) => x.id == this.SelectedBaselineKA)
              .length > 0
              ? this.Filterbaselineka.filter(
                  (x) => x.id == this.SelectedBaselineKA,
                )[0].knowass_name
              : '',
        });
      }
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            if (
              userslist != null &&
              userslist != undefined &&
              userslist != ''
            ) {
              await this.addNotification(schedule, this.Id);
            }
            await this.cohortService
              .UpdateNewCohort(
                this.Id,
                cohort_name,
                certi_id,
                1,
                knowassessments,
                practicetests,
                ketexamtests,
                flashcard_app,
                practice_que_app,
                events,
                userslist,
                this.Location,
                company_id,
                schedule,
                email_templates,
                time_zone,
                this.SelectedTemplate,
                access,
                this.auth.convertStringUtcTimeZone(
                  this.StartDate,
                  this.time_zone.nameValue,
                ),
                0,
                0,
                '',
                token,
                this.SelectedEvents.toString(),
                videotemp,
                mindmaptemp,
                this.SelectedBaselineKA,
                JSON.stringify(baseline_ka_schedule),
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        this.newAuditValueArray = {
                          'Company name': this.Companies.find(
                            (x) => x.id == this.SelectedCompany,
                          )
                            ? this.Companies.find(
                                (x) => x.id == this.SelectedCompany,
                              ).name
                            : '',
                          Template: this.Templates.find(
                            (x) => x.id == this.SelectedTemplate,
                          )
                            ? this.Templates.find(
                                (x) => x.id == this.SelectedTemplate,
                              ).templateName
                            : '',
                          'Cohort Name': this.CohortName,
                          'Time Zone': this.time_zone.nameValue,
                          Location: this.Location,
                          'Start Date': new Date(this.StartDate).toDateString(),
                          'Live Event': this.FilterLiveEvents.filter((item) => {
                            return this.SelectedEvents.includes(item.id);
                          })
                            .map(function (elem) {
                              return elem.event_name;
                            })
                            .join(','),
                          'Video Access': [
                            { type: 'checkbox', value: this.VideoAccess },
                          ],
                          'Mindmaps Access': [
                            { type: 'checkbox', value: this.MindmapAccess },
                          ],
                          'Summary Access': [
                            { type: 'checkbox', value: this.SummaryAccess },
                          ],
                          'Reference Access': [
                            { type: 'checkbox', value: this.ReferenceAccess },
                          ],
                          'KA Access': [
                            { type: 'checkbox', value: this.KAAccess },
                          ],
                          'Practice Test Access': [
                            { type: 'checkbox', value: this.PTAccess },
                          ],
                          'CAT Access': [
                            { type: 'checkbox', value: this.CATAccess },
                          ],
                          'LiveChat Access': [
                            { type: 'checkbox', value: this.LiveChatAccess },
                          ],
                          'LiveQuestion Access': [
                            {
                              type: 'checkbox',
                              value: this.LiveQuestionAccess,
                            },
                          ],
                          'LiveEvent Access': [
                            { type: 'checkbox', value: this.LiveEventAccess },
                          ],
                          'FashcardFull Access': [
                            { type: 'checkbox', value: this.FashFullAccess },
                          ],
                          'Practice question app full access': [
                            { type: 'checkbox', value: this.PraAppFullAccess },
                          ],
                        };
                        var audit = this.auth.compareAuditHist(
                          this.oldAuditValueArray,
                          this.newAuditValueArray,
                        );
                        var desc = audit;
                        await this.service.addAdminLogs(
                          desc,
                          'cohorts',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  this.SearchStudent = '';
                  if (this.SelectedEvents.length > 0) {
                    if (
                      userslist != null &&
                      userslist != undefined &&
                      userslist != ''
                    )
                      await this.addNotificationLiveEvent(
                        userslist,
                        list.data.id,
                      );
                  }
                  this.closeModal();
                  await this.listCohort();
                  this.addCohort = false;
                  this.IsUpdate = false;
                  await this.getAllUsers(
                    1,
                    this.studentpage ? this.studentpage.pageSize : 10,
                    '',
                  );
                  await this.spinner.hide();
                  this.service.ShowSuccess('Live class update Successfully');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async GetAllCompany() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.companyService
              .GetAllCompany(token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  await this.spinner.hide();
                  var list = JSON.parse(<string>data[0].json);
                  this.Companies = list.data;
                  this.SelectedCompany = '';
                }
              });
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
  async getAllCourseTemplate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.templateService.ListTemplates(token).then(async (data) => {
          if (data[0].status == 200) {
            this.time_zone = this.time_zone.nameValue;
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              if (element.videos.length > 0) {
                for (let i = 0; i < element.videos.length; i++) {
                  if (element.video_schedule.length > 0) {
                    element.videos[i].Assigned = true;
                    element.videos[i].cohort_id = element.id;
                    element.video_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.videos[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.videos[i].Required = schedule.required;
                        element.videos[i].Homework = schedule.homework;
                        element.videos[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.videos[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.videos[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.videos[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.videos[i].OverDueTime = schedule.overdue_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.overdue_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.videos[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.videos[i].DueNumber = schedule.due_number;
                        element.videos[i].OverDueNumber =
                          schedule.overdue_number;
                        element.videos[i].EndNumber = schedule.end_number;
                      }
                    });
                  }
                }
              }
              if (element.mindmaps.length > 0) {
                for (let i = 0; i < element.mindmaps.length; i++) {
                  if (element.mindmap_schedule.length > 0) {
                    element.mindmaps[i].Assigned = true;
                    element.mindmaps[i].cohort_id = element.id;
                    element.mindmap_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.mindmaps[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.mindmaps[i].Required = schedule.required;
                        element.mindmaps[i].Homework = schedule.homework;
                        element.mindmaps[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.mindmaps[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.mindmaps[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.mindmaps[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.mindmaps[i].OverDueTime = schedule.overdue_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.overdue_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.mindmaps[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.mindmaps[i].DueNumber = schedule.due_number;
                        element.mindmaps[i].OverDueNumber =
                          schedule.overdue_number;
                        element.mindmaps[i].EndNumber = schedule.end_number;
                      }
                    });
                  }
                }
              }
              if (element.knowledge_asses.length > 0) {
                for (let i = 0; i < element.knowledge_asses.length; i++) {
                  if (element.knowledge_schedule.length > 0) {
                    element.knowledge_asses[i].Assigned = true;
                    element.knowledge_asses[i].cohort_id = element.id;
                    element.knowledge_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.knowledge_asses[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.knowledge_asses[i].Required = schedule.required;
                        element.knowledge_asses[i].Homework = schedule.homework;
                        element.knowledge_asses[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.knowledge_asses[i].OverDue =
                          schedule.overdue_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.overdue_date,
                                  this.time_zone,
                                )
                                .split('T')[0]
                            : '';
                        element.knowledge_asses[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.knowledge_asses[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.knowledge_asses[i].OverDueTime =
                          schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                        element.knowledge_asses[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.knowledge_asses[i].DueNumber =
                          schedule.due_number;
                        element.knowledge_asses[i].OverDueNumber =
                          schedule.overdue_number;
                        element.knowledge_asses[i].EndNumber =
                          schedule.end_number;
                      }
                    });
                  }
                }
              }
              if (element.practicetests.length > 0) {
                for (let i = 0; i < element.practicetests.length; i++) {
                  if (element.practice_schedule.length > 0) {
                    element.practicetests[i].Assigned = true;
                    element.practicetests[i].cohort_id = element.id;
                    element.practice_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.practicetests[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.practicetests[i].Required = schedule.required;
                        element.practicetests[i].Homework = schedule.homework;
                        element.practicetests[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.practicetests[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.practicetests[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.practicetests[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.practicetests[i].OverDueTime =
                          schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                        element.practicetests[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.practicetests[i].DueNumber =
                          schedule.due_number;
                        element.practicetests[i].OverDueNumber =
                          schedule.overdue_number;
                        element.practicetests[i].EndNumber =
                          schedule.end_number;
                      }
                    });
                  }
                }
              }
              if (element.ketexamtests.length > 0) {
                for (let i = 0; i < element.ketexamtests.length; i++) {
                  if (element.cat_schedule.length > 0) {
                    element.ketexamtests[i].Assigned = true;
                    element.ketexamtests[i].cohort_id = element.id;
                    element.cat_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.ketexamtests[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.ketexamtests[i].Required = schedule.required;
                        element.ketexamtests[i].Homework = schedule.homework;
                        element.ketexamtests[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.ketexamtests[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.ketexamtests[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone,
                              )
                              .split('T')[0]
                          : '';
                        element.ketexamtests[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.ketexamtests[i].OverDueTime =
                          schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone,
                                'HH:mm:ss',
                              )
                            : '';
                        element.ketexamtests[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone,
                              'HH:mm:ss',
                            )
                          : '';
                        element.ketexamtests[i].DueNumber = schedule.due_number;
                        element.ketexamtests[i].OverDueNumber =
                          schedule.overdue_number;
                        element.ketexamtests[i].EndNumber = schedule.end_number;
                      }
                    });
                  }
                }
              }
              await this.Templates.push({
                Srno: srno,
                id: element.id,
                templateName: element.name,
                certi_id: element.certi_id,
                cource_id: element.cource_id,
                createdAt: element.createdAt,
                videos: element.videos,
                mindmaps: element.mindmaps,
                knowassessments: element.knowledge_asses,
                practicetests: element.practicetests,
                ketexamtests: element.ketexamtests,
                updatedAt: element.updatedAt,
                certificate_name: element.certificate.certificate_name,
                practice_que_app: element.practice_que_app,
                flashcard_app: element.flashcard_app,
                events: element.events,
                live_events: element.live_events
                  ? element.live_events.split(',')
                  : [],
                video_access: element.video_access,
                mindmap_access: element.mindmap_access,
                summary_access: element.summary_access,
                reference_access: element.reference_access,
                KA_access: element.KA_access,
                PT_access: element.PT_access,
                CAT_access: element.CAT_access,
                livechat_access: element.livechat_access,
                liveq_access: element.liveq_access,
                liveevent_access: element.liveevent_access,
                start_date: this.auth.ConvertFormatDbDateTime(
                  element.start_date,
                  this.time_zone,
                ),
                question_access: element.question_access,
                flashcard_access: element.flashcard_access,
                baseline_ka: element.baseline_ka,
                baseline_ka_schedule: element.baseline_ka_schedule,
              });
            });
            this.SelectedTemplate = '';
          }
        });
      }
    });
  }
  async getAllExams() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getAllExams(token).then(async (data) => {
          this.KnowledgeAssessments = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.KnowledgeAssessments = list.data.KnowledgeAssessments;
            this.PracticeExams = list.data.PracticeExams;
            this.CatExams = list.data.CatExams;
            this.FilterKnowledgeAssessments = list.data.KnowledgeAssessments;
            this.FilterPracticeExams = list.data.PracticeExams;
            this.FilterCatExams = list.data.CatExams;
            this.EmailTemplates = list.data.EmailTemplates;
            this.FilterEmailTemplates = list.data.EmailTemplates;
            let cat_count = [];
            cat_count = list.data.cat_count;
            let ka_count = [];
            ka_count = list.data.ka_count;
            let practice_count = [];
            practice_count = list.data.practice_count;
            this.KnowledgeAssessments.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Homework = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
              ka_count.forEach((element) => {
                if (element.ass_id.includes(ka.id)) {
                  ka.Count = element.ttlque;
                }
              });
              if (ka.knowass_name.toLowerCase().indexOf('pre') > -1)
                this.Filterbaselineka.push(ka);
              if (ka.knowass_name.toLowerCase().indexOf('baseline') > -1)
                this.Filterbaselineka.push(ka);
            });
            this.PracticeExams.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Homework = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
              practice_count.forEach((element) => {
                if (element.test_id == ka.id) {
                  ka.Count = element.ttlque;
                }
              });
            });
            this.CatExams.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Homework = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
              cat_count.forEach((element) => {
                if (element.test_id == ka.id) {
                  ka.Count = element.ttlque;
                }
              });
            });
            this.EmailTemplates.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
            });
            this.baselineka = this.Filterbaselineka;
            this.SelectedBaselineKA = '';
            this.SelectedBaselineKAdueDate = '';
            this.SelectedBaselineKAdueTime = '';
            this.SelectedBaselineKAOverdueDate = '';
            this.SelectedBaselineKAOverdueTime = '';
            this.SelectedBaselineKAEndDate = '';
            this.SelectedBaselineKAEndTime = '';
            this.SelectedBaselineKAdueNumber = '';
            this.SelectedBaselineKAOverdueNumber = '';
            this.SelectedBaselineKAEndNumber = '';
          }
        });
      }
    });
  }
  async addEvents() {
    let isAdd = true;
    this.Events.forEach((element) => {
      if (element.event_name == '') {
        isAdd = false;
      }
    });
    if (isAdd) {
      this.Events.push({
        event_name: '',
        event_date: '',
        event_time: '',
        minutes: '',
        url: '',
      });
    }
  }
  async GetAllDropdown(token) {
    await this.service.GetAllDropdown(token).then(async (data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        this.Certificates = list.data.Certificate;
        this.Domain = list.data.Domain;
      }
    });
  }
  async deleteEvents(item) {
    const index = this.Events.indexOf(item);
    if (index > -1) {
      this.Events.splice(index, 1);
    }
  }
  async changeTemplate() {
    if (this.SelectedTemplate == '') {
      this.Videos.forEach((ka) => {
        ka.Assigned = false;
        ka.Required = false;
        ka.Homework = false;
        ka.Due = '';
        ka.OverDue = '';
        ka.EndDue = '';
        ka.DueTime = '';
        ka.OverDueTime = '';
        ka.EndTime = '';
        ka.DueNumber = '';
        ka.OverDueNumber = '';
        ka.EndNumber = '';
      });
      this.Mindmap.forEach((ka) => {
        ka.Assigned = false;
        ka.Required = false;
        ka.Homework = false;
        ka.Due = '';
        ka.OverDue = '';
        ka.EndDue = '';
        ka.DueTime = '';
        ka.OverDueTime = '';
        ka.EndTime = '';
        ka.DueNumber = '';
        ka.OverDueNumber = '';
        ka.EndNumber = '';
      });
      this.KnowledgeAssessments.forEach((ka) => {
        ka.Assigned = false;
        ka.Required = false;
        ka.Homework = false;
        ka.Due = '';
        ka.OverDue = '';
        ka.EndDue = '';
        ka.DueTime = '';
        ka.OverDueTime = '';
        ka.EndTime = '';
        ka.DueNumber = '';
        ka.OverDueNumber = '';
        ka.EndNumber = '';
      });
      this.PracticeExams.forEach((ka) => {
        ka.Assigned = false;
        ka.Required = false;
        ka.Homework = false;
        ka.Due = '';
        ka.OverDue = '';
        ka.EndDue = '';
        ka.DueTime = '';
        ka.OverDueTime = '';
        ka.EndTime = '';
        ka.DueNumber = '';
        ka.OverDueNumber = '';
        ka.EndNumber = '';
      });
      this.CatExams.forEach((ka) => {
        ka.Assigned = false;
        ka.Required = false;
        ka.Homework = false;
        ka.Due = '';
        ka.OverDue = '';
        ka.EndDue = '';
        ka.DueTime = '';
        ka.OverDueTime = '';
        ka.EndTime = '';
        ka.DueNumber = '';
        ka.OverDueNumber = '';
        ka.EndNumber = '';
      });
      this.Events = [];
    }
    this.Templates.forEach((temp) => {
      if (temp.id == this.SelectedTemplate) {
        this.LiveEvents = this.FilterLiveEvents.filter((data) => {
          return (
            data.certi_id
              .toString()
              .toLowerCase()
              .indexOf(temp.certi_id.toString().toLowerCase()) > -1
          );
        });
        this.Videos = this.FilterVideos.filter((data) => {
          return (
            data.certi_id
              .toString()
              .toLowerCase()
              .indexOf(temp.certi_id.toString().toLowerCase()) > -1
          );
        });
        this.Mindmap = this.FilterMindmap.filter((data) => {
          return (
            data.certi_id
              .toString()
              .toLowerCase()
              .indexOf(temp.certi_id.toString().toLowerCase()) > -1
          );
        });
        this.KnowledgeAssessments = this.FilterKnowledgeAssessments.filter(
          (data) => {
            return (
              data.certi_id
                .toString()
                .toLowerCase()
                .indexOf(temp.certi_id.toString().toLowerCase()) > -1
            );
          },
        );
        this.CatExams = this.FilterCatExams.filter((data) => {
          return (
            data.certi_id
              .toString()
              .toLowerCase()
              .indexOf(temp.certi_id.toString().toLowerCase()) > -1
          );
        });
        this.PracticeExams = this.FilterPracticeExams.filter((data) => {
          return (
            data.certi_id
              .toString()
              .toLowerCase()
              .indexOf(temp.certi_id.toString().toLowerCase()) > -1
          );
        });
        this.EmailTemplates = this.FilterEmailTemplates.filter((data) => {
          return (
            data.certi_id
              .toString()
              .toLowerCase()
              .indexOf(temp.certi_id.toString().toLowerCase()) > -1
          );
        });
        this.baselineka = this.Filterbaselineka.filter((data) => {
          return (
            data.certi_id
              .toString()
              .toLowerCase()
              .indexOf(temp.certi_id.toString().toLowerCase()) > -1
          );
        });
        this.PraAppFullAccess = temp.question_access;
        this.FashFullAccess = temp.flashcard_access;
        this.VideoAccess = temp.video_access;
        this.MindmapAccess = temp.mindmap_access;
        this.SummaryAccess = temp.summary_access;
        this.ReferenceAccess = temp.reference_access;
        this.KAAccess = temp.KA_access;
        this.PTAccess = temp.PT_access;
        this.CATAccess = temp.CAT_access;
        this.LiveChatAccess = temp.livechat_access;
        this.LiveQuestionAccess = temp.liveq_access;
        this.LiveEventAccess = temp.liveevent_access;
        this.SelectedCertificate = temp.certi_id;
        this.SelectedBaselineKA = temp.baseline_ka;
        if (temp.baseline_ka_schedule) {
          this.SelectedBaselineKAdueDate =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).DueDate
              : '';
          this.SelectedBaselineKAdueTime =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).DueTime
              : '';
          this.SelectedBaselineKAOverdueDate =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).OverDueDate
              : '';
          this.SelectedBaselineKAOverdueTime =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).OverDueTime
              : '';
          this.SelectedBaselineKAEndDate =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).EndDate
              : '';
          this.SelectedBaselineKAEndTime =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).EndTime
              : '';
          this.SelectedBaselineKAdueNumber =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).DueNumber
              : '';
          this.SelectedBaselineKAOverdueNumber =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).OverDueNumber
              : '';
          this.SelectedBaselineKAEndNumber =
            temp.baseline_ka_schedule && temp.baseline_ka_schedule != ''
              ? JSON.parse(temp.baseline_ka_schedule).EndNumber
              : '';
        }
        this.time_zone = this.auth.formatZone(momentZone.tz.guess(true));
        this.StartDate = this.auth.ConvertFormatDbDateTime(
          temp.start_date,
          this.time_zone.nameValue,
        );
        this.QuestionAccess = true;
        this.FlashcardAccess = true;
        if (temp.events != null) {
          try {
            this.Events = JSON.parse(temp.events);
            this.Events.forEach((element) => {
              element.due_number = element.event_date;
            });
          } catch (error) {
            this.Events = [];
          }
        } else {
          this.Events = [];
        }
        this.SelectedEvents = [];
        if (
          temp.live_events != null &&
          temp.live_events != '' &&
          temp.live_events != '0'
        ) {
          this.SelectedEvents = temp.live_events.map(Number);
        }
        if (temp.videos && temp.videos.length > 0) {
          temp.videos.forEach((temp_ka) => {
            this.Videos.forEach((ka) => {
              if (ka.id == temp_ka.id) {
                ka.Assigned = temp_ka.Assigned;
                ka.Due = temp_ka.Due;
                ka.OverDue = temp_ka.OverDue;
                ka.EndDue = temp_ka.EndDue;
                ka.Required = temp_ka.Required;
                ka.Homework = temp_ka.Homework;
                ka.DueTime = temp_ka.DueTime;
                ka.OverDueTime = temp_ka.OverDueTime;
                ka.EndTime = temp_ka.EndTime;
                ka.DueNumber = temp_ka.DueNumber;
                ka.OverDueNumber = temp_ka.OverDueNumber;
                ka.EndNumber = temp_ka.EndNumber;
              }
            });
          });
        }
        if (temp.mindmaps && temp.mindmaps.length > 0) {
          temp.mindmaps.forEach((temp_ka) => {
            this.Mindmap.forEach((ka) => {
              if (ka.id == temp_ka.id) {
                ka.Assigned = temp_ka.Assigned;
                ka.Due = temp_ka.Due;
                ka.OverDue = temp_ka.OverDue;
                ka.EndDue = temp_ka.EndDue;
                ka.Required = temp_ka.Required;
                ka.Homework = temp_ka.Homework;
                ka.DueTime = temp_ka.DueTime;
                ka.OverDueTime = temp_ka.OverDueTime;
                ka.EndTime = temp_ka.EndTime;
                ka.DueNumber = temp_ka.DueNumber;
                ka.OverDueNumber = temp_ka.OverDueNumber;
                ka.EndNumber = temp_ka.EndNumber;
              }
            });
          });
        }
        if (temp.knowassessments && temp.knowassessments.length > 0) {
          temp.knowassessments.forEach((temp_ka) => {
            this.KnowledgeAssessments.forEach((ka) => {
              if (ka.id == temp_ka.id) {
                ka.Assigned = temp_ka.Assigned;
                ka.Due = temp_ka.Due;
                ka.OverDue = temp_ka.OverDue;
                ka.EndDue = temp_ka.EndDue;
                ka.Required = temp_ka.Required;
                ka.Homework = temp_ka.Homework;
                ka.DueTime = temp_ka.DueTime;
                ka.OverDueTime = temp_ka.OverDueTime;
                ka.EndTime = temp_ka.EndTime;
                ka.DueNumber = temp_ka.DueNumber;
                ka.OverDueNumber = temp_ka.OverDueNumber;
                ka.EndNumber = temp_ka.EndNumber;
              }
            });
          });
        }
        if (temp.practicetests && temp.practicetests.length > 0) {
          temp.practicetests.forEach((temp_ka) => {
            this.PracticeExams.forEach((ka) => {
              if (ka.id == temp_ka.id) {
                ka.Assigned = temp_ka.Assigned;
                ka.Due = temp_ka.Due;
                ka.OverDue = temp_ka.OverDue;
                ka.EndDue = temp_ka.EndDue;
                ka.Required = temp_ka.Required;
                ka.Homework = temp_ka.Homework;
                ka.DueTime = temp_ka.DueTime;
                ka.OverDueTime = temp_ka.OverDueTime;
                ka.EndTime = temp_ka.EndTime;
                ka.DueNumber = temp_ka.DueNumber;
                ka.OverDueNumber = temp_ka.OverDueNumber;
                ka.EndNumber = temp_ka.EndNumber;
              }
            });
          });
        }
        if (temp.ketexamtests && temp.ketexamtests.length > 0) {
          temp.ketexamtests.forEach((temp_ka) => {
            this.CatExams.forEach((ka) => {
              if (ka.id == temp_ka.id) {
                ka.Assigned = temp_ka.Assigned;
                ka.Due = temp_ka.Due;
                ka.OverDue = temp_ka.OverDue;
                ka.EndDue = temp_ka.EndDue;
                ka.Required = temp_ka.Required;
                ka.Homework = temp_ka.Homework;
                ka.DueTime = temp_ka.DueTime;
                ka.OverDueTime = temp_ka.OverDueTime;
                ka.EndTime = temp_ka.EndTime;
                ka.DueNumber = temp_ka.DueNumber;
                ka.OverDueNumber = temp_ka.OverDueNumber;
                ka.EndNumber = temp_ka.EndNumber;
              }
            });
          });
        }
        if (temp.email_templates && temp.email_templates.length > 0) {
          temp.email_templates.forEach((temp_ka) => {
            this.EmailTemplates.forEach((ka) => {
              if (ka.id == temp_ka.id) {
                ka.Assigned = temp_ka.Assigned;
                ka.Due = temp_ka.Due;
                ka.OverDue = temp_ka.OverDue;
                ka.EndDue = temp_ka.EndDue;
                ka.Required = temp_ka.Required;
                ka.Homework = temp_ka.Homework;
                ka.DueTime = temp_ka.DueTime;
                ka.OverDueTime = temp_ka.OverDueTime;
                ka.EndTime = temp_ka.EndTime;
                ka.DueNumber = temp_ka.DueNumber;
                ka.OverDueNumber = temp_ka.OverDueNumber;
                ka.EndNumber = temp_ka.EndNumber;
              }
            });
          });
        }
        if (temp.baseline_ka_schedule && temp.baseline_ka_schedule != '') {
          this.SelectedBaselineKAdueDate = this.dueDateCalculate(
            this.SelectedBaselineKAdueTime,
            this.SelectedBaselineKAdueDate,
          );
          this.SelectedBaselineKAOverdueDate = this.dueDateCalculate(
            this.SelectedBaselineKAOverdueTime,
            this.SelectedBaselineKAOverdueDate,
          );
          this.SelectedBaselineKAEndDate = this.dueDateCalculate(
            this.SelectedBaselineKAEndTime,
            this.SelectedBaselineKAEndDate,
          );
        } else {
          this.SelectedBaselineKA = '';
          this.SelectedBaselineKAdueDate = '';
          this.SelectedBaselineKAdueTime = '';
          this.SelectedBaselineKAOverdueDate = '';
          this.SelectedBaselineKAOverdueTime = '';
          this.SelectedBaselineKAEndDate = '';
          this.SelectedBaselineKAEndTime = '';
          this.SelectedBaselineKAdueNumber = '';
          this.SelectedBaselineKAOverdueNumber = '';
          this.SelectedBaselineKAEndNumber = '';
        }
        this.changeStartDate();
      }
    });
  }
  async getAllUsers(pageNo, limit, search) {
    this.Users = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            if (!this.SearchStudent) {
              this.SearchStudent = '';
            }
            this.Users = [];
            await this.cohortService
              .getLimitStudent(
                pageNo,
                limit,
                search,
                this.SearchSelectedCompany,
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.Users = [];
                  await list.data.rows.forEach(async (element) => {
                    if (element.roll_id == 0 || element.roll_id == 3) {
                      let check = false;
                      for (let i = 0; i < this.selection.selected.length; i++) {
                        if (this.selection.selected[i].id == element.id) {
                          check = true;
                        }
                      }
                      await this.Users.push({
                        id: element.id,
                        name: element.name,
                        email: element.email,
                        isLogin: element.notes,
                        checked: check,
                      });
                    }
                  });
                  if (this.EditUsers.length > 0) {
                    await this.EditUsers.forEach(async (element) => {
                      await this.Users.forEach((main) => {
                        if (element.id == main.id) {
                          main.checked = true;
                        }
                      });
                    });
                  }
                  this.dataSourceStudents = new MatTableDataSource(this.Users);
                  this.totalUsers = list.data.count;
                  if (!this.dataSourceStudents.paginator.initialized) {
                    this.dataSourceStudents.paginator = this.studentpage;
                  }
                } else {
                  this.spinner.hide();
                }
              });
          }
        });
    } catch (e) {
      this.spinner.hide();
    }
  }
  async pageEvents(event: any) {
    await this.spinner.show();
    await this.getAllUsers(
      event.pageIndex + 1,
      event.pageSize,
      this.SearchStudent,
    );
    await this.spinner.hide();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceStudents.data.length;
    return numSelected === numRows;
  }
  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }
  masterToggle(event) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
    if (event.checked == false) {
      this.Users.forEach((element) => {
        element.checked = false;
      });
    }
  }
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isEntirePageSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }
  getPageData() {
    return this.dataSourceStudents._pageData(
      this.dataSourceStudents._orderData(this.dataSourceStudents.filteredData),
    );
  }
  dueDateCalculate(DueTime, DueNumber) {
    if (DueNumber !== '' && DueNumber != null && DueNumber != undefined) {
      let date = this.auth.ConvertAnyTimeZoneToParseTimeZone(
        this.StartDate,
        this.time_zone.nameValue,
      );
      let hr = DueTime;
      let min = DueTime;
      hr = parseInt(hr.substr(0, 2));
      min = parseInt(min.substr(3, 4));
      if (!hr) {
        hr = date.getHours();
      } else {
        hr = date.getHours() + hr;
      }
      if (!min) {
        min = date.getMinutes();
      } else {
        min = date.getHours() + min;
      }
      if (DueNumber == '') DueNumber = '0';
      var dt = new Date(date.setDate(date.getDate() + parseInt(DueNumber)));
      let d_date = dt.getDate();
      let d_year = dt.getFullYear();
      let d_month = dt.getMonth() + 1;
      var final_date =
        d_year +
        '-' +
        d_month.toString().padStart(2, '0') +
        '-' +
        d_date.toString().padStart(2, '0');
      let return_date = this.auth.ConvertDateDDFormat(
        final_date,
        this.time_zone.nameValue,
        'YYYY-MM-DD',
      );
      return final_date;
    } else {
      return '';
    }
  }
  validateFromTime(item, event) {
    let len = item.event_time.length;
    if (event.keyCode != 8) {
      if (event.keyCode == 46) {
        if (item.event_time.includes(':')) {
          item.event_time = item.event_time.replace(':', '');
          let f = item.event_time.substr(0, 2);
          let s = item.event_time.substr(2, len);
          item.event_time = f + ':' + s;
        }
      }
      if (len >= 5) {
        item.event_time = item.event_time.substr(0, 5);
        let first = item.event_time.split(':')[0];
        let second = item.event_time.split(':')[1];
        if (first > 23) {
          first = 23;
        }
        if (second > 59) {
          second = 59;
        }
        item.event_time = first + ':' + second;
      } else if (len == 2) {
        let out = item.event_time.replace(/(.{2})$/, '$1:');
        item.event_time = out;
      } else if (!item.event_time.includes(':') && len > 2) {
        let f = item.event_time.substr(0, 2);
        let s = item.event_time.substr(2, len);
        item.event_time = f + ':' + s;
      }
    } else {
      if (item.event_time.includes(':')) {
        if (item.event_time.indexOf(':') != 2) {
          item.event_time = item.event_time.replace(':', '');
          let f = item.event_time.substr(0, 2);
          let s = item.event_time.substr(2, len);
          item.event_time = f + ':' + s;
        }
      }
    }
  }
  newDueDateCalculate(DueTime, DueDate) {
    if (DueDate) {
      let date = this.auth.ConvertAnyTimeZoneToParseTimeZone(
        DueDate,
        this.time_zone.nameValue,
      );
      let hr = DueTime;
      let min = DueTime;
      hr = parseInt(hr.substr(0, 2));
      min = parseInt(min.substr(3, 4));
      if (!hr) {
        hr = date.getHours();
      } else {
        hr = date.getHours() + hr;
      }
      if (!min) {
        min = date.getMinutes();
      } else {
        min = date.getHours() + min;
      }
      let d_date = date.getDate();
      let d_year = date.getFullYear();
      let d_month = date.getMonth();
      var final_date = new Date(d_year, d_month, d_date, hr, min);
      return this.auth.convertStringUtcTimeZone(
        final_date,
        this.time_zone.nameValue,
      );
    } else {
      return '';
    }
  }
  async getAllLiveEvents() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveservice.getAllLiveEvents(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.LiveEvents = list.data;
            this.FilterLiveEvents = list.data;
            await this.spinner.hide();
          }
        });
      }
    });
  }
  async getAllVideoLesson() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getAllVideoLesson(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Videos = list.data.Videos;
            this.FilterVideos = list.data.Videos;
            this.Mindmap = list.data.MindMaps;
            this.FilterMindmap = list.data.MindMaps;
            this.Videos.forEach((vi) => {
              vi.Assigned = false;
              vi.Required = false;
              vi.Homework = false;
              vi.Due = '';
              vi.OverDue = '';
              vi.EndDue = '';
              vi.DueNumber = '';
              vi.OverDueNumber = '';
              vi.EndNumber = '';
              vi.DueTime = '';
              vi.OverDueTime = '';
              vi.EndTime = '';
            });
            this.Mindmap.forEach((mi) => {
              mi.Assigned = false;
              mi.Required = false;
              mi.Homework = false;
              mi.Due = '';
              mi.OverDue = '';
              mi.EndDue = '';
              mi.DueNumber = '';
              mi.OverDueNumber = '';
              mi.EndNumber = '';
              mi.DueTime = '';
              mi.OverDueTime = '';
              mi.EndTime = '';
            });
            await this.spinner.hide();
          }
        });
      }
    });
  }
  changeStartDate() {
    this.Events.forEach((element) => {
      element.event_date = this.dueDateCalculate('00:00', element.due_number);
    });
    this.Videos.forEach((ka) => {
      if (ka.Assigned) {
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
      }
    });
    this.Mindmap.forEach((ka) => {
      if (ka.Assigned) {
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
      }
    });
    this.KnowledgeAssessments.forEach((ka) => {
      if (ka.Assigned) {
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
      }
    });
    this.PracticeExams.forEach((ka) => {
      if (ka.Assigned) {
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
      }
    });
    this.CatExams.forEach((ka) => {
      if (ka.Assigned) {
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
      }
    });
    this.EmailTemplates.forEach((ka) => {
      if (ka.Assigned) {
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
      }
    });
  }
  videoChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.Videos.sort((a, b) => (a.video_title > b.video_title ? 1 : -1));
    else if (val == 'date')
      this.Videos.sort(
        (a, b) =>
          new Date(a.Due == '' ? '9999-12-31' : a.Due).getTime() -
          new Date(b.Due == '' ? '9999-12-31' : b.Due).getTime(),
      );
  }
  mindmapChange(event) {
    const val = event.target.value;
    if (val == 'name') this.Mindmap.sort((a, b) => (a.name > b.name ? 1 : -1));
    else if (val == 'date')
      this.Mindmap.sort(
        (a, b) =>
          new Date(a.Due == '' ? '9999-12-31' : a.Due).getTime() -
          new Date(b.Due == '' ? '9999-12-31' : b.Due).getTime(),
      );
  }
  knowledgeChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.KnowledgeAssessments.sort((a, b) =>
        a.knowass_name > b.knowass_name ? 1 : -1,
      );
    else if (val == 'date')
      this.KnowledgeAssessments.sort(
        (a, b) =>
          new Date(a.Due == '' ? '9999-12-31' : a.Due).getTime() -
          new Date(b.Due == '' ? '9999-12-31' : b.Due).getTime(),
      );
  }
  catChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.CatExams.sort((a, b) => (a.ketexam_name > b.ketexam_name ? 1 : -1));
    else if (val == 'date')
      this.CatExams.sort(
        (a, b) =>
          new Date(a.Due == '' ? '9999-12-31' : a.Due).getTime() -
          new Date(b.Due == '' ? '9999-12-31' : b.Due).getTime(),
      );
  }
  practiceChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.PracticeExams.sort((a, b) =>
        a.practice_name > b.practice_name ? 1 : -1,
      );
    else if (val == 'date')
      this.PracticeExams.sort(
        (a, b) =>
          new Date(a.Due == '' ? '9999-12-31' : a.Due).getTime() -
          new Date(b.Due == '' ? '9999-12-31' : b.Due).getTime(),
      );
  }
  emailChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.EmailTemplates.sort((a, b) => (a.name > b.name ? 1 : -1));
    else if (val == 'date')
      this.EmailTemplates.sort(
        (a, b) =>
          new Date(a.Due == '' ? '9999-12-31' : a.Due).getTime() -
          new Date(b.Due == '' ? '9999-12-31' : b.Due).getTime(),
      );
  }
  async changeCompany(event) {
    await this.getAllUsers(
      this.studentpage.pageIndex + 1,
      this.studentpage ? this.studentpage.pageSize : 10,
      this.SearchStudent,
    );
  }
  async addNotificationLiveEvent(userlist, id) {
    let noti = [];
    var user = userlist.split(',');
    var liveevent = [];
    liveevent = this.FilterLiveEvents.filter((x) =>
      this.SelectedEvents.includes(x.id),
    );
    if (liveevent.length > 0) {
      liveevent.forEach((ele) => {
        let msg =
          'event (' +
          ele.event_name +
          ') will start from <a ><span style="cursor:not-allowed;opacity:0.5">Join</span></a>';
        let cohortid = id;
        let StartHour = 0;
        let StartMin = 0;
        if (ele.start_time.includes(':')) {
          StartHour = parseInt(ele.start_time.split(':')[0]);
          StartMin = parseInt(ele.start_time.split(':')[1]);
        } else if (ele.start_date != null && ele.start_date != '') {
          StartHour =
            ele.start_time.toString().split(':')[0] != undefined
              ? parseInt(ele.start_time.split(':')[0])
              : 0;
          StartMin =
            ele.start_time.toString().split(':')[1] != undefined
              ? parseInt(ele.start_time.split(':')[1])
              : 0;
        }
        let StrDate = new Date(ele.start_date);
        let EventDate =
          StrDate.getFullYear() +
          '-' +
          (StrDate.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          StrDate.getDate().toString().padStart(2, '0') +
          'T' +
          ele.start_time +
          ':00' +
          JSON.parse(ele.time_zone).timeValue;
        let date = new Date(
          this.auth.convertAnyTimeZoneUtcTimeZone(
            EventDate,
            JSON.parse(ele.time_zone).nameValue,
          ),
        );
        let firetime = this.auth.convertStringUtcTimeZone(
          this.auth.ConvertFormatDbDateTime(
            date.setMinutes(date.getMinutes() - 15),
            JSON.parse(ele.time_zone).nameValue,
          ),
          JSON.parse(ele.time_zone).nameValue,
        );
        let MeetingStartDate = new Date(
          new Date(ele.start_date).setHours(
            new Date(ele.start_date).getHours() + StartHour,
            new Date(ele.start_date).getHours() + StartMin,
          ),
        );
        user.forEach((user) => {
          noti.push({
            user_id: user,
            cohort_id: cohortid,
            message: msg,
            noti_type: 'start_meeting',
            test_type: 'meeting',
            test_id: id,
            is_read: 0,
            fire_time: firetime,
            meeting_date: MeetingStartDate.toISOString(),
          });
        });
      });
    }
    if (noti.length > 0) {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.service.addNotification(noti, token);
          }
        });
    }
  }
}
export interface cohortlist {
  Srno: number;
  id: number;
  certi_id: number;
  cohort_name: string;
  cource_id: string;
  createdAt: string;
  live_events: string;
  videos: string;
  mindmaps: string;
  knowassessments: string;
  practicetests: string;
  updatedAt: string;
  practice_name: string;
  user: string;
  location: string;
  IsLoading: boolean;
  IsLoad: boolean;
  studentCount: number;
  company: string;
  company_id: string;
  ketexamtests: string;
  template_id: string;
  template_name: string;
  events: string;
  email_templates: string;
  time_zone: string;
  video_access: string;
  mindmap_access: string;
  summary_access: string;
  reference_access: string;
  KA_access: string;
  PT_access: string;
  CAT_access: string;
  livechat_access: string;
  liveq_access: string;
  liveevent_access: string;
  flashcard_access: string;
  question_access: string;
  start_date: string;
  baseline_ka: string;
  baseline_ka_schedule: string;
}
export interface StudentLists {
  id: number;
  name: number;
  email: string;
  isLogin: boolean;
  checked: boolean;
}
