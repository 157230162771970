import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { VariableService } from '../../services/variable/variable.service';

@Component({
  selector: 'app-recordings',
  templateUrl: './recordings.component.html',
  styleUrls: ['./recordings.component.scss'],
})
export class RecordingsComponent implements OnInit {
  displayedColumns: string[] = ['srno', 'title', 'createdAt', 'actions'];
  dataSource: MatTableDataSource<VariableList>;
  IsUpdate: boolean = false;
  Search: any;
  recording_url: any;
  recording_url_720p: any;
  recording_url_480p: any;
  recording_url_360p: any;
  recording_url_240p: any;
  recording_url_144p: any;
  recording_id: any;
  showModal: boolean = false;
  Questions = [
    {
      Text: '',
      FromTime: '',
    },
  ];
  public RecordingForm: FormGroup;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public variableService: VariableService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
    this.RecordingForm = this.fb.group({
      recording_url: ['', Validators.required],
      recording_url_720p: new FormControl(),
      recording_url_480p: new FormControl(),
      recording_url_360p: new FormControl(),
      recording_url_240p: new FormControl(),
      recording_url_144p: new FormControl(),
    });
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  edit(item) {
    this.recording_id = item.id;
    this.recording_url = item.recording_url;
    this.recording_url_720p = item.recording_url_720p;
    this.recording_url_480p = item.recording_url_480p;
    this.recording_url_360p = item.recording_url_360p;
    this.recording_url_240p = item.recording_url_240p;
    this.recording_url_144p = item.recording_url_144p;
    this.Questions = item.questions
      ? JSON.parse(item.questions)
      : [
          {
            Text: '',
            FromTime: '',
          },
        ];
    this.IsUpdate = true;
    this.showModal = true;
  }
  closeModal() {
    this.showModal = false;
    this.recording_url = '';
    this.recording_url_720p = '';
    this.recording_url_480p = '';
    this.recording_url_360p = '';
    this.recording_url_240p = '';
    this.recording_url_144p = '';
    this.recording_id = '';
    this.Questions = [
      {
        Text: '',
        FromTime: '',
      },
    ];
  }
  async ngOnInit() {
    await this.getAllRecordings();
  }
  deleteQuestion(item) {
    const index = this.Questions.indexOf(item);
    if (index > -1) {
      this.Questions.splice(index, 1);
    }
  }
  addQuestion() {
    this.Questions.push({
      Text: '',
      FromTime: '',
    });
  }
  validateFromTime(item, event) {
    let len = item.FromTime.length;
    if (event.keyCode != 8) {
      if (event.keyCode == 46) {
        if (item.FromTime.includes(':')) {
          item.FromTime = item.FromTime.replace(':', '');
          let f = item.FromTime.substr(0, 2);
          let s = item.FromTime.substr(2, len);
          item.FromTime = f + ':' + s;
        }
      }
      if (len >= 5) {
        item.FromTime = item.FromTime.substr(0, 5);
        let first = item.FromTime.split(':')[0];
        let second = item.FromTime.split(':')[1];
        if (first > 59) {
          first = 59;
        }
        if (second > 59) {
          second = 59;
        }
        item.FromTime = first + ':' + second;
      } else if (len == 2) {
        let out = item.FromTime.replace(/(.{2})$/, '$1:');
        item.FromTime = out;
      } else if (!item.FromTime.includes(':') && len > 2) {
        let f = item.FromTime.substr(0, 2);
        let s = item.FromTime.substr(2, len);
        item.FromTime = f + ':' + s;
      }
    } else {
      if (item.FromTime.includes(':')) {
        if (item.FromTime.indexOf(':') != 2) {
          item.FromTime = item.FromTime.replace(':', '');
          let f = item.FromTime.substr(0, 2);
          let s = item.FromTime.substr(2, len);
          item.FromTime = f + ':' + s;
        }
      }
    }
  }
  async getAllRecordings() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        this.spinner.show();
        var token = session.getIdToken().getJwtToken();
        var users: VariableList[] = [];
        await this.service.getAllRecordings(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                certi_id: element.certi_id,
                createdAt: element.createdAt,
                duration: element.duration,
                event_id: element.event_id,
                id: element.id,
                meeting_id: 'recordings-new/captures/' + element.meeting_id,
                play_list: element.play_list,
                status: element.status,
                title: element.title,
                updatedAt: element.updatedAt,
                recording_url: element.recording_url,
                recording_url_720p: element.recording_url_720p,
                recording_url_480p: element.recording_url_480p,
                recording_url_360p: element.recording_url_360p,
                recording_url_240p: element.recording_url_240p,
                recording_url_144p: element.recording_url_144p,
                questions: element.questions,
              });
            });
            let group = this.groupByArray(users, 'event_id');
            let output: VariableList[] = [];
            for (const key in group) {
              if (group[key].length >= 2) {
                group[key].forEach((element, i) => {
                  element.title = element.title + ' Part-' + parseInt(i + 1);
                  output.push(element);
                });
              } else {
                group[key].forEach((element, i) => {
                  element.title = element.title;
                  output.push(element);
                });
              }
            }
            output.sort(
              (x, y) =>
                new Date(x.createdAt).getTime() -
                new Date(y.createdAt).getTime(),
            );
            users = output;

            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
        await this.spinner.hide();
      }
    });
  }
  groupByArray(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }
  async updateRecordingURL() {
    this.markFormTouched(this.RecordingForm);
    if (this.RecordingForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          let ques = null;
          if (this.Questions[0].FromTime != '') {
            this.Questions[0].Text = this.Questions[0].Text.trim();
            ques = JSON.stringify(this.Questions);
          }
          await this.service
            .updateRecordingURL(
              this.recording_url,
              this.recording_url_720p,
              this.recording_url_480p,
              this.recording_url_360p,
              this.recording_url_240p,
              this.recording_url_144p,
              ques,
              this.recording_id,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.closeModal();
                await this.getAllRecordings();
                await this.spinner.hide();
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
}
export interface VariableList {
  certi_id: string;
  createdAt: string;
  duration: string;
  event_id: string;
  id: string;
  meeting_id: string;
  play_list: string;
  status: string;
  title: string;
  updatedAt: string;
  recording_url: string;
  recording_url_720p: string;
  recording_url_480p: string;
  recording_url_360p: string;
  recording_url_240p: string;
  recording_url_144p: string;
  questions: string;
}
