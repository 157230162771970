import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services.service';
import { AuthorizationService } from '../../service/authorization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gamification',
  templateUrl: './gamification.component.html',
  styleUrls: ['./gamification.component.scss'],
})
export class GamificationComponent implements OnInit {
  know: number = 0;
  review: number = 0;
  bookmark: number = 0;
  notes: number = 0;
  pq_r: number = 0;
  pq_w: number = 0;
  ka_r: number = 0;
  ka_w: number = 0;
  pt_r: number = 0;
  pt_w: number = 0;
  cat_r: number = 0;
  cat_w: number = 0;
  live_r: number = 0;
  live_w: number = 0;
  video_watch: number = 0;
  mindmap_view: number = 0;
  reference_view: number = 0;
  constructor(
    public spinner: NgxSpinnerService,
    public router: Router,
    public service: ServicesService,
    public auth: AuthorizationService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }

  async ngOnInit() {
    await this.spinner.show();
    await this.getAllScores();
    await this.spinner.hide();
  }
  async getAllScores() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getAllScores(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.know = list.data[0].know;
            this.review = list.data[0].review;
            this.bookmark = list.data[0].bookmark;
            this.notes = list.data[0].notes;
            this.pq_r = list.data[0].pq_r;
            this.pq_w = list.data[0].pq_w;
            this.ka_r = list.data[0].ka_r;
            this.ka_w = list.data[0].ka_w;
            this.pt_r = list.data[0].pt_r;
            this.pt_w = list.data[0].pt_w;
            this.cat_r = list.data[0].cat_r;
            this.cat_w = list.data[0].cat_w;
            this.live_r = list.data[0].live_r;
            this.live_w = list.data[0].live_w;
            this.video_watch = list.data[0].video_watch;
            this.mindmap_view = list.data[0].mindmap_view;
            this.reference_view = list.data[0].reference_view;
          }
        });
      }
    });
  }
  async saveScore() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .saveScore(
            this.know,
            this.review,
            this.bookmark,
            this.notes,
            this.pq_r,
            this.pq_w,
            this.ka_r,
            this.ka_w,
            this.pt_r,
            this.pt_w,
            this.cat_r,
            this.cat_w,
            this.live_r,
            this.live_w,
            this.video_watch,
            this.mindmap_view,
            this.reference_view,
            token,
          )
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowSuccess('Gamification save successfully');
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          });
      }
    });
  }
}
