<div class="header">
  <div class="title">
    <mat-icon title="back" (click)="back()">chevron_left</mat-icon
    >{{ MeetingName }}
  </div>
  <div class="control">
    <div class="icons" (click)="startScreenShare()">
      <mat-icon *ngIf="!IsScreenShare">screen_share</mat-icon>
      <span *ngIf="!IsScreenShare">Share Screen</span>
      <mat-icon *ngIf="IsScreenShare" class="unmute"
        >stop_screen_share</mat-icon
      >
      <span *ngIf="IsScreenShare" class="unmute">Stop Sharing</span>
    </div>
    <div class="icons" (click)="IsFullScreen = !IsFullScreen">
      <mat-icon *ngIf="!IsFullScreen">fullscreen</mat-icon>
      <span *ngIf="!IsFullScreen">Full Screen</span>
      <mat-icon *ngIf="IsFullScreen" class="unmute">fullscreen_exit</mat-icon>
      <span *ngIf="IsFullScreen" class="unmute">Exit Full Screen</span>
    </div>
    <div class="icons icons1" (click)="muteAudio()">
      <mat-icon *ngIf="!IsMuted">mic</mat-icon>
      <span *ngIf="!IsMuted">Mute</span>
      <mat-icon *ngIf="IsMuted" class="unmute">mic_off</mat-icon>
      <span *ngIf="IsMuted" class="unmute">Un Mute</span>
    </div>
    <div class="icons icons1" (click)="startStopVideo()">
      <mat-icon *ngIf="!IsStartVideo">videocam</mat-icon>
      <span *ngIf="!IsStartVideo">Start Video</span>
      <mat-icon *ngIf="IsStartVideo" class="unmute">videocam_off</mat-icon>
      <span *ngIf="IsStartVideo" class="unmute">Stop Video</span>
    </div>
    <div class="icons icons1" (click)="changeAudioVideoSource()">
      <span>Audio/Video Source</span>
    </div>
    <div class="icons" (click)="endMeeting()">
      <mat-icon>phone_locked</mat-icon>
      <span>Disconnect</span>
    </div>
    <div class="icons icons1" (click)="startStopVideo()">
      <mat-icon *ngIf="!IsAutoRecord">record_voice_over</mat-icon>
      <span (click)="startRecording()" *ngIf="!IsAutoRecord"
        >Start Recording</span
      >
      <mat-icon *ngIf="IsAutoRecord">voice_over_off</mat-icon>
      <span (click)="stopRecording()" *ngIf="IsAutoRecord">Stop Recording</span>
    </div>
  </div>
  <div class="logo">
    <img src=".././../../assets/images/logo.png" alt="" />
  </div>
</div>
<div class="section">
  <div class="video-section" [ngClass]="{ 'video-section-full': IsFullScreen }">
    <audio id="meeting-audio"></audio>
    <div id="video-list"></div>
  </div>
  <div class="chat-section" [ngClass]="{ 'full-screen': IsFullScreen }">
    <div
      class="drop"
      [ngClass]="{ 'active-drop': ShowAttendees }"
      (click)="ShowAttendees = !ShowAttendees"
    >
      <span>Attendees ({{ AttendeesCount }})</span>
      <mat-icon *ngIf="ShowAttendees" style="color: #a3a0d5"
        >keyboard_arrow_down</mat-icon
      >
      <mat-icon *ngIf="!ShowAttendees" style="color: #a3a0d53d"
        >expand_less</mat-icon
      >
    </div>
    <div class="attenndees" *ngIf="ShowAttendees">
      <div
        class="oflow"
        [ngClass]="{
          'active-attendes': ShowAttendees,
          'attendes-chat': ShowAttendees && ShowChat,
          'attendes-question-open': QuestionsAnswered,
          'question-chat-attendes-open':
            ShowAttendees && ShowChat && QuestionsAnswered
        }"
      >
        <div *ngFor="let item of Attendees; let i = index">
          <div class="chat" *ngIf="item.activeUser || item.presenter">
            <div class="user">
              <div class="image">
                <div class="live">
                  <img
                    [src]="item.avatar_url"
                    onError="this.src='../../../assets/images/no_avatar.jpg'"
                    alt=""
                  />
                  <span
                    [ngClass]="{
                      activeUser: item.activeUser || item.presenter
                    }"
                  ></span>
                </div>
                <span [ngClass]="{ 'presenter-color': item.presenter }">{{
                  item.aliasname
                    ? item.aliasname
                    : item.name
                      ? item.name
                      : item.email
                }}</span>
                <span class="presenter" *ngIf="item.presenter">Presenter</span>
              </div>
              <div class="mute">
                <mat-icon (click)="muteAttendy(item, 'mute')" *ngIf="!item.mute"
                  >mic</mat-icon
                >
                <mat-icon
                  class="off"
                  (click)="muteAttendy(item, 'unmute')"
                  *ngIf="item.mute"
                  >mic_off
                </mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="drop"
      [ngClass]="{ 'active-drop': QuestionsAnswered }"
      (click)="QuestionsAnswered = !QuestionsAnswered"
    >
      <span>Questions Answered</span>
      <mat-icon *ngIf="QuestionsAnswered" style="color: #a3a0d5"
        >keyboard_arrow_down</mat-icon
      >
      <mat-icon *ngIf="!QuestionsAnswered" style="color: #a3a0d53d"
        >expand_less</mat-icon
      >
    </div>
    <div class="questions-answered" *ngIf="QuestionsAnswered">
      <div class="oflow">
        <textarea placeholder="Type here" [(ngModel)]="question"></textarea>
        <button (click)="saveQuestionsAnswered()">{{ saveButtonText }}</button>
      </div>
    </div>
    <div
      class="drop"
      [ngClass]="{ 'active-drop': ShowChat }"
      (click)="ShowChat = !ShowChat"
    >
      <span>Chat</span>
      <mat-icon *ngIf="ShowChat" style="color: #a3a0d5"
        >keyboard_arrow_down</mat-icon
      >
      <mat-icon *ngIf="!ShowChat" style="color: #a3a0d53d"
        >expand_less</mat-icon
      >
    </div>
    <div
      class="oflow"
      [ngClass]="{
        'active-chat': ShowChat,
        'attendes-chat': ShowAttendees && ShowChat,
        'question-chat-open': QuestionsAnswered,
        'question-chat-attendes-open':
          ShowAttendees && ShowChat && QuestionsAnswered
      }"
      *ngIf="ShowChat"
      infinite-scroll
      [infiniteScrollDistance]="100"
      [scrollWindow]="false"
      [infiniteScrollUpDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollTop]="scrollMe.scrollHeight"
      #scrollMe
    >
      <div *ngFor="let item of Messages; let i = index">
        <div class="chat" *ngIf="item.message != ''">
          <div *ngIf="item.message_type != 'log'">
            <div class="user">
              <div class="image">
                <img
                  [src]="item.user.avatar_url"
                  onError="this.src='../../../assets/images/user.jpg'"
                  alt=""
                />
                <span [ngStyle]="{ color: item.color }">{{
                  item.user.aliasname
                    ? item.user.aliasname
                    : item.user.name
                      ? item.user.name
                      : item.user.email
                }}</span>
              </div>
              <span>{{ item.createdAt | date: 'hh:mm a' }}</span>
            </div>
            <div class="message">
              <span [innerHTML]="item.message"></span>
            </div>
          </div>
          <div class="user_logs" *ngIf="item.message_type == 'log'">
            <span [innerHTML]="item.message"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="type">
      <input
        type="text"
        [(ngModel)]="message"
        (keydown.enter)="sendMessage()"
        placeholder="Type a chat message"
      />
      <mat-icon
        (emojiPickerSelect)="onPickedEmoji($event)"
        (click)="toggled = !toggled"
        *ngIf="toggledView"
        [(emojiPickerIf)]="toggled"
        [emojiPickerDirection]="'left'"
        >face_retouching_natural</mat-icon
      >
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="chooseDevicePopup">
  <div class="cutome-modal-content" style="width: 25%">
    <div class="modal-header">
      <p>Select Devices</p>
      <span class="material-icons" (click)="closeDevicePopup()">close</span>
    </div>
    <div class="modal-body" style="min-height: 110px">
      <div class="example-form">
        <div class="formwrapper">
          <div style="width: 100%" *ngIf="audioDevices.length > 0">
            <div class="select-box">
              <select [(ngModel)]="selectedAudio">
                <option value="">Select Audio Device</option>
                <option
                  value="{{ item.deviceId }}"
                  *ngFor="let item of audioDevices"
                >
                  {{ item.label }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
          <div style="width: 100%" *ngIf="videoDevices.length > 0">
            <div class="select-box">
              <select [(ngModel)]="selectedVideo">
                <option value="">Select Video Device</option>
                <option
                  value="{{ item.deviceId }}"
                  *ngFor="let item of videoDevices"
                >
                  {{ item.label }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeDevicePopup()">Cancel</button>
      <button class="submit-btn" (click)="chooseDeviceClick()">Ok</button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
