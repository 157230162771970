<div class="main-addstudent">
  <p>
    Add Student In <span>{{ CohortName }}</span>
  </p>
  <div class="pagesearch">
    <input
      type="search"
      name="search"
      id="SearchTeamName"
      [(ngModel)]="SearchUser"
      class="search"
      placeholder="Search"
      (ngModelChange)="search()"
    />
    <button *ngIf="Userlistdata.length == 0" (click)="AddStudent()">+</button>
  </div>
  <div class="select-cohort">
    <div class="div-body">
      <p>All Students</p>
      <div class="all-select">
        <label class="container-checkbox"
          >Select All
          <input
            type="checkbox"
            (change)="selectall($event)"
            id="selectcheck"
          />
          <span class="checkmark"></span>
        </label>
      </div>

      <label
        class="container-checkbox"
        *ngFor="let item of Userlistdata; let i = index"
        >{{ item.name }}({{ item.email }})
        <input
          type="checkbox"
          id="Addstudnet{{ i }}"
          (change)="UserSelect($event, item)"
          [checked]="item.check"
        />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="div-body">
      <p>Selected Students</p>
      <label
        class="container-checkbox"
        *ngFor="let item of SelectedUsers; let i = index"
        >{{ item.name }}({{ item.email }})
        <input
          type="checkbox"
          id="Addstudnet{{ i }}"
          (change)="UserSelect($event, item)"
          [checked]="item.check"
        />
        <span class="checkmark"></span>
      </label>
      <br />
      <br />
      <br />
      <p *ngIf="SelectedUsers.length <= 0">
        There is no students in this cohort
      </p>
    </div>
  </div>
  <div class="btn-div">
    <button mat-button color="primary" (click)="submit()">Submit</button>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
