import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  //start domain
  async AddDomain(domain_number, domin_name, certi_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          domain_number: domain_number,
          domin_name: domin_name,
          certi_id: certi_id,
          status: '0',
          cource_id: 1,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DOMAIN_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateDomain(domin_name, domain_number, certificate_name, id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          domin_name: domin_name,
          domain_number: domain_number,
          certi_id: certificate_name,
          status: '0',
          cource_id: 1,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.UPDATE_DOMAIN;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteDomain(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DOMAIN + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListDomain(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DOMAIN_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(resolve([{ status: err.status, json: err['_body'] }]));
          console.log(err);
        },
      );
    });
  }
  async IdDomain(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DOMAIN + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End doamin

  //start Sub domain
  async AddSubDomain(
    domain_number,
    subdomain_name,
    d_id,
    certi_id,
    cource_id,
    no_assign_note,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          domain_number: domain_number,
          subdomain_name: subdomain_name,
          d_id: d_id,
          certi_id: certi_id,
          status: '0',
          cource_id: parseInt(cource_id),
          no_assign_note: no_assign_note,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SUB_DOMAIN_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateSubDomain(
    domain_number,
    certi_id,
    subdomain_name,
    d_id,
    id,
    cource_id,
    no_assign_note,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          domain_number: domain_number,
          subdomain_name: subdomain_name,
          d_id: d_id,
          status: '0',
          certi_id: certi_id,
          cource_id: parseInt(cource_id),
          no_assign_note: no_assign_note,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.SUB_DOMAIN + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteSubDomain(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.SUB_DOMAIN + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListSubDomain(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SUB_DOMAIN_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async IdSubDomain(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.SUB_DOMAIN + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End doamin
}
