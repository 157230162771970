import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { AdminService } from '../../services/admin/admin.service';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-adminlist',
  templateUrl: './adminlist.component.html',
  styleUrls: ['./adminlist.component.scss'],
})
export class AdminlistComponent implements OnInit {
  addAdmin: boolean = false;
  public LoginForm: FormGroup;
  email: any;
  IsUpdate: boolean = false;
  password: any;
  Confirmpass: any;
  userEmails = [];
  firstname: any;
  lastname: any;
  Id: any;
  loglist = [];
  oldAuditValueArray = { 'First Name': '', 'Last Name': '', Email: '' };
  newAuditValueArray = { 'First Name': '', 'Last Name': '', Email: '' };
  auditHistory = '';
  displayedColumns: string[] = [
    'SrNo',
    'name',
    'chortname',
    'email',
    'FreeFlashCissp',
    'FreeFlashCCSP',
    'praticeque',
    'actions',
    'UserAnslysis',
  ];
  dataSource: MatTableDataSource<Userlist>;
  Search: any;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  constructor(
    public adminService: AdminService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var isLogin = this.auth.isLoggedIn();
    if (!isLogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewAdmin() {
    this.lastname = '';
    this.firstname = '';
    this.email = '';
    this.addAdmin = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.lastname = '';
    this.firstname = '';
    this.email = '';
    this.addAdmin = false;
    this.IsUpdate = false;
    this.oldAuditValueArray = { 'First Name': '', 'Last Name': '', Email: '' };
    this.newAuditValueArray = { 'First Name': '', 'Last Name': '', Email: '' };
    this.auditHistory = '';
  }
  async ngOnInit() {
    this.LoginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
          ),
        ]),
      ],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
    });
    await this.spinner.show();
    await this.getAllAdmin();
    await this.spinner.hide();
  }
  delete(element) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.auth.deleteuser(element.email).then(async (data) => {
                if (data[0].StatusCode == 1) {
                  await this.adminService
                    .deleteuser(element.id, token)
                    .then(async (userdata) => {
                      if (userdata[0].status == 200) {
                        await this.getAllAdmin();
                        this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              await this.spinner.hide();
                              this.router.navigate(['/login']);
                            } else {
                              var token = session.getIdToken().getJwtToken();
                              var e = window.localStorage.getItem('adminUser');
                              if (e == null || e == undefined || e == '') {
                                e = 'admin';
                              }
                              var desc =
                                '<span> ' +
                                element.email +
                                '</span> is deleted as admin by <span> ' +
                                e +
                                '</span>';
                              await this.service.addAdminLogs(
                                desc,
                                'admin',
                                element.id,
                                token,
                              );
                            }
                          });
                        await this.spinner.hide();
                        Swal.fire(
                          'Deleted!',
                          'User Delete successfully',
                          'success',
                        );
                      } else {
                        await this.spinner.hide();
                        await this.service.ShowError(
                          'User Delete unsuccessfully',
                        );
                      }
                    });
                } else {
                  if (data[0].message == 'User does not exist.') {
                    await this.adminService
                      .deleteuser(element.id, token)
                      .then(async (userdata) => {
                        if (userdata[0].status == 200) {
                          await this.getAllAdmin();
                          await this.spinner.hide();
                          Swal.fire(
                            'Deleted!',
                            'User Delete successfully',
                            'success',
                          );
                        } else {
                          await this.spinner.hide();
                          await this.service.ShowError(
                            'User Delete unsuccessfully',
                          );
                        }
                      });
                  } else {
                    await this.spinner.hide();
                    await this.service.ShowError(data[0].message);
                  }
                }
              });
            }
          });
      }
    });
  }
  async getAllAdmin() {
    try {
      const users: Userlist[] = [];
      this.userEmails = [];
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.adminService.getAllAdmin(token).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                var srno = 0;
                await list.data.forEach(async (element) => {
                  if (element.roll_id == 1) {
                    var chortname = '';
                    if (element.cohort.length > 0) {
                      for (let i = 0; i < element.cohort.length; i++) {
                        chortname =
                          chortname + element.cohort[i].cohort_name + ',';
                      }
                      if (chortname.endsWith(',') == true) {
                        chortname = chortname.substring(
                          0,
                          chortname.length - 1,
                        );
                      }
                    }
                    this.userEmails.push(element.email);
                    srno = srno + 1;
                    await users.push({
                      SrNo: srno,
                      id: element.id,
                      name: element.name,
                      email: element.email,
                      roll_id: element.roll_id,
                      email_verified_at: element.email_verified_at,
                      email_verified: element.email_verified,
                      mobile: element.mobile,
                      profile_img: element.profile_img,
                      permissions: element.permissions,
                      password: element.password,
                      status: element.status,
                      createdAt: element.createdAt,
                      updatedAt: element.updatedAt,
                      free_flashcard: element.free_flashcard,
                      free_flashccsp: element.free_flashccsp,
                      chortname: chortname,
                    });
                  }
                });
                this.dataSource = new MatTableDataSource(users);
                this.dataSource.sort = this.matSort;
                this.dataSource.paginator = this.paginator;
                this.filter();
                this.pagination = true;
              } else {
                this.spinner.hide();
              }
            });
          }
        });
    } catch (e) {
      this.spinner.hide();
    }
  }
  async Checkboxselect(value, element) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        if (value == true) {
          this.adminService
            .free_purchase(element.id, '1', token)
            .then((datapur) => {});
        }
      }
    });
  }
  async CCSPCheckboxselect(value, element) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        if (value == true) {
          this.adminService
            .CCSPfree_purchase(element.id, '1', token)
            .then((datapur) => {});
        }
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async saveAdmin() {
    this.markFormTouched(this.LoginForm);
    if (this.LoginForm.valid) {
      this.firstname = this.firstname.trim();
      this.lastname = this.lastname.trim();
      if (
        this.firstname == '' ||
        this.firstname == undefined ||
        this.firstname == null
      ) {
        this.service.ShowError('First Name is required');
        return;
      }
      if (
        this.lastname == '' ||
        this.lastname == undefined ||
        this.lastname == null
      ) {
        this.service.ShowError('Last Name is required');
        return;
      }
      if (!this.userEmails.includes(this.email)) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.auth.CreateUser(this.email).then(async (value) => {
                if (value[0].StatusCode == 1) {
                  await this.adminService
                    .addUser(
                      this.firstname + ' ' + this.lastname,
                      this.email,
                      '1',
                      'REWerew889##',
                      0,
                      token,
                    )
                    .then(async (data) => {
                      var list = JSON.parse(<string>data[0].json);
                      if (data[0].status == 200) {
                        await this.getAllAdmin();
                        this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              await this.spinner.hide();
                              this.router.navigate(['/login']);
                            } else {
                              var token = session.getIdToken().getJwtToken();
                              var e = window.localStorage.getItem('adminUser');
                              if (e == null || e == undefined || e == '') {
                                e = 'admin';
                              }
                              var desc =
                                '<span> ' +
                                this.email +
                                '</span> is added as admin by <span> ' +
                                e +
                                '</span>';
                              await this.service.addAdminLogs(
                                desc,
                                'admin',
                                list.data.id,
                                token,
                              );
                            }
                          });
                        this.email = '';
                        this.password = '';
                        this.lastname = '';
                        this.Confirmpass = '';
                        this.password = '';
                        this.IsUpdate = false;
                        this.addAdmin = false;
                        this.service.ShowSuccess('Admin added successfully');
                        await this.spinner.hide();
                      } else {
                        await this.spinner.hide();
                      }
                    });
                } else {
                  await this.spinner.hide();
                  this.service.ShowError(value[0].message);
                }
              });
            }
          });
      } else {
        this.service.ShowError('This email already exist.');
      }
    }
  }
  async updateAdmin() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.adminService
          .updateUser(this.Id, this.firstname + ' ' + this.lastname, token)
          .then(async (data) => {
            var list = JSON.parse(<string>data[0].json);
            if (data[0].status == 200) {
              await this.getAllAdmin();
              this.auth
                .getAuthenticatedUser()
                .getSession(async (err, session) => {
                  if (err) {
                    await this.spinner.hide();
                    this.router.navigate(['/login']);
                  } else {
                    var token = session.getIdToken().getJwtToken();
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    this.newAuditValueArray = {
                      'First Name': this.firstname,
                      'Last Name': this.lastname,
                      Email: this.email,
                    };
                    var audit = this.auth.compareAuditHist(
                      this.oldAuditValueArray,
                      this.newAuditValueArray,
                    );
                    var desc = audit;
                    await this.service.addAdminLogs(
                      desc,
                      'admin',
                      this.Id,
                      token,
                    );
                  }
                });
              this.email = '';
              this.password = '';
              this.lastname = '';
              this.Confirmpass = '';
              this.password = '';
              this.IsUpdate = false;
              this.addAdmin = false;
              this.service.ShowSuccess('Admin Updated successfully');
              await this.spinner.hide();
            } else {
              await this.spinner.hide();
            }
          });
      }
    });
  }
  async edit(item) {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('admin', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.addAdmin = true;
    this.IsUpdate = true;
    let name = item.name;
    this.Id = item.id;
    var index = name.indexOf(' ');
    var fname = name.substr(0, index);
    var lname = name.substr(index + 1);
    if (fname == '') {
      fname = lname;
      lname = '';
    }
    this.email = item.email;
    this.firstname = fname;
    this.lastname = lname;
    this.oldAuditValueArray = {
      'First Name': this.firstname,
      'Last Name': this.lastname,
      Email: this.email,
    };
  }
}
export interface Userlist {
  SrNo: number;
  id: string;
  name: string;
  email: string;
  roll_id: string;
  email_verified_at: string;
  email_verified: string;
  mobile: string;
  profile_img: string;
  permissions: string;
  password: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  free_flashcard: string;
  free_flashccsp: string;
  chortname: string;
}
