<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Class Materials</h3>
    </div>
  </div>
  <div class="select-company">
    <div class="select-box">
      <select
        required
        [(ngModel)]="SelectedCertificate"
        name="SelectedCertificate"
        (change)="changeCertificate()"
      >
        <option value="">Select Certification</option>
        <option *ngFor="let items of Certificatelist" value="{{ items.id }}">
          {{ items.certificate_name }}
        </option>
      </select>
      <span></span>
    </div>
  </div>
  <div class="tablewrap">
    <div class="studyguiddata">
      <div class="tableheader">
        <div class="header" style="width: 250px">Domain</div>
        <div class="header" style="width: 250px">Sub Domain</div>
        <div class="header" style="width: 250px">Video</div>
        <div class="header" style="width: 250px">Mindmap</div>
        <div class="header" style="width: 200px">Topic</div>
        <div class="header" style="width: 200px">Refrences</div>
        <div class="header" style="width: 150px">Written Summary</div>
        <div class="header" style="width: 250px">KA Questions</div>
        <div class="header" style="width: 250px">FlashCards</div>
        <div class="header" style="width: 250px">PracticeTest Questions</div>
      </div>
      <div class="tabledata">
        <div class="domaindata" *ngFor="let item of Materials; let i = index">
          <div class="domainname">{{ i + 1 + ' ' }}{{ item.domin_name }}</div>
          <div class="dmdata">
            <div
              class="subdomaindata"
              *ngFor="let subdomain of item.SubDomains; let j = index"
            >
              <div class="subdomainname">
                <span *ngIf="SelectedCertificate == 11"
                  >{{ i + 1 + '.' }}{{ j + 1 + ' ' }}</span
                >
                {{ subdomain.subdomain_name }}
              </div>
              <div class="subdmdata">
                <div class="videonamelist">
                  <div class="videoname" *ngFor="let video of subdomain.Videos">
                    {{ video.video_title }}
                  </div>
                  <div
                    class="videoname red-bg"
                    *ngIf="subdomain.Videos.length <= 0"
                  >
                    No Data
                  </div>
                </div>
                <div class="videonamelist">
                  <div
                    class="videoname"
                    *ngFor="let mind of subdomain.MindMaps"
                  >
                    {{ mind.name }}
                  </div>
                  <div
                    class="videoname red-bg"
                    *ngIf="subdomain.MindMaps.length <= 0"
                  >
                    No Data
                  </div>
                </div>
                <div class="videonamelist topic">
                  <div
                    class="topiclistdata"
                    *ngFor="let topic of subdomain.Topics; let k = index"
                  >
                    <div class="videoname">
                      <span *ngIf="SelectedCertificate == 1"
                        >{{ i + 1 + '.' }}{{ j + 1 + '.'
                        }}{{
                          k +
                            1 +
                            '
                                                '
                        }}</span
                      >
                      {{ topic.name }}
                    </div>
                    <div class="topicdata">
                      <div class="topiclist ref">
                        <div
                          class="topicname"
                          *ngFor="let Ref of topic.Reference"
                        >
                          {{ Ref.title }}
                        </div>
                        <div
                          class="topicname red-bg"
                          *ngIf="topic.Reference.length <= 0"
                        >
                          No Data
                        </div>
                      </div>
                      <div class="topiclist sum">
                        <div
                          class="topicname"
                          [ngClass]="{
                            'red-bg': topic.WrittenSummary == 'None'
                          }"
                        >
                          {{
                            topic.WrittenSummary == 'Yes' ? 'Yes' : 'No Data'
                          }}
                        </div>
                      </div>
                      <div class="topiclist KAque">
                        <div class="topicname" *ngFor="let KAQue of topic.KAQ">
                          {{ KAQue.question }}
                        </div>
                        <div
                          class="topicname red-bg"
                          *ngIf="topic.KAQ.length <= 0"
                        >
                          No Data
                        </div>
                      </div>
                      <div class="topiclist fcard">
                        <div
                          class="topicname"
                          *ngFor="let FCard of topic.FlashCards"
                        >
                          {{ FCard.term }} | {{ FCard.definition }}
                        </div>
                        <div
                          class="topicname red-bg"
                          *ngIf="topic.FlashCards.length <= 0"
                        >
                          No Data
                        </div>
                      </div>
                      <div class="topiclist ptque">
                        <div class="topicname" *ngFor="let PTQue of topic.PTQ">
                          {{ PTQue.title }}
                        </div>
                        <div
                          class="topicname red-bg"
                          *ngIf="topic.PTQ.length <= 0"
                        >
                          No Data
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
