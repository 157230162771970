<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Students</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          matInput
          placeholder="Search by Name, Company"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewStudent()">Add Student</button>
      <button (click)="openBulkUploadModal()">Bulk Upload</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="SrNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.SrNo }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.name }}">{{ element.name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.email }}">{{ element.email }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="company_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.company_name }}">{{
              element.company_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="chortname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cohort</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.chortname ? element.chortname : '-' }}">{{
              element.chortname
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="FreeFlashCissp">
          <th mat-header-cell *matHeaderCellDef class="t-center">
            Free Flash CISSP
          </th>
          <td mat-cell *matCellDef="let element" class="t-center">
            <mat-checkbox
              class="example-margin"
              [checked]="
                element.free_flashcard == '1' || element.free_flashcard == '2'
              "
              (change)="Checkboxselect($event.checked, element)"
              [disabled]="element.free_flashcard == '1'"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="FreeFlashCCSP">
          <th mat-header-cell *matHeaderCellDef class="t-center">
            Free Flash CCSP
          </th>
          <td mat-cell *matCellDef="let element" class="t-center">
            <mat-checkbox
              [checked]="
                element.free_flashccsp == '1' || element.free_flashccsp == '2'
              "
              (change)="CCSPCheckboxselect($event.checked, element)"
              [disabled]="element.free_flashccsp == '1'"
              class="example-margin"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="praticeque">
          <th mat-header-cell *matHeaderCellDef>Practice question</th>
          <td mat-cell *matCellDef="let element" class="t-center">
            <mat-checkbox class="example-margin" color="primary">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element" style="width: 120px">
            <div class="actionInnerDiv">
              <img
                src="../../../assets/images/edit-1.png"
                (click)="edit(element)"
                alt="Edit"
                class="action-icon"
              />
              <mat-icon
                [ngClass]="{ 'disabled-cls': element.links.length <= 0 }"
                class="action-icon"
                (click)="welcomeLinkClick(element.links)"
                >link</mat-icon
              >
              <img
                src="../../../assets/images/remove.png"
                (click)="delete(element)"
                alt="Delete"
                class="action-icon"
              />
              <img
                src="../../../assets/images/Report.png"
                (click)="gotoStudentReport(element)"
                alt="Report"
                class="action-icon"
              />
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      (page)="pageEvents($event)"
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="totalUsers ? totalUsers : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal std_data" *ngIf="addStudent">
  <div class="cutome-modal-content std_dat_model">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add student</p>
      <p *ngIf="IsUpdate">Edit student</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body">
      <div>
        <div class="avatar">
          <img
            [src]="Avatar"
            onError="this.src='../../../assets/images/avatar.png'"
          />
        </div>
        <form class="example-form" [formGroup]="LoginForm">
          <div class="formwrapper">
            <div>
              <div class="input-fill firstname">
                <input
                  formControlName="firstname"
                  [(ngModel)]="firstname"
                  placeholder="Student Name"
                  required
                  [ngClass]="{
                    err:
                      LoginForm.controls['firstname'].hasError('required') &&
                      (LoginForm.controls['firstname'].dirty ||
                        LoginForm.controls['firstname'].touched)
                  }"
                />
                <span
                  ><svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 18.028C4.02919 18.028 0 13.9919 0 9.01398C0 4.03601 4.02919 0 9 0C13.9703 0 18 4.03601 18 9.01398C18 13.9919 13.9703 18.028 9 18.028ZM9 16.9012C10.7353 16.9012 12.3255 16.3215 13.6271 15.3683C13.3982 14.979 12.9842 14.3604 12.3519 13.7272C11.8839 13.2584 11.3158 12.7891 10.8113 12.3982C10.2668 12.7435 9.657 12.9582 9 12.9582C8.343 12.9582 7.73325 12.7435 7.18875 12.3982C6.68419 12.7891 6.1155 13.2584 5.64806 13.7272C5.01525 14.3604 4.60181 14.979 4.37231 15.3683C5.67394 16.3215 7.26412 16.9012 9 16.9012ZM11.8125 8.17004C11.8125 6.14697 10.5531 4.50755 9 4.50755C7.44637 4.50755 6.1875 6.14697 6.1875 8.17004C6.1875 10.1926 7.44694 11.8314 9 11.8314C10.5531 11.8314 11.8125 10.1926 11.8125 8.17004ZM9 1.12675C4.65075 1.12675 1.125 4.65854 1.125 9.01398C1.125 11.241 2.05481 13.2438 3.537 14.6787C3.74344 14.3086 4.1535 13.6624 4.82288 12.9925C5.28075 12.5334 5.83706 12.0703 6.33769 11.6787C5.55863 10.8032 5.0625 9.56157 5.0625 8.17004C5.0625 5.52444 6.82538 3.3808 9 3.3808C11.1746 3.3808 12.9375 5.52444 12.9375 8.17004C12.9375 9.56157 12.4414 10.8032 11.6617 11.6787C12.1624 12.0703 12.7187 12.5334 13.1766 12.9925C13.8459 13.6624 14.256 14.3086 14.4624 14.6787C15.9452 13.2438 16.875 11.241 16.875 9.01398C16.875 4.65854 13.3487 1.12675 9 1.12675Z"
                      fill="#7066AB"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="email">
              <div class="input-fill firstname">
                <input
                  formControlName="alias_name"
                  type="text"
                  [(ngModel)]="alias_name"
                  placeholder="Student Nickname"
                />
                <span
                  ><svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 18.028C4.02919 18.028 0 13.9919 0 9.01398C0 4.03601 4.02919 0 9 0C13.9703 0 18 4.03601 18 9.01398C18 13.9919 13.9703 18.028 9 18.028ZM9 16.9012C10.7353 16.9012 12.3255 16.3215 13.6271 15.3683C13.3982 14.979 12.9842 14.3604 12.3519 13.7272C11.8839 13.2584 11.3158 12.7891 10.8113 12.3982C10.2668 12.7435 9.657 12.9582 9 12.9582C8.343 12.9582 7.73325 12.7435 7.18875 12.3982C6.68419 12.7891 6.1155 13.2584 5.64806 13.7272C5.01525 14.3604 4.60181 14.979 4.37231 15.3683C5.67394 16.3215 7.26412 16.9012 9 16.9012ZM11.8125 8.17004C11.8125 6.14697 10.5531 4.50755 9 4.50755C7.44637 4.50755 6.1875 6.14697 6.1875 8.17004C6.1875 10.1926 7.44694 11.8314 9 11.8314C10.5531 11.8314 11.8125 10.1926 11.8125 8.17004ZM9 1.12675C4.65075 1.12675 1.125 4.65854 1.125 9.01398C1.125 11.241 2.05481 13.2438 3.537 14.6787C3.74344 14.3086 4.1535 13.6624 4.82288 12.9925C5.28075 12.5334 5.83706 12.0703 6.33769 11.6787C5.55863 10.8032 5.0625 9.56157 5.0625 8.17004C5.0625 5.52444 6.82538 3.3808 9 3.3808C11.1746 3.3808 12.9375 5.52444 12.9375 8.17004C12.9375 9.56157 12.4414 10.8032 11.6617 11.6787C12.1624 12.0703 12.7187 12.5334 13.1766 12.9925C13.8459 13.6624 14.256 14.3086 14.4624 14.6787C15.9452 13.2438 16.875 11.241 16.875 9.01398C16.875 4.65854 13.3487 1.12675 9 1.12675Z"
                      fill="#7066AB"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div>
              <div class="input-fill firstname">
                <input
                  formControlName="email"
                  type="text"
                  [disabled]="IsUpdate"
                  [(ngModel)]="email"
                  placeholder="Student Email"
                  required
                  [ngClass]="{
                    err:
                      (LoginForm.controls['email'].hasError('required') ||
                        LoginForm.controls['email'].hasError('pattern')) &&
                      (LoginForm.controls['email'].dirty ||
                        LoginForm.controls['email'].touched)
                  }"
                />
                <span>
                  <span
                    *ngIf="IsUpdate"
                    class="change-user"
                    (click)="changeUserEmailPopup = true"
                    >Change?</span
                  >
                  <svg
                    width="17"
                    height="12"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.789 0H2.21098C0.990338 0 0 1.01062 0 2.25627V9.74373C0 10.9894 0.990338 12 2.21098 12H14.789C16.0097 12 17 10.9894 17 9.74373V2.25627C17 1.01062 16.0097 0 14.789 0ZM2.21098 1.30362H14.789C15.3041 1.30362 15.7225 1.73059 15.7225 2.25627V9.74373C15.7225 10.2694 15.3041 10.6964 14.789 10.6964H2.21098C1.69586 10.6964 1.27746 10.2694 1.27746 9.74373V2.25627C1.27746 1.73059 1.69586 1.30362 2.21098 1.30362ZM14.3683 3.36019C14.1825 3.05416 13.7888 2.95974 13.4889 3.14931L9.9686 5.37403C9.85874 5.40653 9.75708 5.46906 9.67654 5.5586L8.50098 6.3015L7.31492 5.54929C7.23926 5.46843 7.14602 5.41066 7.04536 5.37833L3.53161 3.14988L3.43921 3.1013C3.15534 2.98068 2.81956 3.08413 2.65193 3.35927C2.46567 3.66499 2.55754 4.0669 2.85712 4.25697L5.63005 6.01629L2.85381 7.80182L2.77061 7.8654C2.54026 8.07327 2.48472 8.42669 2.654 8.70078C2.84209 9.00533 3.23649 9.09662 3.53492 8.90468L6.83615 6.78151L8.16275 7.62319L8.25415 7.67125C8.4424 7.75139 8.65944 7.73558 8.83635 7.62375L10.1673 6.78246L13.486 8.90552L13.5783 8.95444C13.8617 9.07614 14.1978 8.97396 14.3665 8.69945C14.5538 8.39444 14.4634 7.99218 14.1645 7.80098L11.377 6.01776L14.1616 4.25754L14.2453 4.19462C14.4772 3.98858 14.5355 3.63562 14.3683 3.36019Z"
                      fill="#7066AB"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div>
              <div class="select-box firstname">
                <select
                  style="width: 100%"
                  [(ngModel)]="SelectedCompany"
                  (change)="changeCompany()"
                  formControlName="SelectedCompany"
                  required
                  [ngClass]="{
                    err:
                      LoginForm.controls['SelectedCompany'].hasError(
                        'required'
                      ) &&
                      (LoginForm.controls['SelectedCompany'].dirty ||
                        LoginForm.controls['SelectedCompany'].touched)
                  }"
                >
                  <option value="">Select Company</option>
                  <option *ngFor="let item of Companies" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
                <span style="right: 15px"></span>
              </div>
            </div>
            <div>
              <div>
                <ng-moment-timezone-picker
                  formControlName="time_zone"
                  customPlaceholderText="Time Zone"
                  [(ngModel)]="time_zone"
                >
                </ng-moment-timezone-picker>
                <span class="public_icon" style="right: 15px">
                  <mat-icon class="maticon_public">public</mat-icon>
                </span>
              </div>
            </div>
            <div>
              <div class="select-box firstname">
                <select
                  style="width: 100%"
                  [(ngModel)]="SelectedMentor"
                  formControlName="SelectedMentor"
                >
                  <option value="">Select Mentor</option>
                  <option *ngFor="let item of Mentors" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
                <span style="right: 15px"></span>
              </div>
            </div>
          </div>
        </form>
        <div class="hr"></div>
        <div>
          <div class="students-list">
            <label class="title std_enroll">Enroll Student in Class</label>
            <div class="formwrapper client-wrapper">
              <div>
                <div class="select-box firstname">
                  <select
                    style="width: 100%"
                    [(ngModel)]="SelectedCertificate"
                    (change)="changeCertificate()"
                  >
                    <option value="">Select Certification</option>
                    <option
                      *ngFor="let item of Certificatelist"
                      value="{{ item.id }}"
                    >
                      {{ item.certificate_name }}
                    </option>
                  </select>
                  <span style="right: 15px"></span>
                </div>
              </div>
              <div *ngIf="SelectedCertificate">
                <div class="select-box firstname">
                  <select
                    style="width: 100%"
                    [attr.disabled]="
                      this.SelectedCertificate == '' ? true : null
                    "
                    (change)="changeLiveOrCohort()"
                    [(ngModel)]="SelectedLiveOrCohort"
                  >
                    <option value="">Select Live Class / Cohort</option>
                    <option value="Live Class">Live Class</option>
                    <option value="Self-paced Class">Self-paced Class</option>
                    <option value="Hybrid Class">Hybrid Class</option>
                  </select>
                  <span style="right: 15px"></span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="students-list"
            *ngIf="
              SelectedCertificate &&
              dataSourceCohort?.data.length > 0 &&
              SelectedCertificate
            "
          >
            <table
              mat-table
              [dataSource]="dataSourceCohort"
              matSort
              #matSort1="matSort"
              class=""
            >
              <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="check-app">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="cohort_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.cohort_name }}">{{
                    element.cohort_name
                  }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="studentCount">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  #Students
                </th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.studentCount }}">{{
                    element.studentCount
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="start_date">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Start Date
                </th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.start_date | date: 'short' }}">{{
                    element.start_date | date: 'dd MMM yyyy'
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                  <img
                    src="../../../assets/images/edit-1.png"
                    alt="Edit"
                    class="action-icon"
                  />
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsCohort"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsCohort"
              ></tr>
            </table>
          </div>
          <div class="students-list" *ngIf="SelectedCertificate">
            <div class="radio">
              <div class="no-course" *ngIf="dataSourceCohort?.data.length <= 0">
                <span
                  ><svg
                    width="12px"
                    height="12px"
                    viewBox="0 0 12 12"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Login-Flow"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Assets-New-Screens"
                        transform="translate(-104.000000, -237.000000)"
                        fill="#a39bde"
                      >
                        <g
                          id="icon-alert"
                          transform="translate(104.000000, 237.000000)"
                        >
                          <path
                            d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M6,1.1 C3.29380473,1.1 1.1,3.29380473 1.1,6 C1.1,8.70619527 3.29380473,10.9 6,10.9 C8.70619527,10.9 10.9,8.70619527 10.9,6 C10.9,3.29380473 8.70619527,1.1 6,1.1 Z M6,2.93081622 C6.27338095,2.93081622 6.50017619,3.13027336 6.54280143,3.39160336 L6.55,3.48081622 L6.55,5.66865795 C6.55,5.97241456 6.30375661,6.21865795 6,6.21865795 C5.72661905,6.21865795 5.49982381,6.01920081 5.45719857,5.75787081 L5.45,5.66865795 L5.45,3.48081622 C5.45,3.17705961 5.69624339,2.93081622 6,2.93081622 Z"
                            id="Combined-Shape"
                            fill-rule="nonzero"
                          ></path>
                          <path
                            d="M6,8.96240597 C6.4418278,8.96240597 6.8,8.60423377 6.8,8.16240597 C6.8,7.72057817 6.4418278,7.36240597 6,7.36240597 C5.5581722,7.36240597 5.2,7.72057817 5.2,8.16240597 C5.2,8.60423377 5.5581722,8.96240597 6,8.96240597 Z"
                            id="Oval"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <p>
                  No live classes / cohorts are schedules for the selected
                  company & certification
                </p>
              </div>
            </div>
          </div>
          <div class="students-list" *ngIf="SelectedCertificate">
            <div class="formwrapper">
              <div class="date-input">
                <mat-form-field appearance="fill">
                  <input
                    matInput
                    [matDatepicker]="picker1"
                    [disabled]="true"
                    placeholder="Expiry Date"
                    [(ngModel)]="ExpiryDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1 [disabled]="false"></mat-datepicker>
                </mat-form-field>
                <span>
                  <img
                    src="../../../assets/images/Calendar.svg"
                    alt="Calendar"
                    class="calendar-icon"
                  />
                </span>
              </div>
            </div>
          </div>
          <button
            class="submit-btn"
            (click)="enrollStudentInClass()"
            *ngIf="SelectedCertificate"
            [disabled]="
              isDisableEnroll ||
              !SelectedCertificate ||
              !ExpiryDate ||
              selection.selected.length <= 0
            "
          >
            Enroll
          </button>

          <div class="hr" *ngIf="IsUpdate"></div>
          <div class="students-list" *ngIf="IsUpdate">
            <label class="title">Enrollments</label>
            <table
              *ngIf="dataSourceSelfPaced?.data.length > 0"
              mat-table
              [dataSource]="dataSourceSelfPaced"
              style="border-spacing: 0 10px"
              class="enroll-student-edit"
            >
              <ng-container matColumnDef="course_name">
                <th mat-header-cell *matHeaderCellDef>Class Name</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.course_name }}">{{
                    element.course_name
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="enrollment_date">
                <th mat-header-cell *matHeaderCellDef>Enrollment</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.enrollment_date | date: 'short' }}">{{
                    element.enrollment_date | date: 'dd MMM yyyy'
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef>Start Date</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.start_date | date: 'short' }}">{{
                    element.start_date | date: 'dd MMM yyyy'
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="expiry_date">
                <th mat-header-cell *matHeaderCellDef>Expiry</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.expiry_date | date: 'short' }}">{{
                    element.expiry_date | date: 'dd MMM yyyy'
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="exam_date">
                <th mat-header-cell *matHeaderCellDef>Exam Date</th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class=""
                    title="{{ element.exam_date }}"
                    [ngClass]="{ 's-examdate': element.exam_date_class == '' }"
                  >
                    {{ element.exam_date | date: 'dd MMM yyyy' }}</span
                  >
                </td>
              </ng-container>
              <ng-container matColumnDef="FreeFlashCissp">
                <th mat-header-cell *matHeaderCellDef>Flashcards</th>
                <td mat-cell *matCellDef="let element" class="t-center">
                  <span class="flashpan"
                    ><a
                      class="flashcard"
                      [ngClass]="{
                        'f-active': element.flashcard_access == '1'
                      }"
                    ></a
                  ></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="praticeque">
                <th mat-header-cell *matHeaderCellDef>Questions</th>
                <td mat-cell *matCellDef="let element" class="t-center">
                  <span class="flashpan"
                    ><a
                      class="flashcard"
                      [ngClass]="{ 'f-active': element.question_access == '1' }"
                    ></a
                  ></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="amount_paid">
                <th mat-header-cell *matHeaderCellDef>Paid</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.amount_paid }}">{{
                    element.amount_paid
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                  <div class="action-iconall">
                    <img
                      src="../../../assets/images/edit-1.png"
                      (click)="openDateModal(row)"
                      alt="Edit"
                      class="action-icon"
                    />
                    <mat-icon class="edit_actions"
                      >more_vert
                      <div class="edit_popup">
                        <span
                          *ngIf="row.amount_paid != 'Enroll by admin'"
                          (click)="gotoPaymentReceipt(row)"
                        >
                          <a>
                            <mat-icon class="popup-icon">receipt</mat-icon
                            >Receipt
                          </a>
                        </span>
                        <span (click)="gotoCohortReport(row)">
                          <a>
                            <img
                              src="../../../assets/images/Report.png"
                              alt="Report"
                              class="action-icon"
                            />Report
                          </a>
                        </span>
                        <span (click)="removeStudentFromEnroll(row)">
                          <a>
                            <img
                              src="../../../assets/images/remove.png"
                              alt="Delete"
                              class="action-icon"
                            />
                            Delete
                          </a>
                        </span>
                        <span (click)="resetProgress(row)">
                          <a>
                            <img
                              src="../../../assets/images/reset.svg"
                              alt="Reset"
                              class="action-icon"
                            />
                            Reset Progress
                          </a>
                        </span>
                      </div>
                    </mat-icon>
                  </div>
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsSelfPaced"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsSelfPaced"
              ></tr>
            </table>
            <div class="radio">
              <div
                class="no-course"
                *ngIf="dataSourceSelfPaced?.data.length <= 0"
              >
                <span
                  ><svg
                    width="12px"
                    height="12px"
                    viewBox="0 0 12 12"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Login-Flow"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Assets-New-Screens"
                        transform="translate(-104.000000, -237.000000)"
                        fill="#a39bde"
                      >
                        <g
                          id="icon-alert"
                          transform="translate(104.000000, 237.000000)"
                        >
                          <path
                            d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M6,1.1 C3.29380473,1.1 1.1,3.29380473 1.1,6 C1.1,8.70619527 3.29380473,10.9 6,10.9 C8.70619527,10.9 10.9,8.70619527 10.9,6 C10.9,3.29380473 8.70619527,1.1 6,1.1 Z M6,2.93081622 C6.27338095,2.93081622 6.50017619,3.13027336 6.54280143,3.39160336 L6.55,3.48081622 L6.55,5.66865795 C6.55,5.97241456 6.30375661,6.21865795 6,6.21865795 C5.72661905,6.21865795 5.49982381,6.01920081 5.45719857,5.75787081 L5.45,5.66865795 L5.45,3.48081622 C5.45,3.17705961 5.69624339,2.93081622 6,2.93081622 Z"
                            id="Combined-Shape"
                            fill-rule="nonzero"
                          ></path>
                          <path
                            d="M6,8.96240597 C6.4418278,8.96240597 6.8,8.60423377 6.8,8.16240597 C6.8,7.72057817 6.4418278,7.36240597 6,7.36240597 C5.5581722,7.36240597 5.2,7.72057817 5.2,8.16240597 C5.2,8.60423377 5.5581722,8.96240597 6,8.96240597 Z"
                            id="Oval"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <p>No Class Enrollments history found</p>
              </div>
            </div>
          </div>
        </div>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <button class="submit-btn" (click)="submit()" *ngIf="!IsUpdate">
        Save
      </button>
      <button class="submit-btn" (click)="update()" *ngIf="IsUpdate">
        Save
      </button>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="isChangeDate">
  <div class="cutome-modal-content" style="width: 25%">
    <div class="modal-header">
      <p>{{ CourseName }}</p>
      <span (click)="closeDateModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <div class="students-list">
          <label class="exp-date">Expiry Date</label>
          <div class="formwrapper">
            <div class="date-input">
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [matDatepicker]="picker2"
                  [disabled]="true"
                  placeholder="Expiry Date"
                  [(ngModel)]="EditExpiryDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2 [disabled]="false"></mat-datepicker>
              </mat-form-field>
              <span>
                <img
                  src="../../../assets/images/Calendar.svg"
                  alt="Calendar"
                  class="calendar-icon"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeDateModal()">Cancel</button>
      <button class="submit-btn" (click)="updateExpiryDate()">Save</button>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="isBulkUpload">
  <div class="cutome-modal-content" style="width: 50%">
    <div class="modal-header">
      <p>Bulk Upload</p>
      <span (click)="closeBulkUploadModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div *ngIf="!is_fileupload">
        <ngx-dropzone
          maxFileSize="100MB"
          accept=".csv,.xlsx"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label>
            <img src="../../../assets/images/Upload.png" alt="" /> Drag & drop
            here (or)
            <span class="browse">Browse</span>
          </ngx-dropzone-label>
          <div *ngFor="let f of files">
            <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
          </div>
        </ngx-dropzone>
        <div class="format" *ngIf="files.length <= 0">
          <span>Allowed Format: xlsx</span>
        </div>
        <div class="format">
          <a (click)="ExportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            Student_sample.xlsx
          </a>
        </div>
      </div>
      <div *ngIf="is_optionFileUpload">
        <div class="format" *ngFor="let f of files">
          <div class="bbulkuploadname">
            <span *ngFor="let t of f">{{ t.name }} </span>
            <div class="progress-bar">
              <div
                class="progress-correct"
                [ngStyle]="{ 'width.%': uploadPercent }"
              ></div>
            </div>

            <mat-icon (click)="onRemove(f)">close</mat-icon>
          </div>
        </div>
        <div class="format">
          <div class="formwrapper student-checkbox bbulkupload">
            <select [(ngModel)]="SelectedCompany" (change)="changeCompany()">
              <option value="">Select Company</option>
              <option *ngFor="let item of Companies" value="{{ item.id }}">
                {{ item.name }}
              </option>
            </select>
            <div>
              <label class="container"
                >Enroll Students in class
                <input
                  type="checkbox"
                  [(ngModel)]="is_bulkuploadCheck"
                  (change)="ChangeBulkupload()"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="format" *ngIf="is_bulkuploadCheck">
          <div class="bbulkupload">
            <select
              [(ngModel)]="SelectedCertificate"
              (change)="changeCertificate()"
            >
              <option value="">Select Certification</option>
              <option
                *ngFor="let item of Certificatelist"
                value="{{ item.id }}"
              >
                {{ item.certificate_name }}
              </option>
            </select>
            <select
              *ngIf="SelectedCertificate"
              [attr.disabled]="this.SelectedCertificate == '' ? true : null"
              (change)="changeLiveOrCohort()"
              [(ngModel)]="SelectedLiveOrCohort"
            >
              <option value="">Select Live Class / Cohort</option>
              <option value="Live Class">Live Class</option>
              <option value="Self-paced Class">Self-paced Class</option>
              <option value="Hybrid Class">Hybrid Class</option>
            </select>
          </div>
          <div
            class="students-list"
            *ngIf="SelectedCertificate && dataSourceCohort?.data.length > 0"
          >
            <table
              mat-table
              [dataSource]="dataSourceCohort"
              matSort
              #matSort1="matSort"
              style="border-spacing: 0 10px"
            >
              <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="check-app">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="cohort_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.cohort_name }}">{{
                    element.cohort_name
                  }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="studentCount">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  #Students
                </th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.studentCount }}">{{
                    element.studentCount
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="start_date">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Start Date
                </th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.start_date | date: 'short' }}">{{
                    element.start_date | date: 'dd MMM yyyy'
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                  <img
                    src="../../../assets/images/edit-1.png"
                    (click)="openDateModal(row)"
                    alt="Edit"
                    class="action-icon"
                  />
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsCohort"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsCohort"
              ></tr>
            </table>
          </div>
          <div class="students-list" *ngIf="SelectedCertificate">
            <div class="radio">
              <div class="no-course" *ngIf="dataSourceCohort?.data.length <= 0">
                <span
                  ><svg
                    width="12px"
                    height="12px"
                    viewBox="0 0 12 12"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Login-Flow"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Assets-New-Screens"
                        transform="translate(-104.000000, -237.000000)"
                        fill="#a39bde"
                      >
                        <g
                          id="icon-alert"
                          transform="translate(104.000000, 237.000000)"
                        >
                          <path
                            d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M6,1.1 C3.29380473,1.1 1.1,3.29380473 1.1,6 C1.1,8.70619527 3.29380473,10.9 6,10.9 C8.70619527,10.9 10.9,8.70619527 10.9,6 C10.9,3.29380473 8.70619527,1.1 6,1.1 Z M6,2.93081622 C6.27338095,2.93081622 6.50017619,3.13027336 6.54280143,3.39160336 L6.55,3.48081622 L6.55,5.66865795 C6.55,5.97241456 6.30375661,6.21865795 6,6.21865795 C5.72661905,6.21865795 5.49982381,6.01920081 5.45719857,5.75787081 L5.45,5.66865795 L5.45,3.48081622 C5.45,3.17705961 5.69624339,2.93081622 6,2.93081622 Z"
                            id="Combined-Shape"
                            fill-rule="nonzero"
                          ></path>
                          <path
                            d="M6,8.96240597 C6.4418278,8.96240597 6.8,8.60423377 6.8,8.16240597 C6.8,7.72057817 6.4418278,7.36240597 6,7.36240597 C5.5581722,7.36240597 5.2,7.72057817 5.2,8.16240597 C5.2,8.60423377 5.5581722,8.96240597 6,8.96240597 Z"
                            id="Oval"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <p>
                  No live classes / cohorts are schedules for the selected
                  company & certification
                </p>
              </div>
            </div>
          </div>
          <div class="students-list" *ngIf="SelectedCertificate">
            <div class="formwrapper">
              <div class="date-input">
                <mat-form-field appearance="fill">
                  <input
                    matInput
                    [matDatepicker]="picker1"
                    [disabled]="true"
                    placeholder="Expiry Date"
                    [(ngModel)]="ExpiryDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1 [disabled]="false"></mat-datepicker>
                </mat-form-field>
                <span>
                  <img
                    src="../../../assets/images/Calendar.svg"
                    alt="Calendar"
                    class="calendar-icon"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="fileRespose">
        <div class="format">
          <div class="mat-elevation-z8">
            <table
              mat-table
              [dataSource]="bulkuploadDataSource"
              matSort
              style="border-spacing: 0 10px"
            >
              <ng-container matColumnDef="SrNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.id }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.name }}">{{ element.name }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">
                  <span title="{{ element.email }}">{{ element.email }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Message
                </th>
                <td mat-cell *matCellDef="let element">
                  <span
                    title="{{ element.message }}"
                    [ngClass]="{
                      success: element.type == 1,
                      'user-already-exits': element.type == 2,
                      failed: element.type != 2 && element.type != 1
                    }"
                    >{{ element.message }}</span
                  >
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsbulkdata"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsbulkdata"
              ></tr>
            </table>
          </div>
        </div>
        <div class="format">
          <p class="format-contant">
            <span>{{ bulktotaluser }}</span> Students in bulk upload file |
            <span>{{ newUser }}</span> New student created |
            <span>{{ cohortadduser }}</span> Students added to class |
            <span>{{ alreadyuser }}</span> Students already existed |
            <span>{{ FaildcohortUser }}</span> Students failed to add to class
            existed
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeBulkUploadModal()">
        Cancel
      </button>
      <button
        class="submit-btn"
        (click)="bulkUpload()"
        [disabled]="selectBulkupload"
      >
        Upload
      </button>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="changeUserEmailPopup">
  <div class="cutome-modal-content" style="width: 25%">
    <div class="modal-header">
      <p>Change Email</p>
      <span (click)="changeUserEmailPopup = false" class="material-icons"
        >close</span
      >
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div class="formwrapper">
        <div class="w-100">
          <div class="input-fill firstname">
            <input
              type="text"
              disabled
              [(ngModel)]="email"
              placeholder="Student Email"
              required
            />
            <span>
              <svg
                width="17"
                height="12"
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.789 0H2.21098C0.990338 0 0 1.01062 0 2.25627V9.74373C0 10.9894 0.990338 12 2.21098 12H14.789C16.0097 12 17 10.9894 17 9.74373V2.25627C17 1.01062 16.0097 0 14.789 0ZM2.21098 1.30362H14.789C15.3041 1.30362 15.7225 1.73059 15.7225 2.25627V9.74373C15.7225 10.2694 15.3041 10.6964 14.789 10.6964H2.21098C1.69586 10.6964 1.27746 10.2694 1.27746 9.74373V2.25627C1.27746 1.73059 1.69586 1.30362 2.21098 1.30362ZM14.3683 3.36019C14.1825 3.05416 13.7888 2.95974 13.4889 3.14931L9.9686 5.37403C9.85874 5.40653 9.75708 5.46906 9.67654 5.5586L8.50098 6.3015L7.31492 5.54929C7.23926 5.46843 7.14602 5.41066 7.04536 5.37833L3.53161 3.14988L3.43921 3.1013C3.15534 2.98068 2.81956 3.08413 2.65193 3.35927C2.46567 3.66499 2.55754 4.0669 2.85712 4.25697L5.63005 6.01629L2.85381 7.80182L2.77061 7.8654C2.54026 8.07327 2.48472 8.42669 2.654 8.70078C2.84209 9.00533 3.23649 9.09662 3.53492 8.90468L6.83615 6.78151L8.16275 7.62319L8.25415 7.67125C8.4424 7.75139 8.65944 7.73558 8.83635 7.62375L10.1673 6.78246L13.486 8.90552L13.5783 8.95444C13.8617 9.07614 14.1978 8.97396 14.3665 8.69945C14.5538 8.39444 14.4634 7.99218 14.1645 7.80098L11.377 6.01776L14.1616 4.25754L14.2453 4.19462C14.4772 3.98858 14.5355 3.63562 14.3683 3.36019Z"
                  fill="#7066AB"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="w-100">
          <form class="example-form" [formGroup]="EmailChangeForm">
            <div class="input-fill firstname">
              <input
                type="text"
                formControlName="changedEmail"
                [(ngModel)]="changedEmail"
                placeholder="New Email"
                required
                [ngClass]="{
                  err:
                    (EmailChangeForm.controls['changedEmail'].hasError(
                      'required'
                    ) ||
                      EmailChangeForm.controls['changedEmail'].hasError(
                        'pattern'
                      )) &&
                    (EmailChangeForm.controls['changedEmail'].dirty ||
                      EmailChangeForm.controls['changedEmail'].touched)
                }"
              />
              <span>
                <svg
                  width="17"
                  height="12"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.789 0H2.21098C0.990338 0 0 1.01062 0 2.25627V9.74373C0 10.9894 0.990338 12 2.21098 12H14.789C16.0097 12 17 10.9894 17 9.74373V2.25627C17 1.01062 16.0097 0 14.789 0ZM2.21098 1.30362H14.789C15.3041 1.30362 15.7225 1.73059 15.7225 2.25627V9.74373C15.7225 10.2694 15.3041 10.6964 14.789 10.6964H2.21098C1.69586 10.6964 1.27746 10.2694 1.27746 9.74373V2.25627C1.27746 1.73059 1.69586 1.30362 2.21098 1.30362ZM14.3683 3.36019C14.1825 3.05416 13.7888 2.95974 13.4889 3.14931L9.9686 5.37403C9.85874 5.40653 9.75708 5.46906 9.67654 5.5586L8.50098 6.3015L7.31492 5.54929C7.23926 5.46843 7.14602 5.41066 7.04536 5.37833L3.53161 3.14988L3.43921 3.1013C3.15534 2.98068 2.81956 3.08413 2.65193 3.35927C2.46567 3.66499 2.55754 4.0669 2.85712 4.25697L5.63005 6.01629L2.85381 7.80182L2.77061 7.8654C2.54026 8.07327 2.48472 8.42669 2.654 8.70078C2.84209 9.00533 3.23649 9.09662 3.53492 8.90468L6.83615 6.78151L8.16275 7.62319L8.25415 7.67125C8.4424 7.75139 8.65944 7.73558 8.83635 7.62375L10.1673 6.78246L13.486 8.90552L13.5783 8.95444C13.8617 9.07614 14.1978 8.97396 14.3665 8.69945C14.5538 8.39444 14.4634 7.99218 14.1645 7.80098L11.377 6.01776L14.1616 4.25754L14.2453 4.19462C14.4772 3.98858 14.5355 3.63562 14.3683 3.36019Z"
                    fill="#7066AB"
                  />
                </svg>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="changeUserEmailPopup = false">
        Cancel
      </button>
      <button class="submit-btn" (click)="changeUserEmail()">Save</button>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="linkPopup">
  <div class="cutome-modal-content" style="width: 25%">
    <div class="modal-header">
      <p>Onboarding Links</p>
      <span (click)="linkPopup = false" class="material-icons">close</span>
    </div>
    <div class="modal-body" style="min-height: 110px">
      <div class="formwrapper">
        <div class="w-100">
          <div *ngFor="let item of selectedStudentLink">
            <div class="flx-cls">
              <span>{{ item.cohort_name }}</span>
              <span title="click here to copy">
                <mat-icon
                  class="action-icon"
                  (click)="copyToClipboard(item.link)"
                  >link</mat-icon
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="linkPopup = false">Cancel</button>
    </div>
  </div>
</div>
