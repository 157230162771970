import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CohortService } from '../../services/cohort/cohort.service';
import { LiveService } from '../../services/live/live.service';

@Component({
  selector: 'app-livesession',
  templateUrl: './livesession.component.html',
  styleUrls: ['./livesession.component.scss'],
})
export class LivesessionComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'testname',
    'questions',
    'actions',
  ];
  dataSource: MatTableDataSource<SessionList>;
  Search: any;
  TestList = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    public liveService: LiveService,
    public cohortService: CohortService,
    public auth: AuthorizationService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {}

  async ngOnInit() {
    await this.getAllLiveSession();
  }
  async getAllLiveSession() {
    const users: SessionList[] = [];
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveService.livetestList(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;

            await list.data.forEach(async (element) => {
              srno = srno + 1;

              await users.push({
                srno: srno,
                id: element.id,
                cohorts: element.cohorts,
                question_ids: element.question_ids,
                questions: element.questions,
                test_name: element.test_name,
                createdAt: element.createdAt.substr(0, 10),
                status: element.status,
                updatedAt: element.updatedAt,
                certificate_name: element.certificate.certificate_name,
                certi_id: element.certi_id,
                question_count: element.questions.length,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
  async editSession(item) {}
  async deleteSession(item) {}
}

export interface SessionList {
  srno: number;
  id: string;
  cohorts: [];
  question_ids: [];
  questions: [];
  test_name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  certificate_name: string;
  certi_id: string;
  question_count: string;
}
