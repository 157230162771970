<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3 (click)="s3Upload()">Certifications</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="openPopup()">Add Certification</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>#</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Certification Name</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addNew">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!EditHideen">Add Certification</p>
      <p *ngIf="EditHideen">Edit Certification</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="CourseForm">
          <div class="formwrapper">
            <div>
              <input
                [(ngModel)]="CertificateName"
                formControlName="CertificateName"
                placeholder="Certification Name"
                required
                [ngClass]="{
                  err:
                    CourseForm.controls['CertificateName'].hasError(
                      'required'
                    ) &&
                    (CourseForm.controls['CertificateName'].dirty ||
                      CourseForm.controls['CertificateName'].touched)
                }"
              />
            </div>
            <div>
              <!-- <label>Short Name</label> -->
              <input
                [(ngModel)]="CourseShort"
                formControlName="CourseShort"
                placeholder="Short Name"
                type="text"
                required
                [ngClass]="{
                  err:
                    CourseForm.controls['CourseShort'].hasError('required') &&
                    (CourseForm.controls['CourseShort'].dirty ||
                      CourseForm.controls['CourseShort'].touched)
                }"
              />
            </div>
          </div>
          <div class="formwrapper">
            <!-- domain code start  -->
            <label>Domain Sequence</label>
            <div class="add-new-event w-100" *ngIf="EditHideen">
              <div class="course-input">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                  <div cdkDrag *ngFor="let item of Domains; let i = index">
                    <div
                      id="select"
                      class="all-domain"
                      style="
                        border: 1px solid #303563;
                        padding: 5px 10px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        cursor: move;
                      "
                    >
                      <div style="display: flex; width: 100%">
                        <div
                          style="width: 90%; display: flex; align-items: center"
                        >
                          <div style="line-height: 0; margin-right: 10px">
                            <img
                              src="../../../assets/images/sidebar.JPG"
                              alt=""
                              style="width: 13px; line-height: 0"
                            />
                          </div>
                          <div
                            class="domain-name"
                            style="
                              display: flex;
                              align-items: center;
                              width: 100%;
                            "
                          >
                            <span style="color: #9891d1; width: 100%">
                              <label for="Domain" style="margin: 0"
                                >Domain {{ item.domin_name }}</label
                              >
                            </span>
                          </div>
                        </div>
                        <div
                          style="
                            width: 10%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                          "
                        >
                          <span
                            (click)="moveup(i)"
                            style="
                              background: #222650;
                              width: 25px;
                              height: 25px;
                              border-radius: 4px;
                              margin-left: 4px;
                            "
                          >
                            <mat-icon
                              style="
                                font-size: 20px;
                                color: #434473;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              arrow_upward</mat-icon
                            >
                          </span>
                          <span
                            (click)="movedown(i)"
                            style="
                              background: #222650;
                              width: 25px;
                              height: 25px;
                              border-radius: 4px;
                              margin-left: 4px;
                            "
                          >
                            <mat-icon
                              style="
                                font-size: 20px;
                                color: #434473;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                              >arrow_downward</mat-icon
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- domain code end -->
          </div>
          <div class="formwrapper">
            <label>Live Class</label>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="live_course_thumbnail"
                formControlName="live_course_thumbnail"
                placeholder="Course thumbnail"
              />
              <span>
                <label for="live_course_thumbnail" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input
                  type="file"
                  class="file"
                  id="live_course_thumbnail"
                  (change)="handleFileSelect($event)"
                />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="live_welcome_thumbnail"
                formControlName="live_welcome_thumbnail"
                placeholder="Course welcome video thumbnail"
              />
              <span>
                <label for="live_welcome_thumbnail" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input
                  type="file"
                  class="file"
                  id="live_welcome_thumbnail"
                  (change)="handleFileSelect($event)"
                />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="live_welcome_video"
                formControlName="live_welcome_video"
                placeholder="Course welcome video"
              />
              <span>
                <label for="live_welcome_video" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input
                  type="file"
                  class="file"
                  id="live_welcome_video"
                  (change)="handleFileSelect($event)"
                />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
          </div>
          <div class="formwrapper">
            <label>Self-paced Class</label>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="self_course_thumbnail"
                formControlName="self_course_thumbnail"
                placeholder="Course thumbnail"
              />
              <span>
                <label for="self_course_thumbnail" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input
                  type="file"
                  class="file"
                  id="self_course_thumbnail"
                  (change)="handleFileSelect($event)"
                />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="self_welcome_thumbnail"
                formControlName="self_welcome_thumbnail"
                placeholder="Course welcome video thumbnail"
              />
              <span>
                <label for="self_welcome_thumbnail" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input
                  type="file"
                  class="file"
                  id="self_welcome_thumbnail"
                  (change)="handleFileSelect($event)"
                />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="self_welcome_video"
                formControlName="self_welcome_video"
                placeholder="Course welcome video"
              />
              <span>
                <label for="self_welcome_video" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input
                  type="file"
                  class="file"
                  id="self_welcome_video"
                  (change)="handleFileSelect($event)"
                />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
          </div>
          <div class="formwrapper">
            <label>Hybrid Class</label>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="hybrid_course_thumbnail"
                formControlName="hybrid_course_thumbnail"
                placeholder="Course thumbnail"
              />
              <span>
                <label for="hybrid_course_thumbnail" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input type="file" class="file" id="hybrid_course_thumbnail" />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="hybrid_welcome_thumbnail"
                formControlName="hybrid_welcome_thumbnail"
                placeholder="Course welcome video thumbnail"
              />
              <span>
                <label for="hybrid_welcome_thumbnail" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input type="file" class="file" id="hybrid_welcome_thumbnail" />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
            <div class="d-flex2">
              <input
                type="text"
                [(ngModel)]="hybrid_welcome_video"
                formControlName="hybrid_welcome_video"
                placeholder="Course welcome video"
              />
              <span>
                <label for="hybrid_welcome_video" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>Select File
                </label>
                <input type="file" class="file" id="hybrid_welcome_video" />
                <mat-icon class="link">link</mat-icon>
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="history-div" *ngIf="EditHideen">
        <h3><span class="material-icons"> history </span>Audit history</h3>
        <div class="audit-table">
          <div class="table-header">
            <div class="date-title"><span>Date & Time</span></div>
            <div class="action-title"><span>Action</span></div>
          </div>
          <div class="main-body-audit">
            <div class="table-body" *ngFor="let log of loglist">
              <div class="date-title">
                <span>{{ log.createdAt | date: 'medium' }}</span>
              </div>
              <div class="action-title">
                <span [innerHTML]="log.description"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!EditHideen">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="EditHideen">
        <button class="submit-btn" (click)="UpdateCertificate()">Save</button>
      </div>
    </div>
  </div>
</div>
