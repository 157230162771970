<section class="practice-table">
  <h1>Practice exam result</h1>
  <div class="tbl-header">
    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <tr>
          <th>practice_name</th>
          <th>correct</th>
          <th>Incorrect</th>
          <th>Pending</th>
          <th>total</th>
        </tr>
      </thead>
    </table>
  </div>
  <div class="tbl-content">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr *ngFor="let item of Newpracticelist">
          <td>{{ item.practice_name }}</td>
          <td>{{ item.currect }}({{ item.CorrectPer }}%)</td>
          <td>{{ item.wrong }}({{ item.InCorrectPer }}%)</td>
          <td>{{ item.panding }}({{ item.PandingPer }}%)</td>
          <td>{{ item.total }}</td>
        </tr>
      </tbody>
      <div class="no-data">No Data found</div>
    </table>
  </div>
</section>
<section class="cat-table">
  <!--for demo wrap-->
  <h1>Knowledge Assessment</h1>
  <div class="tbl-header">
    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <tr>
          <th>Knowledge Assessment name</th>
          <th>Correct</th>
          <th>Incorrect</th>
          <th>Pending</th>
          <th>total</th>
        </tr>
      </thead>
    </table>
  </div>
  <div class="tbl-content">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr *ngFor="let item of knowass">
          <td>{{ item.knowass_name }}</td>
          <td>{{ item.currect }}({{ item.CorrectPer }})</td>
          <td>{{ item.wrong }}({{ item.InCorrectPer }})</td>
          <td>{{ item.panding }}({{ item.PandingPer }})</td>
          <td>{{ item.total }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data">No Data found</div>
  </div>
</section>
<section class="cat-table">
  <h1>CAT exam result</h1>
  <div class="tbl-header">
    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <tr>
          <th>CAT Exam name</th>
          <th>correct</th>
          <th>Incorrect</th>
          <th>Pending</th>
          <th>total</th>
        </tr>
      </thead>
    </table>
  </div>
  <div class="tbl-content">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr *ngFor="let item of practicetestlist">
          <td>{{ item.ketexam_name }}</td>
          <td>{{ item.currect }}({{ item.CorrectPer }})</td>
          <td>{{ item.wrong }}({{ item.InCorrectPer }})</td>
          <td>{{ item.panding }}({{ item.PandingPer }})</td>
          <td>{{ item.total }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data">No Data found</div>
  </div>
</section>

<section class="cat-table">
  <!--for demo wrap-->
  <h1>Flashcard result</h1>
  <div class="tbl-header">
    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <tr>
          <th>Total right</th>
          <th>Total learnmore</th>
          <th>Total mark</th>
          <th>Total card</th>
        </tr>
      </thead>
    </table>
  </div>
  <div class="tbl-content">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr>
          <td>{{ totalright }}</td>
          <td>{{ totalleft }}</td>
          <td>{{ mark }}</td>
          <td>{{ totalcard }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data">No Data found</div>
  </div>
</section>
<section class="cat-table">
  <!--for demo wrap-->
  <h1>Login History</h1>
  <div class="tbl-header">
    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <tr>
          <th>Sr No.</th>
          <th>Ip Address</th>
          <th>Browser</th>
          <th>Login Date</th>
        </tr>
      </thead>
    </table>
  </div>
  <div class="tbl-content">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr *ngFor="let item of LoginHistory; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.ipaddress }}</td>
          <td>{{ item.browser }}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data-found" *ngIf="LoginHistory.length <= 0">
      No login history available
    </div>
  </div>
</section>

<section class="cat-table">
  <!--for demo wrap-->
  <h1>Exam History</h1>
  <div class="tbl-header">
    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <tr>
          <th>Sr No.</th>
          <th>Exam type</th>
          <th>Exam name</th>
          <th>Action</th>
          <th>Date</th>
        </tr>
      </thead>
    </table>
  </div>
  <div class="tbl-content">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr *ngFor="let item of ExamHistoryData; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.test_name }}</td>
          <td>{{ item.action }}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data-found" *ngIf="LoginHistory.length <= 0">
      No login history available
    </div>
  </div>
</section>
<br />
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
