<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Clients</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Name, Email Company"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewClient()">Add Client</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="SrNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.SrNo }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="chortname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Live Class</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.chortname ? element.chortname : '-' }}">{{
              element.chortname ? element.chortname : '-'
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.name }}">{{ element.name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.email }}">{{ element.email }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.mobile }}">{{ element.mobile }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="company_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.company_name }}">{{
              element.company_name
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="add_update_cohort">
          <th mat-header-cell *matHeaderCellDef>Add / Update Live Class</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon (click)="ShowPop(element)">add_circle_outline</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(element)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(element)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      (page)="pageEvents($event)"
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
  <div class="cutome-modal" *ngIf="PopUpshow">
    <div class="cutome-modal-content">
      <div class="cutome-container">
        <div class="modal-header">
          <p>Add / Update Live Class</p>
          <span class="material-icons close-right" (click)="PopUpshow = false"
            >close</span
          >
        </div>
        <div class="modal-body">
          <div class="cohortwrap" *ngFor="let item of listCohortData">
            <div class="cohortname">
              {{ item.cohort_name }}
            </div>
            <div class="cohortchk">
              <input
                type="checkbox"
                (change)="checkchange($event, item)"
                [checked]="item.checked"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="check-reset">
            <button type="button" class="submit-btn" (click)="checkall(true)">
              Check All
            </button>
            <button type="button" class="submit-btn" (click)="checkall(false)">
              Reset All
            </button>
          </div>
          <div class="cancel-send">
            <button
              type="button"
              class="submit-btn"
              (click)="PopUpshow = false"
            >
              Cancel
            </button>
            <button
              class="mr-0"
              type="button"
              class="submit-btn"
              (click)="submit()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addClient">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Client</p>
      <p *ngIf="IsUpdate">Edit Client</p>

      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="LoginForm">
          <div class="formwrapper">
            <div>
              <div class="select-box">
                <select
                  [(ngModel)]="SelectedCompany"
                  formControlName="SelectedCompany"
                  required
                  [ngClass]="{
                    err:
                      LoginForm.controls['SelectedCompany'].hasError(
                        'required'
                      ) &&
                      (LoginForm.controls['SelectedCompany'].dirty ||
                        LoginForm.controls['SelectedCompany'].touched)
                  }"
                >
                  <option value="">Select Company</option>
                  <option *ngFor="let item of Companies" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <div class="input-fill">
                <input
                  placeholder="Client Name"
                  required
                  (focusout)="ClientNameFocus = false"
                  (focus)="ClientNameFocus = true"
                  formControlName="ClientName"
                  [(ngModel)]="ClientName"
                  [ngClass]="{
                    err:
                      LoginForm.controls['ClientName'].hasError('required') &&
                      (LoginForm.controls['ClientName'].dirty ||
                        LoginForm.controls['ClientName'].touched)
                  }"
                />
                <span [ngClass]="{ 'active-icon-fill': ClientNameFocus }">
                  <svg
                    fill="#7a72b8"
                    width="21"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469-68.382812 0-132.667969 26.628906-181.019531 74.980469-48.351563 48.351562-74.980469 112.636719-74.980469 181.019531 0 68.378906 26.628906 132.667969 74.980469 181.019531 48.351562 48.351563 112.636719 74.980469 181.019531 74.980469 68.378906 0 132.667969-26.628906 181.019531-74.980469 48.351563-48.351562 74.980469-112.640625 74.980469-181.019531 0-68.382812-26.628906-132.667969-74.980469-181.019531zm-308.679687 367.40625c10.707031-61.648438 64.128906-107.121094 127.660156-107.121094 63.535156 0 116.953125 45.472656 127.660156 107.121094-36.347656 24.972656-80.324218 39.613281-127.660156 39.613281s-91.3125-14.640625-127.660156-39.613281zm46.261718-218.519531c0-44.886719 36.515626-81.398438 81.398438-81.398438s81.398438 36.515625 81.398438 81.398438c0 44.882812-36.515626 81.398437-81.398438 81.398437s-81.398438-36.515625-81.398438-81.398437zm235.042969 197.710937c-8.074219-28.699219-24.109375-54.738281-46.585937-75.078125-13.789063-12.480469-29.484375-22.328125-46.359375-29.269531 30.5-19.894531 50.703125-54.3125 50.703125-93.363281 0-61.425782-49.976563-111.398438-111.402344-111.398438s-111.398438 49.972656-111.398438 111.398438c0 39.050781 20.203126 73.46875 50.699219 93.363281-16.871093 6.941406-32.570312 16.785156-46.359375 29.265625-22.472656 20.339844-38.511718 46.378906-46.585937 75.078125-44.472657-41.300781-72.355469-100.238281-72.355469-165.574219 0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 65.339844-27.882812 124.277344-72.355469 165.578125zm0 0"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div>
              <div class="input-fill">
                <input
                  placeholder="Client Email"
                  [attr.disabled]="IsUpdate == true ? true : null"
                  required
                  (focusout)="EmailFocus = false"
                  (focus)="EmailFocus = true"
                  formControlName="email"
                  [(ngModel)]="email"
                  [ngClass]="{
                    err:
                      LoginForm.controls['email'].hasError('required') &&
                      (LoginForm.controls['email'].dirty ||
                        LoginForm.controls['email'].touched)
                  }"
                />
                <span [ngClass]="{ 'active-icon': EmailFocus }">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="maill"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.789 0H2.21098C0.990338 0 0 1.01062 0 2.25627V9.74373C0 10.9894 0.990338 12 2.21098 12H14.789C16.0097 12 17 10.9894 17 9.74373V2.25627C17 1.01062 16.0097 0 14.789 0ZM2.21098 1.30362H14.789C15.3041 1.30362 15.7225 1.73059 15.7225 2.25627V9.74373C15.7225 10.2694 15.3041 10.6964 14.789 10.6964H2.21098C1.69586 10.6964 1.27746 10.2694 1.27746 9.74373V2.25627C1.27746 1.73059 1.69586 1.30362 2.21098 1.30362ZM14.3683 3.36019C14.1825 3.05416 13.7888 2.95974 13.4889 3.14931L9.9686 5.37403C9.85874 5.40653 9.75708 5.46906 9.67654 5.5586L8.50098 6.3015L7.31492 5.54929C7.23926 5.46843 7.14602 5.41066 7.04536 5.37833L3.53161 3.14988L3.43921 3.1013C3.15534 2.98068 2.81956 3.08413 2.65193 3.35927C2.46567 3.66499 2.55754 4.0669 2.85712 4.25697L5.63005 6.01629L2.85381 7.80182L2.77061 7.8654C2.54026 8.07327 2.48472 8.42669 2.654 8.70078C2.84209 9.00533 3.23649 9.09662 3.53492 8.90468L6.83615 6.78151L8.16275 7.62319L8.25415 7.67125C8.4424 7.75139 8.65944 7.73558 8.83635 7.62375L10.1673 6.78246L13.486 8.90552L13.5783 8.95444C13.8617 9.07614 14.1978 8.97396 14.3665 8.69945C14.5538 8.39444 14.4634 7.99218 14.1645 7.80098L11.377 6.01776L14.1616 4.25754L14.2453 4.19462C14.4772 3.98858 14.5355 3.63562 14.3683 3.36019Z"
                      fill="#7066AB"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <div class="input-fill">
                <input
                  type="number"
                  placeholder="Client Phone Number"
                  (focusout)="PhoneFocus = false"
                  (focus)="PhoneFocus = true"
                  [(ngModel)]="ContactNumber"
                  formControlName="ContactNumber"
                  [ngClass]="{
                    err:
                      LoginForm.controls['ContactNumber'].hasError(
                        'required'
                      ) &&
                      (LoginForm.controls['ContactNumber'].dirty ||
                        LoginForm.controls['ContactNumber'].touched)
                  }"
                />
                <span [ngClass]="{ 'active-icon': PhoneFocus }">
                  <svg
                    version="1.1"
                    baseProfile="basic"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="21px"
                    height="21px"
                    viewBox="0 0 90 100"
                    xml:space="preserve"
                    stroke="#766daa"
                    fill="none"
                    stroke-width="5px"
                  >
                    <path
                      d="M38.789,51.211l10.876,10.876c0.974,0.974,2.471,1.194,3.684,0.543l13.034-6.997c0.964-0.518,2.129-0.493,3.07,0.066
                                    	l19.017,11.285c1.357,0.805,1.903,2.489,1.268,3.933c-1.625,3.698-4.583,10.476-5.758,13.473c-0.247,0.631-0.615,1.209-1.127,1.652
                                    	c-12.674,10.986-37.89-2.4-57.191-21.701C6.358,45.039-7.028,19.823,3.958,7.149c0.444-0.512,1.022-0.88,1.652-1.127
                                    	c2.996-1.175,9.775-4.133,13.473-5.758c1.444-0.635,3.128-0.089,3.933,1.268l11.285,19.017c0.558,0.941,0.583,2.106,0.066,3.07
                                    	L27.37,36.651c-0.651,1.213-0.431,2.71,0.543,3.684C27.913,40.335,38.789,51.211,38.789,51.211z"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="w-100">
              <textarea
                placeholder="Client Address"
                name="Address"
                [(ngModel)]="Address"
                formControlName="Address"
                [ngClass]="{
                  err:
                    LoginForm.controls['Address'].hasError('required') &&
                    (LoginForm.controls['Address'].dirty ||
                      LoginForm.controls['Address'].touched)
                }"
              ></textarea>
            </div>
            <div class="w-100">
              <textarea
                placeholder="Client Notes"
                name="Notes"
                [(ngModel)]="Notes"
                formControlName="Notes"
                placeholder="Enter Notes"
                [ngClass]="{
                  err:
                    LoginForm.controls['Notes'].hasError('required') &&
                    (LoginForm.controls['Notes'].dirty ||
                      LoginForm.controls['Notes'].touched)
                }"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <button *ngIf="!IsUpdate" class="submit-btn" (click)="saveClient()">
        Save
      </button>
      <button *ngIf="IsUpdate" class="submit-btn" (click)="updateClient()">
        Save
      </button>
    </div>
  </div>
</div>
