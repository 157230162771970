<div class="pagemain">
  <div class="pagetitle">
    <h3 *ngIf="!IsUpdate">
      <span class="material-icons" title="Back" routerLink="/main/livequestion"
        >arrow_back_ios</span
      >
      Add Question In {{ TestName }}
    </h3>
    <h3 *ngIf="IsUpdate">
      <span class="material-icons" title="Back" routerLink="/main/livequestion"
        >arrow_back_ios</span
      >Update Question In
      {{ TestName }}
    </h3>
    <button class="submit-btn" (click)="gotoTeacherScreen()">View Test</button>
  </div>
  <div>
    <form novalidate [formGroup]="LiveQueTestForm">
      <div class="formwrapper">
        <div>
          <label>Question</label>
          <input
            [(ngModel)]="Question"
            formControlName="Question"
            placeholder="Enter Question"
            type="text"
            name="Question"
            required
          />
        </div>
        <div>
          <label>Option A</label>
          <input
            [(ngModel)]="OptionA"
            formControlName="OptionA"
            placeholder="Enter Option A"
            type="text"
            name="OptionA"
            required
          />
        </div>
        <div>
          <label>Option B</label>
          <input
            [(ngModel)]="OptionB"
            formControlName="OptionB"
            placeholder="Enter Option B"
            type="text"
            name="OptionB"
            required
          />
        </div>
        <div>
          <label>Option C</label>
          <input
            [(ngModel)]="OptionC"
            formControlName="OptionC"
            placeholder="Enter Option C"
            type="text"
            name="OptionC"
            required
          />
        </div>
        <div>
          <label>Option D</label>
          <input
            [(ngModel)]="OptionD"
            formControlName="OptionD"
            placeholder="Enter Option D"
            type="text"
            name="OptionD"
            required
          />
        </div>
        <div>
          <label>Correct Answer</label>
          <div class="select-box">
            <select
              [(ngModel)]="CorrectAnswer"
              formControlName="CorrectAnswer"
              matNativeControl
              required
            >
              <option selected="true" value="">Select</option>
              <option value="1">Option A</option>
              <option value="2">Option B</option>
              <option value="3">Option C</option>
              <option value="4">Option D</option>
            </select>
            <span></span>
          </div>
        </div>
        <div>
          <label>Description</label>
          <input
            [(ngModel)]="Description"
            formControlName="Description"
            placeholder="Enter Description"
            type="text"
            name="Description"
            required
          />
        </div>
      </div>
      <div *ngIf="!IsUpdate">
        <button
          [disabled]="!LiveQueTestForm.valid"
          type="button"
          class="submit-btn"
          (click)="addLiveQuestion()"
        >
          Save
        </button>
      </div>
      <div *ngIf="IsUpdate">
        <button
          [disabled]="!LiveQueTestForm.valid"
          (click)="updateLiveQuestion()"
          type="button"
          class="submit-btn"
        >
          Update
        </button>
        <button
          [disabled]="!LiveQueTestForm.valid"
          (click)="cancelUpdate()"
          type="button"
          class="submit-btn"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
  <mat-form-field class="example-full-width inputsearch">
    <mat-label>Search</mat-label>
    <input
      matInput
      [(ngModel)]="Search"
      autocomplete="off"
      (keyup)="filter()"
      placeholder="Search.."
      name="search"
    />
  </mat-form-field>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">{{ row.srno }}</td>
        </ng-container>
        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Question</th>
          <td mat-cell *matCellDef="let row">{{ row.question }}</td>
        </ng-container>
        <ng-container matColumnDef="first_choice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Option A</th>
          <td mat-cell *matCellDef="let row">{{ row.first_choice }}</td>
        </ng-container>
        <ng-container matColumnDef="second_choice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Option B</th>
          <td mat-cell *matCellDef="let row">{{ row.second_choice }}</td>
        </ng-container>
        <ng-container matColumnDef="third_choice">
          <th mat-header-cell *matHeaderCellDef>Option C</th>
          <td mat-cell *matCellDef="let row">{{ row.third_choice }}</td>
        </ng-container>
        <ng-container matColumnDef="fourth_choice">
          <th mat-header-cell *matHeaderCellDef>Option D</th>
          <td mat-cell *matCellDef="let row">{{ row.fourth_choice }}</td>
        </ng-container>
        <ng-container matColumnDef="answer">
          <th mat-header-cell *matHeaderCellDef>Answer</th>
          <td mat-cell *matCellDef="let row">{{ row.answer }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let row">{{ row.description }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon (click)="editQuestion(row)">edit</mat-icon>
            <mat-icon (click)="deleteQuestion(row)">delete</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[25, 50, 100, 500]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
