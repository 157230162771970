import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  async AddCompany(name, address, notes, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          address: address,
          notes: notes,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COMPANY_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async UpdateCompany(id, name, address, notes, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          name: name,
          address: address,
          notes: notes,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COMPANY_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteCompany(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COMPANY_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetAllCompany(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');

      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COMPANY_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
