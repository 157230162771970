import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../service/authorization.service';
import { ServicesService } from '../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CryptoService } from '../services/crypto/crypto.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent implements OnInit {
  email: any;
  Password: any;
  constructor(
    public router: Router,
    public spinner: NgxSpinnerService,
    public auth: AuthorizationService,
    public service: ServicesService,
    private crypto: CryptoService,
  ) {}

  ngOnInit() {}
  async login() {
    let pattern = new RegExp(
      '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
    );
    if (pattern.test(this.email)) {
      this.spinner.show();
      await this.service
        .versions_getUserByEmail(this.email)
        .then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            if (list.data.length > 0) {
              if (list.data[0].roll_id != 1 && list.data[0].roll_id != 5) {
                this.service.ShowError(
                  'You are not allowed to access this portal. Kindly contact customer support.',
                );
                this.spinner.hide();
                return;
              }
              if (list.data[0].expire_password == 1) {
                var userdata = {
                  email: list.data[0].email,
                };
                var datas = this.crypto.enc(JSON.stringify(userdata));
                this.router.navigate([`/pages/forcechangepassword/${datas}`]);
                return;
              }
            }
            if (list.data.length > 0) {
              await this.auth
                .forgotPassword(list.data[0].email)
                .then(async (data) => {
                  if (data[0].StatusCode == 1) {
                    let user = this.email;
                    let msg = data[0].message;
                    let cohort = 'Reset Password';
                    let email_type = 'Reset Password';
                    await this.addFailedEmail(user, cohort, msg, email_type, 1);
                    await this.addActivityTracker(
                      0,
                      this.email,
                      0,
                      'Forgot Password',
                      'Forgot Password - Forgot password code sent successfully',
                    );
                    this.spinner.hide();

                    var datas = btoa(JSON.stringify(this.email));
                    this.router.navigate([`/resetpassauth/${datas}`]);
                  } else {
                    if (
                      data[0].message ==
                      'Username/client id combination not found.'
                    ) {
                      let user = this.email;
                      let msg = 'No user found';
                      let cohort = 'Reset Password';
                      let email_type = 'Reset Password';
                      await this.addFailedEmail(
                        user,
                        cohort,
                        msg,
                        email_type,
                        0,
                      );
                      this.spinner.hide();

                      this.service.ShowError('No user found');
                    } else if (
                      data[0].message ==
                      'User password cannot be reset in the current state.'
                    ) {
                      let user = this.email;
                      let msg =
                        'You have not completed the onboarding flow. Please check email for temp password.';
                      let cohort = 'Reset Password';
                      let email_type = 'Reset Password';
                      await this.addFailedEmail(
                        user,
                        cohort,
                        msg,
                        email_type,
                        0,
                      );
                      await this.addActivityTracker(
                        0,
                        this.email,
                        0,
                        'Forgot Password',
                        'Forgot Password - ' + msg,
                      );
                      this.spinner.hide();

                      this.service.ShowError(
                        'You have not completed the onboarding flow. Please check email for temp password.',
                      );
                    } else {
                      let user = this.email;
                      let msg = data[0].message;
                      let cohort = 'Reset Password failed';
                      let email_type = 'Reset Password';
                      await this.addFailedEmail(
                        user,
                        cohort,
                        msg,
                        email_type,
                        0,
                      );
                      await this.addActivityTracker(
                        0,
                        this.email,
                        0,
                        'Forgot Password',
                        'Forgot Password - ' + msg,
                      );
                      this.spinner.hide();

                      this.service.ShowError(data[0].message);
                    }
                  }
                });
            } else {
              this.spinner.hide();
              this.service.ShowError('No user found');
            }
          }
        });
    } else {
      this.service.ShowError('email provided is invalid');
    }
  }

  async addFailedEmail(user, cohort, msg, email_type, mail_success) {
    await this.service
      .addFailedEmail(user, cohort, msg, email_type, mail_success, '')
      .then(async (data) => {});
  }
  async addActivityTracker(user_id, email, cohort_id, type, message) {
    await this.service.addActivityTracker(
      user_id,
      email,
      cohort_id,
      type,
      message,
    );
  }
}
