<div class="pagemain">
  <div class="pagetitle">
    <h3 [hidden]="!EditHideen">Add Live Question Test</h3>
    <h3 [hidden]="EditHideen">Update Live Question Test</h3>
  </div>
  <div>
    <form novalidate [formGroup]="LiveQueTestForm">
      <div class="formwrapper">
        <div>
          <label>Test name</label>
          <input
            [(ngModel)]="TestName"
            formControlName="TestName"
            placeholder="Enter Test Name"
            type="text"
            name="TestName"
            required
          />
        </div>
        <div class="sub-cohort-div">
          <div class="cohort-input">
            <label>Live Class</label>
            <ng-select
              [items]="items"
              formControlName="Cohort"
              bindLabel="cohort_name"
              bindValue="id"
              placeholder="Select Live Class"
              appendTo="body"
              multiple="true"
              [(ngModel)]="selecteds"
            >
            </ng-select>
          </div>
          <div class="cohort-btn">
            <div [hidden]="!EditHideen">
              <button
                [disabled]="!LiveQueTestForm.valid"
                (click)="submit()"
                type="button"
                class="submit-btn"
              >
                Submit
              </button>
            </div>
            <div [hidden]="EditHideen">
              <button
                [disabled]="!LiveQueTestForm.valid"
                (click)="update()"
                type="button"
                class="submit-btn"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="pagesection">
      <div class="mat-elevation-z8">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          style="border-spacing: 0 10px"
        >
          <ng-container matColumnDef="srno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
            <td mat-cell *matCellDef="let row">
              <span>{{ row.srno }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="testname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Name</th>
            <td mat-cell *matCellDef="let row">
              <span title="{{ row.test_name }}">{{ row.test_name }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="cohort">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Live Class
            </th>
            <td mat-cell *matCellDef="let row">
              <span title="{{ row.cohort_name }}">{{ row.cohort_name }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">
              <span title="{{ row.createdAt }}">{{ row.createdAt }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="addquestion">
            <th mat-header-cell *matHeaderCellDef>Add/View Question</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon (click)="addLiveQuestion(row)"
                >add_circle_outline</mat-icon
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="viewresult">
            <th mat-header-cell *matHeaderCellDef>View Result</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon (click)="viewResult(row)">visibility</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <img
                src="../../../assets/images/edit-1.png"
                (click)="editTest(row)"
                alt="Edit"
                class="action-icon"
              />
              <img
                src="../../../assets/images/remove.png"
                (click)="deleteTest(row)"
                alt="Delete"
                class="action-icon"
              />
              <mat-icon (click)="openSendMailPopup(row)" title="Send link"
                >email</mat-icon
              >
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      <mat-paginator
        style-paginator
        showFirstLastButtons
        [showTotalPages]="3"
        *ngIf="pagination"
        [length]="dataSource.data ? dataSource.data.length : 0"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50, 100, 500]"
      >
      </mat-paginator>
    </div>
  </div>
  <div class="cutome-modal" *ngIf="SendMail">
    <div class="cutome-modal-content">
      <div class="cutome-container">
        <div class="modal-header">
          <p>Student Link Generator</p>
          <span class="material-icons" (click)="closeSendMailPopup()"
            >close</span
          >
        </div>
        <div class="modal-body">
          <div class="projectorlink">
            <div class="name">Projector Link</div>
            <div class="link">
              {{ ProjectorLink }}
            </div>
          </div>
          <div class="cohortwrap" *ngFor="let item of MailCohorts">
            <div class="cohortname">
              {{ item.cohort_name }}
            </div>
            <div class="cohortchk">
              <input
                type="checkbox"
                (click)="cohortChkClick(item)"
                [checked]="item.checked"
                value="item.id"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="check-reset">
            <button
              type="button"
              class="submit-btn"
              (click)="checkedAllCohort()"
            >
              Check All
            </button>
            <button type="button" class="submit-btn" (click)="resetAllCohort()">
              Reset All
            </button>
          </div>
          <div class="cancel-send">
            <button
              type="button"
              class="submit-btn"
              (click)="closeSendMailPopup()"
            >
              Cancel
            </button>
            <button
              class="mr-0"
              type="button"
              class="submit-btn"
              (click)="sendMail()"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
