<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Email Templates</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Template/Subject Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewsubTopic()">Add Email Templates</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.srno }}">{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certifications
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Template Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.name }}">{{ row.name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Email Subject
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subject }}">{{ row.subject }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="editEmailTemplate(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              *ngIf="row.id != 3"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addsubTopic">
  <div class="cutome-modal-content" style="width: 60%">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Email Templates</p>
      <p *ngIf="IsUpdate">Edit Email Templates</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="VariableForm">
          <div class="formwrapper">
            <div>
              <div class="select-box">
                <select
                  required
                  formControlName="certi_id"
                  [(ngModel)]="certi_id"
                  [ngClass]="{
                    err:
                      VariableForm.controls['certi_id'].hasError('required') &&
                      (VariableForm.controls['certi_id'].dirty ||
                        VariableForm.controls['certi_id'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let items of ListCerficateData"
                    value="{{ items.id }}"
                  >
                    {{ items.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <input
                required
                formControlName="name"
                [(ngModel)]="name"
                [ngClass]="{
                  err:
                    VariableForm.controls['name'].hasError('required') &&
                    (VariableForm.controls['name'].dirty ||
                      VariableForm.controls['name'].touched)
                }"
                placeholder="Template Name"
              />
            </div>

            <div>
              <input
                required
                formControlName="subject"
                [(ngModel)]="subject"
                [ngClass]="{
                  err:
                    VariableForm.controls['subject'].hasError('required') &&
                    (VariableForm.controls['subject'].dirty ||
                      VariableForm.controls['subject'].touched)
                }"
                placeholder="Email Subject"
              />
            </div>
            <div>
              <textarea
                [(ngModel)]="html_source"
                formControlName="html_source"
                placeholder="Html code"
                type="text"
                name="html_source"
              >
              </textarea>
            </div>
          </div>
          <div>
            <div class="send-mails">
              <label class="Ck-Editor-span">Email Message</label>
              <div class="select-box">
                <select [(ngModel)]="variable" formControlName="variable">
                  <option value="">Insert Variable</option>
                  <option
                    *ngFor="let items of Variables"
                    value="{{ items.id }}"
                  >
                    {{ items.name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
          </div>
          <div>
            <div class="ckdiv">
              <ckeditor
                editorUrl="../../../assets/ckeditor/ckeditor.js"
                [(ngModel)]="message"
                formControlName="message"
                [config]="ckeConfig"
              >
              </ckeditor>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="saveEmailTemplate()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="updateEmailTemplate()">
          Update
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
