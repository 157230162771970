<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Mini MasterClasses</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search.."
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewClass()">Add Mini MasterClass</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="class_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.class_name }}">{{ row.class_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Certificate</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="enrollments">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            # Enrollments
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.enrollments }}">{{ row.enrollments }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="editMiniMasterClass(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="deleteMiniMasterClass(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addClass">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Mini MasterClass</p>
      <p *ngIf="IsUpdate">Edit Mini MasterClass</p>
      <span class="material-icons" (click)="closeModal()">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="miniMasterForm">
          <div class="formwrapper">
            <div class="formwrapper-mini-class">
              <label>Mini MasterClass display name</label>
              <input
                [(ngModel)]="class_name"
                formControlName="class_name"
                placeholder="Enter Mini MasterClass name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class_name'].dirty ||
                      miniMasterForm.controls['class_name'].touched)
                }"
              />
            </div>
            <div class="formwrapper-mini-class">
              <label>Class URL</label>
              <input
                [(ngModel)]="class_url"
                disabled
                formControlName="class_url"
                placeholder="Enter Class URL"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class_url'].hasError('required') &&
                    (miniMasterForm.controls['class_url'].dirty ||
                      miniMasterForm.controls['class_url'].touched)
                }"
              />
            </div>
            <div class="select-box w-100 mt-25">
              <select
                [(ngModel)]="certi_id"
                formControlName="certi_id"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['certi_id'].hasError('required') &&
                    (miniMasterForm.controls['certi_id'].dirty ||
                      miniMasterForm.controls['certi_id'].touched)
                }"
              >
                <option value="">Select Certification</option>
                <option
                  *ngFor="let item of allCertificate"
                  value="{{ item.id }}"
                >
                  {{ item.certificate_name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="select-box w-100">
              <label>Email sent when student Login</label>
              <select
                required
                [(ngModel)]="enroll_email_template"
                formControlName="enroll_email_template"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['enroll_email_template'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['enroll_email_template'].dirty ||
                      miniMasterForm.controls['enroll_email_template'].touched)
                }"
              >
                <option value="">Select email template for Enrollments</option>
                <option
                  *ngFor="let items of allTemplates"
                  value="{{ items.id }}"
                >
                  {{ items.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="border-line w-100"></div>
            <p class="class-one-title">Class 1</p>
            <div class="formwrapper-mini-class">
              <label>Class 1 display name</label>
              <input
                [(ngModel)]="class1_display_name"
                formControlName="class1_display_name"
                placeholder="Enter Class 1 display name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class1_display_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class1_display_name'].dirty ||
                      miniMasterForm.controls['class1_display_name'].touched)
                }"
              />
            </div>
            <div class="mini-tooltip d-flex2 formwrapper-mini-class">
              <label>Class 1 Video</label>
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class1_video_url_144p"
                formControlName="class1_video_url_144p"
                placeholder="144p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class1_video_url_240p"
                formControlName="class1_video_url_240p"
                placeholder="240"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class1_video_url_360p"
                formControlName="class1_video_url_360p"
                placeholder="360p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class1_video_url_480p"
                formControlName="class1_video_url_480p"
                placeholder="480p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class1_video_url_720p"
                formControlName="class1_video_url_720p"
                placeholder="720p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class1_video_url"
                formControlName="class1_video_url"
                placeholder="1080p HD"
              />
              <span class="tooltiptext">
                <video width="320" height="240" controls>
                  <source src="{{ class1_video_url }}" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p class="thumb-prev">Video Preview</p>
              </span>
            </div>
            <div class="mini-tooltip d-flex2 formwrapper-mini-class">
              <label>Class 1 Video thumbnail</label>
              <input
                class="input-upload"
                type="text"
                [(ngModel)]="class1_thumbnail_url"
                formControlName="class1_thumbnail_url"
                placeholder="Upload Class 1 video thumbnail"
              />
              <span>
                <label for="class1_thumbnail_url" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>
                  <span>Select File</span>
                </label>
                <input
                  type="file"
                  class="file"
                  id="class1_thumbnail_url"
                  accept="image/png, image/gif, image/jpeg"
                  (change)="handleFileSelect($event)"
                />
              </span>
              <span class="tooltiptext">
                <img [src]="class1_thumbnail_url" alt="Thumbnail" />
                <p class="thumb-prev">Thumbnail Preview</p>
              </span>
            </div>
            <div class="d-flex2 formwrapper-mini-class">
              <label>Class 1 Downloadable material (optional)</label>
              <input
                class="input-upload"
                type="text"
                [(ngModel)]="class1_material_url"
                formControlName="class1_material_url"
                placeholder="Upload Class 1 downloadable material"
              />
              <span>
                <label for="class1_material_url" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>
                  <span>Select File</span>
                </label>
                <input
                  type="file"
                  class="file"
                  id="class1_material_url"
                  (change)="handleFileSelect($event)"
                />
              </span>
            </div>
            <div class="formwrapper-mini-class">
              <label>Downloadable material name</label>
              <input
                [(ngModel)]="class1_material_name"
                formControlName="class1_material_name"
                placeholder="Enter downloadable material name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class1_material_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class1_material_name'].dirty ||
                      miniMasterForm.controls['class1_material_name'].touched)
                }"
              />
            </div>
            <div class="formwrapper-mini-class">
              <label>Next screen button text</label>
              <input
                [(ngModel)]="class1_video_next_button"
                formControlName="class1_video_next_button"
                placeholder="Enter next screen button text"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class1_video_next_button'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class1_video_next_button']
                      .dirty ||
                      miniMasterForm.controls['class1_video_next_button']
                        .touched)
                }"
              />
            </div>
            <div class="select-box w-100">
              <label>Email sent when student completes</label>
              <select
                required
                [(ngModel)]="class1_video_complete_email"
                formControlName="class1_video_complete_email"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class1_video_complete_email'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class1_video_complete_email']
                      .dirty ||
                      miniMasterForm.controls['class1_video_complete_email']
                        .touched)
                }"
              >
                <option value="">Select email template</option>
                <option
                  *ngFor="let items of allTemplates"
                  value="{{ items.id }}"
                >
                  {{ items.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="border-line w-100"></div>
            <p class="class-one-title">Knowledge Assessment</p>
            <div class="formwrapper-mini-class mt-20">
              <input
                [(ngModel)]="class1_ka_name"
                formControlName="class1_ka_name"
                placeholder="Knowledge Assessment Name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class1_ka_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class1_ka_name'].dirty ||
                      miniMasterForm.controls['class1_ka_name'].touched)
                }"
              />
            </div>
            <div class="history-div mt-20 w-100">
              <p>Knowledge Assessment Questions</p>
              <table
                mat-table
                [dataSource]="dataSourceQuestionKA"
                #quespaginator
                matSort
                style="border-spacing: 0 10px"
              >
                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="check-app">
                      <mat-checkbox
                        (change)="$event ? masterToggleKA() : null"
                        [checked]="selectionKA.hasValue() && isAllSelectedKA()"
                        [indeterminate]="
                          selectionKA.hasValue() && !isAllSelectedKA()
                        "
                      >
                      </mat-checkbox>
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="check-app">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selectionKA.toggle(row) : null"
                        [checked]="selectionKA.isSelected(row)"
                      >
                      </mat-checkbox>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="srno">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                  <td mat-cell *matCellDef="let row; let i = index">
                    {{ row.srno }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="question">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Question
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.question }}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row">
                    <div class="action-icon-inner">
                      <span>
                        <img
                          src="../../../assets/images/edit-1.png"
                          alt="Edit"
                          class="action-icon"
                        />
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                        >
                          <g
                            id="Group_1"
                            data-name="Group 1"
                            transform="translate(-1165 -200)"
                          >
                            <g
                              id="Ellipse_1"
                              data-name="Ellipse 1"
                              transform="translate(1165 200)"
                              fill="none"
                              stroke="#7e7ab3"
                              stroke-width="2"
                            >
                              <circle
                                cx="11.5"
                                cy="11.5"
                                r="11.5"
                                stroke="none"
                              />
                              <circle
                                cx="11.5"
                                cy="11.5"
                                r="10.5"
                                fill="none"
                              />
                            </g>
                            <line
                              id="Line_1"
                              data-name="Line 1"
                              x2="7"
                              transform="translate(1173.5 211.5)"
                              fill="none"
                              stroke="#7e7ab3"
                              stroke-width="2"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsQuestionKA"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsQuestionKA"
                ></tr>
              </table>
              <mat-paginator
                style-paginator
                #quespaginator
                [showTotalPages]="0"
                [length]="
                  dataSourceQuestionKA.data
                    ? dataSourceQuestionKA.data.length
                    : 0
                "
                *ngIf="matpagination"
                [pageSizeOptions]="[10, 20, 50, 100, 500]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
            <div class="formwrapper-mini-class mt-20">
              <input
                [(ngModel)]="class1_ka_next_button"
                formControlName="class1_ka_next_button"
                placeholder="Next screen button text"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class1_ka_next_button'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class1_ka_next_button'].dirty ||
                      miniMasterForm.controls['class1_ka_next_button'].touched)
                }"
              />
            </div>
            <div class="select-box w-100">
              <label>Email sent when student completes</label>
              <select
                required
                [(ngModel)]="class1_ka_complete_email"
                formControlName="class1_ka_complete_email"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class1_ka_complete_email'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class1_ka_complete_email']
                      .dirty ||
                      miniMasterForm.controls['class1_ka_complete_email']
                        .touched)
                }"
              >
                <option value="">Select email template</option>
                <option
                  *ngFor="let items of allTemplates"
                  value="{{ items.id }}"
                >
                  {{ items.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="border-line w-100"></div>
            <p class="class-one-title">Class 2</p>
            <div class="formwrapper-mini-class">
              <label>Class 2 display name</label>
              <input
                [(ngModel)]="class2_display_name"
                formControlName="class2_display_name"
                placeholder="Enter Class 2 display name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class2_display_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class2_display_name'].dirty ||
                      miniMasterForm.controls['class2_display_name'].touched)
                }"
              />
            </div>
            <div class="mini-tooltip d-flex2 formwrapper-mini-class">
              <label>Class 2 Video</label>
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class2_video_url_144p"
                formControlName="class2_video_url_144p"
                placeholder="144p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class2_video_url_240p"
                formControlName="class2_video_url_240p"
                placeholder="240"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class2_video_url_360p"
                formControlName="class2_video_url_360p"
                placeholder="360p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class2_video_url_480p"
                formControlName="class2_video_url_480p"
                placeholder="480p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class2_video_url_720p"
                formControlName="class2_video_url_720p"
                placeholder="720p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class2_video_url"
                formControlName="class2_video_url"
                placeholder="1080 HD"
              />
              <span class="tooltiptext">
                <video width="320" height="240" controls>
                  <source src="{{ class2_video_url }}" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p class="thumb-prev">Video Preview</p>
              </span>
            </div>
            <div class="mini-tooltip d-flex2 formwrapper-mini-class">
              <label>Class 2 Video thumbnail</label>
              <input
                class="input-upload"
                type="text"
                [(ngModel)]="class2_thumbnail_url"
                formControlName="class2_thumbnail_url"
                placeholder="Upload Class 2 video thumbnail"
              />
              <span>
                <label for="class2_thumbnail_url" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>
                  <span>Select File</span>
                </label>
                <input
                  type="file"
                  class="file"
                  id="class2_thumbnail_url"
                  accept="image/png, image/gif, image/jpeg"
                  (change)="handleFileSelect($event)"
                />
              </span>
              <span class="tooltiptext">
                <img [src]="class2_thumbnail_url" alt="Thumbnail" />
                <p class="thumb-prev">Thumbnail Preview</p>
              </span>
            </div>
            <div class="d-flex2 formwrapper-mini-class">
              <label>Class 2 Downloadable material (optional)</label>
              <input
                class="input-upload"
                type="text"
                [(ngModel)]="class2_material_url"
                formControlName="class2_material_url"
                placeholder="Upload Class 2 downloadable material"
              />
              <span>
                <label for="class2_material_url" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>
                  <span>Select File</span>
                </label>
                <input
                  type="file"
                  class="file"
                  id="class2_material_url"
                  (change)="handleFileSelect($event)"
                />
              </span>
            </div>
            <div class="formwrapper-mini-class">
              <label>Downloadable material name</label>
              <input
                [(ngModel)]="class2_material_name"
                formControlName="class2_material_name"
                placeholder="Enter downloadable material name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class2_material_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class2_material_name'].dirty ||
                      miniMasterForm.controls['class2_material_name'].touched)
                }"
              />
            </div>
            <div class="formwrapper-mini-class">
              <label>Next screen button text</label>
              <input
                [(ngModel)]="class2_video_next_button"
                formControlName="class2_video_next_button"
                placeholder="Enter next screen button text"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class2_video_next_button'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class2_video_next_button']
                      .dirty ||
                      miniMasterForm.controls['class2_video_next_button']
                        .touched)
                }"
              />
            </div>
            <div class="select-box w-100">
              <label>Email sent when student completes</label>
              <select
                required
                [(ngModel)]="class2_video_complete_email"
                formControlName="class2_video_complete_email"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class2_video_complete_email'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class2_video_complete_email']
                      .dirty ||
                      miniMasterForm.controls['class2_video_complete_email']
                        .touched)
                }"
              >
                <option value="">Select email template</option>
                <option
                  *ngFor="let items of allTemplates"
                  value="{{ items.id }}"
                >
                  {{ items.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="border-line w-100"></div>
            <p class="class-one-title">Practice Test</p>
            <div class="formwrapper-mini-class mt-20">
              <input
                [(ngModel)]="class2_pt_name"
                formControlName="class2_pt_name"
                placeholder="Practice Test Name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class2_pt_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class2_pt_name'].dirty ||
                      miniMasterForm.controls['class2_pt_name'].touched)
                }"
              />
            </div>
            <div class="history-div mt-20 w-100">
              <p>Practice Test Questions</p>
              <table
                mat-table
                [dataSource]="dataSourceQuestionPT"
                #quespaginatorpt
                matSort
                style="border-spacing: 0 10px"
              >
                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="check-app">
                      <mat-checkbox
                        (change)="$event ? masterTogglePT() : null"
                        [checked]="selectionPT.hasValue() && isAllSelectedPT()"
                        [indeterminate]="
                          selectionPT.hasValue() && !isAllSelectedPT()
                        "
                      >
                      </mat-checkbox>
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="check-app">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selectionPT.toggle(row) : null"
                        [checked]="selectionPT.isSelected(row)"
                      >
                      </mat-checkbox>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="srno">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                  <td mat-cell *matCellDef="let row; let i = index">
                    {{ row.srno }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="question">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Question
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.title }}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row">
                    <div class="action-icon-inner">
                      <span>
                        <img
                          src="../../../assets/images/edit-1.png"
                          alt="Edit"
                          class="action-icon"
                        />
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                        >
                          <g
                            id="Group_1"
                            data-name="Group 1"
                            transform="translate(-1165 -200)"
                          >
                            <g
                              id="Ellipse_1"
                              data-name="Ellipse 1"
                              transform="translate(1165 200)"
                              fill="none"
                              stroke="#7e7ab3"
                              stroke-width="2"
                            >
                              <circle
                                cx="11.5"
                                cy="11.5"
                                r="11.5"
                                stroke="none"
                              />
                              <circle
                                cx="11.5"
                                cy="11.5"
                                r="10.5"
                                fill="none"
                              />
                            </g>
                            <line
                              id="Line_1"
                              data-name="Line 1"
                              x2="7"
                              transform="translate(1173.5 211.5)"
                              fill="none"
                              stroke="#7e7ab3"
                              stroke-width="2"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsQuestionPT"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsQuestionPT"
                ></tr>
              </table>
              <mat-paginator
                style-paginator
                #quespaginatorpt
                [showTotalPages]="0"
                [length]="
                  dataSourceQuestionPT.data
                    ? dataSourceQuestionPT.data.length
                    : 0
                "
                *ngIf="matpaginationpt"
                [pageSizeOptions]="[10, 20, 50, 100, 500]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
            <div class="formwrapper-mini-class mt-20">
              <input
                [(ngModel)]="class2_pt_next_button"
                formControlName="class2_pt_next_button"
                placeholder="Next screen button text"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class2_pt_next_button'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class2_pt_next_button'].dirty ||
                      miniMasterForm.controls['class2_pt_next_button'].touched)
                }"
              />
            </div>
            <div class="select-box w-100">
              <label>Email sent when student completes</label>
              <select
                required
                [(ngModel)]="class2_pt_complete_email"
                formControlName="class2_pt_complete_email"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class2_pt_complete_email'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class2_pt_complete_email']
                      .dirty ||
                      miniMasterForm.controls['class2_pt_complete_email']
                        .touched)
                }"
              >
                <option value="">Select email template</option>
                <option
                  *ngFor="let items of allTemplates"
                  value="{{ items.id }}"
                >
                  {{ items.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="border-line w-100"></div>
            <p class="class-one-title">Class 3</p>
            <div class="formwrapper-mini-class">
              <label>Class 3 display name</label>
              <input
                [(ngModel)]="class3_display_name"
                formControlName="class3_display_name"
                placeholder="Enter Class 3 display name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class3_display_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class3_display_name'].dirty ||
                      miniMasterForm.controls['class3_display_name'].touched)
                }"
              />
            </div>
            <div class="mini-tooltip d-flex2 formwrapper-mini-class">
              <label>Class 3 Video</label>
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class3_video_url_144p"
                formControlName="class3_video_url_144p"
                placeholder="144p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class3_video_url_240p"
                formControlName="class3_video_url_240p"
                placeholder="240"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class3_video_url_360p"
                formControlName="class3_video_url_360p"
                placeholder="360p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class3_video_url_480p"
                formControlName="class3_video_url_480p"
                placeholder="480p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class3_video_url_720p"
                formControlName="class3_video_url_720p"
                placeholder="720p"
              />
              <input
                class="input-upload video_url"
                type="text"
                [(ngModel)]="class3_video_url"
                formControlName="class3_video_url"
                placeholder="1080p HD"
              />
              <span class="tooltiptext">
                <video width="320" height="240" controls>
                  <source src="{{ class3_video_url }}" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p class="thumb-prev">Video Preview</p>
              </span>
            </div>
            <div class="mini-tooltip d-flex2 formwrapper-mini-class">
              <label>Class 3 Video thumbnail</label>
              <input
                class="input-upload"
                type="text"
                [(ngModel)]="class3_thumbnail_url"
                formControlName="class3_thumbnail_url"
                placeholder="Upload Class 3 video thumbnail"
              />
              <span>
                <label for="class3_thumbnail_url" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>
                  <span>Select File</span>
                </label>
                <input
                  type="file"
                  class="file"
                  id="class3_thumbnail_url"
                  accept="image/png, image/gif, image/jpeg"
                  (change)="handleFileSelect($event)"
                />
              </span>
              <span class="tooltiptext">
                <img [src]="class3_thumbnail_url" alt="Thumbnail" />
                <p class="thumb-prev">Thumbnail Preview</p>
              </span>
            </div>
            <div class="d-flex2 formwrapper-mini-class">
              <label>Class 3 Downloadable material (optional)</label>
              <input
                class="input-upload"
                type="text"
                [(ngModel)]="class3_material_url"
                formControlName="class3_material_url"
                placeholder="Upload Class 3 downloadable material"
              />
              <span>
                <label for="class3_material_url" class="upload-label">
                  <mat-icon class="upload">upload</mat-icon>
                  <span>Select File</span>
                </label>
                <input
                  type="file"
                  class="file"
                  id="class3_material_url"
                  (change)="handleFileSelect($event)"
                />
              </span>
            </div>
            <div class="formwrapper-mini-class">
              <label>Downloadable material name</label>
              <input
                [(ngModel)]="class3_material_name"
                formControlName="class3_material_name"
                placeholder="Enter downloadable material name"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['class3_material_name'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['class3_material_name'].dirty ||
                      miniMasterForm.controls['class3_material_name'].touched)
                }"
              />
            </div>
            <div class="formwrapper-mini-class">
              <label>Next screen button text</label>
              <input
                [(ngModel)]="class3_video_next_button"
                formControlName="class3_video_next_button"
                placeholder="Enter next screen button text"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class3_video_next_button'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class3_video_next_button']
                      .dirty ||
                      miniMasterForm.controls['class3_video_next_button']
                        .touched)
                }"
              />
            </div>
            <div class="select-box w-100">
              <label>Email sent when student completes</label>
              <select
                required
                [(ngModel)]="class3_video_complete_email"
                formControlName="class3_video_complete_email"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls[
                      'class3_video_complete_email'
                    ].hasError('required') &&
                    (miniMasterForm.controls['class3_video_complete_email']
                      .dirty ||
                      miniMasterForm.controls['class3_video_complete_email']
                        .touched)
                }"
              >
                <option value="">Select email template</option>
                <option
                  *ngFor="let items of allTemplates"
                  value="{{ items.id }}"
                >
                  {{ items.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="border-line w-100"></div>
            <p class="class-one-title">Offer</p>
            <div class="formwrapper-mini-class mt-20 position-relative">
              <input
                [(ngModel)]="offer_link"
                formControlName="offer_link"
                placeholder="Offer link"
                required
                [ngClass]="{
                  err:
                    miniMasterForm.controls['offer_link'].hasError(
                      'required'
                    ) &&
                    (miniMasterForm.controls['offer_link'].dirty ||
                      miniMasterForm.controls['offer_link'].touched)
                }"
              />
              <mat-icon class="link">link</mat-icon>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of logsList">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="sub-btn">
        <button class="cancel-btn" (click)="closeModal()">Cancel</button>
        <div *ngIf="!IsUpdate">
          <button
            type="button"
            class="submit-btn"
            (click)="saveMiniMasterClass()"
          >
            Save
          </button>
        </div>
        <div *ngIf="IsUpdate">
          <button
            type="button"
            class="submit-btn"
            (click)="updateMiniMasterClass()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
