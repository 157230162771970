<div class="loginmain">
  <div class="loginformwrap">
    <div class="logoimage">
      <img src="../../assets/images/cissplogo.png" alt="CISSP" />
    </div>
    <h3>Forgot Password</h3>
    <div class="loginform">
      <form (key.enter)="login()">
        <div class="inputtype">
          <label>Email Address</label>
          <input
            type="email"
            [(ngModel)]="email"
            name="email"
            placeholder="Enter Email"
          />
        </div>
        <button type="submit" (click)="login()" tabindex="4" class="button">
          Send me the link
        </button>
      </form>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
