<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Feedback</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Question/Student Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date & Time</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.updatedAt ">{{
              element.updatedAt | date: 'medium'
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Student</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.username }}">{{
              element.username ? element.username : element.email
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Question</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.question ? element.question : '-' }}">{{
              element.question ? element.question : '-'
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="addressname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Addressed?</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.addressname }}">{{
              element.addressname
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addFlashcard">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="IsUpdate">Edit Feedback</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="feedbackform">
          <div class="formwrapper2">
            <div>
              <div class="select-box w-50">
                <select
                  (change)="certificatechange($event)"
                  (keyup)="certificatechange($event)"
                  (keydown)="certificatechange($event)"
                  [(ngModel)]="Certificate"
                  formControlName="Certificate"
                  [ngClass]="{
                    err:
                      feedbackform.controls['Certificate'].hasError(
                        'required'
                      ) &&
                      (feedbackform.controls['Certificate'].dirty ||
                        feedbackform.controls['Certificate'].touched)
                  }"
                  required
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <div class="select-box">
                <select
                  (change)="domainchange($event)"
                  [attr.disabled]="this.Certificate == '' ? true : null"
                  [(ngModel)]="Domainname"
                  formControlName="Domainname"
                  [ngClass]="{
                    err:
                      feedbackform.controls['Domainname'].hasError(
                        'required'
                      ) &&
                      (feedbackform.controls['Domainname'].dirty ||
                        feedbackform.controls['Domainname'].touched)
                  }"
                  required
                >
                  <option value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <div class="select-box">
                <select
                  (change)="SubDomainchange($event)"
                  [attr.disabled]="this.Domainname == '' ? true : null"
                  [(ngModel)]="Subdomainname"
                  formControlName="Subdomainname"
                  [ngClass]="{
                    err:
                      feedbackform.controls['Subdomainname'].hasError(
                        'required'
                      ) &&
                      (feedbackform.controls['Subdomainname'].dirty ||
                        feedbackform.controls['Subdomainname'].touched)
                  }"
                  required
                >
                  <option value="">Select Sub-domain</option>
                  <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <div class="select-box">
                <select
                  [attr.disabled]="this.Subdomainname == '' ? true : null"
                  [(ngModel)]="Topicname"
                  formControlName="Topicname"
                  [ngClass]="{
                    err:
                      feedbackform.controls['Topicname'].hasError('required') &&
                      (feedbackform.controls['Topicname'].dirty ||
                        feedbackform.controls['Topicname'].touched)
                  }"
                  required
                >
                  <option value="">Select Topic</option>
                  <option *ngFor="let item of Topic" value="{{ item.id }}">
                    {{ item.topic_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
          </div>
          <div class="table">
            <div class="table-head">
              <div class="question-div"></div>
              <div class="feedback-div">
                <span>Feedback</span>
              </div>
              <div class="allquestion-div">
                <span>Question</span>
              </div>
            </div>
            <div class="table-body">
              <div class="question-rows">
                <div class="question-div">
                  <span>Qustion</span>
                </div>
                <div class="feedback-div">
                  <span>{{ FeedBackDATA.Question }}</span>
                </div>
                <div class="allquestion-div">
                  <textarea
                    [(ngModel)]="Question.Question"
                    formControlName="Question"
                    [ngClass]="{
                      err:
                        feedbackform.controls['Question'].hasError(
                          'required'
                        ) &&
                        (feedbackform.controls['Question'].dirty ||
                          feedbackform.controls['Question'].touched)
                    }"
                    required
                  >
                  </textarea>
                </div>
              </div>

              <div class="question-rows">
                <div class="question-div">
                  <span>Answer A</span>
                </div>
                <div class="feedback-div">
                  <span>{{ FeedBackDATA.AnswerA }}</span>
                </div>
                <div class="allquestion-div">
                  <textarea
                    [(ngModel)]="Question.AnswerA"
                    [ngClass]="{
                      err:
                        feedbackform.controls['AnswerA'].hasError('required') &&
                        (feedbackform.controls['AnswerA'].dirty ||
                          feedbackform.controls['AnswerA'].touched)
                    }"
                    formControlName="AnswerA"
                    required
                  >
                  </textarea>
                </div>
              </div>

              <div class="question-rows">
                <div class="question-div">
                  <span>Answer B</span>
                </div>
                <div class="feedback-div">
                  <span>{{ FeedBackDATA.AnswerB }}</span>
                </div>
                <div class="allquestion-div">
                  <textarea
                    [(ngModel)]="Question.AnswerB"
                    formControlName="AnswerB"
                    [ngClass]="{
                      err:
                        feedbackform.controls['AnswerB'].hasError('required') &&
                        (feedbackform.controls['AnswerB'].dirty ||
                          feedbackform.controls['AnswerB'].touched)
                    }"
                    required
                  >
                  </textarea>
                </div>
              </div>

              <div class="question-rows">
                <div class="question-div">
                  <span>Answer C</span>
                </div>
                <div class="feedback-div">
                  <span>{{ FeedBackDATA.AnswerC }}</span>
                </div>
                <div class="allquestion-div">
                  <textarea
                    [(ngModel)]="Question.AnswerC"
                    formControlName="AnswerC"
                    [ngClass]="{
                      err:
                        feedbackform.controls['AnswerC'].hasError('required') &&
                        (feedbackform.controls['AnswerC'].dirty ||
                          feedbackform.controls['AnswerC'].touched)
                    }"
                    required
                  >
                  </textarea>
                </div>
              </div>

              <div class="question-rows">
                <div class="question-div">
                  <span>Answer D</span>
                </div>
                <div class="feedback-div">
                  <span>{{ FeedBackDATA.AnswerD }}</span>
                </div>
                <div class="allquestion-div">
                  <textarea
                    [(ngModel)]="Question.AnswerD"
                    formControlName="AnswerD"
                    [ngClass]="{
                      err:
                        feedbackform.controls['AnswerD'].hasError('required') &&
                        (feedbackform.controls['AnswerD'].dirty ||
                          feedbackform.controls['AnswerD'].touched)
                    }"
                    required
                  >
                  </textarea>
                </div>
              </div>

              <div
                class="question-rows"
                [ngClass]="{
                  changeFeedback:
                    FeedBackDATA.Explanation != Question.Explanation
                }"
              >
                <div class="question-div">
                  <span>Explanation</span>
                </div>
                <div class="feedback-div">
                  <span>{{ FeedBackDATA.Explanation }}</span>
                </div>
                <div class="allquestion-div">
                  <textarea
                    [(ngModel)]="Question.Explanation"
                    formControlName="Explanation"
                    [ngClass]="{
                      err:
                        feedbackform.controls['Explanation'].hasError(
                          'required'
                        ) &&
                        (feedbackform.controls['Explanation'].dirty ||
                          feedbackform.controls['Explanation'].touched)
                    }"
                    required
                  >
                  </textarea>
                </div>
              </div>

              <div
                class="question-rows"
                [ngClass]="{
                  changeFeedback:
                    FeedBackDATA.CorrectAnswer != Question.CorrectAnswer
                }"
              >
                <div class="question-div">
                  <span>Correct Answer</span>
                </div>
                <div class="feedback-div">
                  <span>Answer {{ FeedBackDATA.CorrectAnswer }} </span>
                </div>
                <div class="allquestion-div d-flex">
                  <select
                    [(ngModel)]="Question.CorrectAnswer"
                    formControlName="CorrectAnswer"
                    [ngClass]="{
                      err:
                        feedbackform.controls['CorrectAnswer'].hasError(
                          'required'
                        ) &&
                        (feedbackform.controls['CorrectAnswer'].dirty ||
                          feedbackform.controls['CorrectAnswer'].touched)
                    }"
                    required
                  >
                    <option value="">Answer A</option>

                    <option value="1">Answer A</option>
                    <option value="2">Answer B</option>
                    <option value="3">Answer C</option>
                    <option value="4">Answer D</option>
                  </select>
                </div>
              </div>

              <div
                class="question-rows"
                [ngClass]="{
                  changeFeedback: FeedBackDATA.Diffculty != Question.Type
                }"
              >
                <div class="question-div">
                  <span>Diffculty</span>
                </div>
                <div class="feedback-div">
                  <span>{{ FeedBackDATA.Diffculty }}</span>
                </div>
                <div class="allquestion-div d-flex">
                  <select
                    [(ngModel)]="Question.Type"
                    formControlName="Type"
                    [ngClass]="{
                      err:
                        feedbackform.controls['Type'].hasError('required') &&
                        (feedbackform.controls['Type'].dirty ||
                          feedbackform.controls['Type'].touched)
                    }"
                    required
                  >
                    <option selected="true" value="">Select</option>
                    <option value="VERY SIMPLE">VERY SIMPLE</option>
                    <option value="SIMPLE">SIMPLE</option>
                    <option value="AVERAGE">AVERAGE</option>
                    <option value="ABOVE AVERAGE">ABOVE AVERAGE</option>
                    <option value="HARD">HARD</option>
                    <option value="DIFFICULT">DIFFICULT</option>
                    <option value="TRICKY">TRICKY</option>
                  </select>
                </div>
              </div>

              <div class="question-rows">
                <div class="question-div">
                  <span>Addressed ? {{ AddressedCheck }}</span>
                </div>
                <div class="feedback-div d-flex">
                  <input
                    class="w-auto"
                    type="checkbox"
                    formControlName="Addressed"
                    [(ngModel)]="AddressedCheck"
                    [ngClass]="{
                      err:
                        feedbackform.controls['Addressed'].hasError(
                          'required'
                        ) &&
                        (feedbackform.controls['Addressed'].dirty ||
                          feedbackform.controls['Addressed'].touched)
                    }"
                    [checked]="AddressedCheck"
                    (click)="AddressedChange()"
                  />
                </div>
                <div class="allquestion-div"></div>
              </div>

              <div class="question-rows">
                <div class="question-div">
                  <span>Notes</span>
                </div>
                <div class="feedback-div"></div>
                <div class="allquestion-div">
                  <textarea
                    formControlName="Notes"
                    [ngClass]="{
                      err:
                        feedbackform.controls['Notes'].hasError('required') &&
                        (feedbackform.controls['Notes'].dirty ||
                          feedbackform.controls['Notes'].touched)
                    }"
                    [(ngModel)]="Notes"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div>
        <button class="submit-btn" (click)="UpdateFlashcard()">Save</button>
      </div>
    </div>
  </div>
</div>
