<div class="pagemain">
  <div class="pagetitle">
    <h3 [hidden]="!EditHideen">Add New practice test</h3>
    <h3 [hidden]="EditHideen">Update practice test</h3>
  </div>
  <div>
    <form novalidate [formGroup]="PracticeTestForm">
      <div class="formwrapper">
        <div>
          <label>Course</label>
          <ng-select
            [items]="ListCoursedata"
            formControlName="CourseName"
            bindLabel="course_name"
            bindValue="id"
            placeholder="Course"
            appendTo="body"
            multiple="true"
            [(ngModel)]="selecteds"
          >
          </ng-select>
        </div>
        <div>
          <label>Practice Test Name</label>
          <input
            [(ngModel)]="PracticeTestname"
            formControlName="PracticeTestname"
            placeholder="Practice Test Name"
            name="name"
            required
          />
        </div>
        <div>
          <label>Acronyms</label>
          <ng-select
            [items]="acrolist"
            formControlName="Acronymsname"
            bindLabel="short_name"
            bindValue="id"
            placeholder="Acronyms"
            appendTo="body"
            multiple="true"
            [(ngModel)]="acroselecteds"
          >
          </ng-select>
        </div>
        <div>
          <label>Certification</label>
          <div class="select-box">
            <select
              matNativeControl
              [(ngModel)]="Certificate"
              formControlName="Certificate"
              required
            >
              <option value="">Select</option>
              <option
                *ngFor="let item of Certificatelist"
                value="{{ item.id }}"
              >
                {{ item.certificate_name }}
              </option>
            </select>
            <span></span>
          </div>
        </div>
        <div class="date-select">
          <label class="label">Time:</label>
          <div class="date-time">
            <div>
              <label>Hours</label>
              <div class="select-box">
                <select
                  matNativeControl
                  [(ngModel)]="hours"
                  formControlName="hours"
                  required
                >
                  <option value="">Select</option>
                  <option *ngFor="let item of hourslist" value="{{ item }}">
                    {{ item }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Minutes</label>
              <div class="select-box">
                <select
                  matNativeControl
                  [(ngModel)]="Minutes"
                  formControlName="Minutes"
                  required
                >
                  <option value="">Select</option>
                  <option *ngFor="let item of Minuteslist" value="{{ item }}">
                    {{ item }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="!EditHideen">
        <button
          [disabled]="!PracticeTestForm.valid"
          type="button"
          class="submit-btn"
          (click)="onSubmit()"
        >
          Submit
        </button>
      </div>
      <div [hidden]="EditHideen">
        <button
          [disabled]="!PracticeTestForm.valid"
          (click)="Update()"
          type="button"
          class="submit-btn"
        >
          Update
        </button>
      </div>
    </form>
    <div class="history-div" *ngIf="EditHideen">
      <h3><span class="material-icons"> history </span>Audit history</h3>
      <div class="audit-table">
        <div class="table-header">
          <div class="date-title"><span>Date & Time</span></div>
          <div class="action-title"><span>Action</span></div>
        </div>
        <div class="main-body-audit">
          <div class="table-body">
            <div class="date-title"><span>7/11/2020, 6:46:31 PM</span></div>
            <div class="action-title">
              <span
                >Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
