import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../service/authorization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { CohortService } from 'src/app/services/cohort/cohort.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-cohortreport',
  templateUrl: './cohortreport.component.html',
  styleUrls: ['./cohortreport.component.scss'],
})
export class CohortreportComponent implements OnInit {
  Reports = [];
  KnowAss = [];
  Results = [];
  KnowledgeAssessments = [];
  cohort_name: any;
  @ViewChild('printable') printable: ElementRef;
  public context: CanvasRenderingContext2D;
  constructor(
    private location: Location,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public auth: AuthorizationService,
    public service: CohortService,
    public spinner: NgxSpinnerService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        let data = JSON.parse(atob(params['id']));
        this.cohort_name = data.cohort_name;
        await this.getCohortReport(data.cohort_id);
      } catch (error) {
        this.router.navigate(['sidenav/cohortlist']);
      }
    });
  }
  ngOnInit() {}
  back() {
    this.location.back();
  }
  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  async getCohortReport(cohort_id) {
    try {
      this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.service
              .getCohortReport(cohort_id, token)
              .then((data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.Reports = list.data;
                  this.Reports.forEach((element, i) => {
                    if (element.KnowAss.length > 0) {
                      element.KnowAss.forEach((kn) => {
                        let know = { knowass_name: kn.knowass_name, id: kn.id };
                        let pushData = 1;
                        this.KnowledgeAssessments.forEach((KA) => {
                          if (KA.knowass_name == kn.knowass_name) {
                            pushData = 0;
                          }
                        });
                        if (pushData == 1) this.KnowledgeAssessments.push(know);
                      });
                    }
                    let fls = {
                      ttlright: element.Flashcards[0].ttlright
                        ? element.Flashcards[0].ttlright
                        : 0,
                      ttlreview: element.Flashcards[0].ttlreview
                        ? element.Flashcards[0].ttlreview
                        : 0,
                      ttlbookmark: element.Flashcards[0].ttlbookmark
                        ? element.Flashcards[0].ttlbookmark
                        : 0,
                      ttlunseen:
                        parseInt(
                          element.Flashcards[0].ttlcards
                            ? element.Flashcards[0].ttlcards
                            : 0,
                        ) -
                        (parseInt(
                          element.Flashcards[0].ttlright
                            ? element.Flashcards[0].ttlright
                            : 0,
                        ) +
                          parseInt(
                            element.Flashcards[0].ttlreview
                              ? element.Flashcards[0].ttlreview
                              : 0,
                          )),
                    };
                    let practice = {
                      ttlprques: element.Practice.ttlprques,
                      right: element.Practice.right,
                      wrong: element.Practice.wrong,
                      marked: element.Practice.marked,
                      unseen:
                        parseInt(element.Practice.ttlprques) -
                        (parseInt(element.Practice.right) +
                          parseInt(element.Practice.wrong) +
                          parseInt(element.Practice.marked)),
                      percent: (
                        (parseInt(element.Practice.right) * 100) /
                        parseInt(element.Practice.ttlprques)
                      ).toFixed(2),
                    };
                    if (practice.percent == 'NaN') {
                      practice.percent = '0.00';
                    }
                    let knper = 0;
                    element.KnowAss.forEach((kn) => {
                      kn.percent = (
                        (parseInt(kn.ttlpointget) * 100) /
                        parseInt(kn.ttlpoint)
                      ).toFixed(2);
                      knper +=
                        (parseInt(kn.ttlpointget) * 100) /
                        parseInt(kn.ttlpoint);
                      this.KnowledgeAssessments.forEach((kns) => {
                        if (kns.knowass_name == kn.knowass_name) {
                          kns['percent'] = (
                            (parseInt(kn.ttlpointget) * 100) /
                            parseInt(kn.ttlpoint)
                          ).toFixed(2);
                        }
                      });
                    });
                    let AvgKnowPer = (knper / element.KnowAss.length).toFixed(
                      2,
                    );
                    let res = {
                      KnowAss: element.KnowAss,
                      AvgKnowPer: AvgKnowPer,
                      Flashcards: fls,
                      Practice: practice,
                    };
                    this.Results.push(res);
                  });
                  this.Results.forEach((element) => {
                    this.KnowledgeAssessments.forEach((ele) => {
                      let knowass_name = element.KnowAss.filter(
                        (x) => x.knowass_name == ele.knowass_name,
                      );
                      if (knowass_name.length <= 0) {
                        let newknowssData = {
                          knowass_name: ele.knowass_name,
                          type: 'svg',
                          percent: 0,
                        };
                        element.KnowAss.push(newknowssData);
                      }
                    });
                    if (element.AvgKnowPer == 'NaN') {
                      element.AvgKnowPer = 0;
                    }
                  });
                  this.KnowledgeAssessments.sort((a, b) => a.id - b.id);
                  this.spinner.hide();
                }
              });
          }
        });
    } catch (error) {
      this.router.navigate(['/login']);
    }
  }
  public SavePDF(): void {
    let leftTable = document.getElementById('left-table');
    leftTable.style.borderCollapse = 'collapse';
    leftTable.style.width = '300px';
    leftTable.style.backgroundColor = '#e6e6e6';
    leftTable.style.fontFamily =
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
    let rightTable = document.getElementById('right-table');
    rightTable.style.borderCollapse = 'collapse';
    rightTable.style.fontFamily =
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
    let height110 = document.getElementById('height110');
    height110.style.height = '109px';
    height110.style.backgroundColor = '#f6f6f6';
    let dflex = document.getElementById('dflex');
    dflex.style.display = 'flex';
    let height129 = document.getElementById('height129');
    height129.style.height = '248px';
    height129.style.verticalAlign = 'bottom';
    height129.style.backgroundColor = '#e9e9e9';
    height129.style.fontWeight = 'bold';
    height129.style.textAlign = 'left';
    height129.style.paddingBottom = '10px';
    height129.style.paddingLeft = '13px';
    height129.style.fontSize = '15px';
    let height46 = document.getElementsByClassName('height46');
    for (var i = 0; i < height46.length; i++) {
      height46[i]['style']['height'] = '56px';
    }
    let svgSize = document.getElementsByClassName('svgSize');
    for (var i = 0; i < svgSize.length; i++) {
      svgSize[i]['style'].height = '19px';
      svgSize[i]['style'].width = '19px';
    }
    let knowldgeAssement = document.getElementsByClassName('knowldgeAssement');
    for (let index = 0; index < knowldgeAssement.length; index++) {
      knowldgeAssement[index]['style'].writingMode = 'vertical-rl';
      knowldgeAssement[index]['style'].textAlign = 'start';
      knowldgeAssement[index]['style'].transform = 'rotate(180deg)';
      knowldgeAssement[index]['style'].height = '225px';
      knowldgeAssement[index]['style'].padding = '10px';
      knowldgeAssement[index]['style'].fontWeight = 'normal';
      knowldgeAssement[index]['style'].margin = 'auto';
    }
    let cell100 = document.getElementsByClassName('cell100');
    for (let index = 0; index < cell100.length; index++) {
      cell100[index]['style'].fontWeight = 'normal';
      cell100[index]['style'].minWidth = '60px';
    }
    let averageScore = document.getElementsByClassName('averageScore');
    for (let index = 0; index < averageScore.length; index++) {
      averageScore[index]['style'].fontWeight = 'bold';
    }
    let bgColor = document.getElementsByClassName('bg-color');
    for (let index = 0; index < bgColor.length; index++) {
      bgColor[index]['style'].height = '40px';
    }
    let UserName = document.getElementsByClassName('user-name');
    for (let index = 0; index < UserName.length; index++) {
      UserName[index]['style'].display = 'flex';
      UserName[index]['style'].alignItems = 'center';
      UserName[index]['style'].transform = 'rotate(0deg)';
      UserName[index]['style'].writingMode = 'inherit';
      UserName[index]['style'].padding = '0px';
    }
    let printicon = document.getElementById('printicon');
    printicon.style.display = 'none';
    let backbtn = document.getElementById('backbtn');
    backbtn.style.opacity = '0';
    let titlediv = document.getElementById('titlediv');
    titlediv.style.display = 'flex';
    titlediv.style.alignItems = 'center';
    titlediv.style.justifyContent = 'space-between';
    titlediv.style.width = '100%';
    let printlogo = document.getElementById('printlogo');
    printlogo.style.width = '80px';
    let UserBackground = document.getElementsByClassName('UserBackground');
    for (let index = 0; index < UserBackground.length; index++) {
      UserBackground[index]['style'].background = '#dadada';
    }
    const printContent = document.getElementById('printable');
    const WindowPrt = window.open(
      '',
      'report',
      'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0,',
    );
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.document.title = this.cohort_name;
    WindowPrt.document.body.style.backgroundColor = '#e6e6e6';
    WindowPrt.document.body.style.margin = '10px';
    WindowPrt.document.body.style.padding = '10px';
    let Windheaderlogo =
      WindowPrt.document.getElementsByClassName('headerlogo');
    Windheaderlogo[0]['style'].margin = '0 30px 0 0';
    let WindowPrttitlediv = WindowPrt.document.getElementById('titlediv');
    WindowPrttitlediv.style.width = WindowPrt.document.body.scrollWidth + 'px';
    let rightTablecont = WindowPrt.document.getElementById('right-table');
    rightTablecont.style.backgroundColor = '#e6e6e6';
    let stop = WindowPrt.document.getElementsByTagName('stop');
    for (let index = 0; index < stop.length; index++) {
      stop[index].setAttribute('stop-color', '#555555');
    }
    let studentName = WindowPrt.document.getElementsByClassName('studentName');
    for (let index = 0; index < studentName.length; index++) {
      studentName[index]['style'].height = '56px';
    }
    setTimeout(() => {
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
    }, 3000);
    leftTable.style.borderCollapse = 'unset';
    leftTable.style.backgroundColor = '#25305f';
    rightTable.style.height = 'unset';
    height110.style.height = '150px';
    height110.style.backgroundColor = '#25305f';
    height129.style.backgroundColor = '#25305f';
    height129.style.height = '227px';
    dflex.style.display = 'block';
    for (var i = 0; i < height46.length; i++) {
      height46[i]['style']['height'] = '60px';
    }
    for (let index = 0; index < UserBackground.length; index++) {
      UserBackground[index]['style'].background = '#25305f';
    }
    printicon.style.display = 'block';
    backbtn.style.opacity = '1';
  }
}
