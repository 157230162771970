import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { BooksService } from '../../services/books/books.service';
import { ReferenceService } from 'src/app/services/reference/reference.service';
import { SelfpacedService } from 'src/app/services/selfpaced/selfpaced.service';
@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
})
export class BooksComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'book_title',
    'actions',
  ];
  dataSource: MatTableDataSource<certificatelist>;
  Search: any;
  addAcronyms: boolean = false;
  dataSourceReference: MatTableDataSource<ReferenceList>;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @Input('matpagination') matpagination: boolean;
  @ViewChildren(MatPaginator) set matquePaginator(
    quespaginator: QueryList<MatPaginator>,
  ) {
    if (this.matpagination) {
      this.dataSourceReference.paginator = quespaginator.toArray()[1];
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatPaginator) quespaginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortquestion = new QueryList<MatSort>();
  displayedColumnsReference: string[] = ['srno', 'title', 'page_no', 'actions'];
  SearchReference: any;
  book_title: any;
  SelectedCertificate: any;
  id: any;
  IsUpdate = false;
  public CertificateForm: FormGroup;
  Certificatelist = [];
  loglist = [];
  References = [];
  oldAuditValueArray = { Certificate: '', 'Book Name': '' };
  newAuditValueArray = { Certificate: '', 'Book Name': '' };
  auditHistory = '';
  book_img: any;
  constructor(
    public refService: ReferenceService,
    public booksService: BooksService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public sefpaceService: SelfpacedService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  closeModal() {
    this.addAcronyms = false;
    this.book_title = '';
    this.SelectedCertificate = '';
    this.oldAuditValueArray = { Certificate: '', 'Book Name': '' };
    this.newAuditValueArray = { Certificate: '', 'Book Name': '' };
    this.auditHistory = '';
    this.book_img = '';
  }
  addNewaddAcronyms() {
    this.addAcronyms = true;
    this.IsUpdate = false;
  }
  async ngOnInit() {
    this.CertificateForm = this.fb.group({
      book_title: ['', Validators.required],
      SelectedCertificate: ['', Validators.required],
      book_img: new FormControl(),
    });
    await this.spinner.show();
    await this.getAllBooks();
    await this.ListCertificate();
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      try {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                this.service.token = token;
                await this.booksService
                  .DeleteBooks(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();
                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.book_title +
                              '</span> Book is deleted by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'books',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.getAllBooks();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Book has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        }
      } catch (e) {
        await this.spinner.hide();
      }
    });
  }
  async getAllBooks() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: certificatelist[] = [];
            await this.booksService.GetAllBooks(token).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                var srno = 0;
                await list.data.forEach(async (element) => {
                  srno = srno + 1;
                  await users.push({
                    srno: srno,
                    id: element.id,
                    certificate_name: element.certificate.certificate_name,
                    certi_id: element.certi_id,
                    book_title: element.book_title,
                    book_img: element.book_img,
                    createdAt: element.createdAt,
                    updatedAt: element.updatedAt,
                  });
                });
                this.dataSource = new MatTableDataSource(users);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.filter();
                this.pagination = true;
                await this.spinner.hide();
              }
            });
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async filterReference() {
    this.dataSourceReference.filter = this.SearchReference;
    if (this.pagination) {
      this.dataSourceReference.paginator._changePageSize(
        this.dataSourceReference.paginator.pageSize,
      );
    }
  }
  async getReferenceByBook(book_id, token) {
    const users: ReferenceList[] = [];
    await this.refService.getReferenceByBook(book_id, token).then(
      async (value) => {
        if (value[0].status == 200) {
          var list = JSON.parse(<string>value[0].json);
          var srno = 0;
          await list.data.forEach(async (element) => {
            srno = srno + 1;
            await users.push({
              srno: srno,
              id: element.id,
              title: element.title,
              page_no: element.page_no,
            });
          });
          this.dataSourceReference = new MatTableDataSource(users);
          this.dataSourceReference.paginator = this.quespaginator.toArray()[1];
          this.dataSourceReference.sort = this.sortquestion.toArray()[1];
          this.matpagination = true;
        } else {
          this.service.ShowError(list.message);
        }
      },
      (err) => {
        this.spinner.hide();
      },
    );
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.getReferenceByBook(item.id, token);
        await this.service.get_logs('books', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.id = item.id;
    this.book_title = item.book_title;
    this.SelectedCertificate = item.certi_id;
    this.book_img = item.book_img;
    this.IsUpdate = true;
    this.addAcronyms = true;
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find(
        (x) => x.id == this.SelectedCertificate,
      )
        ? this.Certificatelist.find((x) => x.id == this.SelectedCertificate)
            .certificate_name
        : '',
      'Book Name': this.book_title,
    };
  }
  async onSubmit() {
    this.markFormTouched(this.CertificateForm);
    if (this.CertificateForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.booksService
              .AddBooks(
                this.SelectedCertificate,
                this.book_title,
                this.book_img,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.book_title +
                          '</span> Book is added by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'books',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  this.addAcronyms = false;
                  this.IsUpdate = false;
                  this.closeModal();
                  await this.getAllBooks();
                  await this.spinner.hide();
                  this.service.ShowSuccess('Book Added Successfully');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async UpdateCertificate() {
    this.markFormTouched(this.CertificateForm);
    if (this.CertificateForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.booksService
              .UpdateBooks(
                this.id,
                this.SelectedCertificate,
                this.book_title,
                this.book_img,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        this.newAuditValueArray = {
                          Certificate: this.Certificatelist.find(
                            (x) => x.id == this.SelectedCertificate,
                          )
                            ? this.Certificatelist.find(
                                (x) => x.id == this.SelectedCertificate,
                              ).certificate_name
                            : '',
                          'Book Name': this.book_title,
                        };
                        var audit = this.auth.compareAuditHist(
                          this.oldAuditValueArray,
                          this.newAuditValueArray,
                        );
                        var desc = audit;
                        await this.service.addAdminLogs(
                          desc,
                          'books',
                          this.id,
                          token,
                        );
                      }
                    });
                  this.closeModal();
                  this.addAcronyms = false;
                  this.IsUpdate = false;
                  await this.getAllBooks();
                  await this.spinner.hide();
                  this.service.ShowSuccess('Book Updated Successfully');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.spinner.show();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data;
            this.SelectedCertificate = '';
          }
        });
        this.spinner.hide();
      }
    });
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  editReference(item) {
    let data = btoa(JSON.stringify(item.id));
    this.router.navigate([`main/references/${data}`]);
  }
  async deleteReference(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Delete this reference!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.refService
              .DeleteReference(item.id, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          item.web_link +
                          '</span> Reference is deleted by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'references',
                          item.id,
                          token,
                        );
                      }
                    });
                  this.addAcronyms = false;
                  this.IsUpdate = false;
                  this.service.ShowSuccess('Reference deleted successfully');
                }
              });
          }
        });
        this.spinner.hide();
      }
    });
  }
  handleFileSelect(evt) {
    let me = this;
    let file = evt.target.files[0];
    let mime = 'data:' + file.type + ';base64,';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.uploadFile(reader.result, evt.target.id, mime);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  async uploadFile(image, model, mime) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.sefpaceService
          .uploadFile(image, mime, token)
          .then(async (imgresponse) => {
            if (imgresponse[0].status == 200) {
              var imglist = JSON.parse(<string>imgresponse[0].json);
              this.book_img = imglist.data;
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      }
    });
  }
}
export interface certificatelist {
  srno: number;
  id: string;
  certificate_name: string;
  certi_id: string;
  book_title: string;
  book_img: string;
  createdAt: string;
  updatedAt: string;
}
export interface ReferenceList {
  srno: number;
  id: string;
  title: string;
  page_no: string;
}
