<div class="self-paced-main-div">
  <div class="header-flex">
    <div class="self-paced-title-div">
      <span class="back-arrow" (click)="closeSummaryPage()">
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.7039 7H2"
            stroke="#968BDB"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.74121 0.941406L0.795475 6.88714L6.79145 12.8831"
            stroke="#968BDB"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <h2>
        <span class="user-image">
          <img [src]="Avatar" alt="User Icon" />
        </span>
        <span class="student-name">{{ Name }}</span>
      </h2>
    </div>
    <div class="filter-search">
      <div class="filter-by-select">
        <label for="Filterby">Select class</label>
        <select
          id="Filterby"
          [(ngModel)]="SelectedCohort"
          (change)="changeCohort()"
        >
          <option *ngFor="let item of Cohorts" value="{{ item.id }}">
            {{ item.cohort_name }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="main-div">
    <div class="study-progress-main-div">
      <div class="mentor-contact-div">
        <div class="mentor-select">
          <label for="Mentor">Mentor</label>
          <select id="Mentor" [(ngModel)]="SelectedMentor">
            <option value="">Select Mentor</option>
            <option *ngFor="let item of Mentors" value="{{ item.id }}">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="mentor-select">
          <label for="Mentor">Next Contact</label>
          <div class="formwrapper calendar-form">
            <div class="date-input">
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [matDatepicker]="picker1"
                  [disabled]="true"
                  placeholder="Next Contact"
                  [(ngModel)]="NextContact"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1 [disabled]="false"></mat-datepicker>
              </mat-form-field>
              <span>
                <img
                  src="../../../assets/images/Calendar.svg"
                  alt="Calendar"
                  class="calendar-icon"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="mentor-select w-100">
          <textarea
            name=""
            id=""
            rows="4"
            [(ngModel)]="UserNotes"
            placeholder="Type a note..."
          ></textarea>
          <button class="save-note-btn" (click)="SaveNotes()">Save Note</button>
        </div>
      </div>
      <div class="note-section">
        <div class="note-section-scroll">
          <div class="note-div" *ngFor="let item of PushMentorNotes">
            <p>{{ item.notes }}</p>
            <p>
              - Noted by <span class="mentor">{{ item.mentor_name }}</span> on
              <span class="date">
                {{ item.next_contact | date: 'medium' }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="study-progress" id="studyprogress">
        <div class="div-head">
          <span class="bg-span"></span>
          <span>Study Progress</span>
        </div>
        <div class="div-body">
          <div class="main-details">
            <div class="assessments-title"></div>
            <div class="assessments-body">
              <div class="exam-detail">
                <div class="student-names">
                  <span>First Sign-in</span>
                </div>
                <div class="student-names">
                  <span class="rank-date">{{ FirstSignIn }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names">
                  <span>Last Activity</span>
                </div>
                <div class="student-names">
                  <span class="rank-date">{{ LastActivity }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names">
                  <span>Exam date</span>
                </div>
                <div class="student-names">
                  <span class="rank-date">{{ ExamDate }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names">
                  <span>Survey Completed</span>
                </div>
                <div class="student-names">
                  <span class="ranks border-0">
                    <img
                      src="../../../assets/images/true.svg"
                      alt="true"
                      *ngIf="SurComplete != null"
                    />
                    <img
                      src="../../../assets/images/wrong.svg"
                      alt="true"
                      *ngIf="SurComplete == null"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="assessments">
            <div class="assessments-title">
              <span title="Knowledge Assessment" *ngIf="Knowledge.length <= 4"
                >KA</span
              >
              <span title="Knowledge Assessment" *ngIf="Knowledge.length > 4"
                >Knowledge Assessment</span
              >
            </div>
            <div class="assessments-body">
              <div class="exam-detail" *ngFor="let item of Knowledge">
                <div class="student-names">
                  <span>{{ item.knowass_name }}</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ item.Percentage }}%</span>
                  <span class="rank-date"> {{ item.CompletedOn }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names">
                  <span class="totle-score">Average Score</span>
                </div>
                <div class="student-names">
                  <span class="ranks totle-score">{{ AverageScore }}%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="assessments">
            <div class="assessments-title">
              <span title="Practice Test" *ngIf="Practice.length <= 2">PT</span>
              <span title="Practice Test" *ngIf="Practice.length > 2"
                >Practice Test</span
              >
            </div>
            <div class="assessments-body">
              <div class="exam-detail" *ngFor="let item of Practice">
                <div class="student-names">
                  <span>{{ item.practice_name }}</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ item.Percentage }}%</span>
                  <span class="rank-date"> {{ item.CompletedOn }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names">
                  <span class="totle-score">Average Score</span>
                </div>
                <div class="student-names">
                  <span class="ranks totle-score">{{ AverageScorePt }}%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flashcards">
            <div class="assessments-title">
              <span>Flashcards</span>
            </div>
            <div class="assessments-body">
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span>Know</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ Flashcards.Know }}</span>
                  <span class="rank-date"></span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span>Review</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ Flashcards.Review }}</span>
                  <span class="rank-date"></span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span>Marked</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ Flashcards.Mark }}</span>
                  <span class="rank-date practice-date"></span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span class="totle-score">Unseen</span>
                </div>
                <div class="student-names">
                  <span class="ranks totle-score">{{ Flashcards.Unseen }}</span>
                  <span class="rank-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="practice">
            <div class="assessments-title">
              <span>Practice Question</span>
            </div>
            <div class="assessments-body">
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span>Right</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ PracticeQ.Right }}</span>
                  <span class="rank-date">{{ PracticeQ.Completed }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span>Wrong</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ PracticeQ.Wrong }}</span>
                  <span class="rank-date">{{ PracticeQ.Completed }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span>Marked</span>
                </div>
                <div class="student-names">
                  <span class="ranks">{{ PracticeQ.Marked }}</span>
                  <span class="rank-date">{{ PracticeQ.Completed }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span class="totle-score">Unseen</span>
                </div>
                <div class="student-names border-btm-active">
                  <span class="ranks totle-score">{{ PracticeQ.Unseen }}</span>
                  <span class="rank-date">{{ PracticeQ.Completed }}</span>
                </div>
              </div>
              <div class="exam-detail">
                <div class="student-names border-btm-active">
                  <span class="totle-score">Practice Exam Score</span>
                </div>
                <div class="student-names">
                  <span class="ranks totle-score"
                    >{{ PracticeQ.percScore }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="activity-taracker" id="activitytaracker">
      <div class="div-head">
        <span>Activity Tracker</span>
      </div>
      <div class="task-div-body">
        <div class="main-div" *ngFor="let item of Activities; let i = index">
          <div class="date">
            <span class="done-task"
              ><img
                *ngIf="item.completed"
                src="../../../assets/images/true.svg"
                alt="True"
            /></span>
            <span>{{ item.activity_date }}</span>
            <span>{{ item.activity_time }}</span>
          </div>
          <div class="task-name">
            <p>
              {{ item.pers
              }}<span [ngClass]="{ 'task-color': item.completed }">
                {{ item.test_name }}
              </span>
            </p>
          </div>
        </div>
        <div *ngIf="Activities.length <= 0">
          <p style="text-align: center; margin-top: 30px; color: #c2b8ff">
            No Activities found
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
