<div class="pagemain">
  <div class="pagetitle">
    <h3>
      <span class="material-icons" title="Back" routerLink="/main/livequestion"
        >arrow_back_ios</span
      >Result of {{ TestName }}
    </h3>
  </div>

  <div *ngFor="let item of Cohorts">
    <label>{{ item.cohort_name }}</label>
    <div class="tbl-wrap">
      <table>
        <tr class="header-background">
          <th class="student-name">Student Name</th>
          <th
            [ngClass]="{ 'que-1': q == 0 }"
            *ngFor="let que of Questions; let q = index"
            title="{{ que.question }}"
          >
            Q{{ q + 1 }}
          </th>
          <th>Score</th>
        </tr>
        <tr *ngFor="let std of item.users">
          <td class="students">{{ std.name }}</td>
          <td class="incorrect que-center">A</td>
          <td class="correct">C</td>
          <td class="incorrect">B</td>
          <td class="incorrect">A</td>
          <td class="incorrect">D</td>
          <td class="correct">D</td>
          <td>4 / {{ Questions.length }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
