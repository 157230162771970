import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  //start Templates
  async ListTemplates(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEMPLATE_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async DeleteTemplate(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEMPLATE_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async AddTemplate(
    name,
    certi_id,
    liveevent,
    video,
    mindmap,
    knowassessments,
    practicetests,
    cateexam,
    flashcard_app,
    practice_que_app,
    schedule,
    events,
    email_templates,
    access,
    start_date,
    baseline_ka,
    baseline_ka_schedule,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          certi_id: certi_id,
          live_events: liveevent,
          videos: video,
          mindmaps: mindmap,
          knowassessments: knowassessments,
          practicetests: practicetests.toString(),
          ketexamtests: cateexam,
          flashcard_app: flashcard_app,
          practice_que_app: practice_que_app,
          schedule: schedule,
          tevents: events,
          email_templates: email_templates,
          status: '0',
          video_access: access.VideoAccess,
          mindmap_access: access.MindmapAccess,
          summary_access: access.SummaryAccess,
          reference_access: access.ReferenceAccess,
          KA_access: access.KAAccess,
          PT_access: access.PTAccess,
          CAT_access: access.CATAccess,
          livechat_access: access.LiveChatAccess,
          liveq_access: access.LiveQuestionAccess,
          liveevent_access: access.LiveEventAccess,
          flashcard_access: access.FlashcardAccess,
          question_access: access.QuestionAccess,
          start_date: start_date,
          baseline_ka: baseline_ka,
          baseline_ka_schedule: baseline_ka_schedule,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEMPLATE_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetTemplateById(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEMPLATE_BY_ID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateTemplate(
    id,
    name,
    certi_id,
    liveevent,
    video,
    mindmap,
    knowassessments,
    practicetests,
    cateexam,
    flashcard_app,
    practice_que_app,
    schedule,
    events,
    email_templates,
    access,
    start_date,
    baseline_ka,
    baseline_ka_schedule,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          name: name,
          certi_id: certi_id,
          live_events: liveevent,
          videos: video,
          mindmaps: mindmap,
          knowassessments: knowassessments,
          practicetests: practicetests.toString(),
          ketexamtests: cateexam,
          flashcard_app: flashcard_app,
          practice_que_app: practice_que_app,
          schedule: schedule,
          tevents: events,
          email_templates: email_templates,
          status: '0',
          video_access: access.VideoAccess,
          mindmap_access: access.MindmapAccess,
          summary_access: access.SummaryAccess,
          reference_access: access.ReferenceAccess,
          KA_access: access.KAAccess,
          PT_access: access.PTAccess,
          CAT_access: access.CATAccess,
          livechat_access: access.LiveChatAccess,
          liveq_access: access.LiveQuestionAccess,
          liveevent_access: access.LiveEventAccess,
          flashcard_access: access.FlashcardAccess,
          question_access: access.QuestionAccess,
          start_date: start_date,
          baseline_ka: baseline_ka,
          baseline_ka_schedule: baseline_ka_schedule,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.TEMPLATE_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateCohortVideoMind(template_id, videos, mindmap, schedule, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          template_id: template_id,
          videos: videos ? videos : '',
          mindmaps: mindmap ? mindmap : '',
          schedule: schedule,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.UPDATE_COHORT_VIDEO_MIND;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getUserFromCohort(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_USER_FROM_COHORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  //end Templates
}
