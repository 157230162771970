import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../service/authorization.service';
import { ServicesService } from '../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CryptoService } from '../services/crypto/crypto.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  Email: any;
  Password: any;
  constructor(
    public router: Router,
    public spinner: NgxSpinnerService,
    public auth: AuthorizationService,
    public service: ServicesService,
    private crypto: CryptoService,
  ) {}
  ngOnInit() {
    var login = window.sessionStorage.getItem('logintrue');
    if (login) {
      this.router.navigate(['/main/dashboard']);
    }
  }
  async login() {
    if (this.Email == '' || this.Email == undefined) {
      this.service.ShowError('Email is required');
    } else if (this.Password == '' || this.Password == undefined) {
      this.service.ShowError('Password is required');
    } else {
      await this.spinner.show();
      try {
        await this.auth.signIn(this.Email, this.Password).then(
          async (data) => {
            if (data[0].StatusCode == 1) {
              var authenticatedUser = this.auth.getAuthenticatedUser();
              if (authenticatedUser == null) {
                this.router.navigate(['/login']);
              } else {
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      await this.service
                        .UsersFilter(this.Email, token)
                        .then(async (user) => {
                          if (user[0].status == 200) {
                            var list = JSON.parse(<string>user[0].json);
                            if (
                              list.data.roll_id == 1 ||
                              list.data.roll_id == 5
                            ) {
                              await this.spinner.hide();
                              window.localStorage.setItem(
                                'adminUser',
                                this.Email,
                              );
                              this.service.ShowSuccess('Login successfully!');
                              window.sessionStorage.setItem(
                                'b6h3a5d9r6e3s5h',
                                list.data.id,
                              );
                              window.sessionStorage.setItem(
                                'logintrue',
                                'true',
                              );
                              this.router.navigate(['/main/dashboard']);
                            } else {
                              await this.spinner.hide();
                              await this.auth.logOut();
                              this.service.ShowError(
                                'Incorrect username or password.',
                              );
                            }
                          } else {
                            await this.spinner.hide();
                            this.service.ShowSuccess(data[0].message);
                          }
                        });
                    }
                  });
              }
            } else {
              await this.service.ShowError(data[0].message);
              await this.spinner.hide();
            }
          },
          (err) => {
            this.spinner.hide();
            this.service.ShowError(err);
          },
        );
      } catch (e) {
        this.spinner.hide();
        this.service.ShowError(e);
      }
    }
  }
}
