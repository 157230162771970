import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class CertificateService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  //start Certificate
  async AddCertificate(
    certificate_name,
    short_name,
    live_course_thumbnail,
    live_welcome_thumbnail,
    live_welcome_video,
    self_course_thumbnail,
    self_welcome_thumbnail,
    self_welcome_video,
    hybrid_course_thumbnail,
    hybrid_welcome_thumbnail,
    hybrid_welcome_video,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certificate_name: certificate_name,
          short_name: short_name,
          access: '',
          live_course_thumbnail: live_course_thumbnail,
          live_welcome_thumbnail: live_welcome_thumbnail,
          live_welcome_video: live_welcome_video,
          status: '0',
          self_course_thumbnail: self_course_thumbnail,
          self_welcome_thumbnail: self_welcome_thumbnail,
          self_welcome_video: self_welcome_video,
          hybrid_course_thumbnail: hybrid_course_thumbnail,
          hybrid_welcome_thumbnail: hybrid_welcome_thumbnail,
          hybrid_welcome_video: hybrid_welcome_video,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CERTIFICATE_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateCertificate(
    id,
    certificate_name,
    short_name,
    live_course_thumbnail,
    live_welcome_thumbnail,
    live_welcome_video,
    self_course_thumbnail,
    self_welcome_thumbnail,
    self_welcome_video,
    hybrid_course_thumbnail,
    hybrid_welcome_thumbnail,
    hybrid_welcome_video,
    domain_sequence,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          certificate_name: certificate_name,
          short_name: short_name,
          access: '',
          live_course_thumbnail: live_course_thumbnail,
          live_welcome_thumbnail: live_welcome_thumbnail,
          live_welcome_video: live_welcome_video,
          status: '0',
          self_course_thumbnail: self_course_thumbnail,
          self_welcome_thumbnail: self_welcome_thumbnail,
          self_welcome_video: self_welcome_video,
          hybrid_course_thumbnail: hybrid_course_thumbnail,
          hybrid_welcome_thumbnail: hybrid_welcome_thumbnail,
          hybrid_welcome_video: hybrid_welcome_video,
          domain_sequence: domain_sequence,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CERTIFICATE_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async Deletecertificate(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.CERTIFICATE + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListCertificate(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CERTIFICATE_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async IdCertificate(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.CERTIFICATE + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //End Certificate
}
