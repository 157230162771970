import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { ClientService } from '../../services/client/client.service';
import { CohortService } from '../../services/cohort/cohort.service';

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss'],
})
export class TeachersComponent implements OnInit {
  addClient: boolean = false;
  SortingData = {
    active: '',
    direction: '',
  };
  public LoginForm: FormGroup;
  displayedColumns: string[] = [
    'SrNo',
    'name',
    'email',
    'mobile',
    'certificate_name',
    'actions',
  ];
  dataSource: MatTableDataSource<Userlist>;
  Search: any;
  PopUpshow = false;
  SrNo: number = 0;
  totalUsers: number;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public cohortService: CohortService,
    public clientService: ClientService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  IsUpdate: boolean = false;
  email: any;
  ClientName: any;
  ContactNumber: any;
  Password: any;
  selecteds = [];
  Certificates = [];
  Id: any;
  loglist = [];
  ForceChange: boolean = false;
  oldAuditValueArray = {
    Name: '',
    Email: '',
    'Mobile number': '',
    Certificates: '',
  };
  newAuditValueArray = {
    Name: '',
    Email: '',
    'Mobile number': '',
    Certificates: '',
  };
  auditHistory = '';
  async ngOnInit() {
    this.LoginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
          ),
        ]),
      ],
      ClientName: ['', Validators.required],
      ContactNumber: ['', Validators.required],
      Certificate: ['', Validators.required],
      ForceChange1: new FormControl(),
    });
    await this.spinner.show();
    await this.getAllTeachers(
      1,
      this.paginator ? this.paginator.pageSize : 10,
      '',
    );
    await this.getAllCertificates();
    await this.spinner.hide();
  }
  show() {
    this.spinner.show();
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  closeModal() {
    this.addClient = false;
    this.IsUpdate = false;
    this.Id = '';
    this.email = '';
    this.ClientName = '';
    this.ContactNumber = '';
    this.Password = '';
    this.selecteds = [];
    this.ForceChange = false;
    this.oldAuditValueArray = {
      Name: '',
      Email: '',
      'Mobile number': '',
      Certificates: '',
    };
    this.newAuditValueArray = {
      Name: '',
      Email: '',
      'Mobile number': '',
      Certificates: '',
    };
    this.auditHistory = '';
  }
  addNewClient() {
    this.addClient = true;
  }
  get f() {
    return this.LoginForm.controls;
  }
  async getAllCertificates() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          this.Certificates = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificates = list.data;
            this.selecteds = [];
          }
        });
      }
    });
  }
  delete(element) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this Teacher?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();

              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.auth.deleteuser(element.email).then(async (data) => {
                if (data[0].StatusCode == 1) {
                  await this.clientService
                    .deleteuser(element.id, token)
                    .then(async (userdata) => {
                      if (userdata[0].status == 200) {
                        this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              await this.spinner.hide();

                              this.router.navigate(['/login']);
                            } else {
                              var token = session.getIdToken().getJwtToken();
                              var e = window.localStorage.getItem('adminUser');
                              if (e == null || e == undefined || e == '') {
                                e = 'admin';
                              }
                              var desc =
                                '<span> ' +
                                element.email +
                                '</span> is deleted as Teacher by <span> ' +
                                e +
                                '</span>';
                              await this.service.addAdminLogs(
                                desc,
                                'Teacher',
                                element.id,
                                token,
                              );
                            }
                          });
                        await this.getAllTeachers(
                          1,
                          this.paginator ? this.paginator.pageSize : 10,
                          '',
                        );
                        await this.spinner.hide();
                        Swal.fire(
                          'Deleted!',
                          'Teacher delete successfully',
                          'success',
                        );
                      } else {
                        await this.spinner.hide();
                        await this.service.ShowError(
                          'Delete teacher unsuccessfully',
                        );
                      }
                    });
                } else {
                  if (data[0].message == 'User does not exist.') {
                    await this.clientService
                      .deleteuser(element.id, token)
                      .then(async (userdata) => {
                        if (userdata[0].status == 200) {
                          await this.getAllTeachers(
                            1,
                            this.paginator ? this.paginator.pageSize : 10,
                            '',
                          );
                          await this.spinner.hide();
                          Swal.fire(
                            'Deleted!',
                            'Teacher delete successfully',
                            'success',
                          );
                        } else {
                          await this.spinner.hide();
                          await this.service.ShowError(
                            'Delete teacher unsuccessfully',
                          );
                        }
                      });
                  } else {
                    await this.spinner.hide();
                    await this.service.ShowError(data[0].message);
                  }
                }
              });
            }
          });
      }
    });
  }

  async pageEvents(event: any) {}
  async getAllTeachers(pageNo, limit, search, active = '', direction = '') {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: Userlist[] = [];
            if (!this.Search) {
              this.Search = '';
            }
            await this.clientService
              .getLimitTeachers(pageNo, limit, search, active, direction, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.SrNo = (pageNo - 1) * limit;
                  await list.data.rows.forEach(async (element) => {
                    var certificate_name = '';
                    var certi_Ids = [];
                    if (element.notes.length > 0) {
                      for (let i = 0; i < element.notes.length; i++) {
                        certificate_name =
                          certificate_name +
                          element.notes[i].certificate_name +
                          ',';
                        certi_Ids.push(element.notes[i].id);
                      }
                      if (certificate_name.endsWith(',') == true) {
                        certificate_name = certificate_name.substring(
                          0,
                          certificate_name.length - 1,
                        );
                      }
                    }
                    this.SrNo = this.SrNo + 1;
                    await users.push({
                      SrNo: this.SrNo,
                      id: element.id,
                      name: element.name,
                      email: element.email,
                      roll_id: element.roll_id,
                      mobile: element.mobile,
                      createdAt: element.createdAt,
                      updatedAt: element.updatedAt,
                      certificate_name: certificate_name,
                      certi_Ids: certi_Ids,
                      password: element.password,
                    });
                  });
                  this.dataSource = new MatTableDataSource(users);
                  this.totalUsers = list.data.count;
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.pagination = true;
                  this.dataSource.sortingDataAccessor = (
                    data,
                    sortHeaderId,
                  ) => {
                    switch (sortHeaderId) {
                      case 'SrNo':
                        return data[sortHeaderId];
                      default:
                        return data[sortHeaderId].toLocaleLowerCase();
                    }
                  };
                } else {
                  this.spinner.hide();
                }
              });
          }
        });
    } catch (e) {
      this.spinner.hide();
    }
  }

  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async edit(item) {
    this.loglist = [];
    this.ForceChange = false;
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('Teacher', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.addClient = true;
    this.IsUpdate = true;
    this.Id = item.id;
    this.email = item.email;
    this.ClientName = item.name;
    this.ContactNumber = item.mobile;
    this.Password = item.password;
    this.selecteds = item.certi_Ids.map(Number);
    this.oldAuditValueArray = {
      Name: this.ClientName,
      Email: this.email,
      'Mobile number': this.ContactNumber,
      Certificates: this.Certificates.filter((item) => {
        return this.selecteds.includes(item.id);
      })
        .map(function (elem) {
          return elem.certificate_name;
        })
        .join(','),
    };
  }

  async saveTeacher() {
    this.markFormTouched(this.LoginForm);
    if (this.LoginForm.valid) {
      this.ClientName = this.ClientName.trim();
      if (
        this.ClientName == '' ||
        this.ClientName == undefined ||
        this.ClientName == null
      ) {
        this.service.ShowError('Teacher Name is required');
        return;
      }
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.email)) {
        this.service.ShowError('Please enter a valid email!');
        await this.spinner.hide();
        return;
      } else {
        await this.spinner.show();
        await this.auth.CreateUser(this.email).then(async (data) => {
          if (data[0].StatusCode == 1) {
            this.auth
              .getAuthenticatedUser()
              .getSession(async (err, session) => {
                if (err) {
                  await this.spinner.hide();
                  this.router.navigate(['/login']);
                } else {
                  var token = session.getIdToken().getJwtToken();
                  await this.clientService
                    .addTeacher(
                      this.ClientName,
                      this.email,
                      this.ContactNumber,
                      this.Password,
                      this.selecteds.toString(),
                      token,
                    )
                    .then(async (data) => {
                      if (data[0].status == 200) {
                        var list = JSON.parse(<string>data[0].json);
                        this.service.ShowSuccess('Teacher add successfully');

                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.email +
                          '</span> is added as Teacher by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'Teacher',
                          list.data.id,
                          token,
                        );

                        this.closeModal();
                        await this.getAllTeachers(
                          1,
                          this.paginator ? this.paginator.pageSize : 10,
                          '',
                        );
                        await this.spinner.hide();
                      } else {
                        await this.spinner.hide();
                      }
                    });
                }
              });
          } else {
            await this.spinner.hide();
            this.service.ShowError(data[0].message);
          }
        });
      }
    }
  }
  async updateTeacher() {
    this.markFormTouched(this.LoginForm);
    if (this.LoginForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            if (this.ForceChange == true) {
              await this.auth.forcechnagepass(this.email).then((data) => {});
            }
            var token = session.getIdToken().getJwtToken();
            await this.clientService
              .updateTeacher(
                this.Id,
                this.ClientName,
                this.ContactNumber,
                this.selecteds.toString(),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  await this.getAllTeachers(
                    1,
                    this.paginator ? this.paginator.pageSize : 10,
                    '',
                  );
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        this.newAuditValueArray = {
                          Name: this.ClientName,
                          Email: this.email,
                          'Mobile number': this.ContactNumber,
                          Certificates: this.Certificates.filter((item) => {
                            return this.selecteds.includes(item.id);
                          })
                            .map(function (elem) {
                              return elem.certificate_name;
                            })
                            .join(','),
                        };
                        var audit = this.auth.compareAuditHist(
                          this.oldAuditValueArray,
                          this.newAuditValueArray,
                        );
                        var desc = audit;
                        await this.service.addAdminLogs(
                          desc,
                          'Teacher',
                          this.Id,
                          token,
                        );
                      }
                    });
                  await this.getAllTeachers(
                    1,
                    this.paginator ? this.paginator.pageSize : 10,
                    '',
                  );
                  this.closeModal();
                  this.service.ShowSuccess('Teacher update successfully');
                  await this.spinner.hide();
                } else {
                  await this.spinner.hide();
                }
              });
          }
        });
    }
  }
}
export interface Userlist {
  SrNo: number;
  id: string;
  name: string;
  email: string;
  roll_id: string;
  mobile: string;
  createdAt: string;
  updatedAt: string;
  certificate_name: string;
  certi_Ids: string[];
  password: string;
}
