<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Sub Topics</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search.."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewSubTopic()">Add Sub Topic</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subtopic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub Topic Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subtopic_name }}">{{ row.subtopic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domain }}">{{ row.domain }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain }}">{{ row.subdomain }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.topic_name }}">{{ row.topic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addSubTopic">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Sub Topic</p>
      <p *ngIf="IsUpdate">Edit Sub Topic</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="TopicForm">
          <div class="formwrapper">
            <div>
              <label>Course</label>
              <div class="select-box">
                <select
                  [(ngModel)]="CourseName"
                  formControlName="CourseName"
                  (change)="Coursechange($event)"
                  required
                  [ngClass]="{
                    err:
                      TopicForm.controls['CourseName'].hasError('required') &&
                      (TopicForm.controls['CourseName'].dirty ||
                        TopicForm.controls['CourseName'].touched)
                  }"
                >
                  <option value="">Select</option>
                  <option
                    *ngFor="let item of ListCoursedata"
                    value="{{ item.id }}"
                  >
                    {{ item.course_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Domain Name</label>
              <div class="select-box">
                <select
                  [(ngModel)]="domainname"
                  (change)="domainchange($event)"
                  (keyup)="domainchange($event)"
                  (keydown)="domainchange($event)"
                  formControlName="Domainname"
                  required
                  [ngClass]="{
                    err:
                      TopicForm.controls['Domainname'].hasError('required') &&
                      (TopicForm.controls['Domainname'].dirty ||
                        TopicForm.controls['Domainname'].touched)
                  }"
                >
                  <option selected="true" value="">Select</option>
                  <option *ngFor="let item of Domainlist" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Sub Domain Name</label>
              <div class="select-box">
                <select
                  (change)="SubDomainchange($event)"
                  [attr.disabled]="this.domainname == '' ? true : null"
                  [(ngModel)]="subdomainname"
                  formControlName="subdomainname"
                  required
                  [ngClass]="{
                    err:
                      TopicForm.controls['subdomainname'].hasError(
                        'required'
                      ) &&
                      (TopicForm.controls['subdomainname'].dirty ||
                        TopicForm.controls['subdomainname'].touched)
                  }"
                >
                  <option selected="true" value="">Select</option>
                  <option
                    *ngFor="let item of subDomainlist"
                    value="{{ item.id }}"
                  >
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Topic Name</label>
              <div class="select-box">
                <select
                  [(ngModel)]="Topicname"
                  [attr.disabled]="this.subdomainname == '' ? true : null"
                  formControlName="Topicname"
                  required
                  [ngClass]="{
                    err:
                      TopicForm.controls['Topicname'].hasError('required') &&
                      (TopicForm.controls['Topicname'].dirty ||
                        TopicForm.controls['Topicname'].touched)
                  }"
                >
                  <option value="">Select</option>
                  <option *ngFor="let item of Topic" value="{{ item.id }}">
                    {{ item.topic_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Sub Topic Name</label>
              <input
                [(ngModel)]="SubTopicname"
                formControlName="SubTopicname"
                placeholder="Enter Sub Topic Name"
                [ngClass]="{
                  err:
                    TopicForm.controls['SubTopicname'].hasError('required') &&
                    (TopicForm.controls['SubTopicname'].dirty ||
                      TopicForm.controls['SubTopicname'].touched)
                }"
              />
            </div>
          </div>
          <div>
            <!-- [editor]="Editor" -->
            <label class="Ck-Editor-span">Explanation</label>
            <ckeditor
              formControlName="Explanation"
              [(ngModel)]="Explanation"
              id="Explanation"
              name="Explanation"
            >
            </ckeditor>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="Update()">Save</button>
      </div>
    </div>
  </div>
</div>
