<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h2 class="self-title">Self-Paced Classes</h2>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Self-Paced Class Name"
          name="search"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewCohort()">Add Self-Paced Class</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="class_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Self-paced Class Name
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.class_name }}">
              {{ element.class_name }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="count_userslist">
          <th mat-header-cell *matHeaderCellDef mat-sort-header># Students</th>
          <td mat-cell *matCellDef="let element">
            <span
              title="{{
                element.count_userslist ? element.count_userslist : 0
              }}"
              >{{ element.count_userslist ? element.count_userslist : 0 }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              alt="Edit"
              class="action-icon"
              (click)="edit(row)"
            />

            <img
              src="../../../assets/images/Report.png"
              alt="Report"
              class="action-icon"
              (click)="gotoCohortReport(row)"
            />
            <img
              src="../../../assets/images/remove.png"
              [title]="row.course_type == 'free' ? 'Free class' : 'Delete'"
              [ngClass]="{ disbalebutton: row.course_type == 'free' }"
              alt="Delete"
              class="action-icon"
              (click)="delete(row)"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>

<div class="cutome-modal" *ngIf="addSelf">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Self-Paced Class</p>
      <p *ngIf="IsUpdate">Edit Self-Paced Class</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div class="formwrapper">
        <div>
          <div class="course-select course-select-border">
            <div class="course-input select-box">
              <label for="Name">Pricing and features</label>
              <select
                [(ngModel)]="selectedPaymentMethod"
                (change)="addPaymentMethod()"
                [disabled]="IsUpdate"
              >
                <option value="">Free trial course</option>
                <option value="0">Subscription</option>
                <option value="1">One-time payment</option>
              </select>
              <span></span>
            </div>
          </div>
        </div>
        <div>
          <div class="course-select course-select-border">
            <div class="course-input select-box">
              <label for="Name">Certification</label>
              <select
                [(ngModel)]="SelectedCertificate"
                (change)="changeCertificate()"
              >
                <option value="">Select Certification</option>
                <option *ngFor="let item of Certificates" value="{{ item.id }}">
                  {{ item.certificate_name }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
        </div>
        <div>
          <div class="course-select course-select-border">
            <div class="course-input">
              <ng-moment-timezone-picker
                customPlaceholderText="Time Zone"
                [(ngModel)]="time_zone"
              >
              </ng-moment-timezone-picker>
            </div>
          </div>
        </div>
        <div>
          <div class="course-select course-select-border">
            <div class="course-input select-box">
              <select [(ngModel)]="SelectedMentor">
                <option value="">Class Mentor</option>
                <option *ngFor="let item of Mentors" value="{{ item.id }}">
                  {{ item.name }}
                </option>
              </select>
              <span style="top: 5px"></span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="FreeTrial">
        <div class="main_title" *ngIf="IsUpdate">
          <span>{{ certificate_name }}</span>
        </div>
        <div class="free-course">
          <span>Free trial course</span>
        </div>

        <div class="free-course-title">
          <input
            type="text"
            placeholder="Class name"
            [(ngModel)]="class_name"
          />
        </div>

        <div class="course-textarea">
          <textarea
            placeholder="Type here..."
            [(ngModel)]="description"
          ></textarea>
        </div>
        <div class="course-select course-select-border">
          <div class="select-box course-input course-select-border">
            <label for="Name">Company</label>
            <select [(ngModel)]="SelectedCompany">
              <option value="">Company</option>
              <option value="{{ item.id }}" *ngFor="let item of Companies">
                {{ item.name }}
              </option>
            </select>
            <span></span>
          </div>
        </div>
        <div class="visible-trial">
          <h2 class="visible-title">Features visible in free trial</h2>
          <div class="chkboxs">
            <div class="check-app">
              <label class="container">
                Videos
                <input type="checkbox" [(ngModel)]="VideoAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Mindmaps
                <input type="checkbox" [(ngModel)]="MindmapAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Summaries
                <input type="checkbox" [(ngModel)]="SummaryAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                References
                <input type="checkbox" [(ngModel)]="ReferenceAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Knowledge Assessments
                <input type="checkbox" [(ngModel)]="KAAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Tests
                <input type="checkbox" [(ngModel)]="PTAccess" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-app">
              <label class="container">
                CAT Exams (free per attempt)
                <input type="checkbox" [(ngModel)]="CATAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Chat
                <input type="checkbox" [(ngModel)]="LiveChatAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Questions
                <input type="checkbox" [(ngModel)]="LiveQuestionAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Events
                <input type="checkbox" [(ngModel)]="LiveEventAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Flashcard App (full access)
                <input type="checkbox" [(ngModel)]="FashFullAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Question App (full access)
                <input type="checkbox" [(ngModel)]="PraAppFullAccess" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="course-select">
          <div class="select-box course-input">
            <label for="Name">Knowledge Assessment</label>
            <select [(ngModel)]="SelectedKA">
              <option
                value="{{ item.id }}"
                *ngFor="let item of KnowledgeAssessments"
              >
                {{ item.knowass_name }}
              </option>
            </select>
            <span></span>
          </div>
          <div class="select-box course-input">
            <label for="Name">Practice Tests</label>
            <select [(ngModel)]="SelectedPT">
              <option value="{{ item.id }}" *ngFor="let item of PracticeExams">
                {{ item.practice_name }}
              </option>
            </select>
            <span></span>
          </div>
          <div class="course-input">
            <label for="Name">Live Events</label>
            <ng-select
              [items]="LiveEvents"
              bindLabel="event_name"
              bindValue="id"
              placeholder="Select live events"
              appendTo="body"
              multiple="true"
              [(ngModel)]="SelectedEvents"
            >
            </ng-select>
          </div>
          <div class="course-input">
            <label class="container">
              Only show study material linked to topics in knowledge/practice
              exam?
              <input type="checkbox" [(ngModel)]="only_show_study" />
              <span class="checkmark"></span>
            </label>
            <label class="container">
              Publish free trial course
              <input type="checkbox" [(ngModel)]="publish_free_trial" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="Subscription">
        <div class="main_title" *ngIf="IsUpdate">
          <span>{{ certificate_name }}</span>
        </div>
        <div class="subscription">
          <div class="sub-label">
            <span>Subscription</span>
            <div class="sub-icon">
              <span>
                <mat-icon (click)="generateMainSubscriptionLink()"
                  >link</mat-icon
                >
              </span>
              <span>
                <mat-icon (click)="deletePaymentMethod(0)">delete</mat-icon>
              </span>
            </div>
          </div>
          <div class="price-label">
            <label for="name">Price Label</label>
            <div class="free-course-title">
              <input
                type="text"
                placeholder="Subscription"
                [(ngModel)]="SPriceLabel"
              />
            </div>
          </div>
          <div class="course-select course-select-border">
            <div class="course-input">
              <label for="Name">Company</label>
              <select [(ngModel)]="SSelectedCompany">
                <option value="">Company</option>
                <option value="{{ item.id }}" *ngFor="let item of Companies">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="chkboxs">
            <div class="check-app">
              <label class="container">
                Videos
                <input
                  type="checkbox"
                  [(ngModel)]="SVideoAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Mindmaps
                <input
                  type="checkbox"
                  [(ngModel)]="SMindmapAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Summaries
                <input type="checkbox" [(ngModel)]="SSummaryAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                References
                <input type="checkbox" [(ngModel)]="SReferenceAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Knowledge Assessments
                <input
                  type="checkbox"
                  [(ngModel)]="SKAAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Tests
                <input
                  type="checkbox"
                  [(ngModel)]="SPTAccess"
                  (change)="ChangeSubscriptionMaterial()"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-app">
              <label class="container">
                CAT Exams (free per attempt)
                <input type="checkbox" [(ngModel)]="SCATAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Chat
                <input type="checkbox" [(ngModel)]="SLiveChatAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Questions
                <input type="checkbox" [(ngModel)]="SLiveQuestionAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Events
                <input
                  type="checkbox"
                  [(ngModel)]="SLiveEventAccess"
                  (change)="ChangeSubscriptionLiveEvent()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Flashcard App (full access)
                <input type="checkbox" [(ngModel)]="SFashFullAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Question App (full access)
                <input type="checkbox" [(ngModel)]="SPraAppFullAccess" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="live-events">
          <div class="course-input" *ngIf="SLiveEventAccess">
            <label for="Name">Live Events</label>
            <ng-select
              [items]="LiveEvents"
              bindLabel="event_name"
              bindValue="id"
              placeholder="Select live events"
              appendTo="body"
              multiple="true"
              [(ngModel)]="SSelectedEvents"
            >
            </ng-select>
          </div>
          <div class="pricing">
            <h2 class="pricing-title">Add Schedule items</h2>
            <div class="select-pricing">
              <select [(ngModel)]="ScheduleItem">
                <option value="Section">Section</option>
                <option value="Flashcard App">Flashcard App</option>
                <option value="Practice Question App">
                  Practice Question App
                </option>
                <option value="Download">Download</option>
                <option value="Video">Video</option>
              </select>
              <button (click)="addScheduleItems()">Add</button>
            </div>
          </div>
          <div class="course-input">
            <span class="domain-title">Study Material</span>
            <div cdkDropList (cdkDropListDropped)="drop($event)">
              <div
                cdkDrag
                *ngFor="let item of StudyMaterialsSub; let i = index"
              >
                <div
                  id="select"
                  class="all-domain"
                  style="
                    border: 1px solid #303563;
                    padding: 5px 10px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    cursor: move;
                  "
                  *ngIf="item.material_type == 'title' || item.visible_material"
                >
                  <div
                    *ngIf="item.material_type == 'title'"
                    style="display: flex; width: 100%"
                  >
                    <div style="width: 90%; display: flex; align-items: center">
                      <div style="line-height: 0">
                        <img
                          src="../../../assets/images/sidebar.JPG"
                          alt=""
                          style="width: 13px; line-height: 0"
                        />
                      </div>
                      <div class="select-box">
                        <img
                          src="../../../assets/images/{{ item.icon }}"
                          alt=""
                          style="margin: 0 10px 0 10px"
                        />
                      </div>
                      <div
                        class="domain-name"
                        style="display: flex; align-items: center; width: 100%"
                      >
                        <span style="color: #9891d1; margin-right: 5px">{{
                          item.title_type
                        }}</span>
                        <span
                          style="color: #9891d1; width: 84%"
                          *ngIf="
                            item.title_type != 'Download' &&
                            item.title_type != 'Video'
                          "
                        >
                          <input
                            type="text"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="border: solid 1px #4a4a80"
                          />
                        </span>
                        <span
                          style="color: #9891d1; width: 84%"
                          *ngIf="item.title_type == 'Video'"
                        >
                          <input
                            type="text"
                            placeholder="Title"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <input
                            type="text"
                            placeholder="Description"
                            [(ngModel)]="item.download_description"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <input
                            type="text"
                            placeholder="Duration"
                            [(ngModel)]="item.duration"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <input
                            type="url"
                            placeholder="Upload video url 1080p"
                            #myInputFile
                            [(ngModel)]="item.download_url"
                            (click)="(myInputFile.value)"
                            (change)="handleFileSelect($event, item)"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <!-- show transcoder link -->
                          <ng-container
                            *ngIf="item.id == selectedId && openClose"
                          >
                            <input
                              type="url"
                              placeholder="Upload video url 720p"
                              #myInputFile2
                              [(ngModel)]="item.download_url_720p"
                              (click)="(myInputFile2.value)"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                              style="
                                border: solid 1px #4a4a80;
                                margin-bottom: 5px;
                              "
                            />
                            <input
                              type="url"
                              placeholder="Upload video url 480p"
                              #myInputFile3
                              [(ngModel)]="item.download_url_480p"
                              (click)="(myInputFile3.value)"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                              style="
                                border: solid 1px #4a4a80;
                                margin-bottom: 5px;
                              "
                            />
                            <input
                              type="url"
                              placeholder="Upload video url 360p"
                              #myInputFile4
                              [(ngModel)]="item.download_url_360p"
                              (click)="(myInputFile4.value)"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                              style="
                                border: solid 1px #4a4a80;
                                margin-bottom: 5px;
                              "
                            />
                            <input
                              type="url"
                              placeholder="Upload video url 240p"
                              #myInputFile5
                              [(ngModel)]="item.download_url_240p"
                              (click)="(myInputFile5.value)"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                              style="
                                border: solid 1px #4a4a80;
                                margin-bottom: 5px;
                              "
                            />
                            <input
                              type="url"
                              placeholder="Upload video url 144p"
                              #myInputFile6
                              [(ngModel)]="item.download_url_144p"
                              (click)="(myInputFile6.value)"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                              style="
                                border: solid 1px #4a4a80;
                                margin-bottom: 5px;
                              "
                            />
                          </ng-container>
                        </span>
                        <span
                          style="color: #9891d1; width: 84%"
                          *ngIf="item.title_type == 'Download'"
                        >
                          <input
                            type="text"
                            placeholder="Title"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <input
                            type="text"
                            placeholder="Description"
                            [(ngModel)]="item.download_description"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <input
                            type="url"
                            placeholder="Upload Material url"
                            #myInputFile
                            [(ngModel)]="item.download_url"
                            (click)="(myInputFile.value)"
                            (change)="handleFileSelect($event, item)"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                        </span>
                        <span (click)="deleteScheduleItems(item)">
                          <mat-icon
                            style="
                              border: solid 2px #8339c8;
                              border-radius: 50%;
                              font-size: 16px;
                              color: #8339c8;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              font-weight: 600;
                              margin-left: 6px;
                            "
                          >
                            clear</mat-icon
                          >
                        </span>
                        <span
                          (click)="showtranscode(item)"
                          *ngIf="item.title_type == 'Video'"
                        >
                          <mat-icon
                            style="
                              border: solid 2px #8339c8;
                              border-radius: 50%;
                              font-size: 16px;
                              color: #8339c8;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              font-weight: 600;
                              margin-left: 6px;
                            "
                          >
                            tune
                          </mat-icon>
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        width: 10%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                    >
                      <span
                        (click)="moveup(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_upward</mat-icon
                        >
                      </span>
                      <span
                        (click)="movedown(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                          >arrow_downward</mat-icon
                        >
                      </span>
                    </div>
                  </div>

                  <div
                    *ngIf="
                      item.material_type != 'title' && item.visible_material
                    "
                    style="display: flex; width: 100%"
                  >
                    <div style="width: 90%; display: flex; align-items: center">
                      <div style="margin-right: 20px; line-height: 0">
                        <img
                          src="../../../assets/images/sidebar.JPG"
                          alt=""
                          style="width: 13px; line-height: 0"
                        />
                      </div>
                      <div class="select-box">
                        <label class="container">
                          <input
                            type="checkbox"
                            [checked]="item.is_checked"
                            [(ngModel)]="item.is_checked"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div
                        class="domain-name"
                        style="display: flex; align-items: center"
                      >
                        <img
                          src="../../../assets/images/{{ item.icon }}"
                          alt=""
                          style="margin: 0 10px 0 10px"
                        />
                        <span style="color: #9891d1"
                          >{{ item.material_type }}: {{ item.name }}</span
                        >
                      </div>
                    </div>
                    <div
                      style="
                        width: 10%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                    >
                      <span
                        (click)="moveup(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_upward</mat-icon
                        >
                      </span>
                      <span
                        (click)="movedown(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                          >arrow_downward</mat-icon
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="course-input domain-checkbox">
              <label class="container">
                Only show study material linked to topics in knowledge/practice
                exam?
                <input type="checkbox" [(ngModel)]="sonly_show_study" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="cat-examdiv">
          <div class="main-cat">
            <label for="number">CAT Exam attempts included</label>
            <input type="number" [(ngModel)]="cat_exam_attempts" />
          </div>
          <div class="main-cat">
            <label for="number">Additional CAT Exam attempts fee</label>
            <input type="text" [(ngModel)]="attempts_fee" />
          </div>
        </div>

        <div class="desc-table">
          <table class="fixed_headers">
            <thead>
              <tr>
                <th class="colum-1" colspan="6">
                  <span>Description</span>
                </th>
                <th class="colum-2" colspan="3">
                  <span>Access Duration</span>
                </th>
                <th class="colum-2" colspan="3">
                  <span>Price</span>
                </th>
                <th class="colum-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of SubscriptionList">
                <td class="colum-1" colspan="6">
                  <div class="desc-input">
                    <input
                      type="text"
                      [(ngModel)]="item.description"
                      placeholder="Description"
                    />
                  </div>
                </td>
                <td class="colum-2" colspan="3">
                  <div class="days-input">
                    <input type="number" [(ngModel)]="item.access_duration" />
                    <span>days</span>
                  </div>
                </td>
                <td class="colum-2" colspan="3">
                  <div class="desc-input">
                    <input type="text" [(ngModel)]="item.price" />
                  </div>
                </td>
                <td class="colum-2">
                  <span>
                    <mat-icon (click)="generateSubscriptionLink(item)"
                      >link</mat-icon
                    >
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="add-rowbtn">
            <button (click)="addSubscription()">Add New</button>
          </div>
          <div class="desc-checkbox">
            <label class="container">
              Publish this subscription
              <input type="checkbox" [(ngModel)]="publish_subscription" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="OneTime">
        <!-- add certificate_name for show certificate name in OneTime filed -->
        <div class="main_title" *ngIf="IsUpdate">
          <span>{{ certificate_name }}</span>
        </div>
        <div class="subscription">
          <div class="sub-label">
            <span>One-time payment</span>
            <div class="sub-icon">
              <span>
                <mat-icon (click)="generateMainOneTimeLink()">link</mat-icon>
              </span>
              <span>
                <mat-icon (click)="deletePaymentMethod(1)">delete</mat-icon>
              </span>
            </div>
          </div>
          <div class="price-label">
            <label for="name">Price Label</label>
            <div class="free-course-title">
              <input
                type="text"
                placeholder="Subscription"
                [(ngModel)]="oprice_label"
              />
            </div>
          </div>
          <div class="course-select course-select-border">
            <div class="course-input">
              <label for="Name">Company</label>
              <select [(ngModel)]="OSelectedCompany">
                <option value="">Company</option>
                <option value="{{ item.id }}" *ngFor="let item of Companies">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="chkboxs">
            <div class="check-app">
              <label class="container">
                Videos
                <input
                  type="checkbox"
                  [(ngModel)]="OVideoAccess"
                  (change)="ChangeOnetimeMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Mindmaps
                <input
                  type="checkbox"
                  [(ngModel)]="OMindmapAccess"
                  (change)="ChangeOnetimeMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Summaries
                <input type="checkbox" [(ngModel)]="OSummaryAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                References
                <input type="checkbox" [(ngModel)]="OReferenceAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Knowledge Assessments
                <input
                  type="checkbox"
                  [(ngModel)]="OKAAccess"
                  (change)="ChangeOnetimeMaterial()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Tests
                <input
                  type="checkbox"
                  [(ngModel)]="OPTAccess"
                  (change)="ChangeOnetimeMaterial()"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-app">
              <label class="container">
                CAT Exams (free per attempt)
                <input type="checkbox" [(ngModel)]="OCATAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Chat
                <input type="checkbox" [(ngModel)]="OLiveChatAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Questions
                <input type="checkbox" [(ngModel)]="OLiveQuestionAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Events
                <input
                  type="checkbox"
                  [(ngModel)]="OLiveEventAccess"
                  (change)="ChangeOneTimeLiveEvent()"
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Flashcard App (full access)
                <input type="checkbox" [(ngModel)]="OFashFullAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Question App (full access)
                <input type="checkbox" [(ngModel)]="OPraAppFullAccess" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="live-events">
          <div class="course-input" *ngIf="OLiveEventAccess">
            <label for="Name">Live Events</label>
            <ng-select
              [items]="LiveEvents"
              bindLabel="event_name"
              bindValue="id"
              placeholder="Select live events"
              appendTo="body"
              multiple="true"
              [(ngModel)]="OSelectedEvents"
            >
            </ng-select>
          </div>
          <div class="pricing">
            <h2 class="pricing-title">Add Schedule items</h2>
            <div class="select-pricing">
              <select [(ngModel)]="OScheduleItem">
                <option value="Section">Section</option>
                <option value="Flashcard App">Flashcard App</option>
                <option value="Practice Question App">
                  Practice Question App
                </option>
                <option value="Download">Download</option>
                <option value="Video">Video</option>
              </select>
              <button (click)="addOScheduleItems()">Add</button>
            </div>
          </div>
          <div class="course-input">
            <span class="domain-title">Study Material</span>
            <div cdkDropList (cdkDropListDropped)="dropOne($event)">
              <div
                cdkDrag
                *ngFor="let item of StudyMaterialsOne; let i = index"
              >
                <div
                  id="select"
                  class="all-domain"
                  style="
                    border: 1px solid #303563;
                    padding: 5px 10px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    cursor: move;
                  "
                  *ngIf="item.material_type == 'title' || item.visible_material"
                >
                  <div
                    *ngIf="item.material_type == 'title'"
                    style="display: flex; width: 100%"
                  >
                    <div style="width: 90%; display: flex; align-items: center">
                      <div style="line-height: 0">
                        <img
                          src="../../../assets/images/sidebar.JPG"
                          alt=""
                          style="width: 13px; line-height: 0"
                        />
                      </div>
                      <div class="select-box">
                        <img
                          src="../../../assets/images/{{ item.icon }}"
                          alt=""
                          style="margin: 0 10px 0 10px"
                        />
                      </div>
                      <div
                        class="domain-name"
                        style="display: flex; align-items: center; width: 100%"
                      >
                        <span style="color: #9891d1; margin-right: 5px">{{
                          item.title_type
                        }}</span>
                        <span
                          style="color: #9891d1; width: 84%"
                          *ngIf="
                            item.title_type != 'Download' &&
                            item.title_type != 'Video'
                          "
                        >
                          <input
                            type="text"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="border: solid 1px #4a4a80"
                          />
                        </span>
                        <span
                          style="color: #9891d1; width: 84%"
                          *ngIf="item.title_type == 'Download'"
                        >
                          <input
                            type="text"
                            placeholder="Title"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <input
                            type="text"
                            placeholder="Description"
                            [(ngModel)]="item.download_description"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <input
                            type="url"
                            placeholder="Upload Material url"
                            #myInputFile1
                            [(ngModel)]="item.download_url"
                            (click)="(myInputFile1.value)"
                            (change)="handleFileSelect($event, item)"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                        </span>
                        <span
                          style="color: #9891d1; width: 84%"
                          *ngIf="item.title_type == 'Video'"
                        >
                          <input
                            type="text"
                            placeholder="Title"
                            [(ngModel)]="item.title_name"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />

                          <input
                            type="text"
                            placeholder="Description"
                            [(ngModel)]="item.download_description"
                            class="textbox"
                            style="
                              border: solid 1px #4a4a80;
                              margin-bottom: 5px;
                            "
                          />
                          <span>
                            <input
                              type="text"
                              placeholder="Duration"
                              [(ngModel)]="item.duration"
                              class="textbox"
                              style="
                                border: solid 1px #4a4a80;
                                margin-bottom: 5px;
                              "
                            />
                            <input
                              type="url"
                              placeholder="Upload video url"
                              #myInputFile1
                              [(ngModel)]="item.download_url"
                              (click)="(myInputFile1.value)"
                              (change)="handleFileSelect($event, item)"
                              class="textbox"
                              style="
                                border: solid 1px #4a4a80;
                                margin-bottom: 5px;
                              "
                            />
                            <!-- show transcoder link -->
                            <ng-container
                              *ngIf="item.id == selectedId && openClose"
                            >
                              <input
                                type="url"
                                placeholder="Upload video url 720p"
                                #myInputFile7
                                [(ngModel)]="item.download_url_720p"
                                (click)="(myInputFile7.value)"
                                (change)="handleFileSelect($event, item)"
                                class="textbox"
                                style="
                                  border: solid 1px #4a4a80;
                                  margin-bottom: 5px;
                                "
                              />
                              <input
                                type="url"
                                placeholder="Upload video url 480p"
                                #myInputFile8
                                [(ngModel)]="item.download_url_480p"
                                (click)="(myInputFile8.value)"
                                (change)="handleFileSelect($event, item)"
                                class="textbox"
                                style="
                                  border: solid 1px #4a4a80;
                                  margin-bottom: 5px;
                                "
                              />
                              <input
                                type="url"
                                placeholder="Upload video url 360p"
                                #myInputFile9
                                [(ngModel)]="item.download_url_360p"
                                (click)="(myInputFile9.value)"
                                (change)="handleFileSelect($event, item)"
                                class="textbox"
                                style="
                                  border: solid 1px #4a4a80;
                                  margin-bottom: 5px;
                                "
                              />
                              <input
                                type="url"
                                placeholder="Upload video url 240p"
                                #myInputFile10
                                [(ngModel)]="item.download_url_240p"
                                (click)="(myInputFile10.value)"
                                (change)="handleFileSelect($event, item)"
                                class="textbox"
                                style="
                                  border: solid 1px #4a4a80;
                                  margin-bottom: 5px;
                                "
                              />
                              <input
                                type="url"
                                placeholder="Upload video url 144p"
                                #myInputFile11
                                [(ngModel)]="item.download_url_144p"
                                (click)="(myInputFile11.value)"
                                (change)="handleFileSelect($event, item)"
                                class="textbox"
                                style="
                                  border: solid 1px #4a4a80;
                                  margin-bottom: 5px;
                                "
                              />
                            </ng-container>
                          </span>
                        </span>
                        <span (click)="deleteOScheduleItems(item)">
                          <mat-icon
                            style="
                              border: solid 2px #8339c8;
                              border-radius: 50%;
                              font-size: 16px;
                              color: #8339c8;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              font-weight: 600;
                              margin-left: 6px;
                            "
                          >
                            clear</mat-icon
                          >
                        </span>
                        <!--transcoder button-->
                        <span
                          (click)="showtranscode(item)"
                          *ngIf="item.title_type == 'Video'"
                        >
                          <mat-icon
                            style="
                              border: solid 2px #8339c8;
                              border-radius: 50%;
                              font-size: 16px;
                              color: #8339c8;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              font-weight: 600;
                              margin-left: 6px;
                            "
                          >
                            tune
                          </mat-icon>
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        width: 10%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                    >
                      <span
                        (click)="moveupOne(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_upward</mat-icon
                        >
                      </span>
                      <span
                        (click)="movedownOne(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                          >arrow_downward</mat-icon
                        >
                      </span>
                    </div>
                  </div>

                  <div
                    *ngIf="item.material_type != 'title'"
                    style="display: flex; width: 100%"
                  >
                    <div style="width: 90%; display: flex; align-items: center">
                      <div style="margin-right: 20px; line-height: 0">
                        <img
                          src="../../../assets/images/sidebar.JPG"
                          alt=""
                          style="width: 13px; line-height: 0"
                        />
                      </div>
                      <div class="select-box">
                        <label class="container">
                          <input
                            type="checkbox"
                            [checked]="item.is_checked"
                            [(ngModel)]="item.is_checked"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div
                        class="domain-name"
                        style="display: flex; align-items: center"
                      >
                        <img
                          src="../../../assets/images/{{ item.icon }}"
                          alt=""
                          style="margin: 0 10px 0 10px"
                        />
                        <span style="color: #9891d1"
                          >{{ item.material_type }}: {{ item.name }}</span
                        >
                      </div>
                    </div>
                    <div
                      style="
                        width: 10%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                    >
                      <span
                        (click)="moveupOne(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          arrow_upward</mat-icon
                        >
                      </span>
                      <span
                        (click)="movedownOne(i)"
                        style="
                          background: #222650;
                          width: 25px;
                          height: 25px;
                          border-radius: 4px;
                          margin-left: 4px;
                        "
                      >
                        <mat-icon
                          style="
                            font-size: 20px;
                            color: #434473;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                          >arrow_downward</mat-icon
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="course-input domain-checkbox">
              <label class="container">
                Only show study material linked to topics in knowledge/practice
                exam?
                <input type="checkbox" [(ngModel)]="Oonly_show_study" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="cat-examdiv">
          <div class="main-cat">
            <label for="number">CAT Exam attempts included</label>
            <input type="number" [(ngModel)]="ocat_exam_attempts" />
          </div>
          <div class="main-cat">
            <label for="number">Additional CAT Exam attempts fee</label>
            <input type="text" [(ngModel)]="oattempts_fee" />
          </div>
        </div>

        <div class="desc-table">
          <table class="fixed_headers">
            <thead>
              <tr>
                <th class="colum-1" colspan="6">
                  <span>Description</span>
                </th>
                <th class="colum-2" colspan="3">
                  <span>Access Duration</span>
                </th>
                <th class="colum-2" colspan="3">
                  <span>Price</span>
                </th>
                <th class="colum-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="colum-1" colspan="6">
                  <div class="desc-input">
                    <input type="text" [(ngModel)]="Odescription" />
                  </div>
                </td>
                <td class="colum-2" colspan="3">
                  <div class="days-input">
                    <input type="number" [(ngModel)]="Oaccess_duration" />
                    <span>days</span>
                  </div>
                </td>
                <td class="colum-2" colspan="3">
                  <div class="desc-input">
                    <input type="text" [(ngModel)]="Oprice" />
                  </div>
                </td>
                <td class="colum-2">
                  <span>
                    <mat-icon (click)="generateMainOneTimeLink()"
                      >link</mat-icon
                    >
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="my-20">
            <label class="container">
              Publish this subscription
              <input type="checkbox" [(ngModel)]="Opublish_subscription" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="saveSelfPaced()">Save</button>
      </div>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="addSelfPaced()">Add</button>
      </div>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="LiveEventPopup">
  <div class="cutome-modal-content" style="width: 45%; height: auto">
    <div class="modal-header">
      <p>Live Events</p>
      <span class="material-icons" (click)="closeLiveEventPopup()">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <div class="students-list">
          <div class="event-list" *ngIf="!NoLiveEventFound">
            <div class="rowevent header">
              <div class="value first">Event Name</div>
              <div class="value">Start Date</div>
              <div class="value">Start Time</div>
              <div class="value last">Action</div>
            </div>
            <div class="rowevent" *ngFor="let item of PopLiveEvents">
              <div class="value first">{{ item.event_name }}</div>
              <div class="value">
                {{ item.start_date | date: 'dd MMM yyyy' }}
              </div>
              <div class="value">{{ item.start_time }}</div>
              <div class="value last">
                <mat-icon (click)="startMeeting(item)">play_arrow</mat-icon>
              </div>
            </div>
          </div>
          <div class="no-course" *ngIf="NoLiveEventFound">
            <span
              ><svg
                width="12px"
                height="12px"
                viewBox="0 0 12 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Login-Flow"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Assets-New-Screens"
                    transform="translate(-104.000000, -237.000000)"
                    fill="#a39bde"
                  >
                    <g
                      id="icon-alert"
                      transform="translate(104.000000, 237.000000)"
                    >
                      <path
                        d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M6,1.1 C3.29380473,1.1 1.1,3.29380473 1.1,6 C1.1,8.70619527 3.29380473,10.9 6,10.9 C8.70619527,10.9 10.9,8.70619527 10.9,6 C10.9,3.29380473 8.70619527,1.1 6,1.1 Z M6,2.93081622 C6.27338095,2.93081622 6.50017619,3.13027336 6.54280143,3.39160336 L6.55,3.48081622 L6.55,5.66865795 C6.55,5.97241456 6.30375661,6.21865795 6,6.21865795 C5.72661905,6.21865795 5.49982381,6.01920081 5.45719857,5.75787081 L5.45,5.66865795 L5.45,3.48081622 C5.45,3.17705961 5.69624339,2.93081622 6,2.93081622 Z"
                        id="Combined-Shape"
                        fill-rule="nonzero"
                      ></path>
                      <path
                        d="M6,8.96240597 C6.4418278,8.96240597 6.8,8.60423377 6.8,8.16240597 C6.8,7.72057817 6.4418278,7.36240597 6,7.36240597 C5.5581722,7.36240597 5.2,7.72057817 5.2,8.16240597 C5.2,8.60423377 5.5581722,8.96240597 6,8.96240597 Z"
                        id="Oval"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <p>No live events are schedules for the selected class</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeLiveEventPopup()">Cancel</button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="#d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
