<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Videos</h3>
      <img
        src="../../../assets/images/videolesson-icon.png"
        alt=""
        (click)="openRearrangePopup()"
      />
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Domain/Sub Domain..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewVideo()">Add Video</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="video_link">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Video</th>
          <td mat-cell *matCellDef="let row" class="videos_link">
            <img
              [src]="row.video_thumbnil"
              onerror="this.src='../../../assets/images/no-thumbnail.jpg';"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domin_name }}">{{ row.domin_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdomain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain_name }}">{{
              row.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.topic_name }}">{{ row.topic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="video_title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.video_title }}">{{ row.video_title }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.duration }}">{{ row.duration }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Free/Paid</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.type }}">{{ row.type }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="editVideoLesson(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="deleteVideoLesson(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<div class="cutome-modal" *ngIf="TranscriptPopup">
  <div class="cutome-modal-content">
    <div class="cutome-container">
      <div class="modal-header">
        <p>Transcription</p>
        <span class="material-icons" (click)="closeTranscriptPopup()"
          >close</span
        >
      </div>
      <div class="modal-body">
        <div class="table-div">
          <table>
            <tr>
              <th>Words</th>
              <th>From Time</th>
              <th>To Time</th>
            </tr>
            <tr *ngFor="let item of TranscriptArray">
              <td>{{ item.Text }}</td>
              <td>{{ item.FromTime }}</td>
              <td>{{ item.ToTime }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addVideo">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Video</p>
      <p *ngIf="IsUpdate">Edit Video</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="VideoLessonForm">
          <div class="formwrapper">
            <div class="w-50">
              <div class="select-box">
                <select
                  [(ngModel)]="SelectedCertificate"
                  (change)="onCertificateChange($event)"
                  formControlName="SelectedCertificate"
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['SelectedCertificate'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['SelectedCertificate'].dirty ||
                        VideoLessonForm.controls['SelectedCertificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100">
              <div class="select-box">
                <select
                  [attr.disabled]="this.SelectedCertificate == '' ? true : null"
                  (change)="domainchange($event)"
                  [(ngModel)]="SelectedDomain"
                  formControlName="SelectedDomain"
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['SelectedDomain'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['SelectedDomain'].dirty ||
                        VideoLessonForm.controls['SelectedDomain'].touched)
                  }"
                >
                  <option value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100">
              <ng-select
                [items]="this.SelectedDomain != '' ? SubDomain : []"
                formControlName="SelectedSubDomain"
                (change)="changeSubDomain($event)"
                [disabled]="true"
                [(ngModel)]="SelectedSubDomain"
                bindLabel="subdomain_name"
                bindValue="id"
                placeholder="SubDomains"
                appendTo="body"
                multiple="true"
                [attr.disabled]="this.SelectedDomain == '' ? true : null"
                [ngClass]="{
                  err:
                    VideoLessonForm.controls['SelectedSubDomain'].hasError(
                      'required'
                    ) &&
                    (VideoLessonForm.controls['SelectedSubDomain'].dirty ||
                      VideoLessonForm.controls['SelectedSubDomain'].touched)
                }"
              >
              </ng-select>
            </div>
            <div class="w-100">
              <ng-select
                [items]="SelectedSubDomain != '' ? Topic : []"
                formControlName="SelectedTopics"
                bindLabel="topic_name"
                bindValue="id"
                placeholder="Topics"
                appendTo="body"
                multiple="true"
                [(ngModel)]="SelectedTopics"
                [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                [ngClass]="{
                  err:
                    VideoLessonForm.controls['SelectedTopics'].hasError(
                      'required'
                    ) &&
                    (VideoLessonForm.controls['SelectedTopics'].dirty ||
                      VideoLessonForm.controls['SelectedTopics'].touched)
                }"
              >
              </ng-select>
            </div>
            <div class="w-100">
              <input
                [(ngModel)]="VideoTitle"
                formControlName="VideoTitle"
                placeholder="Video Title"
                type="text"
                name="VideoTitle"
                [ngClass]="{
                  err:
                    VideoLessonForm.controls['VideoTitle'].hasError(
                      'required'
                    ) &&
                    (VideoLessonForm.controls['VideoTitle'].dirty ||
                      VideoLessonForm.controls['VideoTitle'].touched)
                }"
              />
            </div>
            <div class="w-100">
              <textarea
                [(ngModel)]="Summary"
                formControlName="Summary"
                placeholder="Summary"
                type="text"
                name="Summary"
                [ngClass]="{
                  err:
                    VideoLessonForm.controls['Summary'].hasError('required') &&
                    (VideoLessonForm.controls['Summary'].dirty ||
                      VideoLessonForm.controls['Summary'].touched)
                }"
              ></textarea>
            </div>
            <div class="w-100 thumb">
              <div class="tooltip input-linkimg">
                <input
                  formControlName="VideoThumbnail"
                  [(ngModel)]="VideoThumbnail"
                  placeholder="Video Thumbnail"
                  type="text"
                  name="Thumbnail "
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['VideoThumbnail'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['VideoThumbnail'].dirty ||
                        VideoLessonForm.controls['VideoThumbnail'].touched)
                  }"
                />
                <div class="link-img">
                  <mat-icon class="link">link</mat-icon>
                  <img
                    src="../../../assets/images/No-image Icon.jpg"
                    alt="No-image"
                  />
                </div>
                <span class="tooltiptext">
                  <img [src]="VideoThumbnail" alt="Thumbnail" />
                  <p class="thumb-prev">Thumbnail Preview</p>
                </span>
              </div>
            </div>
            <div class="w-100">
              <div class="tooltip input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink"
                  [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                  (keyup)="linkChange()"
                  formControlName="VideoLink"
                  placeholder="CDN Link 1080p HD"
                  type="text"
                  name="VideoLink"
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['VideoLink'].hasError(
                        'required'
                      ) &&
                      (VideoLessonForm.controls['VideoLink'].dirty ||
                        VideoLessonForm.controls['VideoLink'].touched)
                  }"
                />
                <mat-icon class="link-cdn">link</mat-icon>
                <span class="tooltiptext">
                  <video width="320" height="240" controls>
                    <source src="{{ VideoLink }}" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <p class="thumb-prev">Video Preview</p>
                </span>
              </div>
            </div>
            <!-- video transcode start -->
            <div class="w-100">
              <div class="tooltip input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_720p"
                  [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                  formControlName="VideoLink_720p"
                  placeholder="CDN Link 720p"
                  type="text"
                  name="VideoLink_720p"
                />
                <mat-icon class="link-cdn">link</mat-icon>
                <span class="tooltiptext">
                  <video width="320" height="240" controls>
                    <source src="{{ VideoLink_720p }}" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <p class="thumb-prev">Video Preview</p>
                </span>
              </div>
            </div>
            <div class="w-100">
              <div class="tooltip input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_480p"
                  [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                  formControlName="VideoLink_480p"
                  placeholder="CDN Link 480p"
                  type="text"
                  name="VideoLink_480p"
                />
                <mat-icon class="link-cdn">link</mat-icon>
                <span class="tooltiptext">
                  <video width="320" height="240" controls>
                    <source src="{{ VideoLink_480p }}" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <p class="thumb-prev">Video Preview</p>
                </span>
              </div>
            </div>
            <div class="w-100">
              <div class="tooltip input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_360p"
                  [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                  formControlName="VideoLink_360p"
                  placeholder="CDN Link 360p"
                  type="text"
                  name="VideoLink_360p"
                />
                <mat-icon class="link-cdn">link</mat-icon>
                <span class="tooltiptext">
                  <video width="320" height="240" controls>
                    <source src="{{ VideoLink_360p }}" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <p class="thumb-prev">Video Preview</p>
                </span>
              </div>
            </div>
            <div class="w-100">
              <div class="tooltip input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_240p"
                  [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                  formControlName="VideoLink_240p"
                  placeholder="CDN Link 240p"
                  type="text"
                  name="VideoLink_240p"
                />
                <mat-icon class="link-cdn">link</mat-icon>
                <span class="tooltiptext">
                  <video width="320" height="240" controls>
                    <source src="{{ VideoLink_240p }}" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <p class="thumb-prev">Video Preview</p>
                </span>
              </div>
            </div>
            <div class="w-100">
              <div class="tooltip input-video-linkimg">
                <input
                  [(ngModel)]="VideoLink_144p"
                  [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                  formControlName="VideoLink_144p"
                  placeholder="CDN Link 144p"
                  type="text"
                  name="VideoLink_144p"
                />
                <mat-icon class="link-cdn">link</mat-icon>
                <span class="tooltiptext">
                  <video width="320" height="240" controls>
                    <source src="{{ VideoLink_144p }}" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <p class="thumb-prev">Video Preview</p>
                </span>
              </div>
            </div>
            <!-- video transcode end -->
            <div class="w-50 w-qrurl">
              <input
                [(ngModel)]="video_lesson_link"
                formControlName="video_lesson_link"
                placeholder="Video Lesson Link"
                type="text"
                name="video_lesson_link"
              />
            </div>
            <div class="w-qr">
              <ngx-kjua
                [text]="Qr_Url"
                [renderAsync]="false"
                [render]="'svg'"
                [crisp]="true"
                [minVersion]="1"
                [ecLevel]="'H'"
                [size]="70"
                [ratio]="undefined"
                [fill]="'#333'"
                [mSize]="40"
                [back]="'#fff'"
                [rounded]="100"
                [quiet]="0"
                [mode]="'labelimage'"
                [image]="base64"
                [cssClass]="'coolQRCode'"
                style="width: 70px; height: 70px"
                #qrEle
                id="container"
              >
              </ngx-kjua>
              <p (click)="downloadQrCode()" class="qr-download">Download</p>
            </div>
            <div class="w-50">
              <div class="select-box">
                <select
                  [(ngModel)]="Type"
                  formControlName="Type"
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['Type'].hasError('required') &&
                      (VideoLessonForm.controls['Type'].dirty ||
                        VideoLessonForm.controls['Type'].touched)
                  }"
                >
                  <option selected="true" value="">Select Type</option>
                  <option value="1">Free</option>
                  <option value="2">Paid</option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-50">
              <input
                [(ngModel)]="Duration"
                formControlName="Duration"
                placeholder="Minutes:Seconds"
                type="text"
                name="Duration"
                [ngClass]="{
                  err:
                    VideoLessonForm.controls['Duration'].hasError('required') &&
                    (VideoLessonForm.controls['Duration'].dirty ||
                      VideoLessonForm.controls['Duration'].touched)
                }"
              />
            </div>
          </div>
        </form>
        <div>
          <span class="transcripts">Topics</span>
          <div class="topic-div" *ngFor="let item of TopicArray; let i = index">
            <div class="main-transcript">
              <div class="transcript-topic">
                <input
                  type="text"
                  [(ngModel)]="item.Text"
                  placeholder="Type here..."
                />
              </div>
              <div
                *ngIf="i > 0"
                class="transcript-delete"
                (click)="DeleteTopic(item)"
              >
                <span class="material-icons"> delete </span>
              </div>
            </div>
            <div class="add-new" (click)="addAnotherTopic()">
              <span class="material-icons"> add </span>
            </div>
          </div>
        </div>
        <div>
          <span class="transcripts">Chapters</span>
          <div
            class="transcript-div"
            *ngFor="let item of ChaptersArray; let i = index"
          >
            <div class="main-transcript">
              <div class="transcript-textaria">
                <textarea
                  [(ngModel)]="item.Text"
                  placeholder="Type here..."
                ></textarea>
              </div>
              <div class="transcript-input">
                <input
                  [(ngModel)]="item.FromTime"
                  type="text"
                  placeholder="00:00"
                  (keyup)="validateFromTime(item, $event)"
                />
                <!-- <input [(ngModel)]="item.ToTime" type="text" placeholder="00:00:00" /> -->
              </div>
              <div
                *ngIf="i > 0"
                class="transcript-delete"
                (click)="DeleteChapters(item)"
              >
                <span class="material-icons"> delete </span>
              </div>
            </div>
            <div class="add-new" (click)="addAnotherChapters()">
              <span class="material-icons"> add </span>
            </div>
          </div>
        </div>
        <div>
          <span class="transcripts">Transcripts</span>
          <div
            class="transcript-div"
            *ngFor="let item of TranscriptArray; let i = index"
          >
            <div class="main-transcript">
              <div class="transcript-textaria">
                <textarea
                  [(ngModel)]="item.Text"
                  placeholder="Type here..."
                ></textarea>
              </div>
              <div class="transcript-input">
                <input
                  [(ngModel)]="item.FromTime"
                  type="text"
                  placeholder="00:00"
                  (keyup)="validateFromTime(item, $event)"
                />
                <input
                  [(ngModel)]="item.ToTime"
                  type="text"
                  placeholder="00:00"
                  (keyup)="validateToTime(item, $event)"
                />
              </div>
              <div
                *ngIf="i > 0"
                class="transcript-delete"
                (click)="DeleteTranscript(item)"
              >
                <span class="material-icons"> delete </span>
              </div>
            </div>
            <div class="add-new" (click)="addAnotherTranscript()">
              <span class="material-icons"> add </span>
            </div>
          </div>
        </div>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button type="button" (click)="addVideoLesson()" class="submit-btn">
          Save
        </button>
      </div>
      <div *ngIf="IsUpdate">
        <button type="button" class="submit-btn" (click)="updateVideoLesson()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<div id="video_qrcode" *ngIf="addVideo" style="text-align: center">
  <ngx-kjua
    [text]="Qr_Url"
    [renderAsync]="false"
    [render]="'svg'"
    [crisp]="true"
    [minVersion]="1"
    [ecLevel]="'H'"
    [size]="500"
    [ratio]="1"
    [fill]="'#333'"
    [mSize]="40"
    [back]="'#fff'"
    [rounded]="100"
    [quiet]="0"
    [mode]="'labelimage'"
    [image]="base64"
    [cssClass]="'coolQRCode'"
    style="display: contents; height: 500px"
    #qrEle
    id="container"
  >
  </ngx-kjua>
</div>
<div class="cutome-modal" *ngIf="RearrangePopup">
  <div class="cutome-modal-content" style="width: 75%">
    <div class="modal-header">
      <p>Rearrange Videos</p>
      <span (click)="closeRearrangeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <div class="formwrapper">
          <div class="w33">
            <div class="select-box">
              <select
                [(ngModel)]="RearrangeCertificate"
                (change)="onRearrangeCertificateChange()"
              >
                <option value="">Select Certification</option>
                <option
                  *ngFor="let item of Certificatelist"
                  value="{{ item.id }}"
                >
                  {{ item.certificate_name }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
          <div class="w33">
            <div class="select-box">
              <select
                [attr.disabled]="this.RearrangeCertificate == '' ? true : null"
                (change)="RearrangeDomainChange()"
                [(ngModel)]="RearrangeSelectedDomain"
              >
                <option value="">Select Domain</option>
                <option *ngFor="let item of Domain" value="{{ item.id }}">
                  {{ item.domin_name }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
          <div class="w33">
            <div class="select-box">
              <select
                [attr.disabled]="
                  this.RearrangeSelectedDomain == '' ? true : null
                "
                (change)="RearrangeSubDomainChange()"
                [(ngModel)]="RearrangeSelecteSubdDomain"
              >
                <option value="">Select Sub Domain</option>
                <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                  {{ item.subdomain_name }}
                </option>
              </select>
              <span></span>
            </div>
          </div>
        </div>
        <div *ngIf="RearrangeArray.length <= 0" class="no-data">
          <p>There is no videos availabe for selected filter</p>
        </div>
        <div class="mat-elevation-z8" *ngIf="RearrangeArray.length > 0">
          <table
            class="rearrange-table"
            style="
              border-collapse: separate;
              border-spacing: 0 10px;
              border: none;
              color: #9b94d5;
              text-align: left;
            "
          >
            <thead>
              <tr
                style="
                  background: #1b1f48;
                  display: table-row;
                  vertical-align: inherit;
                  border-color: inherit;
                "
              >
                <th
                  style="
                    background: #2b2b5d;
                    padding: 10px;
                    border-top: 1px solid #2e2c7d;
                    border-bottom: 1px solid #2e2c7d;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                    border-left: 1px solid #303563;
                    min-width: 50px;
                    width: 50px;
                  "
                ></th>
                <th
                  style="
                    background: #2b2b5d;
                    padding: 10px 0;
                    border-top: 1px solid #2e2c7d;
                    border-bottom: 1px solid #2e2c7d;
                    width: 100px;
                    min-width: 100px;
                  "
                >
                  Title
                </th>
                <th
                  style="
                    background: #2b2b5d;
                    padding: 10px 0;
                    border-top: 1px solid #2e2c7d;
                    border-bottom: 1px solid #2e2c7d;
                  "
                ></th>
                <th
                  style="
                    background: #2b2b5d;
                    padding: 10px 0;
                    border-top: 1px solid #2e2c7d;
                    border-bottom: 1px solid #2e2c7d;
                  "
                >
                  Topic
                </th>
                <th
                  style="
                    background: #2b2b5d;
                    padding: 10px 0;
                    border-top: 1px solid #2e2c7d;
                    border-bottom: 1px solid #2e2c7d;
                    width: 90px;
                    min-width: 90px;
                  "
                >
                  Duration
                </th>
                <th
                  style="
                    background: #2b2b5d;
                    padding: 10px 0;
                    border-top: 1px solid #2e2c7d;
                    border-bottom: 1px solid #2e2c7d;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                    border-right: 1px solid #303563;
                    width: 80px;
                    min-width: 80px;
                  "
                >
                  Free/Paid
                </th>
              </tr>
            </thead>
            <tbody
              cdkDropList
              (cdkDropListDropped)="drop($event)"
              style="display: table-row-group"
            >
              <tr
                *ngFor="let item of RearrangeArray"
                cdkDrag
                class="trrow"
                style="cursor: move; background: #1b1f48; display: table-row"
              >
                <td
                  style="
                    border-top: 1px solid #303563;
                    vertical-align: middle;
                    display: table-cell;
                    border-bottom: 1px solid #303563;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                    border-left: 1px solid #303563;
                    padding: 10px 15px;
                    min-width: 50px;
                    width: 50px;
                  "
                >
                  <img
                    src="../../../assets/images/sidebar.JPG"
                    alt=""
                    style="width: 13px; line-height: 0"
                  />
                </td>
                <td
                  style="
                    border-top: 1px solid #303563;
                    vertical-align: middle;
                    display: table-cell;
                    border-bottom: 1px solid #303563;
                    padding: 10px 0;
                    width: 100px;
                    min-width: 100px;
                  "
                >
                  <img
                    src="{{ item.video_thumbnil }}"
                    alt="videos"
                    style="width: 65px; height: 47px; border-radius: 6px"
                  />
                </td>
                <td
                  style="
                    padding: 10px;
                    color: #9b94d5;
                    border-top: 1px solid #303563;
                    vertical-align: middle;
                    display: table-cell;
                    border-bottom: 1px solid #303563;
                  "
                >
                  {{ item.video_title }}
                </td>
                <td
                  style="
                    padding: 10px 0;
                    color: #9b94d5;
                    border-top: 1px solid #303563;
                    vertical-align: middle;
                    display: table-cell;
                    border-bottom: 1px solid #303563;
                  "
                >
                  <span
                    *ngFor="let topic of item.topic_list"
                    style="
                      color: #9b94d5;
                      padding-right: 25px;
                      position: relative;
                    "
                  >
                    <span
                      style="
                        background: #7e79b3;
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        position: absolute;
                        left: -12px;
                        top: 8px;
                      "
                    ></span>
                    {{ topic.Text }}</span
                  >
                </td>
                <td
                  style="
                    width: 90px;
                    min-width: 90px;
                    padding: 10px 0;
                    color: #9b94d5;
                    border-top: 1px solid #303563;
                    vertical-align: middle;
                    display: table-cell;
                    border-bottom: 1px solid #303563;
                  "
                >
                  {{ item.duration }}
                </td>
                <td
                  style="
                    width: 80px;
                    min-width: 80px;
                    padding: 10px 0;
                    color: #9b94d5;
                    border-top: 1px solid #303563;
                    vertical-align: middle;
                    display: table-cell;
                    border-bottom: 1px solid #303563;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                    border-right: 1px solid #303563;
                  "
                >
                  {{ item.type }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeRearrangeModal()">Cancel</button>
      <div>
        <button
          type="button"
          (click)="saveRearrange()"
          class="submit-btn"
          [disabled]="RearrangeArray.length <= 0"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
