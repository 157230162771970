<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Sessions</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Session Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button>Add Live Session</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="testname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Live Session Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.test_name }}">{{ row.test_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="questions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header># Questions</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.question_count }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon (click)="editSession(row)" title="Edit Session"
              >edit</mat-icon
            >
            <mat-icon (click)="deleteSession(row)" title="Delete Session"
              >delete</mat-icon
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
