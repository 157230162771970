<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Topics</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Topic Name, Domain, Sub-domain..."
          [(ngModel)]="Search"
          (keyup)="filter()"
          name="search"
          autocomplete="off"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewTopic()">Add Topic</button>
      <button (click)="openBulkUploadModal()">Bulk Upload</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic Name</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.topic_name }}">{{ row.topic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub-domain Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain }}">{{ row.subdomain }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain Name</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domain }}">{{ row.domain }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addTopic">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Topic</p>
      <p *ngIf="IsUpdate">Edit Topic</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="TopicForm">
          <div class="formwrapper">
            <div>
              <div class="select-box">
                <select
                  (change)="certificatechange($event)"
                  (keyup)="certificatechange($event)"
                  (keydown)="certificatechange($event)"
                  [(ngModel)]="Certificate"
                  formControlName="Certificate"
                  required
                  [ngClass]="{
                    err:
                      TopicForm.controls['Certificate'].hasError('required') &&
                      (TopicForm.controls['Certificate'].dirty ||
                        TopicForm.controls['Certificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <div class="select-box">
                <select
                  [attr.disabled]="this.Certificate == '' ? true : null"
                  [(ngModel)]="domainname"
                  (change)="domainchange($event)"
                  (keyup)="domainchange($event)"
                  (keydown)="domainchange($event)"
                  formControlName="Domainname"
                  required
                  [ngClass]="{
                    err:
                      TopicForm.controls['Domainname'].hasError('required') &&
                      (TopicForm.controls['Domainname'].dirty ||
                        TopicForm.controls['Domainname'].touched)
                  }"
                >
                  <option selected="true" value="">Select Domain</option>
                  <option *ngFor="let item of Domainlist" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100">
              <div class="select-box">
                <select
                  [attr.disabled]="this.domainname == '' ? true : null"
                  [(ngModel)]="subdomainname"
                  formControlName="subdomainname"
                  required
                  [ngClass]="{
                    err:
                      TopicForm.controls['subdomainname'].hasError(
                        'required'
                      ) &&
                      (TopicForm.controls['subdomainname'].dirty ||
                        TopicForm.controls['subdomainname'].touched)
                  }"
                >
                  <option selected="true" value="">Select Sub-domain</option>
                  <option
                    *ngFor="let item of subDomainlist"
                    value="{{ item.id }}"
                  >
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-20">
              <input
                formControlName="SRNumber"
                [(ngModel)]="SRNumber"
                placeholder="Topic #"
                required
                [ngClass]="{
                  err:
                    TopicForm.controls['SRNumber'].hasError('required') &&
                    (TopicForm.controls['SRNumber'].dirty ||
                      TopicForm.controls['SRNumber'].touched)
                }"
              />
            </div>
            <div class="w-20">
              <input
                [(ngModel)]="Topicname"
                formControlName="Topicname"
                placeholder="Topic Name"
                [ngClass]="{
                  err:
                    TopicForm.controls['Topicname'].hasError('required') &&
                    (TopicForm.controls['Topicname'].dirty ||
                      TopicForm.controls['Topicname'].touched)
                }"
              />
            </div>

            <div class="w-100">
              <textarea
                [(ngModel)]="Explanation"
                formControlName="Explanation"
                class="my-text"
                placeholder="Keywords"
                rows="7"
                [ngClass]="{
                  err:
                    TopicForm.controls['Explanation'].hasError('required') &&
                    (TopicForm.controls['Explanation'].dirty ||
                      TopicForm.controls['Explanation'].touched)
                }"
              >
              </textarea>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="Update()">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="isBulkUpload">
  <div class="cutome-modal-content" style="width: 50%">
    <div class="modal-header">
      <p>Bulk Upload</p>
      <span (click)="closeBulkUploadModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <ngx-dropzone
          maxFileSize="100MB"
          accept=".csv"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label
            >Drag & drop here (or)
            <span class="browse">Browse</span></ngx-dropzone-label
          >
          <div *ngFor="let f of files">
            <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
          </div>
        </ngx-dropzone>
        <div class="format" *ngIf="files.length <= 0">
          <span>Allowed Format: CSV</span>
        </div>
        <div class="format" *ngFor="let f of files">
          <div class="bbulkupload">
            <span *ngFor="let t of f">{{ t.name }} </span>

            <mat-icon (click)="onRemove(f)">close</mat-icon>
          </div>
        </div>
        <div class="format">
          <progress
            value="{{ progress }}"
            max="10"
            id="progressBar"
            *ngIf="selectprogress"
          ></progress>
        </div>
        <div class="format">
          <a (click)="ExportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            Topic_sample.xlsx
          </a>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeBulkUploadModal()">
        Cancel
      </button>
      <button
        class="submit-btn"
        (click)="bulkUpload()"
        [disabled]="selectBulkupload"
      >
        Upload
      </button>
    </div>
  </div>
</div>
