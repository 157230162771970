import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthorizationService } from './service/authorization.service';
import { Router } from '@angular/router';
import { ServicesService } from './services.service';
const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    public router: Router,
    public auth: AuthorizationService,
    public service: ServicesService,
  ) {
    var login = window.sessionStorage.getItem('logintrue');
    if (!login) {
      this.router.navigate(['/login']);
    }
  }
}
