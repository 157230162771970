import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { MatSort } from '@angular/material/sort';
import { FlashcardService } from '../../services/flashcard/flashcard.service';

@Component({
  selector: 'app-flashcard',
  templateUrl: './flashcard.component.html',
  styleUrls: ['./flashcard.component.scss'],
})
export class FlashcardComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'free_paid',
    'domin_name',
    'subdomain_name',
    'topic_name',
    'term',
    'definition',
    'certificate_name',
    'actions',
  ];
  dataSource: MatTableDataSource<flashcardlist>;
  Search: any;
  addFlashcard: boolean = false;
  IsUpdate: boolean = false;

  Domain = [];
  SubDomain = [];
  Topic = [];
  free_paid: any;
  Domainname: any;
  Subdomainname: any;
  Topicname: any;
  Definition: any;
  Term: any;
  id: any;
  public FlashcardForm: FormGroup;
  filtersubDomain = [];
  filterDomain = [];
  Certificatelist = [];
  Certificate: any;
  filterTpoic = [];
  loglist = [];

  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Type: '',
    Term: '',
    Definition: '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    Type: '',
    Term: '',
    Definition: '',
  };
  auditHistory = '';
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public flashcardService: FlashcardService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public fb: FormBuilder,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  addNewFlashcard() {
    this.addFlashcard = true;
    this.IsUpdate = false;
    this.id = '';
    this.Certificate = '';
    this.free_paid = '';
    this.Domainname = '';
    this.Subdomainname = '';
    this.Topicname = '';
    this.Term = '';
    this.Definition = '';
    this.loglist = [];
  }
  closeModal() {
    this.addFlashcard = false;
    this.IsUpdate = false;
    this.id = '';
    this.Certificate = '';
    this.free_paid = '';
    this.Domainname = '';
    this.Subdomainname = '';
    this.Topicname = '';
    this.Term = '';
    this.Definition = '';
    this.loglist = [];

    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Type: '',
      Term: '',
      Definition: '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      Type: '',
      Term: '',
      Definition: '',
    };
    this.auditHistory = '';
  }

  async ngOnInit() {
    this.FlashcardForm = this.fb.group({
      free_paid: ['', Validators.required],
      Domainname: ['', Validators.required],
      Subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      Definition: ['', Validators.required],
      Term: ['', Validators.required],
      Certificate: ['', Validators.required],
    });
    await this.spinner.show();
    try {
      await this.listflashcard();
      await this.GetAllDropdown();
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.flashcardService
                  .DeleteFlashcard(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();
                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.term +
                              '</span> flashcard is deleted by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'flashcard',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.listflashcard();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Flashcard has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
  async edit(item) {
    this.addFlashcard = true;
    this.IsUpdate = true;
    this.id = item.id;
    this.Certificate = item.certi_id;
    this.free_paid = item.free_paid;
    this.Domainname = item.d_id;
    this.Subdomainname = item.sd_id;
    this.Topicname = item.topic_id;
    this.Term = item.term;
    this.Definition = item.definition;
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('flashcard', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.Domain = this.filterDomain.filter(
      (x) => x.certi_id == this.Certificate,
    );
    this.SubDomain = this.filtersubDomain.filter(
      (x) => x.d_id == this.Domainname,
    );
    this.Topic = this.filterTpoic.filter((x) => x.sd_id == this.Subdomainname);
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find((x) => x.id == this.Certificate)
        ? this.Certificatelist.find((x) => x.id == this.Certificate)
            .certificate_name
        : '',
      Domain: this.filterDomain.find((x) => x.id == this.Domainname)
        ? this.filterDomain.find((x) => x.id == this.Domainname).domin_name
        : '',
      Subdomain: this.filtersubDomain.find((x) => x.id == this.Subdomainname)
        ? this.filtersubDomain.find((x) => x.id == this.Subdomainname)
            .subdomain_name
        : '',
      Topic: this.filterTpoic.find((x) => x.id == this.Topicname)
        ? this.filterTpoic.find((x) => x.id == this.Topicname).topic_name
        : '',
      Type: this.free_paid,
      Term: this.Term,
      Definition: this.Definition,
    };
  }
  async listflashcard() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: flashcardlist[] = [];
        await this.flashcardService.ListFlashcard(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                free_paid: element.free_paid,
                d_id: element.d_id,
                sd_id: element.sd_id,
                topic_id: element.topic_id,
                term: element.term,
                definition: element.definition,
                ref_link: element.ref_link,
                youtube_link: element.youtube_link,
                bookpage_no: element.bookpage_no,
                blog_url: element.blog_url,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                domin_name: element.domain.domin_name,
                subdomain_name: element.subdomain.subdomain_name,
                topic_name: element.topic.topic_name,
                certificate_name: element.certificate.certificate_name,
                point: element.point,
                certi_id: element.certi_id,
                cource_id: element.cource_id,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.pagination = true;
            this.filter();
          }
        });
      }
    });
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domain = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.SubDomain = list.data.SubDomain;
            this.filtersubDomain = list.data.SubDomain;
            this.Certificatelist = list.data.Certificate;
            this.Topic = list.data.Topic;
            this.filterTpoic = list.data.Topic;

            this.Topicname = '';
            this.Domainname = '';
            this.Certificate = '';
            this.free_paid = '';
            this.Subdomainname = '';
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
      this.Domain = this.filterDomain;
    }
  }
  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });
      this.Subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
      this.SubDomain = this.filtersubDomain;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return itemset.sd_id == val;
      });
      this.Topicname = '';
    } else {
      this.Topicname = '';
      this.Topic = this.filterTpoic;
    }
  }
  async SubmitFlashcard() {
    this.markFormTouched(this.FlashcardForm);
    if (this.FlashcardForm.valid) {
      await this.spinner.show();
      try {
        var authenticatedUser = this.auth.getAuthenticatedUser();

        authenticatedUser.getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            this.auth
              .getAuthenticatedUser()
              .getSession(async (err, session) => {
                if (err) {
                  await this.spinner.hide();

                  this.router.navigate(['/login']);
                } else {
                  var token = session.getIdToken().getJwtToken();
                  await this.flashcardService
                    .AddFlashcard(
                      this.Certificate,
                      this.free_paid,
                      this.Domainname,
                      this.Subdomainname,
                      this.Topicname,
                      this.Term,
                      this.Definition,
                      '1',
                      token,
                      0,
                    )
                    .then(async (value) => {
                      if (value[0].status == 200) {
                        var list = JSON.parse(<string>value[0].json);
                        this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              await this.spinner.hide();
                              this.router.navigate(['/login']);
                            } else {
                              this.service.ShowSuccess(list.message);
                              this.addFlashcard = false;
                              await this.listflashcard();
                              await this.spinner.hide();

                              var token = session.getIdToken().getJwtToken();
                              var e = window.localStorage.getItem('adminUser');
                              if (e == null || e == undefined || e == '') {
                                e = 'admin';
                              }
                              var desc =
                                '<span> ' +
                                this.Term +
                                '</span> flashcard is added by <span> ' +
                                e +
                                '</span>';
                              await this.service.addAdminLogs(
                                desc,
                                'flashcard',
                                list.data.id,
                                token,
                              );
                            }
                          });
                      } else {
                        var list = JSON.parse(<string>value[0].json);
                        await this.spinner.hide();
                        this.service.ShowError(list.message);
                      }
                    });
                }
              });
          }
        });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  async UpdateFlashcard() {
    this.markFormTouched(this.FlashcardForm);
    if (this.FlashcardForm.valid) {
      await this.spinner.show();
      try {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.flashcardService
                .UpdateFlashcard(
                  this.Certificate,
                  this.free_paid,
                  this.Domainname,
                  this.Subdomainname,
                  this.Topicname,
                  this.Term,
                  this.Definition,
                  this.id,
                  '1',
                  token,
                  0,
                )
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();
                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          this.service.ShowSuccess(list.message);
                          this.addFlashcard = false;
                          await this.listflashcard();
                          await this.spinner.hide();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }

                          this.newAuditValueArray = {
                            Certificate: this.Certificatelist.find(
                              (x) => x.id == this.Certificate,
                            )
                              ? this.Certificatelist.find(
                                  (x) => x.id == this.Certificate,
                                ).certificate_name
                              : '',
                            Domain: this.filterDomain.find(
                              (x) => x.id == this.Domainname,
                            )
                              ? this.filterDomain.find(
                                  (x) => x.id == this.Domainname,
                                ).domin_name
                              : '',
                            Subdomain: this.filtersubDomain.find(
                              (x) => x.id == this.Subdomainname,
                            )
                              ? this.filtersubDomain.find(
                                  (x) => x.id == this.Subdomainname,
                                ).subdomain_name
                              : '',
                            Topic: this.filterTpoic.find(
                              (x) => x.id == this.Topicname,
                            )
                              ? this.filterTpoic.find(
                                  (x) => x.id == this.Topicname,
                                ).topic_name
                              : '',
                            Type: this.free_paid,
                            Term: this.Term,
                            Definition: this.Definition,
                          };
                          var audit = this.auth.compareAuditHist(
                            this.oldAuditValueArray,
                            this.newAuditValueArray,
                          );
                          var desc = audit;
                          await this.service.addAdminLogs(
                            desc,
                            'flashcard',
                            this.id,
                            token,
                          );
                        }
                      });
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface flashcardlist {
  srno: number;
  id: number;
  free_paid: string;
  d_id: string;
  sd_id: string;
  topic_id: string;
  term: string;
  definition: string;
  ref_link: string;
  youtube_link: string;
  bookpage_no: string;
  blog_url: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  domin_name: string;
  subdomain_name: string;
  topic_name: string;
  certificate_name: string;
  point: string;
  cource_id: string;
  certi_id: String;
}
