import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../service/authorization.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { formatDate } from '@angular/common';
import { ScheduleService } from '../../services/schedule/schedule.service';
import { TemplateService } from '../../services/template/template.service';

@Component({
  selector: 'app-scheduletest',
  templateUrl: './scheduletest.component.html',
  styleUrls: ['./scheduletest.component.scss'],
})
export class ScheduletestComponent implements OnInit {
  CohortList = [];
  FilterCohorts = [];
  FilterTest = [];
  TestLists = [];
  SelectedCohort: any;
  TestType: any;
  SelectedTest: any;
  StartDate: any;
  EndDate: any;
  ExamName: any;
  UpdateId: any;
  IsUpdate: boolean = false;
  addSchedule: boolean = false;
  public ScheduleForm: FormGroup;
  displayedColumns: string[] = [
    'srno',
    'name',
    'test_name',
    'type',
    'start_time',
    'end_time',
    'actions',
  ];
  dataSource: MatTableDataSource<ScheduleTimeList>;
  Search: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public templateService: TemplateService,
    public scheduleService: ScheduleService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    } else {
      this.ScheduleForm = this.fb.group({
        SelectedCohort: ['', Validators.required],
        TestType: ['', Validators.required],
        SelectedTest: ['', Validators.required],
        StartDate: ['', Validators.required],
        EndDate: ['', Validators.required],
      });
    }
  }

  async ngOnInit() {
    await this.getAllTemplate();
    this.SelectedCohort = '';
    this.TestType = '';
    this.SelectedTest = '';
    await this.getAllScheduleTime();
  }

  async getAllTemplate() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.templateService.ListTemplates(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.CohortList = list.data;
          }
        });
        this.spinner.hide();
      }
    });
  }
  async onChangeCohort() {
    this.TestType = '';
    this.SelectedTest = '';
    this.FilterCohorts = [];
    this.FilterCohorts = this.CohortList.filter(
      (x) => x.id == this.SelectedCohort,
    );
  }
  async onChangeTestType() {
    this.SelectedTest = '';
    this.TestLists = [];
    if (this.TestType == 'KA') {
      if (this.FilterCohorts[0].knowledge_asses.length > 0) {
        this.FilterCohorts[0].knowledge_asses.forEach((element) => {
          this.TestLists.push({
            id: element.id,
            TestName: element.knowass_name,
          });
        });
      }
    } else if (this.TestType == 'PRACTICE') {
      if (this.FilterCohorts[0].practicetests.length > 0) {
        this.FilterCohorts[0].practicetests.forEach((element) => {
          this.TestLists.push({
            id: element.id,
            TestName: element.practice_name,
          });
        });
      }
    } else if (this.TestType == 'CAT') {
      if (this.FilterCohorts[0].ketexamtests.length > 0) {
        this.FilterCohorts[0].ketexamtests.forEach((element) => {
          this.TestLists.push({
            id: element.id,
            TestName: element.ketexam_name,
          });
        });
      }
    }
  }
  onChangeTest() {
    this.TestLists.forEach((element) => {
      if (element.id == this.SelectedTest) {
        this.ExamName = element.TestName;
      }
    });
  }
  async saveSchedule() {
    this.spinner.show();
    if (new Date(this.StartDate) > new Date(this.EndDate)) {
      this.service.ShowError("End Date can't before start date");
      this.spinner.hide();
    } else {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.scheduleService
              .AddScheduleTime(
                this.SelectedCohort,
                this.SelectedTest,
                this.StartDate,
                this.EndDate,
                this.TestType,
                this.ExamName,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        let sdt = formatDate(
                          this.StartDate,
                          'yyyy-MM-dd, hh:mm',
                          'en_US',
                        );
                        let edt = formatDate(
                          this.EndDate,
                          'yyyy-MM-dd, hh:mm',
                          'en_US',
                        );

                        var desc =
                          '<span> ' +
                          this.ExamName +
                          '</span> test is Start at <span>' +
                          sdt +
                          '</span> and end on <span>' +
                          edt +
                          '</span> scheduled by <span> ' +
                          e +
                          '</span>';
                      }
                    });
                  this.service.ShowSuccess('Successfully test scheduled');
                  await this.getAllScheduleTime();
                }
              });
            this.spinner.hide();
          }
        });
    }
  }

  async getAllScheduleTime() {
    const users: ScheduleTimeList[] = [];
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.scheduleService
          .GetAllScheduleTime(token)
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                await users.push({
                  srno: srno,
                  id: element.id,
                  template_id: element.template.id,
                  name: element.template.name,
                  test_id: element.test_id,
                  test_name: element.examname,
                  type: element.type,
                  start_time: element.start_time,
                  end_time: element.end_time,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
            }
          });
        this.spinner.hide();
      }
    });
  }
  async deleteScheduleTime(row) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();

              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.scheduleService
                .DeleteScheduleTime(row.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    await this.getAllScheduleTime();
                    await this.spinner.hide();
                    this.service.ShowSuccess('Successfully deleted');
                  }
                });
            }
          });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
  openPopup() {
    this.addSchedule = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addSchedule = false;
    this.IsUpdate = false;
    this.TestType = '';
    this.SelectedTest = '';
    this.SelectedCohort = '';
  }
  editScheduleTime(row) {
    this.addSchedule = true;
    this.IsUpdate = true;

    this.TestLists = [];
    this.FilterCohorts = this.CohortList.filter((x) => x.id == row.template_id);
    this.SelectedCohort = row.template_id;
    if (row.type == 'KA') {
      if (this.FilterCohorts[0].knowledge_asses.length > 0) {
        this.FilterCohorts[0].knowledge_asses.forEach((element) => {
          this.TestLists.push({
            id: element.id,
            TestName: element.knowass_name,
          });
        });
      }
    } else if (row.type == 'PRACTICE') {
      if (this.FilterCohorts[0].practicetests.length > 0) {
        this.FilterCohorts[0].practicetests.forEach((element) => {
          this.TestLists.push({
            id: element.id,
            TestName: element.practice_name,
          });
        });
      }
    }
    this.TestType = row.type;
    this.SelectedTest = row.test_id;
    this.StartDate = row.start_time;
    this.EndDate = row.end_time;
    this.UpdateId = row.id;
    this.IsUpdate = true;
  }
  async updateSchedule() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.scheduleService
          .UpdateScheduleTime(
            this.UpdateId,
            this.SelectedCohort,
            this.SelectedTest,
            this.StartDate,
            this.EndDate,
            this.TestType,
            this.ExamName,
            token,
          )
          .then(async (value) => {
            if (value[0].status == 200) {
              this.service.ShowSuccess('Successfully update');
              this.IsUpdate = false;
              this.SelectedCohort = '';
              await this.getAllScheduleTime();
            }
          });
        this.spinner.hide();
      }
    });
  }
}

export interface ScheduleTimeList {
  srno: number;
  id: number;
  template_id: string;
  name: string;
  test_id: string;
  test_name: string;
  type: string;
  start_time: DateTime;
  end_time: DateTime;
}
