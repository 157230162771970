<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Written</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Domain/Sub Domain..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewStudyMaterial()">Add Content</button>
      <button (click)="openBulkUploadModal()">Bulk Upload</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.domin_name }}">{{
              element.domin_name
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Domain</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.subdomain_name }}">{{
              element.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.topic_name }}">{{
              element.topic_name
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.title }}">{{ element.title }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addStudyMaterial">
  <div class="cutome-modal-content" style="width: 60%">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Content</p>
      <p *ngIf="IsUpdate">Edit Content</p>

      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="StudymaterialForm">
          <div class="formwrapper">
            <div class="w-50">
              <div class="select-box">
                <select
                  (change)="certificatechange($event)"
                  (keyup)="certificatechange($event)"
                  (keydown)="certificatechange($event)"
                  [(ngModel)]="Certificate"
                  formControlName="Certificate"
                  required
                  [ngClass]="{
                    err:
                      StudymaterialForm.controls['Certificate'].hasError(
                        'required'
                      ) &&
                      (StudymaterialForm.controls['Certificate'].dirty ||
                        StudymaterialForm.controls['Certificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <div class="select-box">
                <select
                  [(ngModel)]="Domainname"
                  (change)="domainchange($event)"
                  [attr.disabled]="this.Certificate == '' ? true : null"
                  formControlName="Domainname"
                  required
                  [ngClass]="{
                    err:
                      StudymaterialForm.controls['Domainname'].hasError(
                        'required'
                      ) &&
                      (StudymaterialForm.controls['Domainname'].dirty ||
                        StudymaterialForm.controls['Domainname'].touched)
                  }"
                >
                  <option value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <div class="select-box">
                <select
                  [(ngModel)]="Subdomainname"
                  (change)="SubDomainchange($event)"
                  [attr.disabled]="this.Domainname == '' ? true : null"
                  formControlName="Subdomainname"
                  required
                  [ngClass]="{
                    err:
                      StudymaterialForm.controls['Subdomainname'].hasError(
                        'required'
                      ) &&
                      (StudymaterialForm.controls['Subdomainname'].dirty ||
                        StudymaterialForm.controls['Subdomainname'].touched)
                  }"
                >
                  <option value="">Select Sub-domain</option>
                  <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <div class="select-box">
                <select
                  [(ngModel)]="Topicname"
                  [attr.disabled]="this.Subdomainname == '' ? true : null"
                  formControlName="Topicname"
                  required
                  [ngClass]="{
                    err:
                      StudymaterialForm.controls['Topicname'].hasError(
                        'required'
                      ) &&
                      (StudymaterialForm.controls['Topicname'].dirty ||
                        StudymaterialForm.controls['Topicname'].touched)
                  }"
                >
                  <option value="">Select Topic</option>
                  <option *ngFor="let item of Topic" value="{{ item.id }}">
                    {{ item.topic_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <input
                [(ngModel)]="Title"
                formControlName="Title"
                placeholder="Title"
                name="name"
                [ngClass]="{
                  err:
                    StudymaterialForm.controls['Title'].hasError('required') &&
                    (StudymaterialForm.controls['Title'].dirty ||
                      StudymaterialForm.controls['Title'].touched)
                }"
              />
            </div>
          </div>
          <div>
            <label class="Ck-Editor-label">Content</label>
            <div class="form-group has-feedback">
              <ckeditor
                editorUrl="../../../assets/ckeditor/ckeditor.js"
                [(ngModel)]="Content"
                #myckeditor
                name="myckeditor"
                required
                formControlName="Content"
                [config]="ckeConfig"
                debounce="500"
              >
              </ckeditor>
            </div>
          </div>
        </form>

        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="Submit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="Update()">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="ckdivnew" *ngIf="showFullScreen">
  <span class="material-icons ckfull" (click)="showFullScreen = !showFullScreen"
    >fullscreen_exit</span
  >
</div>
<div class="cutome-modal" *ngIf="isBulkUpload">
  <div class="cutome-modal-content" style="width: 50%">
    <div class="modal-header">
      <p>Bulk Upload</p>
      <span (click)="closeBulkUploadModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <ngx-dropzone
          maxFileSize="100MB"
          accept=".csv"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label
            >Drag & drop here (or)
            <span class="browse">Browse</span></ngx-dropzone-label
          >
          <div *ngFor="let f of files">
            <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
          </div>
        </ngx-dropzone>
        <div class="format" *ngIf="files.length <= 0">
          <span>Allowed Format: CSV</span>
        </div>
        <div class="format" *ngFor="let f of files">
          <div class="bbulkupload">
            <span *ngFor="let t of f">{{ t.name }} </span>

            <mat-icon (click)="onRemove(f)">close</mat-icon>
          </div>
        </div>
        <div class="format">
          <progress
            value="{{ progress }}"
            max="10"
            id="progressBar"
            *ngIf="selectprogress"
          ></progress>
        </div>
        <div class="format">
          <a (click)="ExportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            Written_sample.xlsx
          </a>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeBulkUploadModal()">
        Cancel
      </button>
      <button
        class="submit-btn"
        (click)="bulkUpload()"
        [disabled]="selectBulkupload"
      >
        Upload
      </button>
    </div>
  </div>
</div>
