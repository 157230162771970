<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>URL Shortener</h3>
    </div>
  </div>
  <div class="main-short">
    <div class="search-yourlink">
      <div class="search-icon">
        <span class="material-icons">link</span>
        <input
          placeholder="Shorten your link"
          [(ngModel)]="EnterShortURL"
          autocomplete="off"
          name="EnterShortURL"
        />
      </div>
      <button (click)="addNewSubDomain()">Shorten URL & Generate QR</button>
    </div>
  </div>
  <div class="main-pagemain">
    <div class="pagetitle pagetitle-flex">
      <span class="material-icons">history</span>
      <h4>Short URL's history</h4>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by URL name & Short URL name..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="urlname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name of the URL
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.urlname }}">
              {{ element.urlname }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="shorturl">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Short URL</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.shorturl }}">
              {{ element.shorturl }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <div class="actions-btn">
              <button class="copied" *ngIf="row.copymark">
                <span class="material-icons"> done </span>
                Copied!
              </button>
              <button
                class="copy_url"
                *ngIf="!row.copymark"
                (click)="gridUrlClick(row)"
              >
                <span class="material-icons"> content_copy</span> Copy URL
              </button>
              <img
                src="../../../assets/images/url_short.png"
                class="action-icon"
                (click)="downloadQrCode(row)"
              />
              <img
                src="../../../assets/images/edit-1.png"
                (click)="edit(row)"
                alt="Edit"
                class="action-icon"
              />
              <img
                src="../../../assets/images/remove.png"
                (click)="delete(row)"
                alt="Delete"
                class="action-icon"
              />
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<div id="video_qrcode" style="display: none">
  <ngx-kjua
    [text]="Qr_Url"
    [renderAsync]="false"
    [render]="'svg'"
    [crisp]="true"
    [minVersion]="1"
    [ecLevel]="'H'"
    [size]="500"
    [ratio]="1"
    [fill]="'#333'"
    [mSize]="40"
    [back]="'#fff'"
    [rounded]="100"
    [quiet]="0"
    [mode]="'labelimage'"
    [image]="base64"
    [cssClass]="'coolQRCode'"
    style="display: contents; height: 500px"
    #qrEle
    id="container"
  >
  </ngx-kjua>
</div>
<div class="cutome-modal" *ngIf="addShortURL">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Short URL</p>
      <p *ngIf="IsUpdate">Edit Short URL</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="ShortenUrlForm">
          <div class="formwrapper">
            <div class="d-flex2">
              <span class="material-icons">link</span>
              <input
                type="text"
                [(ngModel)]="fullurl"
                formControlName="fullurl"
                placeholder="Shorten your URL"
                (keyup)="GenerateQRCode()"
                class="link-attached"
              />
              <span
                class="material-icons"
                *ngIf="fullurl != ''"
                (click)="clearFullUrl()"
              >
                close
              </span>
            </div>
            <div class="d-flex">
              <div class="w-68">
                <label>Short URL</label>
                <div class="url-short">
                  <input
                    formControlName="shorturl"
                    class="urlshort-input"
                    [(ngModel)]="shorturl"
                    placeholder="Short URL"
                    required
                    (keyup)="ChangeCopyText(false)"
                    [ngClass]="{
                      err:
                        ShortenUrlForm.controls['shorturl'].hasError(
                          'required'
                        ) &&
                        (ShortenUrlForm.controls['shorturl'].dirty ||
                          ShortenUrlForm.controls['shorturl'].touched)
                    }"
                  />
                  <div class="button-copy">
                    <button class="copied" *ngIf="ModelCopyUrl">
                      <span class="material-icons"> done </span>
                      Copied!
                    </button>
                    <button
                      class="copy_url"
                      *ngIf="!ModelCopyUrl"
                      (click)="ModelCopyUrlClick()"
                    >
                      <span class="material-icons"> content_copy</span> Copy URL
                    </button>
                  </div>
                </div>
                <label>URL Name</label>
                <input
                  formControlName="urlname"
                  [(ngModel)]="urlname"
                  placeholder="URL Name"
                  required
                  [ngClass]="{
                    err:
                      ShortenUrlForm.controls['urlname'].hasError('required') &&
                      (ShortenUrlForm.controls['urlname'].dirty ||
                        ShortenUrlForm.controls['urlname'].touched)
                  }"
                />
              </div>
              <div class="w-30">
                <div class="w-qr">
                  <p class="qr-download">QR Preview</p>
                  <ngx-kjua
                    [text]="Qr_Url"
                    [renderAsync]="false"
                    [render]="'svg'"
                    [crisp]="true"
                    [minVersion]="1"
                    [ecLevel]="'H'"
                    [size]="70"
                    [ratio]="undefined"
                    [fill]="'#333'"
                    [mSize]="40"
                    [back]="'#fff'"
                    [rounded]="100"
                    [quiet]="0"
                    [mode]="'labelimage'"
                    [image]="base64"
                    [cssClass]="'coolQRCode'"
                    style="
                      width: 70px;
                      height: 70px;
                      background: #151a41;
                      padding: 11px;
                      border-radius: 5px;
                    "
                    #qrEle
                    id="container"
                  >
                  </ngx-kjua>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="Update()">Save</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
