import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class LiveeventsService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async DeleteLiveEvents(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_EVENT_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async AddLiveEvents(
    certi_id,
    event_name,
    event_link,
    start_date,
    start_time,
    end_time,
    is_repeat,
    is_record,
    is_auto_record,
    meeting_playform,
    time_zone,
    token,
    repeat_type,
    repeat_count,
    repeat_on,
    ends_type,
    ends_on,
    total_occurances,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          event_name: event_name,
          event_link: event_link,
          start_date: start_date,
          start_time: start_time,
          end_time: end_time,
          is_repeat: is_repeat,
          is_record: is_record,
          is_auto_record: is_auto_record,
          meeting_playform: meeting_playform,
          time_zone: time_zone,
          repeat_type: repeat_type,
          repeat_count: repeat_count,
          repeat_on: repeat_on,
          ends_type: ends_type,
          ends_on: ends_on,
          total_occurances: total_occurances,
          created_by: window.sessionStorage.getItem('b6h3a5d9r6e3s5h'),
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_EVENT_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateLiveEvents(
    certi_id,
    event_name,
    event_link,
    start_date,
    start_time,
    end_time,
    is_repeat,
    token,
    repeat_type,
    repeat_count,
    repeat_on,
    ends_type,
    ends_on,
    total_occurances,
    id,
    is_record,
    is_auto_record,
    meeting_playform,
    time_zone,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          event_name: event_name,
          event_link: event_link,
          start_date: start_date,
          start_time: start_time,
          end_time: end_time,
          is_repeat: is_repeat,
          repeat_count: repeat_count,
          repeat_type: repeat_type,
          repeat_on: repeat_on,
          ends_type: ends_type,
          ends_on: ends_on,
          total_occurances: total_occurances,
          id: id,
          is_record: is_record,
          is_auto_record: is_auto_record,
          meeting_playform: meeting_playform,
          time_zone: time_zone,
          created_by: window.sessionStorage.getItem('b6h3a5d9r6e3s5h'),
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_EVENT_Update;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllLiveEvents(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_EVENT_ALL;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async getUserwithcohorts(event_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: event_id,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_ATTENDY;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAttendySelfPaced(event_id, course_type, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          course_type: course_type,
          id: event_id,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_ATTENDY_SELFPACED;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async add_recording(recodermeeting, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(recodermeeting)),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADD_RECORDING;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async UpdatestartLiveEvents(ObjData, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(ObjData)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_EVENT_Update;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async recurringLiveEvents(
    certi_id,
    event_name,
    event_link,
    start_date,
    start_time,
    end_time,
    is_repeat,
    token,
    repeat_type,
    repeat_count,
    repeat_on,
    ends_type,
    ends_on,
    total_occurances,
    id,
    is_record,
    is_auto_record,
    meeting_playform,
    time_zone,
    array_start_date,
    dstartdate,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          event_name: event_name,
          event_link: event_link,
          start_date: start_date,
          start_time: start_time,
          end_time: end_time,
          is_repeat: is_repeat,
          repeat_count: repeat_count,
          repeat_type: repeat_type,
          repeat_on: repeat_on,
          ends_type: ends_type,
          ends_on: ends_on,
          total_occurances: total_occurances,
          live_event_id: id,
          is_record: is_record,
          is_auto_record: is_auto_record,
          meeting_playform: meeting_playform,
          time_zone: time_zone,
          array_start_date: array_start_date,
          dstartdate: dstartdate,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ALL_LIVE_EVENT_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdatestartAllLiveEvents(ObjData, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(ObjData)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ALL_LIVE_EVENT_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async all_live_event_Start_time_event(event_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: event_id,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.ALL_LIVE_EVENT_START_TIME_EVENT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
