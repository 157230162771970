<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Hybrid Class</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Hybrid Class/Company/Course Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewCohort()">Add Hybrid Class</button>
    </div>
    <div class="pagesection">
      <div class="mat-elevation-z8">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          style="border-spacing: 0 10px"
        >
          <ng-container matColumnDef="Srno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.Srno }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="cohort_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Hybrid Class Name
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.cohort_name }}">{{
                element.cohort_name
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.company ? element.company : '-' }}">{{
                element.company ? element.company : '-'
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="template_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Course</th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.template_name }}">{{
                element.template_name
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="studentCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              # Students
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.studentCount }}">{{
                element.studentCount
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Start Date
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.start_date | date: 'short' }}">{{
                element.start_date | date: 'dd MMM yyyy'
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <img
                src="../../../assets/images/edit-1.png"
                (click)="edit(row)"
                alt="Edit"
                class="action-icon"
              />
              <img
                src="../../../assets/images/remove.png"
                (click)="delete(row)"
                alt="Delete"
                class="action-icon"
              />
              <img
                src="../../../assets/images/Report.png"
                (click)="gotoCohortReport(row)"
                alt="Report"
                class="action-icon"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      <mat-paginator
        style-paginator
        showFirstLastButtons
        [showTotalPages]="3"
        *ngIf="pagination"
        [length]="dataSource.data ? dataSource.data.length : 0"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50, 100, 500]"
      >
      </mat-paginator>
    </div>
  </div>
  <ngx-spinner
    bdColor="#00041aa6"
    size="default"
    color="##d7779b"
    template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
    [fullScreen]="true"
  >
  </ngx-spinner>
  <div class="cutome-modal" *ngIf="addCohort">
    <div class="cutome-modal-content">
      <div class="modal-header">
        <p *ngIf="!IsUpdate">Add Hybrid Class</p>
        <p *ngIf="IsUpdate">Edit Hybrid Class</p>
        <span (click)="closeModal()" class="material-icons">close</span>
      </div>
      <div class="modal-body">
        <div>
          <div class="formwrapper">
            <div class="select-box">
              <select [(ngModel)]="SelectedCompany">
                <option value="">Company</option>
                <option value="{{ item.id }}" *ngFor="let item of Companies">
                  {{ item.name }}
                </option>
              </select>
              <span></span>
            </div>
            <div class="select-box">
              <select
                [(ngModel)]="SelectedTemplate"
                (change)="changeTemplate()"
              >
                <option value="">Live Course Template</option>
                <option value="{{ item.id }}" *ngFor="let item of Templates">
                  {{ item.templateName }}
                </option>
              </select>
              <span></span>
            </div>
          </div>

          <div class="formwrapper">
            <div class="cohort-name w-100">
              <input [(ngModel)]="CohortName" placeholder="Class Name" />
            </div>
          </div>
          <div class="formwrapper">
            <div class="cohort-name">
              <ng-moment-timezone-picker
                customPlaceholderText="Time Zone"
                [(ngModel)]="time_zone"
              >
              </ng-moment-timezone-picker>
            </div>
            <div class="cohort-name">
              <input [(ngModel)]="Location" placeholder="Location" />
            </div>
          </div>
          <div class="formwrapper">
            <div class="date-input">
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [matDatepicker]="picker"
                  [disabled]="true"
                  placeholder="Class Start Date"
                  [(ngModel)]="StartDate"
                  (dateChange)="changeStartDate()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="false"></mat-datepicker>
              </mat-form-field>
              <span>
                <img
                  src="../../../assets/images/Calendar.svg"
                  alt="Calendar"
                  class="calendar-icon"
                />
              </span>
            </div>
          </div>
          <div class="course-name">
            <div class="course-input">
              <div class="check-app">
                <label class="container">
                  Videos
                  <input
                    type="checkbox"
                    [(ngModel)]="VideoAccess"
                    (change)="ChangeSubscriptionMaterial()"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Mindmaps
                  <input
                    type="checkbox"
                    [(ngModel)]="MindmapAccess"
                    (change)="ChangeSubscriptionMaterial()"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Summaries
                  <input type="checkbox" [(ngModel)]="SummaryAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  References
                  <input type="checkbox" [(ngModel)]="ReferenceAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Knowledge Assessments
                  <input
                    type="checkbox"
                    [(ngModel)]="KAAccess"
                    (change)="ChangeSubscriptionMaterial()"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Practice Tests
                  <input
                    type="checkbox"
                    [(ngModel)]="PTAccess"
                    (change)="ChangeSubscriptionMaterial()"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="course-input">
              <div class="check-app">
                <label class="container">
                  CAT Exams (free per attempt)
                  <input
                    type="checkbox"
                    [(ngModel)]="CATAccess"
                    (change)="ChangeSubscriptionMaterial()"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Live Chat
                  <input type="checkbox" [(ngModel)]="LiveChatAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Live Questions
                  <input type="checkbox" [(ngModel)]="LiveQuestionAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Live Events
                  <input
                    type="checkbox"
                    [(ngModel)]="LiveEventAccess"
                    (change)="ChangeSubscriptionLiveEvent()"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Flashcard App (full access)
                  <input type="checkbox" [(ngModel)]="FashFullAccess" />
                  <span class="checkmark"></span>
                </label>
                <label class="container">
                  Practice Question App (full access)
                  <input type="checkbox" [(ngModel)]="PraAppFullAccess" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="add-new-event" *ngIf="LiveEventAccess">
            <div class="events">
              <span>Live Events</span>
            </div>
            <ng-select
              [items]="LiveEvents"
              bindLabel="event_name"
              bindValue="id"
              placeholder="Select Live Events"
              appendTo="body"
              multiple="true"
              [(ngModel)]="SelectedEvents"
            >
            </ng-select>
          </div>
          <div class="knowledge-ass">
            <div class="live-events">
              <div class="pricing">
                <h2 class="pricing-title">Add Schedule items</h2>
                <div class="select-pricing">
                  <select [(ngModel)]="ScheduleItem">
                    <option value="Section">Section</option>
                    <option value="Flashcard App">Flashcard App</option>
                    <option value="Practice Question App">
                      Practice Question App
                    </option>
                    <option value="Download">Download</option>
                    <option value="video">Video</option>
                  </select>
                  <button (click)="addScheduleItems()">Add</button>
                </div>
              </div>
              <div class="course-input">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                  <div
                    cdkDrag
                    *ngFor="let item of StudyMaterialsSub; let i = index"
                  >
                    <div
                      id="select"
                      class="all-domain"
                      style="
                        border: 1px solid #303563;
                        padding: 5px 10px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        cursor: move;
                        background: #1b1f48;
                        width: 94%;
                        margin-left: auto;
                      "
                      *ngIf="item.type == 'title' || item.visible_material"
                    >
                      <div
                        *ngIf="item.type == 'title'"
                        style="display: flex; width: 100%"
                      >
                        <div
                          style="width: 90%; display: flex; align-items: center"
                        >
                          <div style="line-height: 0">
                            <img
                              src="../../../assets/images/sidebar.JPG"
                              alt=""
                              style="width: 13px; line-height: 0"
                            />
                          </div>
                          <div
                            class="domain-name"
                            style="
                              display: flex;
                              align-items: center;
                              width: 100%;
                            "
                          >
                            <div
                              class="select-box"
                              style="
                                display: flex;
                                align-items: center;
                                width: 27%;
                              "
                            >
                              <img
                                src="../../../assets/images/{{ item.icon }}"
                                alt=""
                                style="margin: 0 10px 0 10px"
                              />
                              <p
                                style="
                                  color: #9891d1;
                                  margin: 0;
                                  margin-right: 5px;
                                "
                              >
                                {{ item.title_type }}
                              </p>
                            </div>
                            <span
                              style="color: #9891d1; width: 73%"
                              *ngIf="
                                item.title_type != 'Download' &&
                                item.title_type != 'video'
                              "
                            >
                              <input
                                type="text"
                                [(ngModel)]="item.title_name"
                                class="textbox"
                                style="
                                  border: solid 1px #4a4a80;
                                  width: calc(100% - 20px);
                                  background: none;
                                  color: #8683b2;
                                  outline: none;
                                  border-radius: 8px;
                                  padding: 10px;
                                  font-size: 15px;
                                "
                              />
                            </span>
                            <span
                              style="color: #9891d1; width: 73%"
                              *ngIf="item.title_type == 'video'"
                            >
                              <input
                                type="text"
                                placeholder="Title"
                                [(ngModel)]="item.title_name"
                                class="textbox"
                                style="
                                  margin-bottom: 5px;
                                  border: solid 1px #4a4a80;
                                  width: calc(100% - 20px);
                                  background: none;
                                  color: #8683b2;
                                  outline: none;
                                  border-radius: 8px;
                                  padding: 10px;
                                  font-size: 15px;
                                "
                              />
                              <input
                                type="text"
                                placeholder="Description"
                                [(ngModel)]="item.download_description"
                                class="textbox"
                                style="
                                  margin-bottom: 5px;
                                  border: solid 1px #4a4a80;
                                  width: calc(100% - 20px);
                                  background: none;
                                  color: #8683b2;
                                  outline: none;
                                  border-radius: 8px;
                                  padding: 10px;
                                  font-size: 15px;
                                "
                              />
                              <div
                                style="
                                  position: relative;
                                  align-items: center;
                                  justify-content: space-between;
                                  display: flex;
                                  border-radius: 8px;
                                  font-size: 15px;
                                  outline: none;
                                  border: 1px solid #4a4a80;
                                  background: none;
                                  width: 100%;
                                  box-sizing: border-box;
                                  color: #8d87c5;
                                "
                              >
                                <span style="margin-left: 10px">{{
                                  item.download_url.substring(
                                    54,
                                    item.download_url.length
                                  )
                                }}</span>
                                <button
                                  style="
                                    white-space: nowrap;
                                    background: #45287e;
                                    border: none;
                                    outline: none;
                                    color: #a799e2;
                                    padding: 7px 15px;
                                    margin: 5px;
                                    border-radius: 5px;
                                  "
                                >
                                  Choose File
                                </button>
                                <input
                                  style="position: absolute; opacity: 0"
                                  type="file"
                                  #myInputFile
                                  (click)="myInputFile.value = null"
                                  (change)="handleFileSelect($event, item)"
                                  class="textbox"
                                />
                              </div>
                            </span>
                            <span
                              style="color: #9891d1; width: 73%"
                              *ngIf="item.title_type == 'Download'"
                            >
                              <input
                                type="text"
                                placeholder="Title"
                                [(ngModel)]="item.title_name"
                                class="textbox"
                                style="
                                  margin-bottom: 5px;
                                  border: solid 1px #4a4a80;
                                  width: calc(100% - 20px);
                                  background: none;
                                  color: #8683b2;
                                  outline: none;
                                  border-radius: 8px;
                                  padding: 10px;
                                  font-size: 15px;
                                "
                              />
                              <input
                                type="text"
                                placeholder="Description"
                                [(ngModel)]="item.download_description"
                                class="textbox"
                                style="
                                  margin-bottom: 5px;
                                  border: solid 1px #4a4a80;
                                  width: calc(100% - 20px);
                                  background: none;
                                  color: #8683b2;
                                  outline: none;
                                  border-radius: 8px;
                                  padding: 10px;
                                  font-size: 15px;
                                "
                              />
                              <div
                                style="
                                  position: relative;
                                  align-items: center;
                                  justify-content: space-between;
                                  display: flex;
                                  border-radius: 8px;
                                  font-size: 15px;
                                  outline: none;
                                  border: 1px solid #4a4a80;
                                  background: none;
                                  width: 100%;
                                  box-sizing: border-box;
                                  color: #8d87c5;
                                "
                              >
                                <span style="margin-left: 10px">{{
                                  item.download_url.substring(
                                    54,
                                    item.download_url.length
                                  )
                                }}</span>
                                <button
                                  style="
                                    white-space: nowrap;
                                    background: #45287e;
                                    border: none;
                                    outline: none;
                                    color: #a799e2;
                                    padding: 7px 15px;
                                    margin: 5px;
                                    border-radius: 5px;
                                  "
                                >
                                  Choose File
                                </button>
                                <input
                                  style="position: absolute; opacity: 0"
                                  type="file"
                                  #myInputFile
                                  (click)="myInputFile.value = null"
                                  (change)="handleFileSelect($event, item)"
                                  class="textbox"
                                />
                              </div>
                            </span>
                          </div>
                        </div>
                        <div
                          style="
                            width: 17%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                          "
                        >
                          <span
                            (click)="moveup(i)"
                            style="
                              background: #222650;
                              width: 23px;
                              height: 23px;
                              border-radius: 4px;
                              margin-left: 4px;
                            "
                          >
                            <mat-icon
                              style="
                                font-size: 17px;
                                color: #434473;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              arrow_upward</mat-icon
                            >
                          </span>
                          <span
                            (click)="movedown(i)"
                            style="
                              background: #222650;
                              width: 25px;
                              height: 25px;
                              border-radius: 4px;
                              margin-left: 4px;
                            "
                          >
                            <mat-icon
                              style="
                                font-size: 17px;
                                color: #434473;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              arrow_downward</mat-icon
                            >
                          </span>
                          <span (click)="deleteScheduleItems(item)">
                            <mat-icon
                              style="
                                border: solid 2px #434473;
                                border-radius: 50%;
                                font-size: 16px;
                                color: #434473;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-weight: 600;
                                margin-left: 6px;
                                height: 20px;
                                width: 20px;
                              "
                            >
                              clear</mat-icon
                            >
                          </span>
                        </div>
                      </div>

                      <div
                        *ngIf="item.type != 'title' && item.visible_material"
                        style="display: flex; width: 100%"
                      >
                        <div
                          style="
                            width: 40%;
                            display: flex;
                            align-items: flex-start;
                            border-right: 1px solid #303563;
                            padding-top: 10px;
                          "
                        >
                          <div style="margin-right: 20px; line-height: 0">
                            <img
                              src="../../../assets/images/sidebar.JPG"
                              alt=""
                              style="width: 13px; line-height: 0"
                            />
                          </div>
                          <div
                            class="domain-name"
                            style="display: flex; align-items: flex-start"
                          >
                            <img
                              src="../../../assets/images/{{ item.icon }}"
                              alt=""
                              style="margin: 5px 10px 0 10px"
                            />
                            <span style="color: #9891d1; padding-right: 10px">
                              {{ item.name }}</span
                            >
                          </div>
                        </div>
                        <div
                          style="
                            width: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                          "
                        >
                          <div class="over">
                            <div class="check-app">
                              <label
                                class="container"
                                style="
                                  margin: 10px 0;
                                  color: #9891d1;
                                  position: relative;
                                "
                              >
                                <input
                                  type="checkbox"
                                  [(ngModel)]="item.Assigned"
                                />
                                <span
                                  class="checkmark"
                                  style="
                                    position: absolute;
                                    top: 2px;
                                    left: 0;
                                    height: 15px;
                                    width: 15px;
                                    border: 1px solid #4e4c7f;
                                    border-radius: 5px;
                                  "
                                ></span>
                                Assigned
                              </label>
                            </div>
                            <div class="check-app check-app2">
                              <label
                                class="container"
                                style="color: #9891d1; position: relative"
                              >
                                <input
                                  type="checkbox"
                                  [(ngModel)]="item.Required"
                                />
                                <span
                                  class="checkmark"
                                  style="
                                    position: absolute;
                                    top: 2px;
                                    left: 0;
                                    height: 15px;
                                    width: 15px;
                                    border: 1px solid #4e4c7f;
                                    border-radius: 5px;
                                  "
                                ></span>
                                Required
                              </label>
                            </div>
                          </div>
                          <div class="over">
                            <div
                              class="due"
                              [ngClass]="{ opacity: item.Assigned }"
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 7px 0;
                              "
                            >
                              <label
                                for="Due"
                                style="
                                  margin-bottom: 0px;
                                  width: 55px;
                                  text-align: right;
                                  color: #9891d1;
                                "
                                >Due:</label
                              >
                              <input
                                type="date"
                                class="date-w"
                                [disabled]="!item.Assigned"
                                [(ngModel)]="item.Due"
                                style="
                                  width: calc(70% - 20px);
                                  background: none;
                                  color: #8683b2;
                                  outline: none;
                                  border-radius: 4px;
                                  padding: 4px;
                                  font-size: 14px;
                                  border: 1px solid #2f2f5e;
                                "
                              />
                            </div>
                            <div
                              class="due due2"
                              [ngClass]="{ opacity: item.Required }"
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 7px 0;
                              "
                            >
                              <label
                                for="Due"
                                style="margin-bottom: 0px; color: #9891d1"
                                >Overdue:</label
                              >
                              <input
                                type="date"
                                class="date-w"
                                [disabled]="!item.Required"
                                [(ngModel)]="item.OverDue"
                                [min]="item.Due | date: 'yyyy-MM-dd'"
                                style="
                                  width: calc(70% - 20px);
                                  background: none;
                                  color: #8683b2;
                                  outline: none;
                                  border-radius: 4px;
                                  padding: 4px;
                                  font-size: 14px;
                                  border: 1px solid #2f2f5e;
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style="
                            width: 10%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                          "
                        >
                          <span
                            (click)="moveup(i)"
                            style="
                              background: #222650;
                              width: 23px;
                              height: 23px;
                              border-radius: 4px;
                              margin-left: 4px;
                            "
                          >
                            <mat-icon
                              style="
                                font-size: 20px;
                                color: #434473;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              arrow_upward</mat-icon
                            >
                          </span>
                          <span
                            (click)="movedown(i)"
                            style="
                              background: #222650;
                              width: 23px;
                              height: 23px;
                              border-radius: 4px;
                              margin-left: 4px;
                            "
                          >
                            <mat-icon
                              style="
                                font-size: 20px;
                                color: #434473;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              arrow_downward</mat-icon
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="users-table">
            <div class="main-title">
              <div class="student-filters">
                <span>Students</span>

                <div class="search-icon">
                  <select
                    [(ngModel)]="SearchSelectedCompany"
                    name="SearchEmail"
                    id="SearchEmail"
                    (change)="changeCompany($event)"
                  >
                    <option value="0">Filter by Company</option>
                    <option
                      value="{{ item.id }}"
                      *ngFor="let item of Companies"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <input
                    placeholder="Search by Student Name/Email"
                    [(ngModel)]="SearchStudent"
                    (keyup)="filterStudent()"
                    name="search"
                  />
                  <span class="material-icons">search</span>
                </div>
              </div>
            </div>
            <div class="students-list">
              <table
                mat-table
                [dataSource]="dataSourceStudents"
                style="border-spacing: 0 10px"
              >
                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? masterToggle($event) : null"
                      [checked]="selection.hasValue() && isEntirePageSelected()"
                      [indeterminate]="
                        selection.hasValue() && !isEntirePageSelected()
                      "
                    ></mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="check-app">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="changeCheckbox(row, $event)"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row) || row.checked"
                      >
                      </mat-checkbox>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Student Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Student Email</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.email }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsStudents"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsStudents"
                ></tr>
              </table>
              <mat-paginator
                #studentpage
                (page)="pageEvents($event)"
                [length]="totalUsers"
                [pageSizeOptions]="[10, 20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
          <div class="history-div" *ngIf="IsUpdate">
            <h3><span class="material-icons"> history </span>Audit history</h3>
            <div class="audit-table">
              <div class="table-header">
                <div class="date-title"><span>Date & Time</span></div>
                <div class="action-title"><span>Action</span></div>
              </div>
              <div class="main-body-audit">
                <div class="table-body" *ngFor="let log of loglist">
                  <div class="date-title">
                    <span>{{ log.createdAt | date: 'medium' }}</span>
                  </div>
                  <div class="action-title">
                    <span [innerHTML]="log.description"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel-btn" (click)="closeModal()">Cancel</button>
        <div *ngIf="!IsUpdate">
          <button type="button" class="submit-btn" (click)="SaveCohort()">
            Save
          </button>
        </div>
        <div *ngIf="IsUpdate">
          <button type="button" class="submit-btn" (click)="UpdateCohort()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="cutome-modal" *ngIf="LiveEventPopup">
    <div class="cutome-modal-content" style="width: 45%">
      <div class="modal-header">
        <p>Live Events</p>
        <span class="material-icons" (click)="closeLiveEventPopup()"
          >close</span
        >
      </div>

      <div class="modal-body" style="min-height: 110px">
        <div>
          <div class="students-list">
            <div class="event-list" *ngIf="!NoLiveEventFound">
              <div class="rowevent header">
                <div class="value first">Event Name</div>
                <div class="value">Start Date</div>
                <div class="value">Start Time</div>
                <div class="value last">Action</div>
              </div>
              <div class="rowevent" *ngFor="let item of PopLiveEvents">
                <div class="value first">{{ item.event_name }}</div>
                <div class="value">
                  {{ item.start_date | date: 'dd MMM yyyy' }}
                </div>
                <div class="value">{{ item.start_time }}</div>
              </div>
            </div>
            <div class="no-course" *ngIf="NoLiveEventFound">
              <span
                ><svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Login-Flow"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Assets-New-Screens"
                      transform="translate(-104.000000, -237.000000)"
                      fill="#a39bde"
                    >
                      <g
                        id="icon-alert"
                        transform="translate(104.000000, 237.000000)"
                      >
                        <path
                          d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M6,1.1 C3.29380473,1.1 1.1,3.29380473 1.1,6 C1.1,8.70619527 3.29380473,10.9 6,10.9 C8.70619527,10.9 10.9,8.70619527 10.9,6 C10.9,3.29380473 8.70619527,1.1 6,1.1 Z M6,2.93081622 C6.27338095,2.93081622 6.50017619,3.13027336 6.54280143,3.39160336 L6.55,3.48081622 L6.55,5.66865795 C6.55,5.97241456 6.30375661,6.21865795 6,6.21865795 C5.72661905,6.21865795 5.49982381,6.01920081 5.45719857,5.75787081 L5.45,5.66865795 L5.45,3.48081622 C5.45,3.17705961 5.69624339,2.93081622 6,2.93081622 Z"
                          id="Combined-Shape"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M6,8.96240597 C6.4418278,8.96240597 6.8,8.60423377 6.8,8.16240597 C6.8,7.72057817 6.4418278,7.36240597 6,7.36240597 C5.5581722,7.36240597 5.2,7.72057817 5.2,8.16240597 C5.2,8.60423377 5.5581722,8.96240597 6,8.96240597 Z"
                          id="Oval"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <p>No live events are schedules for the selected class</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel-btn" (click)="closeLiveEventPopup()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
