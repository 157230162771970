import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/service/authorization.service';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-paymentreceipt',
  templateUrl: './paymentreceipt.component.html',
  styleUrls: ['./paymentreceipt.component.scss'],
})
export class PaymentreceiptComponent implements OnInit {
  ReceiptData: any;
  constructor(
    public auth: AuthorizationService,
    public adminService: AdminService,
    public spinner: NgxSpinnerService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        let Details = JSON.parse(atob(params['id']));
        this.spinner.show();
        await this.getPaymentReceipt(Details.id);
      } catch (error) {
        this.router.navigate(['main/students']);
      }
    });
  }
  ngOnInit(): void {}
  async getPaymentReceipt(id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.spinner.show();
        await this.adminService
          .payTransactionById(id, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.ReceiptData = list.data[0];
            }
          });
        this.spinner.hide();
      }
    });
  }
}
