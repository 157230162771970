<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Failed Emails</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Domain Name, Number, Certificate..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.srno }}">{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.email }}">{{ element.email }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="cohort">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Live Class Name
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.cohort }}">{{ element.cohort }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Error Message
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.message }}">{{ element.message }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Type</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.email_type }}">{{
              element.email_type
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.createdAt }}">{{
              element.createdAt | date: 'medium'
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
