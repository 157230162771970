import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class SelfpacedService {
  stripeKey =
    'sk_test_51JoLjKAI7lJSHRGDiZMXhMTrr7cAswHBCB7WLh7mGRM5Uu70uJ5egoRcnBEdWFx3vsAb4ROqgaBE3Sb120XnRgpG00sDb5zjVr'; //us
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async fillAllDropdown(certi_id, token) {
    return new Promise((resolve) => {
      let data = {
        data: this.crypto.encrypt(
          JSON.stringify({
            certi_id: certi_id,
          }),
        ),
      };
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_ALL_DROPDOWN;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async deleteSelfPaced(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async deleteSelfPacedClass(SelfPaceddata, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(SelfPaceddata)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DELETE_SELF_CLASS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async editSelfPaced(
    id,
    certi_id,
    class_name,
    description,
    features_visible,
    ka_id,
    pt_id,
    live_events,
    only_show_study,
    publish_free_trial,
    cohort_id,
    company_id,
    mentor_id,
    time_zone,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          certi_id: certi_id,
          class_name: class_name,
          description: description,
          features_visible: features_visible,
          ka_id: ka_id,
          pt_id: pt_id,
          live_events: live_events,
          only_show_study: only_show_study,
          publish_free_trial: publish_free_trial,
          cohort_id: cohort_id,
          mentor_id: mentor_id,
          time_zone: time_zone,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async editSelfPacedOneTime(
    id,
    certi_id,
    price_label,
    features_visible,
    live_events,
    only_show_study,
    publish_subscription,
    cat_exam_attempts,
    attempts_fee,
    description,
    access_duration,
    price,
    cohort_id,
    company_id,
    mentor_id,
    time_zone,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          certi_id: certi_id,
          price_label: price_label,
          features_visible: features_visible,
          live_events: live_events,
          only_show_study: only_show_study,
          publish_subscription: publish_subscription,
          cat_exam_attempts: cat_exam_attempts,
          attempts_fee: attempts_fee,
          description: description,
          access_duration: access_duration,
          price: price,
          cohort_id: cohort_id,
          mentor_id: mentor_id,
          time_zone: time_zone,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_ONE_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllSefPaced(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_ALL;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async editSelfPacedSubscription(
    id,
    certi_id,
    price_label,
    features_visible,
    live_events,
    only_show_study,
    publish_subscription,
    cat_exam_attempts,
    attempts_fee,
    subscriptions,
    cohort_id,
    company_id,
    mentor_id,
    time_zone,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          certi_id: certi_id,
          price_label: price_label,
          features_visible: features_visible,
          live_events: live_events,
          only_show_study: only_show_study,
          publish_subscription: publish_subscription,
          cat_exam_attempts: cat_exam_attempts,
          attempts_fee: attempts_fee,
          subscriptions: subscriptions,
          cohort_id: cohort_id,
          mentor_id: mentor_id,
          time_zone: time_zone,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_SUB_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllMaterials(certi_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_ALL_MATERIALS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async saveMaterials(method_id, method_type, Subscriptions, OneTime, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          method_id: method_id,
          method_type: method_type,
          Subscriptions: Subscriptions,
          OneTime: OneTime,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_SAVE_MATERIALS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getSaveMaterials(method_id, method_type, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          method_id: method_id,
          method_type: method_type,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_GET_SAVE_MATERIALS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  public CreateProduct(product_name, description) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = 'name=' + product_name + '&description=' + description;
    let options = new RequestOptions({ headers: headers });
    var curl = 'https://api.stripe.com/v1/products';
    return new Promise((resolve, reject) => {
      this.http.post(curl, body, options).subscribe(
        (res) => {
          resolve(res['_body']);
        },
        (err) => {
          resolve(err['_body']);
          console.log('The error is ', err);
        },
      );
    });
  }
  public CreatePlan(Amount, ProductId, days) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body =
      'amount=' +
      Amount +
      '&currency=usd&interval=day&interval_count=' +
      days +
      '&product=' +
      ProductId;
    let options = new RequestOptions({ headers: headers });
    var curl = 'https://api.stripe.com/v1/plans';
    return new Promise((resolve, reject) => {
      this.http.post(curl, body, options).subscribe(
        (res) => {
          resolve(res['_body']);
        },
        (err) => {
          resolve(err['_body']);
          console.log('The error is ', err);
        },
      );
    });
  }
  async uploadLargeFile_OLD(base64Data, mime, token) {
    var data = {
      base64Data: base64Data,
      mime_type: mime,
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });

      var url = environment.BASE_URL + environment.UPLOAD_FILE;

      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async uploadFile(base64Data, mime_type, token) {
    var chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var upload_key = '';
    for (var i = 10; i > 0; --i) {
      upload_key += chars[Math.floor(Math.random() * chars.length)];
    }

    for (
      var i = 0, charsLength = base64Data.length;
      i < charsLength;
      i += 5000000
    ) {
      let uploadPromiseResult = {
        upload_data: base64Data.substring(i, i + 5000000),
        upload_key: upload_key,
      };

      await this.uploadLargePart(uploadPromiseResult, token).then(
        (uploadPartData) => {
          if (uploadPartData[0].status == 200) {
            var resuploadPart = JSON.parse(<string>uploadPartData[0].json).data;
          }
        },
      );
    }
    var complete_data = {
      upload_key: upload_key,
      mime_type: mime_type,
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.UPLOAD_LARGE_FILE_COMPLE;
      this.http.post(url, complete_data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async uploadLargePart(uploadPromiseResult, token) {
    var data = {
      upload_data: uploadPromiseResult.upload_data,
      upload_key: uploadPromiseResult.upload_key,
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.UPLOAD_LARGE_FILE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async uploadFilePhp(base64Data, mime_type, file_type, token) {
    let complete_data = {
      base64Data: base64Data,
      mime_type: file_type,
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = 'http://127.0.0.1/cissp-chime/upload.php';
      this.http.post(url, complete_data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async getAllHybridMaterials(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.HYBRID_ALL_MATERIALS;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getSelfPacedSummary(
    cohort_id: number,
    userslist: string,
    knowassessments: string,
    practicetests: string,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: cohort_id,
          userslist: userslist,
          knowassessments: knowassessments,
          practicetests: practicetests,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_SUMMARY;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllCohortForStudent(
    page: number,
    limit: number,
    search: string,
    token: string,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          page: page,
          limit: limit,
          search: search,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SELF_SUMM_USER;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
