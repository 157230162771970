<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Gamification</h3>
    </div>
  </div>
  <div class="component-header">
    <div class="components">
      <span>Component</span>
    </div>
    <div class="actions">
      <span>Action</span>
    </div>
    <div class="points">
      <span>Points</span>
    </div>
  </div>
  <div class="component-body">
    <div class="components">
      <span>Flashcard App</span>
    </div>
    <div class="actions">
      <span>Mark card as Know It</span>
      <span>Mark card as Review</span>
      <span>Bookmark Card</span>
      <span>Add notes to card</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input type="number" [(ngModel)]="know" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="review" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="bookmark" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="notes" />
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Practice question</span>
    </div>
    <div class="actions">
      <span>Answered incorrectly</span>
      <span>Answered correctly</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input type="number" [(ngModel)]="pq_w" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="pq_r" />
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Knowledge assessment question</span>
    </div>
    <div class="actions">
      <span>Answered incorrectly</span>
      <span>Answered correctly</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input type="number" [(ngModel)]="ka_w" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="ka_r" />
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Practice Exam question</span>
    </div>
    <div class="actions">
      <span>Answered incorrectly</span>
      <span>Answered correctly</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input type="number" [(ngModel)]="pt_w" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="pt_r" />
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>CAT exam question</span>
    </div>
    <div class="actions">
      <span>Answered incorrectly</span>
      <span>Answered correctly</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input type="number" [(ngModel)]="cat_w" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="cat_r" />
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Live questions</span>
    </div>
    <div class="actions">
      <span>Answered incorrectly</span>
      <span>Answered correctly</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input type="number" [(ngModel)]="live_w" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="live_r" />
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Study Material</span>
    </div>
    <div class="actions">
      <span>Watch a video</span>
      <span>View a MindMap</span>
      <span>View References</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input type="number" [(ngModel)]="video_watch" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="mindmap_view" />
      </div>
      <div class="all-points">
        <input type="number" [(ngModel)]="reference_view" />
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
