import { Component, OnInit, ViewChild } from '@angular/core';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from '../../service/authorization.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { AdminService } from '../../services/admin/admin.service';
import { WebsocketService } from '../../services/websocket/websocket.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  totalStudent = 0;
  totalTeacher = 0;
  totalClients: number = 0;
  course = 0;
  valueset: any;

  constructor(
    public socket: WebsocketService,
    public adminService: AdminService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  async ngOnInit() {
    await this.spinner.show();
    await this.countdash();
    await this.spinner.hide();
  }
  async countdash() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.adminService.countdash(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);

            this.totalStudent = list.data.TotalStudents;
            this.totalTeacher = list.data.TotalTeachers;
            this.course = list.data.TotalCourse;
            this.totalClients = list.data.TotalClients;
          }
        });
      }
    });
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = jsonData;
    };
    reader.readAsBinaryString(file);
  }
}
