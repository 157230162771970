<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Revision Materials</h3>
    </div>
  </div>
  <div class="component-header">
    <div class="components">
      <span>Component</span>
    </div>
    <div class="points">
      <span>Seconds</span>
    </div>
  </div>
  <div class="component-body">
    <div class="components">
      <span>Flashcard App</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input
          type="number"
          [(ngModel)]="flashcard_app"
          placeholder="Seconds"
        />
        <span> /One Flashcard</span>
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Flashcard App Revision</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input
          type="number"
          [(ngModel)]="flashcard_app_revision"
          placeholder="Seconds"
        /><span> /One Flashcard</span>
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Practice Question App</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input
          type="number"
          [(ngModel)]="practice_que_app"
          placeholder="Seconds"
        /><span>/One Question</span>
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Practice Question App Revision</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input
          type="number"
          [(ngModel)]="practice_que_app_revision"
          placeholder="Seconds"
        /><span>/One Question</span>
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Knowledge Assessment Revision</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input
          type="number"
          [(ngModel)]="know_ass_revision"
          placeholder="Seconds"
        /><span>/One Question</span>
      </div>
    </div>
  </div>

  <div class="component-body">
    <div class="components">
      <span>Practice Test Revision</span>
    </div>
    <div class="points">
      <div class="all-points">
        <input
          type="number"
          [(ngModel)]="practice_test_revision"
          placeholder="Seconds"
        /><span>/One Question</span>
      </div>
    </div>
  </div>
  <div class="main-pagemain">
    <div class="search-wrap">
      <button (click)="saveComponentTime()">Save</button>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
