import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class LiveService {
  token: any;
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  async livetestadd(test_name, cohort_ids, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          test_name: test_name,
          cohort_ids: cohort_ids,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_TEST_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async livetestList(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_TEST_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }
  async livetestupdate(id, test_name, cohort_ids) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          test_name: test_name,
          cohort_ids: cohort_ids,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_TEST_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async livetestdelete(id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_TEST_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async SendStudentLinkLiveq(CohortIds, test_id, q_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          CohortIds: CohortIds,
          test_id: test_id,
          q_id: q_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_SEND_LINK;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async AddLiveQuestion(
    lt_id,
    question,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
    description,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          lt_id: lt_id,
          question: question,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
          description: description,
          ref_link: '',
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_QUES_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async LiveTestWiseQuestionList(id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_QUES_LIST;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateLiveQuestion(
    id,
    question,
    first_choice,
    second_choice,
    third_choice,
    fourth_choice,
    answer,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          question: question,
          first_choice: first_choice,
          second_choice: second_choice,
          third_choice: third_choice,
          fourth_choice: fourth_choice,
          answer: answer,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_QUES_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteLiveQuestion(id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_QUES_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetLiveTestResult(TestId, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: TestId,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_TEST_REPORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
