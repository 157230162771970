import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../../service/authorization.service';
import { async } from '@angular/core/testing';
import { AcronymsService } from '../../../services/acronyms/acronyms.service';
import { CourseService } from '../../../services/course/course.service';
import { PracticeService } from '../../../services/practice/practice.service';

@Component({
  selector: 'app-practicetestsque',
  templateUrl: './practicetestsque.component.html',
  styleUrls: ['./practicetestsque.component.scss'],
})
export class PracticetestsqueComponent implements OnInit {
  id: any;
  EditHideen = true;
  PracticeTestname: any;
  public PracticeTestForm: FormGroup;
  ListCoursedata = [];
  Certificate: any;
  Certificatelist = [];
  CourseName: any;
  selecteds = [];
  acrolist = [];
  acroselecteds = [];
  hours = '00';
  Minutes = '00';
  hourslist = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ];
  Minuteslist = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
  ];
  Type = 1;
  constructor(
    public practiceService: PracticeService,
    public courseService: CourseService,
    public AcronymsService: AcronymsService,
    public auth: AuthorizationService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    } else {
      this.ActiveRoute.params.subscribe(async (params) => {
        try {
          this.id = JSON.parse(atob(params['id']));
          this.EditHideen = false;
        } catch (error) {
          this.router.navigate(['/main/Practicetest/createpractice']);
        }
      });
    }
  }

  async ngOnInit() {
    this.PracticeTestForm = this.fb.group({
      PracticeTestname: ['', Validators.required],
      Certificate: ['', Validators.required],
      CourseName: ['', Validators.required],
      Acronymsname: ['', Validators.required],
      hours: ['', Validators.required],
      Minutes: ['', Validators.required],
      Type: ['', Validators.required],
    });
    await this.spinner.show();
    await this.ListCourse();
    await this.certificatelist();
    await this.Acroymslist();
    await this.spinner.hide();
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.id = JSON.parse(atob(params['id']));
        if (this.id != null && this.id != undefined && this.id != '') {
          await this.EditRecord();
        }
      } catch (error) {
        this.PracticeTestname = '';

        await this.spinner.hide();
      }
    });
  }

  async onSubmit() {
    if (parseInt(this.hours) > 0 || parseInt(this.Minutes) > 0) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();

            var time = this.hours + ':' + this.Minutes + ':00';
            await this.practiceService
              .AddPracticeTest(
                this.PracticeTestname,
                this.selecteds.toString(),
                this.Certificate,
                token,
                this.acroselecteds.toString(),
                time,
                this.Type,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.PracticeTestname +
                          '</span> Practice Test is added by <span> ' +
                          e +
                          '</span>';

                        await this.service.addAdminLogs(
                          desc,
                          'practicetestques',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  await this.spinner.hide();
                  this.service.ShowSuccess(list.message);
                  this.router.navigate(['main/Practicetest']);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    } else {
      this.service.ShowError('Please Valid time format.');
    }
  }
  async EditRecord() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.practiceService
          .IdPracticeTest(this.id, token)
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.PracticeTestname = list.data.practice_name;
              this.Certificate = list.data.certi_id;
              this.selecteds = list.data.course_id.map(Number);
              this.acroselecteds = list.data.acronum_ids.map(Number);
              if (list.data.type == 'LINEAR') {
                this.Type = 1;
              } else if (list.data.type == 'ADAPTIVE') {
                this.Type = 2;
              }

              if (
                list.data.time != undefined &&
                list.data.time != null &&
                list.data.time != ''
              ) {
                this.hours = list.data.time.split(':')[0];
                this.Minutes = list.data.time.split(':')[1];
              } else {
                this.hours = '00';
                this.Minutes = '00';
              }
            }
          });
      }
    });
  }
  async Update() {
    if (parseInt(this.hours) > 0 || parseInt(this.Minutes) > 0) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            var time = this.hours + ':' + this.Minutes + ':00';

            await this.practiceService
              .UpdatePracticeTest(
                this.PracticeTestname,
                this.id,
                this.selecteds.toString(),
                this.Certificate,
                token,
                this.acroselecteds.toString(),
                time,
                this.Type,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.PracticeTestname +
                          '</span> Practice Test is update by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'practicetestques',
                          this.id,
                          token,
                        );
                      }
                    });
                  await this.spinner.hide();
                  this.service.ShowSuccess(list.message);
                  this.router.navigate(['main/Practicetest']);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    } else {
      this.service.ShowError('Please enter valid time format');
    }
  }

  async ListCourse() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.courseService.ListCourses(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCoursedata = list.data;
          }
        });
      }
    });
  }

  async certificatelist() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          this.Certificatelist = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data;
          }
        });
      }
    });
  }

  async Acroymslist() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();

        await this.AcronymsService.Listacronyms(token).then(async (data) => {
          this.acrolist = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.acrolist = list.data;
          }
        });
      }
    });
  }
}
