import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}
  checkLogin() {
    this.router.navigate(['/userinfo']);
  }
}
