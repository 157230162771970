<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Sub Domains</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Domain, Sub-domain, Certificate..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewSubDomain()">Add Sub-domain</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="domain_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub-domain #
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.domain_number }}">{{
              element.domain_number
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub-domain Name
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.subdomain_name }}">{{
              element.subdomain_name
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="d_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain Name</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.domain_name }}">{{
              element.domain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addSubDomain">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Sub-domain</p>
      <p *ngIf="IsUpdate">Edit Sub-domain</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="SubDomainForm">
          <div class="formwrapper">
            <div>
              <div class="select-box">
                <select
                  (change)="onBookChange($event)"
                  [(ngModel)]="Certificate"
                  formControlName="Certificate"
                  required
                  [ngClass]="{
                    err:
                      SubDomainForm.controls['Certificate'].hasError(
                        'required'
                      ) &&
                      (SubDomainForm.controls['Certificate'].dirty ||
                        SubDomainForm.controls['Certificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="w-100">
              <div class="select-box">
                <select
                  [attr.disabled]="this.Certificate == '' ? true : null"
                  [(ngModel)]="Domainname"
                  formControlName="DomainName"
                  required
                  [ngClass]="{
                    err:
                      SubDomainForm.controls['DomainName'].hasError(
                        'required'
                      ) &&
                      (SubDomainForm.controls['DomainName'].dirty ||
                        SubDomainForm.controls['DomainName'].touched)
                  }"
                >
                  <option value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="w-20">
                <input
                  formControlName="DomainNumber"
                  [(ngModel)]="DomainNumber"
                  placeholder="Sub-domain #"
                  required
                  [ngClass]="{
                    err:
                      SubDomainForm.controls['DomainNumber'].hasError(
                        'required'
                      ) &&
                      (SubDomainForm.controls['DomainNumber'].dirty ||
                        SubDomainForm.controls['DomainNumber'].touched)
                  }"
                />
              </div>
              <div class="w-78">
                <input
                  [attr.disabled]="this.Certificate == '' ? true : null"
                  [(ngModel)]="SubDomain"
                  formControlName="SubDomainName"
                  placeholder="Sub Domain Name"
                  name="name"
                  required
                  [ngClass]="{
                    err:
                      SubDomainForm.controls['SubDomainName'].hasError(
                        'required'
                      ) &&
                      (SubDomainForm.controls['SubDomainName'].dirty ||
                        SubDomainForm.controls['SubDomainName'].touched)
                  }"
                />
              </div>
            </div>
            <div class="w-100">
              <input
                [attr.disabled]="this.Certificate == '' ? true : null"
                [(ngModel)]="no_assign_note"
                formControlName="no_assign_note"
                placeholder="Material no assign note"
                name="name"
              />
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="Update()">Save</button>
      </div>
    </div>
  </div>
</div>
