<div class="pagemain">
  <div class="pagetitle">
    <h3>Add New Practice Questions</h3>
  </div>
  <div>
    <form class="example-form" [formGroup]="PraacticeQueForm">
      <div class="formwrapper">
        <div>
          <label>Course</label>
          <div class="select-box">
            <select
              (change)="Coursechange($event)"
              (keyup)="Coursechange($event)"
              (keydown)="Coursechange($event)"
              [(ngModel)]="CourseName"
              formControlName="CourseName"
              required
            >
              <option value="">Select</option>
              <option *ngFor="let item of ListCoursedata" value="{{ item.id }}">
                {{ item.course_name }}
              </option>
            </select>
            <span></span>
          </div>
        </div>
        <div>
          <label>Certification</label>
          <div class="select-box">
            <select
              (change)="certificatechange($event)"
              (keyup)="certificatechange($event)"
              (keydown)="certificatechange($event)"
              [(ngModel)]="Certificate"
              formControlName="Certificate"
              required
            >
              <option value="">Select</option>
              <option
                *ngFor="let item of ListCerficateData"
                value="{{ item.id }}"
              >
                {{ item.certificate_name }}
              </option>
            </select>
            <span></span>
          </div>
        </div>
        <div>
          <label>Free/Paid</label>
          <div class="select-box">
            <select
              [(ngModel)]="Free_paid"
              formControlName="Free_paid"
              required
            >
              <option value="">Select</option>
              <option value="free">Free</option>
              <option value="paid">Paid</option>
            </select>
            <span></span>
          </div>
        </div>
        <div>
          <label>Domain Name</label>
          <div class="select-box">
            <select
              [(ngModel)]="Domainname"
              (keyup)="domainchange($event)"
              (keydown)="domainchange($event)"
              (change)="domainchange($event)"
              formControlName="Domainname"
              required
            >
              <option selected="true" value="">Select</option>
              <option *ngFor="let item of Domain" value="{{ item.id }}">
                {{ item.domin_name }}
              </option>
            </select>
            <span></span>
          </div>
        </div>

        <div>
          <label>Sub Domain Name</label>
          <div class="select-box">
            <select
              [(ngModel)]="subdomainname"
              (keyup)="SubDomainchange($event)"
              (keydown)="SubDomainchange($event)"
              (change)="SubDomainchange($event)"
              formControlName="subdomainname"
              required
            >
              <option selected="true" value="">Select</option>
              <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                {{ item.subdomain_name }}
              </option>
            </select>
            <span></span>
          </div>
        </div>

        <div>
          <label>Topic Name</label>
          <div class="select-box">
            <select
              [(ngModel)]="Topicname"
              formControlName="Topicname"
              [(ngModel)]="Topicname"
              formControlName="Topicname"
              required
            >
              <option selected="true" value="">Select</option>
              <option *ngFor="let item of Topic" value="{{ item.id }}">
                {{ item.topic_name }}
              </option>
            </select>
            <span></span>
          </div>
        </div>
        <div>
          <label>Title</label>
          <textarea
            [(ngModel)]="title"
            formControlName="title"
            placeholder="Title"
            name="search"
          ></textarea>
        </div>

        <div>
          <label>Option 1</label>
          <input
            [(ngModel)]="option1"
            formControlName="option1"
            placeholder="Term"
            name="name"
          />
        </div>

        <div>
          <label>Option 2</label>
          <input
            [(ngModel)]="option2"
            formControlName="option2"
            placeholder="Term"
            name="name"
          />
        </div>

        <div>
          <label>Option 3</label>
          <input
            [(ngModel)]="option3"
            formControlName="option3"
            placeholder="Term"
            name="name"
          />
        </div>
        <div>
          <label>Option 4</label>
          <input
            [(ngModel)]="option4"
            formControlName="option4"
            placeholder="Term"
            name="name"
          />
        </div>

        <div>
          <label>Correct Answer</label>
          <div class="select-box">
            <select
              [(ngModel)]="CorrectAnswer"
              formControlName="CorrectAnswer"
              required
            >
              <option value="">Select</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
              <option value="4">Option 4</option>
            </select>
            <span></span>
          </div>
        </div>

        <div>
          <label>Type</label>
          <div class="select-box">
            <select [(ngModel)]="Type" formControlName="Type" required>
              <option selected="true" value="">Select</option>
              <option value="VERY SIMPLE">VERY SIMPLE</option>
              <option value="SIMPLE">SIMPLE</option>
              <option value="AVERAGE">AVERAGE</option>
              <option value="ABOVE AVERAGE">ABOVE AVERAGE</option>
              <option value="HARD">HARD</option>
              <option value="DIFFICULT">DIFFICULT</option>
              <option value="TRICKY">TRICKY</option>
            </select>
            <span></span>
          </div>
        </div>
        <div>
          <label>Explanation</label>
          <textarea
            [(ngModel)]="Explanation"
            formControlName="Explanation"
            placeholder="Explanation"
            name="search"
          ></textarea>
        </div>
      </div>
      <div [hidden]="!EditHideen">
        <button
          [disabled]="!PraacticeQueForm.valid"
          type="button"
          class="submit-btn"
          (click)="onSubmit()"
        >
          Submit
        </button>
      </div>
      <div [hidden]="EditHideen">
        <button
          [disabled]="!PraacticeQueForm.valid"
          type="button"
          class="submit-btn"
          (click)="UpdatePracticeQue()"
        >
          Update
        </button>
      </div>
    </form>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
