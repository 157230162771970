<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Course Templates</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Course Template Name, Certification..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewTemplate()">Add Course Template</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="Srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.Srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="templateName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.templateName }}">{{
              element.templateName
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<div class="cutome-modal" *ngIf="PopUpshow">
  <div class="cutome-modal-content">
    <div class="cutome-container">
      <div class="modal-header">
        <p>Client List</p>
        <span class="material-icons" (click)="PopUpshow = false">close</span>
      </div>
      <div class="modal-body">
        <div class="cohortwrap" *ngFor="let item of ClientCohort">
          <div class="cohortname">
            {{ item.cohort_name }}
          </div>
          <div class="list-sub">
            <div class="sub-name" *ngFor="let itm of item.practice_time[0]">
              <span>{{ itm.email }}</span>
            </div>
            <div class="sub-name" *ngIf="item.practice_time[0].length <= 0">
              <span>
                <button
                  type="button"
                  mat-button
                  color="primary"
                  routerLink="/main/clientcohort/createclientcohort"
                >
                  Add Client
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="submit-btn" (click)="PopUpshow = false">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addTemplate">
  <div class="cutome-modal-content course-template">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Course Template</p>
      <p *ngIf="IsUpdate">Edit Course Template</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div class="body-div">
        <div class="course-name">
          <div class="course-input">
            <input
              type="text"
              [(ngModel)]="template_name"
              placeholder="Course Template Name"
            />
          </div>

          <div class="course-input">
            <select
              [(ngModel)]="SelectedCertificate"
              (change)="CertificateChange($event)"
            >
              <option value="">Select Certification</option>
              <option *ngFor="let item of Certificates" value="{{ item.id }}">
                {{ item.certificate_name }}
              </option>
            </select>
          </div>
          <div class="chkboxs">
            <div class="check-app">
              <label class="container">
                Videos
                <input type="checkbox" [(ngModel)]="VideoAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Mindmaps
                <input type="checkbox" [(ngModel)]="MindmapAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Summaries
                <input type="checkbox" [(ngModel)]="SummaryAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                References
                <input type="checkbox" [(ngModel)]="ReferenceAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Knowledge Assessments
                <input type="checkbox" [(ngModel)]="KAAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Tests
                <input type="checkbox" [(ngModel)]="PTAccess" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-app">
              <label class="container">
                CAT Exams (free per attempt)
                <input type="checkbox" [(ngModel)]="CATAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Chat
                <input type="checkbox" [(ngModel)]="LiveChatAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Questions
                <input type="checkbox" [(ngModel)]="LiveQuestionAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Live Events
                <input type="checkbox" [(ngModel)]="LiveEventAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Flashcard App (full access)
                <input type="checkbox" [(ngModel)]="FashFullAccess" />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Practice Question App (full access)
                <input type="checkbox" [(ngModel)]="PraAppFullAccess" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="add-new-event">
          <div class="events">
            <span>Live Events</span>
          </div>
          <!-- <span class="main-title" for="Name">Live Events</span> -->
          <ng-select
            [items]="LiveEvents"
            bindLabel="event_name"
            bindValue="id"
            placeholder="Select Live Events"
            appendTo="body"
            multiple="true"
            [(ngModel)]="SelectedEvents"
          >
          </ng-select>
        </div>
        <div class="add-new-event">
          <div class="events">
            <!-- <span>Events</span> -->
            <span>Class Times</span>
            <!-- <button (click)="addEvents()">Add Event</button> -->
            <button (click)="addEvents()">Add Class Time</button>
          </div>
          <div class="transcript-div" *ngFor="let item of Events">
            <div class="main-transcript">
              <div class="all-event">
                <div class="event-name">
                  <input
                    type="text"
                    placeholder="Event Name"
                    [(ngModel)]="item.event_name"
                  />
                </div>
                <div class="event-number">
                  <input
                    type="number"
                    min="1"
                    max="31"
                    [(ngModel)]="item.event_date"
                  />
                </div>
                <div class="event-time">
                  <input type="text" [(ngModel)]="item.event_time" />
                </div>
                <div class="event-time min">
                  <input type="number" [(ngModel)]="item.minutes" />
                  <span>MIN</span>
                </div>
              </div>
              <div class="all-event m-0">
                <input type="text" placeholder="URL" [(ngModel)]="item.url" />
              </div>
              <div class="transcript-delete" (click)="deleteEvents(item)">
                <!-- <span class="material-icons"> delete </span> -->
                <img
                  src="../../../assets/images/remove.png"
                  alt="Delete"
                  class="action-icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="knowledge-ass">
          <div class="title_filter">
            <span class="main-title">Video</span>
            <div class="course-input">
              <select
                name="filtervideo"
                id="filtervideo"
                (change)="videoChange($event)"
              >
                <option value="name">Name</option>
                <option value="date">Date & Time</option>
              </select>
            </div>
          </div>
          <div class="cissp-days" *ngFor="let item of Videos">
            <div class="cissp-assigned">
              <span class="first-title">{{ item.video_title }}</span>
              <div>
                <div class="check-app">
                  <label class="container">
                    Assigned
                    <input type="checkbox" [(ngModel)]="item.Assigned" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="check-app">
                  <label class="container">
                    Required
                    <input type="checkbox" [(ngModel)]="item.Required" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="check-app check-app2"
                  [ngClass]="{ opacity: item.Assigned }"
                >
                  <label class="container">
                    Homework
                    <input
                      type="checkbox"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Homework"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="overdue">
              <div class="over">
                <div class="due" [ngClass]="{ opacity: item.Assigned }">
                  <label for="Due">Due:</label>
                  <input
                    type="number"
                    (keyup)="checkIsDueNumber(item)"
                    [disabled]="!item.Assigned"
                    [(ngModel)]="item.DueNumber"
                  />
                  <select
                    [(ngModel)]="item.DueTime"
                    [disabled]="!item.Assigned"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">Overdue:</label>
                  <input
                    type="number"
                    (keyup)="checkIsOverDueNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.OverDueNumber"
                  />
                  <select
                    [(ngModel)]="item.OverDueTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due due2" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">End Date:</label>
                  <input
                    type="number"
                    (keyup)="checkIsEndNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.EndNumber"
                  />
                  <select
                    [(ngModel)]="item.EndTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="cissp-days" *ngIf="Videos.length == 0">
            <div class="cissp-assigned">
              <span class="first-title">NONE</span>
            </div>
          </div>
          <div class="title_filter">
            <span class="main-title">Mind Map</span>
            <div class="course-input">
              <select
                name="filtermindmap"
                id="filtermindmap"
                (change)="mindmapChange($event)"
              >
                <option value="name">Name</option>
                <option value="date">Date & Time</option>
              </select>
            </div>
          </div>
          <!-- <span class="main-title">Mind Map</span> -->
          <div class="cissp-days" *ngFor="let item of Mindmap">
            <div class="cissp-assigned">
              <span class="first-title">{{ item.name }}</span>
              <div>
                <div class="check-app">
                  <label class="container">
                    Assigned
                    <input type="checkbox" [(ngModel)]="item.Assigned" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="check-app">
                  <label class="container">
                    Required
                    <input type="checkbox" [(ngModel)]="item.Required" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="check-app check-app2"
                  [ngClass]="{ opacity: item.Assigned }"
                >
                  <label class="container">
                    Homework
                    <input
                      type="checkbox"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Homework"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="overdue">
              <div class="over">
                <div class="due" [ngClass]="{ opacity: item.Assigned }">
                  <label for="Due">Due:</label>
                  <input
                    type="number"
                    (keyup)="checkIsDueNumber(item)"
                    [disabled]="!item.Assigned"
                    [(ngModel)]="item.DueNumber"
                  />
                  <select
                    [(ngModel)]="item.DueTime"
                    [disabled]="!item.Assigned"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">Overdue:</label>
                  <input
                    type="number"
                    (keyup)="checkIsOverDueNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.OverDueNumber"
                  />
                  <select
                    [(ngModel)]="item.OverDueTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due due2" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">End Date:</label>
                  <input
                    type="number"
                    (keyup)="checkIsEndNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.EndNumber"
                  />
                  <select
                    [(ngModel)]="item.EndTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="cissp-days" *ngIf="Mindmap.length == 0">
            <div class="cissp-assigned">
              <span class="first-title">NONE</span>
            </div>
          </div>
          <div class="title_filter">
            <span class="main-title">Knowledge Assessments</span>
            <div class="course-input">
              <select
                name="filterknowledge"
                id="filterknowledge"
                (change)="knowledgeChange($event)"
              >
                <option value="name">Name</option>
                <option value="date">Date & Time</option>
              </select>
            </div>
          </div>
          <!-- <span class="main-title">Knowledge Assessments</span> -->
          <div class="course-input">
            <span class="main-title">Baseline Knowledge Assessment</span>
            <select [(ngModel)]="SelectedBaselineKA">
              <option value="" style="display: none">
                Select Baseline Knowledge Assessment
              </option>
              <option *ngFor="let item of baselineka" value="{{ item.id }}">
                {{ item.knowass_name }}
              </option>
            </select>
          </div>
          <div class="course-input">
            <div class="baselinedue">
              <label for="Due">Due:</label>
              <input type="number" [(ngModel)]="SelectedBaselineKAdueDate" />
              <label for="Due">Time:</label>
              <select [(ngModel)]="SelectedBaselineKAdueTime">
                <option
                  *ngFor="let itm of Hours; let i = index"
                  value="{{ itm }}:00:00"
                >
                  {{ itm }}
                </option>
              </select>
              <label for="Due">Overdue:</label>
              <input
                type="number"
                [(ngModel)]="SelectedBaselineKAOverdueDate"
              />
              <label for="Due">Time:</label>
              <select [(ngModel)]="SelectedBaselineKAOverdueTime">
                <option
                  *ngFor="let itm of Hours; let i = index"
                  value="{{ itm }}:00:00"
                >
                  {{ itm }}
                </option>
              </select>
              <label for="End">End:</label>
              <input type="number" [(ngModel)]="SelectedBaselineKAEndDate" />
              <label for="End">Time:</label>
              <select [(ngModel)]="SelectedBaselineKAEndTime">
                <option
                  *ngFor="let itm of Hours; let i = index"
                  value="{{ itm }}:00:00"
                >
                  {{ itm }}
                </option>
              </select>
            </div>
          </div>
          <div class="cissp-days" *ngFor="let item of KnowledgeAssessments">
            <div class="cissp-assigned">
              <span class="first-title">{{ item.knowass_name }}</span>
              <div *ngIf="item.Count > 0">
                <div class="check-app">
                  <label class="container">
                    Assigned
                    <input type="checkbox" [(ngModel)]="item.Assigned" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="check-app">
                  <label class="container">
                    Required
                    <input type="checkbox" [(ngModel)]="item.Required" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="check-app check-app2"
                  [ngClass]="{ opacity: item.Assigned }"
                >
                  <label class="container">
                    Homework
                    <input
                      type="checkbox"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Homework"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="overdue" *ngIf="item.Count > 0">
              <div class="over">
                <div class="due" [ngClass]="{ opacity: item.Assigned }">
                  <label for="Due">Due:</label>
                  <input
                    type="number"
                    (keyup)="checkIsDueNumber(item)"
                    [disabled]="!item.Assigned"
                    [(ngModel)]="item.DueNumber"
                  />
                  <select
                    [(ngModel)]="item.DueTime"
                    [disabled]="!item.Assigned"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">Overdue:</label>
                  <input
                    type="number"
                    (keyup)="checkIsOverDueNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.OverDueNumber"
                  />
                  <select
                    [(ngModel)]="item.OverDueTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due due2" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">End Date:</label>
                  <input
                    type="number"
                    (keyup)="checkIsEndNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.EndNumber"
                  />
                  <select
                    [(ngModel)]="item.EndTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="!item.Count" class="noque">
              <span>There is no questions in this test</span>
            </div>
          </div>
          <div class="cissp-days" *ngIf="KnowledgeAssessments.length == 0">
            <div class="cissp-assigned">
              <span class="first-title">NONE</span>
            </div>
          </div>
          <div class="title_filter">
            <span class="main-title">Practice Tests</span>
            <div class="course-input">
              <select
                name="filterpractice"
                id="filterpractice"
                (change)="practiceChange($event)"
              >
                <option value="name">Name</option>
                <option value="date">Date & Time</option>
              </select>
            </div>
          </div>
          <!-- <span class="main-title">Practice Tests</span> -->
          <div class="cissp-days" *ngFor="let item of PracticeExams">
            <div class="cissp-assigned">
              <span class="first-title">{{ item.practice_name }}</span>
              <div *ngIf="item.Count > 0">
                <div class="check-app">
                  <label class="container">
                    Assigned
                    <input type="checkbox" [(ngModel)]="item.Assigned" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="check-app">
                  <label class="container">
                    Required
                    <input type="checkbox" [(ngModel)]="item.Required" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="check-app check-app2"
                  [ngClass]="{ opacity: item.Assigned }"
                >
                  <label class="container">
                    Homework
                    <input
                      type="checkbox"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Homework"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="overdue" *ngIf="item.Count > 0">
              <div class="over">
                <div class="due" [ngClass]="{ opacity: item.Assigned }">
                  <label for="Due">Due:</label>
                  <input
                    type="number"
                    (keyup)="checkIsDueNumber(item)"
                    [disabled]="!item.Assigned"
                    [(ngModel)]="item.DueNumber"
                  />
                  <select
                    [(ngModel)]="item.DueTime"
                    [disabled]="!item.Assigned"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">Overdue:</label>
                  <input
                    type="number"
                    (keyup)="checkIsOverDueNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.OverDueNumber"
                  />
                  <select
                    [(ngModel)]="item.OverDueTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due due2" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">End Date:</label>
                  <input
                    type="number"
                    (keyup)="checkIsEndNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.EndNumber"
                  />
                  <select
                    [(ngModel)]="item.EndTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="!item.Count" class="noque">
              <span>There is no questions in this test</span>
            </div>
          </div>
          <div class="cissp-days" *ngIf="PracticeExams.length == 0">
            <div class="cissp-assigned">
              <span class="first-title">NONE</span>
            </div>
          </div>
          <div class="title_filter">
            <span class="main-title">CAT Exam</span>
            <div class="course-input">
              <select
                name="filtercat"
                id="filtercat"
                (change)="catChange($event)"
              >
                <option value="name">Name</option>
                <option value="date">Date & Time</option>
              </select>
            </div>
          </div>
          <!-- <span class="main-title">CAT Exam</span> -->
          <div class="cissp-days" *ngFor="let item of CatExams">
            <div class="cissp-assigned">
              <span class="first-title">{{ item.ketexam_name }}</span>
              <div *ngIf="item.Count > 0">
                <div class="check-app">
                  <label class="container">
                    Assigned
                    <input type="checkbox" [(ngModel)]="item.Assigned" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="check-app">
                  <label class="container">
                    Required
                    <input type="checkbox" [(ngModel)]="item.Required" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="check-app check-app2"
                  [ngClass]="{ opacity: item.Assigned }"
                >
                  <label class="container">
                    Homework
                    <input
                      type="checkbox"
                      [disabled]="!item.Assigned"
                      [(ngModel)]="item.Homework"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="overdue" *ngIf="item.Count > 0">
              <div class="over">
                <div class="due" [ngClass]="{ opacity: item.Assigned }">
                  <label for="Due">Due:</label>
                  <input
                    type="number"
                    (keyup)="checkIsDueNumber(item)"
                    [disabled]="!item.Assigned"
                    [(ngModel)]="item.DueNumber"
                  />
                  <select
                    [(ngModel)]="item.DueTime"
                    [disabled]="!item.Assigned"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">Overdue:</label>
                  <input
                    type="number"
                    (keyup)="checkIsOverDueNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.OverDueNumber"
                  />
                  <select
                    [(ngModel)]="item.OverDueTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due due2" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">End Date:</label>
                  <input
                    type="number"
                    (keyup)="checkIsEndNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.EndNumber"
                  />
                  <select
                    [(ngModel)]="item.EndTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="!item.Count" class="noque">
              <span>There is no questions in this test</span>
            </div>
          </div>
          <div class="cissp-days" *ngIf="CatExams.length == 0">
            <div class="cissp-assigned">
              <span class="first-title">NONE</span>
            </div>
          </div>
          <div class="title_filter">
            <span class="main-title">Email Notifications</span>
            <div class="course-input">
              <select
                name="filteremail"
                id="filteremail"
                (change)="emailChange($event)"
              >
                <option value="name">Name</option>
                <option value="date">Date & Time</option>
              </select>
            </div>
          </div>

          <div class="cissp-days" *ngFor="let item of EmailTemplates">
            <div class="cissp-assigned">
              <span class="first-title">{{ item.name }}</span>
              <div>
                <div class="check-app">
                  <label class="container">
                    Assigned
                    <input type="checkbox" [(ngModel)]="item.Assigned" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="check-app check-app2">
                  <label class="container">
                    Required
                    <input type="checkbox" [(ngModel)]="item.Required" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="overdue">
              <div class="over">
                <div class="due" [ngClass]="{ opacity: item.Assigned }">
                  <label for="Due">Due:</label>
                  <input
                    type="number"
                    (focusout)="checkvaluedDue(item)"
                    [disabled]="!item.Assigned"
                    [(ngModel)]="item.DueNumber"
                  />
                  <select
                    [(ngModel)]="item.DueTime"
                    [disabled]="!item.Assigned"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due due2" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">Overdue:</label>
                  <input
                    type="number"
                    (focusout)="checkvalueOverDue(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.OverDueNumber"
                  />
                  <select
                    [(ngModel)]="item.OverDueTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
                <div class="due due2" [ngClass]="{ opacity: item.Required }">
                  <label for="Due">End Date:</label>
                  <input
                    type="number"
                    (keyup)="checkIsEndNumber(item)"
                    [disabled]="!item.Required"
                    [(ngModel)]="item.EndNumber"
                  />
                  <select
                    [(ngModel)]="item.EndTime"
                    [disabled]="!item.Required"
                  >
                    <option
                      *ngFor="let itm of Hours; let i = index"
                      value="{{ itm }}:00:00"
                    >
                      {{ itm }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="cissp-days" *ngIf="EmailTemplates.length == 0">
            <div class="cissp-assigned">
              <span class="first-title">NONE</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button type="button" class="submit-btn" (click)="saveTemplates()">
          Save
        </button>
      </div>
      <div *ngIf="IsUpdate">
        <button type="button" class="submit-btn" (click)="updateTemplates()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
