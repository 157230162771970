import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class ShorturlService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  //start domain
  async AddShortUrl(ShortUrlData, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(ShortUrlData)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADD_SHORTURL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateShortUrl(ShortUrlData, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(ShortUrlData)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SHORTURL_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteShortUrl(id, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify({ id: id })),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SHORTURL_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListShortUrl(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GETALL_SHORTURL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(resolve([{ status: err.status, json: err['_body'] }]));
          console.log(err);
        },
      );
    });
  }
  //End doamin
}
