import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { BooksService } from '../../services/books/books.service';
import { ReferenceService } from '../../services/reference/reference.service';
import { Location } from '@angular/common';
import * as XLSX from 'xlsx';
import { ExcelService } from 'src/app/services/excelservices/excel.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
})
export class ReferencesComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'domin_name',
    'subdomain_name',
    'topic_name',
    'ref_type',
    'title',
    'actions',
  ];
  dataSource: MatTableDataSource<videolist>;
  Search: any;
  TranscriptPopup: boolean = false;
  token: any;

  public VideoLessonForm: FormGroup;
  IsUpdate: boolean = false;
  id: any;
  Domain = [];
  SubDomain = [];
  Topic = [];
  Certificates = [];
  SelectedSubDomain: any;
  SelectedTopics: any;
  SelectedCertificate: any;
  SelectedDomain: any;
  WebLink: any;
  Title: any;
  ref_type: any;

  filterDomain = [];
  filtersubDomain = [];
  filterTpoic = [];
  Books = [];
  SelectedBook: any;
  PageNumber: any;

  loglist = [];
  editFromBook: boolean = false;
  isBulkUpload: boolean = false;
  files: File[] = [];
  myEvent: any;
  progress: any = 0;
  selectBulkupload: boolean = false;
  selectprogress: boolean = false;
  oldAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    'Reference Type': '',
    WebLink: '',
    'Book Name': '',
    'Page Number': '',
    'Reference Name': '',
  };
  newAuditValueArray = {
    Certificate: '',
    Domain: '',
    Subdomain: '',
    Topic: '',
    'Reference Type': '',
    WebLink: '',
    'Book Name': '',
    'Page Number': '',
    'Reference Name': '',
  };
  auditHistory = '';
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private location: Location,
    public referenceService: ReferenceService,
    public booksService: BooksService,
    public fb: FormBuilder,
    public ActiveRoute: ActivatedRoute,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    private excelService: ExcelService,
  ) {
    this.VideoLessonForm = this.fb.group({
      SelectedSubDomain: ['', Validators.required],
      SelectedTopics: ['', Validators.required],
      SelectedCertificate: ['', Validators.required],
      SelectedDomain: ['', Validators.required],
      WebLink: new FormControl(),
      Title: ['', Validators.required],
      ref_type: ['', Validators.required],
      SelectedBook: new FormControl(),
      PageNumber: new FormControl(),
    });
  }
  async ngOnInit() {
    await this.spinner.show();
    try {
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          this.service.token = token;
          await this.GetAllBooks(token);
          await this.GetAllDropdown(token);
          await this.GetAllReferences(token);
          await this.spinner.hide();
        }
      });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  openTranscriptPopup() {
    this.TranscriptPopup = true;
    this.IsUpdate = false;
  }
  async GetAllReferences(token) {
    const users: videolist[] = [];
    await this.referenceService.GetAllReferences(token).then(async (data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        var srno = 0;
        await list.data.forEach(async (element) => {
          srno = srno + 1;
          await users.push({
            srno: srno,
            id: element.id,
            web_link: element.web_link,
            title: element.title,
            certificate_name: element.certificate.certificate_name,
            domin_name: element.domain.domin_name,
            subdomain_name: element.subdomain.subdomain_name,
            topic_name: element.topic.topic_name,
            d_id: element.domain.id,
            sd_id: element.subdomain.id,
            topic_id: element.topic.id,
            certi_id: element.certificate.id,
            ref_type: element.ref_type,
            page_no: element.page_no,
            book_id: element.book_id,
          });
        });
        this.ActiveRoute.params.subscribe(async (params) => {
          try {
            var data = JSON.parse(atob(params['id']));
            let edited = users.filter((x) => x.id == data);
            this.editFromBook = true;
            this.editReference(edited[0]);
          } catch (error) {}
        });
        this.dataSource = new MatTableDataSource(users);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.filter();
        this.pagination = true;
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  closeTranscriptPopup() {
    this.TranscriptPopup = false;
    this.IsUpdate = false;
    this.SelectedCertificate = '';
    this.SelectedDomain = '';
    this.SelectedSubDomain = '';
    this.SelectedTopics = '';
    this.ref_type = '';
    this.SelectedBook = '';
    this.PageNumber = '';
    this.WebLink = '';
    this.Title = '';

    this.oldAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      'Reference Type': '',
      WebLink: '',
      'Book Name': '',
      'Page Number': '',
      'Reference Name': '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      Domain: '',
      Subdomain: '',
      Topic: '',
      'Reference Type': '',
      WebLink: '',
      'Book Name': '',
      'Page Number': '',
      'Reference Name': '',
    };
    this.auditHistory = '';
  }
  async deleteReference(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Delete this reference!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.referenceService
              .DeleteReference(item.id, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          item.web_link +
                          '</span> Reference is deleted by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'references',
                          item.id,
                          token,
                        );
                      }
                    });
                  await this.GetAllReferences(this.token);
                  this.service.ShowSuccess('Reference deleted successfully');
                }
              });
          }
        });
        this.spinner.hide();
      }
    });
  }
  async GetAllDropdown(token) {
    await this.service.GetAllDropdown(token).then(async (data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        this.Certificates = list.data.Certificate;
        this.Domain = list.data.Domain;
        this.filterDomain = list.data.Domain;
        this.SubDomain = list.data.SubDomain;
        this.filtersubDomain = list.data.SubDomain;
        this.Topic = list.data.Topic;
        this.filterTpoic = list.data.Topic;
        this.SelectedSubDomain = '';
        this.SelectedTopics = '';
        this.SelectedCertificate = '';
        this.SelectedDomain = '';
        this.WebLink = '';
        this.Title = '';
        this.ref_type = 'Book';
      }
    });
  }
  async GetAllBooks(token) {
    await this.booksService.GetAllBooks(token).then(async (data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        this.Books = list.data;
        this.SelectedBook = '';
      }
    });
  }
  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });
      this.SelectedSubDomain = '';
      this.SelectedTopics = '';
    } else {
      this.SelectedDomain = '';
      this.SelectedSubDomain = '';
      this.SelectedTopics = '';
      this.SubDomain = this.filtersubDomain;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return itemset.sd_id == val;
      });
      this.SelectedTopics = '';
    } else {
      this.SelectedTopics = '';
      this.Topic = this.filterTpoic;
    }
  }

  async saveReference() {
    this.markFormTouched(this.VideoLessonForm);
    if (this.VideoLessonForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          if (this.ref_type == 'Book') {
            this.WebLink = '';
          } else {
            this.SelectedBook = null;
            this.PageNumber = null;
          }
          await this.referenceService
            .AddReferences(
              this.SelectedCertificate,
              this.SelectedDomain,
              this.SelectedSubDomain,
              this.SelectedTopics,
              this.ref_type,
              this.SelectedBook,
              this.PageNumber,
              this.Title,
              this.WebLink,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.WebLink +
                        ' </span> references is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'references',
                        list.data.id,
                        token,
                      );
                    }
                  });
                this.closeTranscriptPopup();
                await this.GetAllReferences(token);
                await this.spinner.hide();
                this.service.ShowSuccess('Reference added successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
              }
            });
        }
      });
    }
  }
  async updateReference() {
    this.markFormTouched(this.VideoLessonForm);
    if (this.VideoLessonForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          if (this.ref_type == 'Book') {
            this.WebLink = '';
          } else {
            this.SelectedBook = null;
            this.PageNumber = null;
          }
          var token = session.getIdToken().getJwtToken();
          await this.referenceService
            .UpdateReferences(
              this.id,
              this.SelectedCertificate,
              this.SelectedDomain,
              this.SelectedSubDomain,
              this.SelectedTopics,
              this.ref_type,
              this.SelectedBook,
              this.PageNumber,
              this.Title,
              this.WebLink,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      this.newAuditValueArray = {
                        Certificate: this.Certificates.find(
                          (x) => x.id == this.SelectedCertificate,
                        )
                          ? this.Certificates.find(
                              (x) => x.id == this.SelectedCertificate,
                            ).certificate_name
                          : '',
                        Domain: this.filterDomain.find(
                          (x) => x.id == this.SelectedDomain,
                        )
                          ? this.filterDomain.find(
                              (x) => x.id == this.SelectedDomain,
                            ).domin_name
                          : '',
                        Subdomain: this.filtersubDomain.find(
                          (x) => x.id == this.SelectedSubDomain,
                        )
                          ? this.filtersubDomain.find(
                              (x) => x.id == this.SelectedSubDomain,
                            ).subdomain_name
                          : '',
                        Topic: this.filterTpoic.find(
                          (x) => x.id == this.SelectedTopics,
                        )
                          ? this.filterTpoic.find(
                              (x) => x.id == this.SelectedTopics,
                            ).topic_name
                          : '',
                        'Reference Type': this.ref_type,
                        WebLink: this.ref_type == 'Website' ? this.WebLink : '',
                        'Book Name':
                          this.ref_type == 'Book'
                            ? this.Books.find((x) => x.id == this.SelectedBook)
                              ? this.Books.find(
                                  (x) => x.id == this.SelectedBook,
                                ).book_title
                              : ''
                            : '',
                        'Page Number':
                          this.ref_type == 'Book' ? this.PageNumber : '',
                        'Reference Name': this.Title,
                      };
                      var audit = this.auth.compareAuditHist(
                        this.oldAuditValueArray,
                        this.newAuditValueArray,
                      );
                      var desc = audit;
                      await this.service.addAdminLogs(
                        desc,
                        'references',
                        this.id,
                        token,
                      );
                    }
                  });
                this.closeTranscriptPopup();
                this.service.ShowSuccess('Reference update successfully');
                if (this.editFromBook == true) {
                  this.editFromBook = false;
                  await this.spinner.hide();
                  this.location.back();
                } else {
                  await this.GetAllReferences(token);
                  await this.spinner.hide();
                }
                await this.spinner.hide();
              } else {
                await this.spinner.hide();
              }
            });
        }
      });
    }
  }
  async editReference(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('references', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.IsUpdate = true;
    this.TranscriptPopup = true;
    this.id = item.id;
    this.SelectedSubDomain = item.sd_id;
    this.SelectedTopics = item.topic_id;
    this.SelectedCertificate = item.certi_id;
    this.SelectedDomain = item.d_id;
    this.WebLink = item.web_link;
    this.Title = item.title;
    this.SelectedBook = item.book_id;
    this.PageNumber = item.page_no;
    this.ref_type = item.ref_type;

    this.Domain = this.filterDomain.filter(
      (x) => x.certi_id == this.SelectedCertificate,
    );
    this.SubDomain = this.filtersubDomain.filter(
      (x) => x.d_id == this.SelectedDomain,
    );
    this.Topic = this.filterTpoic.filter(
      (x) => x.sd_id == this.SelectedSubDomain,
    );
    this.oldAuditValueArray = {
      Certificate: this.Certificates.find(
        (x) => x.id == this.SelectedCertificate,
      )
        ? this.Certificates.find((x) => x.id == this.SelectedCertificate)
            .certificate_name
        : '',
      Domain: this.filterDomain.find((x) => x.id == this.SelectedDomain)
        ? this.filterDomain.find((x) => x.id == this.SelectedDomain).domin_name
        : '',
      Subdomain: this.filtersubDomain.find(
        (x) => x.id == this.SelectedSubDomain,
      )
        ? this.filtersubDomain.find((x) => x.id == this.SelectedSubDomain)
            .subdomain_name
        : '',
      Topic: this.filterTpoic.find((x) => x.id == this.SelectedTopics)
        .topic_name
        ? this.filterTpoic.find((x) => x.id == this.SelectedTopics).topic_name
        : '',
      'Reference Type': this.ref_type,
      WebLink: this.ref_type == 'Website' ? this.WebLink : '',
      'Book Name':
        this.ref_type == 'Book'
          ? this.Books.find((x) => x.id == this.SelectedBook)
            ? this.Books.find((x) => x.id == this.SelectedBook).book_title
            : ''
          : '',
      'Page Number': this.ref_type == 'Book' ? this.PageNumber : '',
      'Reference Name': this.Title,
    };
  }
  onCertificateChange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((data) => {
        return data.certi_id == val;
      });
      this.SelectedDomain = '';
      this.SelectedSubDomain = '';
      this.SelectedTopics = '';
    } else {
      this.SelectedDomain = '';
      this.SelectedSubDomain = '';
      this.SelectedTopics = '';
      this.Domain = this.filterDomain;
    }
  }

  async bulkCheckDKIM(data) {
    let response = 0;
    data.forEach(async (element) => {
      let domain = element.email.split('@')[1];
      await this.auth.verifyDKIM(domain).then(async (result) => {
        if (result[0].StatusCode == 1) {
          response = 1;
        }
      });
    });
    return response;
  }
  async openBulkUploadModal() {
    this.isBulkUpload = true;
  }
  async closeBulkUploadModal() {
    this.isBulkUpload = false;
    this.progress = 0;
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.files = [];
    this.myEvent = '';
  }
  async bulkUpload() {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.myEvent.addedFiles[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = jsonData;
    };

    reader.readAsBinaryString(file);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.selectBulkupload = true;
    this.selectprogress = false;
  }
  onSelect(event) {
    let me = this;
    var timeleft = 10;
    me.progress = 0;
    me.selectprogress = true;
    me.selectBulkupload = true;
    this.files.push(event.addedFiles);
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        me.progress = 10 - timeleft;
        me.myEvent = event;
        me.selectBulkupload = false;
        me.selectprogress = false;
        clearInterval(downloadTimer);
      }
      me.progress = 10 - timeleft;
      timeleft -= 1;
    }, 200);
  }

  ExportExcel() {
    var certification = Object.assign(
      this.Certificates.map((k) => ({ name: k.certificate_name })),
    );
    var domain = Object.assign(
      this.Domain.map((k) => ({ name: k.domin_name })),
    );
    var subdomain = Object.assign(
      this.SubDomain.map((k) => ({ name: k.subdomain_name })),
    );
    var topic = Object.assign(this.Topic.map((k) => ({ name: k.topic_name })));
    var book = Object.assign(this.Books.map((k) => ({ name: k.book_title })));
    var bookwebsite = [
      {
        workSheet: 'bookwebsite',
        rows: [{ name: 'Book' }, { name: 'Website' }],
        properties: { state: 'hidden' },
      },
    ];
    var certificationData = [
      {
        workSheet: 'certification',
        rows: certification,
        properties: { state: 'hidden' },
      },
    ];
    var domainData = [
      {
        workSheet: 'domain',
        rows: domain,
        properties: { state: 'hidden' },
      },
    ];
    var subdomainData = [
      {
        workSheet: 'subdomain',
        rows: subdomain,
        properties: { state: 'hidden' },
      },
    ];
    var topicData = [
      {
        workSheet: 'topic',
        rows: topic,
        properties: { state: 'hidden' },
      },
    ];
    var bookData = [
      {
        workSheet: 'book',
        rows: book,
        properties: { state: 'hidden' },
      },
    ];
    var References_sample = [
      {
        name: 'References_sample',
        values: [
          {
            header: 'certification',
            value: [
              { name: 'certification!$A$2:$A$' + (certification.length + 1) },
            ],
          },
          {
            header: 'domain',
            value: [{ name: 'domain!$A$2:$A$' + (domain.length + 1) }],
          },
          {
            header: 'subdomain',
            value: [{ name: 'subdomain!$A$2:$A$' + (subdomain.length + 1) }],
          },
          {
            header: 'topic',
            value: [{ name: 'topic!$A$2:$A$' + (topic.length + 1) }],
          },
          { header: 'bookwebsite', value: [{ name: 'bookwebsite!$A$2:$A$4' }] },
          {
            header: 'select_book',
            value: [{ name: 'book!$A$2:$A$' + (book.length + 1) }],
          },
          { header: 'page_no', value: '' },
          { header: 'website_url', value: '' },
          { header: 'reference', value: '' },
        ],
        properties: { state: 'visible' },
      },
    ];
    var workbookData = [
      this.transform(References_sample)[0],
      certificationData[0],
      domainData[0],
      subdomainData[0],
      topicData[0],
      bookwebsite[0],
      bookData[0],
    ];
    this.excelService.exportAsExcelFile(
      workbookData,
      References_sample[0].name,
    );
  }
  transform(data) {
    const noOfRowaToGenerate = 5;
    return data.map(({ name, values, properties }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value)
            ? next.value.map(({ name }) => name)
            : next.value,
        }),
        {},
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers),
        properties: properties,
      };
    });
  }

  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface videolist {
  srno: number;
  id: string;
  web_link: string;
  title: string;
  certificate_name: string;
  domin_name: string;
  subdomain_name: string;
  topic_name: string;
  d_id: number;
  sd_id: number;
  topic_id: number;
  certi_id: number;
  ref_type: string;
  page_no: string;
  book_id: string;
}
