<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Admins</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Name/Email"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewAdmin()">Add Admin</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="SrNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.SrNo }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="chortname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Live Class</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.chortname ? element.chortname : '-' }}">{{
              element.chortname ? element.chortname : '-'
            }}</span>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.name }}">{{ element.name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.email }}">{{ element.email }}</span>
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="FreeFlashCissp">
          <th mat-header-cell *matHeaderCellDef>Free Flash CISSP</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              class="example-margin"
              [checked]="
                element.free_flashcard == '1' || element.free_flashcard == '2'
              "
              (change)="Checkboxselect($event.checked, element)"
              [disabled]="element.free_flashcard == '1'"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="FreeFlashCCSP">
          <th mat-header-cell *matHeaderCellDef>Free Flash CCSP</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [checked]="
                element.free_flashccsp == '1' || element.free_flashccsp == '2'
              "
              (change)="CCSPCheckboxselect($event.checked, element)"
              [disabled]="element.free_flashccsp == '1'"
              class="example-margin"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="praticeque">
          <th mat-header-cell *matHeaderCellDef>Practice question</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox class="example-margin" color="primary">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="UserAnslysis">
          <th mat-header-cell *matHeaderCellDef>User Analysis</th>
          <td mat-cell *matCellDef="let element">
            <span
              [routerLink]="['../client/useranalysis', element.id]"
              class="material-icons"
            >
              visibility
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(element)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(element)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addAdmin">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Admin</p>
      <p *ngIf="IsUpdate">Edit Admin</p>

      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="LoginForm">
          <div class="formwrapper">
            <div class="email" *ngIf="!IsUpdate">
              <div class="input-fill">
                <input
                  formControlName="email"
                  [(ngModel)]="email"
                  placeholder="Enter Email"
                  required
                  [ngClass]="{
                    err:
                      LoginForm.controls['email'].hasError('required') &&
                      (LoginForm.controls['email'].dirty ||
                        LoginForm.controls['email'].touched)
                  }"
                />
                <span class="material-icons">email</span>
              </div>
            </div>
            <div class="firs-name">
              <div class="input-fill">
                <input
                  formControlName="firstname"
                  [(ngModel)]="firstname"
                  placeholder="Enter first name"
                  required
                  [ngClass]="{
                    err:
                      LoginForm.controls['firstname'].hasError('required') &&
                      (LoginForm.controls['firstname'].dirty ||
                        LoginForm.controls['firstname'].touched)
                  }"
                />
                <span class="material-icons">account_circle</span>
              </div>
            </div>
            <div class="last-name">
              <div class="input-fill">
                <input
                  formControlName="lastname"
                  [(ngModel)]="lastname"
                  placeholder="Enter last name"
                  required
                  [ngClass]="{
                    err:
                      LoginForm.controls['lastname'].hasError('required') &&
                      (LoginForm.controls['lastname'].dirty ||
                        LoginForm.controls['lastname'].touched)
                  }"
                />
                <span class="material-icons">account_circle</span>
              </div>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <button class="submit-btn" *ngIf="!IsUpdate" (click)="saveAdmin()">
        Save
      </button>
      <button class="submit-btn" *ngIf="IsUpdate" (click)="updateAdmin()">
        Update
      </button>
    </div>
  </div>
</div>
