<div class="auth">
  <div class="login-form">
    <div class="logo">
      <img src="../../assets/images/logo.png" alt="CISSP" />
    </div>
    <h3>
      <span>Enter your authentication code</span> Use your authenticator app to
      view your security code
    </h3>
    <form class="form" [formGroup]="LoginForm">
      <div class="input-form mb-20">
        <ng-otp-input
          formControlName="authcode"
          (onInputChange)="onOtpChange($event)"
          [config]="{ length: 6, inputClass: 'input' }"
          [(ngModel)]="code"
        >
        </ng-otp-input>
      </div>
      <button type="submit" class="submit" (click)="thankyou()">Login</button>
    </form>
    <div class="form-button-next-generate">
      <button
        type="button "
        class="frm-next-btn-generate"
        (click)="GeneratedQR()"
      >
        Setup Code
      </button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
