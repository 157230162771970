<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Exams</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Exam Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewCatExam()">Add Exam</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="ketexam_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Exam Name</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.ketexam_name }}">{{ row.ketexam_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="questionlenth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header># Questions</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.questionlenth }}">{{ row.questionlenth }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Time Limit</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.time }}">{{ row.time }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addCateExam">
  <div class="cutome-modal-content" style="width: 60%">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add CAT Exam</p>
      <p *ngIf="IsUpdate">Edit CAT Exam</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="PracticeTestForm">
          <div class="formwrapper">
            <div>
              <div class="select-box">
                <select
                  [(ngModel)]="Certificate"
                  formControlName="Certificate"
                  required
                  [ngClass]="{
                    err:
                      PracticeTestForm.controls['Certificate'].hasError(
                        'required'
                      ) &&
                      (PracticeTestForm.controls['Certificate'].dirty ||
                        PracticeTestForm.controls['Certificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <input
                [(ngModel)]="PracticeTestname"
                formControlName="PracticeTestname"
                placeholder="Exam Name"
                name="name"
                required
                [ngClass]="{
                  err:
                    PracticeTestForm.controls['PracticeTestname'].hasError(
                      'required'
                    ) &&
                    (PracticeTestForm.controls['PracticeTestname'].dirty ||
                      PracticeTestForm.controls['PracticeTestname'].touched)
                }"
              />
            </div>

            <div>
              <ng-select
                [items]="acrolist"
                formControlName="Acronymsname"
                bindLabel="short_name"
                bindValue="id"
                placeholder="Select Acronyms"
                appendTo="body"
                multiple="true"
                [(ngModel)]="acroselecteds"
                [ngClass]="{
                  err:
                    PracticeTestForm.controls['Acronymsname'].hasError(
                      'required'
                    ) &&
                    (PracticeTestForm.controls['Acronymsname'].dirty ||
                      PracticeTestForm.controls['Acronymsname'].touched)
                }"
              >
              </ng-select>
            </div>
            <div>
              <div class="date-select">
                <div class="date-time">
                  <div
                    class="hr-select"
                    [ngClass]="{
                      err:
                        PracticeTestForm.controls['hours'].hasError(
                          'required'
                        ) &&
                        (PracticeTestForm.controls['hours'].dirty ||
                          PracticeTestForm.controls['hours'].touched)
                    }"
                  >
                    <div class="select-box">
                      <input
                        [(ngModel)]="hours"
                        formControlName="hours"
                        placeholder="0"
                        type="number"
                        required
                      />
                    </div>
                    <span>HR</span>
                  </div>
                  <div
                    class="min-select"
                    [ngClass]="{
                      err:
                        PracticeTestForm.controls['Minutes'].hasError(
                          'required'
                        ) &&
                        (PracticeTestForm.controls['Minutes'].dirty ||
                          PracticeTestForm.controls['Minutes'].touched)
                    }"
                  >
                    <div class="select-box">
                      <input
                        [(ngModel)]="Minutes"
                        formControlName="Minutes"
                        placeholder="0"
                        type="number"
                        required
                      />
                    </div>
                    <span>MIN</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="pagesection" *ngIf="IsUpdate">
          <div class="search-wrap">
            <div class="search-icon">
              <input
                [(ngModel)]="SearchQuestion"
                (keyup)="filterQuestion()"
                placeholder="Search by Question"
                name="search"
              />
              <span class="material-icons">search</span>
            </div>
          </div>
          <div class="mat-elevation-z8">
            <table
              mat-table
              [dataSource]="dataSourcequestion"
              #quespaginator
              matSort
              style="border-spacing: 0 10px"
            >
              <ng-container matColumnDef="srno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  <span>{{ i + 1 }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Question
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.title }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="AnswerA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Answer A
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.first_choice }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="AnswerB">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Answer B
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.second_choice }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="AnswerC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Answer C
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.third_choice }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="AnswerD">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Answer D
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.fourth_choice }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                  <img
                    src="../../../assets/images/edit-1.png"
                    (click)="questionedit(row)"
                    alt="Edit"
                    class="action-icon"
                  />
                  <img
                    src="../../../assets/images/remove.png"
                    (click)="deleteque(row)"
                    alt="Delete"
                    class="action-icon"
                  />
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsquestion"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsquestion"
              ></tr>
            </table>
          </div>
        </div>
        <div class="page-footer" *ngIf="IsUpdate">
          <mat-paginator
            style-paginator
            #quespaginator
            [showTotalPages]="0"
            [length]="
              dataSourcequestion.data ? dataSourcequestion.data.length : 0
            "
            *ngIf="matpagination"
            [pageSizeOptions]="[10, 20, 50, 100, 500]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button type="button" class="submit-btn" (click)="onSubmit()">
          Save
        </button>
      </div>
      <div *ngIf="IsUpdate">
        <button type="button" class="submit-btn" (click)="Update()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
