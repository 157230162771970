import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ClientService } from '../../services/client/client.service';
import { EmailService } from '../../services/email/email.service';
import { VariableService } from '../../services/variable/variable.service';
import * as ClassicEditor from '@blowstack/ckeditor5-full-free-build';
import { CohortService } from '../../services/cohort/cohort.service';
import { TemplateService } from '../../services/template/template.service';
import { CompanyService } from 'src/app/services/company/company.service';
@Component({
  selector: 'app-manuallysend',
  templateUrl: './manuallysend.component.html',
  styleUrls: ['./manuallysend.component.scss'],
})
export class ManuallysendComponent implements OnInit {
  displayedColumns: string[] = [
    'checkbox',
    'srno',
    'student_name',
    'email',
    'user_state',
    'link',
  ];
  dataSource: MatTableDataSource<Topiclist>;
  selection = new SelectionModel<Topiclist>(true, []);
  Search: any;
  Companies = [];
  Cohorts = [];
  Templates = [];
  Variables = [];
  SelectedCompany: any;
  SelectedCohort: any;
  SelectCohort = [];
  SelectedTemplate: any;
  Subject: any;
  CC: any;
  Message = '';
  HtmlCode = '';
  SelectedVariable: any;
  showFullScreen: boolean = false;

  public config: any = {
    simpleUpload: {
      uploadUrl: 'https://tech4biz.io/ckeditor/ckeditor.php?responseType=json',
    },
    removePlugins: ['Title'],
    heading: {
      options: [
        {
          model: 'heading1',
          view: { name: 'h1', classes: 'ck-heading_heading1' },
          title: 'Heading 1',
        },
        {
          model: 'heading2',
          view: { name: 'h2', classes: 'ck-heading_heading2' },
          title: 'Heading 2',
        },
        {
          model: 'heading3',
          view: { name: 'h3', classes: 'ck-heading_heading3' },
          title: 'Heading 3',
        },
      ],
    },
    toolbar: [
      'undo',
      'redo',
      'removeformat',
      'heading',
      '|',
      'fontsize',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'fontfamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'link',
      'highlight',
      'code',
      'codeBlock',
      'blockQuote',
      'subscript',
      'superscript',
      'specialCharacters',
      '|',
      'alignment',
      'indent',
      'outdent',
      'pagebreak',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'uploadImage',
      'insertTable',
      'tableProperties',
      'tableCellProperties',
      'horizontalLine',
    ],
    extraAllowedContent: true,
    fullPage: true,
  };
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public Editor = ClassicEditor;
  constructor(
    public templateService: TemplateService,
    public cohortService: CohortService,
    public variableService: VariableService,
    public emailService: EmailService,
    public clientService: ClientService,
    public companyService: CompanyService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  async ngOnInit() {
    await this.spinner.show();
    await this.getAllCompany();
    await this.getAllCohort();
    await this.listTemplate();
    await this.getAllVariables();
    await this.spinner.hide();
  }
  async getAllCompany() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.companyService.GetAllCompany(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Companies = list.data;
            this.SelectedCompany = '';
            this.SelectedCohort = '';
          } else {
            this.spinner.hide();
          }
        });
      }
    });
  }
  async getAllCohort() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.cohortService.ListCohort(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Cohorts = list.data;
            this.SelectedCohort = '';
          }
        });
      }
    });
  }
  async listTemplate() {
    await this.auth.GetAllBeeFreeTemplate().then(async (BeeFreeData) => {
      if (BeeFreeData[0].StatusCode == 1) {
        for (const ListTemp of BeeFreeData[0].data.TemplatesMetadata) {
          await this.auth
            .GetBeeFreeTemplate(ListTemp.Name)
            .then(async (BeeFreeGetTemp) => {
              if (BeeFreeGetTemp[0].StatusCode == 1) {
                var TemplateParam = {
                  id: BeeFreeGetTemp[0].data.Template.TemplateName,
                  name: BeeFreeGetTemp[0].data.Template.TemplateName,
                  certi_id: 11,
                  subject: '',
                  message: BeeFreeGetTemp[0].data.Template.HtmlPart,
                };
                this.Templates.push(TemplateParam);
              }
            });
        }
      }
    });
    this.SelectedTemplate = '';
  }
  async getAllVariables() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.variableService.GetAllVariables(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Variables = list.data;
            this.SelectedVariable = '';
          }
        });
      }
    });
  }
  changeCompany() {
    this.Cohorts = [];
    this.dataSource = null;
    this.Companies.forEach((element) => {
      if (element.id == this.SelectedCompany) {
        this.Cohorts = element.cohorts;
        this.SelectedCohort = '';
      }
    });
  }
  async changeCohort() {
    this.spinner.show();
    this.SelectCohort = [];
    this.Cohorts.forEach((element) => {
      if (element.id == this.SelectedCohort) {
        this.SelectCohort = element;
      }
    });
    await this.usersList(this.SelectedCohort);
    this.spinner.hide();
  }
  async changeTemplate() {
    this.Subject = '';
    this.Message = '';
    this.Templates.forEach((element) => {
      if (element.id == this.SelectedTemplate) {
        this.Subject = element.subject;
        if (this.Subject == '') {
          if (element.id == 'Welcome__onboarding_from_Rob-1659968632166') {
            this.Subject = 'CISSP MasterClass ACCESS';
          }
        }
        this.Message = element.message;
        this.HtmlCode = element.html_source;
      }
    });
  }
  changeVariable() {
    this.Message = this.SelectedVariable + ' ' + this.Message;
    this.HtmlCode = this.SelectedVariable + ' ' + this.HtmlCode;
  }
  async usersList(id) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Topiclist[] = [];
        this.spinner.show();
        await this.templateService
          .getUserFromCohort(id, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ];
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                var status = 'Confirm';
                if (element.last_login == null) {
                  status = 'New';
                } else {
                  status = 'Confirm';
                }
                let usr = {
                  user_id: element.id,
                  cohort: this.SelectedCohort,
                  date:
                    monthNames[
                      new Date(this.SelectCohort['start_date']).getMonth()
                    ] +
                    ' ' +
                    this.getOrdinal(
                      new Date(this.SelectCohort['start_date']).getDate(),
                    ),
                };
                let user_id = btoa(JSON.stringify(usr));
                let my_link =
                  'https://www.my.destcert.com/welcomertemp/' + user_id;
                await users.push({
                  srno: srno,
                  id: element.id,
                  student_name: element.name,
                  email: element.email,
                  link: my_link,
                  user_state: status,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
              this.pagination = true;
            }
          });
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }
  copyToClipboard(link) {
    navigator.clipboard.writeText(link);
    this.service.ShowSuccess('Link copied to clipboard.');
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  async addFailedEmail(user, cohort, msg, email_type, mail_success) {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .addFailedEmail(user, cohort, msg, email_type, mail_success, token)
          .then(async (data) => {});
      }
    });
  }
  async sendMail() {
    if (
      this.SelectedTemplate != '' &&
      this.SelectedTemplate != null &&
      this.SelectedTemplate != undefined
    ) {
      if (this.selection.selected.length > 0) {
        this.spinner.show();
        let message = '';

        let cnt = 0;
        let conf_cnt = 0;
        for (let i = 0; i < this.selection.selected.length; i++) {
          const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
          let subject = this.Subject;
          let usr = {
            user_id: this.selection.selected[i].id,
            cohort: this.SelectedCohort,
            date:
              monthNames[new Date(this.SelectCohort['start_date']).getMonth()] +
              ' ' +
              this.getOrdinal(
                new Date(this.SelectCohort['start_date']).getDate(),
              ),
          };
          let user_id = btoa(JSON.stringify(usr));
          let my_link = 'welcomertemp/' + user_id;

          message = this.HtmlCode;

          var datas = {
            to: this.selection.selected[i].email,
            text: message,
            subject: subject,
            name: this.selection.selected[i].student_name,
            link: 'https://www.my.destcert.com/welcomertemp/' + user_id,
            Template: this.SelectedTemplate,
            cohort_name: this.SelectCohort['cohort_name']
              ? this.SelectCohort['cohort_name']
              : '',
          };
          await this.auth
            .TemplateSendMail(datas)
            .then(async (result) => {
              if (result[0].StatusCode == 1) {
                let user = this.selection.selected[i].email;
                let msg = result[0].message;
                let cohort = this.SelectCohort['cohort_name'];
                let email_type = 'Welcome Email';
                await this.addFailedEmail(user, cohort, msg, email_type, 1);
                let activity_message = 'Welcome email send';
                await this.service.addActivityTracker(
                  this.selection.selected[i].id,
                  user,
                  this.SelectedCohort,
                  'Welcome Email',
                  activity_message,
                );
                cnt++;
              } else {
                let user = this.selection.selected[i].email;
                let msg = result[0].message;
                let cohort = this.SelectCohort['cohort_name'];
                let email_type = 'Welcome Email';
                await this.addFailedEmail(user, cohort, msg, email_type, 0);
                let activity_message = 'Welcome email send failed';
                await this.service.addActivityTracker(
                  this.selection.selected[i].id,
                  user,
                  this.SelectedCohort,
                  'Welcome Email',
                  activity_message,
                );
                this.service.ShowError(result[0].message);
              }
            })
            .catch((err) => {});
          conf_cnt++;
        }
        if (conf_cnt > 0) {
          await this.service.ShowSuccess(
            'Email send fail for ' +
              conf_cnt +
              ' users as they are confirmed users.',
          );
        }
        this.spinner.hide();
        await this.service.somePushShowSuccess(
          'Total email sent ' +
            cnt +
            ' out of ' +
            this.selection.selected.length,
        );
        this.cancelMail();
      } else {
        this.service.ShowError('Please select at least one student');
      }
    } else {
      let cnt = 0;
      for (let i = 0; i < this.selection.selected.length; i++) {
        let message = this.Message;
        message = message.replace(
          '{name}',
          this.selection.selected[i].student_name,
        );
        message = message.replace('{email}', this.selection.selected[i].email);
        var req = {
          to: this.selection.selected[i].email,
          text: message,
          subject: this.Subject,
          cc: this.CC,
        };
        await this.auth
          .sendmail(req)
          .then((result) => {
            cnt++;
          })
          .catch((err) => {});
      }
      await this.service.somePushShowSuccess(
        'Total email sent ' + cnt + ' out of ' + this.selection.selected.length,
      );
      this.cancelMail();
    }
  }
  cancelMail() {
    this.SelectedCohort = '';
    this.SelectedTemplate = '';
    this.SelectedVariable = '';
    this.dataSource = null;
    this.Subject = '';
    this.Message = '';
    this.selection.clear();
  }
  getOrdinal(n) {
    let ord = [, 'st', 'nd', 'rd'];
    let exceptions = [11, 12, 13];
    let nth =
      ord[n % 100] == undefined || exceptions.includes(n % 100)
        ? 'th'
        : ord[n % 100];
    return n + nth;
  }
}

export interface Topiclist {
  srno: number;
  id: number;
  student_name: string;
  email: string;
  link: string;
  user_state: string;
}
export class UploadAdapter {
  private loader;
  constructor(loader: any) {
    this.loader = loader;
  }
  public upload(): Promise<any> {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = (e) => {
            resolve({ default: myReader.result });
          };
          myReader.readAsDataURL(file);
        }),
    );
  }
}
