import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import * as crypto from 'crypto-js';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class HybridclassService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  async DeleteCohort(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.HYBRID_CLASS_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListCohort(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.HYBRIDCLASS_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async AddCohort(
    cohort_name,
    certi_id,
    cource_id,
    knowassessments,
    practicetests,
    ketexamtests,
    flashcard_app,
    practice_que_app,
    tevents,
    userslist,
    location,
    company_id,
    schedule,
    email_templates,
    time_zone,
    template_id,
    access,
    start_date,
    method_id,
    access_duration,
    course_type,
    token,
    liveevent = '',
    video = '',
    mindmap = '',
    baseline_ka = '',
    baseline_ka_schedule = '',
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_name: cohort_name,
          certi_id: certi_id,
          cource_id: cource_id,
          live_events: liveevent,
          videos: video,
          mindmaps: mindmap,
          knowassessments: knowassessments,
          practicetests: practicetests,
          ketexamtests: ketexamtests,
          flashcard_app: flashcard_app,
          practice_que_app: practice_que_app,
          tevents: tevents,
          userslist: userslist,
          location: location,
          company_id: company_id,
          schedule: schedule,
          email_templates: email_templates,
          time_zone: time_zone,
          template_id: template_id,
          video_access: access.VideoAccess,
          mindmap_access: access.MindmapAccess,
          summary_access: access.SummaryAccess,
          reference_access: access.ReferenceAccess,
          KA_access: access.KAAccess,
          PT_access: access.PTAccess,
          CAT_access: access.CATAccess,
          livechat_access: access.LiveChatAccess,
          liveq_access: access.LiveQuestionAccess,
          liveevent_access: access.LiveEventAccess,
          flashcard_access: access.FlashcardAccess,
          question_access: access.QuestionAccess,
          start_date: start_date,
          method_id: method_id,
          access_duration: access_duration,
          course_type: course_type,
          baseline_ka: baseline_ka,
          baseline_ka_schedule: baseline_ka_schedule,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.HYBRID_COHORT_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateNewCohort(
    id,
    cohort_name,
    certi_id,
    cource_id,
    knowassessments,
    practicetests,
    ketexamtests,
    flashcard_app,
    practice_que_app,
    tevents,
    userslist,
    location,
    company_id,
    schedule,
    email_templates,
    time_zone,
    template_id,
    access,
    start_date,
    method_id,
    access_duration,
    course_type,
    token,
    liveevent = null,
    video = null,
    mindmap = null,
    baseline_ka = '',
    baseline_ka_schedule = '',
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          cohort_name: cohort_name,
          certi_id: certi_id,
          cource_id: cource_id,
          live_events: liveevent,
          videos: video,
          mindmaps: mindmap,
          knowassessments: knowassessments,
          practicetests: practicetests,
          ketexamtests: ketexamtests,
          flashcard_app: flashcard_app,
          practice_que_app: practice_que_app,
          tevents: tevents,
          userslist: userslist,
          location: location,
          company_id: company_id,
          schedule: schedule,
          email_templates: email_templates,
          time_zone: time_zone,
          template_id: template_id,
          video_access: access.VideoAccess,
          mindmap_access: access.MindmapAccess,
          summary_access: access.SummaryAccess,
          reference_access: access.ReferenceAccess,
          KA_access: access.KAAccess,
          PT_access: access.PTAccess,
          CAT_access: access.CATAccess,
          livechat_access: access.LiveChatAccess,
          liveq_access: access.LiveQuestionAccess,
          liveevent_access: access.LiveEventAccess,
          flashcard_access: access.FlashcardAccess,
          question_access: access.QuestionAccess,
          start_date: start_date,
          method_id: method_id,
          access_duration: access_duration,
          course_type: course_type,
          baseline_ka: baseline_ka,
          baseline_ka_schedule: baseline_ka_schedule,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.HYBRID_COHORT_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateCohort(
    id,
    cohort_name,
    location,
    knowassessments,
    practicetests,
    cource_id,
    certi_id,
    cateexam,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_name: cohort_name,
          location: location,
          knowassessments: knowassessments,
          practicetests: practicetests.toString(),
          cource_id: parseInt(cource_id),
          certi_id: certi_id,
          ketexamtests: cateexam,
          status: '0',
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COHORT + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async IdCohort(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COHORT + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async EditCohortuser(userlist, id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          userslist: userlist,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COHORT + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateCohortWithCompanyIdNull(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COHORT_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getLimitStudent(page, limit, search, company_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          page: page,
          limit: limit,
          search: search.toString(),
          company_id: company_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USER_LIMIT_COHORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllCohort(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ALL_COHORT;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }

  async addMultipleStudentCohort(cohortIds, user_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohortIds: cohortIds,
          user_id: user_id.toString(),
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.STUDENT_ADD_MULTIPLE_COHORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getStudentReport(user_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.STUDENT_REPORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getActivityTracker(user_id, email, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          email: email,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ACTIVITY_TRACKER;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getCohortByIds(cohortIds, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohortIds: cohortIds,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.COHORT_BY_IDS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getCohortReport(cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CLIENT_COHORTREPORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async liveEventByIds(ids, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ids: ids,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + 'live_events/event_byids';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getSavedHybridClassSchedules(cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_id: cohort_id,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.HYBRID_CLASS_GET_SAVE_SCHEDULE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
