import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class MindmapService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async GetAllMindMaps(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MIND_MAP_ALL;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteMindMaps(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MIND_MAP_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async AddMindMaps(
    d_id,
    sd_id,
    name,
    description,
    certi_id,
    thumbnail_link,
    video_link,
    video_link_720p,
    video_link_480p,
    video_link_360p,
    video_link_240p,
    video_link_144p,
    chapters,
    transcript,
    duration,
    topic_id,
    qr_code,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          d_id: d_id,
          sd_id: sd_id,
          name: name,
          description: description,
          certi_id: certi_id,
          thumbnail_link: thumbnail_link,
          video_link: video_link,
          video_link_720p: video_link_720p,
          video_link_480p: video_link_480p,
          video_link_360p: video_link_360p,
          video_link_240p: video_link_240p,
          video_link_144p: video_link_144p,
          chapters: chapters,
          transcript: transcript,
          duration: duration,
          topic_id: topic_id,
          qr_code: qr_code,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MIND_MAP_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateMindMaps(
    id,
    d_id,
    sd_id,
    name,
    description,
    certi_id,
    thumbnail_link,
    video_link,
    video_link_720p,
    video_link_480p,
    video_link_360p,
    video_link_240p,
    video_link_144p,
    chapters,
    transcript,
    duration,
    topic_id,
    qr_code,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          d_id: d_id,
          sd_id: sd_id,
          name: name,
          description: description,
          certi_id: certi_id,
          thumbnail_link: thumbnail_link,
          video_link: video_link,
          video_link_720p: video_link_720p,
          video_link_480p: video_link_480p,
          video_link_360p: video_link_360p,
          video_link_240p: video_link_240p,
          video_link_144p: video_link_144p,
          chapters: chapters,
          transcript: transcript,
          duration: duration,
          topic_id: topic_id,
          qr_code: qr_code,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MIND_MAP_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async saveRearrangeVide(mindmaps, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          mindmaps: mindmaps,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MINDMAP_REARRANGE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
