import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { type } from 'os';
import { environment } from '../environments/environment';
import { CryptoService } from './services/crypto/crypto.service';
@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  public token: any;
  public ErrorPopup: boolean = false;
  public ErrorMessage: any;
  constructor(
    public http: Http,
    private toastr: ToastrService,
    private crypto: CryptoService,
  ) {}
  async ShowSuccess(success) {}
  async somePushShowSuccess(success) {
    this.toastr.success(success);
  }
  async ShowError(error) {
    this.ErrorPopup = true;
    this.ErrorMessage = error;
  }
  async ListCertificate(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CERTIFICATE_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListMentors(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MENTOR_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UsersFilter(email, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USERS_FILTER;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  public GetIpAddress() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.IP_ADDRESS).subscribe(
        (data) => {
          resolve(data['_body']);
        },
        (err) => {
          resolve(err['_body']);
        },
      );
    });
  }
  async bulkuploded(datas, tablename, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          data: datas,
          table: tablename,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();

      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.IMPORT_JSON;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async bulkuplodedSelectedStudent(datas, tablename, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          data: datas,
          table: tablename,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();

      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.BULK_STUDENT_TRANSCTION;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async addAdminLogs(description, type, type_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          description: description,
          type: type,
          type_id: type_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADMIN_LOGS_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async get_logs(type, type_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          type: type,
          type_id: type_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_LOGS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetAllDropdown(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.USER_LOGS_ALL_DROPDOWN;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async getDomainSequence(certi_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_DOMAIN_SEQUENCE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async getAllScores(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ALL_SCORE;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async getAllExams(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ALL_EXAMS;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async saveScore(
    know,
    review,
    bookmark,
    notes,
    pq_r,
    pq_w,
    ka_r,
    ka_w,
    pt_r,
    pt_w,
    cat_r,
    cat_w,
    live_r,
    live_w,
    video_watch,
    mindmap_view,
    reference_view,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          know: know,
          review: review,
          bookmark: bookmark,
          notes: notes,
          pq_r: pq_r,
          pq_w: pq_w,
          ka_r: ka_r,
          ka_w: ka_w,
          pt_r: pt_r,
          pt_w: pt_w,
          cat_r: cat_r,
          cat_w: cat_w,
          live_r: live_r,
          live_w: live_w,
          video_watch: video_watch,
          mindmap_view: mindmap_view,
          reference_view: reference_view,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SAVE_SCORE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addNotification(notification, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          data: notification,
          table: 'Notifications',
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADD_NOTIFICATION;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async getAllFailedEmails(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.FAILED_EMAIL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllPaymentTransactions(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_PAYMENT_TRANSACTIONS;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllSuccessEmails(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SUCCESS_EMAIL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          console.log(err);
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async deleteFailedEmail(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DELETE_FAILED_EMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addFailedEmail(
    email,
    cohort,
    message,
    email_type,
    mail_success,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          cohort: cohort,
          message: message,
          email_type: email_type,
          mail_success: mail_success,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADD_FAILED_EMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async createMeeting(userIds) {
    var url = 'https://teach.dstncert.com/chime/create_meeting.php';
    var data = {
      userIds: userIds,
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async deleteChimeMeeting(MeetingId) {
    var url = 'https://teach.dstncert.com/chime/delete_meeting.php';
    var data = {
      MeetingId: MeetingId,
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getMeeting(AttendeeId, MeetingId) {
    var url = 'https://teach.dstncert.com/chime/get_meeting.php';
    let data = {
      AttendeeId: AttendeeId,
      MeetingId: MeetingId,
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async certiWiseMaterials(certi_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CERTIWISE_MATERIAL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  getMessage(meeting_id, limitRecord, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          meeting_id: meeting_id,
          limitRecord: limitRecord,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_MESSAGE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  saveMessage(
    cohort_id,
    user_id,
    meeting_id,
    aws_meeting_id,
    message_type,
    attendees_log,
    message,
    ref_number,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_id: cohort_id,
          user_id: user_id,
          meeting_id: meeting_id,
          aws_meeting_id: aws_meeting_id,
          message_type: message_type,
          attendees_log: attendees_log,
          message: message,
          ref_number: ref_number,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADD_MESSAGE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  deleteMeetingChats(meeting_id, meeting_name: string, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          meeting_id: meeting_id,
          meeting_name: meeting_name,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DELETE_MESSAGE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  getSingleMessage(meeting_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          meeting_id: meeting_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_SINGLE_MESSAGE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  getAllChannelUsers(Ids, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          Ids: Ids,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CHANNEL_USERS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addMeeting(Meetingdata, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(Meetingdata)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADD_MEETING;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getMeetingById(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.LIVE_EVENT_BYID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllMeetings(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MEETING_ALL;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteMeeting(id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MEETING_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async updateMeeting(UpdateMeeting, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(UpdateMeeting)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.MEETING_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async deleteNotification(DeleteMeeting, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(DeleteMeeting)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.DELETE_NOTIFICATION;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllVideoLesson(token) {
    var data = {};
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ALL_VIDEO_MIND;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  versions_getUserByEmail(email) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VERSIONS_GETUSER_BYEMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async startRecording(RecodMeeting) {
    var data = RecodMeeting;
    var url = 'https://teach.dstncert.com/chime/start_recording.php';

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async stopRecording(StopMeeting) {
    var data = StopMeeting;
    var url = 'https://teach.dstncert.com/chime/stop_recording.php';

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getBucketData(MeetingId) {
    var url = 'https://teach.dstncert.com/chime/get_data.php';
    var data = {
      MeetingId: MeetingId,
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async updateRecordings(UpdateMeeting, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(UpdateMeeting)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + 'live_events/update_recordings';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addActivityTracker(user_id, email, cohort_id, type, description) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          email: email,
          cohort_id: cohort_id,
          type: type,
          description: description,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(
          environment.BASE_URL + environment.ADD_ACTIVITY_TRACKER,
          data,
          options,
        )
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }

  addOnbordingData(user_id, cohort_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.ADD_ONBORDING_DATA;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async updateMentorNotes(notes, user_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          notes: notes,
          user_id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.UPDATE_MENTOR_NOTES;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async changeUserEmail(old_email: string, new_email: string, token: string) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          old_email: old_email,
          new_email: new_email,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.CHANGE_USER_EMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllAppVersions(token: string) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_ALL_APP_VERSION;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async updateAllAppVersion(params: any, token: string) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(params)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.UPDATE_APP_VERSION;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async saveComponentTime(
    flashcard_app,
    flashcard_app_revision,
    practice_que_app,
    practice_que_app_revision,
    know_ass_revision,
    practice_test_revision,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          flashcard_app: flashcard_app,
          flashcard_app_revision: flashcard_app_revision,
          practice_que_app: practice_que_app,
          practice_que_app_revision: practice_que_app_revision,
          know_ass_revision: know_ass_revision,
          practice_test_revision: practice_test_revision,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.SAVE_SELF_PACED_STUDY_TIME;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getAllComponentTime(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_SELF_PACED_STUDY_TIME;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async getAllRecordings(token: string) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.GET_ALL_RECORDINGS;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async updateRecordingURL(
    recording_url,
    recording_url_720p,
    recording_url_480p,
    recording_url_360p,
    recording_url_240p,
    recording_url_144p,
    questions,
    id,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          recording_url: recording_url,
          recording_url_720p: recording_url_720p,
          recording_url_480p: recording_url_480p,
          recording_url_360p: recording_url_360p,
          recording_url_240p: recording_url_240p,
          recording_url_144p: recording_url_144p,
          questions: questions,
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.UPDATE_RECORDING_URL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
