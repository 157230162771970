import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class VideolessonService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  //---------------start video lesson-------//
  async GetAllVideoLesson(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VIDEO_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }
  async deleteVideoLesson(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VIDEO_DELETE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async AddVideoLesson(
    video_link,
    video_lesson_link_720p,
    video_lesson_link_480p,
    video_lesson_link_360p,
    video_lesson_link_240p,
    video_lesson_link_144p,
    video_title,
    summary,
    video_thumbnil,
    c_id,
    d_id,
    sd_id,
    topic_id,
    subtopic_ids,
    type,
    transcript_link,
    video_type,
    token,
    point,
    certi_id,
    duration,
    chapters,
    qr_code,
    topic_list,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          subtopic_ids: subtopic_ids,
          video_title: video_title,
          summary: summary,
          video_thumbnil: video_thumbnil,
          video_link: video_link,
          video_lesson_link_720p: video_lesson_link_720p,
          video_lesson_link_480p: video_lesson_link_480p,
          video_lesson_link_360p: video_lesson_link_360p,
          video_lesson_link_240p: video_lesson_link_240p,
          video_lesson_link_144p: video_lesson_link_144p,
          type: type,
          duration: duration,
          video_type: video_type,
          point: point,
          chapters: chapters,
          transcript_link: transcript_link,
          qr_code: qr_code,
          topic_list: topic_list,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VIDEO_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async GetEditVideoLesson(id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VIDEO_BY_ID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateVideoLesson(
    id,
    video_link,
    video_lesson_link_720p,
    video_lesson_link_480p,
    video_lesson_link_360p,
    video_lesson_link_240p,
    video_lesson_link_144p,
    video_title,
    summary,
    video_thumbnil,
    c_id,
    d_id,
    sd_id,
    topic_id,
    subtopic_ids,
    type,
    transcript_link,
    video_type,
    token,
    point,
    certi_id,
    duration,
    chapters,
    qr_code,
    topic_list,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          certi_id: certi_id,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          subtopic_ids: subtopic_ids,
          video_title: video_title,
          summary: summary,
          video_thumbnil: video_thumbnil,
          video_link: video_link,
          video_lesson_link_720p: video_lesson_link_720p,
          video_lesson_link_480p: video_lesson_link_480p,
          video_lesson_link_360p: video_lesson_link_360p,
          video_lesson_link_240p: video_lesson_link_240p,
          video_lesson_link_144p: video_lesson_link_144p,
          type: type,
          duration: duration,
          video_type: video_type,
          point: point,
          chapters: chapters,
          transcript_link: transcript_link,
          qr_code: qr_code,
          topic_list: topic_list,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VIDEO_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateVideoLessonQr(id, qr_code, video_lesson_link, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          qr_code: qr_code,
          video_lesson_link: video_lesson_link,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VIDEO_UPDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async GetTranscriptFile(url) {
    return new Promise((resolve) => {
      this.http.get(url).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
          console.log(err);
        },
      );
    });
  }
  async saveRearrangeVide(videos, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          videos: videos,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.VIDEO_REARRANGE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  //---------------end video lesson-------//
}
