export const environment = {
  production: false,
  ACCESS_KEY:
    '53616c7465645f5f13d88f696384d936a4cb5a7213f7027578fa655f1557834c265865fe3d31eecd21672df60e869a1fe65b17150e88c8cd6dfb6c51ca15885b', //'J64bIC2bMEJp9R8bRH1XxIIeSZ1+V7z2S1PP0L5d',
  KEY_ID:
    '53616c7465645f5f5a165efa7969bced05af609ea1ba6175dce7efdf45d7c46dc74f008aaa4b53f036e691be1b547fb6', //'AKIAWXQ73NHRLQLKIY42',
  UserPoolId:
    '53616c7465645f5fa3d171fdb0710a0990c3ca9095f3216b97be59a13853d5c39758fc9f064e05754597d5c27076c567', //"us-east-2_Fch3dpzG3",
  ClientId:
    '53616c7465645f5fcaca636da6bf2610832b9b527e3fa08f89fab933af9566e5f09efd00b8f9cd304f422bbfe13618bb', //"6nsghkfji3fipnnsh0khjcm1tb",
  // BASE_URL: 'https://api.destcert.com/api/',
  BASE_URL:
    process.env.NG_APP_VERCEL_ENV == 'production'
      ? 'https://api.destcert.com/api/'
      : 'https://api.dev.destcert.com/api/',
  // BASE_URL: 'http://localhost:3000/api/',
  IP_ADDRESS: 'http://api.ipify.org/?format=json',
  COURSE_ADD: 'course/add',
  COURSE: 'course/',
  COURSE_ALL: 'course/all',

  CERTIFICATE_ADD: 'notification/add_certificate',
  CERTIFICATE_UPDATE: 'notification/update_certificate',
  CERTIFICATE: 'certificate/',
  CERTIFICATE_ALL: 'certificate/all',

  DOMAIN_ADD: 'domain/add',
  DOMAIN: 'domain/',
  DOMAIN_ALL: 'domain/all',
  UPDATE_DOMAIN: 'template/update_domain',

  SUB_DOMAIN_ADD: 'subdomain/add',
  SUB_DOMAIN: 'subdomain/',
  SUB_DOMAIN_ALL: 'subdomain/all',

  TOPIC_ADD: 'topic/add',
  TOPIC: 'topic/',
  TOPIC_ALL: 'topic/all',

  SUB_TOPIC_ADD: 'subtopic/add',
  SUB_TOPIC_BY_ID: 'subtopic/byid',
  SUB_TOPIC_ALL: 'subtopic/all',
  SUB_TOPIC_UPDATE: 'subtopic/update',

  FLASHCARD_ADD: 'flashcard/add',
  FLASHCARD: 'flashcard/',
  FLASHCARD_ALL: 'flashcard/all',

  FLASHCARD_APP_AGGREGATE: 'flashcardapp/aggregate',

  STUDY_MATERIAL_ADD: 'studyMaterial/add',
  STUDY_MATERIAL: 'studyMaterial/',
  STUDY_MATERIAL_ALL: 'studyMaterial/all',

  KNOW_ASSES_ADD: 'knowledgeAsses/add',
  KNOW_ASSES: 'knowledgeAsses/',
  KNOW_ASSES_ALL: 'userlogs/allknowass',

  KNOW_ASSES_QUES_ADD: 'knowAssQues/add',
  KNOW_ASSES_QUES: 'knowAssQues/',
  KNOW_ASSES_QUES_ALL: 'knowAssQues/all',
  KNOW_ASSES_REPORT: 'knowassqueans/knowass-report',
  KNOW_ASSES_QUES_BY_ID: 'userlogs/getknowassbyid',

  PRACTICE_TEST_ADD: 'practicetest/add',
  PRACTICE_TEST: 'practicetest/',
  PRACTICE_TEST_ALL: 'userlogs/allpractice',
  PRACTICE_TEST_USER_REPORT: 'practiceans/getuserreportpracticetestAns',

  PRACTICE_QUE_ADD: 'practiceque/add',
  PRACTICE_QUE: 'practiceque/',
  PRACTICE_QUE_ALL: 'practiceque/all',

  PRACTICE_APP_QUE_ADD: 'practiceAppque/add',
  PRACTICE_APP_QUE: 'practiceAppque/',
  PRACTICE_APP_QUE_ALL: 'practiceAppque/all',
  FEEDBACKLIST: 'userlogs/practiceappfeedback',
  FEEDBACK_DETELE: 'userlogs/deletepracticefeedback',
  FEEDBACK_UPDATE: 'userlogs/updatepracticefeedback',

  COHORT_ADD: 'template/addcohort',
  COHORT: 'cohort/',
  COHORT_ALL: 'userlogs/cohortall',
  COHORT_UPDATE: 'userlogs/updatecohort',
  COHORT_TEMPLATE_UPDATE: 'template/updatecohorttemp',
  ALL_COHORT: 'cohort/all',
  STUDENT_ADD_MULTIPLE_COHORT: 'client/admin_add_strudent_cohort',
  STUDENT_REPORT: 'testlogs/studentreport',
  COHORT_BY_IDS: 'testlogs/cohortbyIds',
  ACTIVITY_TRACKER: 'testlogs/activity_tracker',
  CLIENT_COHORTREPORT: 'client/cohortreport',

  TEMPLATE_ALL: 'template/all',
  TEMPLATE_DELETE: 'template/delete',
  TEMPLATE_ADD: 'template/add',
  TEMPLATE_BY_ID: 'template/byid',
  TEMPLATE_UPDATE: 'template/update',
  UPDATE_COHORT_VIDEO_MIND: 'template/update_cohort_videomind',

  USERS_ALL: 'users/all',
  USERS_FILTER: 'users/filter',
  USERS: 'users/',
  USERS_ADD: 'users/add',
  USER_LIMIT: 'userlogs/alluser',
  USER_LIMIT_COHORT: 'points/alluserforcohortadd',

  CAT_TEST_ADD: 'catexamtest/add',
  CAT_TEST: 'catexamtest/',
  CAT_TEST_ALL: 'userlogs/allcatexams',
  CAT_USER_REPORT: 'catexamans/getuserreportketexamtestAns',

  CAT_QUES_ADD: 'catexamque/add',
  CAT_QUES: 'catexamque/',
  CAT_QUES_ALL: 'catexamque/all',

  LIVE_TEST_ADD: 'live/liveadd',
  LIVE_TEST_ALL: 'live/liveall',
  LIVE_TEST_UPDATE: 'live/liveupdate',
  LIVE_TEST_DELETE: 'live/livedelete',
  LIVE_TEST_REPORT: 'live/testreport',
  LIVE_SEND_LINK: 'live/sendlink',

  LIVE_QUES_ADD: 'live/livequesadd',
  LIVE_QUES_LIST: 'live/livebyid',
  LIVE_QUES_UPDATE: 'live/livequesupdate',
  LIVE_QUES_DELETE: 'live/livequesdelete',

  ACRONYMS_ALL: 'acronyms/all',
  ACRONYMS_ADD: 'acronyms/add',
  ACRONYMS_UPDATE: 'acronyms/update',
  ACRONYMS_DELETE: 'acronyms/delete',
  ACRONYMS_BY_ID: 'acronyms/byid',

  COMPANY_ALL: 'userlogs/allcompany',
  COMPANY_ADD: 'adminlogs/addcompany',
  COMPANY_UPDATE: 'adminlogs/updatecompany',
  COMPANY_DELETE: 'adminlogs/deletecompany',

  VIDEO_ADD: 'video/add',
  VIDEO_ALL: 'video/all',
  VIDEO_DELETE: 'video/delete',
  VIDEO_BY_ID: 'video/byid',
  VIDEO_UPDATE: 'video/update',
  VIDEO_REARRANGE: 'video/save_rearrange',

  SCHEDULE_ADD: 'scheduletime/add',
  SCHEDULE_ALL: 'scheduletime/all',
  SCHEDULE_DELETE: 'scheduletime/delete',
  SCHEDULE_UPDATE: 'scheduletime/update',

  IMPORT_JSON: 'importjson',
  BULK_STUDENT_TRANSCTION: 'support/bulk_student',
  SEND_MAIL: 'send-email',
  LOGIN_LOGS_BY: 'loginlogs/by',
  TEST_LOGS_HISTORY: 'testlogs/history',
  ADMIN_LOGS_ADD: 'adminlogs/add',
  ADMIN_LOGS_ALL: 'adminlogs/all',
  ADMIN_ALL: 'adminlogs/alladmin',
  GET_LOGS: 'userlogs/by',

  CLIENT_LOGS_ALL: 'clientlogs/all',
  USER_LOGS_ALL_DROPDOWN: 'userlogs/alldropdown',
  ALL_SCORE: 'userlogs/allscore',
  SAVE_SCORE: 'userlogs/savescores',
  ALL_EXAMS: 'userlogs/allexams',
  ALL_VIDEO_MIND: 'userlogs/all_video_mind',

  CLIENT_COUNT_DASH: 'client/countdash',
  CLIENT_ALL: 'client/allclient',
  CLIENT_LIMIT: 'adminlogs/allclient',
  CLIENT_COHORT_ASSIGN: 'client/addclientcohort',
  CLIENT_COHORT_EMPTY: 'client/emptyclientcohort',
  CLIENT_COHORT_REMOVE: 'client/deleteclientcohort',
  CLIENT_COHORT_DETAIL_VIEW: 'client/cohortdetailview',
  RESET_PROGRESS: 'testlogs/reset_progress_by_class',

  TEACHER_LIMIT: 'adminlogs/allteacher',
  TEACHER_ADD: 'adminlogs/addteacher',
  TEACHER_UPDATE: 'adminlogs/updateteacher',

  VARIABLE_ALL: 'varialbe/all',
  VARIABLE_ADD: 'varialbe/add',
  VARIABLE_UPDATE: 'varialbe/update',
  VARIABLE_DELETE: 'varialbe/delete',
  GET_ALL_APP_VERSION: 'varialbe/get_all_app_version',
  UPDATE_APP_VERSION: 'varialbe/update_app_version',
  CERTIWISE_MATERIAL: 'varialbe/certificate_materials',

  EMAIL_TEMPLATE_ALL: 'emailtemplate/all',
  EMAIL_TEMPLATE_ADD: 'emailtemplate/add',
  EMAIL_TEMPLATE_UPDATE: 'emailtemplate/update',
  EMAIL_TEMPLATE_DELETE: 'emailtemplate/delete',
  GET_USER_FROM_COHORT: 'emailtemplate/getstudentbycohort',
  GET_PAYMENT_TRANSACTIONS: 'emailtemplate/get_payment_transactions',

  REFERENCE_ALL: 'reference/all',
  REFERENCE_ADD: 'reference/add',
  REFERENCE_UPDATE: 'reference/update',
  REFERENCE_DELETE: 'reference/delete',
  REFERENCE_BY_BOOK: 'reference/ref_by_book',

  MIND_MAP_ALL: 'mindmap/all',
  MIND_MAP_DELETE: 'mindmap/delete',
  MIND_MAP_ADD: 'mindmap/add',
  MIND_MAP_UPDATE: 'mindmap/update',
  MINDMAP_REARRANGE: 'mindmap/save_rearrange',

  SUPPORT_ALL: 'support/all',
  SUPPORT_DELETE: 'support/delete',

  BOOK_ALL: 'adminlogs/allbooks',
  BOOK_ADD: 'adminlogs/addbooks',
  BOOK_UPDATE: 'adminlogs/updatebooks',
  BOOK_DELETE: 'adminlogs/deletebook',

  AUTHY_REGISTER: 'authy/register',
  CREATE_MEETING: 'authy/create_meeting',
  DELETE_CHIME_MEETING: 'authy/delete_meeting',
  LIVE_EVENT_ADD: 'live_events/add',
  LIVE_EVENT_Update: 'live_events/update',
  LIVE_EVENT_DELETE: 'live_events/delete',
  GET_ATTENDY: 'live_events/get_attendy',
  GET_ATTENDY_SELFPACED: 'live_events/get_attendy_selfpaced',
  LIVE_EVENT_BYID: 'live_events/byid',
  ADD_RECORDING: 'live_events/add_recording',
  ALL_LIVE_EVENT_ADD: 'live_events/all_live_event_add',
  ALL_LIVE_EVENT_UPDATE: 'live_events/all_live_event_update',
  ALL_LIVE_EVENT_START_TIME_EVENT:
    'live_events/all_live_event_Start_time_event',

  LIVE_EVENT_ALL: 'live_events/all',
  SELF_ALL_DROPDOWN: 'self_paced/alldropdown',
  SELF_DELETE: 'self_paced/delete',
  DELETE_SELF_CLASS: 'self_paced/delete_self_class',
  SELF_UPDATE: 'self_paced/update',
  SELF_ALL: 'self_paced/all',
  SELF_SUB_UPDATE: 'self_paced/update_subscription',
  SELF_ONE_UPDATE: 'self_paced/update_one_time',
  SELF_ALL_MATERIALS: 'self_paced/get_all_materials',
  SELF_SAVE_MATERIALS: 'self_paced/save_materials',
  SELF_GET_SAVE_MATERIALS: 'self_paced/get_saved_materials',
  MENTOR_ALL: 'self_paced/get_all_mentor',
  SELF_SUMMARY: 'self_paced/get_selfpaced_summary_report',

  ADD_NOTIFICATION: 'notification/add',
  DELETE_NOTIFICATION: 'notification/delete',
  FAILED_EMAIL: 'notification/all_failed_emails',
  SUCCESS_EMAIL: 'notification/all_success_emails',
  DELETE_FAILED_EMAIL: 'notification/delete_failed_emails',
  GET_DOMAIN_SEQUENCE: 'notification/get_domain_sequence',
  ADD_FAILED_EMAIL: 'authy/add_failed_emails',
  SELFPACED_CLASSES: 'self_paced/selfpaced_classes',
  ADD_SELFCOHORT_STD: 'authy/add_selfcohort_student',
  SAVE_USER_ACCESS_DURATION: 'authy/save_access_duration',
  ENROLL_DETAILS: 'self_paced/enrollments_detail',
  REMOVE_ENROLL: 'self_paced/remove_enrollments',
  PAY_TRN_BYID: 'self_paced/pay_trn_byid',
  PAYMENT_TRANSACTION: 'authy/add_payment_transaction',
  UPDATE_PAY_TRN: 'authy/update_payment_transaction',
  GET_MEETING: 'authy/get_meeting',
  GET_MESSAGE: 'meeting/get_message',
  ADD_MESSAGE: 'meeting/save_message',
  DELETE_MESSAGE: 'meeting/delete_meeting_chats',
  GET_SINGLE_MESSAGE: 'meeting/get_single_message',
  CHANNEL_USERS: 'chatting/get_channel_users',
  ADD_MEETING: 'meeting/add',
  MEETING_BYID: 'meeting/byid',
  MEETING_ALL: 'meeting/all',
  MEETING_UPDATE: 'meeting/update',
  MEETING_DELETE: 'meeting/delete',
  UPLOAD_FILE: 'chatting/upload_file',
  UPLOAD_LARGE_FILE: 'chatting/upload_largefile',
  UPLOAD_LARGE_FILE_COMPLE: 'chatting/upload_largefile_complete',

  VERSIONS_GETUSER_BYEMAIL: 'authy/getUserByEmail',
  ADD_ACTIVITY_TRACKER: 'authy/add_activity_tracker',
  ADD_ONBORDING_DATA: 'authy/add_onbording',

  ADD_SHORTURL: 'authy/addshorturl',
  GETALL_SHORTURL: 'authy/getallShortUrl',
  SHORTURL_UPDATE: 'authy/ShortUrlupdate',
  SHORTURL_DELETE: 'authy/ShortUrldelete',

  HYBRID_ALL_MATERIALS: 'self_paced/get_all_hybrid_materials',
  HYBRID_TEMPLATE_DELETE: 'template/hybridtemplatedelete',
  HYBRID_TEMPLATE_ADD: 'template/hybrid_add',
  HYBRID_TEMPLATE_ALL: 'template/hybrid_all',
  HYBRID_TEMPLATE_BY_ID: 'template/hybrid_byid',
  HYBRID_TEMPLATE_UPDATE: 'template/hybrid_update',
  HYBRID_GET_SAVE_SCHEDULE: 'template/hybrid_get_save_schedule',

  HYBRIDCLASS_ALL: 'userlogs/hybrid_class_all',
  HYBRID_CLASS_GET_SAVE_SCHEDULE: 'userlogs/hybrid_class_get_save_schedule',
  HYBRID_CLASS_DELETE: 'userlogs/hybrid_class_delete',
  HYBRID_COHORT_ADD: 'userlogs/hybrid_addcohort',
  HYBRID_COHORT_UPDATE: 'userlogs/hybrid_updatecohort',

  GET_COHORT_WISE_USER_ALLEXAM: 'practiceans/getcohortsuserallexam',

  PRECTICEANS_GETDOMAIN_REPORT: 'practiceans/getdomainreportpracticetestans',
  PRECTICEANS_FILTERS_QUEST: 'practiceans/filtertestques',

  KNOWASSANS_FILTERS_QUEST: 'knowassque/filter-ques',
  KNOWASSANS_GETDOMAIN_REPORT: 'knowassqueans/domain-report',
  KNOWASSANS_ASS_REPORT: 'knowassqueans/ass-report',
  MINI_MASTER_ALL: 'mini_masterclass/all',
  MINI_MASTER_ADD: 'mini_masterclass/add',
  MINI_MASTER_UPDATE: 'mini_masterclass/update',
  MINI_MASTER_DELETE: 'mini_masterclass/delete',
  SELF_SUMM_USER: 'adminlogs/alluser',
  UPDATE_MENTOR_NOTES: 'self_paced/update_mentor_notes',
  CHANGE_USER_EMAIL: 'emailtemplate/change_user_email',
  SAVE_SELF_PACED_STUDY_TIME: 'self_paced/add_selfpaced_hours',
  GET_SELF_PACED_STUDY_TIME: 'self_paced/selfpaced_times',
  GET_ALL_RECORDINGS: 'live_events/get_all_event_recordings',
  UPDATE_RECORDING_URL: 'live_events/update_event_recordings_url',

  // hardcoded urls - are they being used?
  // 'live_events/update_recordings'
  // 'live_events/event_byids'
};
