import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { CourseService } from '../../services/course/course.service';
import { DomainService } from '../../services/domain/domain.service';
@Component({
  selector: 'app-payment-transactions',
  templateUrl: './payment-transactions.component.html',
  styleUrls: ['./payment-transactions.component.scss'],
})
export class PaymentTransactionsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'email',
    'name',
    'last_name',
    'amount',
    'product_purchased',
    'createdAt',
  ];
  dataSource: MatTableDataSource<Domainlist>;
  Search: any;

  id: any;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  loglist = [];

  constructor(
    public domainService: DomainService,
    public courseService: CourseService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  async ngOnInit() {
    await this.spinner.show();
    try {
      await this.getAllPaymentTransactions();
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }

  async getAllPaymentTransactions() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Domainlist[] = [];
        await this.service
          .getAllPaymentTransactions(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                if (element.email) {
                  srno = srno + 1;
                  await users.push({
                    srno: srno,
                    id: element.id,
                    user_id: element.user_id,
                    amount: element.amount,
                    createdAt: element.createdAt,
                    email: element.email,
                    aliasname: element.aliasname,
                    name: element.first_name
                      ? element.first_name
                      : element.name,
                    last_name: element.last_name,
                    product_purchased: element.product_purchased,
                  });
                }
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
              this.pagination = true;
            }
          });
      }
    });
  }

  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
}
export interface Domainlist {
  srno: number;
  id: number;
  user_id: number;
  amount: string;
  createdAt: string;
  email: string;
  aliasname: string;
  name: string;
  last_name: string;
  product_purchased: string;
}
