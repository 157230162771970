<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Live Events</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Event Name"
          name="search"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="AddEvent()">Add Event</button>
    </div>
    <div class="pagesection">
      <div class="mat-elevation-z8">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          style="border-spacing: 0 10px"
        >
          <ng-container matColumnDef="certificate_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Certification
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.certificate_name }}">{{
                element.certificate_name
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="event_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Event Name
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                title="{{ element.event_name ? element.event_name : '-' }}"
                >{{ element.event_name ? element.event_name : '-' }}</span
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="event_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Event Type
            </th>
            <td mat-cell *matCellDef="let element">
              <span title="{{ element.event_type }}">{{
                element.event_type
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="next_event">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Next Event
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                title="{{ element.next_event ? element.next_event : '-' }}"
                >{{ element.next_event ? element.next_event : '-' }}</span
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" class="icons">
              <img
                src="../../../assets/images/edit-1.png"
                (click)="editEvent(row)"
                alt="Edit"
                class="action-icon"
              />
              <img
                src="../../../assets/images/remove.png"
                (click)="deleteEvent(row)"
                alt="Delete"
                class="action-icon"
              />
              <img
                src="../../../assets/images/Play.png"
                (click)="startMeetingSingleAttendee(row)"
                alt="Start Meeting"
                class="action-icon"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      <mat-paginator
        style-paginator
        showFirstLastButtons
        [showTotalPages]="3"
        *ngIf="pagination"
        [length]="dataSource.data ? dataSource.data.length : 0"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50, 100, 500]"
      >
      </mat-paginator>
    </div>
  </div>
</div>

<div class="cutome-modal" *ngIf="EditEventpopup">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p (click)="testing()">{{ SetEvent }} Event</p>

      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <form [formGroup]="addeventform">
      <div class="modal-body">
        <div class="example-form">
          <div class="formwrapper">
            <div>
              <div class="select-box">
                <select
                  [(ngModel)]="SelectedCertificate"
                  formControlName="CertificateName"
                  [ngClass]="{
                    err:
                      addeventform.controls['CertificateName'].hasError(
                        'required'
                      ) &&
                      (addeventform.controls['CertificateName'].dirty ||
                        addeventform.controls['CertificateName'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100">
              <input
                type="text"
                [(ngModel)]="Event_Name"
                formControlName="EventName"
                placeholder="Event Name"
                [ngClass]="{
                  err:
                    addeventform.controls['EventName'].hasError('required') &&
                    (addeventform.controls['EventName'].dirty ||
                      addeventform.controls['EventName'].touched)
                }"
              />
            </div>
            <div class="w-100">
              <div class="select-box">
                <select
                  [(ngModel)]="AlterNativeSoftware"
                  formControlName="AlterNative"
                  (change)="ChangeSoftWare($event)"
                  [ngClass]="{
                    err:
                      addeventform.controls['AlterNative'].hasError(
                        'required'
                      ) &&
                      (addeventform.controls['AlterNative'].dirty ||
                        addeventform.controls['AlterNative'].touched)
                  }"
                >
                  <option value="1">Alternate Meeting Software</option>
                  <option value="2">Destination meeting system</option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100" *ngIf="!linkupdate">
              <div class="course-name">
                <div class="course-input">
                  <div class="check-app">
                    <label class="container">
                      Record event
                      <input
                        type="checkbox"
                        formControlName="RecordEvent"
                        [(ngModel)]="RecordEvent"
                        (change)="changeEvent()"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">
                      Automatically make recording available
                      <input
                        type="checkbox"
                        formControlName="AutomaticallyRecode"
                        [(ngModel)]="AutomaticallyRecode"
                        [attr.disabled]="!RecordEvent ? true : null"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex2" *ngIf="linkupdate">
              <input
                type="text"
                [(ngModel)]="Event_Link"
                formControlName="EventLink"
                placeholder="Event Link"
                [ngClass]="{
                  err:
                    addeventform.controls['EventLink'].hasError('required') &&
                    (addeventform.controls['EventLink'].dirty ||
                      addeventform.controls['EventLink'].touched)
                }"
              />
              <span>
                <mat-icon (click)="generateLink()">link</mat-icon>
              </span>
            </div>
            <div class="d-flex3">
              <div class="date-input">
                <div>
                  <mat-form-field appearance="fill">
                    <input
                      matInput
                      [matDatepicker]="picker"
                      [disabled]="true"
                      placeholder="Start Date"
                      formControlName="Start_Date"
                      [min]="todayDate"
                      [ngClass]="{
                        err:
                          addeventform.controls['Start_Date'].hasError(
                            'required'
                          ) &&
                          (addeventform.controls['Start_Date'].dirty ||
                            addeventform.controls['Start_Date'].touched)
                      }"
                      style="padding: 12px"
                      placeholder="Start Date"
                      [(ngModel)]="Start_Date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                  </mat-form-field>
                  <span>
                    <img
                      src="../../../assets/images/Calendar.svg"
                      alt="Calendar"
                      class="calendar-icon"
                    />
                  </span>
                </div>
              </div>
              <div
                class="date-time custom_time"
                [ngClass]="{
                  err:
                    addeventform.controls['StartTime'].hasError('required') &&
                    (addeventform.controls['StartTime'].dirty ||
                      addeventform.controls['StartTime'].touched)
                }"
              >
                <input
                  type="text"
                  [(ngModel)]="Temp_Start_Time"
                  formControlName="TempStartTime"
                  [disabled]
                />
                <mat-icon>schedule</mat-icon>
                <input
                  type="time"
                  class="my_time"
                  [(ngModel)]="Start_Time"
                  formControlName="StartTime"
                  (change)="StartTimeChange()"
                />
              </div>
              <div
                class="date-time custom_time"
                [ngClass]="{
                  err:
                    addeventform.controls['EndTime'].hasError('required') &&
                    (addeventform.controls['EndTime'].dirty ||
                      addeventform.controls['EndTime'].touched)
                }"
              >
                <input
                  type="text"
                  [(ngModel)]="Temp_End_Time"
                  formControlName="TempEndTime"
                  [disabled]
                />
                <mat-icon>schedule</mat-icon>
                <input
                  type="time"
                  class="my_time"
                  [(ngModel)]="End_Time"
                  formControlName="EndTime"
                  (change)="EndTimeChange()"
                  [ngClass]="{
                    err:
                      addeventform.controls['EndTime'].hasError('required') &&
                      (addeventform.controls['EndTime'].dirty ||
                        addeventform.controls['EndTime'].touched)
                  }"
                />
              </div>
            </div>
            <div class="w-100">
              <ng-moment-timezone-picker
                formControlName="time_zone"
                customPlaceholderText="Time Zone"
                [(ngModel)]="time_zone"
              >
              </ng-moment-timezone-picker>
            </div>

            <div class="w-100">
              <div class="select-box">
                <select
                  [(ngModel)]="Is_Repeat"
                  formControlName="IsRepeat"
                  [ngClass]="{
                    err:
                      addeventform.controls['IsRepeat'].hasError('required') &&
                      (addeventform.controls['IsRepeat'].dirty ||
                        addeventform.controls['IsRepeat'].touched)
                  }"
                  (change)="changerRepeatValue($event)"
                >
                  <option value="1">Recurring</option>
                  <option value="2">Does not repeat</option>
                </select>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="NotAddpopup">
          <div class="every-day">
            <div class="repeat-every">
              <label for="name">Repeat Every</label>
              <div class="repeat-week">
                <input
                  type="number"
                  min="0"
                  [(ngModel)]="Repeat_Count"
                  formControlName="RepeatCount"
                  [ngClass]="{
                    err:
                      addeventform.controls['RepeatCount'].hasError(
                        'required'
                      ) &&
                      (addeventform.controls['RepeatCount'].dirty ||
                        addeventform.controls['RepeatCount'].touched)
                  }"
                />
                <select
                  [(ngModel)]="Repeat_Type"
                  formControlName="RepeatType"
                  [ngClass]="{
                    err:
                      addeventform.controls['RepeatType'].hasError(
                        'required'
                      ) &&
                      (addeventform.controls['RepeatType'].dirty ||
                        addeventform.controls['RepeatType'].touched)
                  }"
                >
                  <option value="Day">Day</option>
                  <option value="Week">Week</option>
                  <option value="Month">Month</option>
                  <option value="Year">Year</option>
                </select>
              </div>
            </div>
            <div class="repeat-on">
              <label for="name">Repeat On</label>
              <div class="all-day">
                <button
                  (click)="setRepeatOn('sun')"
                  [ngClass]="{ active: sun == 1 }"
                >
                  S
                </button>
                <button
                  (click)="setRepeatOn('mon')"
                  [ngClass]="{ active: mon == 1 }"
                >
                  M
                </button>
                <button
                  (click)="setRepeatOn('tues')"
                  [ngClass]="{ active: tues == 1 }"
                >
                  T
                </button>
                <button
                  (click)="setRepeatOn('wed')"
                  [ngClass]="{ active: wed == 1 }"
                >
                  W
                </button>
                <button
                  (click)="setRepeatOn('thurs')"
                  [ngClass]="{ active: thurs == 1 }"
                >
                  T
                </button>
                <button
                  (click)="setRepeatOn('fri')"
                  [ngClass]="{ active: fri == 1 }"
                >
                  F
                </button>
                <button
                  (click)="setRepeatOn('sat')"
                  [ngClass]="{ active: sat == 1 }"
                >
                  S
                </button>
              </div>
            </div>
          </div>
          <div class="select-date">
            <span class="select-title">Ends</span>
            <div class="naver">
              <div class="select-radio">
                <p>
                  <input
                    id="Never"
                    type="radio"
                    value="Never"
                    name="Ends_Type"
                    [(ngModel)]="Ends_Type"
                    formControlName="Ends_Type"
                  />
                  <label class="custom-control-label" for="Never">Never</label>
                </p>
              </div>

              <div class="select-radio">
                <p>
                  <input
                    id="On"
                    type="radio"
                    value="On"
                    name="Ends_Type"
                    [(ngModel)]="Ends_Type"
                    formControlName="Ends_Type"
                  />
                  <label class="custom-control-label" for="On">On</label>
                </p>
                <div class="select-datetype">
                  <div class="date-input1">
                    <mat-form-field appearance="fill">
                      <input
                        matInput
                        [matDatepicker]="picker1"
                        [min]="todayDate | date: 'yyyy-MM-dd'"
                        [(ngModel)]="ends_on"
                        formControlName="ends_on"
                        style="margin-left: 10px"
                        disabled
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker
                        #picker1
                        [disabled]="Ends_Type == 'Never'"
                      ></mat-datepicker>
                    </mat-form-field>
                    <span>
                      <img
                        src="../../../assets/images/Calendar.svg"
                        alt="Calendar"
                        class="calendar-icon"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div class="select-radio">
                <p>
                  <input
                    id="After"
                    type="radio"
                    value="After"
                    name="Ends_Type"
                    [(ngModel)]="Ends_Type"
                    formControlName="Ends_Type"
                  />
                  <label class="custom-control-label" for="After">After</label>
                </p>
                <span class="occurance">
                  {{ total_occurances }} Occurances
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <button class="submit-btn" (click)="saveModal()">Save</button>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
