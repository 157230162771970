import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/service/authorization.service';
import { courselist } from '../courses/courses.component';
import { MiniMasterService } from '../../services/miniMaster/mini-master.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesService } from 'src/app/services.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SelfpacedService } from 'src/app/services/selfpaced/selfpaced.service';
import { KnowledgeService } from 'src/app/services/knowledge/knowledge.service';
import { SelectionModel } from '@angular/cdk/collections';
import { PracticeService } from 'src/app/services/practice/practice.service';

@Component({
  selector: 'app-mini-master-class',
  templateUrl: './mini-master-class.component.html',
  styleUrls: ['./mini-master-class.component.scss'],
})
export class MiniMasterClassComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'class_name',
    'certificate_name',
    'enrollments',
    'actions',
  ];
  Search: any;
  addClass: boolean = false;
  IsUpdate: boolean = false;
  EditId: any;
  enroll_email_template: any;
  class_name: any;
  class_url: any;
  certi_id: any;
  class1_display_name: any;
  class1_video_url: any;
  class1_video_url_144p: any;
  class1_video_url_240p: any;
  class1_video_url_360p: any;
  class1_video_url_480p: any;
  class1_video_url_720p: any;
  class1_thumbnail_url: any;
  class1_material_url: any;
  class1_material_name: any;
  class1_video_next_button: any;
  class1_video_complete_email: any;
  class1_video_not_complete_email: any;
  class1_ka_name: any;
  class1_ka_next_button: any;
  class1_ka_complete_email: any;
  class1_ka_not_complete_email: any;
  class2_display_name: any;
  class2_video_url: any;
  class2_video_url_144p: any;
  class2_video_url_240p: any;
  class2_video_url_360p: any;
  class2_video_url_480p: any;
  class2_video_url_720p: any;
  class2_thumbnail_url: any;
  class2_material_name: any;
  class2_material_url: any;
  class2_video_next_button: any;
  class2_video_complete_email: any;
  class2_video_not_complete_email: any;
  class2_pt_name: any;
  class2_pt_next_button: any;
  class2_pt_complete_email: any;
  class2_pt_not_complete_email: any;
  class3_display_name: any;
  class3_video_url: any;
  class3_video_url_144p: any;
  class3_video_url_240p: any;
  class3_video_url_360p: any;
  class3_video_url_480p: any;
  class3_video_url_720p: any;
  class3_thumbnail_url: any;
  class3_material_url: any;
  class3_material_name: any;
  class3_video_next_button: any;
  class3_video_complete_email: any;
  class3_video_not_complete_email: any;
  offer_link: any;

  allCertificate = [];
  allTemplates = [];
  logsList = [];
  KaQuestions = [];
  PtQuestions = [];
  selectionKA = new SelectionModel<kaQuestionClass>(true, []);
  selectionPT = new SelectionModel<ptQuestionClass>(true, []);

  public miniMasterForm: FormGroup;
  dataSource: MatTableDataSource<miniMasterClass>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input('pagination') pagination: boolean;

  dataSourceQuestionKA: MatTableDataSource<kaQuestionClass>;
  @Input('matpagination') matpagination: boolean;
  @ViewChildren(MatPaginator) set matquePaginator(
    quespaginator: QueryList<MatPaginator>,
  ) {
    if (this.matpagination) {
      this.dataSourceQuestionKA.paginator = quespaginator.toArray()[1];
    }
  }
  @ViewChildren(MatPaginator) quespaginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortquestion = new QueryList<MatSort>();
  displayedColumnsQuestionKA: string[] = [
    'checkbox',
    'srno',
    'question',
    'actions',
  ];

  dataSourceQuestionPT: MatTableDataSource<ptQuestionClass>;
  @Input('matpaginationpt') matpaginationpt: boolean;
  @ViewChildren(MatPaginator) set matquePaginatorpt(
    quespaginatorpt: QueryList<MatPaginator>,
  ) {
    if (this.matpaginationpt) {
      this.dataSourceQuestionPT.paginator = quespaginatorpt.toArray()[2];
    }
  }
  @ViewChildren(MatPaginator) quespaginatorpt = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortquestionpt = new QueryList<MatSort>();
  displayedColumnsQuestionPT: string[] = [
    'checkbox',
    'srno',
    'question',
    'actions',
  ];

  constructor(
    public practiceService: PracticeService,
    public knowledgeService: KnowledgeService,
    public sefpaceService: SelfpacedService,
    public service: ServicesService,
    public miniMasterService: MiniMasterService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {}
  ckeConfig = {
    allowedContent: true,
    height: '100vh',
    toolbar: [
      {
        name: 'paragraph',
        items: [
          'Source',
          'Undo',
          'Redo',
          'RemoveFormat',
          'heading',
          'Format',
          '|',
          'FontSize',
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Font',
          'TextColor',
          'BGColor',
          'Link',
          'CreateDiv',
          'Blockquote',
          'Subscript',
          'Superscript',
          'SpecialChar',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          'Outdent',
          'Indent',
          'PageBreak',
          'NumberedList',
          'BulletedList',
          'Image',
          'Table',
          'HorizontalRule',
          'Maximize',
        ],
      },
    ],
  };
  ngOnInit(): void {
    this.miniMasterForm = this.fb.group({
      class_name: ['', Validators.required],
      class_url: ['', Validators.required],
      enroll_email_template: ['', Validators.required],
      certi_id: ['', Validators.required],
      class1_display_name: ['', Validators.required],
      class1_video_url: ['', Validators.required],
      class1_video_url_144p: [''],
      class1_video_url_240p: [''],
      class1_video_url_360p: [''],
      class1_video_url_480p: [''],
      class1_video_url_720p: [''],
      class1_thumbnail_url: ['', Validators.required],
      class1_material_url: ['', Validators.required],
      class1_material_name: ['', Validators.required],
      class1_video_next_button: ['', Validators.required],
      class1_video_complete_email: ['', Validators.required],
      class1_ka_name: ['', Validators.required],
      class1_ka_next_button: ['', Validators.required],
      class1_ka_complete_email: ['', Validators.required],
      class2_display_name: ['', Validators.required],
      class2_video_url: ['', Validators.required],
      class2_video_url_144p: [''],
      class2_video_url_240p: [''],
      class2_video_url_360p: [''],
      class2_video_url_480p: [''],
      class2_video_url_720p: [''],
      class2_thumbnail_url: ['', Validators.required],
      class2_material_name: ['', Validators.required],
      class2_material_url: ['', Validators.required],
      class2_video_next_button: ['', Validators.required],
      class2_video_complete_email: ['', Validators.required],
      class2_pt_name: ['', Validators.required],
      class2_pt_next_button: ['', Validators.required],
      class2_pt_complete_email: ['', Validators.required],
      class3_display_name: ['', Validators.required],
      class3_video_url: ['', Validators.required],
      class3_video_url_144p: [''],
      class3_video_url_240p: [''],
      class3_video_url_360p: [''],
      class3_video_url_480p: [''],
      class3_video_url_720p: [''],
      class3_thumbnail_url: ['', Validators.required],
      class3_material_url: ['', Validators.required],
      class3_material_name: ['', Validators.required],
      class3_video_next_button: ['', Validators.required],
      class3_video_complete_email: ['', Validators.required],
      offer_link: ['', Validators.required],
    });
    this.spinner.show();
    this.listTemplate();
    this.getAllMiniMasterClass();
    this.ListCertificate();
    this.getAllKaQuestions();
    this.getAllPtQuestions();
    this.spinner.hide();
  }
  filter() {}
  randomString() {
    var chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let ids = '';
    for (let id = 0; id < 7; id++) {
      ids += chars[Math.floor(Math.random() * chars.length)];
    }
    return ids;
  }
  addNewClass() {
    this.addClass = true;
    this.IsUpdate = false;
    this.class_url = this.randomString();
  }
  closeModal() {
    this.addClass = false;
    this.IsUpdate = false;
    this.EditId = '';
    this.class_name = '';
    this.enroll_email_template = '';
    this.class_url = '';
    this.certi_id = '';
    this.class1_display_name = '';
    this.class1_video_url = '';
    this.class1_video_url_144p = '';
    this.class1_video_url_240p = '';
    this.class1_video_url_360p = '';
    this.class1_video_url_480p = '';
    this.class1_video_url_720p = '';
    this.class1_thumbnail_url = '';
    this.class1_material_url = '';
    this.class1_material_name = '';
    this.class1_video_next_button = '';
    this.class1_video_complete_email = '';
    this.class1_video_not_complete_email = '';
    this.class1_ka_name = '';
    this.class1_ka_next_button = '';
    this.class1_ka_complete_email = '';
    this.class1_ka_not_complete_email = '';
    this.class2_display_name = '';
    this.class2_video_url = '';
    this.class2_video_url_144p = '';
    this.class2_video_url_240p = '';
    this.class2_video_url_360p = '';
    this.class2_video_url_480p = '';
    this.class2_video_url_720p = '';
    this.class2_thumbnail_url = '';
    this.class2_material_name = '';
    this.class2_material_url = '';
    this.class2_video_next_button = '';
    this.class2_video_complete_email = '';
    this.class2_video_not_complete_email = '';
    this.class2_pt_name = '';
    this.class2_pt_next_button = '';
    this.class2_pt_complete_email = '';
    this.class2_pt_not_complete_email = '';
    this.class3_display_name = '';
    this.class3_video_url = '';
    this.class3_video_url_144p = '';
    this.class3_video_url_240p = '';
    this.class3_video_url_360p = '';
    this.class3_video_url_480p = '';
    this.class3_video_url_720p = '';
    this.class3_thumbnail_url = '';
    this.class3_material_url = '';
    this.class3_material_name = '';
    this.class3_video_next_button = '';
    this.class3_video_complete_email = '';
    this.class3_video_not_complete_email = '';
    this.offer_link = '';
    this.selectionKA = new SelectionModel<kaQuestionClass>(true, []);
    this.selectionPT = new SelectionModel<ptQuestionClass>(true, []);
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async getAllMiniMasterClass() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: miniMasterClass[] = [];
            await this.miniMasterService
              .getAllMiniMasterClass(token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  var srno = 0;
                  await list.data.forEach(async (element) => {
                    srno = srno + 1;
                    await users.push({
                      srno: srno,
                      id: element.id,
                      enroll_email_template: element.enroll_email_template,
                      class_name: element.class_name,
                      class_url: element.class_url,
                      certi_id: element.certi_id,
                      certificate_name: element.certificate.certificate_name,
                      class1_display_name: element.class1_display_name,
                      class1_video_url: element.class1_video_url,
                      class1_video_url_144p: element.class1_video_url_144p,
                      class1_video_url_240p: element.class1_video_url_240p,
                      class1_video_url_360p: element.class1_video_url_360p,
                      class1_video_url_480p: element.class1_video_url_480p,
                      class1_video_url_720p: element.class1_video_url_720p,
                      class1_thumbnail_url: element.class1_thumbnail_url,
                      class1_material_url: element.class1_material_url,
                      class1_material_name: element.class1_material_name,
                      class1_video_next_button:
                        element.class1_video_next_button,
                      class1_video_complete_email:
                        element.class1_video_complete_email,
                      class1_video_not_complete_email:
                        element.class1_video_not_complete_email,
                      class1_ka_name: element.class1_ka_name,
                      class1_ka_next_button: element.class1_ka_next_button,
                      class1_ka_complete_email:
                        element.class1_ka_complete_email,
                      class1_ka_not_complete_email:
                        element.class1_ka_not_complete_email,

                      class2_display_name: element.class2_display_name,
                      class2_video_url: element.class2_video_url,
                      class2_video_url_144p: element.class2_video_url_144p,
                      class2_video_url_240p: element.class2_video_url_240p,
                      class2_video_url_360p: element.class2_video_url_360p,
                      class2_video_url_480p: element.class2_video_url_480p,
                      class2_video_url_720p: element.class2_video_url_720p,
                      class2_thumbnail_url: element.class2_thumbnail_url,
                      class2_material_url: element.class2_material_url,
                      class2_material_name: element.class2_material_name,
                      class2_video_next_button:
                        element.class2_video_next_button,
                      class2_video_complete_email:
                        element.class2_video_complete_email,
                      class2_video_not_complete_email:
                        element.class2_video_not_complete_email,
                      class2_pt_name: element.class2_pt_name,
                      class2_pt_next_button: element.class2_pt_next_button,
                      class2_pt_complete_email:
                        element.class2_pt_complete_email,
                      class2_pt_not_complete_email:
                        element.class2_pt_not_complete_email,

                      class3_display_name: element.class3_display_name,
                      class3_video_url: element.class3_video_url,
                      class3_video_url_144p: element.class3_video_url_144p,
                      class3_video_url_240p: element.class3_video_url_240p,
                      class3_video_url_360p: element.class3_video_url_360p,
                      class3_video_url_480p: element.class3_video_url_480p,
                      class3_video_url_720p: element.class3_video_url_720p,
                      class3_thumbnail_url: element.class3_thumbnail_url,
                      class3_material_url: element.class3_material_url,
                      class3_material_name: element.class3_material_name,
                      class3_video_next_button:
                        element.class3_video_next_button,
                      class3_video_complete_email:
                        element.class3_video_complete_email,
                      class3_video_not_complete_email:
                        element.class3_video_not_complete_email,

                      offer_link: element.offer_link,
                      enrollments:
                        element.users[0] == 0 ? 0 : element.users.length,
                      kaq: element.kaq,
                      ptq: element.ptq,
                    });
                  });

                  this.dataSource = new MatTableDataSource(users);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.filter();
                  this.pagination = true;
                }
              });
          }
        });
    } catch (e) {
      this.service.ShowError(e.message);
      await this.spinner.hide();
    }
  }
  async saveMiniMasterClass() {
    this.markFormTouched(this.miniMasterForm);
    if (this.miniMasterForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            let kaIds = '';
            for (let i = 0; i < this.selectionKA.selected.length; i++) {
              kaIds += this.selectionKA.selected[i].id + ',';
            }
            if (kaIds.endsWith(',') == true) {
              kaIds = kaIds.substring(0, kaIds.length - 1);
            }

            let ptIds = '';
            for (let i = 0; i < this.selectionPT.selected.length; i++) {
              ptIds += this.selectionPT.selected[i].id + ',';
            }
            if (ptIds.endsWith(',') == true) {
              ptIds = ptIds.substring(0, ptIds.length - 1);
            }

            var token = session.getIdToken().getJwtToken();
            let params = {
              class_name: this.class_name,
              class_url: this.class_url,
              certi_id: this.certi_id,
              enroll_email_template: this.enroll_email_template,
              class1_display_name: this.class1_display_name,
              class1_video_url: this.class1_video_url,
              class1_video_url_144p: this.class1_video_url_144p,
              class1_video_url_240p: this.class1_video_url_240p,
              class1_video_url_360p: this.class1_video_url_360p,
              class1_video_url_480p: this.class1_video_url_480p,
              class1_video_url_720p: this.class1_video_url_720p,
              class1_thumbnail_url: this.class1_thumbnail_url,
              class1_material_url: this.class1_material_url,
              class1_material_name: this.class1_material_name,
              class1_video_next_button: this.class1_video_next_button,
              class1_video_complete_email: this.class1_video_complete_email,
              class1_video_not_complete_email:
                this.class1_video_not_complete_email,
              class1_ka_name: this.class1_ka_name,
              class1_ka_next_button: this.class1_ka_next_button,
              class1_ka_complete_email: this.class1_ka_complete_email,
              class1_ka_not_complete_email: this.class1_ka_not_complete_email,
              class2_display_name: this.class2_display_name,
              class2_video_url: this.class2_video_url,
              class2_video_url_144p: this.class2_video_url_144p,
              class2_video_url_240p: this.class2_video_url_240p,
              class2_video_url_360p: this.class2_video_url_360p,
              class2_video_url_480p: this.class2_video_url_480p,
              class2_video_url_720p: this.class2_video_url_720p,
              class2_thumbnail_url: this.class2_thumbnail_url,
              class2_material_name: this.class2_material_name,
              class2_material_url: this.class2_material_url,
              class2_video_next_button: this.class2_video_next_button,
              class2_video_complete_email: this.class2_video_complete_email,
              class2_video_not_complete_email:
                this.class2_video_not_complete_email,
              class2_pt_name: this.class2_pt_name,
              class2_pt_next_button: this.class2_pt_next_button,
              class2_pt_complete_email: this.class2_pt_complete_email,
              class2_pt_not_complete_email: this.class2_pt_not_complete_email,
              class3_display_name: this.class3_display_name,
              class3_video_url: this.class3_video_url,
              class3_video_url_144p: this.class3_video_url_144p,
              class3_video_url_240p: this.class3_video_url_240p,
              class3_video_url_360p: this.class3_video_url_360p,
              class3_video_url_480p: this.class3_video_url_480p,
              class3_video_url_720p: this.class3_video_url_720p,
              class3_thumbnail_url: this.class3_thumbnail_url,
              class3_material_url: this.class3_material_url,
              class3_material_name: this.class3_material_name,
              class3_video_next_button: this.class3_video_next_button,
              class3_video_complete_email: this.class3_video_complete_email,
              class3_video_not_complete_email:
                this.class3_video_not_complete_email,
              offer_link: this.offer_link,
              ptq: ptIds,
              kaq: kaIds,
              users: '',
            };
            await this.miniMasterService
              .addMiniMasterClass(params, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          'Mini MasterClass is added by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'mini_masterclass',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  await this.getAllMiniMasterClass();
                  await this.spinner.hide();
                  this.service.ShowSuccess('Mini MasterClass add successfully');
                  this.closeModal();
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async updateMiniMasterClass() {
    this.markFormTouched(this.miniMasterForm);
    if (this.miniMasterForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            let kaIds = '';
            for (let i = 0; i < this.selectionKA.selected.length; i++) {
              kaIds += this.selectionKA.selected[i].id + ',';
            }
            if (kaIds.endsWith(',') == true) {
              kaIds = kaIds.substring(0, kaIds.length - 1);
            }

            let ptIds = '';
            for (let i = 0; i < this.selectionPT.selected.length; i++) {
              ptIds += this.selectionPT.selected[i].id + ',';
            }
            if (ptIds.endsWith(',') == true) {
              ptIds = ptIds.substring(0, ptIds.length - 1);
            }

            var token = session.getIdToken().getJwtToken();
            let params = {
              id: this.EditId,
              class_name: this.class_name,
              class_url: this.class_url,
              certi_id: this.certi_id,
              enroll_email_template: this.enroll_email_template,
              class1_display_name: this.class1_display_name,
              class1_video_url: this.class1_video_url,
              class1_video_url_144p: this.class1_video_url_144p,
              class1_video_url_240p: this.class1_video_url_240p,
              class1_video_url_360p: this.class1_video_url_360p,
              class1_video_url_480p: this.class1_video_url_480p,
              class1_video_url_720p: this.class1_video_url_720p,
              class1_thumbnail_url: this.class1_thumbnail_url,
              class1_material_url: this.class1_material_url,
              class1_material_name: this.class1_material_name,
              class1_video_next_button: this.class1_video_next_button,
              class1_video_complete_email: this.class1_video_complete_email,
              class1_video_not_complete_email:
                this.class1_video_not_complete_email,
              class1_ka_name: this.class1_ka_name,
              class1_ka_next_button: this.class1_ka_next_button,
              class1_ka_complete_email: this.class1_ka_complete_email,
              class1_ka_not_complete_email: this.class1_ka_not_complete_email,
              class2_display_name: this.class2_display_name,
              class2_video_url: this.class2_video_url,
              class2_video_url_144p: this.class2_video_url_144p,
              class2_video_url_240p: this.class2_video_url_240p,
              class2_video_url_360p: this.class2_video_url_360p,
              class2_video_url_480p: this.class2_video_url_480p,
              class2_video_url_720p: this.class2_video_url_720p,
              class2_thumbnail_url: this.class2_thumbnail_url,
              class2_material_name: this.class2_material_name,
              class2_material_url: this.class2_material_url,
              class2_video_next_button: this.class2_video_next_button,
              class2_video_complete_email: this.class2_video_complete_email,
              class2_video_not_complete_email:
                this.class2_video_not_complete_email,
              class2_pt_name: this.class2_pt_name,
              class2_pt_next_button: this.class2_pt_next_button,
              class2_pt_complete_email: this.class2_pt_complete_email,
              class2_pt_not_complete_email: this.class2_pt_not_complete_email,
              class3_display_name: this.class3_display_name,
              class3_video_url: this.class3_video_url,
              class3_video_url_144p: this.class3_video_url_144p,
              class3_video_url_240p: this.class3_video_url_240p,
              class3_video_url_360p: this.class3_video_url_360p,
              class3_video_url_480p: this.class3_video_url_480p,
              class3_video_url_720p: this.class3_video_url_720p,
              class3_thumbnail_url: this.class3_thumbnail_url,
              class3_material_url: this.class3_material_url,
              class3_material_name: this.class3_material_name,
              class3_video_next_button: this.class3_video_next_button,
              class3_video_complete_email: this.class3_video_complete_email,
              class3_video_not_complete_email:
                this.class3_video_not_complete_email,
              offer_link: this.offer_link,
              ptq: ptIds,
              kaq: kaIds,
            };
            await this.miniMasterService
              .updateMiniMasterClass(params, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.service.ShowSuccess(
                    'Mini MasterClass updated successfully',
                  );
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          'Mini MasterClass is updated by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'mini_masterclass',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  await this.getAllMiniMasterClass();
                  await this.spinner.hide();
                  this.closeModal();
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.allCertificate = list.data;
            this.certi_id = '';
          }
        });
      }
    });
  }
  async listTemplate() {
    await this.auth.GetAllBeeFreeTemplate().then(async (BeeFreeData) => {
      if (BeeFreeData[0].StatusCode == 1) {
        for (const ListTemp of BeeFreeData[0].data.TemplatesMetadata) {
          await this.auth
            .GetBeeFreeTemplate(ListTemp.Name)
            .then(async (BeeFreeGetTemp) => {
              if (BeeFreeGetTemp[0].StatusCode == 1) {
                var TemplateParam = {
                  id: BeeFreeGetTemp[0].data.Template.TemplateName,
                  name: BeeFreeGetTemp[0].data.Template.TemplateName,
                  certi_id: 11,
                  subject: '',
                  message: BeeFreeGetTemp[0].data.Template.HtmlPart,
                };
                this.allTemplates.push(TemplateParam);
              }
            });
        }
      }
    });
    this.class1_video_complete_email = '';
    this.class1_video_not_complete_email = '';
    this.class1_ka_complete_email = '';
    this.class1_ka_not_complete_email = '';
    this.class2_video_complete_email = '';
    this.class2_video_not_complete_email = '';
    this.class2_pt_complete_email = '';
    this.class2_pt_not_complete_email = '';
    this.class3_video_complete_email = '';
    this.class3_video_not_complete_email = '';
  }
  async editMiniMasterClass(item) {
    this.IsUpdate = true;
    this.addClass = true;
    this.EditId = item.id;
    this.class_name = item.class_name;
    this.class_url = item.class_url;
    this.certi_id = item.certi_id;
    this.enroll_email_template = item.enroll_email_template;
    this.class1_display_name = item.class1_display_name;
    this.class1_video_url = item.class1_video_url;
    (this.class1_video_url_144p = item.class1_video_url_144p),
      (this.class1_video_url_240p = item.class1_video_url_240p),
      (this.class1_video_url_360p = item.class1_video_url_360p),
      (this.class1_video_url_480p = item.class1_video_url_480p),
      (this.class1_video_url_720p = item.class1_video_url_720p),
      (this.class1_thumbnail_url = item.class1_thumbnail_url);
    this.class1_material_url = item.class1_material_url;
    this.class1_material_name = item.class1_material_name;
    this.class1_video_next_button = item.class1_video_next_button;
    this.class1_video_complete_email = item.class1_video_complete_email;
    this.class1_video_not_complete_email = item.class1_video_not_complete_email;
    this.class1_ka_name = item.class1_ka_name;
    this.class1_ka_next_button = item.class1_ka_next_button;
    this.class1_ka_complete_email = item.class1_ka_complete_email;
    this.class1_ka_not_complete_email = item.class1_ka_not_complete_email;
    this.class2_display_name = item.class2_display_name;
    this.class2_video_url = item.class2_video_url;
    (this.class2_video_url_144p = item.class2_video_url_144p),
      (this.class2_video_url_240p = item.class2_video_url_240p),
      (this.class2_video_url_360p = item.class2_video_url_360p),
      (this.class2_video_url_480p = item.class2_video_url_480p),
      (this.class2_video_url_720p = item.class2_video_url_720p),
      (this.class2_thumbnail_url = item.class2_thumbnail_url);
    this.class2_material_name = item.class2_material_name;
    this.class2_material_url = item.class2_material_url;
    this.class2_video_next_button = item.class2_video_next_button;
    this.class2_video_complete_email = item.class2_video_complete_email;
    this.class2_video_not_complete_email = item.class2_video_not_complete_email;
    this.class2_pt_name = item.class2_pt_name;
    this.class2_pt_next_button = item.class2_pt_next_button;
    this.class2_pt_complete_email = item.class2_pt_complete_email;
    this.class2_pt_not_complete_email = item.class2_pt_not_complete_email;
    this.class3_display_name = item.class3_display_name;
    this.class3_video_url = item.class3_video_url;
    (this.class3_video_url_144p = item.class3_video_url_144p),
      (this.class3_video_url_240p = item.class3_video_url_240p),
      (this.class3_video_url_360p = item.class3_video_url_360p),
      (this.class3_video_url_480p = item.class3_video_url_480p),
      (this.class3_video_url_720p = item.class3_video_url_720p),
      (this.class3_thumbnail_url = item.class3_thumbnail_url);
    this.class3_material_url = item.class3_material_url;
    this.class3_material_name = item.class3_material_name;
    this.class3_video_next_button = item.class3_video_next_button;
    this.class3_video_complete_email = item.class3_video_complete_email;
    this.class3_video_not_complete_email = item.class3_video_not_complete_email;
    this.offer_link = item.offer_link;
    this.selectionKA = new SelectionModel<kaQuestionClass>(true, []);
    this.selectionPT = new SelectionModel<ptQuestionClass>(true, []);

    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('mini_masterclass', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.logsList = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.KaQuestions.forEach((element) => {
      if (item.kaq.includes(element.id.toString())) {
        this.selectionKA.select(element);
      }
    });
    this.PtQuestions.forEach((element) => {
      if (item.ptq.includes(element.id.toString())) {
        this.selectionPT.select(element);
      }
    });
  }
  async deleteMiniMasterClass(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Delete this Mini MasterClass!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            await this.miniMasterService
              .deleteMiniMasterClass(item.id, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          item.web_link +
                          '</span> Mini MasterClass is deleted by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'mini_masterclass',
                          item.id,
                          token,
                        );
                      }
                    });
                  await this.getAllMiniMasterClass();
                  await this.spinner.hide();
                  this.service.ShowSuccess(
                    'Mini MasterClass deleted successfully',
                  );
                }
              });
          }
        });
      }
    });
  }
  handleFileSelect(evt) {
    let me = this;
    let file = evt.target.files[0];
    let mime = 'data:' + file.type + ';base64,';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.uploadFile(reader.result, evt.target.id, mime);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  async uploadFile(image, model, mime) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.sefpaceService
          .uploadFile(image, mime, token)
          .then(async (image_response) => {
            if (image_response[0].status == 200) {
              var result = JSON.parse(<string>image_response[0].json);
              if (model == 'class1_video_url')
                this.class1_video_url = result.data;
              else if (model == 'class1_thumbnail_url')
                this.class1_thumbnail_url = result.data;
              else if (model == 'class1_material_url')
                this.class1_material_url = result.data;
              else if (model == 'class2_video_url')
                this.class2_video_url = result.data;
              else if (model == 'class2_thumbnail_url')
                this.class2_thumbnail_url = result.data;
              else if (model == 'class2_material_url')
                this.class2_material_url = result.data;
              else if (model == 'class3_video_url')
                this.class3_video_url = result.data;
              else if (model == 'class3_thumbnail_url')
                this.class3_thumbnail_url = result.data;
              else if (model == 'class3_material_url')
                this.class3_material_url = result.data;
              else this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      }
    });
  }
  async getAllKaQuestions() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        var users: kaQuestionClass[] = [];
        await this.knowledgeService.ListknowAssque(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              users.push({
                srno: srno,
                id: element.id,
                ass_id: element.ass_id,
                question: element.question,
              });
            });
            this.KaQuestions = users;
            this.dataSourceQuestionKA = new MatTableDataSource(users);
            this.dataSourceQuestionKA.sort = this.sortquestion.toArray()[1];
            this.dataSourceQuestionKA.paginator =
              this.quespaginator.toArray()[1];
            this.matpagination = true;
          }
        });
      }
    });
  }
  async getAllPtQuestions() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        var res: ptQuestionClass[] = [];
        await this.practiceService
          .ListPracticeQuestion(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                res.push({
                  srno: srno,
                  id: element.id,
                  test_id: element.test_id,
                  title: element.title,
                });
              });
              this.PtQuestions = res;
              this.dataSourceQuestionPT = new MatTableDataSource(res);
              this.dataSourceQuestionPT.sort = this.sortquestionpt.toArray()[2];
              this.dataSourceQuestionPT.paginator =
                this.quespaginatorpt.toArray()[2];
              this.matpaginationpt = true;
            }
          });
      }
    });
  }
  masterToggleKA() {
    this.isAllSelectedKA()
      ? this.selectionKA.clear()
      : this.dataSourceQuestionKA.data.forEach((row) =>
          this.selectionKA.select(row),
        );
  }
  isAllSelectedKA() {
    const numSelected = this.selectionKA.selected.length;
    const numRows = this.dataSourceQuestionKA.data.length;
    return numSelected === numRows;
  }
  masterTogglePT() {
    this.isAllSelectedPT()
      ? this.selectionPT.clear()
      : this.dataSourceQuestionPT.data.forEach((row) =>
          this.selectionPT.select(row),
        );
  }
  isAllSelectedPT() {
    const numSelected = this.selectionPT.selected.length;
    const numRows = this.dataSourceQuestionPT.data.length;
    return numSelected === numRows;
  }
}

export interface miniMasterClass {
  srno: number;
  id: string;
  enroll_email_template: string;
  class_name: string;
  class_url: string;
  certi_id: string;
  certificate_name: string;
  class1_display_name: string;
  class1_video_url: string;
  class1_video_url_144p: string;
  class1_video_url_240p: string;
  class1_video_url_360p: string;
  class1_video_url_480p: string;
  class1_video_url_720p: string;
  class1_thumbnail_url: string;
  class1_material_url: string;
  class1_material_name: string;
  class1_video_next_button: string;
  class1_video_complete_email: string;
  class1_video_not_complete_email: string;
  class1_ka_name: string;
  class1_ka_next_button: string;
  class1_ka_complete_email: string;
  class1_ka_not_complete_email: string;

  class2_display_name: string;
  class2_video_url: string;
  class2_video_url_144p: string;
  class2_video_url_240p: string;
  class2_video_url_360p: string;
  class2_video_url_480p: string;
  class2_video_url_720p: string;
  class2_thumbnail_url: string;
  class2_material_url: string;
  class2_material_name: string;
  class2_video_next_button: string;
  class2_video_complete_email: string;
  class2_video_not_complete_email: string;
  class2_pt_name: string;
  class2_pt_next_button: string;
  class2_pt_complete_email: string;
  class2_pt_not_complete_email: string;

  class3_display_name: string;
  class3_video_url: string;
  class3_video_url_144p: string;
  class3_video_url_240p: string;
  class3_video_url_360p: string;
  class3_video_url_480p: string;
  class3_video_url_720p: string;
  class3_thumbnail_url: string;
  class3_material_url: string;
  class3_material_name: string;
  class3_video_next_button: string;
  class3_video_complete_email: string;
  class3_video_not_complete_email: string;
  offer_link: string;
  enrollments: string;
  kaq: [];
  ptq: [];
}

export interface kaQuestionClass {
  srno: number;
  id: string;
  ass_id: string;
  question: string;
}
export interface ptQuestionClass {
  srno: number;
  id: string;
  test_id: string;
  title: string;
}
