import { Component, OnInit } from '@angular/core';
import { ConfirmedValidator } from '../confirmed.validator';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../service/authorization.service';
import { ServicesService } from '../services.service';

@Component({
  selector: 'app-newchnagepass',
  templateUrl: './newchnagepass.component.html',
  styleUrls: ['./newchnagepass.component.scss'],
})
export class NewchnagepassComponent implements OnInit {
  public LoginForm: FormGroup;
  password: any;
  Confirmpass: any;
  PassLength: boolean = false;
  strangth = 0;
  IsSmall: boolean = false;
  IsUpper: boolean = false;
  Number: boolean = false;
  IsSpecial: boolean = false;
  PassWidth = 0;
  PassMsg = 'Poor';
  email: any;
  data: any;
  submitted = false;
  oldpassword: any;

  constructor(
    public service: ServicesService,
    public ActiveRoute: ActivatedRoute,
    public auth: AuthorizationService,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.email = JSON.parse(atob(params['email']));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit(): void {
    this.LoginForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        oldpassword: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]])[A-Za-z0-9d$@].{7,}',
            ),
          ],
        ],
        Confirmpass: ['', Validators.required],
      },
      {
        validator: ConfirmedValidator('password', 'Confirmpass'),
      },
    );
  }
  get f() {
    return this.LoginForm.controls;
  }
  PasswordCheck() {
    if (this.password) {
      if (this.password.length > 0) {
        this.PassWidth = 0;
        this.IsSmall = false;
        this.IsUpper = false;
        this.Number = false;
        this.IsSpecial = false;
        this.PassLength = false;
        this.PassMsg = 'Poor';
        if (/[a-z]/.test(this.password)) {
          this.IsSmall = true;
        }
        if (this.password.length >= 8) {
          this.PassLength = true;
        }

        if (/[A-Z]/.test(this.password)) {
          this.IsUpper = true;
        }

        if (/[0-9]/.test(this.password)) {
          this.Number = true;
        }
        if (/[!@#$%^&*()_+]/.test(this.password)) {
          this.IsSpecial = true;
        }

        if (this.PassLength) {
          this.PassWidth += 20;
        }
        if (this.IsSmall) {
          this.PassWidth += 20;
        }
        if (this.IsUpper) {
          this.PassWidth += 20;
        }
        if (this.Number) {
          this.PassWidth += 20;
        }
        if (this.IsSpecial) {
          this.PassWidth += 20;
        }

        if (this.PassWidth == 20 || this.PassWidth == 40) {
          this.PassMsg = 'Poor';
        }
        if (this.PassWidth == 60 || this.PassWidth == 80) {
          this.PassMsg = 'Good';
        }
        if (this.PassWidth == 100) {
          this.PassMsg = 'Strong';
        }
      } else {
        this.PassWidth = 0;
        this.IsSmall = false;
        this.IsUpper = false;
        this.Number = false;
        this.IsSpecial = false;
        this.PassLength = false;
        this.PassMsg = 'Poor';
      }
    } else {
      this.PassWidth = 0;
      this.IsSmall = false;
      this.IsUpper = false;
      this.Number = false;
      this.IsSpecial = false;
      this.PassLength = false;
      this.PassMsg = 'Poor';
    }
  }

  submit() {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.email = JSON.parse(atob(params['email']));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });

    this.submitted = true;
    if (this.LoginForm.valid) {
      this.spinner.show();

      this.auth.TempsignIn(this.email, this.oldpassword, this.password).then(
        (data) => {
          if (data[0].StatusCode == 2) {
            this.service.ShowSuccess('Change password successfully');
            this.spinner.hide();
            this.router.navigate(['/login']);
          } else if (data[0].StatusCode == 1) {
            this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            this.service.ShowError(data[0].message);
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
          this.service.ShowError(err);
        },
      );
    }
  }
}
