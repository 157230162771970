import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { TemplateService } from '../../services/template/template.service';
import { FormBuilder } from '@angular/forms';
import { PracticeService } from '../../services/practice/practice.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { CatexamService } from '../../services/catexam/catexam.service';
import { KnowledgeService } from '../../services/knowledge/knowledge.service';
import { CourseService } from '../../services/course/course.service';
import { LiveeventsService } from 'src/app/services/liveevents/liveevents.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit {
  displayedColumns: string[] = [
    'Srno',
    'templateName',
    'certificate_name',
    'actions',
  ];
  dataSource: MatTableDataSource<cohortlist>;
  Search: any;
  PopUpshow: boolean = false;
  addTemplate: boolean = false;
  ClientCohort = [];
  IsUpdate: boolean = false;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  Certificates = [];
  SelectedCertificate: any;
  template_name: any;
  Id: any;
  KnowledgeAssessments = [];
  PracticeExams = [];
  CatExams = [];
  FilterKnowledgeAssessments = [];
  FilterPracticeExams = [];
  FilterCatExams = [];
  EmailTemplates = [];
  FilterEmailTemplates = [];
  Events = [];
  StartDate = new Date();

  VideoAccess: boolean = false;
  MindmapAccess: boolean = false;
  SummaryAccess: boolean = false;
  ReferenceAccess: boolean = false;
  KAAccess: boolean = false;
  PTAccess: boolean = false;
  CATAccess: boolean = false;
  LiveChatAccess: boolean = false;
  LiveQuestionAccess: boolean = false;
  LiveEventAccess: boolean = false;
  FashFullAccess: boolean = false;
  PraAppFullAccess: boolean = false;
  QuestionAccess: boolean = false;
  FlashcardAccess: boolean = false;
  SelectedEvents = [];
  LiveEvents = [];
  FilterLiveEvents = [];
  Videos = [];
  FilterVideos = [];
  Mindmap = [];
  FilterMindmap = [];
  baselineka = [];
  Filterbaselineka = [];
  SelectedBaselineKA: any;
  SelectedBaselineKAdueDate: any;
  SelectedBaselineKAdueTime: any;
  SelectedBaselineKAOverdueDate: any;
  SelectedBaselineKAOverdueTime: any;
  SelectedBaselineKAEndDate: any;
  SelectedBaselineKAEndTime: any;
  time_zone = this.auth.currentTimeZone();
  Hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
  constructor(
    public templateService: TemplateService,
    public practiceService: PracticeService,
    public knowledgeService: KnowledgeService,
    public courseService: CourseService,
    private cohortService: CohortService,
    private catTestService: CatexamService,
    public auth: AuthorizationService,
    public router: Router,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public liveservice: LiveeventsService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewTemplate() {
    this.addTemplate = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addTemplate = false;
    this.IsUpdate = false;
    this.template_name = '';
    this.SelectedCertificate = '';
    this.FashFullAccess = false;
    this.PraAppFullAccess = false;
    this.Events = [];
    this.VideoAccess = false;
    this.MindmapAccess = false;
    this.SummaryAccess = false;
    this.ReferenceAccess = false;
    this.KAAccess = false;
    this.PTAccess = false;
    this.CATAccess = false;
    this.LiveChatAccess = false;
    this.LiveQuestionAccess = false;
    this.LiveEventAccess = false;
    this.QuestionAccess = false;
    this.FlashcardAccess = false;
    this.Videos.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.Mindmap.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.KnowledgeAssessments.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.PracticeExams.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.CatExams.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueTime = '';
      ka.OverDueTime = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
    this.SelectedBaselineKA = '';
    this.SelectedBaselineKAdueDate = '';
    this.SelectedBaselineKAdueTime = '';
    this.SelectedBaselineKAOverdueDate = '';
    this.SelectedBaselineKAOverdueTime = '';
    this.SelectedBaselineKAEndDate = '';
    this.SelectedBaselineKAEndTime = '';
    this.Events = [];
    this.SelectedEvents = [];
    this.EmailTemplates.forEach((ka) => {
      ka.Assigned = false;
      ka.Required = false;
      ka.Homework = false;
      ka.Due = '';
      ka.OverDue = '';
      ka.EndDue = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndTime = '';
      ka.DueNumber = '';
      ka.OverDueNumber = '';
      ka.EndNumber = '';
    });
  }

  async ngOnInit() {
    this.spinner.show();
    await this.getAllCourseTemplate();
    await this.getAllCertificate();
    await this.getAllLiveEvents();
    await this.getAllVideoLesson();
    await this.getAllExams();
    await this.spinner.hide();
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.templateService
              .DeleteTemplate(item.id, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          item.name +
                          '</span> template is deleted by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'templates',
                          item.id,
                          token,
                        );
                      }
                    });
                  await this.getAllCourseTemplate();
                  await this.spinner.hide();
                  Swal.fire(
                    list.message,
                    'Template has been deleted.',
                    'success',
                  );
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      }
    });
  }
  async edit(item) {
    this.addTemplate = true;
    this.IsUpdate = true;
    this.Id = item.id;
    this.template_name = item.templateName;
    this.SelectedCertificate = item.certi_id;
    this.VideoAccess = item.video_access;
    this.MindmapAccess = item.mindmap_access;
    this.SummaryAccess = item.summary_access;
    this.ReferenceAccess = item.reference_access;
    this.KAAccess = item.KA_access;
    this.PTAccess = item.PT_access;
    this.CATAccess = item.CAT_access;
    this.LiveChatAccess = item.livechat_access;
    this.LiveQuestionAccess = item.liveq_access;
    this.LiveEventAccess = item.liveevent_access;
    this.PraAppFullAccess = item.question_access;
    this.FashFullAccess = item.flashcard_app;
    this.StartDate = item.start_date;
    this.SelectedBaselineKA = item.baseline_ka == null ? '' : item.baseline_ka;
    if (item.baseline_ka_schedule) {
      this.SelectedBaselineKAdueDate =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).DueDate
          : '';
      this.SelectedBaselineKAdueTime =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).DueTime
          : '';
      this.SelectedBaselineKAOverdueDate =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).OverDueDate
          : '';
      this.SelectedBaselineKAOverdueTime =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).OverDueTime
          : '';
      this.SelectedBaselineKAEndDate =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).EndDate
          : '';
      this.SelectedBaselineKAEndTime =
        item.baseline_ka_schedule && item.baseline_ka_schedule != ''
          ? JSON.parse(item.baseline_ka_schedule).EndTime
          : '';
    }
    if (item.live_events != null && item.live_events != '') {
      this.SelectedEvents = item.live_events.split(',').map(Number);
    } else {
      this.SelectedEvents = [];
    }
    if (item.events != null) {
      try {
        this.Events = JSON.parse(item.events);
      } catch (error) {
        this.Events = [];
      }
    } else this.Events = [];
    this.LiveEvents = this.FilterLiveEvents.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.Videos = this.FilterVideos.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.Mindmap = this.FilterMindmap.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.KnowledgeAssessments = this.FilterKnowledgeAssessments.filter(
      (data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1
        );
      },
    );
    this.CatExams = this.FilterCatExams.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.PracticeExams = this.FilterPracticeExams.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.EmailTemplates = this.FilterEmailTemplates.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.baselineka = this.Filterbaselineka.filter((data) => {
      return data.certi_id.toString().toLowerCase().indexOf(item.certi_id) > -1;
    });
    this.Videos.forEach((ka) => {
      item.videos.forEach((element) => {
        if (ka.id == element.id && element.template_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.Mindmap.forEach((ka) => {
      item.mindmaps.forEach((element) => {
        if (ka.id == element.id && element.template_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.KnowledgeAssessments.forEach((ka) => {
      item.knowassessments.forEach((element) => {
        if (ka.id == element.id && element.template_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.PracticeExams.forEach((ka) => {
      item.practicetests.forEach((element) => {
        if (ka.id == element.id && element.template_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.CatExams.forEach((ka) => {
      item.ketexamtests.forEach((element) => {
        if (ka.id == element.id && element.template_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Homework = element.Homework;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
    this.EmailTemplates.forEach((ka) => {
      item.email_templates.forEach((element) => {
        if (ka.id == element.id && element.template_id == item.id) {
          ka.Assigned = true;
          ka.Required = element.Required;
          ka.Due = element.Due;
          ka.OverDue = element.OverDue;
          ka.EndDue = element.EndDue;
          ka.DueNumber = element.DueNumber;
          ka.OverDueNumber = element.OverDueNumber;
          ka.EndNumber = element.EndNumber;
          ka.DueTime = element.DueTime;
          ka.OverDueTime = element.OverDueTime;
          ka.EndTime = element.EndTime;
        }
      });
    });
  }
  async getAllCourseTemplate() {
    const users: cohortlist[] = [];
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.templateService.ListTemplates(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              if (element.videos.length > 0) {
                for (let i = 0; i < element.videos.length; i++) {
                  element.videos[i].Assigned = true;
                  element.videos[i].template_id = element.id;
                  if (element.video_schedule.length > 0) {
                    element.video_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.videos[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.videos[i].Homework = schedule.homework;
                        element.videos[i].Required = schedule.required;
                        element.videos[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.videos[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.videos[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.videos[i].DueNumber = schedule.due_number;
                        element.videos[i].OverDueNumber =
                          schedule.overdue_number;
                        element.videos[i].EndNumber = schedule.end_number;
                        element.videos[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.videos[i].OverDueTime = schedule.overdue_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.overdue_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.videos[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                      }
                    });
                  }
                }
              }
              if (element.mindmaps.length > 0) {
                for (let i = 0; i < element.mindmaps.length; i++) {
                  element.mindmaps[i].Assigned = true;
                  element.mindmaps[i].template_id = element.id;
                  if (element.mindmap_schedule.length > 0) {
                    element.mindmap_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.mindmaps[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.mindmaps[i].Homework = schedule.homework;
                        element.mindmaps[i].Required = schedule.required;
                        element.mindmaps[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.mindmaps[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.mindmaps[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.mindmaps[i].DueNumber = schedule.due_number;
                        element.mindmaps[i].OverDueNumber =
                          schedule.overdue_number;
                        element.mindmaps[i].EndNumber = schedule.end_number;
                        element.mindmaps[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.mindmaps[i].OverDueTime = schedule.overdue_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.overdue_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.mindmaps[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                      }
                    });
                  }
                }
              }
              if (element.knowledge_asses.length > 0) {
                for (let i = 0; i < element.knowledge_asses.length; i++) {
                  element.knowledge_asses[i].Assigned = true;
                  element.knowledge_asses[i].template_id = element.id;
                  if (element.knowledge_schedule.length > 0) {
                    element.knowledge_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.knowledge_asses[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.knowledge_asses[i].Homework = schedule.homework;
                        element.knowledge_asses[i].Required = schedule.required;
                        element.knowledge_asses[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.knowledge_asses[i].OverDue =
                          schedule.overdue_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.overdue_date,
                                  this.time_zone.nameValue,
                                )
                                .split('T')[0]
                            : '';
                        element.knowledge_asses[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.knowledge_asses[i].DueNumber =
                          schedule.due_number;
                        element.knowledge_asses[i].OverDueNumber =
                          schedule.overdue_number;
                        element.knowledge_asses[i].EndNumber =
                          schedule.end_number;
                        element.knowledge_asses[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.knowledge_asses[i].OverDueTime =
                          schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                                'HH:mm:ss',
                              )
                            : '';
                        element.knowledge_asses[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                      }
                    });
                  }
                }
              }
              if (element.practicetests.length > 0) {
                for (let i = 0; i < element.practicetests.length; i++) {
                  element.practicetests[i].Assigned = true;
                  element.practicetests[i].template_id = element.id;
                  if (element.practice_schedule.length > 0) {
                    element.practice_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.practicetests[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.practicetests[i].Homework = schedule.homework;
                        element.practicetests[i].Required = schedule.required;
                        element.practicetests[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.practicetests[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.practicetests[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.practicetests[i].DueNumber =
                          schedule.due_number;
                        element.practicetests[i].OverDueNumber =
                          schedule.overdue_number;
                        element.practicetests[i].EndNumber =
                          schedule.end_number;
                        element.practicetests[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.practicetests[i].OverDueTime =
                          schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                                'HH:mm:ss',
                              )
                            : '';
                        element.practicetests[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                      }
                    });
                  }
                }
              }

              if (element.ketexamtests.length > 0) {
                for (let i = 0; i < element.ketexamtests.length; i++) {
                  element.ketexamtests[i].Assigned = true;
                  element.ketexamtests[i].template_id = element.id;
                  if (element.cat_schedule.length > 0) {
                    element.cat_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.ketexamtests[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.ketexamtests[i].Homework = schedule.homework;
                        element.ketexamtests[i].Required = schedule.required;
                        element.ketexamtests[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.ketexamtests[i].OverDue = schedule.overdue_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.ketexamtests[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.ketexamtests[i].DueNumber = schedule.due_number;
                        element.ketexamtests[i].OverDueNumber =
                          schedule.overdue_number;
                        element.ketexamtests[i].EndNumber = schedule.end_number;
                        element.ketexamtests[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.ketexamtests[i].OverDueTime =
                          schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                                'HH:mm:ss',
                              )
                            : '';
                        element.ketexamtests[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                      }
                    });
                  }
                }
              }
              if (element.email_templates.length > 0) {
                for (let i = 0; i < element.email_templates.length; i++) {
                  element.email_templates[i].Assigned = true;
                  element.email_templates[i].template_id = element.id;
                  if (element.email_schedule.length > 0) {
                    element.email_schedule.forEach((schedule) => {
                      if (
                        schedule.type_id == element.email_templates[i].id &&
                        schedule.template_id == element.id
                      ) {
                        element.email_templates[i].Required = schedule.required;
                        element.email_templates[i].Due = schedule.due_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.due_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.email_templates[i].OverDue =
                          schedule.overdue_date
                            ? this.auth
                                .ConvertFormatDbDateTime(
                                  schedule.overdue_date,
                                  this.time_zone.nameValue,
                                )
                                .split('T')[0]
                            : '';
                        element.email_templates[i].EndDue = schedule.end_date
                          ? this.auth
                              .ConvertFormatDbDateTime(
                                schedule.end_date,
                                this.time_zone.nameValue,
                              )
                              .split('T')[0]
                          : '';
                        element.email_templates[i].DueNumber =
                          schedule.due_number;
                        element.email_templates[i].OverDueNumber =
                          schedule.overdue_number;
                        element.email_templates[i].EndNumber =
                          schedule.end_number;
                        element.email_templates[i].DueTime = schedule.due_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.due_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                        element.email_templates[i].OverDueTime =
                          schedule.overdue_date
                            ? this.auth.ConvertDateDDFormat(
                                schedule.overdue_date,
                                this.time_zone.nameValue,
                                'HH:mm:ss',
                              )
                            : '';
                        element.email_templates[i].EndTime = schedule.end_date
                          ? this.auth.ConvertDateDDFormat(
                              schedule.end_date,
                              this.time_zone.nameValue,
                              'HH:mm:ss',
                            )
                          : '';
                      }
                    });
                  }
                }
              }

              await users.push({
                Srno: srno,
                id: element.id,
                templateName: element.name,
                certi_id: element.certi_id,
                cource_id: element.cource_id,
                createdAt: element.createdAt,
                live_events: element.live_events,
                videos: element.videos,
                mindmaps: element.mindmaps,
                knowassessments: element.knowledge_asses,
                practicetests: element.practicetests,
                ketexamtests: element.ketexamtests,
                updatedAt: element.updatedAt,
                certificate_name: element.certificate.certificate_name,
                practice_que_app: element.practice_que_app,
                flashcard_app: element.flashcard_app,
                events: element.events,
                email_templates: element.email_templates,
                video_access: element.video_access,
                mindmap_access: element.mindmap_access,
                summary_access: element.summary_access,
                reference_access: element.reference_access,
                KA_access: element.KA_access,
                PT_access: element.PT_access,
                CAT_access: element.CAT_access,
                livechat_access: element.livechat_access,
                liveq_access: element.liveq_access,
                liveevent_access: element.liveevent_access,
                start_date: element.start_date,
                question_access: element.question_access,
                flashcard_access: element.flashcard_access,
                baseline_ka: element.baseline_ka,
                baseline_ka_schedule: element.baseline_ka_schedule,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  showClientPopup(element) {
    this.PopUpshow = true;
    this.ClientCohort = element.practice_time[0];
  }

  async addEvents() {
    let isAdd = true;
    this.Events.forEach((element) => {
      if (element.event_name == '') {
        isAdd = false;
      }
    });
    if (isAdd) {
      this.Events.push({
        event_name: '',
        event_date: '',
        event_time: '',
        minutes: '',
        url: '',
      });
    }
  }
  async deleteEvents(item) {
    const index = this.Events.indexOf(item);
    if (index > -1) {
      this.Events.splice(index, 1);
    }
  }
  async getAllCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificates = list.data;
            this.SelectedCertificate = '';
          }
        });
      }
    });
  }
  async getAllExams() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getAllExams(token).then(async (data) => {
          this.KnowledgeAssessments = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.KnowledgeAssessments = list.data.KnowledgeAssessments;
            this.PracticeExams = list.data.PracticeExams;
            this.CatExams = list.data.CatExams;
            this.FilterKnowledgeAssessments = list.data.KnowledgeAssessments;

            this.FilterPracticeExams = list.data.PracticeExams;
            this.FilterCatExams = list.data.CatExams;
            this.EmailTemplates = list.data.EmailTemplates;
            this.FilterEmailTemplates = list.data.EmailTemplates;
            let cat_count = [];
            cat_count = list.data.cat_count;
            let ka_count = [];
            ka_count = list.data.ka_count;
            let practice_count = [];
            practice_count = list.data.practice_count;
            this.KnowledgeAssessments.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Homework = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
              ka_count.forEach((element) => {
                if (element.ass_id.includes(ka.id)) {
                  ka.Count = element.ttlque;
                }
              });
              if (ka.knowass_name.toLowerCase().indexOf('pre') > -1)
                this.Filterbaselineka.push(ka);

              if (ka.knowass_name.toLowerCase().indexOf('baseline') > -1)
                this.Filterbaselineka.push(ka);
            });
            this.PracticeExams.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Homework = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
              practice_count.forEach((element) => {
                if (element.test_id == ka.id) {
                  ka.Count = element.ttlque;
                }
              });
            });
            this.CatExams.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Homework = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
              cat_count.forEach((element) => {
                if (element.test_id == ka.id) {
                  ka.Count = element.ttlque;
                }
              });
            });
            this.EmailTemplates.forEach((ka) => {
              ka.Assigned = false;
              ka.Required = false;
              ka.Homework = false;
              ka.Due = '';
              ka.OverDue = '';
              ka.EndDue = '';
              ka.DueNumber = '';
              ka.OverDueNumber = '';
              ka.EndNumber = '';
              ka.DueTime = '';
              ka.OverDueTime = '';
              ka.EndTime = '';
            });
            this.baselineka = this.Filterbaselineka;
            this.SelectedBaselineKA = '';
            this.SelectedBaselineKAdueDate = '';
            this.SelectedBaselineKAdueTime = '';
            this.SelectedBaselineKAOverdueDate = '';
            this.SelectedBaselineKAOverdueTime = '';
            this.SelectedBaselineKAEndDate = '';
            this.SelectedBaselineKAEndTime = '';
          }
          await this.spinner.hide();
        });
      }
    });
  }
  async CertificateChange(ev) {
    this.spinner.show();
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.LiveEvents = this.FilterLiveEvents.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.Videos = this.FilterVideos.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.Mindmap = this.FilterMindmap.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.KnowledgeAssessments = this.FilterKnowledgeAssessments.filter(
        (data) => {
          return (
            data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) >
            -1
          );
        },
      );
      this.CatExams = this.FilterCatExams.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.PracticeExams = this.FilterPracticeExams.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.EmailTemplates = this.FilterEmailTemplates.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
      this.baselineka = this.Filterbaselineka.filter((data) => {
        return (
          data.certi_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    }
    this.spinner.hide();
  }
  async saveTemplates() {
    if (
      this.template_name == '' ||
      this.template_name == null ||
      this.template_name == undefined
    ) {
      this.service.ShowError('Enter template name');
    } else if (
      this.SelectedCertificate == '' ||
      this.SelectedCertificate == null ||
      this.SelectedCertificate == undefined
    ) {
      this.service.ShowError('Select certificate');
    } else {
      let videotemp = '';
      let mindmaptemp = '';
      let knowassessments = '';
      let practicetests = '';
      let email_templates = '';
      let ketexamtests = '';
      let schedule = [];
      this.Videos.forEach((ka) => {
        if (ka.Assigned) {
          videotemp += ka.id + ',';
          ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
          ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
          ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
          schedule.push({
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'video',
            type_id: ka.id,
            template_id: '',
          });
        }
      });
      if (videotemp.endsWith(',') == true) {
        videotemp = videotemp.substring(0, videotemp.length - 1);
      }
      this.Mindmap.forEach((ka) => {
        if (ka.Assigned) {
          mindmaptemp += ka.id + ',';
          ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
          ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
          ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
          schedule.push({
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'mindmap',
            type_id: ka.id,
            template_id: '',
          });
        }
      });
      if (mindmaptemp.endsWith(',') == true) {
        mindmaptemp = mindmaptemp.substring(0, mindmaptemp.length - 1);
      }
      //Knowledge Assessments----------------------------------
      this.KnowledgeAssessments.forEach((ka) => {
        if (ka.Assigned) {
          knowassessments += ka.id + ',';
          ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
          ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
          ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
          schedule.push({
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'KA',
            type_id: ka.id,
            template_id: '',
          });
        }
      });
      if (knowassessments.endsWith(',') == true) {
        knowassessments = knowassessments.substring(
          0,
          knowassessments.length - 1,
        );
      }

      //Practice Exams----------------------------------
      this.PracticeExams.forEach((ka) => {
        if (ka.Assigned) {
          practicetests += ka.id + ',';
          ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
          ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
          ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
          schedule.push({
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'PRACTICE',
            type_id: ka.id,
            template_id: '',
          });
        }
      });
      if (practicetests.endsWith(',') == true) {
        practicetests = practicetests.substring(0, practicetests.length - 1);
      }

      //CAT Exams----------------------------------
      this.CatExams.forEach((ka) => {
        if (ka.Assigned) {
          ketexamtests += ka.id + ',';
          ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
          ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
          ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
          schedule.push({
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            homework: ka.Homework,
            type: 'CAT',
            type_id: ka.id,
            template_id: '',
          });
        }
      });
      if (ketexamtests.endsWith(',') == true) {
        ketexamtests = ketexamtests.substring(0, ketexamtests.length - 1);
      }

      //Email Templates----------------------------------
      this.EmailTemplates.forEach((ka) => {
        if (ka.Assigned) {
          email_templates += ka.id + ',';
          ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
          ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
          ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
          schedule.push({
            due_number: ka.DueNumber,
            overdue_number: ka.OverDueNumber,
            end_number: ka.EndNumber,
            due_date: ka.Due,
            overdue_date: ka.OverDue,
            end_date: ka.EndDue,
            required: ka.Required,
            type: 'EMAIL',
            type_id: ka.id,
            template_id: '',
          });
        }
      });
      if (email_templates.endsWith(',') == true) {
        email_templates = email_templates.substring(
          0,
          email_templates.length - 1,
        );
      }

      let events = '';
      if (this.Events.length > 0) {
        events = JSON.stringify(this.Events);
      } else {
        events = '';
      }

      var baseline_ka_schedule = {
        baselineka: this.SelectedBaselineKA,
        DueDate: this.SelectedBaselineKAdueDate,
        DueTime: this.SelectedBaselineKAdueTime,
        OverDueDate: this.SelectedBaselineKAOverdueDate,
        OverDueTime: this.SelectedBaselineKAOverdueTime,
        EndDate: this.SelectedBaselineKAEndDate,
        EndTime: this.SelectedBaselineKAEndTime,
      };

      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var access = {
            VideoAccess: this.VideoAccess,
            MindmapAccess: this.MindmapAccess,
            SummaryAccess: this.SummaryAccess,
            ReferenceAccess: this.ReferenceAccess,
            KAAccess: this.KAAccess,
            PTAccess: this.PTAccess,
            CATAccess: this.CATAccess,
            LiveChatAccess: this.LiveChatAccess,
            LiveQuestionAccess: this.LiveQuestionAccess,
            LiveEventAccess: this.LiveEventAccess,
            FlashcardAccess: this.FlashcardAccess,
            QuestionAccess: this.QuestionAccess,
          };
          await this.templateService
            .AddTemplate(
              this.template_name,
              this.SelectedCertificate,
              this.SelectedEvents.toString(),
              videotemp,
              mindmaptemp,
              knowassessments,
              practicetests,
              ketexamtests,
              this.FashFullAccess,
              this.PraAppFullAccess,
              schedule,
              events,
              email_templates,
              access,
              this.StartDate,
              this.SelectedBaselineKA,
              JSON.stringify(baseline_ka_schedule),
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.template_name +
                        '</span> template is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'templates',
                        list.data.id,
                        token,
                      );
                    }
                  });
                await this.getAllCourseTemplate();
                this.closeModal();
                await this.spinner.hide();
                this.service.ShowSuccess('Template add successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async updateTemplates() {
    let videotemp = '';
    let mindmaptemp = '';
    let knowassessments = '';
    let practicetests = '';
    let email_templates = '';
    let ketexamtests = '';
    let schedule = [];
    let cohort_schedule = [];
    this.Videos.forEach((ka) => {
      if (ka.Assigned) {
        videotemp += ka.id + ',';
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
        schedule.push({
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          homework: ka.Homework,
          type: 'video',
          type_id: ka.id,
          template_id: '',
        });
        cohort_schedule.push({
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          homework: ka.Homework,
          type: 'video',
          type_id: ka.id,
          cohort_id: '',
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          name: ka.video_title,
        });
      }
    });
    if (videotemp.endsWith(',') == true) {
      videotemp = videotemp.substring(0, videotemp.length - 1);
    }
    this.Mindmap.forEach((ka) => {
      if (ka.Assigned) {
        mindmaptemp += ka.id + ',';
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
        schedule.push({
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          homework: ka.Homework,
          type: 'mindmap',
          type_id: ka.id,
          template_id: '',
        });
        cohort_schedule.push({
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          homework: ka.Homework,
          type: 'mindmap',
          type_id: ka.id,
          cohort_id: '',
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          name: ka.name,
        });
      }
    });
    if (mindmaptemp.endsWith(',') == true) {
      mindmaptemp = mindmaptemp.substring(0, mindmaptemp.length - 1);
    }
    //Knowledge Assessments----------------------------------
    this.KnowledgeAssessments.forEach((ka) => {
      if (ka.Assigned) {
        knowassessments += ka.id + ',';
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
        schedule.push({
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          homework: ka.Homework,
          type: 'KA',
          type_id: ka.id,
          template_id: '',
        });
      }
    });
    if (knowassessments.endsWith(',') == true) {
      knowassessments = knowassessments.substring(
        0,
        knowassessments.length - 1,
      );
    }

    //Practice Exams----------------------------------
    this.PracticeExams.forEach((ka) => {
      if (ka.Assigned) {
        practicetests += ka.id + ',';
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
        schedule.push({
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          homework: ka.Homework,
          type: 'PRACTICE',
          type_id: ka.id,
          template_id: '',
        });
      }
    });
    if (practicetests.endsWith(',') == true) {
      practicetests = practicetests.substring(0, practicetests.length - 1);
    }

    //CAT Exams----------------------------------
    this.CatExams.forEach((ka) => {
      if (ka.Assigned) {
        ketexamtests += ka.id + ',';
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
        schedule.push({
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          homework: ka.Homework,
          type: 'CAT',
          type_id: ka.id,
          template_id: '',
        });
      }
    });
    if (ketexamtests.endsWith(',') == true) {
      ketexamtests = ketexamtests.substring(0, ketexamtests.length - 1);
    }

    //Email Templates----------------------------------
    this.EmailTemplates.forEach((ka) => {
      if (ka.Assigned) {
        email_templates += ka.id + ',';
        ka.Due = this.dueDateCalculate(ka.DueTime, ka.DueNumber);
        ka.OverDue = this.dueDateCalculate(ka.OverDueTime, ka.OverDueNumber);
        ka.EndDue = this.dueDateCalculate(ka.EndTime, ka.EndNumber);
        schedule.push({
          due_number: ka.DueNumber,
          overdue_number: ka.OverDueNumber,
          end_number: ka.EndNumber,
          due_date: ka.Due,
          overdue_date: ka.OverDue,
          end_date: ka.EndDue,
          required: ka.Required,
          type: 'EMAIL',
          type_id: ka.id,
          template_id: '',
        });
      }
    });
    if (email_templates.endsWith(',') == true) {
      email_templates = email_templates.substring(
        0,
        email_templates.length - 1,
      );
    }

    let events = '';
    if (this.Events.length > 0) {
      events = JSON.stringify(this.Events);
    } else {
      events = '';
    }
    var baseline_ka_schedule = {
      baselineka: this.SelectedBaselineKA,
      DueDate: this.SelectedBaselineKAdueDate,
      DueTime: this.SelectedBaselineKAdueTime,
      OverDueDate: this.SelectedBaselineKAOverdueDate,
      OverDueTime: this.SelectedBaselineKAOverdueTime,
      EndDate: this.SelectedBaselineKAEndDate,
      EndTime: this.SelectedBaselineKAEndTime,
    };
    await this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        var access = {
          VideoAccess: this.VideoAccess,
          MindmapAccess: this.MindmapAccess,
          SummaryAccess: this.SummaryAccess,
          ReferenceAccess: this.ReferenceAccess,
          KAAccess: this.KAAccess,
          PTAccess: this.PTAccess,
          CATAccess: this.CATAccess,
          LiveChatAccess: this.LiveChatAccess,
          LiveQuestionAccess: this.LiveQuestionAccess,
          LiveEventAccess: this.LiveEventAccess,
          FlashcardAccess: this.FlashcardAccess,
          QuestionAccess: this.QuestionAccess,
        };
        await this.templateService
          .UpdateTemplate(
            this.Id,
            this.template_name,
            this.SelectedCertificate,
            this.SelectedEvents.toString(),
            videotemp,
            mindmaptemp,
            knowassessments,
            practicetests,
            ketexamtests,
            this.FashFullAccess,
            this.PraAppFullAccess,
            schedule,
            events,
            email_templates,
            access,
            this.StartDate,
            this.SelectedBaselineKA,
            JSON.stringify(baseline_ka_schedule),
            token,
          )
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              if (cohort_schedule.length > 0) {
                await this.templateService.UpdateCohortVideoMind(
                  this.Id,
                  videotemp,
                  mindmaptemp,
                  cohort_schedule,
                  token,
                );
              }
              this.auth
                .getAuthenticatedUser()
                .getSession(async (err, session) => {
                  if (err) {
                    await this.spinner.hide();
                    this.router.navigate(['/login']);
                  } else {
                    var token = session.getIdToken().getJwtToken();
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    var desc =
                      '<span> ' +
                      this.template_name +
                      '</span> template is update by <span> ' +
                      e +
                      '</span>';
                    await this.service.addAdminLogs(
                      desc,
                      'Addtemplates',
                      this.Id,
                      token,
                    );
                  }
                });
              await this.getAllCourseTemplate();
              this.closeModal();
              await this.spinner.hide();
              this.service.ShowSuccess('Template update successfully');
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          });
      }
    });
  }
  checkIsDueNumber(item) {
    var reg = /^\d+$/;
    if (!reg.test(item.DueNumber)) {
      item.DueNumber = '';
    }
  }
  checkIsOverDueNumber(item) {
    var reg = /^\d+$/;
    if (!reg.test(item.OverDueNumber)) {
      item.OverDueNumber = '';
    }
  }
  checkIsEndNumber(item) {
    var reg = /^\d+$/;
    if (!reg.test(item.EndNumber)) {
      item.EndNumber = '';
    }
  }
  dueDateCalculate(DueTime, DueNumber) {
    if (DueNumber !== '' && DueNumber != null && DueNumber != undefined) {
      let date = new Date();
      let hr = DueTime;
      let min = DueTime;
      hr = parseInt(hr.substr(0, 2));
      min = parseInt(min.substr(3, 4));
      if (!hr && hr != 0) {
        hr = 0;
      }
      if (!min && min != 0) {
        min = 0;
      }
      if (DueNumber == '') DueNumber = '0';
      var dt = new Date(date.setDate(date.getDate() + parseInt(DueNumber)));
      let d_date = dt.getDate();
      let d_year = dt.getFullYear();
      let d_month = dt.getMonth();
      var final_date = new Date(d_year, d_month, d_date, hr, min);
      return final_date.toString();
    } else {
      return '';
    }
  }

  async getAllLiveEvents() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveservice.getAllLiveEvents(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.LiveEvents = list.data;
            this.FilterLiveEvents = list.data;
            await this.spinner.hide();
          }
        });
      }
    });
  }

  async getAllVideoLesson() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getAllVideoLesson(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Videos = list.data.Videos;
            this.FilterVideos = list.data.Videos;
            this.Mindmap = list.data.MindMaps;
            this.FilterMindmap = list.data.MindMaps;
            this.Videos.forEach((vi) => {
              vi.Assigned = false;
              vi.Required = false;
              vi.Homework = false;
              vi.Due = '';
              vi.OverDue = '';
              vi.EndDue = '';
              vi.DueNumber = '';
              vi.OverDueNumber = '';
              vi.EndNumber = '';
              vi.DueTime = '';
              vi.OverDueTime = '';
              vi.EndTime = '';
            });
            this.Mindmap.forEach((mi) => {
              mi.Assigned = false;
              mi.Required = false;
              mi.Homework = false;
              mi.Due = '';
              mi.OverDue = '';
              mi.EndDue = '';
              mi.DueNumber = '';
              mi.OverDueNumber = '';
              mi.EndNumber = '';
              mi.DueTime = '';
              mi.OverDueTime = '';
              mi.EndTime = '';
            });
            await this.spinner.hide();
          }
        });
      }
    });
  }
  videoChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.Videos.sort((a, b) => (a.video_title > b.video_title ? 1 : -1));
    else if (val == 'date')
      this.Videos.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  mindmapChange(event) {
    const val = event.target.value;
    if (val == 'name') this.Mindmap.sort((a, b) => (a.name > b.name ? 1 : -1));
    else if (val == 'date')
      this.Mindmap.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  knowledgeChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.KnowledgeAssessments.sort((a, b) =>
        a.knowass_name > b.knowass_name ? 1 : -1,
      );
    else if (val == 'date')
      this.KnowledgeAssessments.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  catChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.CatExams.sort((a, b) => (a.ketexam_name > b.ketexam_name ? 1 : -1));
    else if (val == 'date')
      this.CatExams.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  practiceChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.PracticeExams.sort((a, b) =>
        a.practice_name > b.practice_name ? 1 : -1,
      );
    else if (val == 'date')
      this.PracticeExams.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  emailChange(event) {
    const val = event.target.value;
    if (val == 'name')
      this.EmailTemplates.sort((a, b) => (a.name > b.name ? 1 : -1));
    else if (val == 'date')
      this.EmailTemplates.sort((a, b) => b.DueNumber - a.DueNumber);
  }
  checkvaluedDue(item) {
    var reg = /^-?\d+$/;
    if (!reg.test(item.DueNumber)) {
      item.DueNumber = '';
    }
  }
  checkvalueOverDue(item) {
    var reg = /^-?\d+$/;
    if (!reg.test(item.OverDueNumber)) {
      item.OverDueNumber = '';
    }
  }
  checkvalueEnd(item) {
    var reg = /^-?\d+$/;
    if (!reg.test(item.EndNumber)) {
      item.EndNumber = '';
    }
  }
}

export interface cohortlist {
  Srno: number;
  id: number;
  templateName: string;
  certi_id: number;
  cource_id: string;
  createdAt: string;
  live_events: string;
  videos: string;
  mindmaps: string;
  knowassessments: string;
  practicetests: string;
  ketexamtests: string;
  updatedAt: string;
  certificate_name: string;
  flashcard_app: string;
  practice_que_app: string;
  events: string;
  email_templates: string;
  video_access: string;
  mindmap_access: string;
  summary_access: string;
  reference_access: string;
  KA_access: string;
  PT_access: string;
  CAT_access: string;
  livechat_access: string;
  liveq_access: string;
  liveevent_access: string;
  flashcard_access: string;
  question_access: string;
  start_date: string;
  baseline_ka: string;
  baseline_ka_schedule: string;
}
