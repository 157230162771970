<div class="self-paced-main-div">
  <div class="header-flex">
    <div class="self-paced-title-div">
      <span (click)="closeSummaryPage()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="338.355"
          height="338.355"
          viewBox="0 0 338.355 338.355"
        >
          <g
            id="Group_1"
            data-name="Group 1"
            transform="translate(-1136.822 -840.822)"
          >
            <line
              id="Line_1"
              data-name="Line 1"
              x2="303"
              y2="303"
              transform="translate(1154.5 858.5)"
              fill="none"
              stroke="#7773a8"
              stroke-width="35"
            />
            <line
              id="Line_2"
              data-name="Line 2"
              x1="303"
              y2="303"
              transform="translate(1154.5 858.5)"
              fill="none"
              stroke="#7773a8"
              stroke-width="35"
            />
          </g>
        </svg>
      </span>
      <h2>Self-paced Class Summary</h2>
    </div>
    <div class="filter-search">
      <div class="filter-by-select">
        <label for="Filterby">Filter by</label>
        <select
          name=""
          id="Filterby"
          [(ngModel)]="SelectedMentor"
          (change)="changeMentor()"
        >
          <option value="">Class Mentor</option>
          <option *ngFor="let item of Mentors" value="{{ item.id }}">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="search-icon">
        <input
          placeholder="Search by Certification name"
          (keyup)="filter()"
          [(ngModel)]="Search"
          autocomplete="off"
          name="search"
          class="input-seacrch"
        />
        <span>
          <svg
            _ngcontent-oqq-c66=""
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              _ngcontent-oqq-c66=""
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.3569 0C2.84628 0 0 2.84627 0 6.35687C0 9.86748 2.84628 12.7137 6.3569 12.7137C7.86707 12.7137 9.2543 12.1871 10.3449 11.3074L12.8382 13.8007L12.9226 13.8724C13.1885 14.0638 13.5615 14.0399 13.8007 13.8007C14.0664 13.5349 14.0664 13.104 13.8007 12.8382L11.3074 10.3449C12.1871 9.25432 12.7138 7.86706 12.7138 6.35687C12.7138 2.84627 9.86753 0 6.3569 0ZM9.90681 9.87185C10.8007 8.96919 11.3527 7.72744 11.3527 6.35687C11.3527 3.59798 9.11582 1.36109 6.3569 1.36109C3.59799 1.36109 1.3611 3.59798 1.3611 6.35687C1.3611 9.11577 3.59799 11.3527 6.3569 11.3527C7.72745 11.3527 8.96917 10.8006 9.87184 9.90682C9.87743 9.90082 9.88316 9.89488 9.88901 9.88903C9.89486 9.88318 9.9008 9.87745 9.90681 9.87185Z"
              fill="url(#paint0_linear)"
            ></path>
            <defs _ngcontent-oqq-c66="">
              <linearGradient
                _ngcontent-oqq-c66=""
                id="paint0_linear"
                x1="0"
                y1="0"
                x2="0"
                y2="14"
                gradientUnits="userSpaceOnUse"
              >
                <stop _ngcontent-oqq-c66="" stop-color="#E9716A"></stop>
                <stop
                  _ngcontent-oqq-c66=""
                  offset="1"
                  stop-color="#B164AC"
                ></stop>
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div>
    <div class="pagesection">
      <div class="mat-elevation-z8">
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          style="border-spacing: 0 10px"
        >
          <ng-container matColumnDef="student_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Student Name
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mw-93">{{ element.student_name }} </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="enrolled_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Enrolled Date
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mw-93">{{ element.enrolled_date }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="onboarded_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Onboarded Date
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mw-93">{{ element.onboarded_date }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="last_activity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Last Activity
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mw-93">{{ element.last_activity }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="exam_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Exam Date</th>
            <td mat-cell *matCellDef="let element">
              <span class="mw-93">{{ element.exam_date }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="mentor_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Mentor Name
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mw-93">{{ element.mentor_name }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="next_contact">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Next Contact
            </th>
            <td mat-cell *matCellDef="let element">
              <ul>
                <li *ngFor="let itm of element.notes">
                  <span class="note-span">
                    {{ itm.next_contact | date: 'medium' }}
                  </span>
                </li>
              </ul>
            </td>
          </ng-container>
          <ng-container matColumnDef="overdue_classwork">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Overdue Classwork
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mw-93">{{ element.overdue_classwork }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="knowledge_assessment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Knowledge Assessment
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="exam-formate"
                >{{ element.ka_completed
                }}<span>/{{ element.ka_total }} | </span
                ><span>{{ element.ka_percentage }}</span></span
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="practice_test">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Practice Test
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="exam-formate"
                >{{ element.pt_completed
                }}<span>/{{ element.pt_total }} | </span
                ><span>{{ element.pt_percentage }}</span></span
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="flashcard">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Flashcard</th>
            <td mat-cell *matCellDef="let element">
              <span class="exam-formate"
                >{{ element.fc_completed
                }}<span>/{{ element.fc_total }} | </span
                ><span>{{ element.fc_percentage }}</span></span
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="practice_question">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Practice Questions
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="exam-formate"
                >{{ element.ptq_completed
                }}<span>/{{ element.ptq_total }} | </span
                ><span>{{ element.ptq_percentage }}</span></span
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
            <td mat-cell *matCellDef="let element">
              <ul>
                <li *ngFor="let itm of element.notes">
                  <span class="note-span">
                    {{ itm.notes }}
                  </span>
                </li>
              </ul>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <img
                src="../../../assets/images/Report.png"
                alt="Report"
                class="action-icon"
                (click)="gotoUserReport(row)"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      <mat-paginator
        style-paginator
        (page)="pageEvents($event)"
        showFirstLastButtons
        [showTotalPages]="3"
        [length]="totalRecords"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50, 100, 500]"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="#d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
