<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>References</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Assessment Name..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="openTranscriptPopup()">Add References</button>
      <button (click)="openBulkUploadModal()">Bulk Upload</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domin_name }}">{{ row.domin_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain_name }}">{{
              row.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.topic_name }}">{{ row.topic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="ref_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.ref_type }}">{{ row.ref_type }}</span>
          </td> </ng-container
        >>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Reference Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.title }}">{{ row.title }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="editReference(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="deleteReference(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<div class="cutome-modal" *ngIf="TranscriptPopup">
  <div class="cutome-modal-content">
    <div class="cutome-container">
      <div class="modal-header">
        <p *ngIf="!IsUpdate">Add References</p>
        <p *ngIf="IsUpdate">Edit References</p>
        <span class="material-icons" (click)="closeTranscriptPopup()"
          >close</span
        >
      </div>
      <div class="modal-body">
        <div>
          <form class="example-form" [formGroup]="VideoLessonForm">
            <div class="formwrapper">
              <div class="w-50">
                <!-- <label>Certificate</label> -->
                <div class="select-box">
                  <select
                    [(ngModel)]="SelectedCertificate"
                    (change)="onCertificateChange($event)"
                    formControlName="SelectedCertificate"
                    required
                    [ngClass]="{
                      err:
                        VideoLessonForm.controls[
                          'SelectedCertificate'
                        ].hasError('required') &&
                        (VideoLessonForm.controls['SelectedCertificate']
                          .dirty ||
                          VideoLessonForm.controls['SelectedCertificate']
                            .touched)
                    }"
                  >
                    <option value="">Select Certification</option>
                    <option
                      *ngFor="let item of Certificates"
                      value="{{ item.id }}"
                    >
                      {{ item.certificate_name }}
                    </option>
                  </select>
                  <span></span>
                </div>
              </div>
              <div class="w-100">
                <div class="select-box">
                  <select
                    (change)="domainchange($event)"
                    [(ngModel)]="SelectedDomain"
                    formControlName="SelectedDomain"
                    required
                    [ngClass]="{
                      err:
                        VideoLessonForm.controls['SelectedDomain'].hasError(
                          'required'
                        ) &&
                        (VideoLessonForm.controls['SelectedDomain'].dirty ||
                          VideoLessonForm.controls['SelectedDomain'].touched)
                    }"
                  >
                    <option value="">Select Domain</option>
                    <option *ngFor="let item of Domain" value="{{ item.id }}">
                      {{ item.domin_name }}
                    </option>
                  </select>
                  <span></span>
                </div>
              </div>

              <div class="w-100">
                <!-- <label>Sub Domain</label> -->
                <div class="select-box">
                  <select
                    (change)="SubDomainchange($event)"
                    [attr.disabled]="this.SelectedDomain == '' ? true : null"
                    [(ngModel)]="SelectedSubDomain"
                    formControlName="SelectedSubDomain"
                    required
                    [ngClass]="{
                      err:
                        VideoLessonForm.controls['SelectedSubDomain'].hasError(
                          'required'
                        ) &&
                        (VideoLessonForm.controls['SelectedSubDomain'].dirty ||
                          VideoLessonForm.controls['SelectedSubDomain'].touched)
                    }"
                  >
                    <option value="">Select Sub-domain</option>
                    <option
                      *ngFor="let item of SubDomain"
                      value="{{ item.id }}"
                    >
                      {{ item.subdomain_name }}
                    </option>
                  </select>
                  <span></span>
                </div>
              </div>

              <div class="w-100">
                <!-- <label>Topic</label> -->
                <div class="select-box">
                  <select
                    [(ngModel)]="SelectedTopics"
                    [attr.disabled]="this.SelectedSubDomain == '' ? true : null"
                    formControlName="SelectedTopics"
                    required
                    [ngClass]="{
                      err:
                        VideoLessonForm.controls['SelectedTopics'].hasError(
                          'required'
                        ) &&
                        (VideoLessonForm.controls['SelectedTopics'].dirty ||
                          VideoLessonForm.controls['SelectedTopics'].touched)
                    }"
                  >
                    <option value="">Select Topic</option>
                    <option *ngFor="let item of Topic" value="{{ item.id }}">
                      {{ item.topic_name }}
                    </option>
                  </select>
                  <span></span>
                </div>
              </div>

              <div class="w-100">
                <!-- <label>Reference Type</label> -->
                <div class="select-box">
                  <select
                    [(ngModel)]="ref_type"
                    formControlName="ref_type"
                    required
                    [ngClass]="{
                      err:
                        VideoLessonForm.controls['ref_type'].hasError(
                          'required'
                        ) &&
                        (VideoLessonForm.controls['ref_type'].dirty ||
                          VideoLessonForm.controls['ref_type'].touched)
                    }"
                  >
                    <option value="Book">Book</option>
                    <option value="Website">Website</option>
                  </select>
                  <span></span>
                </div>
              </div>

              <div class="w-80" [hidden]="ref_type == 'Website'">
                <!-- <label>Book</label> -->
                <div class="select-box">
                  <select
                    [(ngModel)]="SelectedBook"
                    formControlName="SelectedBook"
                  >
                    <option value="">Select Book</option>
                    <option *ngFor="let item of Books" value="{{ item.id }}">
                      {{ item.book_title }}
                    </option>
                  </select>
                  <span></span>
                </div>
              </div>

              <div class="w-20" [hidden]="ref_type == 'Website'">
                <!-- <label>Page Number</label> -->
                <input
                  [(ngModel)]="PageNumber"
                  formControlName="PageNumber"
                  placeholder="Page #"
                  type="number"
                />
              </div>

              <div class="w-100" [hidden]="ref_type == 'Book'">
                <!-- <label>Web Link</label> -->
                <input
                  [(ngModel)]="WebLink"
                  formControlName="WebLink"
                  placeholder="Web link"
                  type="text"
                  name="WebLink"
                />
              </div>

              <div class="w-100">
                <!-- <label>Reference Title</label> -->
                <input
                  [(ngModel)]="Title"
                  formControlName="Title"
                  placeholder="Reference Title"
                  type="text"
                  name="Title"
                  required
                  [ngClass]="{
                    err:
                      VideoLessonForm.controls['Title'].hasError('required') &&
                      (VideoLessonForm.controls['Title'].dirty ||
                        VideoLessonForm.controls['Title'].touched)
                  }"
                />
              </div>
            </div>
          </form>
          <div class="history-div" *ngIf="IsUpdate">
            <h3><span class="material-icons"> history </span>Audit history</h3>
            <div class="audit-table">
              <div class="table-header">
                <div class="date-title"><span>Date & Time</span></div>
                <div class="action-title"><span>Action</span></div>
              </div>
              <div class="main-body-audit">
                <div class="table-body" *ngFor="let log of loglist">
                  <div class="date-title">
                    <span>{{ log.createdAt | date: 'medium' }}</span>
                  </div>
                  <div class="action-title">
                    <span [innerHTML]="log.description"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel-btn" (click)="closeTranscriptPopup()">
          Cancel
        </button>
        <button class="submit-btn" *ngIf="!IsUpdate" (click)="saveReference()">
          Save
        </button>
        <button class="submit-btn" *ngIf="IsUpdate" (click)="updateReference()">
          Update
        </button>
      </div>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="isBulkUpload">
  <div class="cutome-modal-content" style="width: 50%">
    <div class="modal-header">
      <p>Bulk Upload</p>
      <span (click)="closeBulkUploadModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <ngx-dropzone
          maxFileSize="100MB"
          accept=".csv"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label
            >Drag & drop here (or)
            <span class="browse">Browse</span></ngx-dropzone-label
          >
          <div *ngFor="let f of files">
            <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
          </div>
        </ngx-dropzone>
        <div class="format" *ngIf="files.length <= 0">
          <span>Allowed Format: CSV</span>
        </div>
        <div class="format" *ngFor="let f of files">
          <div class="bbulkupload">
            <span *ngFor="let t of f">{{ t.name }} </span>

            <mat-icon (click)="onRemove(f)">close</mat-icon>
          </div>
        </div>
        <div class="format">
          <progress
            value="{{ progress }}"
            max="10"
            id="progressBar"
            *ngIf="selectprogress"
          ></progress>
        </div>
        <div class="format">
          <a (click)="ExportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            References_sample.xlsx
          </a>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeBulkUploadModal()">
        Cancel
      </button>
      <button
        class="submit-btn"
        (click)="bulkUpload()"
        [disabled]="selectBulkupload"
      >
        Upload
      </button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
