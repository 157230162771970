import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CohortService } from '../../services/cohort/cohort.service';
import { LiveService } from '../../services/live/live.service';
@Component({
  selector: 'app-livequestion',
  templateUrl: './livequestion.component.html',
  styleUrls: ['./livequestion.component.scss'],
})
export class LivequestionComponent implements OnInit {
  public LiveQueTestForm: FormGroup;
  EditHideen: boolean = true;
  SendMail: boolean = false;
  TestName: any;
  displayedColumns: string[] = [
    'srno',
    'testname',
    'cohort',
    'date',
    'addquestion',
    'viewresult',
    'actions',
  ];
  dataSource: MatTableDataSource<testlist>;
  Search: any;
  TestList = [];
  EditedRecord = [];
  MailCohorts = [];
  SelectedCohorts = [];
  ProjectorLink: any;
  SelectedTestId: any;
  SelectedQuestionId: any;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    public liveService: LiveService,
    public cohortService: CohortService,
    public auth: AuthorizationService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {}
  CohortList = [];
  selecteds = [];
  items = [];
  async ngOnInit() {
    this.LiveQueTestForm = this.fb.group({
      TestName: ['', Validators.required],
      Cohort: ['', Validators.required],
    });
    await this.spinner.show();
    await this.tokengenerate();
    await this.listCohort();
    await this.livetestList();
    await this.spinner.hide();
  }
  async tokengenerate() {
    try {
      var authenticatedUser = await this.auth.getAuthenticatedUser();
      authenticatedUser.getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          await authenticatedUser.getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            }
            await this.auth
              .getAuthenticatedUser()
              .getSession(async (err, session) => {
                if (err) {
                  await this.spinner.hide();
                  this.router.navigate(['/login']);
                } else {
                  var token = session.getIdToken().getJwtToken();
                  this.liveService.token = token;
                }
              });
          });
        }
      });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  async listCohort() {
    this.items = [];
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.cohortService.ListCohort(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.items = list.data;
          }
        });
      }
    });
  }
  async submit() {
    try {
      if (this.LiveQueTestForm.valid) {
        this.spinner.show();
        var Ass_id = '';
        this.selecteds.forEach((element) => {
          Ass_id = Ass_id + element + ',';
        });
        if (Ass_id.endsWith(',') == true) {
          Ass_id = Ass_id.substring(0, Ass_id.length - 1);
        }
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.liveService
              .livetestadd(this.TestName, Ass_id, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.TestName +
                          '</span> Live test added by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'livequestion',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  this.service.ShowSuccess('Test added successfully');
                  this.livetestList();
                }
              });
          }
        });
        this.spinner.hide();
      }
    } catch (e) {
      await this.spinner.hide();
    }
  }
  async livetestList() {
    const users: testlist[] = [];
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveService.livetestList(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;

            await list.data.forEach(async (element) => {
              srno = srno + 1;
              var cohortname = '';
              if (element.cohorts.length > 0) {
                for (let i = 0; i < element.cohorts.length; i++) {
                  cohortname =
                    cohortname + element.cohorts[i].cohort_name + ',';
                }
                if (cohortname.endsWith(',') == true) {
                  cohortname = cohortname.substring(0, cohortname.length - 1);
                }
              }
              await users.push({
                srno: srno,
                id: element.id,
                cohort_ids: element.cohort_ids,
                cohort_name: cohortname,
                cohorts: element.cohorts,
                question_ids: element.question_ids,
                questions: element.questions,
                test_name: element.test_name,
                createdAt: element.createdAt.substr(0, 10),
                status: element.status,
                updatedAt: element.updatedAt,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.pagination = true;
          }
        });
      }
    });
  }
  async addLiveQuestion(item) {
    var datas = {
      id: item.id,
      test_name: item.test_name,
    };
    var data = btoa(JSON.stringify(datas));
    this.router.navigate([`main/livequestion/addlivequestion/${data}`]);
  }
  async editTest(row) {
    this.EditedRecord = row;
    this.EditHideen = false;
    this.TestName = row.test_name;
    this.selecteds = row.cohort_ids.map(Number);
  }
  async update() {
    try {
      if (this.LiveQueTestForm.valid) {
        this.spinner.show();
        var Ass_id = '';
        this.selecteds.forEach((element) => {
          Ass_id = Ass_id + element + ',';
        });
        if (Ass_id.endsWith(',') == true) {
          Ass_id = Ass_id.substring(0, Ass_id.length - 1);
        }
        await this.liveService
          .livetestupdate(this.EditedRecord['id'], this.TestName, Ass_id)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.service.ShowSuccess('Test update successfully');

              this.livetestList();
              this.EditHideen = true;
              this.TestName = '';
              this.selecteds = [];
            }
          });
        this.spinner.hide();
      }
    } catch (e) {
      await this.spinner.hide();
    }
  }
  async deleteTest(row) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        await this.liveService.livetestdelete(row.id).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.service.ShowSuccess('Test deleted successfully');
            this.livetestList();
          }
        });
        this.spinner.hide();
      }
    });
  }
  openSendMailPopup(item) {
    this.MailCohorts = [];
    this.SelectedTestId = item.id;
    this.SelectedQuestionId = item.question_ids[0];
    let user_id = window.sessionStorage.getItem('b6h3a5d9r6e3s5h');
    let dt = { id: item.id, user_id: user_id };
    var data = btoa(JSON.stringify(dt));
    this.ProjectorLink =
      'https://www.my.destcert.com/sidenav/projector/' + data;
    this.MailCohorts = item.cohorts;
    this.MailCohorts.forEach((element) => {
      element['checked'] = false;
    });
    this.SendMail = true;
  }
  closeSendMailPopup() {
    this.SendMail = false;
    this.SelectedTestId = '';
    this.SelectedQuestionId = '';
  }
  checkedAllCohort() {
    this.MailCohorts.forEach((element) => {
      element.checked = true;
    });
  }
  resetAllCohort() {
    this.MailCohorts.forEach((element) => {
      element.checked = false;
    });
  }
  cohortChkClick(item) {
    this.MailCohorts.forEach((element) => {
      if (item.id == element.id) {
        element.checked = !item.checked;
      }
    });
  }
  async sendMail() {
    this.SelectedCohorts = this.MailCohorts.filter(function (e) {
      return e.checked == true;
    });
    let CohortIds = this.SelectedCohorts.map((a) => a.id);
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveService
          .SendStudentLinkLiveq(
            CohortIds.toString(),
            parseInt(this.SelectedTestId),
            parseInt(this.SelectedQuestionId),
            token,
          )
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
            }
          });
      }
    });
    this.spinner.hide();
  }
  viewResult(item) {
    var data = btoa(JSON.stringify(item));
    this.router.navigate([`main/livequestion/liveresult/${data}`]);
  }
}
export interface testlist {
  srno: number;
  id: string;
  cohort_ids: [];
  cohort_name: string;
  cohorts: [];
  question_ids: [];
  questions: [];
  test_name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}
