<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Questions</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Topic, Domain..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewCatExamQua()">Add Question</button>
      <button (click)="openBulkUploadModal()">Bulk Upload</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domin_name }}">{{ row.domin_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdomain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain_name }}">{{
              row.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.topic_name }}">{{ row.topic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="ketexam_name">
          <th
            style="white-space: nowrap"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            CAT Exam
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.ketexam_name }}">{{ row.ketexam_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Question</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.title }}">{{ row.title }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Difficulty</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.type }}">{{ row.type }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addCateExamQua">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Question</p>
      <p *ngIf="IsUpdate">Edit Question</p>
      <p *ngIf="IsUpdate" class="diff-score">
        <span class="score">Difficulty Score: </span>{{ DifficultyScore }}
      </p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="PraacticeQueForm">
          <div class="formwrapper">
            <div class="w-50">
              <!-- <label>Certification</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="CertificateName"
                  (change)="certificatechange($event)"
                  formControlName="CertificateName"
                  required
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['CertificateName'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['CertificateName'].dirty ||
                        PraacticeQueForm.controls['CertificateName'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let items of Certificates"
                    value="{{ items.id }}"
                  >
                    {{ items.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <!-- <label>Domain</label> -->
              <div class="select-box">
                <select
                  (change)="domainchange($event)"
                  [(ngModel)]="Domainname"
                  formControlName="Domainname"
                  required
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['Domainname'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['Domainname'].dirty ||
                        PraacticeQueForm.controls['Domainname'].touched)
                  }"
                  tabindex="2"
                >
                  <option value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <!-- <label>Sub Domain</label> -->
              <div class="select-box">
                <select
                  (change)="SubDomainchange($event)"
                  [attr.disabled]="this.Domainname == '' ? true : null"
                  [(ngModel)]="subdomainname"
                  formControlName="subdomainname"
                  required
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['subdomainname'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['subdomainname'].dirty ||
                        PraacticeQueForm.controls['subdomainname'].touched)
                  }"
                  tabindex="3"
                >
                  <option value="">Select Sub Domain</option>
                  <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <!-- <label>Topic</label> -->
              <div class="select-box">
                <div class="select-box">
                  <select
                    [(ngModel)]="Topicname"
                    formControlName="Topicname"
                    required
                    [ngClass]="{
                      err:
                        PraacticeQueForm.controls['Topicname'].hasError(
                          'required'
                        ) &&
                        (PraacticeQueForm.controls['Topicname'].dirty ||
                          PraacticeQueForm.controls['Topicname'].touched)
                    }"
                    tabindex="4"
                  >
                    <option value="">Select Topic</option>
                    <option *ngFor="let item of Topics" value="{{ item.id }}">
                      {{ item.topic_name }}
                    </option>
                  </select>
                  <span></span>
                </div>
                <span></span>
              </div>
            </div>

            <div class="select-test">
              <label>Select CAT Exams</label>
              <ng-select
                [items]="CatExams"
                formControlName="catexamsid"
                bindLabel="ketexam_name"
                bindValue="id"
                placeholder="Select"
                appendTo="body"
                multiple="true"
                [(ngModel)]="selecteds"
                [ngClass]="{
                  err:
                    PraacticeQueForm.controls['catexamsid'].hasError(
                      'required'
                    ) &&
                    (PraacticeQueForm.controls['catexamsid'].dirty ||
                      PraacticeQueForm.controls['catexamsid'].touched)
                }"
              >
              </ng-select>
            </div>

            <div
              class="Question"
              [ngClass]="{
                err:
                  PraacticeQueForm.controls['title'].hasError('required') &&
                  (PraacticeQueForm.controls['title'].dirty ||
                    PraacticeQueForm.controls['title'].touched)
              }"
            >
              <label>Question</label>
              <textarea
                [(ngModel)]="title"
                formControlName="title"
                placeholder="Type here..."
                type="text"
                name="title"
                required
                tabindex="5"
              >
              </textarea>
            </div>

            <div
              class="Question"
              [ngClass]="{
                err:
                  PraacticeQueForm.controls['option1'].hasError('required') &&
                  (PraacticeQueForm.controls['option1'].dirty ||
                    PraacticeQueForm.controls['option1'].touched)
              }"
            >
              <label>Answer A</label>

              <textarea
                [(ngModel)]="option1"
                formControlName="option1"
                placeholder="Type here..."
                type="text"
                name="title"
                required
                tabindex="6"
              ></textarea>
            </div>

            <div
              class="Question"
              [ngClass]="{
                err:
                  PraacticeQueForm.controls['option2'].hasError('required') &&
                  (PraacticeQueForm.controls['option2'].dirty ||
                    PraacticeQueForm.controls['option2'].touched)
              }"
            >
              <label>Answer B</label>
              <textarea
                [(ngModel)]="option2"
                formControlName="option2"
                placeholder="Type here..."
                type="text"
                name="title"
                required
                tabindex="7"
              ></textarea>
            </div>

            <div
              class="Question"
              [ngClass]="{
                err:
                  PraacticeQueForm.controls['option3'].hasError('required') &&
                  (PraacticeQueForm.controls['option3'].dirty ||
                    PraacticeQueForm.controls['option3'].touched)
              }"
            >
              <label>Answer C</label>
              <textarea
                [(ngModel)]="option3"
                formControlName="option3"
                placeholder="Type here..."
                type="text"
                name="title"
                required
                tabindex="8"
              ></textarea>
            </div>

            <div
              class="Question"
              [ngClass]="{
                err:
                  PraacticeQueForm.controls['option4'].hasError('required') &&
                  (PraacticeQueForm.controls['option4'].dirty ||
                    PraacticeQueForm.controls['option4'].touched)
              }"
            >
              <label>Answer D</label>
              <textarea
                [(ngModel)]="option4"
                formControlName="option4"
                placeholder="Type here..."
                type="text"
                name="title"
                required
                tabindex="9"
              ></textarea>
            </div>

            <div class="w-50">
              <div class="select-box">
                <select
                  [(ngModel)]="CorrectAnswer"
                  formControlName="CorrectAnswer"
                  required
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['CorrectAnswer'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['CorrectAnswer'].dirty ||
                        PraacticeQueForm.controls['CorrectAnswer'].touched)
                  }"
                  tabindex="10"
                >
                  <option value="">Select Correct Answer</option>
                  <option value="1">Option1</option>
                  <option value="2">Option2</option>
                  <option value="3">Option3</option>
                  <option value="4">Option4</option>
                </select>
                <span></span>
              </div>
            </div>

            <div>
              <!-- <label>Type</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="Type"
                  formControlName="Type"
                  required
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['Type'].hasError('required') &&
                      (PraacticeQueForm.controls['Type'].dirty ||
                        PraacticeQueForm.controls['Type'].touched)
                  }"
                  tabindex="11"
                >
                  <option value="">Select Type</option>
                  <option value="VERY SIMPLE">VERY SIMPLE</option>
                  <option value="SIMPLE">SIMPLE</option>
                  <option value="AVERAGE">AVERAGE</option>
                  <option value="ABOVE AVERAGE">ABOVE AVERAGE</option>
                  <option value="HARD">HARD</option>
                  <option value="DIFFICULT">DIFFICULT</option>
                  <option value="TRICKY">TRICKY</option>
                </select>
                <span></span>
              </div>
            </div>
          </div>

          <div class="Question">
            <label>Explanation</label>
            <textarea
              tabindex="13"
              name="Explanation"
              placeholder="Type here..."
              formControlName="Explanation"
              [(ngModel)]="Explanation"
            ></textarea>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="UpdateCatQue()">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="isBulkUpload">
  <div class="cutome-modal-content" style="width: 50%">
    <div class="modal-header">
      <p>Bulk Upload</p>
      <span (click)="closeBulkUploadModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <ngx-dropzone
          maxFileSize="100MB"
          accept=".csv"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label
            >Drag & drop here (or)
            <span class="browse">Browse</span></ngx-dropzone-label
          >
          <div *ngFor="let f of files">
            <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
          </div>
        </ngx-dropzone>
        <div class="format" *ngIf="files.length <= 0">
          <span>Allowed Format: CSV</span>
        </div>
        <div class="format" *ngFor="let f of files">
          <div class="bbulkupload">
            <span *ngFor="let t of f">{{ t.name }} </span>

            <mat-icon (click)="onRemove(f)">close</mat-icon>
          </div>
        </div>
        <div class="format">
          <progress
            value="{{ progress }}"
            max="10"
            id="progressBar"
            *ngIf="selectprogress"
          ></progress>
        </div>
        <div class="format">
          <a (click)="ExportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            Cat_sample.xlsx
          </a>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeBulkUploadModal()">
        Cancel
      </button>
      <button
        class="submit-btn"
        (click)="bulkUpload()"
        [disabled]="selectBulkupload"
      >
        Upload
      </button>
    </div>
  </div>
</div>
