<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Schedule Test</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search.."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="openPopup()">Add Schedule</button>
    </div>
  </div>
  <div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      style="border-spacing: 0 10px"
    >
      <ng-container matColumnDef="srno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let element">
          <span title="{{ element.srno }}">{{ element.srno }}</span>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Template</th>
        <td mat-cell *matCellDef="let element">
          <span title="{{ element.name }}">{{ element.name }}</span>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="test_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Test</th>
        <td mat-cell *matCellDef="let element">
          <span title="{{ element.test_name }}">{{ element.test_name }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Type</th>
        <td mat-cell *matCellDef="let element">
          <span title="{{ element.type }}">{{ element.type }}</span>
        </td>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="start_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let element">
          <span title="{{ element.start_time }}">{{ element.start_time }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="end_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
        <td mat-cell *matCellDef="let element">
          <span title="{{ element.end_time }}">{{ element.end_time }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row">
          <img
            src="../../../assets/images/edit-1.png"
            (click)="editScheduleTime(row)"
            alt="Edit"
            class="action-icon"
          />
          <img
            src="../../../assets/images/remove.png"
            (click)="deleteScheduleTime(row)"
            alt="Delete"
            class="action-icon"
          />
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="page-footer">
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addSchedule">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Schedule</p>
      <p *ngIf="IsUpdate">Edit Schedule</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" novalidate [formGroup]="ScheduleForm">
          <div class="formwrapper">
            <div>
              <label>Template</label>
              <div class="select-box">
                <select
                  [(ngModel)]="SelectedCohort"
                  (change)="onChangeCohort()"
                  formControlName="SelectedCohort"
                  required
                >
                  <option value="">Select</option>
                  <option *ngFor="let item of CohortList" value="{{ item.id }}">
                    {{ item.name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Test Type</label>
              <div class="select-box">
                <select
                  [(ngModel)]="TestType"
                  (change)="onChangeTestType()"
                  [attr.disabled]="this.SelectedCohort == '' ? true : null"
                  formControlName="TestType"
                  required
                >
                  <option value="">Select</option>
                  <option value="CAT">CAT</option>
                  <option value="PRACTICE">Practice</option>
                  <option value="KA">Knowledge Assessment</option>
                  <option value="LIVE">Live</option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label>Select Test</label>
              <div class="select-box">
                <select
                  [(ngModel)]="SelectedTest"
                  (change)="onChangeTest()"
                  [attr.disabled]="this.TestType == '' ? true : null"
                  formControlName="SelectedTest"
                  required
                >
                  <option value="">Select</option>
                  <option *ngFor="let item of TestLists" value="{{ item.id }}">
                    {{ item.TestName }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
          </div>
          <div class="formwrapper">
            <div class="datetime">
              <div class="date">
                <label for="date">Start Date</label>
                <input
                  type="datetime-local"
                  [(ngModel)]="StartDate"
                  formControlName="StartDate"
                />
              </div>
            </div>
            <div class="datetime">
              <div class="date">
                <label for="date">End Date</label>
                <input
                  type="datetime-local"
                  [(ngModel)]="EndDate"
                  formControlName="EndDate"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div class="sub-btn">
        <div *ngIf="!IsUpdate">
          <button
            [disabled]="!ScheduleForm.valid"
            type="button"
            class="submit-btn"
            (click)="saveSchedule()"
          >
            Save
          </button>
        </div>
        <div *ngIf="IsUpdate">
          <button
            [disabled]="!ScheduleForm.valid"
            type="button"
            class="submit-btn"
            (click)="updateSchedule()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
