<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Companies</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Company Name"
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewaddAcronyms()">Add Company</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.name }}">{{ element.name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.address }}">{{ element.address }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addAcronyms">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Company</p>
      <p *ngIf="IsUpdate">Edit Company</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="CertificateForm">
          <div class="formwrapper">
            <div class="input-fill">
              <input
                type="text"
                [(ngModel)]="CompanyName"
                formControlName="CompanyName"
                placeholder="Company Name"
                required
                [ngClass]="{
                  err:
                    CertificateForm.controls['CompanyName'].hasError(
                      'required'
                    ) &&
                    (CertificateForm.controls['CompanyName'].dirty ||
                      CertificateForm.controls['CompanyName'].touched)
                }"
              />
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="21"
                  height="21"
                  viewBox="0 0 500 500"
                >
                  <defs>
                    <style>
                      .cls-1 {
                        filter: url(#filter);
                      }

                      .cls-2,
                      .cls-3 {
                        fill: none;
                        stroke: #7a72b8;
                        fill-rule: evenodd;
                      }

                      .cls-2 {
                        stroke-width: 22px;
                      }

                      .cls-3 {
                        stroke-width: 13px;
                      }
                    </style>
                    <filter id="filter" filterUnits="userSpaceOnUse">
                      <feFlood result="flood" flood-color="#62608f" />
                      <feComposite
                        result="composite"
                        operator="in"
                        in2="SourceGraphic"
                      />
                      <feBlend result="blend" in2="SourceGraphic" />
                    </filter>
                  </defs>
                  <g class="cls-1">
                    <path
                      class="cls-2"
                      d="M78.442,10.173H421.558a24,24,0,0,1,24,24V466.827a24,24,0,0,1-24,24H78.442a24,24,0,0,1-24-24V34.173A24,24,0,0,1,78.442,10.173Z"
                    />
                    <path
                      class="cls-3"
                      d="M136.682,89.737h80.806a6,6,0,0,1,6,6v80.806a6,6,0,0,1-6,6H136.682a6,6,0,0,1-6-6V95.737A6,6,0,0,1,136.682,89.737Z"
                    />
                    <path
                      id="Rectangle_2_copy"
                      data-name="Rectangle 2 copy"
                      class="cls-3"
                      d="M279.216,89.737h80.806a6,6,0,0,1,6,6v80.806a6,6,0,0,1-6,6H279.216a6,6,0,0,1-6-6V95.737A6,6,0,0,1,279.216,89.737Z"
                    />
                    <path
                      id="Rectangle_2_copy_2"
                      data-name="Rectangle 2 copy 2"
                      class="cls-3"
                      d="M136.682,232.271h80.806a6,6,0,0,1,6,6v80.806a6,6,0,0,1-6,6H136.682a6,6,0,0,1-6-6V238.271A6,6,0,0,1,136.682,232.271Z"
                    />
                    <path
                      id="Rectangle_2_copy_2-2"
                      data-name="Rectangle 2 copy 2"
                      class="cls-3"
                      d="M279.216,232.271h80.806a6,6,0,0,1,6,6v80.806a6,6,0,0,1-6,6H279.216a6,6,0,0,1-6-6V238.271A6,6,0,0,1,279.216,232.271Z"
                    />
                    <path
                      class="cls-3"
                      d="M170.031,368.865h159a10,10,0,0,1,10,10V483.26h-179V378.865A10,10,0,0,1,170.031,368.865Z"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <div class="input-fill">
              <input
                type="text"
                [(ngModel)]="Address"
                (focusout)="LocationFocus = false"
                (focus)="LocationFocus = true"
                formControlName="Address"
                placeholder="Company Address"
                required
                [ngClass]="{
                  err:
                    CertificateForm.controls['Address'].hasError('required') &&
                    (CertificateForm.controls['Address'].dirty ||
                      CertificateForm.controls['Address'].touched)
                }"
              />

              <span [ngClass]="{ 'active-icon-fill': LocationFocus }">
                <svg
                  xmlns:svg="http://www.w3.org/2000/svg"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                  id="svg4081"
                  viewBox="0 0 772.51629 772.51628"
                  version="1.1"
                  inkscape:version="0.91 r13725"
                  width="21"
                  height="21"
                  fill="#595690"
                >
                  <path
                    id="path4043"
                    d="m 454.278,160.51201 c -99.484,0 -179.81,80.41 -179.81,179.59 0,31.186 7.9688,60.528 21.938,86.094 l 157.88,264.31 157.88,-264.31 c 13.969,-25.566 21.938,-54.908 21.938,-86.094 0,-99.184 -80.328,-179.59 -179.81,-179.59 z m -2,104.37 c 0.6674,-0.0169 1.3285,0 2,0 42.975,0 77.812,34.838 77.812,77.813 0,42.975 -34.838,77.812 -77.812,77.812 -42.975,0 -77.812,-34.838 -77.812,-77.812 0,-42.303 33.766,-76.747 75.812,-77.813 z"
                    inkscape:connector-curvature="0"
                  />
                </svg>
              </span>
            </div>
            <div class="w-100">
              <textarea
                class="my-text"
                placeholder="Company Notes"
                name="Notes"
                [(ngModel)]="Notes"
                formControlName="Notes"
                [ngClass]="{
                  err:
                    CertificateForm.controls['Notes'].hasError('required') &&
                    (CertificateForm.controls['Notes'].dirty ||
                      CertificateForm.controls['Notes'].touched)
                }"
              ></textarea>
            </div>
          </div>
        </form>
        <div *ngIf="IsUpdate" class="history-div">
          <h3><span class="material-icons"> store </span>Live Class</h3>
          <table
            mat-table
            [dataSource]="dataSourceCohort"
            matSort
            #SourceCohort
            style="border-spacing: 0 10px"
          >
            <ng-container matColumnDef="cohort_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.cohort_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="course_name">
              <th mat-header-cell *matHeaderCellDef>Course</th>
              <td mat-cell *matCellDef="let element">
                {{ element.course_name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="total_students">
              <th mat-header-cell *matHeaderCellDef>Students</th>
              <td mat-cell *matCellDef="let element">
                {{ element.total_students }}
              </td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef>Start Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/images/remove.png"
                  (click)="deleteCohortFromCompany(element)"
                  alt="Delete"
                  class="action-icon"
                />
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsCohort"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsCohort"
            ></tr>
          </table>
          <div *ngIf="!dataSourceCohort" class="no-record">
            No Cohort Assign
          </div>
          <mat-paginator
            style-paginator
            #SourceCohort
            [showTotalPages]="0"
            [length]="dataSourceCohort.data ? dataSourceCohort.data.length : 0"
            [pageSize]="10"
            *ngIf="matcohortpagination"
            [pageSizeOptions]="[10, 20, 50, 100, 500]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
        <div *ngIf="IsUpdate" class="history-div">
          <h3><span class="material-icons"> people </span>Clients</h3>
          <table
            mat-table
            [dataSource]="dataSourceClient"
            #SourceClient
            matSort
            style="border-spacing: 0 10px"
          >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="Email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="mobile">
              <th mat-header-cell *matHeaderCellDef>Phone</th>
              <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/images/remove.png"
                  (click)="deleteClientFromCompany(element)"
                  alt="Delete"
                  class="action-icon"
                />
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsClient"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsClient"
            ></tr>
          </table>
          <mat-paginator
            style-paginator
            #SourceClient
            [showTotalPages]="0"
            [length]="dataSourceClient.data ? dataSourceClient.data.length : 0"
            [pageSize]="10"
            *ngIf="matClientpagination"
            [pageSizeOptions]="[10, 20, 50, 100, 500]"
            showFirstLastButtons
          >
          </mat-paginator>
          <div *ngIf="!dataSourceClient" class="no-record">
            No Client Assign
          </div>
        </div>

        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="UpdateCertificate()">Save</button>
      </div>
    </div>
  </div>
</div>
