import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { TemplateService } from '../../services/template/template.service';

@Component({
  selector: 'app-assigned-materials',
  templateUrl: './assigned-materials.component.html',
  styleUrls: ['./assigned-materials.component.scss'],
})
export class AssignedMaterialsComponent implements OnInit {
  Certificatelist = [];
  SelectedCertificate: any;
  Materials = [];
  constructor(
    public templateService: TemplateService,
    public cohortService: CohortService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {}

  async ngOnInit() {
    await this.spinner.show();
    await this.ListCertificate();
    await this.spinner.hide();
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.spinner.show();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data;
            this.SelectedCertificate = '';
          }
        });
        this.spinner.hide();
      }
    });
  }
  async changeCertificate() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .certiWiseMaterials(this.SelectedCertificate, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.Materials = list.data;
            }
          });
        this.spinner.hide();
      }
    });
  }
}
export interface Topiclist {
  srno: number;
  id: number;
  student_name: string;
  email: string;
}
