import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ServicesService } from '../../services.service';
import { CompanyService } from '../../services/company/company.service';
import { ClientService } from '../../services/client/client.service';
import { formatDate } from '@angular/common';
import { CohortService } from '../../services/cohort/cohort.service';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  LocationFocus: boolean = false;
  displayedColumns: string[] = ['srno', 'name', 'address', 'actions'];
  displayedColumnsClient: string[] = ['name', 'Email', 'mobile', 'actions'];
  displayedColumnsCohort: string[] = [
    'cohort_name',
    'course_name',
    'total_students',
    'createdAt',
    'actions',
  ];
  dataSource: MatTableDataSource<certificatelist>;
  dataSourceClient: MatTableDataSource<ClientLists>;
  dataSourceCohort: MatTableDataSource<CohortLists>;
  Search: any;
  addAcronyms: boolean = false;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @Input('matClientpagination') matClientpagination: boolean;
  @ViewChildren(MatPaginator) set matSourceCohort(
    SourceCohort: QueryList<MatPaginator>,
  ) {
    if (this.matcohortpagination) {
      this.dataSourceCohort.paginator = SourceCohort.toArray()[1];
    }
  }
  @Input('matcohortpagination') matcohortpagination: boolean;
  @ViewChildren(MatPaginator) set matSourceClient(
    SourceClient: QueryList<MatPaginator>,
  ) {
    if (this.matClientpagination) {
      this.dataSourceClient.paginator = SourceClient.toArray()[2];
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatPaginator) SourceCohort = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortCohort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) SourceClient = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortClient = new QueryList<MatSort>();
  Address: any;
  CompanyName: any;
  Notes: any;
  id: any;
  IsUpdate: boolean = false;
  loglist = [];
  public CertificateForm: FormGroup;
  oldAuditValueArray = { Address: '', 'Company Name': '', Notes: '' };
  newAuditValueArray = { Address: '', 'Company Name': '', Notes: '' };
  auditHistory = '';
  constructor(
    public cohortService: CohortService,
    public clientService: ClientService,
    public companyService: CompanyService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  closeModal() {
    this.addAcronyms = false;
    this.Address = '';
    this.CompanyName = '';
    this.Notes = '';
    this.oldAuditValueArray = { Address: '', 'Company Name': '', Notes: '' };
    this.newAuditValueArray = { Address: '', 'Company Name': '', Notes: '' };
    this.auditHistory = '';
  }
  addNewaddAcronyms() {
    this.addAcronyms = true;
    this.IsUpdate = false;
  }
  async ngOnInit() {
    this.CertificateForm = this.fb.group({
      Address: ['', Validators.required],
      CompanyName: ['', Validators.required],
      Notes: ['', Validators.required],
    });
    await this.spinner.show();
    await this.GetAllCompany();
  }
  async deleteClientFromCompany(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to remove client from ' + this.CompanyName + ' company',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      try {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.clientService
                  .UpdateClient(
                    item.id,
                    item.name,
                    'null',
                    item.mobile,
                    item.address,
                    item.notes,
                    token,
                  )
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();
                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.name +
                              '</span> Client is removed from company by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'companies',
                              item.id,
                              token,
                            );
                          }
                        });
                      const index = this.dataSourceClient.data.indexOf(item.id);
                      this.dataSourceClient.data.splice(index, 1);
                      this.dataSourceClient._updateChangeSubscription();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Client has been removed.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        }
      } catch (e) {
        await this.spinner.hide();
      }
    });
  }
  async deleteCohortFromCompany(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to remove cohort from ' + this.CompanyName + ' company',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      try {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.cohortService
                  .UpdateCohortWithCompanyIdNull(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();
                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.cohort_name +
                              '</span> Cohort is removed from company by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'companies',
                              item.id,
                              token,
                            );
                          }
                        });
                      const index = this.dataSourceCohort.data.indexOf(item.id);
                      this.dataSourceCohort.data.splice(index, 1);
                      this.dataSourceCohort._updateChangeSubscription();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Cohort has been removed.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        }
      } catch (e) {
        await this.spinner.hide();
      }
    });
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      try {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.companyService
                  .DeleteCompany(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();
                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.name +
                              '</span> Company is deleted by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'companies',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.GetAllCompany();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Company has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        }
      } catch (e) {
        await this.spinner.hide();
      }
    });
  }
  async GetAllCompany() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: certificatelist[] = [];
            await this.companyService
              .GetAllCompany(token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  await this.spinner.hide();
                  var list = JSON.parse(<string>data[0].json);
                  var srno = 0;
                  await list.data.forEach(async (element) => {
                    srno = srno + 1;
                    await users.push({
                      srno: srno,
                      id: element.id,
                      name: element.name,
                      address: element.address,
                      notes: element.notes,
                      createdAt: element.createdAt,
                      updatedAt: element.updatedAt,
                      clients: element.clients,
                      cohorts: element.cohorts,
                    });
                  });
                  this.dataSource = new MatTableDataSource(users);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.filter();
                  this.pagination = true;
                }
              });
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async edit(item) {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('companies', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    this.id = item.id;
    this.CompanyName = item.name;
    this.Address = item.address;
    this.Notes = item.notes;
    this.IsUpdate = true;
    this.addAcronyms = true;
    let cSr = 0;
    const clients: ClientLists[] = [];
    this.dataSourceClient = null;
    this.matClientpagination = false;
    if (item.clients.length > 0) {
      item.clients.forEach((client) => {
        cSr = cSr + 1;
        clients.push({
          srno: cSr,
          id: client.id,
          name: client.name,
          email: client.email,
          mobile: client.mobile,
          address: client.address,
          notes: client.notes,
        });
      });
      this.dataSourceClient = new MatTableDataSource(clients);
      this.dataSourceClient.paginator = this.SourceClient.toArray()[2];
      this.dataSourceClient.sort = this.sortClient.toArray()[2];
      this.matClientpagination = true;
    }
    let coSr = 0;
    const cohorts: CohortLists[] = [];
    this.dataSourceCohort = null;
    this.matcohortpagination = false;
    if (item.cohorts.length > 0) {
      item.cohorts.forEach((client) => {
        coSr = coSr + 1;
        let dt = formatDate(client.createdAt, 'yyyy-MM-dd, hh:mm', 'en_US');
        cohorts.push({
          srno: coSr,
          id: client.id,
          cohort_name: client.cohort_name,
          course_name: client.cource.course_name,
          createdAt: dt,
          total_students: client.userslist.length,
        });
      });
      this.dataSourceCohort = new MatTableDataSource(cohorts);
      this.dataSourceCohort.paginator = this.SourceCohort.toArray()[1];
      this.dataSourceCohort.sort = this.sortCohort.toArray()[1];
      this.matcohortpagination = true;
    }
    this.oldAuditValueArray = {
      Address: this.Address,
      'Company Name': this.CompanyName,
      Notes: this.Notes,
    };
  }
  async onSubmit() {
    this.markFormTouched(this.CertificateForm);
    if (this.CertificateForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.companyService
              .AddCompany(this.CompanyName, this.Address, this.Notes, token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.CompanyName +
                          '</span> Company is added by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'companies',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  this.addAcronyms = false;
                  await this.GetAllCompany();
                  await this.spinner.hide();
                  this.service.ShowSuccess('Company add successfully');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async UpdateCertificate() {
    this.markFormTouched(this.CertificateForm);
    if (this.CertificateForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.companyService
              .UpdateCompany(
                this.id,
                this.CompanyName,
                this.Address,
                this.Notes,
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();
                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        this.newAuditValueArray = {
                          Address: this.Address,
                          'Company Name': this.CompanyName,
                          Notes: this.Notes,
                        };
                        var audit = this.auth.compareAuditHist(
                          this.oldAuditValueArray,
                          this.newAuditValueArray,
                        );
                        var desc = audit;
                        if (desc != '')
                          await this.service.addAdminLogs(
                            desc,
                            'companies',
                            this.id,
                            token,
                          );
                      }
                    });
                  this.addAcronyms = false;
                  this.IsUpdate = false;
                  await this.GetAllCompany();
                  await this.spinner.hide();
                  this.service.ShowSuccess('Acronyms update successfully');
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface certificatelist {
  srno: number;
  id: string;
  name: string;
  address: string;
  notes: string;
  createdAt: string;
  updatedAt: string;
  clients: string[];
  cohorts: string[];
}
export interface ClientLists {
  srno: number;
  id: string;
  name: string;
  email: string;
  mobile: string;
  address: string;
  notes: string;
}
export interface CohortLists {
  srno: number;
  id: string;
  cohort_name: string;
  course_name: string;
  createdAt: string;
  total_students: string;
}
