import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  OnChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { AdminService } from '../../services/admin/admin.service';
import { ClientService } from '../../services/client/client.service';
import { CompanyService } from '../../services/company/company.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { SelectionModel } from '@angular/cdk/collections';
import * as XLSX from 'xlsx';
import { ExcelService } from 'src/app/services/excelservices/excel.service';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss'],
})
export class StudentComponent implements OnInit {
  SortingData = {
    active: '',
    direction: '',
  };
  uploadPercent = 0;
  public LoginForm: FormGroup;
  public EmailChangeForm: FormGroup;
  IsUpdate: boolean = false;
  displayedColumns: string[] = [
    'SrNo',
    'name',
    'email',
    'company_name',
    'chortname',
    'FreeFlashCissp',
    'FreeFlashCCSP',
    'actions',
  ];
  dataSource: MatTableDataSource<UsersList>;
  displayedColumnsCohort: string[] = [
    'checkbox',
    'cohort_name',
    'studentCount',
    'start_date',
  ];
  dataSourceCohort: MatTableDataSource<CohortList>;
  displayedColumnsSelfPaced: string[] = [
    'course_name',
    'enrollment_date',
    'start_date',
    'expiry_date',
    'exam_date',
    'FreeFlashCissp',
    'praticeque',
    'amount_paid',
    'actions',
  ];
  dataSourceSelfPaced: MatTableDataSource<SelfPacedList>;
  selection = new SelectionModel<CohortList>(true, []);
  bkpselection = [];
  Search: any;
  email: any;
  Password: any;
  mobile: any;
  ExamDate: any;
  alias_name: any;
  Confirmpass: any;
  firstname: any;
  editStudent: boolean = false;
  addStudent: boolean = false;
  EmailList = [];
  Id: any;
  pageNo: number;
  totalUsers: number;
  SrNo: number = 0;
  loglist = [];
  Companies = [];
  SelectedCompany: any;
  bulkSelectedCompany: any;
  ForceChange: boolean = false;
  AllowAccess: boolean = true;
  Avatar: any;
  FashFullAccess: boolean = false;
  PraAppFullAccess: boolean = false;
  AccountActivation: boolean = false;
  is_temp: boolean = false;
  OptOut: boolean = false;
  Certificatelist = [];
  SelectedCertificate: any;
  bulkSelectedCertificate: any;
  isDisableEnroll: boolean = true;
  ExpiryDate: any;
  Cohorts: CohortList[];
  bkpCohorts: CohortList[];
  SelectedLiveOrCohort: any;
  isChangeDate: boolean = false;
  CourseName: any;
  EditExpiryDate: any;
  EditEnrExpiryDate: any;
  EditPayId: any;
  Location: any;
  time_zone: any;
  Is_Selpaced: any;
  isenroll: boolean = false;
  isBulkUpload: boolean = false;
  files: File[] = [];
  myEvent: any;
  progress: any = 0;
  selectBulkupload: boolean = false;
  selectprogress: boolean = false;
  displayedColumnsbulkdata: string[] = ['SrNo', 'name', 'email', 'message'];
  bulkuploadDataSource: MatTableDataSource<BulkuploadData>;
  bulkuploadrespose: BulkuploadData[];
  is_fileupload: boolean = false;
  is_optionFileUpload: boolean = false;
  is_bulkuploadCheck: any;
  bulktotaluser = 0;
  newUser = 0;
  alreadyuser = 0;
  cohortadduser = 0;
  cohortadready = 0;
  FaildUser = 0;
  FaildcohortUser = 0;
  fileRespose: boolean = false;
  oldAuditValueArray = {
    Firstname: '',
    'Alias name': '',
    'Company Name': '',
    'Time Zone': '',
    Cohort: '',
  };
  newAuditValueArray = {
    Firstname: '',
    'Alias name': '',
    'Company Name': '',
    'Time Zone': '',
    Cohort: '',
  };
  auditHistory = '';
  PaginationCount: boolean = false;
  Mentors = [];
  SelectedMentor = '';
  changeUserEmailPopup: boolean = false;
  linkPopup: boolean = false;
  selectedStudentLink = [];
  changedEmail = '';
  @Input('pagination') pagination: boolean;
  @ViewChildren(MatPaginator) set matquePaginator(
    paginator: QueryList<MatPaginator>,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator.toArray()[0];
    }
  }
  countuser: any;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('matSort1') matSort1: MatSort;
  constructor(
    public cohortService: CohortService,
    public companyService: CompanyService,
    public clientService: ClientService,
    public adminService: AdminService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    private excelService: ExcelService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  async ngOnInit() {
    this.LoginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
          ),
        ]),
      ],
      firstname: ['', Validators.required],
      SelectedCompany: ['', Validators.required],
      alias_name: new FormControl(),
      time_zone: new FormControl(),
      SelectedMentor: new FormControl(),
    });
    this.EmailChangeForm = this.fb.group({
      changedEmail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
          ),
        ]),
      ],
    });
    this.time_zone = '';
    await this.spinner.show();
    await this.getAllCohorts();
    await this.getAllMentors();
    await this.getAllUsers(
      1,
      this.paginator.toArray()[0] ? this.paginator.toArray()[0].pageSize : 10,
      '',
    );
    setTimeout(() => {
      this.spinner.show();
      this.Search = 'y';
      this.filter();
      setTimeout(() => {
        this.Search = '';
        this.filter();
      }, 50);
      this.spinner.hide();
    }, 50);
    this.pagination = true;
    await this.getAllCompanies();
    await this.ListCertificate();
    await this.spinner.hide();
    this.pageNo = 0;
  }
  async changeUserEmail() {
    this.markFormTouched(this.EmailChangeForm);
    if (this.EmailChangeForm.valid) {
      this.spinner.show();
      let old_email = this.email;
      let new_email = this.changedEmail;
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.service
              .changeUserEmail(old_email, new_email, token)
              .then(async (data) => {
                var list = JSON.parse(<string>data[0].json);
                if (data[0].status == 200) {
                  this.changeUserEmailPopup = false;
                  this.changedEmail = '';
                  await this.getAllUsers(
                    1,
                    this.paginator.toArray()[0].pageSize,
                    this.Search,
                  );
                  this.closeModal();
                } else {
                  this.service.ShowError(list.message);
                }
              });
            this.spinner.hide();
          }
        });
    }
  }
  countView() {
    this.Search = 'y';
    this.filter();
    this.Search = '';
    this.filter();
  }
  gotoCohortReport(row) {
    let data = {
      user_id: row.user_id,
      cohortIds: row.cohort_id,
    };
    let post = btoa(JSON.stringify(data));
    this.router.navigate([`reports/${post}`]);
  }
  gotoPaymentReceipt(row) {
    let data = {
      id: row.id,
    };
    let post = btoa(JSON.stringify(data));
    this.router.navigate([`paymentreceipt/${post}`]);
  }
  async removeStudentFromEnroll(row) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this enrollments?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.adminService
                .removeStudentFromEnroll(
                  row.id,
                  row.user_id,
                  row.cohort_id,
                  token,
                )
                .then(async (data) => {
                  if (data[0].status == 200) {
                    var bkp = [];
                    this.bkpselection.forEach((element) => {
                      if (element != row.cohort_id) {
                        bkp.push(element);
                      }
                    });
                    this.bkpselection = bkp;
                    await this.getEnrollmentDetails(row.user_id, token);
                    this.spinner.hide();
                    this.service.ShowSuccess('Successfully deleted');
                  }
                });
              this.spinner.hide();
            }
          });
      }
    });
  }
  async resetProgress(row) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to reset a user's progress?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.clientService
                .resetProgress(row.user_id, row.cohort_id, token)
                .then();
              this.spinner.hide();
            }
          });
      }
    });
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data;
            this.SelectedCertificate = '';
          }
        });
        this.spinner.hide();
      }
    });
  }
  async changeCertificate() {
    this.SelectedLiveOrCohort = '';
    this.Is_Selpaced = 0;
    this.Cohorts = this.bkpCohorts.filter(
      (x) =>
        x.course_type == null &&
        x.course_type == '' &&
        x.certi_id == this.SelectedCertificate,
    );
    this.dataSourceCohort = new MatTableDataSource(this.Cohorts);
    this.dataSourceCohort.sort = this.matSort1;
  }
  async bulkchangeCertificate() {
    this.SelectedLiveOrCohort = '';
    this.Is_Selpaced = 0;
    this.Cohorts = this.bkpCohorts.filter(
      (x) =>
        x.course_type == null &&
        x.course_type == '' &&
        x.certi_id == this.bulkSelectedCertificate,
    );
    this.dataSourceCohort = new MatTableDataSource(this.Cohorts);
    this.dataSourceCohort.sort = this.matSort1;
  }
  async enrollStudentInClass() {
    var is_already_user = false;
    this.spinner.show();
    await this.service
      .versions_getUserByEmail(this.email)
      .then(async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          if (list.data.length <= 0) {
            await this.submit();
          } else {
            if (!this.editStudent) {
              this.service.ShowError('User already existed');
              is_already_user = true;
              this.spinner.hide();
            }
          }
        }
      });
    if (is_already_user) {
      return;
    }
    await this.spinner.show();
    let cohortIds = '';
    for (let i = 0; i < this.selection.selected.length; i++) {
      cohortIds = cohortIds + this.selection.selected[i].id + ',';
    }
    if (cohortIds.endsWith(',') == true) {
      cohortIds = cohortIds.substring(0, cohortIds.length - 1);
    }
    let date = new Date(new Date(this.ExpiryDate).toDateString());
    let today = new Date(new Date().toDateString());
    var Difference_In_Time = date.getTime() - today.getTime();
    var diffDays = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.cohortService
          .addMultipleStudentCohort(cohortIds, this.Id, token)
          .then(async (res) => {
            for (let i = 0; i < this.selection.selected.length; i++) {
              if (!this.bkpselection.includes(this.selection.selected[i].id)) {
                let access_dur = {
                  user_id: this.Id,
                  cohort_id: this.selection.selected[i].id,
                  plan_id: this.selection.selected[i].method_id,
                  plan_type: this.selection.selected[i].course_type,
                  access_duration: diffDays,
                };
                await this.adminService
                  .addUserAccessDuration(access_dur)
                  .then(async (result) => {
                    if (result[0].status == 200) {
                      let save_transaction = {
                        user_id: this.Id,
                        payment_type: this.selection.selected[i].course_type,
                        plan_id: this.selection.selected[i].method_id,
                        access_duration: diffDays,
                        amount: 'Enroll by admin',
                        certi_id: this.SelectedCertificate,
                        cohort_id: this.selection.selected[i].id,
                      };
                      await this.adminService
                        .savePaymentTransaction(save_transaction)
                        .then((trn) => {
                          this.spinner.hide();
                          Swal.fire(
                            '',
                            'Student successfully enrolled in ' +
                              this.selection.selected[i].cohort_name,
                            'success',
                          );
                        });
                      this.isenroll = true;
                    }
                  });
              }
            }
            this.paginator.toArray()[0].pageIndex = 0;
            this.ExpiryDate = '';
            await this.getEnrollmentDetails(this.Id, token);
            await this.getAllUsers(1, this.paginator.toArray()[0].pageSize, '');
          });
      }
    });
  }
  changeCompany() {
    this.SelectedCertificate = '';
    this.SelectedLiveOrCohort = '';
  }
  bulkchangeCompany() {
    this.SelectedCertificate = '';
    this.SelectedLiveOrCohort = '';
  }
  changeLiveOrCohort() {
    this.isDisableEnroll = false;
    if (this.is_bulkuploadCheck) {
      this.selection.clear();
      this.selectBulkupload = false;
    }
    let course_type = this.SelectedLiveOrCohort;
    if (course_type == 'Live Class') {
      this.Cohorts = this.bkpCohorts.filter(
        (x) =>
          (x.course_type == null || x.course_type == '') &&
          x.certi_id == this.SelectedCertificate &&
          x.company_id == this.SelectedCompany,
      );
      this.Is_Selpaced = 0;
    } else if (course_type == 'Self-paced Class') {
      this.Cohorts = this.bkpCohorts.filter(
        (x) =>
          (x.course_type == 'subscription' ||
            x.course_type == 'onetime' ||
            x.course_type == 'Free') &&
          x.certi_id == this.SelectedCertificate &&
          x.company_id == this.SelectedCompany,
      );
      this.Is_Selpaced = 1;
    } else if (course_type == 'Hybrid Class') {
      this.Cohorts = this.bkpCohorts.filter(
        (x) =>
          x.course_type == 'hybrid' &&
          x.certi_id == this.SelectedCertificate &&
          x.company_id == this.SelectedCompany,
      );
      this.Is_Selpaced = 0;
    } else {
      this.Cohorts = this.bkpCohorts.filter(
        (x) =>
          x.course_type == null &&
          x.course_type == '' &&
          x.certi_id == this.SelectedCertificate &&
          x.company_id == this.SelectedCompany,
      );
      this.Is_Selpaced = 0;
    }
    this.dataSourceCohort = new MatTableDataSource(this.Cohorts);
    this.dataSourceCohort.sort = this.matSort1;
  }
  gotoStudentReport(row) {
    if (row.cohorts.length > 0) {
      let cohortIds = row.cohorts.map((a) => a.id);
      let data = {
        email: row.email,
        user_id: row.id,
        cohortIds: cohortIds.join(','),
      };
      let post = btoa(JSON.stringify(data));
      this.router.navigate([`reports/${post}`]);
    } else {
      Swal.fire('', 'No Activities');
    }
  }
  gotoStudentResult(row) {
    if (row.cohorts.length > 0) {
      let cohortIds = row.cohorts.map((a) => a.id);
      let data = {
        email: row.email,
        user_id: row.id,
        cohortIds: cohortIds.join(','),
      };
      let post = btoa(JSON.stringify(data));
      this.router.navigate([`result/${post}`]);
    } else {
      Swal.fire('', 'No Result');
    }
  }
  async getAllCompanies() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.companyService.GetAllCompany(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Companies = list.data;
            this.SelectedCompany = '';
          }
        });
      }
    });
  }
  async getAllCohorts() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.cohortService.getAllCohort(token).then(async (data) => {
          if (data[0].status == 200) {
            this.Cohorts = [];
            var list = JSON.parse(<string>data[0].json);
            list.data.forEach((element) => {
              this.Cohorts.push({
                id: element.id,
                cohort_name: element.cohort_name,
                certificate_name: element.certificate.certificate_name,
                studentCount: element.user.length,
                start_date: element.start_date,
                certi_id: element.certi_id,
                user: element.user,
                course_type: element.course_type,
                company_id: element.company_id,
                method_id: element.method_id,
              });
            });
            this.bkpCohorts = this.Cohorts;
            this.dataSourceCohort = new MatTableDataSource(this.Cohorts);
            this.dataSourceCohort.sort = this.matSort1;
          }
        });
      }
    });
    await this.spinner.hide();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceCohort.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSourceCohort.data.forEach((row) => this.selection.select(row));
  }
  addNewStudent() {
    this.addStudent = true;
    this.editStudent = false;
    this.AllowAccess = true;
    this.LoginForm.controls['email'].enable();
  }
  closeDateModal() {
    this.isChangeDate = false;
    this.CourseName = '';
    this.EditPayId = '';
    this.EditExpiryDate = '';
    this.EditEnrExpiryDate = '';
  }
  openDateModal(item) {
    this.CourseName = item.course_name;
    this.EditPayId = item.id;
    this.EditExpiryDate = new Date(item.expiry_date);
    this.EditEnrExpiryDate = item.enrollment_date;
    this.isChangeDate = true;
  }
  async updateExpiryDate() {
    let date = this.EditExpiryDate;
    let today = new Date(new Date(this.EditEnrExpiryDate).toDateString());
    var Difference_In_Time = date.getTime() - today.getTime();
    var diffDays = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    let data = {
      id: this.EditPayId,
      access_duration: diffDays,
    };
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.adminService
          .updatePaymentTransaction(data)
          .then(async (trn) => {
            await this.getEnrollmentDetails(this.Id, token);
            this.spinner.hide();
            this.closeDateModal();
            Swal.fire('', 'Expiry date change successfully', 'success');
          });
      }
    });
  }
  closeModal() {
    this.IsUpdate = false;
    this.addStudent = false;
    this.editStudent = false;
    this.firstname = '';
    this.email = '';
    this.mobile = '';
    this.SelectedCompany = '';
    this.SelectedMentor = '';
    this.Avatar = '';
    this.FashFullAccess = false;
    this.PraAppFullAccess = false;
    this.AccountActivation = false;
    this.AllowAccess = false;
    this.OptOut = false;
    this.selection = new SelectionModel<CohortList>(true, []);
    this.bkpselection = [];
    this.SelectedCertificate = '';
    this.Cohorts = [];
    this.Location = '';
    this.time_zone = '';
    this.dataSourceCohort = new MatTableDataSource(this.bkpCohorts);
    this.dataSourceCohort.sort = this.matSort1;
    this.time_zone = '';
    this.alias_name = '';
    this.isenroll = false;
    this.oldAuditValueArray = {
      Firstname: '',
      'Alias name': '',
      'Company Name': '',
      'Time Zone': '',
      Cohort: '',
    };
    this.newAuditValueArray = {
      Firstname: '',
      'Alias name': '',
      'Company Name': '',
      'Time Zone': '',
      Cohort: '',
    };
    this.auditHistory = '';
  }
  async resendTempPassword() {
    this.spinner.show();
    await this.auth.ResendPasswordTemp(this.email).then((data) => {
      this.spinner.hide();
      if (data[0].StatusCode == 1) {
        this.service.ShowSuccess(data[0].message);
      } else {
        this.service.ShowError(data[0].message);
      }
    });
    this.spinner.hide();
  }
  get f() {
    return this.LoginForm.controls;
  }
  async pageEvents(event: any) {
    await this.spinner.show();
    await this.getAllUsers(
      event.pageIndex + 1,
      event.pageSize,
      this.Search,
      this.SortingData.active,
      this.SortingData.direction,
    );
    await this.spinner.hide();
  }
  delete(element) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.auth.deleteuser(element.email).then(async (data) => {
                if (data[0].StatusCode == 1) {
                  await this.clientService
                    .deleteuser(element.id, token)
                    .then(async (userdata) => {
                      if (userdata[0].status == 200) {
                        await this.getAllUsers(
                          1,
                          this.paginator.toArray()[0].pageSize,
                          this.Search,
                        );
                        this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              await this.spinner.hide();
                              this.router.navigate(['/login']);
                            } else {
                              var token = session.getIdToken().getJwtToken();
                              var e = window.localStorage.getItem('adminUser');
                              if (e == null || e == undefined || e == '') {
                                e = 'admin';
                              }
                              var desc =
                                element.email + ' User is deleted by ' + e;
                              await this.service.addAdminLogs(
                                desc,
                                'student',
                                element.id,
                                token,
                              );
                            }
                          });
                        await this.spinner.hide();
                        Swal.fire(
                          'Deleted!',
                          'Deleted user successfully',
                          'success',
                        );
                      } else {
                        await this.spinner.hide();
                        await this.service.ShowError(
                          'Deleted user unsuccessfully',
                        );
                      }
                    });
                } else {
                  await this.clientService
                    .deleteuser(element.id, token)
                    .then(async (userdata) => {
                      if (userdata[0].status == 200) {
                        await this.getAllUsers(
                          1,
                          this.paginator.toArray()[0].pageSize,
                          this.Search,
                        );
                        await this.spinner.hide();
                        Swal.fire(
                          'Deleted!',
                          'Deleted user successfully',
                          'success',
                        );
                      } else {
                        await this.spinner.hide();
                        await this.service.ShowError(
                          'Deleted user unsuccessfully',
                        );
                      }
                    });
                }
              });
            }
          });
      }
    });
  }
  async getAllUsers(pageNo, limit, search, active = '', direction = '') {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: UsersList[] = [];
            if (!this.Search) {
              this.Search = '';
            }
            this.EmailList = [];
            await this.clientService
              .getLimitStudent(pageNo, limit, search, active, direction, token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  this.SrNo = (pageNo - 1) * limit;
                  this.totalUsers = list.data.count;
                  await list.data.rows.forEach(async (element) => {
                    if (element.roll_id == 0 || element.roll_id == 3) {
                      var chortname = '';
                      let links = [];
                      if (element.notes.length > 0) {
                        for (let i = 0; i < element.notes.length; i++) {
                          chortname =
                            chortname + element.notes[i].cohort_name + ' | ';
                          let usr = {
                            user_id: element.id,
                            cohort: element.notes[i].id,
                            date: '',
                          };
                          let user_id = btoa(JSON.stringify(usr));
                          let my_link =
                            'https://www.my.destcert.com/welcomertemp/' +
                            user_id;
                          links.push({
                            cohort_name: element.notes[i].cohort_name,
                            link: my_link,
                          });
                        }
                        if (chortname.endsWith(' | ') == true) {
                          chortname = chortname.substring(
                            0,
                            chortname.length - 2,
                          );
                        }
                      }
                      this.EmailList.push(element.email);
                      this.SrNo = this.SrNo + 1;
                      await users.push({
                        SrNo: this.SrNo,
                        id: element.id,
                        name: element.name,
                        email: element.email,
                        roll_id: element.roll_id,
                        email_verified_at: element.email_verified_at,
                        email_verified: element.email_verified,
                        mobile: element.mobile,
                        profile_img: element.profile_img,
                        permissions: element.permissions,
                        password: element.password,
                        status: element.status,
                        createdAt: element.createdAt,
                        updatedAt: element.updatedAt,
                        free_flashcard: element.free_flashcard,
                        free_flashccsp: element.free_flashccsp,
                        chortname: chortname,
                        allow_access: element.allow_access,
                        schedule_date: element.schedule_date,
                        company_id: element.company_id,
                        avatar_url: element.avatar_url,
                        opt_in: element.opt_in,
                        cohorts: element.notes,
                        aliasname: element.aliasname,
                        is_temp: element.is_temp,
                        time_zone: element.user_time_zone,
                        company_name:
                          element.stripe_customer_id.length > 0
                            ? element.stripe_customer_id[0].name
                            : '-',
                        mentor_id: element.mentor_id,
                        links: links,
                      });
                    }
                  });
                  this.dataSource = new MatTableDataSource(users);
                  this.dataSource.sort = this.sort.toArray()[0];
                  if (!this.dataSource.paginator.initialized) {
                    this.dataSource.paginator = this.paginator.toArray()[0];
                    this.pagination = true;
                  }
                } else {
                  this.spinner.hide();
                }
              });
          }
        });
    } catch (e) {
      this.spinner.hide();
    }
  }
  copyToClipboard(link: string) {
    navigator.clipboard.writeText(link);
  }
  welcomeLinkClick(links) {
    this.selectedStudentLink = links;
    this.linkPopup = true;
  }
  async Checkboxselect(value, element) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        if (value == true) {
          element.free_flashcard = '2';
          this.adminService
            .free_purchase(element.id, '2', token)
            .then((datapur) => {});
        }
        if (value == false && element.free_flashcard == '2') {
          element.free_flashcard = '0';
          this.adminService
            .free_purchase(element.id, '0', token)
            .then((datapur) => {});
        }
      }
    });
  }
  async CCSPCheckboxselect(value, element) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        if (value == true) {
          element.free_flashccsp = '2';
          this.adminService
            .CCSPfree_purchase(element.id, '2', token)
            .then((datapur) => {});
        }
        if (value == false && element.free_flashccsp == '2') {
          element.free_flashccsp = '0';
          this.adminService
            .CCSPfree_purchase(element.id, '0', token)
            .then((datapur) => {});
        }
      }
    });
  }
  async filter() {
    this.paginator.toArray()[0].pageIndex = 0;
    await this.getAllUsers(
      this.paginator.toArray()[0].pageIndex + 1,
      this.paginator.toArray()[0].pageSize,
      this.Search,
    );
    this.paginator.toArray()[0]._changePageSize(10);
  }
  async getEnrollmentDetails(id, token) {
    let SelfPaced: SelfPacedList[] = [];
    await this.adminService.getEnrollmentDetails(id, token).then(
      async (value) => {
        if (value[0].status == 200) {
          var list = JSON.parse(<string>value[0].json);
          list.data.forEach((element) => {
            let exp = new Date(
              new Date(element.createdAt).setDate(
                new Date(element.createdAt).getDate() +
                  parseInt(element.access_duration),
              ),
            );
            SelfPaced.push({
              id: element.id,
              amount_paid: element.amount,
              certi_id: element.certi_id,
              certificate_name: element.certificate.certificate_name,
              course_name: element.email ? element.email.price_label : '',
              flashcard_access: element.email
                ? element.email.flashcard_access
                : 0,
              question_access: element.email
                ? element.email.question_access
                : 0,
              enrollment_date: element.createdAt,
              expiry_date: exp.toDateString(),
              subscription_type: element.payment_type,
              access_duration: element.access_duration,
              start_date: element.createdAt.toString(),
              exam_date: element.exam_date,
              exam_date_class: 's-examdate',
              cohort_id: element.cohort_id,
              user_id: element.user_id,
            });
          });
          this.dataSourceSelfPaced = new MatTableDataSource(SelfPaced);
          this.selection = new SelectionModel<CohortList>(true, []);
          this.dataSourceCohort.data.forEach((row) => {
            this.dataSourceSelfPaced.data.forEach((element) => {
              if (
                element.certi_id == row.certi_id &&
                element.subscription_type == row.course_type &&
                element.course_name == row.cohort_name
              ) {
                this.selection.select(row);
                if (element.certi_id == row.certi_id) {
                  if (!this.bkpselection.includes(row.certi_id))
                    this.bkpselection.push(row.id);
                }
              }
            });
          });
          await this.spinner.hide();
        } else {
          var list = JSON.parse(<string>value[0].json);
          this.service.ShowError(list.message);
        }
      },
      (err) => {
        this.spinner.hide();
      },
    );
  }
  async edit(item) {
    await this.spinner.show();
    this.LoginForm.controls['email'].disable();
    this.addStudent = true;
    this.editStudent = true;
    this.IsUpdate = true;
    this.Id = item.id;
    this.AllowAccess = item.allow_access;
    this.ForceChange = false;
    this.firstname = item.name;
    this.email = item.email;
    this.ExamDate = item.schedule_date;
    this.Avatar = item.avatar_url;
    this.SelectedCompany = item.company_id == null ? '' : item.company_id;
    this.SelectedMentor = item.mentor_id == null ? '' : item.mentor_id;
    this.OptOut = item.opt_in == 0 ? true : false;
    this.mobile = item.mobile;
    this.alias_name = item.aliasname;
    this.is_temp = item.is_temp;
    this.time_zone =
      item.time_zone != null && item.time_zone != ''
        ? JSON.parse(item.time_zone)
        : '';
    this.oldAuditValueArray = {
      Firstname: this.firstname,
      'Alias name': this.alias_name,
      'Company Name': this.Companies.find((x) => x.id == item.company_id)
        ? this.Companies.find((x) => x.id == item.company_id).name
        : '',
      'Time Zone': this.time_zone.nameValue,
      Cohort: '',
    };
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.get_logs('student', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          },
        );
        this.bkpselection = [];
        await this.getEnrollmentDetails(item.id, token);
      }
    });
    this.changeCertificate();
  }
  async testDKIM() {
    await this.auth.verifyDKIM('yk').then(async (result) => {});
  }
  async getAllMentors() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListMentors(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Mentors = list.data;
            this.SelectedMentor = '';
          }
        });
        await this.spinner.hide();
      }
    });
  }
  async submit() {
    this.markFormTouched(this.LoginForm);
    if (this.LoginForm.valid) {
      let domain = this.email.split('@')[1];
      await this.auth
        .verifyDKIM(domain)
        .then(async (result) => {
          if (result[0].StatusCode == 1) {
            this.firstname = this.firstname.trim();
            if (
              this.firstname == '' ||
              this.firstname == undefined ||
              this.firstname == null
            ) {
              this.service.ShowError('First Name is required');
              return;
            }
            if (!this.EmailList.includes(this.email)) {
              await this.spinner.show();
              var re =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!re.test(this.email)) {
                this.service.ShowError('Please enter a valid email!');
                await this.spinner.hide();
              } else {
                await this.spinner.show();
                var free_flashcard = 'false';
                var free_practque = 'false';
                var allow_access = 'false';
                var opt_in = '1';
                if (this.FashFullAccess == true) {
                  free_flashcard = '2';
                }
                if (this.PraAppFullAccess == true) {
                  free_practque = '2';
                }
                if (this.OptOut == true) {
                  opt_in = '0';
                }
                if (this.AllowAccess == true) {
                  allow_access = '1';
                }
                await this.clientService
                  .addUsertemp(
                    this.firstname,
                    this.email,
                    3,
                    'REWerew889##',
                    0,
                    this.mobile,
                    this.ExamDate,
                    this.SelectedCompany,
                    free_flashcard,
                    free_practque,
                    opt_in,
                    allow_access,
                    JSON.stringify(this.time_zone),
                    this.Is_Selpaced,
                    this.SelectedMentor,
                  )
                  .then(
                    async (data) => {
                      if (data[0].status == 200) {
                        var list = JSON.parse(<string>data[0].json);
                        if (
                          list.data.message ==
                          'Oops. Looks like email already exist.'
                        ) {
                          this.service.ShowError(list.data.message);
                          this.spinner.hide();
                          this.closeModal();
                        } else {
                          let cohortIds = '';
                          for (
                            let i = 0;
                            i < this.selection.selected.length;
                            i++
                          ) {
                            cohortIds =
                              cohortIds + this.selection.selected[i].id + ',';
                          }
                          if (cohortIds.endsWith(',') == true) {
                            cohortIds = cohortIds.substring(
                              0,
                              cohortIds.length - 1,
                            );
                          }
                          let user_id = list.data.id;
                          this.Id = user_id;
                          await this.auth
                            .getAuthenticatedUser()
                            .getSession(async (err, session) => {
                              if (err) {
                                await this.spinner.hide();
                                this.router.navigate(['/login']);
                              } else {
                                var token = session.getIdToken().getJwtToken();
                                var e =
                                  window.localStorage.getItem('adminUser');
                                if (e == null || e == undefined || e == '') {
                                  e = 'admin';
                                }
                                var desc =
                                  'User is added by <span>' + e + '</span>';
                                await this.service.addAdminLogs(
                                  desc,
                                  'student',
                                  list.data.id,
                                  token,
                                );
                              }
                            });
                          await this.getAllUsers(
                            1,
                            this.paginator.toArray()[0].pageSize,
                            '',
                          );
                          this.IsUpdate = true;
                          await this.spinner.hide();
                        }
                      } else {
                        await this.spinner.hide();
                      }
                    },
                    (err) => {
                      this.spinner.hide();
                    },
                  );
                await this.spinner.hide();
              }
            } else {
              this.service.ShowError('This email already exist.');
            }
          } else {
            this.service.ShowError(result[0].message);
          }
        })
        .catch((err) => {});
      await this.spinner.hide();
    }
  }
  async update() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        if (this.ForceChange == true) {
          await this.auth.forcechnagepass(this.email).then((data) => {});
        }
        let cohort_Ids = '';
        let savedata = false;
        for (let i = 0; i < this.selection.selected.length; i++) {
          if (!this.bkpselection.includes(this.selection.selected[i].id)) {
            cohort_Ids = cohort_Ids + this.selection.selected[i].id + ',';
            if (!this.isenroll) savedata = true;
          }
        }
        if (cohort_Ids.endsWith(',') == true) {
          cohort_Ids = cohort_Ids.substring(0, cohort_Ids.length - 1);
        }
        if (savedata) {
          await Swal.fire({
            title: 'Are you sure?',
            text: 'Without Enroll Cohort Save Data',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save',
          }).then(async (result) => {
            if (!result.value) {
              savedata = true;
            } else {
              savedata = false;
            }
          });
        }
        if (!savedata) {
          var token = session.getIdToken().getJwtToken();
          await this.adminService
            .updateUserPage(
              this.Id,
              this.firstname,
              this.AllowAccess,
              this.SelectedCompany,
              this.ExamDate,
              this.alias_name,
              JSON.stringify(this.time_zone),
              this.Is_Selpaced,
              this.SelectedMentor,
              token,
            )
            .then(async (data) => {
              var list = JSON.parse(<string>data[0].json);
              if (data[0].status == 200) {
                let cohortIds = '';
                for (let i = 0; i < this.selection.selected.length; i++) {
                  if (
                    !this.bkpselection.includes(this.selection.selected[i].id)
                  ) {
                    cohortIds = cohortIds + this.selection.selected[i].id + ',';
                  }
                }
                this.bkpselection.forEach((element) => {
                  cohortIds = cohortIds + element + ',';
                });
                if (cohortIds.endsWith(',') == true) {
                  cohortIds = cohortIds.substring(0, cohortIds.length - 1);
                }
                await this.cohortService.addMultipleStudentCohort(
                  cohortIds,
                  this.Id,
                  token,
                );
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      this.newAuditValueArray = {
                        Firstname: this.firstname,
                        'Alias name': this.alias_name,
                        'Company Name': this.Companies.find(
                          (x) => x.id == this.SelectedCompany,
                        )
                          ? this.Companies.find(
                              (x) => x.id == this.SelectedCompany,
                            ).name
                          : '',
                        'Time Zone': this.time_zone.nameValue,
                        Cohort: '',
                      };
                      var audit = this.auth.compareAuditHist(
                        this.oldAuditValueArray,
                        this.newAuditValueArray,
                      );
                      var desc = audit;
                      if (desc != '')
                        await this.service.addAdminLogs(
                          desc,
                          'student',
                          this.Id,
                          token,
                        );
                    }
                  });
                this.email = '';
                this.Confirmpass = '';
                this.Password = '';
                this.closeModal();
                this.service.ShowSuccess('Student update successfully');
                await this.getAllUsers(
                  1,
                  this.paginator.toArray()[0].pageSize,
                  this.Search,
                );
                await this.getAllCohorts();
                this.IsUpdate = false;
                this.addStudent = false;
                this.editStudent = false;
                await this.spinner.hide();
              } else {
                await this.spinner.hide();
              }
            });
        }
      }
    });
    await this.spinner.hide();
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  async convertFile(ev: any) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = jsonData;
      this.spinner.show();
      if ((await this.bulkCheckDKIM(dataString.Sheet1)) == 0) {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.service
                .bulkuploded(dataString.Sheet1, 'User', token)
                .then(
                  (data) => {
                    var list = JSON.parse(<string>data[0].json);
                    if (data[0].status == 200) {
                      this.service.ShowSuccess(
                        list.data.length + ' Students added successfully',
                      );
                      this.spinner.hide();
                    } else {
                      this.service.ShowError(list.message);
                      this.spinner.hide();
                    }
                  },
                  (error) => {
                    console.log(error);
                    this.spinner.hide();
                  },
                );
            }
          });
      } else {
        this.service.ShowError('Invalid domain found in email');
      }
    };
    reader.readAsBinaryString(file);
  }
  async bulkCheckDKIM(data) {
    let response = 0;
    data.forEach(async (element) => {
      let domain = element.email.split('@')[1];
      await this.auth.verifyDKIM(domain).then(async (result) => {
        if (result[0].StatusCode == 1) {
          response = 1;
        }
      });
    });
    return response;
  }
  async openBulkUploadModal() {
    this.isBulkUpload = true;
    this.bulkuploadrespose = [];
    this.SelectedCertificate = '';
    this.SelectedCompany = '';
    this.SelectedMentor = '';
    this.SelectedLiveOrCohort = '';
    this.is_fileupload = false;
    this.is_optionFileUpload = false;
    this.selection.clear();
    this.is_bulkuploadCheck = false;
    this.ExpiryDate = '';
    this.fileRespose = false;
  }
  async closeBulkUploadModal() {
    this.isBulkUpload = false;
    this.progress = 0;
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.bulkSelectedCertificate = '';
    this.bulkSelectedCompany = '';
    this.files = [];
    this.bulkuploadrespose = [];
    this.myEvent = '';
    this.bulkuploadDataSource = null;
    this.selection.clear();
    this.SelectedCertificate = '';
    this.SelectedCompany = '';
    this.SelectedMentor = '';
    this.SelectedLiveOrCohort = '';
    this.is_fileupload = false;
    this.is_optionFileUpload = false;
    this.is_bulkuploadCheck = false;
    this.ExpiryDate = '';
    this.selection.clear();
    this.bulktotaluser = 0;
    this.newUser = 0;
    this.alreadyuser = 0;
    this.cohortadduser = 0;
    this.cohortadready = 0;
    this.FaildUser = 0;
    this.fileRespose = false;
  }
  async bulkUpload() {
    this.bulktotaluser = 0;
    this.newUser = 0;
    this.alreadyuser = 0;
    this.cohortadduser = 0;
    this.cohortadready = 0;
    this.FaildUser = 0;
    const workbook = new ExcelJS.Workbook();
    let studentData = [];
    let bulkuploadr: BulkuploadData[] = [];
    await workbook.xlsx.load(this.myEvent.addedFiles[0]);
    let json = workbook.model.sheets.filter((x) => x.name == 'Student List');
    if (json.length > 0) {
      let jsonData = json[0].rows;
      jsonData.forEach((element, i) => {
        let array = {};
        if (i != 0) {
          element.cells.forEach((ele, j) => {
            if (jsonData[0].cells[j].value == 'email')
              array[jsonData[0].cells[j].value] = ele.text
                ? ele.text
                : ele.value;
            else if (jsonData[0].cells[j].value == 'cohort') {
            } else if (jsonData[0].cells[j].value == 'certificate') {
            } else if (jsonData[0].cells[j].value == 'company') {
            } else if (jsonData[0].cells[j].value == 'course_type') {
            } else if (jsonData[0].cells[j].value == 'expiry_date') {
            } else {
              array[jsonData[0].cells[j].value] = ele.value ? ele.value : '';
            }
            array['roll_id'] = 3;
            array['allow_access'] = 1;
            array['is_temp'] = 1;
            array['password'] = 'Rhw#123ab';
          });
          if (array['name'] != '' && array['email'] != '')
            studentData.push(array);
        }
      });
    }
    if (studentData.length > 0) {
      this.bulktotaluser = studentData.length;
      let date = new Date(new Date(this.ExpiryDate).toDateString());
      let today = new Date(new Date().toDateString());
      var Difference_In_Time = date.getTime() - today.getTime();
      var diffDays = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      let cohortdetails = [];
      for (let i = 0; i < this.selection.selected.length; i++) {
        var data = {
          cohort_id: this.selection.selected[i].id,
          plan_id: this.selection.selected[i].method_id,
          plan_type: this.selection.selected[i].course_type,
          access_duration: diffDays ? diffDays : null,
          payment_type: this.selection.selected[i].course_type,
          certi_id: this.SelectedCertificate,
        };
        cohortdetails.push(data);
      }
      studentData.forEach((element) => {
        element.cohort = cohortdetails;
        element.is_selfpaced = this.Is_Selpaced;
        element.company_id = this.SelectedCompany;
      });
      if ((await this.bulkCheckDKIM(studentData)) == 0) {
        await this.spinner.show();
        for (let i = 0; i < studentData.length; i++) {
          let bulkres: BulkuploadData[] = [];
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                bulkres['id'] = bulkuploadr.length + 1;
                var token = session.getIdToken().getJwtToken();
                await this.service
                  .bulkuplodedSelectedStudent(studentData[i], 'User', token)
                  .then(
                    (data) => {
                      var list = JSON.parse(<string>data[0].json);
                      if (data[0].status == 200) {
                        if (list.data.userstatus == 1) {
                          bulkres['message'] = 'Success';
                          bulkres['type'] = 1;
                          this.newUser += 1;
                          if (list.data.cohortstatus == 1)
                            this.cohortadduser += 1;
                          else this.cohortadready += 1;
                        } else if (list.data.userstatus == 0) {
                          bulkres['message'] = 'User Already exits';
                          bulkres['type'] = 2;
                          this.alreadyuser = this.alreadyuser + 1;
                          if (list.data.cohortstatus == 1)
                            this.cohortadduser = this.cohortadduser + 1;
                          else this.cohortadready = this.cohortadready + 1;
                        } else {
                          bulkres['message'] = 'Failed';
                          bulkres['type'] = 3;
                          this.newUser = this.newUser + 0;
                          this.alreadyuser = this.alreadyuser + 0;
                          this.cohortadduser = this.cohortadduser + 0;
                          this.cohortadready = this.cohortadready + 0;
                        }
                        bulkres['email'] = studentData[i].email;
                        bulkres['name'] = studentData[i].name;
                      } else {
                        this.service.ShowError(list.message);
                        bulkres['type'] = 0;
                        bulkres['message'] = 'Failed';
                        bulkres['email'] = studentData[i].email;
                        bulkres['name'] = studentData[i].name;
                        this.FaildUser = this.FaildUser + 1;
                      }
                    },
                    (error) => {
                      bulkres['type'] = 4;
                      bulkres['message'] = 'Failed';
                      bulkres['email'] = studentData[i].email;
                      bulkres['name'] = studentData[i].name;
                      this.FaildUser = this.FaildUser + 1;
                    },
                  );
              }
            });
          let data = {
            id: bulkres['id'],
            type: bulkres['type'],
            name: bulkres['name'],
            email: bulkres['email'],
            message: bulkres['message'],
          };
          bulkuploadr.push(data);
          this.bulkuploadDataSource = new MatTableDataSource(bulkuploadr);
        }
        await this.spinner.hide();
      } else {
        this.service.ShowError('Invalid domain found in email');
      }
    }
    this.is_optionFileUpload = false;
    this.fileRespose = true;
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.selectBulkupload = true;
    this.selectprogress = false;
    this.is_fileupload = false;
    this.is_optionFileUpload = false;
    this.SelectedCompany = '';
    this.SelectedMentor = '';
    this.is_bulkuploadCheck = false;
    this.SelectedCertificate = '';
    this.SelectedLiveOrCohort = '';
    this.ExpiryDate = '';
    this.selection.clear();
  }
  onSelect(event) {
    let me = this;
    var timeleft = 10;
    me.progress = 0;
    me.is_fileupload = true;
    this.is_optionFileUpload = true;
    me.selectprogress = true;
    me.selectBulkupload = true;
    this.files.push(event.addedFiles);
    for (let i = 0; i < 100; i++) {
      setTimeout(() => {
        this.uploadPercent++;
      }, 1000);
    }
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        me.progress = 10 - timeleft;
        me.myEvent = event;
        me.selectBulkupload = true;
        me.selectprogress = false;
        clearInterval(downloadTimer);
      }
      me.progress = 10 - timeleft;
      timeleft -= 1;
    }, 200);
  }
  ExportExcel() {
    var Smaple_Student_Data = [
      {
        workSheet: 'Sample_Student_Data',
        rows: [
          {
            name: 'Devid',
            email: 'devidjoe@example.com',
          },
        ],
        properties: { state: 'visible', filtercolumn: 2 },
      },
    ];
    var Student = [
      {
        name: 'Student List',
        values: [
          { header: 'name', value: '' },
          { header: 'email', value: '' },
        ],
        properties: { state: 'visible', filtercolumn: 7, date: 'expiry_date' },
      },
    ];
    var workbookData = [this.transform(Student)[0], Smaple_Student_Data[0]];
    this.excelService.exportAsExcelFile(workbookData, 'Student List');
  }
  transform(data) {
    const noOfRowaToGenerate = 100;
    return data.map(({ name, values, properties }) => {
      const headers = values.reduce(
        (prev, next) => ({
          ...prev,
          [next.header]: Array.isArray(next.value)
            ? next.value.map(({ name }) => name)
            : next.value,
        }),
        {},
      );
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers),
        properties: properties,
      };
    });
  }
  exportAsXLSX(): void {}
  ChangeBulkupload() {
    this.SelectedCertificate = '';
    this.SelectedLiveOrCohort = '';
    this.ExpiryDate = '';
  }
  async sortData(event) {
    await this.spinner.show();
    if (event.active == 'company_name') {
      this.SortingData.active = 'company_id';
    } else this.SortingData.active = event.active;
    this.SortingData.direction = event.direction;
    await this.getAllUsers(
      1,
      this.paginator.toArray()[0].pageSize,
      this.Search,
      this.SortingData.active,
      this.SortingData.direction,
    );
    await this.spinner.hide();
  }
}
export interface UsersList {
  SrNo: number;
  id: string;
  name: string;
  email: string;
  roll_id: string;
  email_verified_at: string;
  email_verified: string;
  mobile: string;
  profile_img: string;
  permissions: string;
  password: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  free_flashcard: string;
  free_flashccsp: string;
  chortname: string;
  allow_access: string;
  schedule_date: string;
  company_id: string;
  avatar_url: string;
  cohorts: string[];
  opt_in: string;
  aliasname: string;
  is_temp: string;
  time_zone: string;
  company_name: string;
  mentor_id: string;
  links: string[];
}
export interface CohortList {
  id: number;
  certi_id: number;
  certificate_name: string;
  cohort_name: string;
  user: string;
  studentCount: number;
  start_date: string;
  course_type: string;
  method_id: number;
  company_id: number;
}
export interface SelfPacedList {
  id: number;
  certi_id: number;
  certificate_name: string;
  course_name: string;
  flashcard_access: number;
  question_access: number;
  subscription_type: string;
  enrollment_date: string;
  expiry_date: string;
  amount_paid: string;
  access_duration: number;
  start_date: string;
  exam_date: string;
  exam_date_class: string;
  cohort_id: string;
  user_id: string;
}
export interface BulkuploadData {
  id: number;
  type: number;
  name: string;
  email: string;
  message: string;
}
