<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Questions</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification Domain/Sub Domain..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewKnowledgeassqua()">Add Question</button>
      <button (click)="openBulkUploadModal()">Bulk Upload</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.certificate_name }}">{{
              row.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="ass_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Assessment</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.aas_name }}">{{ row.aas_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Question</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.question }}">{{ row.question }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domin_name }}">{{ row.domin_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Domain</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain_name }}">{{
              row.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.topic_name }}">{{ row.topic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="answer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Answer</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.answer }}">{{ row.answer }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addKnowledgeassqua">
  <div class="cutome-modal-content" style="width: 60%">
    <div class="modal-header">
      <p *ngIf="!EditPopup">Add Question</p>
      <p *ngIf="EditPopup">Edit Question</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="knowQueForm">
          <div class="formwrapper">
            <div class="w-50">
              <label></label>
              <div class="select-box">
                <select
                  [(ngModel)]="CertificateName"
                  (change)="certificatechange($event)"
                  formControlName="CertificateName"
                  required
                  [ngClass]="{
                    err:
                      knowQueForm.controls['CertificateName'].hasError(
                        'required'
                      ) &&
                      (knowQueForm.controls['CertificateName'].dirty ||
                        knowQueForm.controls['CertificateName'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let items of Certificates"
                    value="{{ items.id }}"
                  >
                    {{ items.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label> </label>
              <div class="select-box">
                <select
                  [(ngModel)]="Domainname"
                  formControlName="Domainname"
                  required
                  (change)="domainchange($event)"
                  [ngClass]="{
                    err:
                      knowQueForm.controls['Domainname'].hasError('required') &&
                      (knowQueForm.controls['Domainname'].dirty ||
                        knowQueForm.controls['Domainname'].touched)
                  }"
                >
                  <option value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label></label>
              <div class="select-box">
                <select
                  [(ngModel)]="subdomainname"
                  formControlName="subdomainname"
                  required
                  (change)="SubDomainchange($event)"
                  [ngClass]="{
                    err:
                      knowQueForm.controls['subdomainname'].hasError(
                        'required'
                      ) &&
                      (knowQueForm.controls['subdomainname'].dirty ||
                        knowQueForm.controls['subdomainname'].touched)
                  }"
                >
                  <option value="">Select Sub Domain</option>
                  <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <label></label>
              <div class="select-box">
                <select
                  [(ngModel)]="Topicname"
                  formControlName="Topicname"
                  [(ngModel)]="Topicname"
                  formControlName="Topicname"
                  required
                  [ngClass]="{
                    err:
                      knowQueForm.controls['Topicname'].hasError('required') &&
                      (knowQueForm.controls['Topicname'].dirty ||
                        knowQueForm.controls['Topicname'].touched)
                  }"
                >
                  <option value="">Select Topic</option>
                  <option *ngFor="let item of Topic" value="{{ item.id }}">
                    {{ item.topic_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="select-test">
              <label>Select Assessment</label>
              <ng-select
                [items]="items"
                formControlName="Assessment"
                bindLabel="knowass_name"
                bindValue="id"
                placeholder="Select"
                appendTo="body"
                multiple="true"
                [(ngModel)]="selecteds"
                [ngClass]="{
                  err:
                    knowQueForm.controls['Assessment'].hasError('required') &&
                    (knowQueForm.controls['Assessment'].dirty ||
                      knowQueForm.controls['Assessment'].touched)
                }"
              >
              </ng-select>
            </div>
            <div class="question-text">
              <label>Questions</label>
              <textarea
                [(ngModel)]="Questions"
                formControlName="Questions"
                placeholder="Question"
                name="search"
                [ngClass]="{
                  err:
                    knowQueForm.controls['Questions'].hasError('required') &&
                    (knowQueForm.controls['Questions'].dirty ||
                      knowQueForm.controls['Questions'].touched)
                }"
              >
              </textarea>
            </div>
            <div>
              <label></label>
              <div class="select-box">
                <select
                  [ngClass]="{
                    err:
                      knowQueForm.controls['answer'].hasError('required') &&
                      (knowQueForm.controls['answer'].dirty ||
                        knowQueForm.controls['answer'].touched)
                  }"
                  [(ngModel)]="answer"
                  formControlName="answer"
                  required
                >
                  <option value="">Select Answer</option>
                  <option value="TRUE">True</option>
                  <option value="FALSE">False</option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="point-input">
              <label>Points</label>
              <input
                [ngClass]="{
                  err:
                    knowQueForm.controls['Points'].hasError('required') &&
                    (knowQueForm.controls['Points'].dirty ||
                      knowQueForm.controls['Points'].touched)
                }"
                [(ngModel)]="Points"
                formControlName="Points"
                placeholder="Points"
                type="name"
                name="title"
                required
              />
            </div>
          </div>
          <div style="width: 100%">
            <span class="Ck-Editor-span">Explanation</span>
            <div class="ckdiv">
              <ckeditor
                editorUrl="../../../assets/ckeditor/ckeditor.js"
                [(ngModel)]="Explanation"
                #myckeditor
                name="myckeditor"
                required
                formControlName="Explanation"
                [config]="ckeConfig"
                debounce="500"
                [ngClass]="{
                  err:
                    knowQueForm.controls['Explanation'].hasError('required') &&
                    (knowQueForm.controls['Explanation'].dirty ||
                      knowQueForm.controls['Explanation'].touched)
                }"
              >
              </ckeditor>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="EditPopup">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!EditPopup">
        <button
          class="submit-btn"
          (click)="save()"
          [disabled]="!knowQueForm.valid"
        >
          Save
        </button>
      </div>
      <div *ngIf="EditPopup">
        <button
          class="submit-btn"
          (click)="Update()"
          [disabled]="!knowQueForm.valid"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<div class="ckdivnew" *ngIf="showFullScreen">
  <span class="material-icons ckfull" (click)="showFullScreen = !showFullScreen"
    >fullscreen_exit</span
  >
</div>
<div class="cutome-modal" *ngIf="isBulkUpload">
  <div class="cutome-modal-content" style="width: 50%">
    <div class="modal-header">
      <p>Bulk Upload</p>
      <span (click)="closeBulkUploadModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <ngx-dropzone
          maxFileSize="100MB"
          accept=".csv"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label
            >Drag & drop here (or)
            <span class="browse">Browse</span></ngx-dropzone-label
          >
          <div *ngFor="let f of files">
            <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
          </div>
        </ngx-dropzone>
        <div class="format" *ngIf="files.length <= 0">
          <span>Allowed Format: CSV</span>
        </div>
        <div class="format" *ngFor="let f of files">
          <div class="bbulkupload">
            <span *ngFor="let t of f">{{ t.name }} </span>

            <mat-icon (click)="onRemove(f)">close</mat-icon>
          </div>
        </div>
        <div class="format">
          <progress
            value="{{ progress }}"
            max="10"
            id="progressBar"
            *ngIf="selectprogress"
          ></progress>
        </div>
        <div class="format">
          <a (click)="ExportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            KA_sample.xlsx
          </a>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeBulkUploadModal()">
        Cancel
      </button>
      <button
        class="submit-btn"
        (click)="bulkUpload()"
        [disabled]="selectBulkupload"
      >
        Upload
      </button>
    </div>
  </div>
</div>
