<div class="pagemain">
  <div class="pagetitle">
    <h3>Admin Dashboard</h3>
  </div>

  <div class="dash-box">
    <div class="client-box">
      <div class="main-client">
        <div class="inner">
          <h3>{{ totalClients }}</h3>
          <p>Clients</p>
        </div>
        <div class="icon">
          <mat-icon>people_alt</mat-icon>
        </div>
      </div>
      <div class="link">
        <a routerLink="/main/clients" class="small-box-footer">More info </a>
      </div>
    </div>
    <div class="client-box">
      <div class="main-client">
        <div class="inner">
          <h3>{{ totalTeacher }}</h3>
          <p>Teachers</p>
        </div>
        <div class="icon">
          <mat-icon>psychology</mat-icon>
        </div>
      </div>
      <div class="link">
        <a routerLink="/main/teachers" class="small-box-footer">More info </a>
      </div>
    </div>
    <div class="client-box">
      <div class="main-client">
        <div class="inner">
          <h3>{{ totalStudent }}</h3>
          <p>Students</p>
        </div>
        <div class="icon">
          <mat-icon>psychology</mat-icon>
        </div>
      </div>
      <div class="link">
        <a routerLink="/main/students" class="small-box-footer">More info</a>
      </div>
    </div>
    <div class="client-box">
      <div class="main-client">
        <div class="inner">
          <h3>{{ course }}</h3>
          <p>Courses</p>
        </div>
        <div class="icon">
          <mat-icon>library_books</mat-icon>
        </div>
      </div>
      <div class="link">
        <a routerLink="/main/certificate" class="small-box-footer"
          >More info
        </a>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
