import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';

@Component({
  selector: 'app-study-time',
  templateUrl: './study-time.component.html',
  styleUrls: ['./study-time.component.scss'],
})
export class StudyTimeComponent implements OnInit {
  flashcard_app: number;
  flashcard_app_revision: number;
  practice_que_app: number;
  practice_que_app_revision: number;
  know_ass_revision: number;
  practice_test_revision: number;
  constructor(
    public spinner: NgxSpinnerService,
    public router: Router,
    public service: ServicesService,
    public auth: AuthorizationService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }

  async ngOnInit() {
    await this.spinner.show();
    await this.getAllComponentTime();
    await this.spinner.hide();
  }
  async getAllComponentTime() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.getAllComponentTime(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.flashcard_app = list.data[0].flashcard_app;
            this.flashcard_app_revision = list.data[0].flashcard_app_revision;
            this.practice_que_app = list.data[0].practice_que_app;
            this.practice_que_app_revision =
              list.data[0].practice_que_app_revision;
            this.know_ass_revision = list.data[0].know_ass_revision;
            this.practice_test_revision = list.data[0].practice_test_revision;
          }
        });
      }
    });
  }
  async saveComponentTime() {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service
          .saveComponentTime(
            this.flashcard_app,
            this.flashcard_app_revision,
            this.practice_que_app,
            this.practice_que_app_revision,
            this.know_ass_revision,
            this.practice_test_revision,
            token,
          )
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowSuccess(
                'Self-paced study time save successfully',
              );
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          });
      }
    });
  }
}
