import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/service/authorization.service';
import { ServicesService } from 'src/app/services.service';
import { SelfpacedService } from 'src/app/services/selfpaced/selfpaced.service';

@Component({
  selector: 'app-selfpaced-summary',
  templateUrl: './selfpaced-summary.component.html',
  styleUrls: ['./selfpaced-summary.component.scss'],
})
export class SelfpacedSummaryComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input('pagination') pagination: boolean;
  @ViewChildren(MatPaginator) set matquePaginator(
    paginator: QueryList<MatPaginator>,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator.toArray()[0];
    }
  }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  dataSource: MatTableDataSource<SelfSummary>;
  bkpDataSource: any;
  displayedColumns: string[] = [
    'student_name',
    'enrolled_date',
    'onboarded_date',
    'last_activity',
    'exam_date',
    'mentor_name',
    'next_contact',
    'overdue_classwork',
    'knowledge_assessment',
    'practice_test',
    'flashcard',
    'practice_question',
    'notes',
    'actions',
  ];
  Mentors = [];
  SelectedMentor = '';
  Search = '';
  users_array = [];
  cohort_id: any;
  knowassessments: any;
  practicetests: any;
  totalRecords: number = 0;
  constructor(
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public selfService: SelfpacedService,
    public service: ServicesService,
    public ActiveRoute: ActivatedRoute,
  ) {}
  async ngOnInit() {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        let Details = JSON.parse(atob(params['id']));

        this.cohort_id = Details.cohort_id;
        let userslist = Details.userslist;
        this.knowassessments = Details.knowassessments;
        this.practicetests = Details.practicetests;
        this.spinner.show();
        this.users_array = userslist.split(',');
        this.totalRecords = this.users_array.length;
        await this.getAllMentors();
        let userIds = [];
        for (let i = 0; i < 10; i++) {
          userIds.push(this.users_array[i]);
        }
        await this.getSelfPacedSummary(
          this.cohort_id,
          userIds.join(','),
          this.knowassessments,
          this.practicetests,
        );
      } catch (error) {
        console.log(error);
      }
    });
  }
  gotoUserReport(row) {
    let data = {
      email: row.email,
      user_id: row.user_id,
    };
    let post = btoa(JSON.stringify(data));
    this.router.navigate([`selfpaced-study-progress/${post}`]);
  }
  closeSummaryPage() {
    this.router.navigate([`/main/selfpaced`]);
  }
  async getAllMentors() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListMentors(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Mentors = list.data;
            this.SelectedMentor = '';
          }
        });
        await this.spinner.hide();
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async pageEvents(event: any) {
    await this.spinner.show();
    let userIds = [];
    let page = event.pageIndex + 1;
    let pageSize = event.pageSize;
    for (let i = page * pageSize - pageSize; i < page * pageSize; i++) {
      if (i < this.users_array.length - 1) userIds.push(this.users_array[i]);
    }
    await this.getSelfPacedSummary(
      this.cohort_id,
      userIds.join(','),
      this.knowassessments,
      this.practicetests,
    );
    await this.spinner.hide();
  }
  async changeMentor() {
    if (this.SelectedMentor) {
      let data = this.bkpDataSource.filter(
        (x) => x.mentor_id == this.SelectedMentor,
      );
      this.dataSource = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator.toArray()[0];
    } else {
      this.dataSource = this.bkpDataSource;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator.toArray()[0];
    }
  }
  async getSelfPacedSummary(
    cohort_id: number,
    userslist: string,
    knowassessments: string,
    practicetests: string,
  ) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.selfService
          .getSelfPacedSummary(
            cohort_id,
            userslist,
            knowassessments,
            practicetests,
            token,
          )
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);

              const users: SelfSummary[] = [];
              list.data.forEach((element) => {
                let ka_percentage = 0;
                if (element.knowledge.length > 0) {
                  element.knowledge.forEach((ka) => {
                    let ttlKa = ka.ttlpoint;
                    let ttlKaGet = ka.ttlpointget;
                    ka_percentage += (ttlKaGet * 100) / ttlKa;
                  });
                  ka_percentage = Math.round(
                    ka_percentage / element.knowledge.length,
                  );
                }

                let pt_percentage = 0;
                if (element.practice.length > 0) {
                  let ttlPt = element.practice[0].ttlpoint;
                  let ttlPtGet = element.practice[0].ttlpointget;
                  pt_percentage = Math.round((ttlPtGet * 100) / ttlPt);
                }

                let fc_percentage = 0;
                let fc_total = 0;
                let fc_completed = 0;
                if (element.flashcards.length > 0) {
                  fc_total = element.flashcards[0].ttlcards;
                  fc_completed = element.flashcards[0].ttlright;
                  fc_percentage = Math.round((fc_completed * 100) / fc_total);
                }

                let notes = element.mentor_notes
                  ? JSON.parse(element.mentor_notes)
                  : [];
                let Mentors = this.Mentors.filter(
                  (x) => x.id == element.mentor_id,
                );
                let mentor_name = '-';
                if (Mentors.length > 0) {
                  mentor_name = Mentors[0].name;
                }
                users.push({
                  student_name: element.name
                    ? element.name
                    : element.aliasname
                      ? element.aliasname
                      : element.email,
                  enrolled_date: '-',
                  onboarded_date: element.onbording_date
                    ? formatDate(
                        element.onbording_date,
                        'yyyy-MM-dd, hh:mm',
                        'en_US',
                      )
                    : '-',
                  last_activity: element.last_activity
                    ? formatDate(
                        element.last_activity,
                        'yyyy-MM-dd, hh:mm',
                        'en_US',
                      )
                    : '-',
                  exam_date: element.exam_date
                    ? formatDate(
                        element.exam_date,
                        'yyyy-MM-dd, hh:mm',
                        'en_US',
                      )
                    : '-',
                  mentor_name: mentor_name,
                  next_contact: '-',
                  overdue_classwork: '-',
                  ka_completed: element.total_KA_complete,
                  ka_total: element.knowassessments.split(',').length,
                  ka_percentage: ka_percentage + '%',
                  pt_completed: element.total_PT_complete,
                  pt_total: element.practicetests.split(',').length,
                  pt_percentage: pt_percentage + '%',
                  fc_completed: fc_completed + '',
                  fc_total: fc_total + '',
                  fc_percentage: fc_percentage + '%',
                  ptq_completed: '0',
                  ptq_total: '0',
                  ptq_percentage: '0%',
                  notes: notes,
                  user_id: element.user_id,
                  cohort_id: element.cohort_id,
                  mentor_id: element.mentor_id,
                  email: element.email,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.bkpDataSource = users;
              this.dataSource.sort = this.sort;

              if (!this.dataSource?.paginator?.initialized) {
              }
            }
          });
        await this.spinner.hide();
      }
    });
  }
}
export interface SelfSummary {
  student_name: string;
  email: string;
  enrolled_date: string;
  onboarded_date: string;
  last_activity: string;
  exam_date: string;
  mentor_name: string;
  next_contact: string;
  overdue_classwork: string;
  ka_completed: string;
  ka_total: string;
  ka_percentage: string;
  pt_completed: string;
  pt_total: string;
  pt_percentage: string;
  fc_completed: string;
  fc_total: string;
  fc_percentage: string;
  ptq_completed: string;
  ptq_total: string;
  ptq_percentage: string;
  notes: string;
  user_id: string;
  cohort_id: string;
  mentor_id: string;
}
