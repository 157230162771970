<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Acronyms</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Abbreviation Name..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewaddAcronyms()">Add Acronym</button>
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.certificate_name }}">{{
              element.certificate_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="short_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Abbreviation
          </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.short_name }}">{{
              element.short_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="full_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Phrases</th>
          <td mat-cell *matCellDef="let element">
            <span title="{{ element.full_name }}">{{ element.full_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="cutome-modal" *ngIf="addAcronyms">
  <div class="cutome-modal-content">
    <div class="modal-header">
      <p *ngIf="!IsUpdate">Add Acronym</p>
      <p *ngIf="IsUpdate">Edit Acronym</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="CertificateForm">
          <div class="formwrapper">
            <div class="w-50">
              <div class="select-box">
                <select
                  [(ngModel)]="SelectedCertificate"
                  formControlName="SelectedCertificate"
                  required
                  [ngClass]="{
                    err:
                      CertificateForm.controls['SelectedCertificate'].hasError(
                        'required'
                      ) &&
                      (CertificateForm.controls['SelectedCertificate'].dirty ||
                        CertificateForm.controls['SelectedCertificate'].touched)
                  }"
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of Certificatelist"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-50">
              <input
                type="text"
                [(ngModel)]="Abbreviation"
                formControlName="Abbreviation"
                placeholder="Abbreviation"
                required
                [ngClass]="{
                  err:
                    CertificateForm.controls['Abbreviation'].hasError(
                      'required'
                    ) &&
                    (CertificateForm.controls['Abbreviation'].dirty ||
                      CertificateForm.controls['Abbreviation'].touched)
                }"
              />
            </div>
            <div class="w-100">
              <input
                type="text"
                [(ngModel)]="Fullname"
                formControlName="Fullname"
                placeholder="Phrases"
                required
                [ngClass]="{
                  err:
                    CertificateForm.controls['Fullname'].hasError('required') &&
                    (CertificateForm.controls['Fullname'].dirty ||
                      CertificateForm.controls['Fullname'].touched)
                }"
              />
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="IsUpdate">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!IsUpdate">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="IsUpdate">
        <button class="submit-btn" (click)="UpdateCertificate()">Save</button>
      </div>
    </div>
  </div>
</div>
