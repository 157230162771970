import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AcronymsService } from '../../services/acronyms/acronyms.service';
import { PracticeService } from '../../services/practice/practice.service';

@Component({
  selector: 'app-practicetests',
  templateUrl: './practicetests.component.html',
  styleUrls: ['./practicetests.component.scss'],
})
export class PracticetestsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certificate_name',
    'practice_name',
    'questionlenth',
    'type',
    'time',
    'actions',
  ];
  dataSource: MatTableDataSource<PreacticeTestlist>;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumnsquestion: string[] = [
    'srno',
    'question',
    'AnswerA',
    'AnswerB',
    'AnswerC',
    'AnswerD',
    'actions',
  ];
  dataSourcequestion: MatTableDataSource<questionlist>;
  @Input('matpagination') matpagination: boolean;
  @ViewChildren(MatPaginator) set matquePaginator(
    quespaginator: QueryList<MatPaginator>,
  ) {
    if (this.matpagination) {
      this.dataSourcequestion.paginator = quespaginator.toArray()[1];
    }
  }

  @ViewChildren(MatPaginator) quespaginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortquestion = new QueryList<MatSort>();

  public PracticeTestForm: FormGroup;

  Search: any;
  addPracticeTest: boolean = false;

  id: any;
  EditHideen = false;
  PracticeTestname: any;
  Certificate: any;
  Certificatelist = [];
  acrolist = [];
  acroselecteds = [];
  hours = '00';
  Minutes = '00';
  hourslist = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
  Minuteslist = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
  ];
  Type = '';
  loglist = [];
  SearchQuestion: any;
  acrolistvisible: boolean = true;
  oldAuditValueArray = {
    Certificate: '',
    'Exam Name': '',
    Acronyms: '',
    Hours: '',
    Minutes: '',
    'Exam Type': '',
  };
  newAuditValueArray = {
    Certificate: '',
    'Exam Name': '',
    Acronyms: '',
    Hours: '',
    Minutes: '',
    'Exam Type': '',
  };
  auditHistory = '';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public practiceService: PracticeService,
    public AcronymsService: AcronymsService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  addNewPracticeTest() {
    this.addPracticeTest = true;
    this.EditHideen = false;
    this.PracticeTestname = '';
    this.Certificate = '';
    this.acroselecteds = [];
    this.hours = '00';
    this.Minutes = '00';
    this.acrolistvisible = true;
  }
  closeModal() {
    this.addPracticeTest = false;
    this.EditHideen = false;
    this.PracticeTestname = '';
    this.Certificate = '';
    this.acroselecteds = [];
    this.hours = '00';
    this.Minutes = '00';
    this.Type = '';
    this.oldAuditValueArray = {
      Certificate: '',
      'Exam Name': '',
      Acronyms: '',
      Hours: '',
      Minutes: '',
      'Exam Type': '',
    };
    this.newAuditValueArray = {
      Certificate: '',
      'Exam Name': '',
      Acronyms: '',
      Hours: '',
      Minutes: '',
      'Exam Type': '',
    };
    this.auditHistory = '';
  }

  async ngOnInit() {
    this.PracticeTestForm = this.fb.group({
      PracticeTestname: ['', Validators.required],
      Certificate: ['', Validators.required],
      Acronymsname: new FormControl(),
      hours: ['', Validators.required],
      Minutes: ['', Validators.required],
      Type: ['', Validators.required],
    });
    await this.spinner.show();
    await this.Acroymslist();
    await this.GetAllDropdown();
    await this.ListPracticeQue();
    await this.spinner.hide();
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificatelist = list.data.Certificate;
            this.Certificate = '';
          }
        });
      }
    });
  }
  async Acroymslist() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();

        await this.AcronymsService.Listacronyms(token).then(async (data) => {
          this.acrolist = [];
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.acrolist = list.data;
          }
        });
      }
    });
  }

  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();

              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.practiceService
                .DeletePracticeTest(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();

                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            '<span> ' +
                            item.practice_name +
                            '</span> Practice Test is deleted by <span> ' +
                            e +
                            '</span>';
                          await this.service.addAdminLogs(
                            desc,
                            'practicetest',
                            item.id,
                            token,
                          );
                        }
                      });
                    await this.ListPracticeQue();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Practice test has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async ListPracticeQue() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: PreacticeTestlist[] = [];
        await this.practiceService
          .ListPracticeTest(token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var srno = 0;
              await list.data.forEach(async (element) => {
                srno = srno + 1;
                await users.push({
                  srno: srno,
                  id: element.id,
                  practice_name: element.practice_name,
                  status: element.status,
                  createdAt: element.createdAt,
                  acronum_ids: element.acronum_ids,
                  certi_id: element.certi_id,
                  certificate_name: element.certificate.certificate_name,
                  course_id: element.course_id,
                  updatedAt: element.updatedAt,
                  time: element.time,
                  questionlenth: element.questions.length
                    ? element.questions.length
                    : 0,
                  question: element.questions,
                  type: element.type,
                });
              });
              this.dataSource = new MatTableDataSource(users);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.filter();
              this.pagination = true;
            }
          });
      }
    });
  }
  async edit(row) {
    this.addPracticeTest = true;
    this.EditHideen = true;
    this.id = row.id;
    this.PracticeTestname = row.practice_name;
    this.Certificate = row.certi_id;
    this.Type = row.type;

    if (row.acroselecteds) {
      this.acroselecteds = row.acronum_ids.map(Number);
    }

    if (row.time != undefined && row.time != null && row.time != '') {
      this.hours = row.time.split(':')[0];
      this.Minutes = row.time.split(':')[1];
    } else {
      this.hours = '00';
      this.Minutes = '00';
    }
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('practicetest', row.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();

              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });
    var srno = 0;
    const questionlist: questionlist[] = [];
    row.question.forEach(async (element) => {
      srno = srno + 1;
      await questionlist.push({
        srno: srno,
        id: element.id,
        answer: element.answer,
        blog_url: element.blog_url,
        bookpage_no: element.bookpage_no,
        createdAt: element.createdAt,
        d_id: element.d_id,
        description: element.description,
        first_choice: element.first_choice,
        fourth_choice: element.fourth_choice,
        point: element.point,
        ref_link: element.ref_link,
        sd_id: element.sd_id,
        second_choice: element.second_choice,
        status: element.status,
        subtopic_id: element.subtopic_id,
        test_id: element.test_id,
        third_choice: element.third_choice,
        title: element.title,
        topic_id: element.topic_id,
        type: element.type,
        updatedAt: element.updatedAt,
        youtube_link: element.iyoutube_linkd,
      });
    });
    setTimeout(() => {
      this.dataSourcequestion = new MatTableDataSource(questionlist);
      this.dataSourcequestion.sort = this.sortquestion.toArray()[1];
      this.dataSourcequestion.paginator = this.quespaginator.toArray()[1];
      this.matpagination = true;
    }, 1000);

    this.acrolistvisible = false;
    this.oldAuditValueArray = {
      Certificate: this.Certificatelist.find((x) => x.id == this.Certificate)
        ? this.Certificatelist.find((x) => x.id == this.Certificate)
            .certificate_name
        : '',
      'Exam Name': this.PracticeTestname,
      Acronyms: this.acrolist
        .filter((item) => {
          return this.acroselecteds.includes(item.id);
        })
        .map(function (elem) {
          return elem.short_name;
        })
        .join(','),
      Hours: this.hours,
      Minutes: this.Minutes,
      'Exam Type': this.Type,
    };
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async submit() {
    this.markFormTouched(this.PracticeTestForm);
    if (this.PracticeTestForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();

            var time = this.hours + ':' + this.Minutes + ':00';
            await this.practiceService
              .AddPracticeTest(
                this.PracticeTestname,
                '1',
                this.Certificate,
                token,
                this.acroselecteds.toString(),
                time,
                this.Type,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.PracticeTestname +
                          '</span> Practice Test is added by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'practicetest',
                          list.data.id,
                          token,
                        );
                      }
                    });
                  this.closeModal();
                  await this.ListPracticeQue();
                  await this.spinner.hide();
                  await this.service.ShowSuccess(list.message);
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  async save() {
    this.markFormTouched(this.PracticeTestForm);
    if (this.PracticeTestForm.valid) {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            var time = this.hours + ':' + this.Minutes + ':00';

            await this.practiceService
              .UpdatePracticeTest(
                this.PracticeTestname,
                this.id,
                '1',
                this.Certificate,
                token,
                this.acroselecteds.toString(),
                time,
                this.Type,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        this.newAuditValueArray = {
                          Certificate: this.Certificatelist.find(
                            (x) => x.id == this.Certificate,
                          )
                            ? this.Certificatelist.find(
                                (x) => x.id == this.Certificate,
                              ).certificate_name
                            : '',
                          'Exam Name': this.PracticeTestname,
                          Acronyms: this.acrolist
                            .filter((item) => {
                              return this.acroselecteds.includes(item.id);
                            })
                            .map(function (elem) {
                              return elem.short_name;
                            })
                            .join(','),
                          Hours: this.hours,
                          Minutes: this.Minutes,
                          'Exam Type': this.Type,
                        };
                        var audit = this.auth.compareAuditHist(
                          this.oldAuditValueArray,
                          this.newAuditValueArray,
                        );
                        var desc = audit;
                        await this.service.addAdminLogs(
                          desc,
                          'practicetest',
                          this.id,
                          token,
                        );
                      }
                    });
                  this.closeModal();
                  await this.ListPracticeQue();
                  await this.spinner.hide();
                  await this.service.ShowSuccess(
                    'Practicetest save successfully !',
                  );
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
    }
  }
  questionedit(item) {
    let data = btoa(JSON.stringify(item.id));
    this.router.navigate([`main/PracticetestQuestion/${data}`]);
  }
  async filterQuestion() {
    this.dataSourcequestion.filter = this.SearchQuestion;
    if (this.matpagination) {
      this.dataSourcequestion.paginator._changePageSize(
        this.dataSourcequestion.paginator.pageSize,
      );
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface PreacticeTestlist {
  srno: number;
  id: string;
  practice_name: string;
  status: string;
  acronum_ids: [];
  certi_id: [];
  course_id: [];
  time: string;
  createdAt: string;
  updatedAt: string;
  questionlenth: number;
  question: [];
  certificate_name: string;
  type: string;
}
export interface questionlist {
  srno: number;
  id: string;
  answer: string;
  blog_url: string;
  bookpage_no: number;
  createdAt: string;
  d_id: string;
  description: string;
  first_choice: number;
  fourth_choice: string;
  point: string;
  ref_link: string;
  sd_id: string;
  second_choice: string;
  status: string;
  subtopic_id: string;
  test_id: string;
  third_choice: string;
  title: string;
  topic_id: string;
  type: string;
  updatedAt: string;
  youtube_link: string;
}
