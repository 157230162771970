import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class StudymaterialService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  //Start Study material
  async AddStudyMaterial(
    certi_id,
    d_id,
    sd_id,
    topic_id,
    title,
    content,
    cource_id,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          d_id: d_id,
          sd_id: sd_id,
          sub_domain: sd_id,
          topic_id: topic_id,
          title: title,
          content: content,
          status: '0',
          cource_id: cource_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.STUDY_MATERIAL_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateStudyMaterial(
    certi_id,
    d_id,
    sd_id,
    topic_id,
    title,
    content,
    id,
    cource_id,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          certi_id: certi_id,
          d_id: d_id,
          sd_id: sd_id,
          sub_domain: sd_id,
          topic_id: topic_id,
          title: title,
          content: content,
          status: '0',
          cource_id: cource_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.STUDY_MATERIAL + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async DeleteStudyMaterial(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.STUDY_MATERIAL + this.crypto.enc(id);
      this.http.delete(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ListStudyMaterial(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.BASE_URL + environment.STUDY_MATERIAL_ALL;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }
  async IdStudyMaterial(id, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url =
        environment.BASE_URL + environment.STUDY_MATERIAL + this.crypto.enc(id);
      this.http.get(url, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ckUpload(base64Data) {
    let data = {
      base64Data: base64Data,
    };
  }
  //End Study material
}
