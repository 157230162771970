import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { VariableService } from '../../services/variable/variable.service';

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss'],
})
export class VariablesComponent implements OnInit {
  displayedColumns: string[] = ['srno', 'name', 'query_text', 'actions'];
  dataSource: MatTableDataSource<VariableList>;
  IsUpdate: boolean = false;
  Search: any;
  name: any;
  query_text: any;
  VariableId: any;
  addsubTopic: boolean = false;
  public VariableForm: FormGroup;
  loglist = [];
  oldAuditValueArray = { 'Variables name': '', Query: '' };
  newAuditValueArray = { 'Variables name': '', Query: '' };
  auditHistory = '';
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public variableService: VariableService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
    this.VariableForm = this.fb.group({
      name: ['', Validators.required],
      query_text: ['', Validators.required],
    });
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  addNewsubTopic() {
    this.IsUpdate = false;
    this.addsubTopic = true;
  }
  closeModal() {
    this.addsubTopic = false;
    this.VariableId = '';
    this.name = '';
    this.query_text = '';
    this.oldAuditValueArray = { 'Variables name': '', Query: '' };
    this.newAuditValueArray = { 'Variables name': '', Query: '' };
    this.auditHistory = '';
  }
  async ngOnInit() {
    await this.listTopic();
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete ' + item.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.variableService
                .DeleteVariables(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();

                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            '<span> ' +
                            item.name +
                            ' </span> variable is deleted by <span> ' +
                            e +
                            '</span>';
                          await this.service.addAdminLogs(
                            desc,
                            'variables',
                            item.id,
                            token,
                          );
                        }
                      });

                    await this.listTopic();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Variable has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async listTopic() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        this.spinner.show();
        var token = session.getIdToken().getJwtToken();
        const users: VariableList[] = [];
        await this.variableService.GetAllVariables(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                name: element.name,
                query_text: element.query_text,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
        await this.spinner.hide();
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async saveVariable() {
    this.markFormTouched(this.VariableForm);
    if (this.VariableForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.variableService
            .AddVariables('{' + this.name + '}', this.query_text, token)
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.name +
                        ' </span> variable is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'variables',
                        list.data.id,
                        token,
                      );
                    }
                  });
                this.IsUpdate = false;
                this.addsubTopic = false;
                await this.listTopic();
                await this.spinner.hide();
                this.service.ShowSuccess('Variable added successfully');
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
              }
            });
        }
      });
    }
  }
  async updateVariable() {
    await this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.variableService
          .UpdateVariables(this.VariableId, this.name, this.query_text, token)
          .then(async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              this.auth
                .getAuthenticatedUser()
                .getSession(async (err, session) => {
                  if (err) {
                    await this.spinner.hide();
                    this.router.navigate(['/login']);
                  } else {
                    var token = session.getIdToken().getJwtToken();
                    var e = window.localStorage.getItem('adminUser');
                    if (e == null || e == undefined || e == '') {
                      e = 'admin';
                    }
                    this.newAuditValueArray = {
                      'Variables name': this.name,
                      Query: this.query_text,
                    };
                    var audit = this.auth.compareAuditHist(
                      this.oldAuditValueArray,
                      this.newAuditValueArray,
                    );
                    var desc = audit;
                    await this.service.addAdminLogs(
                      desc,
                      'variables',
                      this.VariableId,
                      token,
                    );
                  }
                });
              this.addsubTopic = false;
              this.IsUpdate = false;
              this.query_text = '';
              this.name = '';

              await this.listTopic();
              await this.spinner.hide();
              this.service.ShowSuccess('Variable update successfully');
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          });
      }
    });
  }
  async editVariable(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('variables', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.IsUpdate = true;
    this.addsubTopic = true;
    this.VariableId = item.id;
    this.name = item.name;
    this.query_text = item.query_text;
    this.oldAuditValueArray = {
      'Variables name': this.name,
      Query: this.query_text,
    };
  }
}
export interface VariableList {
  srno: number;
  id: number;
  name: string;
  query_text: string;
}
