import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { MatSort } from '@angular/material/sort';
import { FlashcardService } from '../../services/flashcard/flashcard.service';
import { PracticeService } from '../../services/practice/practice.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'updatedAt',
    'username',
    'question',
    'addressname',
    'actions',
  ];
  dataSource: MatTableDataSource<Feedback>;
  Search: any;
  addFlashcard: boolean = false;
  IsUpdate: boolean = false;

  Domain = [];
  SubDomain = [];
  Topic = [];
  free_paid: any;
  Domainname: any;
  Subdomainname: any;
  Topicname: any;
  Definition: any;
  Term: any;
  id: any;
  public feedbackform: FormGroup;
  filtersubDomain = [];
  filterDomain = [];
  Certificatelist = [];
  Certificate: any;
  filterTpoic = [];
  CourseName: any;
  ListCoursedata = [];
  Points: any;
  loglist = [];
  Editdata: any;
  AddressedCheck = false;
  Notes: any;

  FeedBackDATA = {
    Question: '',
    AnswerA: '',
    AnswerB: '',
    AnswerC: '',
    AnswerD: '',
    Explanation: '',
    CorrectAnswer: '',
    Diffculty: '',
  };
  Question = {
    Question: '',
    AnswerA: '',
    AnswerB: '',
    AnswerC: '',
    AnswerD: '',
    Explanation: '',
    CorrectAnswer: '',
    Type: '',
  };
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public practiceService: PracticeService,
    public flashcardService: FlashcardService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public fb: FormBuilder,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  addNewFlashcard() {
    this.addFlashcard = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addFlashcard = false;
    this.IsUpdate = false;
    this.Topicname = '';
    this.Domainname = '';
    this.CourseName = '';
    this.Certificate = '';
    this.free_paid = '';
    this.Subdomainname = '';
  }

  async ngOnInit() {
    this.feedbackform = this.fb.group({
      Domainname: ['', Validators.required],
      Subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      Certificate: ['', Validators.required],
      Question: ['', Validators.required],
      AnswerA: ['', Validators.required],
      AnswerB: ['', Validators.required],
      AnswerC: ['', Validators.required],
      AnswerD: ['', Validators.required],
      Explanation: ['', Validators.required],
      CorrectAnswer: ['', Validators.required],
      Type: ['', Validators.required],
      Addressed: ['', Validators.required],
      Notes: ['', Validators.required],
    });
    await this.spinner.show();
    try {
      await this.GetAllDropdown();
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();

                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();

                await this.practiceService
                  .Deletefeddback(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();

                            this.router.navigate(['/login']);
                          } else {
                            await this.listfeedback();
                            await this.spinner.hide();
                          }
                        });
                      Swal.fire(
                        list.message,
                        'Feedback has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
  async edit(item) {
    this.AddressedCheck = false;
    this.Notes = '';
    this.FeedBackDATA = {
      Question: '',
      AnswerA: '',
      AnswerB: '',
      AnswerC: '',
      AnswerD: '',
      Explanation: '',
      CorrectAnswer: '',
      Diffculty: '',
    };
    this.Question = {
      Question: '',
      AnswerA: '',
      AnswerB: '',
      AnswerC: '',
      AnswerD: '',
      Explanation: '',
      CorrectAnswer: '',
      Type: '',
    };
    this.addFlashcard = true;
    this.IsUpdate = true;
    this.Editdata = item;
    this.Certificate = item.practice_appque_certi_id;
    this.Domainname = item.practice_appque_d_id;
    this.Subdomainname = item.practice_appque_sd_id;
    this.Topicname = item.practice_appque_topic_id;
    this.Notes = item.notes;
    this.FeedBackDATA = {
      Question: item.question,
      AnswerA: item.option1,
      AnswerB: item.option2,
      AnswerC: item.option3,
      AnswerD: item.option4,
      Explanation: item.explanation,
      CorrectAnswer: item.correct,
      Diffculty: item.difficulty,
    };
    this.Question = {
      Question: item.practice_appque_title ? item.practice_appque_title : '',
      AnswerA: item.practice_appque_first_choice
        ? item.practice_appque_first_choice
        : '',
      AnswerB: item.practice_appque_second_choice
        ? item.practice_appque_second_choice
        : '',
      AnswerC: item.practice_appque_third_choice
        ? item.practice_appque_third_choice
        : '',
      AnswerD: item.practice_appque_fourth_choice
        ? item.practice_appque_fourth_choice
        : '',
      Explanation: item.practice_appque_explanation
        ? item.practice_appque_explanation
        : '',
      CorrectAnswer: item.practice_appque_answer
        ? item.practice_appque_answer
        : '',
      Type: item.practice_appque_type ? item.practice_appque_type : '',
    };
    if (item.addressed == 1) {
      this.AddressedCheck = true;
    } else {
      this.AddressedCheck = false;
    }

    this.Domain = this.filterDomain.filter(
      (x) => x.certi_id == this.Certificate,
    );
    this.SubDomain = this.filtersubDomain.filter(
      (x) => x.d_id == this.Domainname,
    );
    this.Topic = this.filterTpoic.filter((x) => x.sd_id == this.Subdomainname);
  }

  async listfeedback() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Feedback[] = [];
        await this.practiceService.Feedbacklist(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              var addres = '';
              if (element.addressed) {
                addres = 'Yes';
              } else {
                addres = 'No';
              }

              await users.push({
                srno: srno,
                id: element.id,
                user_id: element.user_id,
                queid: element.queid,
                question: element.question,
                option1: element.option1,
                option2: element.option2,
                option3: element.option3,
                option4: element.option4,
                explanation: element.explanation,
                correct: element.correct,
                type: element.type,
                feedback: element.feedback,
                difficulty: element.difficulty,
                software: element.software,
                addressed: element.addressed,
                addressname: addres,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                username: element.user.name,
                useremail: element.user.email,
                practice_appque_id: element.practice_appque.id,
                practice_appque_free_paid: element.practice_appque.free_paid,
                practice_appque_title: element.practice_appque.title,
                practice_appque_first_choice:
                  element.practice_appque.first_choice,
                practice_appque_second_choice:
                  element.practice_appque.second_choice,
                practice_appque_third_choice:
                  element.practice_appque.third_choice,
                practice_appque_fourth_choice:
                  element.practice_appque.fourth_choice,
                practice_appque_answer: element.practice_appque.answer,
                practice_appque_type: element.practice_appque.type,
                practice_appque_explanation:
                  element.practice_appque.explanation,
                practice_appque_d_id: element.practice_appque.d_id,
                practice_appque_sd_id: element.practice_appque.sd_id,
                practice_appque_topic_id: element.practice_appque.topic_id,
                practice_appque_subtopic_id:
                  element.practice_appque.subtopic_id,
                practice_appque_ref_link: element.practice_appque.ref_link,
                practice_appque_certi_id: element.practice_appque.certi_id,
                practice_appque_cource_id: element.practice_appque.cource_id,
                practice_appque_youtube_link:
                  element.practice_appque.youtube_link,
                practice_appque_bookpage_no:
                  element.practice_appque.bookpage_no,
                practice_appque_blog_url: element.practice_appque.blog_url,
                practice_appque_keyword: element.practice_appque.keyword,
                practice_appque_status: element.practice_appque.status,
                practice_appque_createdAt: element.practice_appque.createdAt,
                practice_appque_updatedAt: element.practice_appque.updatedAt,
                notes: element.notes,
                certificate_name: this.Certificatelist.filter(
                  (x) => x.id == element.practice_appque.certi_id,
                )[0].certificate_name,
                domain_name: this.Domain.filter(
                  (x) => x.id == element.practice_appque.d_id,
                )[0].domin_name,
                subdomain_name: this.SubDomain.filter(
                  (x) => x.id == element.practice_appque.sd_id,
                )[0].subdomain_name,
                topic_name: this.Topic.filter(
                  (x) => x.id == element.practice_appque.topic_id,
                )[0].topic_name,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domain = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.SubDomain = list.data.SubDomain;
            this.filtersubDomain = list.data.SubDomain;
            this.ListCoursedata = list.data.Course;
            this.Certificatelist = list.data.Certificate;
            this.Topic = list.data.Topic;
            this.filterTpoic = list.data.Topic;

            this.Topicname = '';
            this.Domainname = '';
            this.CourseName = '';
            this.Certificate = '';
            this.free_paid = '';
            this.Subdomainname = '';
            await this.listfeedback();
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async certificatechange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Domain = this.filterDomain.filter((itemset) => {
        return itemset.certi_id == val;
      });

      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
      this.Domain = this.filterDomain;
    }
  }
  async domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.SubDomain = this.filtersubDomain.filter((itemset) => {
        return itemset.d_id == val;
      });
      this.Subdomainname = '';
      this.Topicname = '';
    } else {
      this.Domainname = '';
      this.Subdomainname = '';
      this.Topicname = '';
      this.SubDomain = this.filtersubDomain;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return itemset.sd_id == val;
      });
      this.Topicname = '';
    } else {
      this.Topicname = '';
      this.Topic = this.filterTpoic;
    }
  }

  async UpdateFlashcard() {
    this.markFormTouched(this.feedbackform);
    if (this.feedbackform.valid) {
      await this.spinner.show();

      try {
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              var check = 0;
              if (this.AddressedCheck) {
                check = 1;
              } else {
                check = 0;
              }

              await this.practiceService
                .Updatefeddback(this.Editdata.id, check, this.Notes, token)
                .then((data) => {});
              await this.practiceService
                .UpdatePracticeAppQuestion(
                  this.Editdata.practice_appque_free_paid,
                  '',
                  this.Question.Question,
                  this.Question.AnswerA,
                  this.Question.AnswerB,
                  this.Question.AnswerC,
                  this.Question.AnswerD,
                  this.Question.CorrectAnswer,
                  this.Question.Type,
                  this.Domainname,
                  this.Subdomainname,
                  this.Topicname,
                  this.Editdata.practice_appque_id,
                  this.Editdata.practice_appque_certi_id,
                  this.Editdata.practice_appque_cource_id,
                  this.Editdata.explanation,
                  token,
                )
                .then(async (set) => {
                  await this.listfeedback();

                  this.addFlashcard = false;
                  await this.spinner.hide();
                });
            }
          });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  AddressedChange() {
    this.AddressedCheck = !this.AddressedCheck;
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}
export interface Feedback {
  srno: number;
  id: number;
  user_id: string;
  queid: string;
  question: string;
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  explanation: string;
  correct: string;
  type: string;
  feedback: string;
  difficulty: string;
  software: string;
  addressed: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  username: string;
  useremail: string;
  notes: string;
  practice_appque_id: string;
  practice_appque_free_paid: string;
  practice_appque_title: string;
  practice_appque_first_choice: string;
  practice_appque_second_choice: string;
  practice_appque_third_choice: string;
  practice_appque_fourth_choice: string;
  practice_appque_answer: string;
  practice_appque_type: string;
  practice_appque_explanation: string;
  practice_appque_d_id: string;
  practice_appque_sd_id: string;
  practice_appque_topic_id: string;
  practice_appque_subtopic_id: string;
  practice_appque_ref_link: string;
  practice_appque_certi_id: string;
  practice_appque_cource_id: string;
  practice_appque_youtube_link: string;
  practice_appque_bookpage_no: string;
  practice_appque_blog_url: string;
  practice_appque_keyword: string;
  practice_appque_status: string;
  practice_appque_createdAt: string;
  practice_appque_updatedAt: string;
  addressname: string;
  certificate_name: string;
  domain_name: string;
  subdomain_name: string;
  topic_name: string;
}
