import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { CourseService } from '../../services/course/course.service';
import { DomainService } from '../../services/domain/domain.service';
@Component({
  selector: 'app-failedemails',
  templateUrl: './failedemails.component.html',
  styleUrls: ['./failedemails.component.scss'],
})
export class FailedemailsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'email',
    'cohort',
    'message',
    'email_type',
    'createdAt',
    'actions',
  ];
  dataSource: MatTableDataSource<Domainlist>;
  Search: any;

  id: any;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  loglist = [];

  constructor(
    public domainService: DomainService,
    public courseService: CourseService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }

  async ngOnInit() {
    await this.spinner.show();
    try {
      await this.getAllFailedEmails();
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.service
                  .deleteFailedEmail(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();

                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.email +
                              '</span> failed email is deleted by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'failedemail',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.getAllFailedEmails();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Failed email has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
  async getAllFailedEmails() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Domainlist[] = [];
        await this.service.getAllFailedEmails(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                email: element.email,
                email_type: element.email_type,
                message: element.message,
                cohort: element.cohort,
                createdAt: element.createdAt,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }

  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
}
export interface Domainlist {
  srno: number;
  id: number;
  email: string;
  cohort: string;
  message: string;
  email_type: string;
  createdAt: string;
}
