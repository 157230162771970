import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CourseService } from '../../services/course/course.service';
import { KnowledgeService } from '../../services/knowledge/knowledge.service';

@Component({
  selector: 'app-knowlegeass',
  templateUrl: './knowlegeass.component.html',
  styleUrls: ['./knowlegeass.component.scss'],
})
export class KnowlegeassComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'certi_id',
    'knowass_name',
    'total_question',
    'actions',
  ];
  dataSource: MatTableDataSource<KnowAsslist>;
  Search: any;
  addKnowledgeass: boolean = false;

  displayedColumnsquestion: string[] = [
    'srno',
    'question',
    'answer',
    'actions',
  ];
  dataSourcequestion: MatTableDataSource<courselist>;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @Input('matpagination') matpagination: boolean;
  @ViewChildren(MatPaginator) set matquePaginator(
    quespaginator: QueryList<MatPaginator>,
  ) {
    if (this.matpagination) {
      this.dataSourcequestion.paginator = quespaginator.toArray()[1];
    }
  }
  @ViewChildren(MatPaginator) quespaginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sortquestion = new QueryList<MatSort>();

  id: any;
  IsUpdate: boolean = false;
  KnowAssname: any;
  CertificateName: any;
  ListCerficateData = [];
  public KnowAssForm: FormGroup;
  SearchQuestion: any;
  oldAuditValueArray = { Certificate: '', 'Exam Name': '' };
  newAuditValueArray = { Certificate: '', 'Exam Name': '' };
  auditHistory = '';
  loglist = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public knowledgeService: KnowledgeService,
    public courseService: CourseService,
    public fb: FormBuilder,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewKnowledgeAss() {
    this.addKnowledgeass = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addKnowledgeass = false;
    this.IsUpdate = false;
    this.KnowAssname = '';
    this.CertificateName = '';
    this.oldAuditValueArray = { Certificate: '', 'Exam Name': '' };
    this.newAuditValueArray = { Certificate: '', 'Exam Name': '' };
    this.auditHistory = '';
  }
  async ngOnInit() {
    this.KnowAssForm = this.fb.group({
      KnowAssname: ['', Validators.required],
      CertificateName: ['', Validators.required],
    });
    await this.spinner.show();
    try {
      await this.listKnowAss();
      await this.ListCertificate();
      await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.message);
    }
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        try {
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();

                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                await this.knowledgeService
                  .DeleteKnowAss(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();

                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.knowass_name +
                              '</span> Knowledge Assessment is deleted by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'knowass',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.listKnowAss();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Knowledge Assessment has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        } catch (e) {
          await this.spinner.hide();
          this.service.ShowError(e.message);
        }
      }
    });
  }
  async listKnowAss() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: KnowAsslist[] = [];
        await this.knowledgeService.ListKnowAss(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                certi_id: element.certi_id,
                knowass_name: element.knowass_name,
                status: element.status,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                certificate_name: element.certificate.certificate_name,
                cource_id: element.cource_id,
                total_question: element.total_question,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;

            this.dataSource.paginator = this.paginator;
            this.filter();
            this.pagination = true;
          }
        });
      }
    });
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.knowledgeService.getKnowAssQueById(item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);
              let quelist = list.data;
              var srno = 0;
              const questionlist: courselist[] = [];
              if (quelist.length > 0) {
                quelist.forEach(async (element) => {
                  srno = srno + 1;
                  await questionlist.push({
                    srno: srno,
                    id: element.id,
                    ass_id: element.ass_id,
                    question: element.question,
                    answer: element.answer,
                  });
                });
              }
              this.dataSourcequestion = new MatTableDataSource(questionlist);
              this.dataSourcequestion.sort = this.sortquestion.toArray()[1];
              this.dataSourcequestion.paginator =
                this.quespaginator.toArray()[1];
              this.matpagination = true;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
        this.service.get_logs('knowass', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.id = item.id;
    this.KnowAssname = item.knowass_name;
    this.CertificateName = item.certi_id;
    this.addKnowledgeass = true;
    this.IsUpdate = true;

    this.oldAuditValueArray = {
      Certificate: this.ListCerficateData.find(
        (x) => x.id == this.CertificateName,
      )
        ? this.ListCerficateData.find((x) => x.id == this.CertificateName)
            .certificate_name
        : '',
      'Exam Name': this.KnowAssname,
    };
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.ListCerficateData = list.data;
            this.CertificateName = '';
          }
        });
      }
    });
  }
  async onSubmit() {
    this.markFormTouched(this.KnowAssForm);
    if (this.KnowAssForm.valid) {
      await this.spinner.show();
      try {
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();

            await this.knowledgeService
              .AddKnowAss(this.CertificateName, this.KnowAssname, '1', token)
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);

                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        var desc =
                          '<span> ' +
                          this.KnowAssname +
                          '</span> Knowledge Assessment is added by <span> ' +
                          e +
                          '</span>';
                        await this.service.addAdminLogs(
                          desc,
                          'knowass',
                          list.data.id,
                          token,
                        );
                        this.addKnowledgeass = false;
                        await this.spinner.hide();
                        this.service.ShowSuccess(list.message);
                      }
                    });
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  async UpdateKnowAss() {
    this.markFormTouched(this.KnowAssForm);
    if (this.KnowAssForm.valid) {
      await this.spinner.show();
      try {
        this.auth.getAuthenticatedUser().getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.knowledgeService
              .UpdateKnowAss(
                this.CertificateName,
                this.KnowAssname,
                this.id,
                '1',
                token,
              )
              .then(async (value) => {
                if (value[0].status == 200) {
                  var list = JSON.parse(<string>value[0].json);
                  this.auth
                    .getAuthenticatedUser()
                    .getSession(async (err, session) => {
                      if (err) {
                        await this.spinner.hide();

                        this.router.navigate(['/login']);
                      } else {
                        var token = session.getIdToken().getJwtToken();
                        var e = window.localStorage.getItem('adminUser');
                        if (e == null || e == undefined || e == '') {
                          e = 'admin';
                        }
                        this.newAuditValueArray = {
                          Certificate: this.ListCerficateData.find(
                            (x) => x.id == this.CertificateName,
                          )
                            ? this.ListCerficateData.find(
                                (x) => x.id == this.CertificateName,
                              ).certificate_name
                            : '',
                          'Exam Name': this.KnowAssname,
                        };
                        var audit = this.auth.compareAuditHist(
                          this.oldAuditValueArray,
                          this.newAuditValueArray,
                        );
                        var desc = audit;
                        await this.service.addAdminLogs(
                          desc,
                          'knowass',
                          this.id,
                          token,
                        );
                        await this.listKnowAss();
                        this.addKnowledgeass = false;
                        await this.spinner.hide();
                        this.service.ShowSuccess(
                          'Assessment update successfully',
                        );
                      }
                    });
                } else {
                  var list = JSON.parse(<string>value[0].json);
                  await this.spinner.hide();
                  this.service.ShowError(list.message);
                }
              });
          }
        });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.message);
      }
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  questionedit(item) {
    let data = btoa(JSON.stringify(item.id));
    this.router.navigate([`main/knowlegeassques/${data}`]);
  }
  async filterQuestion() {
    this.dataSourcequestion.filter = this.SearchQuestion;
    if (this.pagination) {
      this.dataSourcequestion.paginator._changePageSize(
        this.dataSourcequestion.paginator.pageSize,
      );
    }
  }
}
export interface KnowAsslist {
  srno: number;
  id: string;
  certi_id: string;
  knowass_name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  certificate_name: string;
  cource_id: string;
  total_question: string;
}
export interface courselist {
  srno: number;
  id: string;
  ass_id: string;
  question: string;
  answer: string;
}
