import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { formatDate } from '@angular/common';
import { SupportService } from '../../services/support/support.service';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'email',
    'name',
    'subject',
    'message',
    'app_type',
    'createdAt',
    'actions',
  ];
  dataSource: MatTableDataSource<SupportList>;
  Search: any;
  addAcronyms: boolean = false;
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public supportService: SupportService,
    public router: Router,
    public auth: AuthorizationService,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {}

  async ngOnInit() {
    await this.spinner.show();
    await this.GetSupportData();
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  async GetSupportData() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            const users: SupportList[] = [];
            await this.supportService
              .GetSupportData(token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  var srno = 0;
                  await list.data.forEach(async (element) => {
                    srno = srno + 1;
                    let dt = formatDate(
                      element.createdAt,
                      'yyyy-MM-dd, hh:mm',
                      'en_US',
                    );
                    await users.push({
                      srno: srno,
                      id: element.id,
                      name: element.user.name,
                      email: element.user.email,
                      app_type: element.app_type,
                      message: element.message,
                      subject: element.subject,
                      createdAt: dt,
                    });
                  });

                  this.dataSource = new MatTableDataSource(users);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.filter();
                  this.pagination = true;
                  await this.spinner.hide();
                }
              });
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
  async delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      try {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();

                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                this.service.token = token;
                await this.supportService
                  .DeleteSupport(item.id, token)
                  .then(async (value) => {
                    if (value[0].status == 200) {
                      var list = JSON.parse(<string>value[0].json);
                      this.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            await this.spinner.hide();

                            this.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            var e = window.localStorage.getItem('adminUser');
                            if (e == null || e == undefined || e == '') {
                              e = 'admin';
                            }
                            var desc =
                              '<span> ' +
                              item.message +
                              '</span> Support is deleted by <span> ' +
                              e +
                              '</span>';
                            await this.service.addAdminLogs(
                              desc,
                              'support',
                              item.id,
                              token,
                            );
                          }
                        });
                      await this.GetSupportData();
                      await this.spinner.hide();
                      Swal.fire(
                        list.message,
                        'Message has been deleted.',
                        'success',
                      );
                    } else {
                      var list = JSON.parse(<string>value[0].json);
                      await this.spinner.hide();
                      this.service.ShowError(list.message);
                    }
                  });
              }
            });
        }
      } catch (e) {
        await this.spinner.hide();
      }
    });
  }
}
export interface SupportList {
  srno: number;
  id: string;
  email: string;
  name: string;
  subject: string;
  message: string;
  app_type: string;
  createdAt: string;
}
