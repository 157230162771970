import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AuthorizationService } from '../../service/authorization.service';
import { TopicService } from '../../services/topic/topic.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import * as ClassicEditor from '@blowstack/ckeditor5-full-free-build';

@Component({
  selector: 'app-subtopics',
  templateUrl: './subtopics.component.html',
  styleUrls: ['./subtopics.component.scss'],
})
export class SubtopicsComponent implements OnInit {
  displayedColumns: string[] = [
    'srno',
    'subtopic_name',
    'domain',
    'subdomain',
    'topic_name',
    'actions',
  ];
  dataSource: MatTableDataSource<Topiclist>;
  Search: any;
  addSubTopic: boolean = false;
  IsUpdate: boolean = false;
  public Editor = ClassicEditor;
  domainname: any;
  subdomainname: any;
  Topicname: any;
  SubTopicname: any;
  Explanation: any;
  id: any;
  EditHideen = true;
  public TopicForm: FormGroup;
  Domainlist = [];
  subDomainlist = [];
  filterDomain = [];
  filtersubDomain = [];
  ListCoursedata = [];
  CourseName: any;
  Topic = [];
  filterTpoic = [];
  loglist = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public fb: FormBuilder,
    public topicService: TopicService,
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
  ) {
    var IsLogin = this.auth.isLoggedIn();
    if (!IsLogin) {
      this.router.navigate(['/login']);
    }
  }
  addNewSubTopic() {
    this.CourseName = '';
    this.domainname = '';
    this.subdomainname = '';
    this.Topicname = '';
    this.SubTopicname = '';
    this.Explanation = '';
    this.addSubTopic = true;
    this.IsUpdate = false;
  }
  closeModal() {
    this.addSubTopic = false;
    this.IsUpdate = false;
    this.CourseName = '';
    this.domainname = '';
    this.subdomainname = '';
    this.Topicname = '';
    this.SubTopicname = '';
    this.Explanation = '';
  }
  async ngOnInit() {
    this.TopicForm = this.fb.group({
      CourseName: ['', Validators.required],
      Domainname: ['', Validators.required],
      subdomainname: ['', Validators.required],
      Topicname: ['', Validators.required],
      SubTopicname: ['', Validators.required],
      Explanation: ['', Validators.required],
    });
    await this.spinner.show();
    await this.listTopic();
    await this.GetAllDropdown();
    await this.spinner.hide();
  }
  delete(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              await this.topicService
                .DeleteTopic(item.id, token)
                .then(async (value) => {
                  if (value[0].status == 200) {
                    var list = JSON.parse(<string>value[0].json);
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();

                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();
                          var e = window.localStorage.getItem('adminUser');
                          if (e == null || e == undefined || e == '') {
                            e = 'admin';
                          }
                          var desc =
                            '<span> ' +
                            item.subtopic_name +
                            '</span> sub-topic is deleted by <span> ' +
                            e +
                            '</span>';
                          await this.service.addAdminLogs(
                            desc,
                            'subtopic',
                            item.id,
                            token,
                          );
                        }
                      });

                    await this.listTopic();
                    await this.spinner.hide();
                    Swal.fire(
                      list.message,
                      'Sub Topic has been deleted.',
                      'success',
                    );
                  } else {
                    var list = JSON.parse(<string>value[0].json);
                    await this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
            }
          });
      }
    });
  }
  async listTopic() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        const users: Topiclist[] = [];
        await this.topicService.ListSubTopic(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var srno = 0;
            await list.data.forEach(async (element) => {
              srno = srno + 1;
              await users.push({
                srno: srno,
                id: element.id,
                topic_name: element.topic.topic_name,
                d_id: element.d_id,
                sd_id: element.sd_id,
                status: element.status,
                domain: element.domain.domin_name,
                subdomain: element.subdomain.subdomain_name,
                subtopic_name: element.subtopic_name,
                createdAt: element.createdAt,
                updatedAt: element.updatedAt,
                topic_id: element.topic_id,
                subtopic_id: element.subtopic_id,
                description: element.description,
                cource_id: element.cource_id,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.filter();
          }
        });
      }
    });
  }
  async filter() {
    this.dataSource.filter = this.Search;
  }
  async edit(item) {
    this.loglist = [];
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.service.get_logs('subtopic', item.id, token).then(
          async (value) => {
            if (value[0].status == 200) {
              var list = JSON.parse(<string>value[0].json);

              this.loglist = list.data;
              await this.spinner.hide();
            } else {
              var list = JSON.parse(<string>value[0].json);
              await this.spinner.hide();
              this.service.ShowError(list.message);
            }
          },
          (err) => {
            this.spinner.hide();
          },
        );
      }
    });

    this.addSubTopic = true;
    this.IsUpdate = true;
    this.id = item.id;
    this.CourseName = item.cource_id;
    this.domainname = item.d_id;
    this.subdomainname = item.sd_id;
    this.Topicname = item.topic_id;
    this.SubTopicname = item.subtopic_name;
    this.Explanation = item.description;
  }
  domainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.subDomainlist = this.filtersubDomain.filter((itemset) => {
        return (
          itemset.d_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.domainname = '';
      this.subdomainname = '';
      this.subDomainlist = this.subDomainlist;
    }
  }
  async SubDomainchange(ev) {
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.Topic = this.filterTpoic.filter((itemset) => {
        return (
          itemset.sd_id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.Topicname = '';
      this.Topic = this.filterTpoic;
    }
  }
  async Coursechange(ev) {
    this.ListCoursedata.forEach((element) => {
      if (element.id == ev.target.value) {
      }
    });
  }

  async GetAllDropdown() {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.GetAllDropdown(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Domainlist = list.data.Domain;
            this.filterDomain = list.data.Domain;
            this.ListCoursedata = list.data.Course;
            this.subDomainlist = list.data.SubDomain;
            this.filtersubDomain = list.data.SubDomain;
            this.Topic = list.data.Topic;
            this.filterTpoic = list.data.Topic;

            this.CourseName = '';
            this.domainname = '';
            this.subdomainname = '';
            this.Topicname = '';
            this.SubTopicname = '';
            this.Explanation = '';
          }
        });
      }
    });
  }

  async onSubmit() {
    this.markFormTouched(this.TopicForm);
    if (this.TopicForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.topicService
            .AddSubTopic(
              this.Topicname,
              this.SubTopicname,
              this.domainname,
              this.subdomainname,
              this.CourseName,
              this.Explanation,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.SubTopicname +
                        '</span> sub-topic is added by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'subtopic',
                        list.data.id,
                        token,
                      );
                    }
                  });
                await this.spinner.hide();
                this.service.ShowSuccess(list.message);
                this.router.navigate(['main/subtopics']);
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  async Update() {
    this.markFormTouched(this.TopicForm);
    if (this.TopicForm.valid) {
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.topicService
            .UpdateSubTopic(
              this.id,
              this.Topicname,
              this.SubTopicname,
              this.domainname,
              this.subdomainname,
              this.CourseName,
              this.Explanation,
              token,
            )
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      await this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      await this.listTopic();
                      this.addSubTopic = false;
                      var token = session.getIdToken().getJwtToken();
                      var e = window.localStorage.getItem('adminUser');
                      if (e == null || e == undefined || e == '') {
                        e = 'admin';
                      }
                      var desc =
                        '<span> ' +
                        this.SubTopicname +
                        '</span> sub-topic is updated by <span> ' +
                        e +
                        '</span>';
                      await this.service.addAdminLogs(
                        desc,
                        'subtopic',
                        this.id,
                        token,
                      );
                    }
                  });
                await this.spinner.hide();
                this.service.ShowSuccess(list.message);
              } else {
                var list = JSON.parse(<string>value[0].json);
                await this.spinner.hide();
                this.service.ShowError(list.message);
              }
            });
        }
      });
    }
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
}

export interface Topiclist {
  srno: number;
  id: number;
  topic_name: string;
  d_id: number;
  sd_id: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  subtopic_name: string;
  domain: string;
  description: string;
  topic_id: string;
  subtopic_id: string;
  subdomain: string;
  cource_id: string;
}
