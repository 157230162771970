<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Client Logs History</h3>
    </div>
    <div class="search-wrap">
      <input
        placeholder="Search.."
        [(ngModel)]="Search"
        autocomplete="off"
        (keyup)="filter()"
        name="search"
      />
    </div>
  </div>
  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="SrNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.SrNo }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let element">
            <div class="spn-color" [innerHTML]="element.description"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td class="date-times" mat-cell *matCellDef="let element">
            <span title="{{ element.createdAt }}">{{ element.createdAt }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[25, 50, 100, 500]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
