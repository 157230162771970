<div class="pagemain">
  <div class="main-pagemain">
    <div class="pagetitle">
      <h3>Questions</h3>
    </div>
    <div class="search-wrap">
      <div class="search-icon">
        <input
          placeholder="Search by Certification, Topic, Domain Name..."
          [(ngModel)]="Search"
          autocomplete="off"
          (keyup)="filter()"
          name="search"
        />
        <span class="material-icons">search</span>
      </div>
      <button (click)="addNewPracticeQua()">Add Question</button>
      <button (click)="openBulkUploadModal()">Bulk Upload</button>
    </div>
  </div>

  <div class="pagesection">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        style="border-spacing: 0 10px"
      >
        <ng-container matColumnDef="srno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.srno }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="certificate_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Certification
          </th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.certificate_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="free_paid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Free/Paid</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.free_paid }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="domin_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain name</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.domin_name }}">{{ row.domin_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="subdomain_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Subdomain Name
          </th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.subdomain_name }}">{{
              row.subdomain_name
            }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="topic_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Topic Name</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.topic_name }}">{{ row.topic_name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.title }}">{{ row.title }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="first_choice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Option1</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.first_choice }}">{{ row.first_choice }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="second_choice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Option2</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.second_choice }}">{{ row.second_choice }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="third_choice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Option3</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.third_choice }}">{{ row.third_choice }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="fourth_choice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Option4</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.fourth_choice }}">{{ row.fourth_choice }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let row">
            <span title="{{ row.type }}">{{ row.type }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
            <img
              src="../../../assets/images/edit-1.png"
              (click)="edit(row)"
              alt="Edit"
              class="action-icon"
            />
            <img
              src="../../../assets/images/remove.png"
              (click)="delete(row)"
              alt="Delete"
              class="action-icon"
            />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="page-footer">
    <mat-paginator
      style-paginator
      showFirstLastButtons
      [showTotalPages]="3"
      *ngIf="pagination"
      [length]="dataSource.data ? dataSource.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100, 500]"
    >
    </mat-paginator>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<div class="cutome-modal" *ngIf="addPracticeQua">
  <div class="cutome-modal-content">
    <div class="modal-header" *ngIf="!EditHideen">
      <p>Add Question</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-header" *ngIf="EditHideen">
      <p>Edit Question</p>
      <span (click)="closeModal()" class="material-icons">close</span>
    </div>
    <div class="modal-body">
      <div>
        <form class="example-form" [formGroup]="PraacticeQueForm">
          <div class="formwrapper">
            <div class="w-50">
              <div class="select-box">
                <select
                  (change)="certificatechange($event)"
                  (keyup)="certificatechange($event)"
                  (keydown)="certificatechange($event)"
                  [(ngModel)]="Certificate"
                  formControlName="Certificate"
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['Certificate'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['Certificate'].dirty ||
                        PraacticeQueForm.controls['Certificate'].touched)
                  }"
                  required
                >
                  <option value="">Select Certification</option>
                  <option
                    *ngFor="let item of ListCerficateData"
                    value="{{ item.id }}"
                  >
                    {{ item.certificate_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100">
              <div class="select-box">
                <select
                  [(ngModel)]="Domainname"
                  (keyup)="domainchange($event)"
                  (keydown)="domainchange($event)"
                  (change)="domainchange($event)"
                  formControlName="Domainname"
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['Domainname'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['Domainname'].dirty ||
                        PraacticeQueForm.controls['Domainname'].touched)
                  }"
                  required
                >
                  <option selected="true" value="">Select Domain</option>
                  <option *ngFor="let item of Domain" value="{{ item.id }}">
                    {{ item.domin_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="w-100">
              <!-- <label>Sub Domain Name</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="subdomainname"
                  (keyup)="SubDomainchange($event)"
                  (keydown)="SubDomainchange($event)"
                  (change)="SubDomainchange($event)"
                  formControlName="subdomainname"
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['subdomainname'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['subdomainname'].dirty ||
                        PraacticeQueForm.controls['subdomainname'].touched)
                  }"
                  required
                >
                  <option selected="true" value="">Select Sub-domain</option>
                  <option *ngFor="let item of SubDomain" value="{{ item.id }}">
                    {{ item.subdomain_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="w-100">
              <!-- <label>Topic Name</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="Topicname"
                  formControlName="Topicname"
                  [(ngModel)]="Topicname"
                  formControlName="Topicname"
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['Topicname'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['Topicname'].dirty ||
                        PraacticeQueForm.controls['Topicname'].touched)
                  }"
                  required
                >
                  <option selected="true" value="">Select Topic</option>
                  <option *ngFor="let item of Topic" value="{{ item.id }}">
                    {{ item.topic_name }}
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="Question">
              <label>Question</label>
              <textarea
                [(ngModel)]="title"
                formControlName="title"
                placeholder="Type here..."
                name="search"
                [ngClass]="{
                  err:
                    PraacticeQueForm.controls['title'].hasError('required') &&
                    (PraacticeQueForm.controls['title'].dirty ||
                      PraacticeQueForm.controls['title'].touched)
                }"
              ></textarea>
            </div>

            <div class="Question">
              <label>Answer A</label>
              <textarea
                [(ngModel)]="option1"
                formControlName="option1"
                placeholder="Type here..."
                name="name"
                [ngClass]="{
                  err:
                    PraacticeQueForm.controls['option1'].hasError('required') &&
                    (PraacticeQueForm.controls['option1'].dirty ||
                      PraacticeQueForm.controls['option1'].touched)
                }"
              ></textarea>
            </div>

            <div class="Question">
              <label>Answer B</label>
              <textarea
                [(ngModel)]="option2"
                formControlName="option2"
                placeholder="Type here..."
                name="name"
                [ngClass]="{
                  err:
                    PraacticeQueForm.controls['option2'].hasError('required') &&
                    (PraacticeQueForm.controls['option2'].dirty ||
                      PraacticeQueForm.controls['option2'].touched)
                }"
              ></textarea>
            </div>

            <div class="Question">
              <label>Answer C</label>
              <textarea
                [(ngModel)]="option3"
                formControlName="option3"
                placeholder="Type here..."
                name="name"
                [ngClass]="{
                  err:
                    PraacticeQueForm.controls['option3'].hasError('required') &&
                    (PraacticeQueForm.controls['option3'].dirty ||
                      PraacticeQueForm.controls['option3'].touched)
                }"
              ></textarea>
            </div>

            <div class="Question">
              <label>Answer D</label>
              <textarea
                [(ngModel)]="option4"
                formControlName="option4"
                placeholder="Type here..."
                name="name"
                [ngClass]="{
                  err:
                    PraacticeQueForm.controls['option4'].hasError('required') &&
                    (PraacticeQueForm.controls['option4'].dirty ||
                      PraacticeQueForm.controls['option4'].touched)
                }"
              ></textarea>
            </div>

            <div>
              <!-- <label>Correct Answer</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="CorrectAnswer"
                  formControlName="CorrectAnswer"
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['CorrectAnswer'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['CorrectAnswer'].dirty ||
                        PraacticeQueForm.controls['CorrectAnswer'].touched)
                  }"
                  required
                >
                  <option value="">Select Correct Answer</option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                  <option value="4">Option 4</option>
                </select>
                <span></span>
              </div>
            </div>

            <div class="Question">
              <label>Explanation</label>
              <textarea
                [(ngModel)]="Explanation"
                formControlName="Explanation"
                placeholder="Type here..."
                name="search"
              ></textarea>
            </div>
            <div>
              <!-- <label>Free/Paid</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="Free_paid"
                  formControlName="Free_paid"
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['Free_paid'].hasError(
                        'required'
                      ) &&
                      (PraacticeQueForm.controls['Free_paid'].dirty ||
                        PraacticeQueForm.controls['Free_paid'].touched)
                  }"
                  required
                >
                  <option value="">Free/Paid</option>
                  <option value="free">Free</option>
                  <option value="paid">Paid</option>
                </select>
                <span></span>
              </div>
            </div>
            <div>
              <!-- <label>Type</label> -->
              <div class="select-box">
                <select
                  [(ngModel)]="Type"
                  formControlName="Type"
                  [ngClass]="{
                    err:
                      PraacticeQueForm.controls['Type'].hasError('required') &&
                      (PraacticeQueForm.controls['Type'].dirty ||
                        PraacticeQueForm.controls['Type'].touched)
                  }"
                  required
                >
                  <option selected="true" value="">Select Difficulty</option>
                  <option value="VERY SIMPLE">VERY SIMPLE</option>
                  <option value="SIMPLE">SIMPLE</option>
                  <option value="AVERAGE">AVERAGE</option>
                  <option value="ABOVE AVERAGE">ABOVE AVERAGE</option>
                  <option value="HARD">HARD</option>
                  <option value="DIFFICULT">DIFFICULT</option>
                  <option value="TRICKY">TRICKY</option>
                </select>
                <span></span>
              </div>
            </div>
          </div>
        </form>
        <div class="history-div" *ngIf="EditHideen">
          <h3><span class="material-icons"> history </span>Audit history</h3>
          <div class="audit-table">
            <div class="table-header">
              <div class="date-title"><span>Date & Time</span></div>
              <div class="action-title"><span>Action</span></div>
            </div>
            <div class="main-body-audit">
              <div class="table-body" *ngFor="let log of loglist">
                <div class="date-title">
                  <span>{{ log.createdAt | date: 'medium' }}</span>
                </div>
                <div class="action-title">
                  <span [innerHTML]="log.description"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <div *ngIf="!EditHideen">
        <button class="submit-btn" (click)="onSubmit()">Save</button>
      </div>
      <div *ngIf="EditHideen">
        <button class="submit-btn" (click)="Update()">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="cutome-modal" *ngIf="isBulkUpload">
  <div class="cutome-modal-content" style="width: 50%">
    <div class="modal-header">
      <p>Bulk Upload</p>
      <span (click)="closeBulkUploadModal()" class="material-icons">close</span>
    </div>

    <div class="modal-body" style="min-height: 110px">
      <div>
        <ngx-dropzone
          maxFileSize="100MB"
          accept=".csv"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label
            >Drag & drop here (or)
            <span class="browse">Browse</span></ngx-dropzone-label
          >
          <div *ngFor="let f of files">
            <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
          </div>
        </ngx-dropzone>
        <div class="format" *ngIf="files.length <= 0">
          <span>Allowed Format: CSV</span>
        </div>
        <div class="format" *ngFor="let f of files">
          <div class="bbulkupload">
            <span *ngFor="let t of f">{{ t.name }} </span>

            <mat-icon (click)="onRemove(f)">close</mat-icon>
          </div>
        </div>
        <div class="format">
          <progress
            value="{{ progress }}"
            max="10"
            id="progressBar"
            *ngIf="selectprogress"
          ></progress>
        </div>
        <div class="format">
          <a (click)="ExportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            Practice_App_Question_sample.xlsx
          </a>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="closeBulkUploadModal()">
        Cancel
      </button>
      <button
        class="submit-btn"
        (click)="bulkUpload()"
        [disabled]="selectBulkupload"
      >
        Upload
      </button>
    </div>
  </div>
</div>
