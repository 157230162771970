import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import { AuthorizationService } from '../../../service/authorization.service';
import { LiveService } from '../../../services/live/live.service';

@Component({
  selector: 'app-liveresult',
  templateUrl: './liveresult.component.html',
  styleUrls: ['./liveresult.component.scss'],
})
export class LiveresultComponent implements OnInit {
  TestId: any;
  TestName: any;
  Cohorts = [];
  Questions = [];
  constructor(
    public liveService: LiveService,
    public auth: AuthorizationService,
    private service: ServicesService,
    private ActivatedRoute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {
    this.ActivatedRoute.params.subscribe(async (params) => {
      try {
        this.TestId = JSON.parse(atob(params['id'])).id;
        await this.getLiveTestResult(this.TestId);
      } catch (error) {
        this.router.navigate(['/main/livequestion/']);
      }
    });
  }
  async getLiveTestResult(TestId) {
    this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveService
          .GetLiveTestResult(TestId, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              let result = list.data;
              this.TestName = result.test_name;
              this.Cohorts = result.cohorts;
              this.Questions = result.questions;
            }
          });
      }
    });
    this.spinner.hide();
  }

  ngOnInit(): void {}
}
