import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthorizationService } from 'src/app/service/authorization.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from 'src/app/services.service';
import { SelfpacedService } from 'src/app/services/selfpaced/selfpaced.service';
import { CohortService } from 'src/app/services/cohort/cohort.service';
import { LiveeventsService } from 'src/app/services/liveevents/liveevents.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CompanyService } from 'src/app/services/company/company.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-selfpaced',
  templateUrl: './selfpaced.component.html',
  styleUrls: ['./selfpaced.component.scss'],
})
export class SelfpacedComponent implements OnInit {
  @Input('pagination') pagination: boolean;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator,
  ) {
    if (this.pagination) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<cohortlist>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = [
    'class_name',
    'certificate_name',
    'count_userslist',
    'actions',
  ];
  Certificates = [];
  IsUpdate: boolean = false;
  EventCourseType: any;
  LiveEventPopup: boolean = false;
  NoLiveEventFound: boolean = false;
  PopLiveEvents = [];
  AdminAttendeeId: any;
  AdminMeetingId: any;
  certificate_name: any;
  ScheduleItem: any;
  OScheduleItem: any;
  addSelf: boolean = false;
  class_name: any;
  description: any;
  VideoAccess: boolean = false;
  MindmapAccess: boolean = false;
  SummaryAccess: boolean = false;
  ReferenceAccess: boolean = false;
  KAAccess: boolean = false;
  PTAccess: boolean = false;
  CATAccess: boolean = false;
  LiveChatAccess: boolean = false;
  LiveQuestionAccess: boolean = false;
  LiveEventAccess: boolean = false;
  FashFullAccess: boolean = false;
  PraAppFullAccess: boolean = false;
  SelectedKA: any;
  SelectedPT: any;
  KnowledgeAssessments = [];
  PracticeExams = [];
  LiveEvents = [];
  SelectedEvents = [];
  Subscription: boolean = false;
  OneTime: boolean = false;
  FreeTrial: boolean = false;
  selectedPaymentMethod = '';
  only_show_study: boolean = false;
  publish_free_trial: boolean = false;
  SelectedCertificate: any;
  time_zone: any;
  SelectedMentor: any;
  Mentors = [];
  editID: any;
  SelfPaced = [];

  SSelectedEvents = [];
  SVideoAccess: boolean = false;
  SMindmapAccess: boolean = false;
  SSummaryAccess: boolean = false;
  SReferenceAccess: boolean = false;
  SKAAccess: boolean = false;
  SPTAccess: boolean = false;
  SCATAccess: boolean = false;
  SLiveChatAccess: boolean = false;
  SLiveQuestionAccess: boolean = false;
  SLiveEventAccess: boolean = false;
  SFashFullAccess: boolean = false;
  SPraAppFullAccess: boolean = false;
  SPriceLabel: any;
  sonly_show_study: boolean = false;
  cat_exam_attempts: any;
  publish_subscription: boolean = false;
  attempts_fee: any;
  SubscriptionList = [
    {
      description: '',
      access_duration: 0,
      price: 0,
      price_id: '',
      cohort_id: '',
    },
  ];
  sEditID: any;

  OSelectedEvents = [];
  oprice_label: any;
  OVideoAccess: boolean = false;
  OMindmapAccess: boolean = false;
  OSummaryAccess: boolean = false;
  OReferenceAccess: boolean = false;
  OKAAccess: boolean = false;
  OPTAccess: boolean = false;
  OCATAccess: boolean = false;
  OLiveChatAccess: boolean = false;
  OLiveQuestionAccess: boolean = false;
  OLiveEventAccess: boolean = false;
  OFashFullAccess: boolean = false;
  OPraAppFullAccess: boolean = false;
  Oonly_show_study: boolean = false;
  ocat_exam_attempts: any;
  oattempts_fee: any;
  Odescription: any;
  Oaccess_duration: any;
  Oprice: any;
  Opublish_subscription: boolean = false;
  oEditID: any;
  cohort_id: any;
  scohort_id: any;
  StudyMaterialsSub = [];
  StudyMaterialsOne = [];
  SavedStudyMaterialsSub = [];
  SavedStudyMaterialsOne = [];
  course_type: any = '';
  delete_id: any = '';
  delete_course_type: any = '';
  delete_cohort_id: any = '';
  userslist: any = '';
  SelectedCompany: any;
  Companies = [];
  SSelectedCompany: any;
  OSelectedCompany: any;
  Search: string = '';
  selectedId: any;
  openClose: boolean = false;

  constructor(
    public auth: AuthorizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public selfService: SelfpacedService,
    public service: ServicesService,
    public cohortService: CohortService,
    public liveservice: LiveeventsService,
    public companyService: CompanyService,
  ) {}

  addNewCohort() {
    this.IsUpdate = false;
    this.FreeTrial = true;
    this.class_name = '';
    this.SelectedKA = '';
    this.SelectedPT = '';
    this.only_show_study = false;
    this.Oonly_show_study = false;
    this.sonly_show_study = false;
    this.description = '';
    this.Odescription = '';
    this.Oaccess_duration = '';
    this.Oprice = '';
    this.Opublish_subscription = false;
    this.SubscriptionList = [
      {
        description: '',
        access_duration: 0,
        price: 0,
        price_id: '',
        cohort_id: '',
      },
    ];
    this.publish_free_trial = false;
    this.SelectedEvents = [];
    this.OSelectedEvents = [];
    this.SSelectedEvents = [];
    this.StudyMaterialsSub = [];
    this.StudyMaterialsOne = [];
    this.Subscription = false;
    this.OneTime = false;
    this.addSelf = true;
  }

  async ngOnInit() {
    await this.ListCertificate();
    await this.ListMentors();
    await this.getAllSefPaced();
    await this.GetAllCompany();
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.StudyMaterialsSub,
      event.previousIndex,
      event.currentIndex,
    );
  }
  private swap(array: any[], x: any, y: any) {
    var b = array[x];
    array[x] = array[y];
    array[y] = b;
  }
  moveup(index: any) {
    if (index >= 1) {
      this.swap(this.StudyMaterialsSub, index, index - 1);
    }
  }
  movedown(index: any) {
    if (index < this.StudyMaterialsSub.length - 1) {
      this.swap(this.StudyMaterialsSub, index, index + 1);
    }
  }
  dropOne(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.StudyMaterialsOne,
      event.previousIndex,
      event.currentIndex,
    );
  }
  moveupOne(index: any) {
    if (index >= 1) {
      this.swap(this.StudyMaterialsOne, index, index - 1);
    }
  }
  movedownOne(index: any) {
    if (index < this.StudyMaterialsOne.length - 1) {
      this.swap(this.StudyMaterialsOne, index, index + 1);
    }
  }

  async edit(item) {
    this.certificate_name = item.certificate_name;
    this.IsUpdate = true;
    this.Subscription = false;
    this.OneTime = false;
    this.FreeTrial = false;
    this.course_type = item.course_type;
    if (item.course_type == 'subscription') {
      this.Subscription = true;
    } else if (item.course_type == 'onetime') {
      this.OneTime = true;
    } else {
      this.FreeTrial = true;
    }
    this.SelectedCertificate = item.certi_id;
    this.time_zone =
      item.time_zone != null && item.time_zone != ''
        ? JSON.parse(item.time_zone)
        : '';
    this.SelectedMentor = item.mentor_id;
    await this.getAllExams();
    await this.getSavedMaterials(item.id, item.course_type);
    this.addSelf = true;
    this.class_name = item.class_name;
    this.description = item.description;
    let fv = item.features_visible ? JSON.parse(item.features_visible) : [];
    this.VideoAccess = fv.VideoAccess;
    this.MindmapAccess = fv.MindmapAccess;
    this.SummaryAccess = fv.SummaryAccess;
    this.ReferenceAccess = fv.ReferenceAccess;
    this.KAAccess = fv.KAAccess;
    this.PTAccess = fv.PTAccess;
    this.CATAccess = fv.CATAccess;
    this.LiveChatAccess = fv.LiveChatAccess;
    this.LiveQuestionAccess = fv.LiveQuestionAccess;
    this.LiveEventAccess = fv.LiveEventAccess;
    this.FashFullAccess = fv.FashFullAccess;
    this.PraAppFullAccess = fv.PraAppFullAccess;
    this.publish_free_trial = item.publish_free_trial;
    this.only_show_study = item.only_show_study;

    this.SelectedKA = item.ka_id;
    this.SelectedPT = item.pt_id;
    if (item.live_events.length > 0) {
      this.SelectedEvents = item.live_events.split(',').map(Number);
    }
    if (item.course_type == 'subscription') {
      this.editID = 0;
      this.sEditID = item.id;
      this.oEditID = 0;
      this.Subscription = true;
      this.SPriceLabel = item.class_name;
      let sfv = item.features_visible ? JSON.parse(item.features_visible) : [];
      this.SVideoAccess = sfv.VideoAccess;
      this.SMindmapAccess = sfv.MindmapAccess;
      this.SSummaryAccess = sfv.SummaryAccess;
      this.SReferenceAccess = sfv.ReferenceAccess;
      this.SKAAccess = sfv.KAAccess;
      this.SPTAccess = sfv.PTAccess;
      this.SCATAccess = sfv.CATAccess;
      this.SLiveChatAccess = sfv.LiveChatAccess;
      this.SLiveQuestionAccess = sfv.LiveQuestionAccess;
      this.SLiveEventAccess = sfv.LiveEventAccess;
      this.SFashFullAccess = sfv.FashFullAccess;
      this.SPraAppFullAccess = sfv.PraAppFullAccess;
      if (item.live_events.length > 0) {
        this.SSelectedEvents = item.live_events.split(',').map(Number);
      }
      this.cat_exam_attempts = item.cat_exam_attempts;
      this.attempts_fee = item.cat_attempts_fee;
      if (item.array_subscriptions != null && item.array_subscriptions != '')
        this.SubscriptionList = JSON.parse(item.array_subscriptions);
      this.publish_subscription = item.publish_free_trial;
      this.sonly_show_study = item.only_show_study;
      this.scohort_id = item.cohort_id;
      this.SSelectedCompany = item.company_id;
    } else if (item.course_type == 'onetime') {
      this.editID = 0;
      this.sEditID = 0;
      this.oEditID = item.id;
      this.OneTime = true;
      this.oprice_label = item.class_name;
      let ofv = item.features_visible ? JSON.parse(item.features_visible) : [];
      this.OVideoAccess = ofv.VideoAccess;
      this.OMindmapAccess = ofv.MindmapAccess;
      this.OSummaryAccess = ofv.SummaryAccess;
      this.OReferenceAccess = ofv.ReferenceAccess;
      this.OKAAccess = ofv.KAAccess;
      this.OPTAccess = ofv.PTAccess;
      this.OCATAccess = ofv.CATAccess;
      this.OLiveChatAccess = ofv.LiveChatAccess;
      this.OLiveQuestionAccess = ofv.LiveQuestionAccess;
      this.OLiveEventAccess = ofv.LiveEventAccess;
      this.OFashFullAccess = ofv.FashFullAccess;
      this.OPraAppFullAccess = ofv.PraAppFullAccess;
      if (item.live_events.length > 0) {
        this.OSelectedEvents = item.live_events.split(',').map(Number);
      }
      this.ocat_exam_attempts = item.cat_exam_attempts;
      this.oattempts_fee = item.cat_attempts_fee;
      this.Odescription = item.one_time_description;
      this.Oprice = item.one_time_price;
      this.Oaccess_duration = item.one_time_access_duration;
      this.Opublish_subscription = item.publish_free_trial;
      this.Oonly_show_study = item.only_show_study;
      this.cohort_id = item.cohort_id;
      this.OSelectedCompany = item.company_id;
    } else {
      this.editID = item.id;
      this.sEditID = 0;
      this.oEditID = 0;
      this.FreeTrial = true;
      this.cohort_id = item.cohort_id;
      this.SelectedCompany = item.company_id;
    }

    this.delete_id = item.id;
    this.delete_cohort_id = item.cohort_id;
    this.delete_course_type = item.course_type;
    this.userslist = item.userslist == null ? '' : item.userslist;
    this.ChangeSubscriptionMaterial();
    this.ChangeOnetimeMaterial();
  }
  closeModal() {
    this.addSelf = false;
    this.IsUpdate = false;
    this.SelectedCertificate = '';
    this.SelectedMentor = '';
    this.time_zone = '';
    this.cohort_id = null;
    this.scohort_id = null;
    this.VideoAccess = false;
    this.MindmapAccess = false;
    this.SummaryAccess = false;
    this.ReferenceAccess = false;
    this.KAAccess = false;
    this.PTAccess = false;
    this.CATAccess = false;
    this.LiveChatAccess = false;
    this.LiveQuestionAccess = false;
    this.LiveEventAccess = false;
    this.FashFullAccess = false;
    this.PraAppFullAccess = false;
    this.publish_free_trial = false;
    this.only_show_study = false;
    this.editID = '';
    this.sEditID = '';
    this.oEditID = '';
    this.SPriceLabel = '';
    this.SVideoAccess = false;
    this.SMindmapAccess = false;
    this.SSummaryAccess = false;
    this.SReferenceAccess = false;
    this.SKAAccess = false;
    this.SPTAccess = false;
    this.SCATAccess = false;
    this.SLiveChatAccess = false;
    this.SLiveQuestionAccess = false;
    this.SLiveEventAccess = false;
    this.SFashFullAccess = false;
    this.SPraAppFullAccess = false;
    this.cat_exam_attempts = '';
    this.attempts_fee = '';
    this.SubscriptionList = [];
    this.publish_subscription = false;
    this.sonly_show_study = false;

    this.oprice_label = '';
    this.OVideoAccess = false;
    this.OMindmapAccess = false;
    this.OSummaryAccess = false;
    this.OReferenceAccess = false;
    this.OKAAccess = false;
    this.OPTAccess = false;
    this.OCATAccess = false;
    this.OLiveChatAccess = false;
    this.OLiveQuestionAccess = false;
    this.OLiveEventAccess = false;
    this.OFashFullAccess = false;
    this.OPraAppFullAccess = false;
    this.delete_id = '';
    this.cohort_id = '';
    this.delete_course_type = '';
    this.userslist = '';

    this.SelectedCompany = '';
    this.SSelectedCompany = '';
    this.OSelectedCompany = '';
    this.SelectedEvents = [];
    this.OSelectedEvents = [];
    this.SSelectedEvents = [];
  }
  async filter() {
    this.dataSource.filter = this.Search;
    if (this.pagination) {
      this.dataSource.paginator._changePageSize(
        this.dataSource.paginator.pageSize,
      );
    }
  }
  groupBy(array) {
    return array.reduce((r, { certi_id, certificate_name }) => {
      if (!r.some((o) => o.certi_id == certi_id)) {
        r.push({
          certi_id,
          certificate_name,
          groupItem: array.filter((v) => v.certi_id == certi_id),
        });
      }
      return r;
    }, []);
  }
  async getAllSefPaced() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.selfService.getAllSefPaced(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            const users: cohortlist[] = [];
            list.data.forEach((element) => {
              users.push({
                id: element.id,
                certificate_name: element.certificate_name,
                count_userslist: element.count_userslist
                  ? element.count_userslist.length
                  : 0,
                class_name: element.class_name,
                course_type: element.course_type,
                live_events: element.live_events,
                cat_exam_attempts: element.cat_exam_attempts,
                certi_id: element.certi_id,
                cohort_id: element.cohort_id,
                company_id: element.company_id,
                cat_attempts_fee: element.cat_attempts_fee,
                publish_free_trial: element.publish_free_trial,
                one_time_access_duration: element.one_time_access_duration,
                array_subscriptions: element.array_subscriptions,
                description: element.description,
                features_visible: element.features_visible,
                ka_id: element.ka_id,
                one_time_description: element.one_time_description,
                one_time_price: element.one_time_price,
                only_show_study: element.only_show_study,
                pt_id: element.pt_id,
                userslist: element.count_userslist
                  ? element.count_userslist.map((x) => x.id).join(',')
                  : [],
                mentor_id: element.mentor_id,
                time_zone: element.time_zone,
                knowassessments: element.knowassessments,
                practicetests: element.practicetests,
              });
            });
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.pagination = true;
          }
          await this.spinner.hide();
        });
      }
    });
  }
  async changeCertificate() {
    await this.getAllExams();
    await this.getAllMaterials();
  }
  async ListCertificate() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListCertificate(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Certificates = list.data;
            this.SelectedCertificate = '';
          }
        });
      }
    });
  }
  async ListMentors() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.service.ListMentors(token).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.Mentors = list.data;
            this.SelectedMentor = '';
          }
        });
      }
    });
  }
  async getAllExams() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();

        await this.selfService
          .fillAllDropdown(this.SelectedCertificate, token)
          .then(async (data) => {
            this.KnowledgeAssessments = [];
            this.PracticeExams = [];
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.KnowledgeAssessments = list.data.KnowledgeAssessments;
              this.PracticeExams = list.data.PracticeExams;
              this.LiveEvents = list.data.LiveEvents;
              this.SelectedKA = '';
              this.SelectedPT = '';
            }
            await this.spinner.hide();
          });
      }
    });
  }
  handleFileSelect(evt, item) {
    let me = this;
    let file;
    let mime;
    let reader = new FileReader();
    if (item.title_type == 'Download') {
      let file = evt.target.files[0];
      item['download_filename'] = file.name;
      mime = 'data:' + file.type + ';base64,';
      reader.readAsDataURL(file);
    }
    reader.onload = function () {
      me.uploadFile(reader.result, item, mime, file.type);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async uploadFile(image, item, mime, file_type) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.selfService
          .uploadFilePhp(image, mime, file_type, token)
          .then(async (imgresponse) => {
            if (imgresponse[0].status == 200) {
              var imglist = JSON.parse(<string>imgresponse[0].json);
              if (item.title_type == 'Video') {
                item.download_url = imglist.data;
              } else {
                item.download_url = imglist.data;
              }
              this.service.ShowSuccess('File uploaded successful');
              this.spinner.hide();
            } else {
              this.service.ShowError('Endpoint request timeout');
              this.spinner.hide();
            }
          });
      }
    });
  }
  async addScheduleItems() {
    if (this.ScheduleItem == 'Section') {
      this.StudyMaterialsSub.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'section.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
        duration: null,
      });
    } else if (this.ScheduleItem == 'Flashcard App') {
      this.StudyMaterialsSub.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'flashcardsection.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
        duration: null,
      });
    } else if (this.ScheduleItem == 'Practice Question App') {
      this.StudyMaterialsSub.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'pqasection.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
        duration: null,
      });
    } else if (this.ScheduleItem == 'Download') {
      this.StudyMaterialsSub.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'downloadsection.png',
        seq_number: 1,
        title_type: this.ScheduleItem,
        download_url: '',
        download_description: '',
        duration: null,
      });
    } else {
      this.StudyMaterialsSub.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'video.svg',
        seq_number: 1,
        title_type: this.ScheduleItem,
        download_url: '',
        download_url_720p: '',
        download_url_480p: '',
        download_url_360p: '',
        download_url_240p: '',
        download_url_144p: '',
        download_description: '',
        duration: null,
      });
    }
  }
  async deleteScheduleItems(item) {
    const index = this.StudyMaterialsSub.indexOf(item);
    if (index > -1) {
      this.StudyMaterialsSub.splice(index, 1);
    }
  }
  async addOScheduleItems() {
    if (this.OScheduleItem == 'Section') {
      this.StudyMaterialsOne.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'section.png',
        seq_number: 0,
        title_type: this.OScheduleItem,
        download_url: '',
        download_description: '',
        duration: null,
      });
    } else if (this.OScheduleItem == 'Flashcard App') {
      this.StudyMaterialsOne.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'flashcardsection.png',
        seq_number: 0,
        title_type: this.OScheduleItem,
        download_url: '',
        download_description: '',
        duration: null,
      });
    } else if (this.OScheduleItem == 'Practice Question App') {
      this.StudyMaterialsOne.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'pqasection.png',
        seq_number: 0,
        title_type: this.OScheduleItem,
        download_url: '',
        download_description: '',
        duration: null,
      });
    } else if (this.ScheduleItem == 'Download') {
      this.StudyMaterialsOne.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'downloadsection.png',
        seq_number: 0,
        title_type: this.OScheduleItem,
        download_url: '',
        download_description: '',
        duration: null,
      });
    } else {
      this.StudyMaterialsOne.unshift({
        material_id: 0,
        title_name: '',
        material_type: 'title',
        is_checked: true,
        icon: 'video.svg',
        seq_number: 1,
        title_type: this.OScheduleItem,
        download_url: '',
        download_url_720p: '',
        download_url_480p: '',
        download_url_360p: '',
        download_url_240p: '',
        download_url_144p: '',
        download_description: '',
        duration: null,
      });
    }
  }
  async deleteOScheduleItems(item) {
    const index = this.StudyMaterialsOne.indexOf(item);
    if (index > -1) {
      this.StudyMaterialsOne.splice(index, 1);
    }
  }
  async showtranscode(item) {
    if (this.selectedId == item.id) {
      this.openClose = !this.openClose;
    }
    this.selectedId = item.id;
  }
  async getAllMaterials() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.selfService
          .getAllMaterials(this.SelectedCertificate, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.StudyMaterialsOne = [];
              this.StudyMaterialsSub = [];
              if (list.data.KnowledgeAssessments.length > 0) {
                list.data.KnowledgeAssessments.forEach((element) => {
                  this.StudyMaterialsOne.push({
                    visible_material: this.OKAAccess,
                    material_id: element.id,
                    name: element.knowass_name,
                    material_type: 'KA',
                    is_checked: false,
                    icon: 'ka.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                  this.StudyMaterialsSub.push({
                    visible_material: this.SKAAccess,
                    material_id: element.id,
                    name: element.knowass_name,
                    material_type: 'KA',
                    is_checked: false,
                    icon: 'ka.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                });
              }
              if (list.data.MindMaps.length > 0) {
                list.data.MindMaps.forEach((element) => {
                  this.StudyMaterialsOne.push({
                    visible_material: this.OMindmapAccess,
                    material_id: element.id,
                    name: element.name,
                    material_type: 'Mindmap',
                    is_checked: false,
                    icon: 'mindmap.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                  this.StudyMaterialsSub.push({
                    visible_material: this.SMindmapAccess,
                    material_id: element.id,
                    name: element.name,
                    material_type: 'Mindmap',
                    is_checked: false,
                    icon: 'mindmap.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                });
              }
              if (list.data.PracticeExams.length > 0) {
                list.data.PracticeExams.forEach((element) => {
                  this.StudyMaterialsOne.push({
                    visible_material: this.OPTAccess,
                    material_id: element.id,
                    name: element.practice_name,
                    material_type: 'Test',
                    is_checked: false,
                    icon: 'test.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                  this.StudyMaterialsSub.push({
                    visible_material: this.SPTAccess,
                    material_id: element.id,
                    name: element.practice_name,
                    material_type: 'Test',
                    is_checked: false,
                    icon: 'test.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                });
              }
              if (list.data.Videos.length > 0) {
                list.data.Videos.forEach((element) => {
                  this.StudyMaterialsOne.push({
                    visible_material: this.OVideoAccess,
                    material_id: element.id,
                    name: element.video_title,
                    material_type: 'Video',
                    is_checked: false,
                    icon: 'video.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                  this.StudyMaterialsSub.push({
                    visible_material: this.SVideoAccess,
                    material_id: element.id,
                    name: element.video_title,
                    material_type: 'Video',
                    is_checked: false,
                    icon: 'video.svg',
                    seq_number: 5000,
                    duration: null,
                  });
                });
              }
              this.StudyMaterialsSub.forEach((element) => {
                this.SavedStudyMaterialsSub.forEach((saved) => {
                  if (
                    element.material_id == saved.material_id &&
                    saved.material_type == element.material_type
                  ) {
                    element.is_checked = saved.is_checked;
                    element['seq_number'] = saved.seq_number;
                  }
                });
              });
              this.StudyMaterialsOne.forEach((element) => {
                this.SavedStudyMaterialsOne.forEach((saved) => {
                  if (
                    element.material_id == saved.material_id &&
                    saved.material_type == element.material_type
                  ) {
                    element.is_checked = saved.is_checked;
                    element['seq_number'] = saved.seq_number;
                  }
                });
              });
              let titles = this.SavedStudyMaterialsSub.filter(
                (x) => x.material_type == 'title',
              );

              titles.forEach((element) => {
                element.title_type == 'Section'
                  ? (element['icon'] = 'section.png')
                  : element.title_type == 'Flashcard App'
                    ? (element['icon'] = 'flashcardsection.png')
                    : element.title_type == 'Practice Question App'
                      ? (element['icon'] = 'pqasection.png')
                      : element.title_type == 'Download'
                        ? (element['icon'] = 'downloadsection.png')
                        : element.title_type == 'Video'
                          ? (element['icon'] = 'video.svg')
                          : '';
                this.StudyMaterialsSub.push(element);
              });

              let titlesO = this.SavedStudyMaterialsOne.filter(
                (x) => x.material_type == 'title',
              );
              titlesO.forEach((element) => {
                element.title_type == 'Section'
                  ? (element['icon'] = 'section.png')
                  : element.title_type == 'Flashcard App'
                    ? (element['icon'] = 'flashcardsection.png')
                    : element.title_type == 'Practice Question App'
                      ? (element['icon'] = 'pqasection.png')
                      : element.title_type == 'Download'
                        ? (element['icon'] = 'downloadsection.png')
                        : element.title_type == 'Video'
                          ? (element['icon'] = 'video.svg')
                          : '';
                this.StudyMaterialsOne.push(element);
              });

              this.StudyMaterialsSub.sort(function (a, b) {
                if (a.is_checked)
                  return parseInt(a.seq_number) - parseInt(b.seq_number);
              });
              this.StudyMaterialsOne.sort(function (a, b) {
                if (a.is_checked)
                  return parseInt(a.seq_number) - parseInt(b.seq_number);
              });
            }

            await this.spinner.hide();
          });
      }
    });
  }
  async getSavedMaterials(method_id, method_type) {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.SavedStudyMaterialsSub = [];
        this.SavedStudyMaterialsOne = [];
        await this.selfService
          .getSaveMaterials(method_id, method_type, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              if (method_type == 'subscription') {
                this.SavedStudyMaterialsSub = list.data;
              } else if (method_type == 'onetime') {
                this.SavedStudyMaterialsOne = list.data;
              } else {
                this.SavedStudyMaterialsSub = [];
                this.SavedStudyMaterialsOne = [];
              }
            }
            await this.getAllMaterials();
            await this.spinner.hide();
          });
      }
    });
  }
  addPaymentMethod() {
    if (this.selectedPaymentMethod == '1') {
      this.OneTime = true;
      this.FreeTrial = false;
      this.Subscription = false;
    } else if (this.selectedPaymentMethod == '0') {
      this.Subscription = true;
      this.FreeTrial = false;
      this.OneTime = false;
    } else {
      this.FreeTrial = true;
      this.Subscription = false;
      this.OneTime = false;
    }
  }
  gotoCohortReport(row) {
    let data = {
      cohort_id: row.cohort_id,
      userslist: row.userslist,
      knowassessments: row.knowassessments,
      practicetests: row.practicetests,
    };
    let post = btoa(JSON.stringify(data));
    this.router.navigate([`selfpaced-summary/${post}`]);
  }
  async deletePaymentMethod(method) {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              var data = {
                course_type: this.delete_course_type,
                id: this.delete_id,
                cohort_id: this.delete_cohort_id,
              };
              await this.selfService
                .deleteSelfPacedClass(data, token)
                .then(async (data) => {
                  if (data[0].status == 200) {
                    var list = JSON.parse(<string>data[0].json);
                  }
                  await this.getAllSefPaced();
                  this.closeModal();
                  await this.spinner.hide();
                });
            }
          });
        if (method == 1) {
          this.OneTime = false;
        } else {
          this.Subscription = false;
        }
      }
    });
  }
  async delete(method) {
    if (method.course_type != 'free') {
      await Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.value) {
          await this.spinner.show();
          await this.auth
            .getAuthenticatedUser()
            .getSession(async (err, session) => {
              if (err) {
                await this.spinner.hide();
                this.router.navigate(['/login']);
              } else {
                var token = session.getIdToken().getJwtToken();
                var data = {
                  course_type: method.course_type,
                  id: method.id,
                  cohort_id: method.cohort_id,
                };
                await this.selfService
                  .deleteSelfPacedClass(data, token)
                  .then(async (data) => {
                    await this.getAllSefPaced();
                    this.service.ShowSuccess('Delete successfully');
                    this.closeModal();
                    await this.spinner.hide();
                  });
              }
            });
          if (method == 1) {
            this.OneTime = false;
          } else {
            this.Subscription = false;
          }
        }
      });
    }
  }
  async saveSelfPaced() {
    let fv = {
      VideoAccess: this.VideoAccess,
      MindmapAccess: this.MindmapAccess,
      SummaryAccess: this.SummaryAccess,
      ReferenceAccess: this.ReferenceAccess,
      KAAccess: this.KAAccess,
      PTAccess: this.PTAccess,
      CATAccess: this.CATAccess,
      LiveChatAccess: this.LiveChatAccess,
      LiveQuestionAccess: this.LiveQuestionAccess,
      LiveEventAccess: this.LiveEventAccess,
      FashFullAccess: this.FashFullAccess,
      PraAppFullAccess: this.PraAppFullAccess,
    };
    let features_visible = JSON.stringify(fv);

    let sfv = {
      VideoAccess: this.SVideoAccess,
      MindmapAccess: this.SMindmapAccess,
      SummaryAccess: this.SSummaryAccess,
      ReferenceAccess: this.SReferenceAccess,
      KAAccess: this.SKAAccess,
      PTAccess: this.SPTAccess,
      CATAccess: this.SCATAccess,
      LiveChatAccess: this.SLiveChatAccess,
      LiveQuestionAccess: this.SLiveQuestionAccess,
      LiveEventAccess: this.SLiveEventAccess,
      FashFullAccess: this.SFashFullAccess,
      PraAppFullAccess: this.SPraAppFullAccess,
    };
    let sfeatures_visible = JSON.stringify(sfv);

    let ofv = {
      VideoAccess: this.OVideoAccess,
      MindmapAccess: this.OMindmapAccess,
      SummaryAccess: this.OSummaryAccess,
      ReferenceAccess: this.OReferenceAccess,
      KAAccess: this.OKAAccess,
      PTAccess: this.OPTAccess,
      CATAccess: this.OCATAccess,
      LiveChatAccess: this.OLiveChatAccess,
      LiveQuestionAccess: this.OLiveQuestionAccess,
      LiveEventAccess: this.OLiveEventAccess,
      FashFullAccess: this.OFashFullAccess,
      PraAppFullAccess: this.OPraAppFullAccess,
    };
    let ofeatures_visible = JSON.stringify(ofv);

    let subMaterial = [];
    this.StudyMaterialsSub.forEach((element, i) => {
      if (element.is_checked) {
        subMaterial.push({
          method_id: this.sEditID,
          material_id: element.material_id,
          material_type: element.material_type,
          seq_number: i,
          is_checked: element.is_checked,
          method_type: 'subscription',
          title_name:
            element.material_type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
          download_url_720p: element.download_url_720p,
          download_url_480p: element.download_url_480p,
          download_url_360p: element.download_url_360p,
          download_url_240p: element.download_url_240p,
          download_url_144p: element.download_url_144p,
          duration: element.duration,
        });
      }
    });
    let oneMaterial = [];
    this.StudyMaterialsOne.forEach((element, i) => {
      if (element.is_checked) {
        oneMaterial.push({
          method_id: this.oEditID,
          material_id: element.material_id,
          material_type: element.material_type,
          seq_number: i,
          is_checked: element.is_checked,
          method_type: 'onetime',
          title_name:
            element.material_type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
          download_url_720p: element.download_url_720p,
          download_url_480p: element.download_url_480p,
          download_url_360p: element.download_url_360p,
          download_url_240p: element.download_url_240p,
          download_url_144p: element.download_url_144p,
          duration: element.duration,
        });
      }
    });

    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();

        if (this.course_type == 'subscription') {
          for (let i = 0; i < this.SubscriptionList.length; i++) {
            if (
              (this.SubscriptionList[i].price_id == '' ||
                !this.SubscriptionList[i].price_id) &&
              this.SubscriptionList[i].price != 0
            ) {
              let desc =
                'Access Duration: ' +
                this.SubscriptionList[i].access_duration +
                ' Price: ' +
                this.SubscriptionList[i].price;
              await this.selfService
                .CreateProduct(
                  this.SPriceLabel +
                    ' - ' +
                    this.SubscriptionList[i].description,
                  desc,
                )
                .then(async (product_response) => {
                  var product = JSON.parse(<string>product_response);
                  await this.selfService
                    .CreatePlan(
                      this.SubscriptionList[i].price * 100,
                      product.id,
                      this.SubscriptionList[i].access_duration,
                    )
                    .then(async (plan_response: any) => {
                      var sub = JSON.parse(<string>plan_response);
                      this.SubscriptionList[i].price_id = sub.id;
                    });
                });
            }
          }

          if (this.scohort_id == null || this.scohort_id == undefined) {
            await this.addSubscriptionCohort(
              subMaterial,
              sfeatures_visible,
              0,
              token,
            );
          } else {
            await this.addSubscriptionCohort(
              subMaterial,
              sfeatures_visible,
              this.scohort_id,
              token,
            );
          }
          await this.selfService
            .saveMaterials(
              this.sEditID,
              this.course_type,
              subMaterial,
              (oneMaterial = []),
              token,
            )
            .then(async (data) => {
              if (data[0].status == 200) {
                this.closeModal();
                await this.getAllSefPaced();
                await this.spinner.hide();
                this.service.ShowSuccess('Save successfully');
              }
            });
        } else if (this.course_type == 'onetime') {
          if (this.cohort_id == null || this.cohort_id == undefined) {
            await this.addOneTimeCohort(
              oneMaterial,
              ofeatures_visible,
              0,
              token,
            );
          } else {
            await this.addOneTimeCohort(
              oneMaterial,
              ofeatures_visible,
              this.cohort_id,
              token,
            );
          }
          await this.selfService
            .saveMaterials(
              this.oEditID,
              this.course_type,
              (subMaterial = []),
              oneMaterial,
              token,
            )
            .then(async (data) => {
              if (data[0].status == 200) {
                this.closeModal();
                await this.getAllSefPaced();
                await this.spinner.hide();
                this.service.ShowSuccess('Save successfully');
              }
            });
        } else {
          if (this.cohort_id == null || this.cohort_id == undefined) {
            await this.addFreeCohort(subMaterial, features_visible, 0, token);
          } else {
            await this.addFreeCohort(
              subMaterial,
              features_visible,
              this.cohort_id,
              token,
            );
          }
          if (this.Subscription) {
            for (let i = 0; i < this.SubscriptionList.length; i++) {
              if (
                (this.SubscriptionList[i].price_id == '' ||
                  !this.SubscriptionList[i].price_id) &&
                this.SubscriptionList[i].price != 0
              ) {
                let desc =
                  'Access Duration: ' +
                  this.SubscriptionList[i].access_duration +
                  ' Price: ' +
                  this.SubscriptionList[i].price;
                await this.selfService
                  .CreateProduct(
                    this.SPriceLabel +
                      ' - ' +
                      this.SubscriptionList[i].description,
                    desc,
                  )
                  .then(async (product_response) => {
                    var product = JSON.parse(<string>product_response);
                    await this.selfService
                      .CreatePlan(
                        this.SubscriptionList[i].price * 100,
                        product.id,
                        this.SubscriptionList[i].access_duration,
                      )
                      .then(async (plan_response: any) => {
                        var sub = JSON.parse(<string>plan_response);
                        this.SubscriptionList[i].price_id = sub.id;
                      });
                  });
              }
            }

            if (this.scohort_id == null || this.scohort_id == undefined) {
              await this.addSubscriptionCohort(
                subMaterial,
                sfeatures_visible,
                0,
                token,
              );
            } else {
              await this.addSubscriptionCohort(
                subMaterial,
                sfeatures_visible,
                this.scohort_id,
                token,
              );
            }
            subMaterial.forEach((element) => {
              element.method_id = this.sEditID;
            });
            await this.selfService
              .saveMaterials(
                this.sEditID,
                this.course_type,
                subMaterial,
                (oneMaterial = []),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                }
              });
          }
          if (this.OneTime) {
            await this.addOneTimeCohort(
              oneMaterial,
              ofeatures_visible,
              0,
              token,
            );
            subMaterial.forEach((element) => {
              element.method_id = this.oEditID;
            });
            await this.selfService
              .saveMaterials(
                this.oEditID,
                this.course_type,
                (subMaterial = []),
                oneMaterial,
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                }
              });
          }
          this.closeModal();
          await this.getAllSefPaced();
          this.service.ShowSuccess('Save successfully');
          this.spinner.hide();
        }
      }
    });
  }

  async addSelfPaced() {
    this.cohort_id = null;
    this.scohort_id = null;
    this.editID = 0;
    this.oEditID = 0;
    this.sEditID = 0;
    let fv = {
      VideoAccess: this.VideoAccess,
      MindmapAccess: this.MindmapAccess,
      SummaryAccess: this.SummaryAccess,
      ReferenceAccess: this.ReferenceAccess,
      KAAccess: this.KAAccess,
      PTAccess: this.PTAccess,
      CATAccess: this.CATAccess,
      LiveChatAccess: this.LiveChatAccess,
      LiveQuestionAccess: this.LiveQuestionAccess,
      LiveEventAccess: this.LiveEventAccess,
      FashFullAccess: this.FashFullAccess,
      PraAppFullAccess: this.PraAppFullAccess,
    };
    let features_visible = JSON.stringify(fv);

    let sfv = {
      VideoAccess: this.SVideoAccess,
      MindmapAccess: this.SMindmapAccess,
      SummaryAccess: this.SSummaryAccess,
      ReferenceAccess: this.SReferenceAccess,
      KAAccess: this.SKAAccess,
      PTAccess: this.SPTAccess,
      CATAccess: this.SCATAccess,
      LiveChatAccess: this.SLiveChatAccess,
      LiveQuestionAccess: this.SLiveQuestionAccess,
      LiveEventAccess: this.SLiveEventAccess,
      FashFullAccess: this.SFashFullAccess,
      PraAppFullAccess: this.SPraAppFullAccess,
    };
    let sfeatures_visible = JSON.stringify(sfv);

    let ofv = {
      VideoAccess: this.OVideoAccess,
      MindmapAccess: this.OMindmapAccess,
      SummaryAccess: this.OSummaryAccess,
      ReferenceAccess: this.OReferenceAccess,
      KAAccess: this.OKAAccess,
      PTAccess: this.OPTAccess,
      CATAccess: this.OCATAccess,
      LiveChatAccess: this.OLiveChatAccess,
      LiveQuestionAccess: this.OLiveQuestionAccess,
      LiveEventAccess: this.OLiveEventAccess,
      FashFullAccess: this.OFashFullAccess,
      PraAppFullAccess: this.OPraAppFullAccess,
    };
    let ofeatures_visible = JSON.stringify(ofv);

    let subMaterial = [];
    this.StudyMaterialsSub.forEach((element, i) => {
      if (element.is_checked) {
        subMaterial.push({
          method_id: this.sEditID,
          material_id: element.material_id,
          material_type: element.material_type,
          seq_number: i,
          is_checked: element.is_checked,
          method_type: 'subscription',
          title_name:
            element.material_type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
          download_url_720p: element.download_url_720p,
          download_url_480p: element.download_url_480p,
          download_url_360p: element.download_url_360p,
          download_url_240p: element.download_url_240p,
          download_url_144p: element.download_url_144p,
          duration: element.duration,
        });
      }
    });
    let oneMaterial = [];
    this.StudyMaterialsOne.forEach((element, i) => {
      if (element.is_checked) {
        oneMaterial.push({
          method_id: this.oEditID,
          material_id: element.material_id,
          material_type: element.material_type,
          seq_number: i,
          is_checked: element.is_checked,
          method_type: 'onetime',
          title_name:
            element.material_type == 'title' ? element.title_name : null,
          title_type: element.title_type,
          download_description: element.download_description,
          download_url: element.download_url,
          download_url_720p: element.download_url_720p,
          download_url_480p: element.download_url_480p,
          download_url_360p: element.download_url_360p,
          download_url_240p: element.download_url_240p,
          download_url_144p: element.download_url_144p,
          duration: element.duration,
        });
      }
    });
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();

        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();

        if (this.course_type == 'subscription') {
          for (let i = 0; i < this.SubscriptionList.length; i++) {
            if (
              (this.SubscriptionList[i].price_id == '' ||
                !this.SubscriptionList[i].price_id) &&
              this.SubscriptionList[i].price != 0
            ) {
              let desc =
                'Access Duration: ' +
                this.SubscriptionList[i].access_duration +
                ' Price: ' +
                this.SubscriptionList[i].price;
              await this.selfService
                .CreateProduct(
                  this.SPriceLabel +
                    ' - ' +
                    this.SubscriptionList[i].description,
                  desc,
                )
                .then(async (product_response) => {
                  var product = JSON.parse(<string>product_response);
                  await this.selfService
                    .CreatePlan(
                      this.SubscriptionList[i].price * 100,
                      product.id,
                      this.SubscriptionList[i].access_duration,
                    )
                    .then(async (plan_response: any) => {
                      var sub = JSON.parse(<string>plan_response);
                      this.SubscriptionList[i].price_id = sub.id;
                    });
                });
            }
          }

          if (this.scohort_id == null || this.scohort_id == undefined) {
            await this.addSubscriptionCohort(
              subMaterial,
              sfeatures_visible,
              0,
              token,
            );
          } else {
            await this.addSubscriptionCohort(
              subMaterial,
              sfeatures_visible,
              this.scohort_id,
              token,
            );
          }
          await this.selfService
            .saveMaterials(
              this.sEditID,
              this.course_type,
              subMaterial,
              (oneMaterial = []),
              token,
            )
            .then(async (data) => {
              if (data[0].status == 200) {
                this.closeModal();
                await this.getAllSefPaced();
                await this.spinner.hide();
                this.service.ShowSuccess('Save successfully');
              }
            });
        } else if (this.course_type == 'onetime') {
          if (this.cohort_id == null || this.cohort_id == undefined) {
            await this.addOneTimeCohort(
              oneMaterial,
              ofeatures_visible,
              0,
              token,
            );
          } else {
            await this.addOneTimeCohort(
              oneMaterial,
              ofeatures_visible,
              this.cohort_id,
              token,
            );
          }
          await this.selfService
            .saveMaterials(
              this.oEditID,
              this.course_type,
              (subMaterial = []),
              oneMaterial,
              token,
            )
            .then(async (data) => {
              if (data[0].status == 200) {
                this.closeModal();
                await this.getAllSefPaced();
                await this.spinner.hide();
                this.service.ShowSuccess('Save successfully');
              }
            });
        } else {
          if (this.cohort_id == null || this.cohort_id == undefined) {
            await this.addFreeCohort(subMaterial, features_visible, 0, token);
          } else {
            await this.addFreeCohort(
              subMaterial,
              features_visible,
              this.cohort_id,
              token,
            );
          }
          if (this.Subscription) {
            for (let i = 0; i < this.SubscriptionList.length; i++) {
              if (
                (this.SubscriptionList[i].price_id == '' ||
                  !this.SubscriptionList[i].price_id) &&
                this.SubscriptionList[i].price != 0
              ) {
                let desc =
                  'Access Duration: ' +
                  this.SubscriptionList[i].access_duration +
                  ' Price: ' +
                  this.SubscriptionList[i].price;
                await this.selfService
                  .CreateProduct(
                    this.SPriceLabel +
                      ' - ' +
                      this.SubscriptionList[i].description,
                    desc,
                  )
                  .then(async (product_response) => {
                    var product = JSON.parse(<string>product_response);
                    await this.selfService
                      .CreatePlan(
                        this.SubscriptionList[i].price * 100,
                        product.id,
                        this.SubscriptionList[i].access_duration,
                      )
                      .then(async (plan_response: any) => {
                        var sub = JSON.parse(<string>plan_response);
                        this.SubscriptionList[i].price_id = sub.id;
                      });
                  });
              }
            }

            if (this.scohort_id == null || this.scohort_id == undefined) {
              await this.addSubscriptionCohort(
                subMaterial,
                sfeatures_visible,
                0,
                token,
              );
            } else {
              await this.addSubscriptionCohort(
                subMaterial,
                sfeatures_visible,
                this.scohort_id,
                token,
              );
            }
            subMaterial.forEach((element) => {
              element.method_id = this.sEditID;
            });
            await this.selfService
              .saveMaterials(
                this.sEditID,
                this.course_type,
                subMaterial,
                (oneMaterial = []),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                }
              });
          }
          if (this.OneTime) {
            await this.addOneTimeCohort(
              oneMaterial,
              ofeatures_visible,
              0,
              token,
            );
            subMaterial.forEach((element) => {
              element.method_id = this.oEditID;
            });
            await this.selfService
              .saveMaterials(
                this.oEditID,
                this.course_type,
                (subMaterial = []),
                oneMaterial,
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                }
              });
          }
          this.closeModal();
          await this.getAllSefPaced();
          this.service.ShowSuccess('Save successfully');
          this.spinner.hide();
        }
      }
    });
  }

  async addFreeCohort(subMaterial, features_visible, id, token) {
    let schedule = [];
    let ka = this.KnowledgeAssessments.filter(
      (ka) => ka.id == this.SelectedKA,
    )[0];
    let knowassessments = !this.SelectedKA ? '' : this.SelectedKA;
    if (knowassessments) {
      schedule.push({
        due_date: new Date().toString(),
        overdue_date: '',
        required: 1,
        type: 'KA',
        type_id: knowassessments,
        cohort_id: this.cohort_id == undefined ? null : this.cohort_id,
        due_number: '',
        overdue_number: '',
        name: ka != undefined && ka != '' ? ka.knowass_name : '',
      });
    }
    let pt = this.PracticeExams.filter((ka) => ka.id == this.SelectedPT)[0];
    let practicetests = !this.SelectedPT ? '' : this.SelectedPT;
    if (practicetests) {
      schedule.push({
        due_date: new Date().toString(),
        overdue_date: '',
        required: 1,
        type: 'PRACTICE',
        type_id: practicetests,
        cohort_id: this.cohort_id == undefined ? null : this.cohort_id,
        due_number: '',
        overdue_number: '',
        name: pt != undefined && pt != '' ? pt.practice_name : '',
      });
    }

    var access = {
      VideoAccess: this.VideoAccess,
      MindmapAccess: this.MindmapAccess,
      SummaryAccess: this.SummaryAccess,
      ReferenceAccess: this.ReferenceAccess,
      KAAccess: this.KAAccess,
      PTAccess: this.PTAccess,
      CATAccess: this.CATAccess,
      LiveChatAccess: this.LiveChatAccess,
      LiveQuestionAccess: this.LiveQuestionAccess,
      LiveEventAccess: this.LiveEventAccess,
      FlashcardAccess: this.FashFullAccess,
      QuestionAccess: this.LiveQuestionAccess,
    };
    var sadd_cohort = {
      cohort_name: this.class_name,
      certi_id: this.SelectedCertificate,
      knowassessments: knowassessments.toString(),
      practicetests: practicetests.toString(),
      ketexamtests: '',
      flashcard_app: this.FashFullAccess,
      practice_que_app: this.PraAppFullAccess,
      events: '',
      userslist: '',
      location: '',
      company_id: this.SelectedCompany,
      schedule: schedule,
      email_templates: '',
      time_zone: JSON.stringify(this.time_zone),
      template_id: '',
      access: access,
      start_date: new Date().toString(),
      method_id: this.sEditID,
      access_duration: 0,
      course_type: 'Free',
    };

    if (id == 0) {
      await this.cohortService
        .AddCohort(
          sadd_cohort.cohort_name,
          sadd_cohort.certi_id,
          1,
          sadd_cohort.knowassessments,
          sadd_cohort.practicetests,
          sadd_cohort.ketexamtests,
          sadd_cohort.flashcard_app,
          sadd_cohort.practice_que_app,
          sadd_cohort.events,
          sadd_cohort.userslist,
          sadd_cohort.location,
          sadd_cohort.company_id,
          sadd_cohort.schedule,
          sadd_cohort.email_templates,
          sadd_cohort.time_zone,
          sadd_cohort.template_id,
          sadd_cohort.access,
          sadd_cohort.start_date,
          sadd_cohort.method_id,
          sadd_cohort.access_duration,
          sadd_cohort.course_type,
          token,
          this.SelectedEvents.toString(),
        )
        .then(async (value) => {
          if (value[0].status == 200) {
            var list = JSON.parse(<string>value[0].json);
            this.cohort_id = list.data.id;
            await this.selfService
              .editSelfPaced(
                this.editID,
                this.SelectedCertificate,
                this.class_name,
                this.description,
                features_visible,
                this.SelectedKA,
                this.SelectedPT,
                this.SelectedEvents.toString(),
                this.only_show_study,
                this.publish_free_trial,
                this.cohort_id,
                this.SelectedCompany,
                this.SelectedMentor,
                JSON.stringify(this.time_zone),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  if (!this.Subscription && !this.OneTime) {
                    await this.spinner.hide();
                    this.service.ShowSuccess('Save successfully');
                  }
                }
              });
          }
        });
    } else {
      await this.cohortService
        .UpdateNewCohort(
          id,
          sadd_cohort.cohort_name,
          sadd_cohort.certi_id,
          1,
          sadd_cohort.knowassessments,
          sadd_cohort.practicetests,
          sadd_cohort.ketexamtests,
          sadd_cohort.flashcard_app,
          sadd_cohort.practice_que_app,
          sadd_cohort.events,
          sadd_cohort.userslist,
          sadd_cohort.location,
          sadd_cohort.company_id,
          sadd_cohort.schedule,
          sadd_cohort.email_templates,
          sadd_cohort.time_zone,
          sadd_cohort.template_id,
          sadd_cohort.access,
          sadd_cohort.start_date,
          sadd_cohort.method_id,
          sadd_cohort.access_duration,
          sadd_cohort.course_type,
          token,
          this.SelectedEvents.toString(),
        )
        .then(async (value) => {
          if (value[0].status == 200) {
            var list = JSON.parse(<string>value[0].json);
            this.cohort_id = list.data.id;
            await this.selfService
              .editSelfPaced(
                this.editID,
                this.SelectedCertificate,
                this.class_name,
                this.description,
                features_visible,
                this.SelectedKA,
                this.SelectedPT,
                this.SelectedEvents.toString(),
                this.only_show_study,
                this.publish_free_trial,
                this.cohort_id,
                this.SelectedCompany,
                this.SelectedMentor,
                JSON.stringify(this.time_zone),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  if (!this.Subscription && !this.OneTime) {
                    await this.spinner.hide();
                  }
                }
              });
          }
        });
    }
  }

  async addSubscriptionCohort(subMaterial, sfeatures_visible, id, token) {
    let schedule = [];

    let ka = subMaterial.filter(
      (ka) => ka.material_type == 'KA' && ka.is_checked == 1,
    );
    let knowassessments = '';
    ka.forEach((element) => {
      knowassessments += element.material_id + ',';
      schedule.push({
        due_date: new Date().toString(),
        overdue_date: '',
        required: 1,
        type: 'KA',
        type_id: element.material_id,
        cohort_id: '',
        due_number: '',
        overdue_number: '',
        name: element.knowass_name,
      });
    });
    if (knowassessments.endsWith(',') == true) {
      knowassessments = knowassessments.substring(
        0,
        knowassessments.length - 1,
      );
    }
    let pt = subMaterial.filter(
      (ka) => ka.material_type == 'Test' && ka.is_checked == 1,
    );
    let practicetests = '';
    pt.forEach((element) => {
      practicetests += element.material_id + ',';
      schedule.push({
        due_date: new Date().toString(),
        overdue_date: '',
        required: 1,
        type: 'PRACTICE',
        type_id: element.material_id,
        cohort_id: '',
        due_number: '',
        overdue_number: '',
        name: element.knowass_name,
      });
    });
    if (practicetests.endsWith(',') == true) {
      practicetests = practicetests.substring(0, practicetests.length - 1);
    }
    var access = {
      VideoAccess: this.SVideoAccess,
      MindmapAccess: this.SMindmapAccess,
      SummaryAccess: this.SSummaryAccess,
      ReferenceAccess: this.SReferenceAccess,
      KAAccess: this.SKAAccess,
      PTAccess: this.SPTAccess,
      CATAccess: this.SCATAccess,
      LiveChatAccess: this.SLiveChatAccess,
      LiveQuestionAccess: this.SLiveQuestionAccess,
      LiveEventAccess: this.SLiveEventAccess,
      FlashcardAccess: this.SFashFullAccess,
      QuestionAccess: this.SLiveQuestionAccess,
    };
    var sadd_cohort = {
      cohort_name: this.SPriceLabel,
      certi_id: this.SelectedCertificate,
      knowassessments: knowassessments.toString(),
      practicetests: practicetests.toString(),
      ketexamtests: '',
      flashcard_app: this.SFashFullAccess,
      practice_que_app: this.SPraAppFullAccess,
      events: '',
      userslist: '',
      location: '',
      company_id: this.SSelectedCompany,
      schedule: schedule,
      email_templates: '',
      time_zone: JSON.stringify(this.time_zone),
      template_id: '',
      access: access,
      start_date: new Date().toString(),
      method_id: this.sEditID,
      access_duration: 0,
      course_type: 'subscription',
    };

    if (id == 0) {
      await this.cohortService
        .AddCohort(
          sadd_cohort.cohort_name,
          sadd_cohort.certi_id,
          1,
          sadd_cohort.knowassessments,
          sadd_cohort.practicetests,
          sadd_cohort.ketexamtests,
          sadd_cohort.flashcard_app,
          sadd_cohort.practice_que_app,
          sadd_cohort.events,
          sadd_cohort.userslist,
          sadd_cohort.location,
          sadd_cohort.company_id,
          sadd_cohort.schedule,
          sadd_cohort.email_templates,
          sadd_cohort.time_zone,
          sadd_cohort.template_id,
          sadd_cohort.access,
          sadd_cohort.start_date,
          sadd_cohort.method_id,
          sadd_cohort.access_duration,
          sadd_cohort.course_type,
          token,
          this.SSelectedEvents.toString(),
        )
        .then(async (value) => {
          if (value[0].status == 200) {
            var list = JSON.parse(<string>value[0].json);
            this.scohort_id = list.data.id;
            await this.selfService
              .editSelfPacedSubscription(
                this.sEditID,
                this.SelectedCertificate,
                this.SPriceLabel,
                sfeatures_visible,
                this.SSelectedEvents.toString(),
                this.sonly_show_study,
                this.publish_subscription,
                this.cat_exam_attempts,
                this.attempts_fee,
                JSON.stringify(this.SubscriptionList),
                this.scohort_id,
                this.SSelectedCompany,
                this.SelectedMentor,
                JSON.stringify(this.time_zone),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  var sub = JSON.parse(<string>data[0].json);
                  this.sEditID = sub.data.id;
                }
              });
          }
        });
    } else {
      await this.cohortService
        .UpdateNewCohort(
          id,
          sadd_cohort.cohort_name,
          sadd_cohort.certi_id,
          1,
          sadd_cohort.knowassessments,
          sadd_cohort.practicetests,
          sadd_cohort.ketexamtests,
          sadd_cohort.flashcard_app,
          sadd_cohort.practice_que_app,
          sadd_cohort.events,
          sadd_cohort.userslist,
          sadd_cohort.location,
          sadd_cohort.company_id,
          sadd_cohort.schedule,
          sadd_cohort.email_templates,
          sadd_cohort.time_zone,
          sadd_cohort.template_id,
          sadd_cohort.access,
          sadd_cohort.start_date,
          sadd_cohort.method_id,
          sadd_cohort.access_duration,
          sadd_cohort.course_type,
          token,
          this.SSelectedEvents.toString(),
        )
        .then(async (value) => {
          if (value[0].status == 200) {
            var list = JSON.parse(<string>value[0].json);
            this.scohort_id = list.data.id;
            await this.selfService
              .editSelfPacedSubscription(
                this.sEditID,
                this.SelectedCertificate,
                this.SPriceLabel,
                sfeatures_visible,
                this.SSelectedEvents.toString(),
                this.sonly_show_study,
                this.publish_subscription,
                this.cat_exam_attempts,
                this.attempts_fee,
                JSON.stringify(this.SubscriptionList),
                this.scohort_id,
                this.SelectedCompany,
                this.SelectedMentor,
                JSON.stringify(this.time_zone),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  var sub = JSON.parse(<string>data[0].json);
                  this.sEditID = sub.data.id;
                  if (this.SSelectedEvents.length > 0) {
                    await this.addNotificationLiveEvent(
                      this.userslist,
                      this.scohort_id,
                    );
                  }
                }
              });
          }
        });
    }
  }
  async addOneTimeCohort(oneMaterial, ofeatures_visible, id, token) {
    let oneschedule = [];

    let ka = oneMaterial.filter(
      (ka) => ka.material_type == 'KA' && ka.is_checked == 1,
    );
    let knowassessments = '';
    ka.forEach((element) => {
      knowassessments += element.material_id + ',';
      oneschedule.push({
        due_date: new Date().toString(),
        overdue_date: '',
        required: 1,
        type: 'KA',
        type_id: element.material_id,
        cohort_id: '',
        due_number: '',
        overdue_number: '',
        name: element.knowass_name,
      });
    });
    if (knowassessments.endsWith(',') == true) {
      knowassessments = knowassessments.substring(
        0,
        knowassessments.length - 1,
      );
    }
    let pt = oneMaterial.filter(
      (ka) => ka.material_type == 'Test' && ka.is_checked == 1,
    );
    let practicetests = '';
    pt.forEach((element) => {
      practicetests += element.material_id + ',';
      oneschedule.push({
        due_date: new Date().toString(),
        overdue_date: '',
        required: 1,
        type: 'PRACTICE',
        type_id: element.material_id,
        cohort_id: '',
        due_number: '',
        overdue_number: '',
        name: element.knowass_name,
      });
    });
    if (practicetests.endsWith(',') == true) {
      practicetests = practicetests.substring(0, practicetests.length - 1);
    }
    var access = {
      VideoAccess: this.OVideoAccess,
      MindmapAccess: this.OMindmapAccess,
      SummaryAccess: this.OSummaryAccess,
      ReferenceAccess: this.OReferenceAccess,
      KAAccess: this.OKAAccess,
      PTAccess: this.OPTAccess,
      CATAccess: this.OCATAccess,
      LiveChatAccess: this.OLiveChatAccess,
      LiveQuestionAccess: this.OLiveQuestionAccess,
      LiveEventAccess: this.OLiveEventAccess,
      FlashcardAccess: this.OFashFullAccess,
      QuestionAccess: this.OLiveQuestionAccess,
    };
    var add_cohort = {
      cohort_name: this.oprice_label,
      certi_id: this.SelectedCertificate,
      knowassessments: knowassessments,
      practicetests: practicetests,
      ketexamtests: '',
      flashcard_app: this.OFashFullAccess,
      practice_que_app: this.OPraAppFullAccess,
      events: '',
      userslist: '',
      location: '',
      company_id: this.OSelectedCompany,
      schedule: oneschedule,
      email_templates: '',
      time_zone: JSON.stringify(this.time_zone),
      template_id: '',
      access: access,
      start_date: new Date().toString(),
      method_id: this.oEditID,
      access_duration: this.Oaccess_duration,
      course_type: 'onetime',
    };
    if (id == 0) {
      await this.cohortService
        .AddCohort(
          add_cohort.cohort_name,
          add_cohort.certi_id,
          1,
          add_cohort.knowassessments,
          add_cohort.practicetests,
          add_cohort.ketexamtests,
          add_cohort.flashcard_app,
          add_cohort.practice_que_app,
          add_cohort.events,
          add_cohort.userslist,
          add_cohort.location,
          add_cohort.company_id,
          add_cohort.schedule,
          add_cohort.email_templates,
          add_cohort.time_zone,
          add_cohort.template_id,
          add_cohort.access,
          add_cohort.start_date,
          add_cohort.method_id,
          add_cohort.access_duration,
          add_cohort.course_type,
          token,
          this.OSelectedEvents.toString(),
        )
        .then(async (value) => {
          if (value[0].status == 200) {
            var list = JSON.parse(<string>value[0].json);
            this.cohort_id = list.data.id;
            await this.selfService
              .editSelfPacedOneTime(
                this.oEditID,
                this.SelectedCertificate,
                this.oprice_label,
                ofeatures_visible,
                this.OSelectedEvents.toString(),
                this.Oonly_show_study,
                this.Opublish_subscription,
                this.ocat_exam_attempts,
                this.oattempts_fee,
                this.Odescription,
                this.Oaccess_duration,
                this.Oprice,
                this.cohort_id,
                this.OSelectedCompany,
                this.SelectedMentor,
                JSON.stringify(this.time_zone),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  var sub = JSON.parse(<string>data[0].json);
                  this.oEditID = sub.data.id;
                }
              });
          }
        });
    } else {
      await this.cohortService
        .UpdateNewCohort(
          id,
          add_cohort.cohort_name,
          add_cohort.certi_id,
          1,
          add_cohort.knowassessments,
          add_cohort.practicetests,
          add_cohort.ketexamtests,
          add_cohort.flashcard_app,
          add_cohort.practice_que_app,
          add_cohort.events,
          add_cohort.userslist,
          add_cohort.location,
          add_cohort.company_id,
          add_cohort.schedule,
          add_cohort.email_templates,
          add_cohort.time_zone,
          add_cohort.template_id,
          add_cohort.access,
          add_cohort.start_date,
          add_cohort.method_id,
          add_cohort.access_duration,
          add_cohort.course_type,
          token,
          this.OSelectedEvents.toString(),
        )
        .then(async (value) => {
          if (value[0].status == 200) {
            var list = JSON.parse(<string>value[0].json);
            this.cohort_id = list.data.id;
            await this.selfService
              .editSelfPacedOneTime(
                this.oEditID,
                this.SelectedCertificate,
                this.oprice_label,
                ofeatures_visible,
                this.OSelectedEvents.toString(),
                this.Oonly_show_study,
                this.Opublish_subscription,
                this.ocat_exam_attempts,
                this.oattempts_fee,
                this.Odescription,
                this.Oaccess_duration,
                this.Oprice,
                this.cohort_id,
                this.OSelectedCompany,
                this.SelectedMentor,
                JSON.stringify(this.time_zone),
                token,
              )
              .then(async (data) => {
                if (data[0].status == 200) {
                  var sub = JSON.parse(<string>data[0].json);
                  this.oEditID = sub.data.id;
                  if (this.OSelectedEvents.length > 0) {
                    await this.addNotificationLiveEvent(
                      this.userslist,
                      this.cohort_id,
                    );
                  }
                }
              });
          }
        });
    }
  }
  addSubscription() {
    this.SubscriptionList.push({
      description: '',
      access_duration: 0,
      price: 0,
      price_id: '',
      cohort_id: '',
    });
  }
  generateSubscriptionLink(item) {
    let data = {
      SelectedPlan: item.price,
      SubscriptionId: this.sEditID,
      CohortName: this.SPriceLabel,
    };
    let params = btoa(JSON.stringify(data));
    let url = 'https://www.my.destcert.com/membership/' + params;
    navigator.clipboard.writeText(url);
    this.service.ShowSuccess('Link copied to clipboard.');
  }
  generateMainSubscriptionLink() {
    let data = {
      SelectedPlan: 'UserSelection',
      SubscriptionId: this.sEditID,
      CohortName: this.SPriceLabel,
    };
    let params = btoa(JSON.stringify(data));
    let url = 'https://www.my.destcert.com/membership/' + params;
    navigator.clipboard.writeText(url);
    this.service.ShowSuccess('Link copied to clipboard.');
  }
  generateMainOneTimeLink() {
    let data = {
      SelectedPlan: this.Oprice,
      SubscriptionId: this.oEditID,
      CohortName: this.oprice_label,
      PaymentType: 'OneTime',
    };
    let params = btoa(JSON.stringify(data));
    let url = 'https://www.my.destcert.com/onetimepayment/' + params;
    navigator.clipboard.writeText(url);
    this.service.ShowSuccess('Link copied to clipboard.');
  }
  closeLiveEventPopup() {
    this.LiveEventPopup = false;
    this.NoLiveEventFound = false;
    this.EventCourseType = '';
  }
  async openLiveEventPopup(item) {
    this.LiveEventPopup = true;
    this.EventCourseType = item.course_type;
    if (item.live_events[0] > 0) {
      let ids = item.live_events.toString();
      await this.spinner.show();
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          await this.spinner.hide();

          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          await this.cohortService
            .liveEventByIds(ids, token)
            .then(async (value) => {
              if (value[0].status == 200) {
                var list = JSON.parse(<string>value[0].json);
                this.PopLiveEvents = list.data[0];
                this.spinner.hide();
              }
            });
          this.spinner.hide();
        }
      });
    } else {
      this.NoLiveEventFound = true;
    }
  }
  async startMeeting(row) {
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.liveservice
          .getAttendySelfPaced(
            row.id,
            this.EventCourseType.toLowerCase(),
            token,
          )
          .then(async (rep) => {
            if (rep[0].status == 200) {
              var listdata = JSON.parse(<string>rep[0].json).data[0];
              let timezone =
                row.time_zone != '' && row.time_zone != null
                  ? JSON.parse(row.time_zone).nameValue
                  : '';
              let MeetingStartDate = new Date();
              if (listdata.length > 0) {
                let userIds =
                  window.sessionStorage.getItem('b6h3a5d9r6e3s5h') + ',';
                for (let i = 0; i < listdata.length; i++) {
                  userIds += listdata[i].userslist + ',';
                }
                if (userIds.endsWith(',') == true) {
                  userIds = userIds.substring(0, userIds.length - 1);
                }
                userIds = userIds
                  .split(',')
                  .filter(function (item, i, allItems) {
                    if (i < 100) {
                      return i == allItems.indexOf(item);
                    }
                  })
                  .join(',');

                await this.service.createMeeting(userIds).then(async (data) => {
                  if (data[0].status == 200) {
                    var list = JSON.parse(<string>data[0].json);
                    let Attendees = list.data.attendee.Attendees;
                    let Meeting = list.data.meeting.Meeting;
                    let noti = [];
                    let att = [];
                    Attendees.forEach((element) => {
                      let u_id = element.ExternalUserId.split('#')[1];
                      let AttendeeId = element.AttendeeId;
                      att.push({
                        user_id: u_id,
                        AttendeeId: AttendeeId,
                      });
                      if (
                        u_id == window.sessionStorage.getItem('b6h3a5d9r6e3s5h')
                      ) {
                        this.AdminAttendeeId = AttendeeId;
                      }
                    });
                    this.auth
                      .getAuthenticatedUser()
                      .getSession(async (err, session) => {
                        if (err) {
                          await this.spinner.hide();
                          this.router.navigate(['/login']);
                        } else {
                          var token = session.getIdToken().getJwtToken();

                          Attendees.forEach((element) => {
                            let u_id = element.ExternalUserId.split('#')[1];
                            let AttendeeId = element.AttendeeId;
                            let MeetingId = Meeting.MeetingId;
                            let data = {
                              AttendeeId: AttendeeId,
                              meeting_id: row.id,
                            };
                            let user_id = btoa(JSON.stringify(data));
                            let link =
                              'https://www.my.destcert.com/conference/' +
                              user_id;
                            let msg =
                              row.event_name +
                              ' has started please join us by clicking button <a href="' +
                              link +
                              '"><span>Join</span></a>';
                            let xxx = listdata.filter((x) =>
                              x.userslist.includes(u_id),
                            );
                            let cohortid = '';
                            xxx.forEach((element) => {
                              cohortid = element.id;
                              noti.push({
                                user_id: u_id,
                                cohort_id: cohortid,
                                message: msg,
                                noti_type: 'start_meeting',
                                test_type: 'meeting',
                                test_id: row.id,
                                is_read: 0,
                                fire_time: this.auth.convertStringUtcTimeZone(
                                  this.auth.ConvertFormatDbDateTime(
                                    new Date(),
                                    timezone,
                                  ),
                                  timezone,
                                ),
                                meeting_date:
                                  this.auth.convertStringUtcTimeZone(
                                    this.auth.ConvertFormatDbDateTime(
                                      MeetingStartDate,
                                      timezone,
                                    ),
                                    timezone,
                                  ),
                              });
                            });

                            if (
                              u_id ==
                              window.sessionStorage.getItem('b6h3a5d9r6e3s5h')
                            ) {
                              this.AdminMeetingId = MeetingId;
                              this.AdminAttendeeId = AttendeeId;
                            }
                          });
                          await this.service.addNotification(noti, token);
                          let update_meeting = {
                            id: row.id,
                            attendees: JSON.stringify(att),
                            admin_attendee_id: this.AdminAttendeeId,
                            meeting_id: this.AdminMeetingId,
                          };
                          await this.liveservice.UpdatestartLiveEvents(
                            update_meeting,
                            token,
                          );

                          Swal.fire({
                            title: 'Meeting has been created',
                            text: 'You want to join as Admin?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Join',
                          }).then(async (result) => {
                            if (result.value) {
                              let data = {
                                meeting_id: row.id,
                              };
                              let user_id = btoa(JSON.stringify(data));
                              this.router.navigate([`/joinmeeting/${user_id}`]);
                            }
                          });
                          this.spinner.hide();
                        }
                      });
                    this.spinner.hide();
                  } else {
                    var list = JSON.parse(<string>data[0].json);

                    this.spinner.hide();
                    this.service.ShowError(list.message);
                  }
                });
              } else {
                this.service.ShowError('No students assign in selected event');
              }
            } else {
              var listdata = JSON.parse(<string>rep[0].json);
              await this.spinner.hide();
              this.service.ShowError(listdata.message);
            }
          });
      }
    });
    await this.spinner.hide();
  }

  async addNotificationLiveEvent(userlist, id) {
    let noti = [];
    var user = userlist.split(',');
    var liveevent = [];
    liveevent = this.LiveEvents.filter((x) =>
      this.SelectedEvents.includes(x.id),
    );

    if (liveevent.length > 0) {
      liveevent.forEach((ele) => {
        let msg =
          'event (' +
          ele.event_name +
          ') will start from <a ><span style="cursor:not-allowed;opacity:0.5">Join</span></a>';
        let cohortid = id;
        let StartHour = 0;
        let StartMin = 0;
        if (ele.start_time.includes(':')) {
          StartHour = parseInt(ele.start_time.split(':')[0]);
          StartMin = parseInt(ele.start_time.split(':')[1]);
        } else if (ele.start_date != null && ele.start_date != '') {
          StartHour =
            ele.start_time.toString().split(':')[0] != undefined
              ? parseInt(ele.start_time.split(':')[0])
              : 0;
          StartMin =
            ele.start_time.toString().split(':')[1] != undefined
              ? parseInt(ele.start_time.split(':')[1])
              : 0;
        }

        let StrDate = new Date(ele.start_date);
        let EventDate =
          StrDate.getFullYear() +
          '-' +
          (StrDate.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          StrDate.getDate().toString().padStart(2, '0') +
          'T' +
          ele.start_time +
          ':00' +
          JSON.parse(ele.time_zone).timeValue;
        let date = new Date(
          this.auth.convertAnyTimeZoneUtcTimeZone(
            EventDate,
            JSON.parse(ele.time_zone).nameValue,
          ),
        );
        let firetime = this.auth.convertStringUtcTimeZone(
          this.auth.ConvertFormatDbDateTime(
            date.setMinutes(date.getMinutes() - 15),
            JSON.parse(ele.time_zone).nameValue,
          ),
          JSON.parse(ele.time_zone).nameValue,
        );

        user.forEach((user) => {
          noti.push({
            user_id: user,
            cohort_id: cohortid,
            message: msg,
            noti_type: 'start_meeting',
            test_type: 'meeting',
            test_id: ele.id,
            is_read: 0,
            fire_time: firetime,
            meeting_date: new Date(EventDate).toISOString(),
          });
        });
      });
    }
    if (noti.length > 0) {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.service.addNotification(noti, token);
          }
        });
    }
  }

  ChangeSubscriptionMaterial() {
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.material_type == 'KA',
    ).forEach((someobject) => (someobject.visible_material = this.SKAAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.material_type == 'Mindmap',
    ).forEach(
      (someobject) => (someobject.visible_material = this.SMindmapAccess),
    );
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.material_type == 'Test',
    ).forEach((someobject) => (someobject.visible_material = this.SPTAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.material_type == 'Video',
    ).forEach(
      (someobject) => (someobject.visible_material = this.SVideoAccess),
    );
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.material_type == 'KA' && !this.SKAAccess,
    ).forEach((someobject) => (someobject.is_checked = this.SKAAccess));
    this.StudyMaterialsSub.filter(
      (someobject) =>
        someobject.material_type == 'Mindmap' && !this.SMindmapAccess,
    ).forEach((someobject) => (someobject.is_checked = this.SMindmapAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.material_type == 'Test' && !this.SPTAccess,
    ).forEach((someobject) => (someobject.is_checked = this.SPTAccess));
    this.StudyMaterialsSub.filter(
      (someobject) => someobject.material_type == 'Video' && !this.SVideoAccess,
    ).forEach((someobject) => (someobject.is_checked = this.SVideoAccess));
  }
  ChangeOnetimeMaterial() {
    this.StudyMaterialsOne.filter(
      (someobject) => someobject.material_type == 'KA',
    ).forEach((someobject) => (someobject.visible_material = this.OKAAccess));
    this.StudyMaterialsOne.filter(
      (someobject) => someobject.material_type == 'Mindmap',
    ).forEach(
      (someobject) => (someobject.visible_material = this.OMindmapAccess),
    );
    this.StudyMaterialsOne.filter(
      (someobject) => someobject.material_type == 'Test',
    ).forEach((someobject) => (someobject.visible_material = this.OPTAccess));
    this.StudyMaterialsOne.filter(
      (someobject) => someobject.material_type == 'Video',
    ).forEach(
      (someobject) => (someobject.visible_material = this.OVideoAccess),
    );
    this.StudyMaterialsOne.filter(
      (someobject) => someobject.material_type == 'KA' && !this.OKAAccess,
    ).forEach((someobject) => (someobject.is_checked = this.OKAAccess));
    this.StudyMaterialsOne.filter(
      (someobject) =>
        someobject.material_type == 'Mindmap' && !this.OMindmapAccess,
    ).forEach((someobject) => (someobject.is_checked = this.OMindmapAccess));
    this.StudyMaterialsOne.filter(
      (someobject) => someobject.material_type == 'Test' && !this.OPTAccess,
    ).forEach((someobject) => (someobject.is_checked = this.OPTAccess));
    this.StudyMaterialsOne.filter(
      (someobject) => someobject.material_type == 'Video' && !this.OVideoAccess,
    ).forEach((someobject) => (someobject.is_checked = this.OVideoAccess));
  }
  ChangeSubscriptionLiveEvent() {
    this.SSelectedEvents = [];
  }
  ChangeOneTimeLiveEvent() {
    this.OSelectedEvents = [];
  }
  async GetAllCompany() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();

            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.companyService
              .GetAllCompany(token)
              .then(async (data) => {
                if (data[0].status == 200) {
                  await this.spinner.hide();
                  var list = JSON.parse(<string>data[0].json);
                  this.Companies = list.data;
                  this.SelectedCompany = '';
                  this.SSelectedCompany = '';
                  this.OSelectedCompany = '';
                }
              });
          }
        });
    } catch (e) {
      this.service.ShowError(e.meassage);
      await this.spinner.hide();
    }
  }
}
export interface cohortlist {
  id: number;
  certificate_name: string;
  count_userslist: number;
  class_name: string;
  course_type: string;
  live_events: string;
  cat_exam_attempts: number;
  certi_id: number;
  cohort_id: number;
  company_id: number;
  cat_attempts_fee: string;
  publish_free_trial: number;
  one_time_access_duration: number;
  array_subscriptions: string;
  description: string;
  features_visible: string;
  ka_id: number;
  one_time_description: string;
  one_time_price: string;
  only_show_study: number;
  pt_id: number;
  userslist: string;
  mentor_id: string;
  time_zone: string;
  knowassessments: string;
  practicetests: string;
}
